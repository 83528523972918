// React
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from "react-router-dom"

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Box, Typography, Divider } from '@material-ui/core'

// Custom Components
import OffersModal from "../../components/offers/OffersModal";
import ShareIcon from '../../components/common/ShareIcon';
import ButtonBack from '../../components/common/ButtonBack'
import DetailHeroPanel from '../../components/layouts/common/DetailHeroPanel'
import DetailInfoPanel from '../../components/layouts/common/DetailInfoPanel'
import VerifyAccessRoute from '../../components/common/VerifyAccessRoute'
import AdminDetailInfo from '../../components/common/admin/AdminDetailInfo'
import OffersRecommend from '../../components/offers/OffersRecommend'
import SupportFile from '../../components/common/client/SupportFile'

// Custom Hooks
import useUserDisplaytime from '../../hooks/useUserDisplaytime';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';
import { showOffer, toggleFormOffer } from '../../redux/actions/offers'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next"
import moment from 'moment'
import CoursesStyles from '../../styles/components/courses/CoursesStyles'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

// Custom Style
const offerStyles = makeStyles(theme => ({
    offer: {
        borderRadius: 8,
        maxWidth: '100%'
    },
    detailTitle: {
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem !important',
            lineHeight: '1.235',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '16px',
        },
    },
}));

// Types
interface ParamTypes {
    id: string
}

function OfferDetail() {

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Style
    const classes = offerStyles();
    const multimediaclasses = CoursesStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { id } = useParams<ParamTypes>();
    const { isAuth } = useSelector((state: IRootState) => state.auth);
    const { offer_selected, showForm } = useSelector((state: IRootState) => state.offer);
    const { user: authUser } = useSelector((state: IRootState) => state.auth);

    const [favoriteState, setFavoriteState] = useState<boolean>(false);
    const location = useLocation();

    // Send track of user
    useUserDisplaytime({ foreign: offer_selected });

    // Ready Page
    useEffect(() => {
        dispatch(showOffer(id))
    }, [id]);

    useEffect(() => {
        if (offer_selected !== null) {
            setFavoriteState(Boolean(offer_selected.favoriteId));
        }
    }, [offer_selected]);

    let infoDetail = (offer_selected != null) && {
        "item": [
            {
                "id": 0,
                "type": "tag",
                "title": "Tags",
                "color": "light",
                "info": `${offer_selected.tags.map((tag: any) => { return tag.name.toUpperCase() }).join(', ')}`,
            },
            {
                "id": 1,
                "type": "date",
                "title": `${t("start-date")}`,
                "color": "grey6",
                "info": `${moment(offer_selected.created_at).format('LL')}`,
            },
        ]
    }

    /** Functions */
    const handleFavoriteClick = (offer_id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id: offer_id,
                type: 'offer'
            }));
        } else {
            dispatch(unsetFavorite({
                id: offer_id,
                type: 'offer',
            }));
        }
        setFavoriteState(state);
    };

    const handleGoBack = () => {
        window.location.pathname = "/ofertas";
    }

    const handleDownloadMediaFileBlock = (fileName: string) => {
        window.open(`${process.env.REACT_APP_API_URL}/offers/download/${fileName}`)
    };

    return (
        <>
            {(offer_selected != null) &&
                <Container maxWidth="lg">

                    {showForm && (
                        <OffersModal />
                    )}

                    {!isAuth ?
                        <ButtonBack customText={t('see-offers-from-other-brands')} customFunction={handleGoBack} goBack={false} async={false} />
                        :
                        <ButtonBack />
                    }

                    <Grid container spacing={5}>


                        <Grid item xs={12} md={8}>
                            <VerifyAccessRoute visibility={offer_selected.visibility} />

                            {location.pathname.includes('admin') &&
                                <AdminDetailInfo resourceSelected={offer_selected} />
                            }

                            {offer_selected.images.length > 1 ?
                                <Carousel centerMode autoPlay showThumbs={false} interval={5000} infiniteLoop>
                                    {offer_selected.images.map((image: any) => (
                                        <img key={image.file_name} src={`${process.env.REACT_APP_AWS_URL}/offers/${image.file_name}`} alt={offer_selected.title} className={classes.offer} />
                                    ))
                                    }
                                </Carousel>
                                :
                                <Box borderRadius={8}>
                                    <img src={`${process.env.REACT_APP_AWS_URL}/offers/${offer_selected.image}`} alt={offer_selected.title} className={classes.offer} />
                                </Box>
                            }
                            {/* Nuevo bloque para material multimedia de una oferta */}
                            {offer_selected.support_files.length > 0 &&
                                <Box mt={4} mb={6} gridGap={24} display='grid'>
                                    <Divider light />
                                    <Typography variant="subtitle1" color="primary">{t("downloadable-material")}</Typography>
                                    <Grid container spacing={isMobile ? 2 : 3}>
                                        {offer_selected.support_files.map((multimedia: any) => (
                                            <SupportFile
                                                key={multimedia.id}
                                                data={multimedia}
                                                type="offers"
                                                vipContent={offer_selected.visibility === 1}
                                                hasaccess={offer_selected.visibility === 1 && (authUser.vip_lines.includes(offer_selected.line_id) || (offer_selected.line_id === null && authUser.vip_brands.includes(offer_selected.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                                handleDownload={handleDownloadMediaFileBlock}
                                            />
                                        ))}
                                    </Grid>
                                    <Divider light />
                                </Box>
                            }

                            <OffersRecommend id={offer_selected.id} />

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <DetailHeroPanel
                                visibility={offer_selected.visibility}
                                status={offer_selected.status}
                                brand={offer_selected.brand_id}
                                title={offer_selected.title}
                                author={offer_selected.created_by}
                                description={offer_selected.description}
                                // Actions
                                edit={() => { dispatch(toggleFormOffer()) }}
                                download={() => { window.open(`${process.env.REACT_APP_API_URL}/offers/download/${offer_selected.image}`) }}
                                onClickFavorite={handleFavoriteClick(offer_selected.id)}
                                favoriteState={favoriteState}
                                share={<ShareIcon
                                    visibility={offer_selected.visibility}
                                    title={offer_selected.title}
                                    description={offer_selected.description}
                                    link={`${window.location.href}`}
                                    type="offer"
                                    multimedia={`${process.env.REACT_APP_AWS_URL}/offers/${offer_selected.image}`}
                                    component="buttonTextGreen"
                                    buttonTextGreen={t("share")}
                                />}
                            />
                            <DetailInfoPanel attributes={infoDetail} />
                        </Grid>

                    </Grid>

                </Container>
            }
        </>
    );
}

export default OfferDetail;
