import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Container, IconButton } from '@material-ui/core';

// Custom components
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import TypeFilter from '../../../components/common/header/TypeFilter';
import ContentFilter from '../../../components/layouts/filters/ContentFilter';
import OffersPanel from '../../../components/offers/OffersPanel';

// Custom Hooks
import useSkeleton from '../../../components/layouts/navigation/SkeletonLayout';

// Redux Actions
import { getClientOffers, counterViewsOffer } from '../../../redux/actions/offers';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import HiddenDesktop from '../../../components/common/HiddenDesktop';
import { DoubleArrow } from '@material-ui/icons';
import ScrollSubHeader from '../../../components/common/header/ScrollSubHeader';

interface IFilter {
    type: number
}

function OffersHome() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Redux Hooks
    const dispatch = useDispatch();
    const { globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag } = useSelector((state: IRootState) => state.global);

    const types: string[] = [t("professional"), t("your-clients")];
    const [filters, setFilters] = useState<IFilter>({
        type: 0 // 0: Para ti, 1: Para tus clientes
    });

    useEffect(() => {
        dispatch(getClientOffers({
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            country_id: globalCountryId,
            role_id: globalRoleId,
            language_id: globalLanguageId,
            orderby_id: globalOrderById,
            tag_id: globalTag,
            type: filters.type
        }));
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag, filters]);

    const { loading: loadingSkeleton, component: componentSkeleton } = useSkeleton();

    /* Functions */
    const myElement: HTMLElement | null = document.getElementById('window');
    let midOfWidth = window.innerWidth / 2

    const handleChangeFilter = (type: number) => {
        setFilters({ ...filters, type });
    }

    if (loadingSkeleton) {
        return (
            <>
                {componentSkeleton}
            </>
        )
    }

    return (
        <>
            <Header title={t("offers")} />
                <SubHeader>
                    <ScrollSubHeader id='window'>
                    {types.map((type: string, index: number) => (
                        <TypeFilter
                            key={type}
                            id={index}
                            type={type}
                            categories={[]}
                            active={filters.type === index}
                            handleChangeFilter={handleChangeFilter}
                        />
                    ))}
                    </ScrollSubHeader>
                    <HiddenDesktop>
                        <IconButton className='buttonScroll' onClick={() => { myElement?.scrollBy(midOfWidth, 0) }}>
                            <DoubleArrow />
                        </IconButton>
                    </HiddenDesktop>
                </SubHeader>
            <ContentFilter />

            <Container style={{ maxWidth: "1008px" }}>
                <OffersPanel userCounterViewsOffer={counterViewsOffer} subsection={types[filters.type]} />
            </Container>
        </>
    );
}

export default OffersHome;