
import {
    IModalState,
    SUCCESS_SHOW_MODAL, ERROR_SHOW_MODAL,
    SUCCESS_HIDE_MODAL, ERROR_HIDE_MODAL,
    SUCCESS_ASSIGN_ACTION_MODAL, ERROR_ASSIGN_ACTION_MODAL
} from '../types';

const initialState: IModalState = {
    modal: [],
    actionButton: null,
    showModal: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case SUCCESS_SHOW_MODAL:
            return {
                showModal: true,
                ...action.payload
            }
        case SUCCESS_ASSIGN_ACTION_MODAL:
            return {
                ...state,
                actionButton: action.payload
            }
        case SUCCESS_HIDE_MODAL:
        case ERROR_SHOW_MODAL:
        case ERROR_HIDE_MODAL:
        case ERROR_ASSIGN_ACTION_MODAL:
            return {
                showModal: false,
                modal: []
            }
        default:
            return state;
    }
}