// React
import { useState, useEffect, ComponentType } from 'react'
import { useSelector } from 'react-redux';

// Material UI
import { Button, Snackbar, Slide, SlideProps, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// Redux Actions
import { IRootState } from "../redux/types";

// Libraries
import Pusher from "pusher-js";

// Types
type TransitionProps = Omit<SlideProps, 'direction'>;

type INotification = {
    title: null | string,
    body: null | string,
    link: string,
    roles: null | number[]
}

function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

function DesktopNotification(history: any) {
    // Redux Hooks
    const { user } = useSelector((state: IRootState) => state.auth);

    const [pusher, setPusher] = useState(new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
        cluster: 'eu'
    }));
    const [open, setOpen] = useState(false);
    const [notificationState, setNotificationState] = useState<INotification>({
        title: null,
        body: null,
        link: '',
        roles: null
    });
    const [transition, setTransition] = useState<
        ComponentType<TransitionProps> | undefined
    >(undefined);

    // Enable pusher logging - don't include this in production
    // Pusher.logToConsole = true;

    useEffect(() => {
        if ("Notification" in window) {
            if (Notification.permission !== 'denied') {
                try {
                    Notification.requestPermission().then(permission => {});
                } catch (error) {
                    console.log(error);
                }
            }

            var channel = pusher.subscribe(`${process.env.REACT_APP_PUSHER_CHANNEL}`);
            channel.bind('desktop-notification', function (data: any) {
                // If is granted
                if (Notification.permission === "granted") {
                    setNotificationState(data);
                    // Deploy
                    if (data.roles === null)
                        deployNotification(data);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (user !== null) {
            if ("Notification" in window) {
                if (Notification.permission !== 'denied') {
                    try {
                        Notification.requestPermission().then(permission => {});
                    } catch (error) {
                        console.log(error);
                    }
                }

                pusher.unsubscribe(`${process.env.REACT_APP_PUSHER_CHANNEL}`);
                var channel = pusher.subscribe(`${process.env.REACT_APP_PUSHER_CHANNEL}`);
                channel.bind('desktop-notification', function (data: any) {
                    // If is granted
                    if (Notification.permission === "granted") {
                        setNotificationState(data);
                        // Deploy
                        if (data.roles === null)
                            deployNotification(data);
                        else if (data.roles !== null && data.roles.includes(user.role_id))
                            deployNotification(data);
                    }
                });
            }
        }
    }, [user]);

    const handleClick = (Transition: React.ComponentType<TransitionProps>) => () => {
        setTransition(() => Transition);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNotificationState({
            title: null,
            body: null,
            link: '',
            roles: null
        });
    };

    const deployNotification = (data: any) => {
        const notification = new Notification(data.title, {
            body: data.body
        })
        notification.onclick = (e) => {
            window.open(data.link, '_blank');
            window.location.href = data.link;
        };
        handleClick(TransitionUp)();
    }

    return (
        <Snackbar
            style={{padding: '0px'}}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            open={open}
            onClose={handleClose}
            TransitionComponent={transition}
            message={`${notificationState.title}: ${notificationState.body?.substring(0, 15)}`}
            key={transition ? transition.name : ''}
            ContentProps={{
                style: {padding: '0px 16px 0px 16px'}
            }}
            action={
                <>
                    <Button color="primary" style={{ padding: '0px' }} size="small" onClick={() => { window.open(notificationState.link) }}>
                        UNDO
                    </Button>
                    <IconButton
                        style={{padding: '0px'}}
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </>
            }
        />
    );
}

export default DesktopNotification;