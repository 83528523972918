// Material UI
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const SubHeader = styled(Box)(({
    theme
}) => ({
    background: theme.palette.primary.main,
    marginBottom: '16px',
    minHeight: '47px',
    position: 'relative',
    borderTop: '1px solid white',
    '&:before': {
        content: '',
        width: '100%',
        height: '3px',
        background: 'white',
        position: 'absolute',
        top: '0px',
    },
    [theme.breakpoints.up('md')]: {
        '& .buttonScroll': {
            display: 'none',
        },
        paddingLeft: '137.5px',
        marginLeft: '24px',
        borderRadius: '0 0 0 16px',
    },
    [theme.breakpoints.down('xs')]: {
        minHeight: '50px',
        borderTop: '0px',
        paddingLeft: '0px',
        width: '100vw',
        position: 'relative',
        marginBottom: 0,
        marginTop: 50,
        '& .buttonScroll': {
            display: 'block',
            position: 'absolute',
            right: '0px',
            top: '0px',
            background: theme.palette.primary.main,
            maxHeight: 50,
            width: '50px !important',
            minWidth: 50,
            borderLeft: 0,
            padding: 0,
            boxShadow: '-6px -2px 8px 0px #00000022',
            "& span": {
                padding: '10px 0px',
            },
            '& svg': {
                color: `${theme.palette.common.white} !important`,
            },
        },
        '& span': {
            textTransform: 'uppercase',
            '& span.MuiButton-endIcon.MuiButton-iconSizeMedium': {
                width: 32,
            },
        },
        '&.profile': {
            display: 'none'
        },
        '& button': {
            boxSizing: 'content-box',
            maxHeight: 50,
            borderRadius: '0px',
            minWidth: '60%',
            borderLeft: `1px solid ${theme.palette.grey[50]}`
        }
    },
}));

export default SubHeader;