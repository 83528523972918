import { useState } from 'react'
import { MassAssignmentInputList } from '../utils/MassAssignmentInputList'
import { IAddedInputs } from '../interfaces/MassAssignmentAddedInputs'

const useMassAssignmentAddedInputs = (fields: string[]) => {
    const [addedInputs, setAddedInputs] = useState<IAddedInputs[]>([])
    const [buttons, setButtons] = useState<string[]>(fields)

    const handleCheckChange = (event: any) => {
        const tmpInputsAdded = addedInputs.map((input: IAddedInputs) =>
            input.name === event.target.name
                ? { ...input, checked: event.target.checked }
                : input
        )

        setAddedInputs(tmpInputsAdded)
    }

    const addInput = (field: string, index: number) => {
        // Add input
        let tmpInputsAdded: IAddedInputs[] = [...addedInputs]
        tmpInputsAdded.push(MassAssignmentInputList[field])
        setAddedInputs(tmpInputsAdded)

        // Remove button
        let tmpButtons: string[] = [...buttons]
        tmpButtons.splice(index, 1)
        setButtons(tmpButtons)
    }

    const removeInput = (field: string, index: number) => {
        // Remove input
        let tempInputsAdded: IAddedInputs[] = [...addedInputs]
        tempInputsAdded.splice(index, 1)
        setAddedInputs(tempInputsAdded)

        // Add button
        let tmpButtons: string[] = [...buttons]
        tmpButtons.push(field)
        setButtons(tmpButtons)
    }

    return {addedInputs, buttons, addInput, removeInput, handleCheckChange}
}

export default useMassAssignmentAddedInputs