import { 
    ICountryState, 
    START_GET_COUNTRIES, SUCCESS_GET_COUNTRIES, ERROR_GET_COUNTRIES,
    START_GET_FILTERED_COUNTRIES, SUCCESS_GET_FILTERED_COUNTRIES, ERROR_GET_FILTERED_COUNTRIES,
    START_GET_LOCATIONS, SUCCESS_GET_LOCATIONS, ERROR_GET_LOCATIONS,
    START_STORE_LOCATION, SUCCESS_STORE_LOCATION, ERROR_STORE_LOCATION
 } from '../types';

const initialState: ICountryState = {
    countries: [],
    provinces: [],
    towns: [],
    postalcodes: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_COUNTRIES:
        case START_GET_FILTERED_COUNTRIES:
        case START_GET_LOCATIONS:
        case START_STORE_LOCATION:
            return{
                ...state,
                msg: null,
                error:false,
                loading: true
            }
        case SUCCESS_GET_COUNTRIES:
        case SUCCESS_GET_FILTERED_COUNTRIES:
            return{
                ...state,
                countries: action.payload.countries,
                msg: null,
                error:false,
                loading: false
            }
        case SUCCESS_GET_LOCATIONS:
            return{
                ...state,
                provinces: action.payload.provinces,
                towns: action.payload.towns,
                postalcodes: action.payload.postalcodes,
                msg: null,
                error:false,
                loading: false
            }
        case SUCCESS_STORE_LOCATION:
            return{
                ...state,
                towns: action.payload.towns,
                postalcodes: action.payload.postalcodes,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case ERROR_STORE_LOCATION:
            return{
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        case ERROR_GET_COUNTRIES:
        case ERROR_GET_FILTERED_COUNTRIES:
            return{
                ...state,
                countries: [],
                msg: action.payload,
                error: true,
                loading: false
            }
        case ERROR_GET_LOCATIONS:
            return{
                ...state,
                provinces: [],
                towns: [],
                postalcodes: [],
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}