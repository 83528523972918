// React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Grid,
    FormControl,
    Button,
    Dialog,
    DialogContent,
    FormHelperText,
    DialogTitle,
    DialogActions,
    Tabs,
    Tab,
    Typography,
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'

// Custom components
import TabPanel from '../common/admin/TabPanel'
import {
    InputCountry,
    BrandAutocomplete,
    LineAutocomplete,
    RoleAutocomplete,
    VisibilityAutocomplete,
    ResourcetypeSelect,
    LanguageMultiAutocomplete,
    ResourcecategoryAutocomplete,
    DropzoneMulti,
    SaveButton,
    CancelButton,
} from '../common/forms'

// Redux Actions
import { IRootState } from '../../redux/types'
import { getResourcecategoriesOptions } from '../../redux/actions/resourcecategories'
import {
    storeResource,
    toggleFormCreateResources,
} from '../../redux/actions/resources'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hooks
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'

// Types
interface IResource {
    fileNames: string[]
    resourcetypeId: number | null
    category: any
    brand: any
    line: any
    countries: number[]
    languages: number[]
    role: any
    visibility: {
        id: number,
        name: string
    } | null
}

function CreateMediaModal() {
    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const formClasses = FormsStyles()

    //Redux Hooks
    const dispatch = useDispatch()
    const { globalCountriesId, globalLanguageId } = useSelector(
        (state: IRootState) => state.global
    )
    const { showCreateForm } = useSelector(
        (state: IRootState) => state.resource
    )
    const { isValidForm, msgForm, errorForm } = useSelector(
        (state: IRootState) => state.validator
    )

    // Resource State
    const [resource, setResource] = useState<IResource>({
        fileNames: [],
        resourcetypeId: null,
        brand: [],
        line: [],
        countries: [],
        languages: [],
        role: [],
        visibility: null,
        category: [],
    })

    /* Functions */
    const handleInputChange = (inputName: string, value: any) => {
        if (inputName === 'brand' && value !== null){
            dispatch(
                getResourcecategoriesOptions({
                    languageId: globalLanguageId,
                    countriesId: globalCountriesId,
                    familyId: null,
                    brandId: value.id,
                })
            )
        }

        const formatValue = value ?? [];
        console.log(formatValue, inputName);
        setResource({ ...resource, [inputName]: formatValue })
    }

    const handleSubmit = () => {
        console.log('handleSubmit')
        console.log(resource)
        dispatch(
            formValidator({
                brands: [resource.brand, 'notempty'],
                resourcetypeId: [resource.resourcetypeId, 'required'],
                roles: [resource.role, 'notempty'],
                countries: [resource.countries, 'notempty'],
                languages: [resource.languages, 'notempty'],
                visibility: [resource.visibility, 'notempty'],
                fileNames: [resource.fileNames, 'notempty'],
            })
        )
    }

    // Send Form
    useEffect(() => {
        console.log(resource)
        if (isValidForm) {
            dispatch(resetFormValidator())
            dispatch(storeResource(resource))
        }
    }, [isValidForm])

    return (
        <Dialog
            open={showCreateForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            onClose={() => dispatch(toggleFormCreateResources())}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="dialog-resource-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {t('admin.library.create-resource')}
                    </Typography>
                    <Button
                        onClick={() => dispatch(toggleFormCreateResources())}
                        color="primary"
                        variant="text"
                    >
                        <CloseRounded />
                    </Button>
                </Grid>
            </DialogTitle>

            <DialogContent className={formClasses.bodyForm}>
                <Grid
                    container
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={2}
                >
                    {/* Brand Input */}
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={resource.brand.length !== 0 ? 4 : 6}
                    >
                        <FormControl fullWidth variant="filled">
                            <BrandAutocomplete
                                value={resource.brand}
                                handleInputChange={handleInputChange}
                                props={{ required: true }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Line Input */}
                    {resource.brand.length !== 0 && (
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth variant="filled">
                                <LineAutocomplete
                                    value={resource.line}
                                    brand={resource.brand}
                                    handleInputChange={handleInputChange}
                                />
                            </FormControl>
                        </Grid>
                    )}

                    {/* Resourcetype Input */}
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={resource.brand.length !== 0 ? 4 : 6}
                    >
                        <FormControl fullWidth variant="filled">
                            <ResourcetypeSelect
                                value={resource.resourcetypeId ?? ''}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Tabs */}
                <Grid item xs={12}>
                    <Tabs
                        value={0}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={formClasses.tapForm}
                        aria-label="disabled tabs example"
                    >
                        <Tab
                            label="General"
                            id="simple-tab-0"
                            aria-controls="simple-tabpanel-0"
                        />
                    </Tabs>
                </Grid>

                <TabPanel value={0} index={0}>
                    <Grid container spacing={2}>
                        {/* Role Input */}
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth variant="filled">
                                <RoleAutocomplete
                                    value={resource.role}
                                    handleInputChange={handleInputChange}
                                />
                            </FormControl>
                        </Grid>

                        {/* Country Input */}
                        <Grid item xs={12} sm={6} md={4}>
                            <InputCountry
                                value={resource.countries}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        {/* Languages Input */}
                        <Grid item xs={12} sm={6} md={4}>
                            <LanguageMultiAutocomplete
                                value={resource.languages}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        {/* Visibility Input */}
                        <Grid item xs={12} sm={6}>
                            <VisibilityAutocomplete
                                value={resource.visibility}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        {/* Resourcecategory Input */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="filled">
                                <ResourcecategoryAutocomplete
                                    value={resource.category}
                                    resourcetypeId={resource.resourcetypeId}
                                    brandId={resource.brand}
                                    handleInputChange={handleInputChange}
                                />
                            </FormControl>
                        </Grid>

                        {/* Files Input */}
                        <Grid item xs={12}>
                            <DropzoneMulti
                                value={resource.fileNames}
                                handleInputChange={handleInputChange}
                            />
                            <FormHelperText error={errorForm.fileNames}>
                                {msgForm.fileNames}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </TabPanel>
            </DialogContent>

            <DialogActions>
                <CancelButton id='btn-cancel-resource'
                    onClick={() => dispatch(toggleFormCreateResources())}
                >
                    {t('cancel')}
                </CancelButton>
                <SaveButton id='btn-save-resource' onClick={() => handleSubmit()}>
                    {t('save')}
                </SaveButton>
            </DialogActions>
        </Dialog>
    )
}

export default CreateMediaModal
