// AUTH
export interface IAuthState {
    isAuth: boolean,
    authFrom: null | string,
    user: any,
    msg: null | string,
    loading: boolean,
    error: boolean
}

export const START_LOGIN = 'START_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const START_VERIFY_USER = 'START_VERIFY_USER';
export const SUCCESS_VERIFY_USER = 'SUCCESS_VERIFY_USER';
export const ERROR_VERIFY_USER = 'ERROR_VERIFY_USER';
export const ERROR_REQUEST_VERIFY_USER = 'ERROR_REQUEST_VERIFY_USER';
export const START_LOGOUT_USER = 'START_LOGOUT_USER';
export const SUCCESS_LOGOUT_USER = 'SUCCESS_LOGOUT_USER';
export const ERROR_LOGOUT_USER = 'ERROR_LOGOUT_USER';
export const START_REMEMBER_PASSWORD = 'START_REMEMBER_PASSWORD';
export const SUCCESS_REMEMBER_PASSWORD = 'SUCCESS_REMEMBER_PASSWORD';
export const ERROR_REMEMBER_PASSWORD = 'ERROR_REMEMBER_PASSWORD';
export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
export const ERROR_RESET_PASSWORD = 'ERROR_RESET_PASSWORD';
export const START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD';
export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';

// BRANDS
export interface IBrandState {
    brands: any,
    userFamilies: any,
    userBrands: any,
    brandSelected: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_BRANDS = 'START_GET_BRANDS';
export const SUCCESS_GET_BRANDS = 'SUCCESS_GET_BRANDS';
export const ERROR_GET_BRANDS = 'ERROR_GET_BRANDS';
export const START_GET_USER_BRANDS = 'START_GET_USER_BRANDS';
export const SUCCESS_GET_USER_BRANDS = 'SUCCESS_GET_USER_BRANDS';
export const ERROR_GET_USER_BRANDS = 'ERROR_GET_USER_BRANDS';
export const START_SHOW_BRAND = 'START_SHOW_BRAND';
export const SUCCESS_SHOW_BRAND = 'SUCCESS_SHOW_BRAND';
export const ERROR_SHOW_BRAND = 'ERROR_SHOW_BRAND';
export const START_VIEW_BRAND = 'START_VIEW_BRAND';
export const SUCCESS_VIEW_BRAND = 'SUCCESS_VIEW_BRAND';
export const ERROR_VIEW_BRAND = 'ERROR_VIEW_BRAND';
export const START_STORE_BRAND = 'START_STORE_BRAND';
export const SUCCESS_STORE_BRAND = 'SUCCESS_STORE_BRAND';
export const ERROR_STORE_BRAND = 'ERROR_STORE_BRAND';
export const START_UPDATE_BRAND = 'START_UPDATE_BRAND';
export const SUCCESS_UPDATE_BRAND = 'SUCCESS_UPDATE_BRAND';
export const ERROR_UPDATE_BRAND = 'ERROR_UPDATE_BRAND';
export const START_DELETE_BRAND = 'START_DELETE_BRAND';
export const SUCCESS_DELETE_BRAND = 'SUCCESS_DELETE_BRAND';
export const ERROR_DELETE_BRAND = 'ERROR_DELETE_BRAND';
export const START_TOGGLE_FORM_BRANDS = 'START_TOGGLE_FORM_BRANDS';
export const SUCCESS_TOGGLE_FORM_BRANDS = 'SUCCESS_TOGGLE_FORM_BRANDS';
export const START_CHANGE_LINE_STATUS = 'START_CHANGE_LINE_STATUS';
export const SUCCESS_CHANGE_LINE_STATUS = 'SUCCESS_CHANGE_LINE_STATUS';
export const ERROR_CHANGE_LINE_STATUS = 'ERROR_CHANGE_LINE_STATUS';
export const START_DELETE_BRAND_LINE = 'START_DELETE_BRAND_LINE';
export const SUCCESS_DELETE_BRAND_LINE = 'SUCCESS_DELETE_BRAND_LINE';
export const ERROR_DELETE_BRAND_LINE = 'ERROR_DELETE_BRAND_LINE';
export const START_ASSIGN_HIGHLIGHT_BRAND = 'START_ASSIGN_HIGHLIGHT_BRAND';
export const SUCCESS_ASSIGN_HIGHLIGHT_BRAND = 'SUCCESS_ASSIGN_HIGHLIGHT_BRAND';
export const ERROR_ASSIGN_HIGHLIGHT_BRAND = 'ERROR_ASSIGN_HIGHLIGHT_BRAND';
export const START_ORDERED_BRAND = 'START_ORDERED_BRAND';
export const SUCCESS_ORDERED_BRAND = 'SUCCESS_ORDERED_BRAND';
export const ERROR_ORDERED_BRAND = 'ERROR_ORDERED_BRAND';

// FORM VALIDATOR
export interface IFormValidatorState {
    errorForm: any,
    errorTab: any,
    msgForm: any,
    msgTab: any,
    isValidForm: boolean
}

export const START_FORM_VALIDATOR = 'START_FORM_VALIDATOR';
export const SUCCESS_FORM_VALIDATOR = 'SUCCESS_FORM_VALIDATOR';
export const RESET_FORM_VALIDATOR = 'RESET_FORM_VALIDATOR';
export const START_RESET_FORM_VALIDATOR = 'START_RESET_FORM_VALIDATOR';

// COUNTRIES
export interface ICountryState {
    countries: any,
    provinces: any,
    towns: any,
    postalcodes: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_COUNTRIES = 'START_GET_COUNTRIES';
export const SUCCESS_GET_COUNTRIES = 'SUCCESS_GET_COUNTRIES';
export const ERROR_GET_COUNTRIES = 'ERROR_GET_COUNTRIES';
export const START_GET_FILTERED_COUNTRIES = 'START_GET_FILTERED_COUNTRIES';
export const SUCCESS_GET_FILTERED_COUNTRIES = 'SUCCESS_GET_FILTERED_COUNTRIES';
export const ERROR_GET_FILTERED_COUNTRIES = 'ERROR_GET_FILTERED_COUNTRIES';
export const START_GET_LOCATIONS = 'START_GET_LOCATIONS';
export const SUCCESS_GET_LOCATIONS = 'SUCCESS_GET_LOCATIONS';
export const ERROR_GET_LOCATIONS = 'ERROR_GET_LOCATIONS';
export const START_STORE_LOCATION = 'START_STORE_LOCATION';
export const SUCCESS_STORE_LOCATION = 'SUCCESS_STORE_LOCATION';
export const ERROR_STORE_LOCATION = 'ERROR_STORE_LOCATION';

// LANGUAGES
export interface ILanguageState {
    languages: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_LANGUAGES = 'START_GET_LANGUAGES';
export const SUCCESS_GET_LANGUAGES = 'SUCCESS_GET_LANGUAGES';
export const ERROR_GET_LANGUAGES = 'ERROR_GET_LANGUAGES';
export const START_GET_FILTERED_LANGUAGES = 'START_GET_FILTERED_LANGUAGES';
export const SUCCESS_GET_FILTERED_LANGUAGES = 'SUCCESS_GET_FILTERED_LANGUAGES';
export const ERROR_GET_FILTERED_LANGUAGES = 'ERROR_GET_FILTERED_LANGUAGES';

// ROLES
export interface IRoleState {
    roles: any,
    rolehierarchy: number[],
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_ROLES = 'START_GET_ROLES';
export const SUCCESS_GET_ROLES = 'SUCCESS_GET_ROLES';
export const ERROR_GET_ROLES = 'ERROR_GET_ROLES';

// USERS
export interface IUserState {
    users: any,
    dependents: any,
    notdependents: any,
    userSelected: any,
    userProfileSelected: any,
    vipContentSelected: any,
    massivelyAssignData: any,
    inscriptions: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean,
    showLinkForm: boolean,
    reload: boolean,
    code: any
}

export const START_FILTER_USERS = 'START_FILTER_USERS';
export const SUCCESS_FILTER_USERS = 'SUCCESS_FILTER_USERS';
export const ERROR_FILTER_USERS = 'ERROR_FILTER_USERS';
export const START_LINK_USERS = 'START_LINK_USERS';
export const SUCCESS_LINK_USERS = 'SUCCESS_LINK_USERS';
export const ERROR_LINK_USERS = 'ERROR_LINK_USERS';
export const START_STORE_USER = 'START_STORE_USER';
export const SUCCESS_STORE_USER = 'SUCCESS_STORE_USER';
export const ERROR_STORE_USER = 'ERROR_STORE_USER';
export const START_UPDATE_USER = 'START_UPDATE_USER';
export const SUCCESS_UPDATE_USER = 'SUCCESS_UPDATE_USER';
export const ERROR_UPDATE_USER = 'ERROR_UPDATE_USER';
export const START_SHOW_USER = 'START_SHOW_USER';
export const SUCCESS_SHOW_USER = 'SUCCESS_SHOW_USER';
export const ERROR_SHOW_USER = 'ERROR_SHOW_USER';
export const START_SHOW_DEPENDENTS = 'START_SHOW_DEPENDENTS';
export const SUCCESS_SHOW_DEPENDENTS = 'SUCCESS_SHOW_DEPENDENTS';
export const ERROR_SHOW_DEPENDENTS = 'ERROR_SHOW_DEPENDENTS';
export const START_GET_NOT_DEPENDENTS = 'START_GET_NOT_DEPENDENTS';
export const SUCCESS_GET_NOT_DEPENDENTS = 'SUCCESS_GET_NOT_DEPENDENTS';
export const ERROR_GET_NOT_DEPENDENTS = 'ERROR_GET_NOT_DEPENDENTS';
export const START_SHOW_PROFILE_USER = 'START_SHOW_PROFILE_USER';
export const SUCCESS_SHOW_PROFILE_USER = 'SUCCESS_SHOW_PROFILE_USER';
export const ERROR_SHOW_PROFILE_USER = 'ERROR_SHOW_PROFILE_USER';
export const START_TRAININGRESPONSIBLE_USERS = 'START_TRAININGRESPONSIBLE_USERS';
export const SUCCESS_TRAININGRESPONSIBLE_USERS = 'SUCCESS_TRAININGRESPONSIBLE_USERS';
export const ERROR_TRAININGRESPONSIBLE_USERS = 'ERROR_TRAININGRESPONSIBLE_USERS';
export const START_DELETE_USER = 'START_DELETE_USER';
export const SUCCESS_DELETE_USER = 'SUCCESS_DELETE_USER';
export const ERROR_DELETE_USER = 'ERROR_DELETE_USER';
export const START_RESTORE_USER = 'START_RESTORE_USER';
export const SUCCESS_RESTORE_USER = 'SUCCESS_RESTORE_USER';
export const ERROR_RESTORE_USER = 'ERROR_RESTORE_USER';
export const START_ASSIGN_BRAND = 'START_ASSIGN_BRAND';
export const SUCCESS_ASSIGN_BRAND = 'SUCCESS_ASSIGN_BRAND';
export const ERROR_ASSIGN_BRAND = 'ERROR_ASSIGN_BRAND';
export const START_ASSIGN_VIP_CONTENT = 'START_ASSIGN_VIP_CONTENT';
export const SUCCESS_ASSIGN_VIP_CONTENT = 'SUCCESS_ASSIGN_VIP_CONTENT';
export const ERROR_ASSIGN_VIP_CONTENT = 'ERROR_ASSIGN_VIP_CONTENT';
export const START_SHOW_VIP_CONTENT = 'START_SHOW_VIP_CONTENT';
export const SUCCESS_SHOW_VIP_CONTENT = 'SUCCESS_SHOW_VIP_CONTENT';
export const ERROR_SHOW_VIP_CONTENT = 'ERROR_SHOW_VIP_CONTENT';
export const START_DISABLE_VIP_CONTENT = 'START_DISABLE_VIP_CONTENT';
export const SUCCESS_DISABLE_VIP_CONTENT = 'SUCCESS_DISABLE_VIP_CONTENT';
export const ERROR_DISABLE_VIP_CONTENT = 'ERROR_DISABLE_VIP_CONTENT';
export const START_REQUEST_VIP_CONTENT = 'START_REQUEST_VIP_CONTENT';
export const SUCCESS_REQUEST_VIP_CONTENT = 'SUCCESS_REQUEST_VIP_CONTENT';
export const ERROR_REQUEST_VIP_CONTENT = 'ERROR_REQUEST_VIP_CONTENT';
export const START_DENY_VIP_CONTENT = 'START_DENY_VIP_CONTENT';
export const SUCCESS_DENY_VIP_CONTENT = 'SUCCESS_DENY_VIP_CONTENT';
export const ERROR_DENY_VIP_CONTENT = 'ERROR_DENY_VIP_CONTENT';
export const START_ENABLE_USER = 'START_ENABLE_USER';
export const SUCCESS_ENABLE_USER = 'SUCCESS_ENABLE_USER';
export const ERROR_ENABLE_USER = 'ERROR_ENABLE_USER';
export const START_DISABLE_USER = 'START_DISABLE_USER';
export const SUCCESS_DISABLE_USER = 'SUCCESS_DISABLE_USER';
export const ERROR_DISABLE_USER = 'ERROR_DISABLE_USER';
export const START_TOGGLE_FORM_USERS = 'START_TOGGLE_FORM_USERS';
export const SUCCESS_TOGGLE_FORM_USERS = 'SUCCESS_TOGGLE_FORM_USERS';
export const START_TOGGLE_FORM_LINK_USERS = 'START_TOGGLE_FORM_LINK_USERS';
export const SUCCESS_TOGGLE_FORM_LINK_USERS = 'SUCCESS_TOGGLE_FORM_LINK_USERS';
export const START_TOGGLE_PROFILE_USER = 'START_TOGGLE_PROFILE_USER';
export const SUCCESS_TOGGLE_PROFILE_USER = 'SUCCESS_TOGGLE_PROFILE_USER';
export const START_REGISTER_USER = 'START_REGISTER_USER';
export const SUCCESS_REGISTER_USER = 'SUCCESS_REGISTER_USER';
export const ERROR_REGISTER_USER = 'ERROR_REGISTER_USER';
export const START_VERIFY_COMMERCIALCODE = 'START_VERIFY_COMMERCIALCODE';
export const SUCCESS_VERIFY_COMMERCIALCODE = 'SUCCESS_VERIFY_COMMERCIALCODE';
export const ERROR_VERIFY_COMMERCIALCODE = 'ERROR_VERIFY_COMMERCIALCODE';
export const START_VERIFY_STATUS_USER = 'START_VERIFY_STATUS_USER';
export const SUCCESS_VERIFY_STATUS_USER = 'SUCCESS_VERIFY_STATUS_USER';
export const ERROR_VERIFY_STATUS_USER = 'ERROR_VERIFY_STATUS_USER';
export const START_SETTING_USER_HIERARCHY = 'START_SETTING_USER_HIERARCHY';
export const SUCCESS_SETTING_USER_HIERARCHY = 'SUCCESS_SETTING_USER_HIERARCHY';
export const ERROR_SETTING_USER_HIERARCHY = 'ERROR_SETTING_USER_HIERARCHY';
export const START_GET_MASSIVELY_ASSIGN = 'START_GET_MASSIVELY_ASSIGN';
export const SUCCESS_GET_MASSIVELY_ASSIGN = 'SUCCESS_GET_MASSIVELY_ASSIGN';
export const ERROR_GET_MASSIVELY_ASSIGN = 'ERROR_GET_MASSIVELY_ASSIGN';
export const START_SET_MASSIVELY_ASSIGN = 'START_SET_MASSIVELY_ASSIGN';
export const SUCCESS_SET_MASSIVELY_ASSIGN = 'SUCCESS_SET_MASSIVELY_ASSIGN';
export const ERROR_SET_MASSIVELY_ASSIGN = 'ERROR_SET_MASSIVELY_ASSIGN';
export const START_USER_CHANGE_PASSWORD = 'START_USER_CHANGE_PASSWORD';
export const SUCCESS_USER_CHANGE_PASSWORD = 'SUCCESS_USER_CHANGE_PASSWORD';
export const ERROR_USER_CHANGE_PASSWORD = 'ERROR_USER_CHANGE_PASSWORD';
export const START_USER_REGISTRATIONS = 'START_USER_REGISTRATIONS';
export const SUCCESS_USER_REGISTRATIONS = 'SUCCESS_USER_REGISTRATIONS';
export const ERROR_USER_REGISTRATIONS = 'ERROR_USER_REGISTRATIONS';




// COURSETYPES
export interface ICoursetypeState {
    coursetypes: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_COURSETYPES = 'START_GET_COURSETYPES';
export const SUCCESS_GET_COURSETYPES = 'SUCCESS_GET_COURSETYPES';
export const ERROR_GET_COURSETYPES = 'ERROR_GET_COURSETYPES';

// COURSES
export interface ICourseState {
    courses: any,
    coursesProgress: {
        course_id: number,
        value: number,
        total_lessons?: number,
        total_lessons_viewed?: number
    }[],
    homeCourses: any,
    feedCourses: any,
    course_selected: null | any,
    reload: boolean,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_COURSES = 'START_GET_COURSES';
export const SUCCESS_GET_COURSES = 'SUCCESS_GET_COURSES';
export const ERROR_GET_COURSES = 'ERROR_GET_COURSES';
export const START_GET_FILTERED_COURSES = 'START_GET_FILTERED_COURSES';
export const SUCCESS_GET_FILTERED_COURSES = 'SUCCESS_GET_FILTERED_COURSES';
export const ERROR_GET_FILTERED_COURSES = 'ERROR_GET_FILTERED_COURSES';
export const START_GET_COURSES_BY_BRAND = 'START_GET_COURSES_BY_BRAND';
export const SUCCESS_GET_COURSES_BY_BRAND = 'SUCCESS_GET_COURSES_BY_BRAND';
export const ERROR_GET_COURSES_BY_BRAND = 'ERROR_GET_COURSES_BY_BRAND';
export const START_GET_HOME_COURSES = 'START_GET_HOME_COURSES';
export const SUCCESS_GET_HOME_COURSES = 'SUCCESS_GET_HOME_COURSES';
export const ERROR_GET_HOME_COURSES = 'ERROR_GET_HOME_COURSES';
export const START_STORE_COURSE = 'START_STORE_COURSE';
export const SUCCESS_STORE_COURSE = 'SUCCESS_STORE_COURSE';
export const ERROR_STORE_COURSE = 'ERROR_STORE_COURSE';
export const START_SHOW_COURSE = 'START_SHOW_COURSE';
export const SUCCESS_SHOW_COURSE = 'SUCCESS_SHOW_COURSE';
export const ERROR_SHOW_COURSE = 'ERROR_SHOW_COURSE';
export const START_SHOW_COURSE_BY_SLUG = 'START_SHOW_COURSE_BY_SLUG';
export const SUCCESS_SHOW_COURSE_BY_SLUG = 'SUCCESS_SHOW_COURSE_BY_SLUG';
export const ERROR_SHOW_COURSE_BY_SLUG = 'ERROR_SHOW_COURSE_BY_SLUG';
export const START_DELETE_COURSE = 'START_DELETE_COURSE';
export const SUCCESS_DELETE_COURSE = 'SUCCESS_DELETE_COURSE';
export const ERROR_DELETE_COURSE = 'ERROR_DELETE_COURSE';
export const START_COURSE_LESSON_STEP = 'START_COURSE_LESSON_STEP';
export const SUCCESS_COURSE_LESSON_STEP = 'SUCCESS_COURSE_LESSON_STEP';
export const ERROR_COURSE_LESSON_STEP = 'ERROR_COURSE_LESSON_STEP';
export const START_FINISH_COURSE = 'START_FINISH_COURSE';
export const SUCCESS_FINISH_COURSE = 'SUCCESS_FINISH_COURSE';
export const ERROR_FINISH_COURSE = 'ERROR_FINISH_COURSE';
export const START_ASSIGN_HIGHLIGHT_COURSE = 'START_ASSIGN_HIGHLIGHT_COURSE';
export const SUCCESS_ASSIGN_HIGHLIGHT_COURSE = 'SUCCESS_ASSIGN_HIGHLIGHT_COURSE';
export const ERROR_ASSIGN_HIGHLIGHT_COURSE = 'ERROR_ASSIGN_HIGHLIGHT_COURSE';
export const START_TOGGLE_FORM_COURSE = 'START_TOGGLE_FORM_COURSE';
export const SUCCESS_TOGGLE_FORM_COURSE = 'SUCCESS_TOGGLE_FORM_COURSE';
export const START_ASSIGN_TAG_COURSE = 'START_ASSIGN_TAG_COURSE';
export const SUCCESS_ASSIGN_TAG_COURSE = 'SUCCESS_ASSIGN_TAG_COURSE';
export const ERROR_ASSIGN_TAG_COURSE = 'ERROR_ASSIGN_TAG_COURSE';
export const START_DELETE_TAG_COURSE = 'START_DELETE_TAG_COURSE';
export const SUCCESS_DELETE_TAG_COURSE = 'SUCCESS_DELETE_TAG_COURSE';
export const ERROR_DELETE_TAG_COURSE = 'ERROR_DELETE_TAG_COURSE';
export const START_MASS_ASSIGNMENT_COURSES = 'START_MASS_ASSIGNMENT_COURSES';
export const SUCCESS_MASS_ASSIGNMENT_COURSES = 'SUCCESS_MASS_ASSIGNMENT_COURSES';
export const ERROR_MASS_ASSIGNMENT_COURSES = 'ERROR_MASS_ASSIGNMENT_COURSES';

// LESSONS
export interface ILessonState {
    lessons: any,
    lesson_selected: null | any,
    confirm: boolean,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_LESSONS = 'START_GET_LESSONS';
export const SUCCESS_GET_LESSONS = 'SUCCESS_GET_LESSONS';
export const ERROR_GET_LESSONS = 'ERROR_GET_LESSONS';
export const START_CREATE_LESSON = 'START_CREATE_LESSON';
export const SUCCESS_CREATE_LESSON = 'SUCCESS_CREATE_LESSON';
export const ERROR_CREATE_LESSON = 'ERROR_CREATE_LESSON';
export const START_STORE_LESSONMULTIMEDIA = 'START_STORE_LESSONMULTIMEDIA';
export const SUCCESS_STORE_LESSONMULTIMEDIA = 'SUCCESS_STORE_LESSONMULTIMEDIA';
export const ERROR_STORE_LESSONMULTIMEDIA = 'ERROR_STORE_LESSONMULTIMEDIA';
export const START_SHOW_LESSON = 'START_SHOW_LESSON';
export const SUCCESS_SHOW_LESSON = 'SUCCESS_SHOW_LESSON';
export const ERROR_SHOW_LESSON = 'ERROR_SHOW_LESSON';
export const START_DELETE_LESSON = 'START_DELETE_LESSON';
export const SUCCESS_DELETE_LESSON = 'SUCCESS_DELETE_LESSON';
export const ERROR_DELETE_LESSON = 'ERROR_DELETE_LESSON';
export const START_DELETE_LESSONMULTIMEDIA = 'START_DELETE_LESSONMULTIMEDIA';
export const SUCCESS_DELETE_LESSONMULTIMEDIA = 'SUCCESS_DELETE_LESSONMULTIMEDIA';
export const ERROR_DELETE_LESSONMULTIMEDIA = 'ERROR_DELETE_LESSONMULTIMEDIA';
export const START_TOGGLE_FORM_LESSON = 'START_TOGGLE_FORM_LESSON';
export const SUCCESS_TOGGLE_FORM_LESSON = 'SUCCESS_TOGGLE_FORM_LESSON';
export const START_SET_VIEW_LESSON = 'START_SET_VIEW_LESSON';
export const SUCCESS_SET_VIEW_LESSON = 'SUCCESS_SET_VIEW_LESSON';

// RESOURCES
export interface IResourceState {
    reload: boolean,
    resources: any,
    feedResources: any,
    resourceSelected: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showEditForm: boolean,
    showCreateForm: boolean,
    showViewForm: boolean
}

export const START_GET_RESOURCES = 'START_GET_RESOURCES';
export const SUCCESS_GET_RESOURCES = 'SUCCESS_GET_RESOURCES';
export const ERROR_GET_RESOURCES = 'ERROR_GET_RESOURCES';
export const START_SHOW_RESOURCE = 'START_SHOW_RESOURCE';
export const SUCCESS_SHOW_RESOURCE = 'SUCCESS_SHOW_RESOURCE';
export const ERROR_SHOW_RESOURCE = 'ERROR_SHOW_RESOURCE';
export const START_VIEW_RESOURCE = 'START_VIEW_RESOURCE';
export const SUCCESS_VIEW_RESOURCE = 'SUCCESS_VIEW_RESOURCE';
export const ERROR_VIEW_RESOURCE = 'ERROR_VIEW_RESOURCE';
export const START_STORE_RESOURCE = 'START_STORE_RESOURCE';
export const SUCCESS_STORE_RESOURCE = 'SUCCESS_STORE_RESOURCE';
export const ERROR_STORE_RESOURCE = 'ERROR_STORE_RESOURCE';
export const START_UPDATE_RESOURCE = 'START_UPDATE_RESOURCE';
export const SUCCESS_UPDATE_RESOURCE = 'SUCCESS_UPDATE_RESOURCE';
export const ERROR_UPDATE_RESOURCE = 'ERROR_UPDATE_RESOURCE';
export const START_DELETE_RESOURCE = 'START_DELETE_RESOURCE';
export const SUCCESS_DELETE_RESOURCE = 'SUCCESS_DELETE_RESOURCE';
export const ERROR_DELETE_RESOURCE = 'ERROR_DELETE_RESOURCE';
export const START_TOGGLE_FORM_EDIT_RESOURCES = 'START_TOGGLE_FORM_EDIT_RESOURCES';
export const SUCCESS_TOGGLE_FORM_EDIT_RESOURCES = 'SUCCESS_TOGGLE_FORM_EDIT_RESOURCES';
export const START_TOGGLE_FORM_CREATE_RESOURCES = 'START_TOGGLE_FORM_CREATE_RESOURCES';
export const SUCCESS_TOGGLE_FORM_CREATE_RESOURCES = 'SUCCESS_TOGGLE_FORM_CREATE_RESOURCES';
export const START_TOGGLE_FORM_VIEW_RESOURCES = 'START_TOGGLE_FORM_VIEW_RESOURCES';
export const SUCCESS_TOGGLE_FORM_VIEW_RESOURCES = 'SUCCESS_TOGGLE_FORM_VIEW_RESOURCES';
export const START_FILTER_RESOURCES = 'START_FILTER_RESOURCES';
export const SUCCESS_FILTER_RESOURCES = 'SUCCESS_FILTER_RESOURCES';
export const ERROR_FILTER_RESOURCES = 'ERROR_FILTER_RESOURCES';
export const START_ASSIGN_TAG_RESOURCE = 'START_ASSIGN_TAG_RESOURCE';
export const SUCCESS_ASSIGN_TAG_RESOURCE = 'SUCCESS_ASSIGN_TAG_RESOURCE';
export const ERROR_ASSIGN_TAG_RESOURCE = 'ERROR_ASSIGN_TAG_RESOURCE';
export const START_DELETE_TAG_RESOURCE = 'START_DELETE_TAG_RESOURCE';
export const SUCCESS_DELETE_TAG_RESOURCE = 'SUCCESS_DELETE_TAG_RESOURCE';
export const ERROR_DELETE_TAG_RESOURCE = 'ERROR_DELETE_TAG_RESOURCE';
export const START_ASSIGN_HIGHLIGHT_RESOURCE = 'START_ASSIGN_HIGHLIGHT_RESOURCE';
export const SUCCESS_ASSIGN_HIGHLIGHT_RESOURCE = 'SUCCESS_ASSIGN_HIGHLIGHT_RESOURCE';
export const ERROR_ASSIGN_HIGHLIGHT_RESOURCE = 'ERROR_ASSIGN_HIGHLIGHT_RESOURCE';
export const START_GET_HOME_RESOURCES = 'START_GET_HOME_RESOURCES';
export const SUCCESS_GET_HOME_RESOURCES = 'SUCCESS_GET_HOME_RESOURCES';
export const ERROR_GET_HOME_RESOURCES = 'ERROR_GET_HOME_RESOURCES';
export const START_MASS_ASSIGNMENT_RESOURCES = 'START_MASS_ASSIGNMENT_RESOURCES';
export const SUCCESS_MASS_ASSIGNMENT_RESOURCES = 'SUCCESS_MASS_ASSIGNMENT_RESOURCES';
export const ERROR_MASS_ASSIGNMENT_RESOURCES = 'ERROR_MASS_ASSIGNMENT_RESOURCES';


// RESOURCESCATEGORIES
export interface IResourcecategoryState {
    resourcecategories: any,
    resourcecategoriesOptions: any,
    resourcecategorySelected: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_RESOURCECATEGORIES = 'START_GET_RESOURCECATEGORIES';
export const SUCCESS_GET_RESOURCECATEGORIES = 'SUCCESS_GET_RESOURCECATEGORIES';
export const ERROR_GET_RESOURCECATEGORIES = 'ERROR_GET_RESOURCECATEGORIES';
export const START_GET_RESOURCECATEGORIES_OPTIONS = 'START_GET_RESOURCECATEGORIES_OPTIONS';
export const SUCCESS_GET_RESOURCECATEGORIES_OPTIONS = 'SUCCESS_GET_RESOURCECATEGORIES_OPTIONS';
export const ERROR_GET_RESOURCECATEGORIES_OPTIONS = 'ERROR_GET_RESOURCECATEGORIES_OPTIONS';
export const START_SHOW_RESOURCECATEGORY = 'START_SHOW_RESOURCECATEGORY';
export const SUCCESS_SHOW_RESOURCECATEGORY = 'SUCCESS_SHOW_RESOURCECATEGORY';
export const ERROR_SHOW_RESOURCECATEGORY = 'ERROR_SHOW_RESOURCECATEGORY';
export const START_STORE_RESOURCECATEGORY = 'START_STORE_RESOURCECATEGORY';
export const SUCCESS_STORE_RESOURCECATEGORY = 'SUCCESS_STORE_RESOURCECATEGORY';
export const ERROR_STORE_RESOURCECATEGORY = 'ERROR_STORE_RESOURCECATEGORY';
export const START_UPDATE_RESOURCECATEGORY = 'START_UPDATE_RESOURCECATEGORY';
export const SUCCESS_UPDATE_RESOURCECATEGORY = 'SUCCESS_UPDATE_RESOURCECATEGORY';
export const ERROR_UPDATE_RESOURCECATEGORY = 'ERROR_UPDATE_RESOURCECATEGORY';
export const START_DELETE_RESOURCECATEGORY = 'START_DELETE_RESOURCECATEGORY';
export const SUCCESS_DELETE_RESOURCECATEGORY = 'SUCCESS_DELETE_RESOURCECATEGORY';
export const ERROR_DELETE_RESOURCECATEGORY = 'ERROR_DELETE_RESOURCECATEGORY';
export const START_TOGGLE_FORM_RESOURCECATEGORIES = 'START_TOGGLE_FORM_RESOURCECATEGORIES';
export const SUCCESS_TOGGLE_FORM_RESOURCECATEGORIES = 'SUCCESS_TOGGLE_FORM_RESOURCECATEGORIES';

// RESOURCETYPES
export interface IResourcetypeState {
    resourcetypes: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_RESOURCETYPES = 'START_GET_RESOURCETYPES';
export const SUCCESS_GET_RESOURCETYPES = 'SUCCESS_GET_RESOURCETYPES';
export const ERROR_GET_RESOURCETYPES = 'ERROR_GET_RESOURCETYPES';

// TERMS
export interface ITermState {
    terms: any,
    accepted: boolean,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_TERMS = 'START_GET_TERMS';
export const SUCCESS_GET_TERMS = 'SUCCESS_GET_TERMS';
export const ERROR_GET_TERMS = 'ERROR_GET_TERMS';
export const START_ACCEPT_TERMS = 'START_ACCEPT_TERMS';
export const SUCCESS_ACCEPT_TERMS = 'SUCCESS_ACCEPT_TERMS';
export const ERROR_ACCEPT_TERMS = 'ERROR_ACCEPT_TERMS';

// SLIDERS
export interface ISliderState {
    reload: boolean,
    sliders: any,
    homeLinks: any,
    internalbanner: any,
    slider_selected: null | any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_SLIDERS = 'START_GET_SLIDERS';
export const SUCCESS_GET_SLIDERS = 'SUCCESS_GET_SLIDERS';
export const ERROR_GET_SLIDERS = 'ERROR_GET_SLIDERS';
export const START_GET_FILTERED_SLIDERS = 'START_GET_FILTERED_SLIDERS';
export const SUCCESS_GET_FILTERED_SLIDERS = 'SUCCESS_GET_FILTERED_SLIDERS';
export const ERROR_GET_FILTERED_SLIDERS = 'ERROR_GET_FILTERED_SLIDERS';
export const START_GET_INDEX_SLIDERS = 'START_GET_INDEX_SLIDERS';
export const SUCCESS_GET_INDEX_SLIDERS = 'SUCCESS_GET_INDEX_SLIDERS';
export const ERROR_GET_INDEX_SLIDERS = 'ERROR_GET_INDEX_SLIDERS';
export const START_GET_INTERNAL_BANNER = 'START_GET_INTERNAL_BANNER';
export const SUCCESS_GET_INTERNAL_BANNER = 'SUCCESS_GET_INTERNAL_BANNER';
export const ERROR_GET_INTERNAL_BANNER = 'ERROR_GET_INTERNAL_BANNER';
export const START_CREATE_SLIDER = 'START_CREATE_SLIDER';
export const SUCCESS_CREATE_SLIDER = 'SUCCESS_CREATE_SLIDER';
export const ERROR_CREATE_SLIDER = 'ERROR_CREATE_SLIDER';
export const START_SHOW_SLIDER = 'START_SHOW_SLIDER';
export const SUCCESS_SHOW_SLIDER = 'SUCCESS_SHOW_SLIDER';
export const ERROR_SHOW_SLIDER = 'ERROR_SHOW_SLIDER';
export const START_DELETE_SLIDER = 'START_DELETE_SLIDER';
export const SUCCESS_DELETE_SLIDER = 'SUCCESS_DELETE_SLIDER';
export const ERROR_DELETE_SLIDER = 'ERROR_DELETE_SLIDER';
export const START_SLIDER_SEND_EMAIL = 'START_SLIDER_SEND_EMAIL';
export const SUCCESS_SLIDER_SEND_EMAIL = 'SUCCESS_SLIDER_SEND_EMAIL';
export const ERROR_SLIDER_SEND_EMAIL = 'ERROR_SLIDER_SEND_EMAIL';
export const START_TOGGLE_FORM_SLIDER = 'START_TOGGLE_FORM_SLIDER';
export const SUCCESS_TOGGLE_FORM_SLIDER = 'SUCCESS_TOGGLE_FORM_SLIDER';

// MODAL GLOBAL
export interface IModalState {
    modal: any,
    actionButton: any,
    showModal: boolean
}

export const START_SHOW_MODAL = 'START_SHOW_MODAL';
export const SUCCESS_SHOW_MODAL = 'SUCCESS_SHOW_MODAL';
export const ERROR_SHOW_MODAL = 'ERROR_SHOW_MODAL';
export const START_HIDE_MODAL = 'START_HIDE_MODAL';
export const SUCCESS_HIDE_MODAL = 'SUCCESS_HIDE_MODAL';
export const ERROR_HIDE_MODAL = 'ERROR_HIDE_MODAL';
export const START_ASSIGN_ACTION_MODAL = 'START_ASSIGN_ACTION_MODAL';
export const SUCCESS_ASSIGN_ACTION_MODAL = 'SUCCESS_ASSIGN_ACTION_MODAL';
export const ERROR_ASSIGN_ACTION_MODAL = 'ERROR_ASSIGN_ACTION_MODAL';

// EVENTS
export interface IEventState {
    events: any,
    calendarEvents: any,
    event_selected: null | any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean,
    reload: boolean
}

export const START_GET_EVENTS = 'START_GET_EVENTS';
export const SUCCESS_GET_EVENTS = 'SUCCESS_GET_EVENTS';
export const ERROR_GET_EVENTS = 'ERROR_GET_EVENTS';
export const START_GET_FILTERED_EVENTS = 'START_GET_FILTERED_EVENTS';
export const SUCCESS_GET_FILTERED_EVENTS = 'SUCCESS_GET_FILTERED_EVENTS';
export const ERROR_GET_FILTERED_EVENTS = 'ERROR_GET_FILTERED_EVENTS';
export const START_GET_EVENTS_BY_BRAND = 'START_GET_EVENTS_BY_BRAND';
export const SUCCESS_GET_EVENTS_BY_BRAND = 'SUCCESS_GET_EVENTS_BY_BRAND';
export const ERROR_GET_EVENTS_BY_BRAND = 'ERROR_GET_EVENTS_BY_BRAND';
export const START_GET_CALENDAR_EVENTS = 'START_GET_CALENDAR_EVENTS';
export const SUCCESS_GET_CALENDAR_EVENTS = 'SUCCESS_GET_CALENDAR_EVENTS';
export const ERROR_GET_CALENDAR_EVENTS = 'ERROR_GET_CALENDAR_EVENTS';
export const START_CREATE_EVENT = 'START_CREATE_EVENT';
export const SUCCESS_CREATE_EVENT = 'SUCCESS_CREATE_EVENT';
export const ERROR_CREATE_EVENT = 'ERROR_CREATE_EVENT';
export const START_SHOW_EVENT = 'START_SHOW_EVENT';
export const SUCCESS_SHOW_EVENT = 'SUCCESS_SHOW_EVENT';
export const ERROR_SHOW_EVENT = 'ERROR_SHOW_EVENT';
export const START_DELETE_EVENT = 'START_DELETE_EVENT';
export const SUCCESS_DELETE_EVENT = 'SUCCESS_DELETE_EVENT';
export const ERROR_DELETE_EVENT = 'ERROR_DELETE_EVENT';
export const START_TOGGLE_FORM_EVENT = 'START_TOGGLE_FORM_EVENT';
export const SUCCESS_TOGGLE_FORM_EVENT = 'SUCCESS_TOGGLE_FORM_EVENT';

// GLOBAL
export interface IGlobalState {
    globalFamilyId: null | number,
    globalBrandId: null | number,
    globalLineId: null | number,
    globalLanguageId: null | number,
    globalCountryId: null | number
    globalCountriesId: number[],
    globalRoleId: null | number,
    globalOrderById: number,
    globalTag: null | number,
    globalSearch: {
        courses: any,
        events: any,
        offers: any,
        novelties: any,
        resources: any,
        seminars: any,
        count: number
    },
    loadingSearch: boolean
}

export const START_SET_GLOBAL_FAMILY = 'START_SET_GLOBAL_FAMILY';
export const SUCCESS_SET_GLOBAL_FAMILY = 'SUCCESS_SET_GLOBAL_FAMILY';
export const START_SET_GLOBAL_BRAND = 'START_SET_GLOBAL_BRAND';
export const SUCCESS_SET_GLOBAL_BRAND = 'SUCCESS_SET_GLOBAL_BRAND';
export const START_SET_GLOBAL_LINE = 'START_SET_GLOBAL_LINE';
export const SUCCESS_SET_GLOBAL_LINE = 'SUCCESS_SET_GLOBAL_LINE';
export const START_SET_GLOBAL_LANGUAGE = 'START_SET_GLOBAL_LANGUAGE';
export const SUCCESS_SET_GLOBAL_LANGUAGE = 'SUCCESS_SET_GLOBAL_LANGUAGE';
export const START_SET_GLOBAL_COUNTRY = 'START_SET_GLOBAL_COUNTRY';
export const SUCCESS_SET_GLOBAL_COUNTRY = 'SUCCESS_SET_GLOBAL_COUNTRY';
export const START_SET_GLOBAL_COUNTRIES = 'START_SET_GLOBAL_COUNTRIES';
export const SUCCESS_SET_GLOBAL_COUNTRIES = 'SUCCESS_SET_GLOBAL_COUNTRIES';
export const START_SET_GLOBAL_ROLE = 'START_SET_GLOBAL_ROLE';
export const SUCCESS_SET_GLOBAL_ROLE = 'SUCCESS_SET_GLOBAL_ROLE';
export const START_SET_GLOBAL_ORDERBY = 'START_SET_GLOBAL_ORDERBY';
export const SUCCESS_SET_GLOBAL_ORDERBY = 'SUCCESS_SET_GLOBAL_ORDERBY';
export const START_SET_GLOBAL_TAG = 'START_SET_GLOBAL_TAG';
export const SUCCESS_SET_GLOBAL_TAG = 'SUCCESS_SET_GLOBAL_TAG';
export const START_CLEAR_STORAGE = 'START_CLEAR_STORAGE';
export const SUCCESS_CLEAR_STORAGE = 'SUCCESS_CLEAR_STORAGE';
export const ERROR_CLEAR_STORAGE = 'ERROR_CLEAR_STORAGE';
export const START_GLOBAL_SEARCH_QUERY = 'START_GLOBAL_SEARCH_QUERY';
export const SUCCESS_GLOBAL_SEARCH_QUERY = 'SUCCESS_GLOBAL_SEARCH_QUERY';
export const ERROR_GLOBAL_SEARCH_QUERY = 'ERROR_GLOBAL_SEARCH_QUERY';

// OFFERS
export interface IOfferState {
    offers: any,
    offer_selected: null | any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean,
    reload: boolean
}

export const START_GET_OFFERS = 'START_GET_OFFERS';
export const SUCCESS_GET_OFFERS = 'SUCCESS_GET_OFFERS';
export const ERROR_GET_OFFERS = 'ERROR_GET_OFFERS';
export const START_GET_CLIENT_OFFERS = 'START_GET_CLIENT_OFFERS';
export const SUCCESS_GET_CLIENT_OFFERS = 'SUCCESS_GET_CLIENT_OFFERS';
export const ERROR_GET_CLIENT_OFFERS = 'ERROR_GET_CLIENT_OFFERS';
export const START_CREATE_OFFER = 'START_CREATE_OFFER';
export const SUCCESS_CREATE_OFFER = 'SUCCESS_CREATE_OFFER';
export const ERROR_CREATE_OFFER = 'ERROR_CREATE_OFFER';
export const START_UPDATE_OFFER = 'START_UPDATE_OFFER';
export const SUCCESS_UPDATE_OFFER = 'SUCCESS_UPDATE_OFFER';
export const ERROR_UPDATE_OFFER = 'ERROR_UPDATE_OFFER';
export const START_SHOW_OFFER = 'START_SHOW_OFFER';
export const SUCCESS_SHOW_OFFER = 'SUCCESS_SHOW_OFFER';
export const ERROR_SHOW_OFFER = 'ERROR_SHOW_OFFER';
export const START_DELETE_OFFER = 'START_DELETE_OFFER';
export const SUCCESS_DELETE_OFFER = 'SUCCESS_DELETE_OFFER';
export const ERROR_DELETE_OFFER = 'ERROR_DELETE_OFFER';
export const START_ASSIGN_HIGHLIGHT_OFFER = 'START_ASSIGN_HIGHLIGHT_OFFER';
export const SUCCESS_ASSIGN_HIGHLIGHT_OFFER = 'SUCCESS_ASSIGN_HIGHLIGHT_OFFER';
export const ERROR_ASSIGN_HIGHLIGHT_OFFER = 'ERROR_ASSIGN_HIGHLIGHT_OFFER';
export const START_TOGGLE_FORM_OFFER = 'START_TOGGLE_FORM_OFFER';
export const SUCCESS_TOGGLE_FORM_OFFER = 'SUCCESS_TOGGLE_FORM_OFFER';
export const START_ASSIGN_TAG_OFFER = 'START_ASSIGN_TAG_OFFER';
export const SUCCESS_ASSIGN_TAG_OFFER = 'SUCCESS_ASSIGN_TAG_OFFER';
export const ERROR_ASSIGN_TAG_OFFER = 'ERROR_ASSIGN_TAG_OFFER';
export const START_DELETE_TAG_OFFER = 'START_DELETE_TAG_OFFER';
export const SUCCESS_DELETE_TAG_OFFER = 'SUCCESS_DELETE_TAG_OFFER';
export const ERROR_DELETE_TAG_OFFER = 'ERROR_DELETE_TAG_OFFER';
export const START_COUNTER_VIEWS_OFFER = 'START_COUNTER_VIEWS_OFFER';
export const SUCCESS_COUNTER_VIEWS_OFFER = 'SUCCESS_COUNTER_VIEWS_OFFER';
export const ERROR_COUNTER_VIEWS_OFFER = 'ERROR_COUNTER_VIEWS_OFFER';

// LOGS
export interface ILogState {
    logs: any,
    logSelected: null | any,
    commitAPI: string,
    commitFront: string,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_FILTER_LOGS = 'START_FILTER_LOGS';
export const SUCCESS_FILTER_LOGS = 'SUCCESS_FILTER_LOGS';
export const ERROR_FILTER_LOGS = 'ERROR_FILTER_LOGS';
export const START_GET_CURRENT_COMMITS = 'START_GET_CURRENT_COMMITS';
export const SUCCESS_GET_CURRENT_COMMITS = 'SUCCESS_GET_CURRENT_COMMITS';
export const ERROR_GET_CURRENT_COMMITS = 'ERROR_GET_CURRENT_COMMITS';


// TAGS
export interface ITagState {
    tags: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_TAGS = 'START_GET_TAGS';
export const SUCCESS_GET_TAGS = 'SUCCESS_GET_TAGS';
export const ERROR_GET_TAGS = 'ERROR_GET_TAGS';

// NOVELTIES / NEWS
export interface INoveltyState {
    reload: boolean,
    novelties: any,
    news: any,
    noveltySelected: null | any,
    newsSelected: null | any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_NOVELTIES = 'START_GET_NOVELTIES';
export const SUCCESS_GET_NOVELTIES = 'SUCCESS_GET_NOVELTIES';
export const ERROR_GET_NOVELTIES = 'ERROR_GET_NOVELTIES';
export const START_GET_CLIENT_NOVELTIES = 'START_GET_CLIENT_NOVELTIES';
export const SUCCESS_GET_CLIENT_NOVELTIES = 'SUCCESS_GET_CLIENT_NOVELTIES';
export const ERROR_GET_CLIENT_NOVELTIES = 'ERROR_GET_CLIENT_NOVELTIES';
export const START_CREATE_NOVELTY = 'START_CREATE_NOVELTY';
export const SUCCESS_CREATE_NOVELTY = 'SUCCESS_CREATE_NOVELTY';
export const ERROR_CREATE_NOVELTY = 'ERROR_CREATE_NOVELTY';
export const START_UPDATE_NOVELTY = 'START_UPDATE_NOVELTY';
export const SUCCESS_UPDATE_NOVELTY = 'SUCCESS_UPDATE_NOVELTY';
export const ERROR_UPDATE_NOVELTY = 'ERROR_UPDATE_NOVELTY';
export const START_SHOW_NOVELTY = 'START_SHOW_NOVELTY';
export const SUCCESS_SHOW_NOVELTY = 'SUCCESS_SHOW_NOVELTY';
export const ERROR_SHOW_NOVELTY = 'ERROR_SHOW_NOVELTY';
export const START_SHOW_NOVELTY_BY_SLUG = 'START_SHOW_NOVELTY_BY_SLUG';
export const SUCCESS_SHOW_NOVELTY_BY_SLUG = 'SUCCESS_SHOW_NOVELTY_BY_SLUG';
export const ERROR_SHOW_NOVELTY_BY_SLUG = 'ERROR_SHOW_NOVELTY_BY_SLUG';
export const START_DELETE_NOVELTY = 'START_DELETE_NOVELTY';
export const SUCCESS_DELETE_NOVELTY = 'SUCCESS_DELETE_NOVELTY';
export const ERROR_DELETE_NOVELTY = 'ERROR_DELETE_NOVELTY';
export const START_ASSIGN_HIGHLIGHT_NOVELTY = 'START_ASSIGN_HIGHLIGHT_NOVELTY';
export const SUCCESS_ASSIGN_HIGHLIGHT_NOVELTY = 'SUCCESS_ASSIGN_HIGHLIGHT_NOVELTY';
export const ERROR_ASSIGN_HIGHLIGHT_NOVELTY = 'ERROR_ASSIGN_HIGHLIGHT_NOVELTY';
export const START_TOGGLE_FORM_NOVELTY = 'START_TOGGLE_FORM_NOVELTY';
export const SUCCESS_TOGGLE_FORM_NOVELTY = 'SUCCESS_TOGGLE_FORM_NOVELTY';
export const START_ASSIGN_TAG_NOVELTY = 'START_ASSIGN_TAG_NOVELTY';
export const SUCCESS_ASSIGN_TAG_NOVELTY = 'SUCCESS_ASSIGN_TAG_NOVELTY';
export const ERROR_ASSIGN_TAG_NOVELTY = 'ERROR_ASSIGN_TAG_NOVELTY';
export const START_DELETE_TAG_NOVELTY = 'START_DELETE_TAG_NOVELTY';
export const SUCCESS_DELETE_TAG_NOVELTY = 'SUCCESS_DELETE_TAG_NOVELTY';
export const ERROR_DELETE_TAG_NOVELTY = 'ERROR_DELETE_TAG_NOVELTY';


// USER SETTINGS
export interface IUsersettingState {
    usersettings: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_STORE_USERSETTINGS = 'START_STORE_USERSETTINGS';
export const SUCCESS_STORE_USERSETTINGS = 'SUCCESS_STORE_USERSETTINGS';
export const ERROR_STORE_USERSETTINGS = 'ERROR_STORE_USERSETTINGS';
export const START_KTB_VIDEO_USERSETTINGS = 'START_KTB_VIDEO_USERSETTINGS';
export const SUCCESS_KTB_VIDEO_USERSETTINGS = 'SUCCESS_KTB_VIDEO_USERSETTINGS';
export const ERROR_KTB_VIDEO_USERSETTINGS = 'ERROR_KTB_VIDEO_USERSETTINGS';
export const START_FIRST_START_VIDEO_USERSETTINGS = 'START_FIRST_START_VIDEO_USERSETTINGS';
export const SUCCESS_FIRST_START_VIDEO_USERSETTINGS = 'SUCCESS_FIRST_START_VIDEO_USERSETTINGS';
export const ERROR_FIRST_START_VIDEO_USERSETTINGS = 'ERROR_FIRST_START_VIDEO_USERSETTINGS';

// MENUS
export interface IMenuState {
    menus: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_MENUS = 'START_GET_MENUS';
export const SUCCESS_GET_MENUS = 'SUCCESS_GET_MENUS';
export const ERROR_GET_MENUS = 'ERROR_GET_MENUS';
export const START_ENABLE_DISABLE_MENU_USER = 'START_ENABLE_DISABLE_MENU_USER';
export const SUCCESS_ENABLE_DISABLE_MENU_USER = 'SUCCESS_ENABLE_DISABLE_MENU_USER';
export const ERROR_ENABLE_DISABLE_MENU_USER = 'ERROR_ENABLE_DISABLE_MENU_USER';

// HELP
export interface IHelpState {
    msg: null | string,
    error: boolean,
    loading: boolean,
    reload: boolean
}
export const START_SEND_CONTACT_US = 'START_SEND_CONTACT_US';
export const SUCCESS_SEND_CONTACT_US = 'SUCCESS_SEND_CONTACT_US';
export const ERROR_SEND_CONTACT_US = 'ERROR_SEND_CONTACT_US';

// FAMILIES
export interface IFamilyState {
    families: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_FAMILIES = 'START_GET_FAMILIES';
export const SUCCESS_GET_FAMILIES = 'SUCCESS_GET_FAMILIES';
export const ERROR_GET_FAMILIES = 'ERROR_GET_FAMILIES';


// RESOURCES
export interface ITestimonialState {
    testimonials: any,
    testimonialSelected: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_TESTIMONIALS = 'START_GET_TESTIMONIALS';
export const SUCCESS_GET_TESTIMONIALS = 'SUCCESS_GET_TESTIMONIALS';
export const ERROR_GET_TESTIMONIALS = 'ERROR_GET_TESTIMONIALS';
export const START_SHOW_TESTIMONIAL = 'START_SHOW_TESTIMONIAL';
export const SUCCESS_SHOW_TESTIMONIAL = 'SUCCESS_SHOW_TESTIMONIAL';
export const ERROR_SHOW_TESTIMONIAL = 'ERROR_SHOW_TESTIMONIAL';
export const START_STORE_TESTIMONIAL = 'START_STORE_TESTIMONIAL';
export const SUCCESS_STORE_TESTIMONIAL = 'SUCCESS_STORE_TESTIMONIAL';
export const ERROR_STORE_TESTIMONIAL = 'ERROR_STORE_TESTIMONIAL';
export const START_UPDATE_TESTIMONIAL = 'START_UPDATE_TESTIMONIAL';
export const SUCCESS_UPDATE_TESTIMONIAL = 'SUCCESS_UPDATE_TESTIMONIAL';
export const ERROR_UPDATE_TESTIMONIAL = 'ERROR_UPDATE_TESTIMONIAL';
export const START_DELETE_TESTIMONIAL = 'START_DELETE_TESTIMONIAL';
export const SUCCESS_DELETE_TESTIMONIAL = 'SUCCESS_DELETE_TESTIMONIAL';
export const ERROR_DELETE_TESTIMONIAL = 'ERROR_DELETE_TESTIMONIAL';
export const START_TOGGLE_FORM_TESTIMONIALS = 'START_TOGGLE_FORM_TESTIMONIALS';
export const SUCCESS_TOGGLE_FORM_TESTIMONIALS = 'SUCCESS_TOGGLE_FORM_TESTIMONIALS';

// NOTIFICATIONS
export interface INotificationState {
    notifications: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_NOTIFICATIONS = 'START_GET_NOTIFICATIONS';
export const SUCCESS_GET_NOTIFICATIONS = 'SUCCESS_GET_NOTIFICATIONS';
export const ERROR_GET_NOTIFICATIONS = 'ERROR_GET_NOTIFICATIONS';
export const START_GET_UNREAD_NOTIFICATIONS = 'START_GET_UNREAD_NOTIFICATIONS';
export const SUCCESS_GET_UNREAD_NOTIFICATIONS = 'SUCCESS_GET_UNREAD_NOTIFICATIONS';
export const ERROR_GET_UNREAD_NOTIFICATIONS = 'ERROR_GET_UNREAD_NOTIFICATIONS';
export const START_SET_NOTIFICATION_AS_READ = 'START_SET_NOTIFICATION_AS_READ';
export const SUCCESS_SET_NOTIFICATION_AS_READ = 'SUCCESS_SET_NOTIFICATION_AS_READ';
export const ERROR_SET_NOTIFICATION_AS_READ = 'ERROR_SET_NOTIFICATION_AS_READ';
export const START_SET_ALL_NOTIFICATIONS_AS_READ = 'START_SET_ALL_NOTIFICATIONS_AS_READ';
export const SUCCESS_SET_ALL_NOTIFICATIONS_AS_READ = 'SUCCESS_SET_ALL_NOTIFICATIONS_AS_READ';
export const ERROR_SET_ALL_NOTIFICATIONS_AS_READ = 'ERROR_SET_ALL_NOTIFICATIONS_AS_READ';

// SEMINARS
export interface ISeminarState {
    seminars: any,
    seminarSelected: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    reload: boolean,
    showForm: boolean
}


export const START_FILTER_SEMINARS = 'START_FILTER_SEMINARS';
export const SUCCESS_FILTER_SEMINARS = 'SUCCESS_FILTER_SEMINARS';
export const ERROR_FILTER_SEMINARS = 'ERROR_FILTER_SEMINARS';
export const START_STORE_SEMINAR = 'START_STORE_SEMINAR';
export const SUCCESS_STORE_SEMINAR = 'SUCCESS_STORE_SEMINAR';
export const ERROR_STORE_SEMINAR = 'ERROR_STORE_SEMINAR';
export const START_UPDATE_SEMINAR = 'START_UPDATE_SEMINAR';
export const SUCCESS_UPDATE_SEMINAR = 'SUCCESS_UPDATE_SEMINAR';
export const ERROR_UPDATE_SEMINAR = 'ERROR_UPDATE_SEMINAR';
export const START_SHOW_SEMINAR = 'START_SHOW_SEMINAR';
export const SUCCESS_SHOW_SEMINAR = 'SUCCESS_SHOW_SEMINAR';
export const ERROR_SHOW_SEMINAR = 'ERROR_SHOW_SEMINAR';
export const START_DELETE_SEMINAR = 'START_DELETE_SEMINAR';
export const SUCCESS_DELETE_SEMINAR = 'SUCCESS_DELETE_SEMINAR';
export const ERROR_DELETE_SEMINAR = 'ERROR_DELETE_SEMINAR';
export const START_ASSIGN_HIGHLIGHT_SEMINAR = 'START_ASSIGN_HIGHLIGHT_SEMINAR';
export const SUCCESS_ASSIGN_HIGHLIGHT_SEMINAR = 'SUCCESS_ASSIGN_HIGHLIGHT_SEMINAR';
export const ERROR_ASSIGN_HIGHLIGHT_SEMINAR = 'ERROR_ASSIGN_HIGHLIGHT_SEMINAR';
export const START_TOGGLE_FORM_SEMINAR = 'START_TOGGLE_FORM_SEMINAR';
export const SUCCESS_TOGGLE_FORM_SEMINAR = 'SUCCESS_TOGGLE_FORM_SEMINAR';
export const SUCCESS_UPDATE_SUBSCRIBE_TO_SEMINARLESSON = 'SUCCESS_UPDATE_SUBSCRIBE_TO_SEMINARLESSON';

// SEMINARLESSONS
export interface ISeminarLessonState {
    seminarlessons: any,
    seminarlesson_selected: null | any,
    registeredUsers: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean
}

export const START_GET_SEMINARLESSONS = 'START_GET_SEMINARLESSONS';
export const SUCCESS_GET_SEMINARLESSONS = 'SUCCESS_GET_SEMINARLESSONS';
export const ERROR_GET_SEMINARLESSONS = 'ERROR_GET_SEMINARLESSONS';
export const START_CREATE_SEMINARLESSON = 'START_CREATE_SEMINARLESSON';
export const SUCCESS_CREATE_SEMINARLESSON = 'SUCCESS_CREATE_SEMINARLESSON';
export const ERROR_CREATE_SEMINARLESSON = 'ERROR_CREATE_SEMINARLESSON';
// export const START_STORE_LESSONMULTIMEDIA = 'START_STORE_LESSONMULTIMEDIA';
// export const SUCCESS_STORE_LESSONMULTIMEDIA = 'SUCCESS_STORE_LESSONMULTIMEDIA';
// export const ERROR_STORE_LESSONMULTIMEDIA = 'ERROR_STORE_LESSONMULTIMEDIA';
export const START_SHOW_SEMINARLESSON = 'START_SHOW_SEMINARLESSON';
export const SUCCESS_SHOW_SEMINARLESSON = 'SUCCESS_SHOW_SEMINARLESSON';
export const ERROR_SHOW_SEMINARLESSON = 'ERROR_SHOW_SEMINARLESSON';
export const START_DELETE_SEMINARLESSON = 'START_DELETE_SEMINARLESSON';
export const SUCCESS_DELETE_SEMINARLESSON = 'SUCCESS_DELETE_SEMINARLESSON';
export const ERROR_DELETE_SEMINARLESSON = 'ERROR_DELETE_SEMINARLESSON';
export const START_DELETE_SEMINARLESSONMULTIMEDIA = 'START_DELETE_SEMINARLESSONMULTIMEDIA';
export const SUCCESS_DELETE_SEMINARLESSONMULTIMEDIA = 'SUCCESS_DELETE_SEMINARLESSONMULTIMEDIA';
export const ERROR_DELETE_SEMINARLESSONMULTIMEDIA = 'ERROR_DELETE_SEMINARLESSONMULTIMEDIA';
export const START_SUBSCRIBE_TO_SEMINARLESSON = 'START_SUBSCRIBE_TO_SEMINARLESSON';
export const SUCCESS_SUBSCRIBE_TO_SEMINARLESSON = 'SUCCESS_SUBSCRIBE_TO_SEMINARLESSON';
export const ERROR_SUBSCRIBE_TO_SEMINARLESSON = 'ERROR_SUBSCRIBE_TO_SEMINARLESSON';
export const START_GET_SEMINARLESSON_REGISTEREDUSERS = 'START_GET_SEMINARLESSON_REGISTEREDUSERS';
export const SUCCESS_GET_SEMINARLESSON_REGISTEREDUSERS = 'SUCCESS_GET_SEMINARLESSON_REGISTEREDUSERS';
export const ERROR_GET_SEMINARLESSON_REGISTEREDUSERS = 'ERROR_GET_SEMINARLESSON_REGISTEREDUSERS';
export const START_TOGGLE_FORM_SEMINARLESSON = 'START_TOGGLE_FORM_SEMINARLESSON';
export const SUCCESS_TOGGLE_FORM_SEMINARLESSON = 'SUCCESS_TOGGLE_FORM_SEMINARLESSON';
export const START_SET_VIEW_SEMINARLESSON = 'START_SET_VIEW_SEMINARLESSON';
export const SUCCESS_SET_VIEW_SEMINARLESSON = 'SUCCESS_SET_VIEW_SEMINARLESSON';

// SEMINARTYPES
export interface ISeminartypeState {
    seminartypes: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_SEMINARTYPES = 'START_GET_SEMINARTYPES';
export const SUCCESS_GET_SEMINARTYPES = 'SUCCESS_GET_SEMINARTYPES';
export const ERROR_GET_SEMINARTYPES = 'ERROR_GET_SEMINARTYPES';

// FAVORITES
export interface IFavoriteState {
    favorites: {
        resources: any,
        courses: any,
        novelties: any,
        news: any,
        offers: any,
        catalogs: any,
        seminars: any
    },
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_FAVORITES = 'START_GET_FAVORITES';
export const SUCCESS_GET_FAVORITES = 'SUCCESS_GET_FAVORITES';
export const ERROR_GET_FAVORITES = 'ERROR_GET_FAVORITES';
export const START_SET_FAVORITE = 'START_SET_FAVORITE';
export const SUCCESS_SET_FAVORITE = 'SUCCESS_SET_FAVORITE';
export const ERROR_SET_FAVORITE = 'ERROR_SET_FAVORITE';
export const START_UNSET_FAVORITE = 'START_UNSET_FAVORITE';
export const SUCCESS_UNSET_FAVORITE = 'SUCCESS_UNSET_FAVORITE';
export const ERROR_UNSET_FAVORITE = 'ERROR_UNSET_FAVORITE';


// INTEGRATIONS
export interface IIntegrationState {
    integrations: any,
    integrationSelected: any,
    msg: null | string,
    error: boolean,
    loading: boolean,
    showForm: boolean,
}

export const START_GET_INTEGRATIONS = 'START_GET_INTEGRATIONS';
export const SUCCESS_GET_INTEGRATIONS = 'SUCCESS_GET_INTEGRATIONS';
export const ERROR_GET_INTEGRATIONS = 'ERROR_GET_INTEGRATIONS';
export const START_UPDATE_INTEGRATION = 'START_UPDATE_INTEGRATION';
export const SUCCESS_UPDATE_INTEGRATION = 'SUCCESS_UPDATE_INTEGRATION';
export const ERROR_UPDATE_INTEGRATION = 'ERROR_UPDATE_INTEGRATION';
export const START_SHOW_INTEGRATION = 'START_SHOW_INTEGRATION';
export const SUCCESS_SHOW_INTEGRATION = 'SUCCESS_SHOW_INTEGRATION';
export const ERROR_SHOW_INTEGRATION = 'ERROR_SHOW_INTEGRATION';
export const START_TOGGLE_FORM_INTEGRATIONS = 'START_TOGGLE_FORM_INTEGRATIONS';
export const SUCCESS_TOGGLE_FORM_INTEGRATIONS = 'SUCCESS_TOGGLE_FORM_INTEGRATIONS';

// INTEGRATIONS
export interface IUserdisplaytimeState {
    displaytimeSelected: any,
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_STORE_DISPLAYTIME = 'START_STORE_DISPLAYTIME';
export const SUCCESS_STORE_DISPLAYTIME = 'SUCCESS_STORE_DISPLAYTIME';
export const ERROR_STORE_DISPLAYTIME = 'ERROR_STORE_DISPLAYTIME';
export const START_UPDATE_DISPLAYTIME = 'START_UPDATE_DISPLAYTIME';
export const SUCCESS_UPDATE_DISPLAYTIME = 'SUCCESS_UPDATE_DISPLAYTIME';
export const ERROR_UPDATE_DISPLAYTIME = 'ERROR_UPDATE_DISPLAYTIME';
// INTEGRATIONS
export interface IReportState {
    popularContent: {
        mostVisualized: {
            foreign_id: number
            foreign_name: string
            image: string
            value: number
            menu: string
        }[],
        mostViewed: {
            foreign_id: number
            foreign_name: string
            image: string
            value: number
            menu: string
        }[]
    },
    bestCTR: {
        weekCTR: {
            foreign_id: number
            foreign_name: string
            image: string
            value: number
            menu: string
        }[],
        monthCTR: {
            foreign_id: number
            foreign_name: string
            image: string
            value: number
            menu: string
        }[]
    },
    sessionlogs: any,
    popularDetailContent: any,
    menuCodes: string[],
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_GET_REPORT_POPULAR_CONTENT = 'START_GET_REPORT_POPULAR_CONTENT';
export const SUCCESS_GET_REPORT_POPULAR_CONTENT = 'SUCCESS_GET_REPORT_POPULAR_CONTENT';
export const ERROR_GET_REPORT_POPULAR_CONTENT = 'ERROR_GET_REPORT_POPULAR_CONTENT';
export const START_GET_REPORT_BEST_CTR = 'START_GET_REPORT_BEST_CTR';
export const SUCCESS_GET_REPORT_BEST_CTR = 'SUCCESS_GET_REPORT_BEST_CTR';
export const ERROR_GET_REPORT_BEST_CTR = 'ERROR_GET_REPORT_BEST_CTR';
export const START_GET_REPORT_SESSION_LOGS = 'START_GET_REPORT_SESSION_LOGS';
export const SUCCESS_GET_REPORT_SESSION_LOGS = 'SUCCESS_GET_REPORT_SESSION_LOGS';
export const ERROR_GET_REPORT_SESSION_LOGS = 'ERROR_GET_REPORT_SESSION_LOGS';
export const START_GET_REPORT_POPULAR_DETAIL_CONTENT = 'START_GET_REPORT_POPULAR_DETAIL_CONTENT';
export const SUCCESS_GET_REPORT_POPULAR_DETAIL_CONTENT = 'SUCCESS_GET_REPORT_POPULAR_DETAIL_CONTENT';
export const ERROR_GET_REPORT_POPULAR_DETAIL_CONTENT = 'ERROR_GET_REPORT_POPULAR_DETAIL_CONTENT';

// GENERAL SUMMARY REPORT
export interface IGeneralSummaryReportState {
    msg: null | string,
    error: boolean,
    loading: boolean
}

export const START_UPDATE_GENERAL_SUMMARY_REPORT = 'START_UPDATE_GENERAL_SUMMARY_REPORT';
export const SUCCESS_UPDATE_GENERAL_SUMMARY_REPORT = 'SUCCESS_UPDATE_GENERAL_SUMMARY_REPORT';
export const ERROR_UPDATE_GENERAL_SUMMARY_REPORT = 'ERROR_UPDATE_GENERAL_SUMMARY_REPORT';


// Root State Interface
export interface IRootState {
    auth: IAuthState,
    brand: IBrandState,
    validator: IFormValidatorState,
    coursetype: ICoursetypeState,
    country: ICountryState,
    course: ICourseState,
    language: ILanguageState,
    lesson: ILessonState,
    role: IRoleState,
    user: IUserState,
    resource: IResourceState,
    resourcetype: IResourcetypeState,
    resourcecategory: IResourcecategoryState,
    term: ITermState,
    slider: ISliderState,
    modal: IModalState,
    event: IEventState,
    global: IGlobalState,
    offer: IOfferState,
    log: ILogState,
    tag: ITagState,
    novelty: INoveltyState,
    usersetting: IUsersettingState,
    menu: IMenuState,
    help: IHelpState,
    family: IFamilyState,
    testimonial: ITestimonialState,
    notification: INotificationState,
    seminar: ISeminarState,
    seminarlesson: ISeminarLessonState,
    seminartype: ISeminartypeState,
    favorite: IFavoriteState,
    integration: IIntegrationState,
    userdisplaytime: IUserdisplaytimeState,
    generalsummaryreport: IGeneralSummaryReportState,
    report: IReportState,
}

