import { makeStyles, createStyles } from '@material-ui/core/styles';
import mainTheme from "../components/themes/ClientTheme";

export default makeStyles((theme) => createStyles({
    // LOGIN
    // Input Language
    blockLanguage: {
        position: 'absolute',
        right: 56,
        top: 56,
        zIndex: 10,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            right: 32,
            top: 30,
        },
    },
    inputLanguage: {
        background: mainTheme.palette.common.white,
        boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '8px 16px',
        color: mainTheme.palette.primary.light,
        fontSize: mainTheme.typography.body1.fontSize,
        maxHeight: '40px',
        "& .MuiSelect-select:focus": {
            backgroundColor: mainTheme.palette.common.white,
        },
        "& .MuiSelect-select.MuiSelect-select": {
            display: 'flex',
            alignItems: 'center',
        },
        "& svg": {
            color: mainTheme.palette.primary.light,
            marginRight: '8px',
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiSelect-select.MuiSelect-select": {
                paddingTop: 4,
                paddingBottom: 4,
                fontSize: theme.typography.body1.fontSize,
            },
            maxHeight: 40,
            fontSize: mainTheme.typography.body2.fontSize,
        },
        '&.dark':{
            boxShadow: '0px 2px 10px 4px rgb(37 41 45)',
            background: mainTheme.palette.grey[900],
        },
    },
    translateIcon: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    optionLanguage: {
        color: mainTheme.palette.grey[400],
        "& svg": {
            display: 'none',
        },
    },
    // Form login
    container: {
        minHeight: '88vh',
        justifyContent: 'center',
        alignContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'auto',
            width: '100% !important',
        },
        '&.seminar': {
            minHeight: 'auto',
        },
    },
    link: {
        color: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
        fontSize: '12px'
    },
    //FORM REGITER
    form: {
        maxWidth: '442px',
        width: '100%',
        marginTop: '15vh',
        marginBottom: '64px',
        paddingLeft: '24px',
        paddingRight: '24px',
        '&live':{
            marginTop: '0',
        },
        '&.seminar':{
            marginBottom: '0',
            marginTop: '0',
        },
        '& .Mui-error': {
            borderColor: mainTheme.palette.warning.main,
            backgroundColor: mainTheme.palette.warning.light,
            color: mainTheme.palette.warning.main,
        },
        "& p.Mui-error": {
            backgroundColor: mainTheme.palette.common.white,
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '100px auto 20px',
            padding: 0,
            '& p, span': {
                fontSize: mainTheme.typography.body2.fontSize,
            },
            '& .MuiInputBase-root': {
                fontSize: mainTheme.typography.body2.fontSize,
                maxHeight: 40,
            }
        },
    },
    registerForm: {
        maxWidth: '560px',
        width: '100%',
        marginTop: '15vh',
        marginBottom: '64px',
        paddingLeft: '24px',
        paddingRight: '24px',
        '&.seminar': {
            marginTop: '0',
        },
        '& .Mui-error': {
            borderColor: mainTheme.palette.warning.main,
            backgroundColor: mainTheme.palette.warning.light,
            color: mainTheme.palette.warning.main,
        },
        "& p.Mui-error": {
            backgroundColor: mainTheme.palette.common.white,
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0px auto 20px',
            padding: 0,
        },
    },
    disabledInput:{
        color: mainTheme.palette.grey[400],
        backgroundColor: mainTheme.palette.grey[100],
    },
    formBody: {
        width: '100% !important',
        [theme.breakpoints.down('sm')]: {
            width: 'auto !important',
            '& .MuiGrid-item': {
                padding: 8,
            },
        },
    },
    autocomplete: {
        '& input': {
            position: 'relative',
            bottom: 5
        }

    },

    registerBtn:{
        padding: '9px 16px',
        borderColor: theme.palette.primary.main,
        border: '1px solid',
        margin: 0,
        color: theme.palette.primary.main,
        '&:hover':{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.dark,
        },
    },
    registerLogoContent: {
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginBottom: 40,
            marginTop: 35,
            marginLeft: 32,
        },
    },
    registerLogo: {
        [theme.breakpoints.down('sm')]: {
            height: 20,
        },
    },
    registerHeroTitle: {
        fontWeight: 700,
        '&live':{
            paddingBottom: 16,
            paddingLeft: 16,
            borderLeft: `4px solid ${mainTheme.palette.primary.main}`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: mainTheme.typography.h6.fontSize,
        },
    },
    stepper: {
        '&.MuiStepIcon-root':{
            color: mainTheme.palette.primary.light,
        },
        [theme.breakpoints.down('sm')]: {
            padding: 16,
            paddingBottom: 8,
        },
    },

    // BOX ON LOGIN
    warning:{
        background: '#062f87',
        position:'relative',
        color:'white',
        borderRadius:'16px',
        padding:'12px 20px',
        '&.seminar': {
            '& > img':{
                display: 'none',
            }
        },
        '& p':{
            fontWeight:'500',
            fontSize:'17px',
            marginBottom:'-6px'
        },
        '& img':{
            position: 'absolute',
            width: '250px',
            height: 'auto',
            top: '-32px',
            right: '-146px'
        },
        [theme.breakpoints.down('sm')]: {
            '& img':{
                display: 'none'
            },
        },
    },
    list:{
        '& li':{
            fontSize:'15px',
            marginBottom:'-17px'
        },
        '& li span':{
            fontSize:'15px'
        }

    },
    buttonHelp: {
        minWidth: 'fit-content',
        borderRadius: '50%',
        position: 'relative',
        top: '3px',
        right: '19px',
        '&:active, &:hover': {
            backgroundColor: mainTheme.palette.common.white,
            '& svg': {
                color: mainTheme.palette.info.main,
            },
        },
    },

}));