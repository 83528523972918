// React
import { useEffect, useState, useLayoutEffect, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

// Material UI
import { Typography, makeStyles, ButtonGroup, Button, Box, Menu, MenuItem, Container, Divider, ListItemIcon } from '@material-ui/core'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import AppsIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'
import clsx from 'clsx'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth'

//Custom Components
import ButtonBack from '../../common/ButtonBack'

// Redux Actions
import { setGlobalRole, setGlobalOrderBy } from '../../../redux/actions/global'
import { storeUsersettings } from '../../../redux/actions/usersettings'
import { getRoles } from '../../../redux/actions/roles'
import { IRootState } from '../../../redux/types'
import { ArrowUpwardRounded, CheckRounded, FiberManualRecord, LiveTvRounded } from '@material-ui/icons'
import ContentSearch from './ContentSearch'

// Styles
import ContentFilterStyles from '../../../styles/components/layouts/filters/ContentFilterStyles'

// Types
type ContentFilterProps = {
    changeDisplay?: any
    sectionName?: any
    showFilterBy?: boolean
    showOrderBy?: boolean
    comercial?: boolean
    handleLive?: any
    goBackAction?: boolean
    handleContentSearch?: any
}

function ContentFilter({
    changeDisplay = null,
    sectionName = null,
    showFilterBy = true,
    showOrderBy = true,
    handleContentSearch,
    handleLive,
    goBackAction = false,
    comercial = false,
}: ContentFilterProps) {
    // Translate
    const { t } = useTranslation(['global'])
    //Custom Hook
    const isMobile = useScreenWidth(700);
    const isLaptop = useScreenWidth(1280);
    // Styles
    const classes = ContentFilterStyles()

    // Location & History
    const { pathname, search } = useLocation()
    const { replace } = useHistory()
    const searchParams = new URLSearchParams(search)

    // ROL FUNCTIONS
    const [anchorLanRole, setAnchorLanRole] = useState<null | HTMLElement>(null)
    const [anchorLanOrderBy, setAnchorLanOrderBy] = useState<null | HTMLElement>(null)

    // orderByOptions state
    const orderByOptionsTranslate = [t('more-recent'), t('more-seen'), 'VIP', t('featured')]
    const [orderByOptions, setOrderByOptions] = useState<string[]>([])

    // Redux Hooks
    const dispatch = useDispatch()
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { globalRoleId, globalOrderById } = useSelector((state: IRootState) => state.global)
    const { roles, rolehierarchy } = useSelector((state: IRootState) => state.role)
    const { coursetypes } = useSelector((state: IRootState) => state.coursetype)

    // Condicional para el switch de grid a lista
    const [display, setDisplay] = useState<number>(1)


    // Ready Page
    useEffect(() => {
        if (!roles.length) dispatch(getRoles())

        if (globalRoleId === null) dispatch(setGlobalRole(null))

        if (search && searchParams.get('globalOrderById') !== null) {
            handleChangeOrderBy(Number(searchParams.get('globalOrderById')))
        }
    }, [])

    useEffect(() => {
        if (roles.length > 0) {
            if (search && searchParams.get('globalRoleId') !== null && authUser.role_id !== 6) {
                handleChangeRole(Number(searchParams.get('globalRoleId')))
            } else {
                searchParams.delete('globalRoleId')
                replace(`${pathname}?${searchParams}`)
            }
        }
    }, [roles])

    useEffect(() => {
        if (pathname.includes('cursos')) {
            let tmporderByOptions = [...orderByOptionsTranslate]
            tmporderByOptions.push(t('client.courses.viewed-courses'))
            tmporderByOptions.push(t('client.courses.recommended'))
            setOrderByOptions(tmporderByOptions)
            handleChangeOrderBy(0)
        } else if (pathname.includes('biblioteca')) {
            setOrderByOptions(orderByOptionsTranslate)
            handleChangeOrderBy(0)
        } else if (pathname.includes('comercial')) {
            setOrderByOptions(orderByOptionsTranslate)
            handleChangeOrderBy(0)
        } else {
            setOrderByOptions(orderByOptionsTranslate)
        }
    }, [t])

    /* Functions */

    /* Set global variables */
    const handleChangeRole = (role_id: null | number) => {
        dispatch(setGlobalRole(role_id))
        if (role_id != null) searchParams.set('globalRoleId', role_id.toString())
        else searchParams.delete('globalRoleId')
        replace(`${pathname}?${searchParams}`)
    }

    const handleChangeOrderBy = (orderById: number) => {
        localStorage.setItem('globalOrderById', orderById.toString())
        dispatch(setGlobalOrderBy(orderById))
        searchParams.set('globalOrderById', orderById.toString())
        replace(`${pathname}?${searchParams}`)
    }

    // Set local variables html events
    const handleClickLanRole = (event: MouseEvent<HTMLElement>) => {
        setAnchorLanRole(event.currentTarget)
    }

    const handleClickLanOrderBy = (event: MouseEvent<HTMLElement>) => {
        setAnchorLanOrderBy(event.currentTarget)
    }

    const handleCloseLanRole = () => {
        setAnchorLanRole(null)
    }

    const handleCloseLanOrderBy = () => {
        setAnchorLanOrderBy(null)
    }

    // Display Mode for Library
    const changeDisplayMode = (mode: number) => {
        setDisplay(mode)
        changeDisplay(mode)
    }


    return (
        <Container className={clsx(classes.containerFilter, comercial !== false && 'noPadding')}>
            {sectionName === null ? (
                <>
                    {goBackAction !== false && <ButtonBack />}
                </>
            ) : (
                <ContentSearch sectionName={sectionName} handleContentSearch={handleContentSearch} />
            )}

            <Box display="flex" className={classes.containerResp} flexWrap="wrap" ml="auto">
                {showFilterBy && (
                    <Box display="flex" alignItems="center">
                        {authUser.role_id !== 6 && (
                            <>
                                {isLaptop ? (
                                    <></>
                                ) : (
                                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                                        Filtrar por rol
                                    </Typography>
                                )}
                                <Button
                                    id="role-filter"
                                    aria-controls="role-filter"
                                    aria-haspopup="true"
                                    className={classes.languageButton}
                                    onClick={handleClickLanRole}
                                    endIcon={<ExpandMoreRoundedIcon htmlColor=" #FC3E6F" />}
                                >
                                    {isLaptop ? (
                                        <Typography variant="body2" style={{ fontWeight: 500 }}>
                                            Rol
                                        </Typography>
                                    ) : (
                                        <>{globalRoleId === null ? 'Todos' : roles.find((role: any) => role.id === globalRoleId).name}</>
                                    )}
                                </Button>

                                <Menu
                                    id="menu-role-filter"
                                    anchorEl={anchorLanRole}
                                    keepMounted
                                    open={Boolean(anchorLanRole)}
                                    onClose={handleCloseLanRole}
                                    onClick={handleCloseLanRole}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem onClick={() => handleChangeRole(null)} classes={{ root: classes.menuItem }}>
                                        Todos
                                        {globalRoleId === null && (
                                            <ListItemIcon style={{ minWidth: 32 }}>
                                                <CheckRounded htmlColor="#8DDD55" />
                                            </ListItemIcon>
                                        )}
                                    </MenuItem>
                                    {roles.map((role: any, index: number) => {
                                        if ((rolehierarchy.includes(role.id) || role.id === authUser.role_id) && ![1, 2, 5, 7].includes(role.id)) {
                                            return (
                                                <MenuItem
                                                    key={role.id}
                                                    onClick={() => handleChangeRole(role.id)}
                                                    classes={{
                                                        root: classes.menuItem,
                                                    }}
                                                >
                                                    {role.name}
                                                    {globalRoleId === role.id && (
                                                        <ListItemIcon
                                                            style={{
                                                                minWidth: 32,
                                                            }}
                                                        >
                                                            <CheckRounded htmlColor="#8DDD55" />
                                                        </ListItemIcon>
                                                    )}
                                                </MenuItem>
                                            )
                                        }
                                    })}
                                </Menu>
                            </>
                        )}
                    </Box>
                )}

                <Box display="flex" alignItems="center" gridGap={isLaptop ? 0 : 24} flexWrap="wrap" justifyContent="center">
                    {showOrderBy && (
                        <Box display="flex" alignItems="center">
                            {isLaptop ? (
                                <></>
                            ) : (
                                <Typography variant="body2" style={{ fontWeight: 500 }}>
                                    Ordenar por
                                </Typography>
                            )}
                            <Button
                                id="orderby-filter"
                                aria-controls="orderby-filter"
                                aria-haspopup="true"
                                className={classes.languageButton}
                                onClick={handleClickLanOrderBy}
                                endIcon={<ArrowUpwardRounded color="primary" />}
                            >
                                {isLaptop ? (
                                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                                        Ordenar
                                    </Typography>
                                ) : (
                                    <>{orderByOptions[globalOrderById]}</>
                                )}
                            </Button>

                            <Menu
                                id="menu-orderby-filter"
                                anchorEl={anchorLanOrderBy}
                                keepMounted
                                open={Boolean(anchorLanOrderBy)}
                                onClose={handleCloseLanOrderBy}
                                onClick={handleCloseLanOrderBy}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {orderByOptions.map((orderBy: string, index: number) => (
                                    <MenuItem key={orderBy} onClick={() => handleChangeOrderBy(index)} classes={{ root: classes.menuItem }}>
                                        {orderBy}
                                        {globalOrderById === index && (
                                            <ListItemIcon style={{ minWidth: 32 }}>
                                                <CheckRounded htmlColor="#8DDD55" />
                                            </ListItemIcon>
                                        )}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    )}
                    {pathname.includes('biblioteca') ? (
                        <>
                            <Box display="flex" alignItems="center" pl={isMobile ? 0 : 1}>
                                {isMobile ? <></> : <Divider orientation="vertical" flexItem />}
                                {isMobile ? (
                                    <>
                                        {display ? (
                                            <>
                                                <Button
                                                    onClick={() => changeDisplayMode(0)}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        contained: classes.contained,
                                                    }}
                                                    className={display ? classes.buttonActive : ''}
                                                    startIcon={<ListIcon />}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    onClick={() => changeDisplayMode(1)}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        contained: classes.contained,
                                                        root: classes.noLine,
                                                    }}
                                                    className={display ? '' : classes.buttonActive}
                                                    startIcon={<AppsIcon />}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <ButtonGroup className={classes.content} disableElevation variant="text">
                                        <Button
                                            onClick={() => changeDisplayMode(0)}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                contained: classes.contained,
                                                root: classes.noLine,
                                            }}
                                            className={display ? '' : classes.buttonActive}
                                            startIcon={<ListIcon />}
                                        />
                                        <Button
                                            onClick={() => changeDisplayMode(1)}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                contained: classes.contained,
                                            }}
                                            className={display ? classes.buttonActive : ''}
                                            startIcon={<AppsIcon />}
                                        />
                                    </ButtonGroup>
                                )}
                            </Box>
                        </>
                    ) : (
                        <> </>
                    )}
                </Box>
            </Box>
        </Container>
    )
}

export default ContentFilter
