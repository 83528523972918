import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    header: {
        borderBottom: '1px solid  #C7CCD1',
        marginTop: 32,
        marginBottom: 24,
        "& h5": {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                fontWeight: 500,
            },
        },
        "& p": {
            whiteSpace: "nowrap",
        }
    },
    seeMore: {
        textDecoration: 'underline',
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
    //Tooltip
    customTitle: {
        '&$title': {
            fontSize: '1rem',
        }
    },
    //BodyTable
    monthBody: {
        minWidth: 'auto',
    },
    monthHeader: {
        minWidth: 'auto',
    },
    monthCell: {
        // height: 'auto',
    },
    layoutToltip: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiPopover-paper': {
                width: 'calc(100% - 32px) !important',
            },
        }
    },
    saveBtnForm: {
        borderRadius: theme.spacing(1),
        padding: `10px ${theme.spacing(6)}px`,
        marginTop: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        display: 'inline-block',
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.dark,
        }
    },
    linkBtn: {
        borderRadius: theme.spacing(1),
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: 'inherit',
        }
    },
    headerContainer: {
        alignItems: 'center',
    },

    content: {
        padding: theme.spacing(2, 1),
        paddingTop: 0,
        backgroundColor: theme.palette.background.paper,
        boxSizing: 'border-box',
        width: '400px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    contentContainer: {
        paddingBottom: theme.spacing(1.5),
    },
    text: {
        ...theme.typography.body2,
        display: 'inline-block',
        padding: 0,
    },
    title: {
        ...theme.typography.h6,
        color: theme.palette.text.primary,
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
    },
    icon: {
        verticalAlign: 'middle',
    },
    contentItemIcon: {
        textAlign: 'center',
    },
    grayIcon: {
        color: theme.palette.action.active,
    },
    colorfulContent: {
        color: ({ color }) => color,
    },
    callToActionBtn: {
        backgroundColor: ({ color }) => color,
        color: theme.palette.grey[50],
    },
    lens: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        verticalAlign: 'super',
    },
    textCenter: {
        textAlign: 'center',
    },
    dateAndTitle: {
        lineHeight: 1.1,
    },
    spaker: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        fontWeight: 500,
        '& p': {
            fontWeight: 500,
        }
    },
    description: {
        lineHeight: 1.20,
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            fontWeight: 500,
        }
    },
    titleContainer: {
        paddingBottom: theme.spacing(2),
    },
    container: {
        paddingBottom: theme.spacing(1.5),
    },
}));