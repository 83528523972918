// React
import { useEffect, useState, ChangeEvent, forwardRef, ReactElement, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// Material UI
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, FormControl, FormHelperText, Grid, IconButton, Input, InputBase, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { BackupRounded, NavigateBeforeRounded, SearchRounded } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

//Custom Components
import Hero from '../../components/helpcenter/Hero';
import ButtonBack from '../../components/common/ButtonBack';

// Redux Actions
import { sendContactUs } from '../../redux/actions/help'
import { formValidator, resetFormValidator } from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import Dropzone from 'react-dropzone-uploader'

// Styles
import HelpCenterStyles from '../../styles/components/HelpCenterStyles';

// Types
interface IContactUs {
    topic: null | number,
    email: string,
    name: string,
    subject: string,
    message: string,
    filesName: string[]
}
type FormElement = ChangeEvent<HTMLInputElement>;

const ContactForm = ({ history }: RouteComponentProps) => {
    // Translate
    const { t } = useTranslation(["helpcenter"]);
    // Styles
    const classes = HelpCenterStyles();

    // Constants
    const topics = [{ id: 1, name: t("contactUs.topics.topic-1") }, { id: 2, name: t("contactUs.topics.topic-2") }, { id: 3, name: t("contactUs.topics.topic-3") }]

    //Redux Hooks
    const dispatch = useDispatch();
    const { reload } = useSelector((state: IRootState) => state.help);
    const { isValidForm, errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // React Hooks
    const [contactUs, setContactUs] = useState<IContactUs>({
        topic: null,
        email: '',
        name: '',
        subject: '',
        message: '',
        filesName: []
    });

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(sendContactUs({ ...contactUs, topic: topics.find((topic: any) => topic.id === contactUs.topic) }));
            setContactUs({
                topic: null,
                email: '',
                name: '',
                subject: '',
                message: '',
                filesName: []
            });
        }
    }, [isValidForm]);
    // Reload page
    useEffect(() => {
        if (reload) {
            history.go(0);
        }
    }, [reload]);

    /* Functions */
    const handleSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
        setContactUs({ ...contactUs, topic: event.target.value as number });
    }

    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value;
        setContactUs({ ...contactUs, [inputName]: value });
    }

    //Dropzone
    const getUploadParams = ({ meta }: any) => {
        let tmpFileNames = [...contactUs.filesName];
        tmpFileNames.push(meta.name);
        setContactUs({ ...contactUs, filesName: tmpFileNames });
        return { url: `${process.env.REACT_APP_API_URL}/help/upload/${meta.name}` }
    }

    const handleSubmit = () => {
        dispatch(formValidator({
            topic: [contactUs.topic, 'required|numeric'],
            email: [contactUs.email, 'required'],
            name: [contactUs.name, 'required|min:2'],
            subject: [contactUs.subject, 'required|max:200|min:2'],
            message: [contactUs.message, 'required|min:2']
        }));
    }

    return (
        <Container style={{ padding: 0 }} maxWidth={"xl"}>
            <Hero />
            <Container maxWidth="sm">
                <Box mt={6}>
                    <ButtonBack />
                </Box>
                <Box display="grid" my={3} gridGap={8}>
                    <Typography style={{ fontWeight: 500 }} variant="h5">{t("contactUs.contacttl")}</Typography>
                    <Typography color="textSecondary">{t("contactUs.contacttx")}</Typography>
                </Box>

                <Box mb={0.5}>
                    <Typography>{t("contactUs.choice")}</Typography>
                </Box>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={contactUs.topic}
                        onChange={handleSelectChange}
                        classes={{ root: classes.form }}
                    >
                        {topics.map((topic) =>
                            <MenuItem key={`${topic.id}-${topic.name}`} value={topic.id}>{topic.name.toUpperCase()}</MenuItem>
                        )}
                    </Select>
                    <FormHelperText error id="topic-helper-text">{msgForm.topic}</FormHelperText>
                </FormControl>

                <Box mb={0.5}>
                    <Typography>{t("contactUs.email")}</Typography>
                </Box>
                <FormControl variant="filled" fullWidth>
                    <Input
                        classes={{ root: classes.form }}
                        placeholder={t("contactUs.emailph")}
                        onChange={handleInputChange('email')}
                        error={errorForm.email}
                        value={contactUs.email}
                    />
                    <FormHelperText error id="email-helper-text">{msgForm.email}</FormHelperText>
                </FormControl>

                <Box mb={0.5}>
                    <Typography>{t("contactUs.fullname")}</Typography>
                </Box>
                <FormControl variant="filled" fullWidth>
                    <Input
                        classes={{ root: classes.form }}
                        placeholder={t("contactUs.fullnameph")}
                        onChange={handleInputChange('name')}
                        error={errorForm.name}
                        value={contactUs.name}
                    />
                    <FormHelperText error id="name-helper-text">{msgForm.name}</FormHelperText>
                </FormControl>

                <Box mb={0.5}>
                    <Typography>{t("contactUs.subject")}</Typography>
                </Box>
                <FormControl variant="filled" fullWidth>
                    <Input
                        classes={{ root: classes.form }}
                        placeholder={t("contactUs.subjectph")}
                        onChange={handleInputChange('subject')}
                        error={errorForm.subject}
                        value={contactUs.subject}
                    />
                    <FormHelperText>{t("contactUs.subjectht")}</FormHelperText>
                    <FormHelperText error id="subject-helper-text">{msgForm.subject}</FormHelperText>
                </FormControl>

                <Box mb={0.5}>
                    <Typography>{t("contactUs.message")}</Typography>
                </Box>
                <FormControl variant="filled" fullWidth>
                    <Input
                        classes={{ root: classes.formText }}
                        multiline
                        rows={4}
                        placeholder={t("contactUs.messageph")}
                        onChange={handleInputChange('message')}
                        error={errorForm.message}
                        value={contactUs.message}
                    />
                    <FormHelperText>{t("contactUs.messageht")}</FormHelperText>
                    <FormHelperText error id="message-helper-text">{msgForm.message}</FormHelperText>
                </FormControl>

                <Grid item xs={12}>
                    <Typography variant="body2">{t("contactUs.addimage")}</Typography>
                    <Dropzone
                        getUploadParams={getUploadParams}
                        maxFiles={2}
                        multiple={false}
                        canCancel={false}
                        classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                        inputContent={
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center">
                                <BackupRounded />
                                {t("contactUs.dropzone-text")}
                            </Box>
                        }
                    />
                </Grid>
                <Box my={7}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() => handleSubmit()}
                    >
                        {t("send")}
                    </Button>
                </Box>
            </Container>
        </Container>
    );
}

export default ContactForm;