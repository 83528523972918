//React

// Material UI
import {
    Box, Card, CardContent, CardHeader, Divider, Grid, List,
    ListItem, Typography, makeStyles
} from '@material-ui/core';
import { PeopleRounded, PublicRounded, StarRounded } from '@material-ui/icons';

// Libraries
import { useTranslation } from "react-i18next";


// Custom Style
const Style = makeStyles(theme => ({
    //Styles cards feed
    visibility: {
        [theme.breakpoints.up('md')]: {
            '& svg': {
                fontSize: 16,
                paddingRight: 2
            },
            '& p': {
                fontSize: 13,
                paddingLeft: 4,
                height: 15,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                fontSize: 13,
                paddingRight: 4
            },
            '& p': {
                fontSize: 11,
                paddingLeft: 2
            },
        }
    },
    public: {
        color: theme.palette.primary.main
    },
    private: {
        color: theme.palette.grey[400],
        height: '100%',
    },

}));


const Visibility = (props: any) => {
    const { type, variant } = props
    // Styles
    const classes = Style();

    // Translate
    const { t } = useTranslation(["global"]);

    let isDefault = variant != null
    let hideIcon = variant === 5

    return (
        <Box className={classes.visibility} >

            {type === 0 &&
                <Box className={classes.private} display="flex" alignItems="center">
                    {!hideIcon && <PeopleRounded />} {isDefault ? '' : '|'} <Typography>{t('only-users')}</Typography>
                </Box>
            }
            {type === 1 &&
                <Box className={classes.private} display="flex" alignItems="center">
                    {!hideIcon && <StarRounded />} {isDefault ? '' : '|'}<Typography>VIP</Typography>
                </Box>
                
            }
            {type === 2 &&
                <Box className={classes.private} display="flex" alignItems="center">
                    {!hideIcon && <PublicRounded />} {isDefault ? '' : '|'}<Typography>{t('public')}</Typography>
                </Box>
                
            }
        </Box>
    )
}

export default Visibility
