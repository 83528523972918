import {
    START_GET_NOVELTIES,
    START_GET_CLIENT_NOVELTIES,
    START_CREATE_NOVELTY,
    START_UPDATE_NOVELTY,
    START_SHOW_NOVELTY,
    START_SHOW_NOVELTY_BY_SLUG,
    START_DELETE_NOVELTY,
    START_TOGGLE_FORM_NOVELTY,
    START_ASSIGN_TAG_NOVELTY,
    START_DELETE_TAG_NOVELTY,
    START_ASSIGN_HIGHLIGHT_NOVELTY
} from '../types';

export const getNovelties = payload => ({
    type: START_GET_NOVELTIES,
    payload
})

export const getClientNovelties = payload => ({
    type: START_GET_CLIENT_NOVELTIES,
    payload
})

export const createNovelty = payload => ({
    type: START_CREATE_NOVELTY,
    payload
})

export const updateNovelty = payload => ({
    type: START_UPDATE_NOVELTY,
    payload
})

export const showNovelty = payload => ({
    type: START_SHOW_NOVELTY,
    payload
})

export const showNoveltyBySlug = payload => ({
    type: START_SHOW_NOVELTY_BY_SLUG,
    payload
})

export const deleteNovelty = payload => ({
    type: START_DELETE_NOVELTY,
    payload
})

export const assignHighlight = payload => ({
    type: START_ASSIGN_HIGHLIGHT_NOVELTY,
    payload
})

export const toggleFormNovelty = payload => ({
    type: START_TOGGLE_FORM_NOVELTY,
    payload
})

export const assignTagNovelty = payload => ({
    type: START_ASSIGN_TAG_NOVELTY,
    payload
})

export const deleteTagNovelty = payload => ({
    type: START_DELETE_TAG_NOVELTY,
    payload
})