import {
    START_LOGIN,
    START_VERIFY_USER,
    START_LOGOUT_USER,
    START_REMEMBER_PASSWORD,
    START_RESET_PASSWORD,
    START_CHANGE_PASSWORD,
    START_CLEAR_STORAGE
} from '../types';

export const login = payload => ({
    type: START_LOGIN,
    payload
})

export const logout = () => ({
    type: START_LOGOUT_USER
})

export const verifyUser = () => ({
    type: START_VERIFY_USER
})

export const rememberPassword = payload => ({
    type: START_REMEMBER_PASSWORD,
    payload
})

export const resetPassword = payload => ({
    type: START_RESET_PASSWORD,
    payload
})

export const changePassword = payload => ({
    type: START_CHANGE_PASSWORD,
    payload
})

export const clearStorage = () => ({
    type: START_CLEAR_STORAGE
})