import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";
import mainTheme from '../themes/ClientTheme';
import imageFav from '../../../assets/Images/fav.png';

// Course Block styles
export default makeStyles((theme) =>
    createStyles({

        media: {
            [theme.breakpoints.up('md')]: {
                height: '163px',
            },
            [theme.breakpoints.down('sm')]: {
                height: '136px',
            },
            position: 'relative',
            cursor: 'pointer',
        },
        card: {
            border: 'none',
            position: 'relative',
            boxShadow: '4px 6px 20px rgb(0 0 0 / 10%)',
            borderRadius: 8,
            '& .MuiCardActionArea-focusHighlight': {
                backgroundColor: theme.palette.grey[800],
            },
            [theme.breakpoints.up('md')]: {
                boxSizing: 'border-box',
            },
        },
        cardText1: {
            padding: 12,
            paddingBottom: 0,
            [theme.breakpoints.down('xs')]: {
                padding: 6,
            },
        },
        detailTitle: {
            [theme.breakpoints.up('md')]: {
                fontSize: '2 rem',
                lineHeight: '1.235',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '16px',
            },
        },
        labelTitle: {
            '&:hover': {
                color: `${mainTheme.palette.grey[800]} !important`,
            }
        },
        percentage: {
            color: theme.palette.grey[800],
            '&.complete': {
                color: theme.palette.success.main
            },
        },
        duration: {
            color: theme.palette.grey[400],
            fontSize: '13px',
            fontWeight: 500,
            height: 18,
            [theme.breakpoints.down('xs')]: {
                fontSize: '12px',
            },
        },
        lessons: {
            position: 'relative',
            background: '#F5F6F6',
            marginTop: theme.spacing(4),
            borderRadius: 16,
            boxShadow: 'none',
            '&.active': {
                border: '2px solid',
                borderColor: theme.palette.error.main,
                background: '#FAECF0',

            },
            '&:hover': {
                cursor: 'pointer',
                background: theme.palette.error.light,
            },
            "& h6": {
                fontWeight: 500,
            },
            '&.block': {
                border: 'none',
                background: theme.palette.grey[100],
                '&:hover': {
                    background: theme.palette.grey[100],
                }
            },
        },
        lessonContent: {
            fontFamily: "Poppins, sans-serif",
        },

        textPrimary: {
            color: mainTheme.palette.primary.main,
        },
        //Estos estilos son temporales, las cards son con base a biblioteca
        lessonsMaterial: {
            boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(4),
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: theme.spacing(2),
            maxWidth: '200px',
            "& img": {
                borderRadius: theme.spacing(1),
                maxHeight: "auto",
                width: '100%'
            },
            cursor: "pointer"
        },
        cardAction: {
            paddingTop: 8,
        },
        visbilityBlock: {
            paddingTop: 12,
            '& button': {
                marginBottom: '-17px',
                position: 'relative',
                bottom: 11
            }
        },
        lessonView: {
            '& iframe': {
                width: '100% !important',
                border: 'none !important'
            },
            '& p': {
                padding: '0px !important',

            }
        },
        lessonPlay: {
            color: theme.palette.grey[500],
            fontSize: '56px !important',
            '&.live': {
                color: theme.palette.error.main,
            },
            '&.new': {
                color: theme.palette.success.main,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '32px !important',
            },
        }

    }));
