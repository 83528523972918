// React
import { useRef, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


// Material UI
import {
    Grid, Container, Box, Button, Typography, Tab, Tabs,
    Dialog, DialogActions, DialogContent, DialogTitle
} from "@material-ui/core";
import { ArrowDownwardRounded } from "@material-ui/icons/";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Redux Actions
import { setViewSeminarLesson, deleteSeminarLessonMultimedia, getRegisteredUsers } from '../../redux/actions/seminarlessons';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import clsx from 'clsx';
import Dropzone, { ILayoutProps } from 'react-dropzone-uploader'

// Custom Components
import SupportFile from '../common/client/SupportFile';

// Styles
import SeminarStyles from '../../styles/components/seminars/SeminarStyles';
import CoursesStyles from '../../styles/components/courses/CoursesStyles';
import VipStyles from '../../styles/components/common/VipStyles';

import 'react-dropzone-uploader/dist/styles.css'

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//Dropzone
const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            {files.length > 0 && submitButton}
        </div>
    )
}

type SeminarLessonDetailProps = {
    handleOpen: (state: boolean) => void
}

function SeminarLessonDetail({ handleOpen }: SeminarLessonDetailProps) {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Styles
    const classes = SeminarStyles();
    const courseClasses = CoursesStyles();
    const vipclasses = VipStyles();

    // Redux Hooks
    const dispatch = useDispatch();
    const { seminarSelected } = useSelector((state: IRootState) => state.seminar);
    const { seminarlesson_selected } = useSelector((state: IRootState) => state.seminarlesson);
    const { user: authUser, isAuth } = useSelector((state: IRootState) => state.auth);
    const { error, msg } = useSelector((state: IRootState) => state.lesson);

    // State
    const [seminarlessonMultimediaSelected, setSeminarlessonMultimediaSelected] = useState<any>(null);
    const [showEditSeminarlessonMultimedia, setShowEditseminarlessonMultimedia] = useState(false);

    //Location
    const location = useLocation();

    //Funcion para cuando esten los metodos
    let isEmbed: boolean = (seminarlesson_selected.type === 0);

    const myRef = useRef(null);
    const scrollToRef = (ref: any) => window.scrollTo({ top: ref?.current?.offsetTop ?? 800, left: 0, behavior: 'smooth' });
    const executeScroll = () => scrollToRef(myRef);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (isAuth && authUser !== null)
            dispatch(getRegisteredUsers(seminarlesson_selected.id));
    }, [isAuth]);

    useEffect(() => {
        if (seminarlesson_selected !== null && seminarlesson_selected.isRegistered === null) {
            handleOpen(true)
        }
    }, [seminarlesson_selected]);

    const handleEditMediaFileBlock = (seminarlessonMultimedia: any) => {
        setSeminarlessonMultimediaSelected(seminarlessonMultimedia);
        setShowEditseminarlessonMultimedia(!showEditSeminarlessonMultimedia);
    };

    const handleCloseEditseminarlessonMultimedia = () => {
        setSeminarlessonMultimediaSelected(null);
        setShowEditseminarlessonMultimedia(!showEditSeminarlessonMultimedia);
    };

    const handleDownloadMediaFileBlock = (fileName: string) => {
        window.open(`${process.env.REACT_APP_API_URL}/seminarlessons/download/${fileName}`)
    };

    const handleDeleteMediaFileBlock = (seminarlessonmultimedia_id: number) => {
        let index = seminarlesson_selected.multimedia.findIndex((multimedia: any) => multimedia.id === seminarlessonmultimedia_id);
        seminarlesson_selected.multimedia.splice(index, 1);
        dispatch(setViewSeminarLesson(seminarlesson_selected));
        dispatch(deleteSeminarLessonMultimedia(seminarlessonmultimedia_id));
    };

    return (
        <Box className={clsx(classes.containerHeroDetail, isEmbed && 'wraped', (seminarSelected.visibility === 1) && vipclasses.containerHeroDetail)}>
            {isEmbed ?
                <Container maxWidth={'lg'} style={{ height: 'max-content' }} className={classes.limitEmbed}>
                    <div dangerouslySetInnerHTML={{ __html: seminarlesson_selected.content }}></div>
                </Container>
                :
                <>
                    {/* ---------Comienzo Vimeo --------- */}
                    <Box className={classes.videoDetail} >
                        <iframe className={classes.iframeDetail} allow='autoplay' src={seminarlesson_selected.content} frameBorder={0} allowFullScreen={true} title='canva'></iframe>
                    </Box>
                    <Box className={classes.chatDetail}>
                        <iframe className={classes.iframeDetail} src={seminarlesson_selected.extracontent} frameBorder={0} title='chat'></iframe>
                    </Box>
                    {/* ------Fin vimeo ------*/}
                </>
            }

            <Box
                className={clsx(
                    classes.bannerHeroDetail,
                    isEmbed && 'wraped',
                    (authUser !== null && (seminarlesson_selected.datetime === null || moment(seminarlesson_selected.datetime).subtract(30, 'minutes').isSameOrBefore(moment()))) && 'hide'
                )}
            >
                <Typography variant='h4' align='left'>{seminarlesson_selected.title}</Typography>
                {(seminarlesson_selected.datetime) &&
                    <Typography>{`${moment(seminarlesson_selected.datetime).format('LLLL')}`}</Typography>
                }
                <Box mx='auto' display='flex' flexDirection='column' gridGap={16} mt={5}>
                    {seminarlesson_selected.isRegistered !== null && authUser !== null
                        ?
                        <Box className={clsx(classes.containerDetail, (seminarSelected.isRegistered !== null) ? 'white' : '')} maxWidth={960} width='100%' >
                            <Typography align="center" variant="h4">{t('you-already-booked-ticket')}</Typography>
                            <Box className={classes.textReadyDetail}>
                                <Typography>{t('the-webinar-bee-sent')} <b>{authUser.email}</b></Typography>
                                <Typography>{t('see-you-seminar')}, {authUser.name}!</Typography>
                            </Box>
                            <Button variant='text' className={classes.linkPrimary} onClick={executeScroll} endIcon={<ArrowDownwardRounded />}>
                                <Typography>{t('more-info')}</Typography>
                            </Button>
                        </Box>
                        : <>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => { handleOpen(true) }}
                                // className={clsx(classes.btnLinkDetail, (seminarSelected.visibility === 1) && 'vip')}>
                                className={(seminarSelected.visibility === 1) ? vipclasses.btnLinkDetail : classes.btnLinkDetail}>
                                {t('book-my-ticket')}
                            </Button>
                            {/* Lo ideal de el botón de más info en esta pantalla es que realice un scroll hata  "acerca de este evento" */}
                            <Button variant='text' className={classes.linkPrimary} onClick={executeScroll} endIcon={<ArrowDownwardRounded />}>
                                <Typography>{t('more-info')}</Typography>
                            </Button>
                        </>}
                </Box>

                {seminarlesson_selected.multimedia.length > 0 &&
                    <Box mt={4} mb={6}>
                        <Typography variant="subtitle1" color="primary">Material de la lección</Typography>
                        <Box display="flex" mt={3} gridGap={16} flexDirection="row">
                            {seminarlesson_selected.multimedia.map((multimedia: any) => (
                                <SupportFile
                                    key={uuidv4()}
                                    data={multimedia}
                                    type="seminarlessons"
                                    vipContent={seminarSelected.visibility === 1}
                                    hasaccess={seminarSelected.visibility === 1 && authUser && (authUser.vip_lines.includes(seminarSelected.line_id) || (seminarSelected.line_id === null && authUser.vip_brands.includes(seminarSelected.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                    handleDownload={handleDownloadMediaFileBlock}
                                    handleDelete={handleDeleteMediaFileBlock}
                                />
                            ))}
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default SeminarLessonDetail;
