// Material UI
import { Box, IconButton, InputBase } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

//Assets
import background from '../../assets/Images/BackgroundHC.jpg';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import HelpCenterStyles from '../../styles/components/HelpCenterStyles';


const Hero = () => {
    // Translation
    const { t } = useTranslation(["helpcenter"]);

    // Styles
    const classes = HelpCenterStyles();
    const data = [{ title: '' }]
    return (
            <Box display="flex" style={{ backgroundImage: `url(${background})` }} height={445} className={classes.hero} alignItems="center" justifyContent="center">
               {/* <Box display="flex" className={classes.containerSearch}>
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        fullWidth
                        classes={{ endAdornment: classes.iconClear }}
                        options={data.map((option) => option.title)}
                        renderInput={(params) => {
                            const { InputLabelProps, InputProps, ...rest } = params;
                            return <InputBase
                                {...params.InputProps}
                                placeholder={t("searchtext")}
                                classes={{ root: classes.inputSearch }}
                                {...rest} />
                        }}
                    />
                    <IconButton type="submit" aria-label="search" classes={{ root: classes.buttonSearch }}>
                        <SearchRounded htmlColor={"white"} />
                    </IconButton>
                </Box>
                    */}
            </Box>
    );
}

export default Hero;