// Material UI
import { Typography, makeStyles, Button, Box, Menu } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import clsx from 'clsx';

// Libraries
import { useTranslation } from "react-i18next";

// Custom Style
const FiltersStyle = makeStyles(theme => ({
    popover: {
        maxHeight: 250,
        maxWidth: '22ch',
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '8px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
    },
    root: {
        padding: 2,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 30,
        width: 12,
        height: 12,
        border: `2px solid ${theme.palette.grey[400]}`,
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: `2px auto ${theme.palette.success.main}`,
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.common.white,
        },
    },
    checkedIcon: {
        border: `2px solid ${theme.palette.success.main}`,
        backgroundColor: theme.palette.common.white,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            filter: 'invert(22%) sepia(79%) saturate(414%) hue-rotate(34deg) brightness(92%) contrast(87%)',
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.common.white,
        },
    },
}));

// Types
type OptionFilterUserProps = {
    text: string,
    checked?: boolean,
    onChange?: any
}

function CustomCheckbox(props: CheckboxProps) {
    const classes = FiltersStyle();

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}

function OptionFilterUser({ text, checked = false, onChange }: OptionFilterUserProps) {
    // Translate
    const { t } = useTranslation(["global"]);

    return (
        <Box display="flex" gridGap={4} padding="2px 0px" alignItems="center" boxSizing="content-box">
            <CustomCheckbox checked={checked} onChange={onChange} />
            <Typography color="textSecondary" variant='body2' style={{ height: 18, whiteSpace: 'nowrap' }}>{text}</Typography>
        </Box>
    )
}

export default OptionFilterUser;