import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_COURSES, SUCCESS_GET_COURSES, ERROR_GET_COURSES,
    START_GET_FILTERED_COURSES, SUCCESS_GET_FILTERED_COURSES, ERROR_GET_FILTERED_COURSES,
    START_GET_COURSES_BY_BRAND, SUCCESS_GET_COURSES_BY_BRAND, ERROR_GET_COURSES_BY_BRAND,
    START_GET_HOME_COURSES, SUCCESS_GET_HOME_COURSES, ERROR_GET_HOME_COURSES,
    START_STORE_COURSE, SUCCESS_STORE_COURSE, ERROR_STORE_COURSE,
    START_SHOW_COURSE, SUCCESS_SHOW_COURSE, ERROR_SHOW_COURSE,
    START_SHOW_COURSE_BY_SLUG, SUCCESS_SHOW_COURSE_BY_SLUG, ERROR_SHOW_COURSE_BY_SLUG,
    START_DELETE_COURSE, SUCCESS_DELETE_COURSE, ERROR_DELETE_COURSE,
    START_COURSE_LESSON_STEP, SUCCESS_COURSE_LESSON_STEP, ERROR_COURSE_LESSON_STEP,
    START_FINISH_COURSE, SUCCESS_FINISH_COURSE, ERROR_FINISH_COURSE,
    START_TOGGLE_FORM_COURSE, SUCCESS_TOGGLE_FORM_COURSE,
    START_ASSIGN_TAG_COURSE, SUCCESS_ASSIGN_TAG_COURSE, ERROR_ASSIGN_TAG_COURSE,
    START_DELETE_TAG_COURSE, SUCCESS_DELETE_TAG_COURSE, ERROR_DELETE_TAG_COURSE,
    START_ASSIGN_HIGHLIGHT_COURSE, SUCCESS_ASSIGN_HIGHLIGHT_COURSE, ERROR_ASSIGN_HIGHLIGHT_COURSE,
    START_MASS_ASSIGNMENT_COURSES, SUCCESS_MASS_ASSIGNMENT_COURSES, ERROR_MASS_ASSIGNMENT_COURSES,
} from '../types';
import { apiCall } from '../config/axios';

function* getCourses() {
    try {
        const response = yield call(apiCall, 'get', '/courses/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_COURSES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_COURSES, payload: error.message })
    }
}

function* getFilteredCourses({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/courses/filteredcourses', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_FILTERED_COURSES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_FILTERED_COURSES, payload: error.message })
    }
}

function* getCoursesByBrand({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/courses/coursesbybrand`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_COURSES_BY_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_COURSES_BY_BRAND, payload: error.message })
    }
}

function* getHomeCourses({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/courses/home`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_HOME_COURSES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_HOME_COURSES, payload: error.message })
    }
}

function* createCourse({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/courses/store', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_STORE_COURSE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_STORE_COURSE, payload: error.message })
    }
}

function* showCourse({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/courses/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_COURSE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_COURSE, payload: error.message })
    }
}

function* showCourseBySlug({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/courses/showbyslug/${payload.brandId}/${payload.slug}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_COURSE_BY_SLUG, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_COURSE_BY_SLUG, payload: error.message })
    }
}

function* deleteCourse({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/courses/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_COURSE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_COURSE, payload: error.message })
    }
}

function* courseLessonStep({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/courses/lessonStep', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_COURSE_LESSON_STEP, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_COURSE_LESSON_STEP, payload: error.message })
    }
}

function* finishCourse({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/courses/finishcourse/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_FINISH_COURSE, payload: response.data })
        window.location.href = '/cursos';
    } catch (error) {
        yield put({ type: ERROR_FINISH_COURSE, payload: error.message })
    }
}

function* assignHighlight({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/courses/assignhighlight`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_HIGHLIGHT_COURSE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_HIGHLIGHT_COURSE, payload: error.message })
    }
}

function* toggleFormCourse({ payload }) {
    yield put({ type: SUCCESS_TOGGLE_FORM_COURSE, payload })
}

function* assignTagCourse({ payload }) {
    try{
        const response = yield call(apiCall, 'post', `/courses/assigntag`, {course: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_ASSIGN_TAG_COURSE, payload})
    } catch (error){
        yield put ({ type: ERROR_ASSIGN_TAG_COURSE, payload: error.message})
    }
}

function* deleteTagCourse({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/courses/deletetag/${payload.id}/${payload.tagId}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_DELETE_TAG_COURSE, payload})
    } catch (error){
        yield put ({ type: ERROR_DELETE_TAG_COURSE, payload: error.message})
    }
}

function* putMassAssignment({ payload }) {
    try {
        const response = yield call(apiCall, 'put', `/courses/massassignment`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_MASS_ASSIGNMENT_COURSES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_MASS_ASSIGNMENT_COURSES, payload: error.message })
    }
}

export default function* courses() {
    yield takeLatest(START_GET_COURSES, getCourses);
    yield takeLatest(START_GET_FILTERED_COURSES, getFilteredCourses);
    yield takeLatest(START_GET_COURSES_BY_BRAND, getCoursesByBrand);
    yield takeLatest(START_GET_HOME_COURSES, getHomeCourses);
    yield takeLatest(START_STORE_COURSE, createCourse);
    yield takeLatest(START_SHOW_COURSE, showCourse);
    yield takeLatest(START_SHOW_COURSE_BY_SLUG, showCourseBySlug);
    yield takeLatest(START_DELETE_COURSE, deleteCourse);
    yield takeLatest(START_COURSE_LESSON_STEP, courseLessonStep);
    yield takeLatest(START_FINISH_COURSE, finishCourse);
    yield takeLatest(START_ASSIGN_HIGHLIGHT_COURSE, assignHighlight);
    yield takeLatest(START_TOGGLE_FORM_COURSE, toggleFormCourse);
    yield takeLatest(START_ASSIGN_TAG_COURSE, assignTagCourse);
    yield takeLatest(START_DELETE_TAG_COURSE, deleteTagCourse);
    yield takeLatest(START_MASS_ASSIGNMENT_COURSES, putMassAssignment);
}