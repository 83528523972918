import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_OFFERS, SUCCESS_GET_OFFERS, ERROR_GET_OFFERS,
    START_GET_CLIENT_OFFERS, SUCCESS_GET_CLIENT_OFFERS, ERROR_GET_CLIENT_OFFERS,
    START_CREATE_OFFER, SUCCESS_CREATE_OFFER, ERROR_CREATE_OFFER,
    START_UPDATE_OFFER, SUCCESS_UPDATE_OFFER, ERROR_UPDATE_OFFER,
    START_SHOW_OFFER, SUCCESS_SHOW_OFFER, ERROR_SHOW_OFFER,
    START_DELETE_OFFER, SUCCESS_DELETE_OFFER, ERROR_DELETE_OFFER,
    START_TOGGLE_FORM_OFFER, SUCCESS_TOGGLE_FORM_OFFER,
    START_ASSIGN_TAG_OFFER, SUCCESS_ASSIGN_TAG_OFFER, ERROR_ASSIGN_TAG_OFFER,
    START_DELETE_TAG_OFFER, SUCCESS_DELETE_TAG_OFFER, ERROR_DELETE_TAG_OFFER,
    START_ASSIGN_HIGHLIGHT_OFFER, SUCCESS_ASSIGN_HIGHLIGHT_OFFER, ERROR_ASSIGN_HIGHLIGHT_OFFER,
    START_COUNTER_VIEWS_OFFER, SUCCESS_COUNTER_VIEWS_OFFER, ERROR_COUNTER_VIEWS_OFFER
} from '../types';
import { apiCall } from '../config/axios';

function* getOffers({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/offers/index', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_OFFERS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_OFFERS, payload: error.message })
    }
}

function* getClientOffers({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/offers/indexclient`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_CLIENT_OFFERS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_CLIENT_OFFERS, payload: error.message })
    }
}

function* createOffer({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/offers/store', {offer: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CREATE_OFFER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CREATE_OFFER, payload: error.message })
    }
}

function* updateOffer({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/offers/update', {offer: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_UPDATE_OFFER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_UPDATE_OFFER, payload: error.message })
    }
}

function* showOffer({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/offers/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_OFFER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_OFFER, payload: error.message })
    }
}

function* deleteOffer({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/offers/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_OFFER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_OFFER, payload: error.message })
    }
}

function* assignHighlight({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/offers/assignhighlight`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_HIGHLIGHT_OFFER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_HIGHLIGHT_OFFER, payload: error.message })
    }
}

function* toggleFormOffer({ payload }) {
    yield put({ type: SUCCESS_TOGGLE_FORM_OFFER, payload })
}

function* assignTagOffer({ payload }) {
    try{
        const response = yield call(apiCall, 'post', `/offers/assigntag`, {offer: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_ASSIGN_TAG_OFFER, payload})
    } catch (error){
        yield put ({ type: ERROR_ASSIGN_TAG_OFFER, payload: error.message})
    }
}

function* deleteTagOffer({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/offers/deletetag/${payload.id}/${payload.tagId}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_DELETE_TAG_OFFER, payload})
    } catch (error){
        yield put ({ type: ERROR_DELETE_TAG_OFFER, payload: error.message})
    }
}

function* counterViewsOffer({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/offers/counterviews/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_COUNTER_VIEWS_OFFER, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_COUNTER_VIEWS_OFFER, payload: error.message})
    }
}

export default function* offers() {
    yield takeLatest(START_GET_OFFERS, getOffers);
    yield takeLatest(START_GET_CLIENT_OFFERS, getClientOffers);
    yield takeLatest(START_CREATE_OFFER, createOffer);
    yield takeLatest(START_UPDATE_OFFER, updateOffer);
    yield takeLatest(START_SHOW_OFFER, showOffer);
    yield takeLatest(START_DELETE_OFFER, deleteOffer);
    yield takeLatest(START_ASSIGN_HIGHLIGHT_OFFER, assignHighlight);
    yield takeLatest(START_TOGGLE_FORM_OFFER, toggleFormOffer);
    yield takeLatest(START_ASSIGN_TAG_OFFER, assignTagOffer);
    yield takeLatest(START_DELETE_TAG_OFFER, deleteTagOffer);
    yield takeLatest(START_COUNTER_VIEWS_OFFER, counterViewsOffer);
}