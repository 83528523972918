// React
import { useEffect, useState, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";

// MATERIAL UI
import {
  Toolbar,
  AppBar,
  Typography,
  Button,
  ThemeProvider,
  Menu,
  Container,
  MenuItem,
  ListItemIcon,
  Link,
  Box,
  Drawer,
  Tooltip,
} from "@material-ui/core";
import {
  Add,
  BookmarkRounded,
  SchoolRounded,
  LocalActivityRounded,
  PeopleAltRounded,
  ViewCarouselRounded,
  EventRounded,
} from "@material-ui/icons";

// CUSTOM COMPONENTS
import PrivateRoute from "../../components/common/PrivateRoute";
import CustomSnackBar from "../common/admin/CustomSnackBar";
import Footer from "./common/Footer";
import AdminMobileMenu from "./navigation/admin/AdminMobileMenu";
import AccountMenu from "./common/AccountMenu";
import BrandMobileFilter from "./filters/BrandMobileFilter";
import LanguageFilter from "./filters/LanguageFilter";
import Sidebar from "./navigation/Sidebar";
import HiddenMobile from "../common/HiddenMobile";

// USERS
import Dashboard from "../../pages/Dashboard";
import Brands from "../../pages/brands/admin/Brands";
import BrandsView from "../../pages/brands/admin/BrandsView";
import Users from "../../pages/users/Users";
import UserProfile from "../users/UserProfile";

import IntegrationsAPI from "../../pages/integrations/admin/IntegrationsAPI";

// COURSES
import CoursesAdmin from "../../pages/courses/admin/Courses";
import CoursesDetail from "../../pages/courses/CourseDetail";
import LessonsView from "../../pages/lessons/admin/LessonsView";

// SLIDERS
import SlidersAdmin from "../../pages/sliders/admin/Sliders";
import SliderDetail from "../../pages/sliders/admin/SliderDetail";

// EVENTS
import EventsAdmin from "../../pages/events/admin/Events";
import EventDetail from "../../pages/events/EventDetail";

// LIVES
import Seminars from "../../pages/seminars/admin/Seminars";
import SeminarDetail from "../../pages/seminars/SeminarDetail";

// OFERTAS
import OffersAdmin from "../../pages/offers/admin/Offers";
import OfferDetail from "../../pages/offers/OfferDetail";

// NOTICIAS
import NewsAdmin from "../../pages/news/admin/NewsAdmin";
import NewsDetail from "../../pages/news/NewsDetail";

// NOVEDADES
import NoveltyAdmin from "../../pages/novelties/admin/NoveltyAdmin";
import NoveltyDetail from "../../pages/novelties/NoveltyDetail";

// LIBRARY
import Library from "../../pages/library/admin/AdminLibrary";
import LibraryDetail from "../../pages/library/ResourceDetail";
import CreateMedia from "../library/CreateMediaModal";
import Resourcecategories from "../library/Resourcecategories";

// Redux Actions
import { toggleFormUsers } from "../../redux/actions/users";
import { toggleFormSlider } from "../../redux/actions/sliders";
import { toggleFormEvent } from "../../redux/actions/events";
import { toggleFormCourse } from "../../redux/actions/courses";
import { toggleFormOffer } from "../../redux/actions/offers";
import { toggleFormCreateResources } from "../../redux/actions/resources";
import { IRootState } from "../../redux/types";

// Assets
import logo from "../../assets/Images/logos/logolight.svg";

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Styles
import BasesStyles from "../../styles/components/layouts/AdminLayout";
import LayoutSyltes from "../../styles/components/layouts/MainLayout";
import AdminTheme from "../../styles/components/themes/AdminTheme";
import "../../styles/messagebird.scss";

function AdminLayout({ history }: RouteComponentProps) {
  // Translation
  const { t } = useTranslation(["global"]);
  //Custom Hook
  const isMobile = useScreenWidth(700);
  const isTablet = useScreenWidth(920);
  // Styles
  const classes = BasesStyles();
  const layoutClasses = LayoutSyltes();


  // Redux Hooks
  const dispatch = useDispatch();
  const {
    user: authUser,
    error: errorAuth,
    msg: msgAuth,
  } = useSelector((state: IRootState) => state.auth);

  // Add Function
  const [anchorAdd, setAnchorAdd] = useState<null | HTMLElement>(null);

  // Ready Page
  useEffect(() => {
    if (localStorage.getItem("accept_terms") === "true" && authUser !== null && authUser.me && authUser.verification === 1)
      history.push("/terms");
  }, []);

  const handleAddClose = () => {
    setAnchorAdd(null);
  };

  const handleClickAdd = (event: MouseEvent<HTMLElement>) => {
    setAnchorAdd(event.currentTarget);
  };

  if (localStorage.getItem("login_redirect")) {
    let tmpPath = localStorage.getItem("login_redirect");
    localStorage.removeItem("login_redirect");
    window.location.href = tmpPath || "/inicio";
  }

  if (authUser.role_id === 6 || authUser.role_id === 7) {
    return <Redirect to="/inicio" />;
  }

  return (
    <ThemeProvider theme={AdminTheme}>
      <Box display="flex" data-testid="admin-main-container">
        <AppBar position="fixed" className={layoutClasses.appBar + " admin"}>
          <Container maxWidth="xl" style={{ padding: 0 }}>
            <Toolbar>
              <Link href="/escritorio" className={layoutClasses.appLogo}>
                <img
                  src={String(logo)}
                  alt={`Logo de ${process.env.REACT_APP_NAME}`}
                  className={layoutClasses.navLogo}
                ></img>
              </Link>

              <HiddenMobile>
                <Typography className={classes.titleNav}>
                  {authUser.role_id === 1 && (
                    t("common.admin").toLocaleUpperCase()
                  )}
                  {authUser.role_id === 2 && (
                    t("common.admin").toLocaleUpperCase()
                  )}
                  {authUser.role_id === 3 && (
                    t("supplier").toLocaleUpperCase()
                  )}
                  {authUser.role_id === 4 && (
                    t("commercial").toLocaleUpperCase()
                  )}
                  {authUser.role_id === 5 && (
                    t("trainer").toLocaleUpperCase()
                  )}
                </Typography>
              </HiddenMobile>

              <BrandMobileFilter theme="admin" type="nav" />

              {isMobile === false && (
                <>
                  <div className={layoutClasses.grow}></div>
                  {[1, 2, 5, 3].includes(authUser.role_id) && (
                    <Tooltip title={`${t("add")}`}>
                      <Button
                        aria-controls="add-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.buttonAdd}
                        onClick={handleClickAdd}
                      >
                        <Add htmlColor="white" />
                      </Button>
                    </Tooltip>
                  )}
                  <LanguageFilter theme="admin" />
                </>
              )}
              {isMobile === true && (
                <>
                  <LanguageFilter theme="admin" />
                  <div className={layoutClasses.grow}></div>
                </>
              )}
              <AccountMenu theme="admin" history={history} />
            </Toolbar>
          </Container>
        </AppBar>

        <Sidebar theme="admin" />

        <main className={layoutClasses.content} style={{ padding: "0px" }}>
          <div className={layoutClasses.toolbar} />
          <Switch>
            <PrivateRoute exact path="/escritorio" component={Dashboard} />

            {/* Brands */}
            <PrivateRoute exact path="/admin/marcas" component={Brands} />
            <PrivateRoute
              exact
              path="/admin/marcasView"
              component={BrandsView}
            />

            {/* Users */}
            <PrivateRoute exact path="/admin/usuarios" component={Users} />
            <PrivateRoute
              exact
              path="/admin/usuarios/:id"
              component={UserProfile}
            />
            <PrivateRoute
              exact
              path="/usuarios/perfil"
              component={UserProfile}
            />

            <PrivateRoute
              exact
              path="/admin/integraciones"
              component={IntegrationsAPI}
            />

            {/* Courses */}
            <PrivateRoute exact path="/admin/cursos" component={CoursesAdmin} />
            <PrivateRoute
              exact
              path="/admin/cursos/:brandId/:slug"
              component={CoursesDetail}
            />

            {/* Lessons */}
            <PrivateRoute
              exact
              path="/admin/courseName/lessonName"
              component={LessonsView}
            />

            {/* Sliders */}
            <PrivateRoute
              exact
              path="/admin/sliders"
              component={SlidersAdmin}
            />
            <PrivateRoute
              exact
              path="/admin/sliders/:id"
              component={SliderDetail}
            />

            {/* Offers */}
            <PrivateRoute exact path="/admin/ofertas" component={OffersAdmin} />
            <PrivateRoute
              exact
              path="/admin/ofertas/:id"
              component={OfferDetail}
            />

            {/* News */}
            <PrivateRoute exact path="/admin/noticias" component={NewsAdmin} />
            <PrivateRoute
              exact
              path="/admin/noticias/:slug"
              component={NewsDetail}
            />

            {/* Updates */}
            <PrivateRoute
              exact
              path="/admin/novedades"
              component={NoveltyAdmin}
            />
            <PrivateRoute
              exact
              path="/admin/novedades/:slug"
              component={NoveltyDetail}
            />

            {/* Events */}
            <PrivateRoute exact path="/admin/events" component={EventsAdmin} />
            <PrivateRoute
              exact
              path="/admin/events/:id"
              component={EventDetail}
            />

            {/* Live */}
            <PrivateRoute exact path="/admin/seminarios" component={Seminars} />
            <PrivateRoute
              exact
              path="/admin/seminarios/:id"
              component={SeminarDetail}
            />

            {/* Library */}
            <PrivateRoute exact path="/admin/biblioteca" component={Library} />
            <PrivateRoute
              exact
              path="/admin/biblioteca/:id"
              component={LibraryDetail}
            />
            <PrivateRoute
              exact
              path="/admin/biblioteca/crear"
              component={CreateMedia}
            />
            <PrivateRoute
              exact
              path="/admin/biblioteca/categorias"
              component={Resourcecategories}
            />

            <PrivateRoute
              exact
              path="/evento/detalle"
              component={EventDetail}
            />
          </Switch>
          <Footer />
          <AdminMobileMenu action={handleClickAdd} />
        </main>
      </Box>

      <Drawer
        variant="temporary"
        anchor={isTablet ? "bottom" : "right"}
        ModalProps={{ keepMounted: true }}
        open={Boolean(anchorAdd)}
        onClose={handleAddClose}
        classes={{ paper: classes.dropdownActions }}
      >
        <Box className={classes.containerActions}>
          <MenuItem
            data-testid={"menu-usuarios"}
            onClick={() => {
              dispatch(toggleFormUsers());
              history.push("/admin/usuarios");
              handleAddClose();
            }}
          >
            <ListItemIcon>
              <PeopleAltRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t("user")}</Typography>
          </MenuItem>
          <MenuItem
            data-testid={"menu-biblioteca"}
            onClick={() => {
              dispatch(toggleFormCreateResources());
              history.push("/admin/biblioteca");
              handleAddClose();
            }}
          >
            <ListItemIcon>
              <BookmarkRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t("document")}</Typography>
          </MenuItem>
          <MenuItem
            data-testid={"menu-cursos"}
            onClick={() => {
              dispatch(toggleFormCourse());
              history.push("/admin/cursos");
              handleAddClose();
            }}
          >
            <ListItemIcon>
              <SchoolRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t("course")}</Typography>
          </MenuItem>
          <MenuItem
            data-testid={"menu-eventos"}
            onClick={() => {
              dispatch(toggleFormEvent());
              history.push("/admin/events");
              handleAddClose();
            }}
          >
            <ListItemIcon>
              <EventRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t("event")}</Typography>
          </MenuItem>
          <MenuItem
            data-testid={"menu-sliders"}
            onClick={() => {
              dispatch(toggleFormSlider());
              history.push("/admin/sliders");
              handleAddClose();
            }}
          >
            <ListItemIcon>
              <ViewCarouselRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Slider</Typography>
          </MenuItem>
          <MenuItem
            data-testid={"menu-ofertas"}
            onClick={() => {
              dispatch(toggleFormOffer());
              history.push("/admin/ofertas");
              handleAddClose();
            }}
          >
            <ListItemIcon>
              <LocalActivityRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t("offer")}</Typography>
          </MenuItem>
          {isTablet && (
            <MenuItem onClick={handleAddClose}>
              <Typography variant="inherit">{t("back")}</Typography>
            </MenuItem>
          )}
        </Box>
      </Drawer>

      <CustomSnackBar errors={[errorAuth]} msgs={[msgAuth]} />
    </ThemeProvider>
  );
}

export default AdminLayout;
