import { IHelpState, START_SEND_CONTACT_US, SUCCESS_SEND_CONTACT_US, ERROR_SEND_CONTACT_US } from '../types';

const initialState: IHelpState = {
    msg: null,
    error: false,
    loading: false,
    reload: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_SEND_CONTACT_US:
            return {
                ...state,
                msg: null,
                error: false,
                loading: true,
                reload: false
            }
        case SUCCESS_SEND_CONTACT_US:
            return {
                ...state,
                msg: null,
                error: false,
                loading: false,
                reload: true
            }
        case ERROR_SEND_CONTACT_US:
            return {
                ...state,
                msg: action.payload,
                error: true,
                loading: false,
                reload: false
            }
        default:
            return state;
    }
}