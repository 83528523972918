import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Grid, Container, Divider, Typography, Box, Button, makeStyles, Card, CardHeader, CardContent, List } from '@material-ui/core';
import { NavigateBeforeRounded } from "@material-ui/icons/";

// Custom components
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import ContentFilter from '../../../components/layouts/filters/ContentFilter';
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../../components/common/ScreenLoading';
import CardHero from '../../../components/common/client/CardHero';
import ShareIcon from '../../../components/common/ShareIcon';
import ButtonBack from '../../../components/common/ButtonBack';

// Custom Hooks
import useSkeleton from '../../../components/layouts/navigation/SkeletonLayout';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../../redux/actions/favorites';
import { getClientNovelties } from '../../../redux/actions/novelties';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Types
interface INovelty {
    id: number,
    brand: any,
    visibility: number,
    creator: string,
    title: string,
    slug: string,
    content: string,
    image_url: string,
    created_at: string
}

// Custom Style
const ResourceStyle = makeStyles(theme => ({
    title: {
        display: 'grid',
        gap: 24,
        padding: 0,
        paddingTop: 40,
        paddingBottom: 16,
    },
    containercarousel: {
        position: "relative",
    },
    contentFeed: {
        padding: '0px 0px 8px 0px',
        '&:last-child': {
            paddingBottom: 8,
        },
    },
    card: {
        boxShadow: 'none',
        backgroundColor: 'transparent'
    }
}));

function NewsPanel() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Styles
    const classes = ResourceStyle();

    //Redux Hooks
    const dispatch = useDispatch();
    const { news, error: errorNovelties, msg: msgNovelties, loading } = useSelector((state: IRootState) => state.novelty);
    const { globalFamilyId, globalBrandId, globalCountryId, globalLanguageId, globalTag, globalRoleId } = useSelector((state: IRootState) => state.global);

    const [highlightNews, setHighlightNews] = useState<INovelty[]>([]);
    const [noHighlightNews, setNoHighlightNews] = useState<INovelty[]>([]);
    const { loading: loadingSkeleton, component: componentSkeleton } = useSkeleton();

    useEffect(() => {
        if (news.length > 0) {
            let tmpHighlightNews = news.filter((currentNews: any) => {
                if (currentNews.highlight === 1)
                    return currentNews;
            });
            let tmpNoHighlightNews = news.filter((currentNews: any) => {
                if (currentNews.highlight === 0 || currentNews.highlight === null)
                    return currentNews;
            });

            setHighlightNews(tmpHighlightNews);
            setNoHighlightNews(tmpNoHighlightNews);
        } else {
            setHighlightNews([]);
            setNoHighlightNews([]);
        }
    }, [news]);

    useEffect(() => {
        dispatch(getClientNovelties({
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            country_id: globalCountryId,
            language_id: globalLanguageId,
            tag_id: globalTag,
            role_id: globalRoleId,
            type: 'news',
            requesttype: 2
        }));
    }, [globalFamilyId, globalBrandId, globalCountryId, globalLanguageId, globalTag, globalRoleId]);

    /* Functions */
    const cutContent = (content: string) => {
        let trimmedString: string = '';
        trimmedString = content.substr(0, Math.min(content.length, content.indexOf("</p>")))
        return trimmedString;
    }

    const handleFavoriteClick = (id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id,
                state,
                type: 'novelty',
                favoritefield: 'news'
            }));
        } else {
            dispatch(unsetFavorite({
                id,
                state,
                type: 'novelty',
                favoritefield: 'news'
            }));
        }
    }

    if (loadingSkeleton) {
        return (
            <>
                {componentSkeleton}
            </>
        )
    }

    return (

        <>

            <Header title={t('news')} />
            <SubHeader />
            <ContentFilter goBackAction={true} />

            <Container style={{ maxWidth: 1008 }}>

                <Grid container spacing={2} >
                    {(highlightNews.length >= 2) &&
                        <>

                            <Box display="flex" alignItems="center" pb={2}>
                                <Typography variant="h6" style={{ minWidth: 'fit-content' }}>{t('featured')}</Typography>
                                <Divider style={{ marginLeft: 16, flexShrink: 1 }} />
                            </Box>

                            <Grid container item alignContent="flex-start" style={{ padding: '1rem' }}>

                                <Grid item xs={(isMobile) && (highlightNews.length >= 7) ? 8 : 12}>
                                    <Carousel centerSlidePercentage={isMobile ? 80 : 40} centerMode autoPlay showThumbs={false} interval={5000} infiniteLoop className={classes.containercarousel}>
                                        {highlightNews.map((currentNews: any) => (
                                            <Box key={currentNews.id} pb={2}>
                                                <CardHero
                                                    type={3}
                                                    cardBrandName={currentNews.brand?.name || ''}
                                                    redirect={`/noticias/${currentNews.slug}`}
                                                    cardVisibility={currentNews.visibility}
                                                    cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${currentNews.image_url}`}
                                                    cardTitle={currentNews.title}
                                                />
                                            </Box>
                                        ))}
                                    </Carousel>
                                </Grid>

                                {(highlightNews.length >= 7 && isMobile) &&
                                    <Grid item xs={4} >
                                        <Card classes={{ root: classes.card }}>
                                            <CardContent className={classes.contentFeed}>
                                                <List component="nav">
                                                    {highlightNews.slice(-3).map((currentNews: any) => (
                                                        <Box key={currentNews.id} pb={2}>
                                                            <CardHero
                                                                type={0}
                                                                redirect={`/noticias/${currentNews.slug}`}
                                                                cardBrandName={currentNews.brand?.name || ''}
                                                                cardVisibility={currentNews.visibility}
                                                                cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${currentNews.image_url}`}
                                                                cardTitle={currentNews.title}
                                                                cardDescription={<div dangerouslySetInnerHTML={{ __html: cutContent(currentNews.content) }}></div>}
                                                                cardDate={`${moment(currentNews.created_at).fromNow()}`}
                                                            />
                                                        </Box>
                                                    ))}
                                                </List>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                }
                            </Grid>
                        </>
                    }

                    <Box display="flex" alignItems="center" py={1} pb={2}>
                        <Typography variant="h6" style={{ minWidth: 'max-content' }}>{t('last-news')}</Typography>
                        <Divider style={{ marginLeft: 16, flexShrink: 1 }} />
                    </Box>
                    <Grid container spacing={2} style={isMobile ? {} : { maxWidth: 900, margin: '0 auto' }}>
                        {/* Insertas .map para las demás noticias en este bloque */}
                        {noHighlightNews.map((currentNews: any, index: number) => (
                            <Grid key={currentNews.id} item xs={12} lg={4}>
                                <CardHero
                                    type={1}
                                    redirect={`/noticias/${currentNews.slug}`}
                                    cardBrandName={currentNews.brand?.name || ''}
                                    cardVisibility={currentNews.visibility}
                                    cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${currentNews.image_url}`}
                                    cardTitle={currentNews.title}
                                    cardDescription={<div dangerouslySetInnerHTML={{ __html: cutContent(currentNews.content) }}></div>}
                                    cardDate={`${moment(currentNews.created_at).fromNow()}`}
                                    cardActions={
                                        <ShareIcon
                                            visibility={currentNews.visibility}
                                            title={currentNews.title}
                                            description={currentNews.title}
                                            link={`${window.location.origin}/noticias/${currentNews.slug}${window.location.search}`}
                                            type="news"
                                            multimedia={`${process.env.REACT_APP_AWS_URL}/novelties/${currentNews.image_url}`}
                                            buttonText={t("share")}
                                        />
                                    }
                                    favoriteState={Boolean(currentNews.favoriteId)}
                                    onClickFavorite={handleFavoriteClick(currentNews.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Grid>
            </Container>

            <CustomSnackBar errors={[errorNovelties]} msgs={[msgNovelties]} />
            <ScreenLoading loadings={[loading]} />

        </>

    );
}

export default NewsPanel;