// React
import { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// Material UI
import {
    Container, DialogActions, Dialog, DialogTitle, DialogContent,
    Button, Box, Grid, Menu, MenuItem, IconButton, Typography, Tooltip, Fade
} from '@material-ui/core';
import { CloseRounded, Delete, InfoOutlined, MoreVert } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../../config/table';

// Commons components
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../../components/common/ScreenLoading';
import EventsModal from '../../../components/events/EventsModal';
import CountriesFilter from '../../../components/common/admin/CountryFilter';
import CustomChip from '../../../components/common/admin/CustomChip';
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import AdminContentFilter from '../../../components/layouts/filters/AdminContentFilter';
import VisibilityIcon from '../../../components/common/admin/VisibilityIcon';
import NotFilter from '../../NotFilter';
import { CancelButton, DeleteButton } from '../../../components/common/forms';

// Redux Actions
import { getFilteredEvents, showEvent, deleteEvent, toggleFormEvent } from '../../../redux/actions/events';
import { getBrands } from '../../../redux/actions/brands'
import { IRootState } from '../../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

//Styles
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles';
import FormsStyles from '../../../styles/components/common/forms/FormsStyles';

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

function Events({ history }: RouteComponentProps) {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Styles
    const tableclass = TableScreenStyles();
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { events, showForm, error, msg, loading, reload } = useSelector((state: IRootState) => state.event);
    const { brands } = useSelector((state: IRootState) => state.brand);
    const { globalFamilyId, globalBrandId, globalLineId, globalCountriesId, globalLanguageId } = useSelector((state: IRootState) => state.global);

    // Component States
    const [openDeleteEvent, setOpenDeleteEvent] = useState(false);
    const [eventSelected, setEventSelected] = useState(false);

    // Table menu function
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(-1);

    // Variables Filter by
    const status = [{ id: 0, name: 'Activo' }, { id: 1, name: 'Inactivo' }];
    const visibility = [{ id: 0, name: t('close') }, { id: 1, name: 'VIP' }];
    const optionsData = [{ data: status, code: 'status', column: 'status' }, { data: visibility, code: 'visibility', column: 'visibility' }];

    useEffect(() => {
        if (brands.length === 0)
            dispatch(getBrands())
    }, []);

    useEffect(() => {
        if (reload) {
            dispatch(getFilteredEvents({
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                line_id: globalLineId,
                language_id: globalLanguageId,
                countries: globalCountriesId
            }));
        }
    }, [reload]);

    useEffect(() => {
        if (globalLanguageId !== null && globalCountriesId.length > 0) {
            dispatch(getFilteredEvents({
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                line_id: globalLineId,
                language_id: globalLanguageId,
                countries: globalCountriesId
            }));
        }
    }, [globalFamilyId, globalBrandId, globalLineId, globalLanguageId, globalCountriesId]);

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const handleClickSetOptions = (options: any) => {
        dispatch(getFilteredEvents({
            brand_id: globalBrandId,
            line_id: globalLineId,
            language_id: globalLanguageId,
            countries: globalCountriesId,
            filterBy: options
        }));
    };

    const columns: any = [
        {
            title: t("title"), field: 'title', render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        {(() => {
                            switch (rowData.status) {
                                case 0:
                                    return (<CustomChip size="small" color={'primary'} />)
                                case 1:
                                    return (<CustomChip size="small" color={'secondary'} />)

                                default:
                                    return (<CustomChip size="small" color={'secondary'} />)
                            }
                        })()}
                        <Typography className={tableclass.nameResource}>
                            {rowData.title}
                        </Typography>
                        <VisibilityIcon id={rowData.visibility} />
                    </Box>)
            }
        },
        { title: t("brand"), field: 'brand.name', hidden: (globalBrandId !== null), type: string },
        {
            title: t("language"), field: "language", hidden: isMobile, render: (rowData: any) => (
                rowData.languages.map((language: any) => { return language.code.toUpperCase() }).join(' - ')
            ), type: string
        },
        { title: t("rol"), field: 'roleName', hidden: isMobile },
        {
            title: t("initial-date"), field: 'date_ini', type: string, hidden: isMobile, render: (rowData: any) => (<> {rowData.date_ini} </>)
        },
        {
            title: t("final-date"), field: 'date_ini', type: string, hidden: isMobile, render: (rowData: any) => {
                if (rowData.date_end != null) {
                    return (<> {rowData.date_end} </>)
                }
            }
        },
        {
            title: '', field: '', render: (rowData: any) =>
                <>

                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}>
                        <MenuItem onClick={() => history.push(`events/${rowData.id}`)}>{t("view")}</MenuItem>
                        <MenuItem onClick={() => { dispatch(showEvent(rowData.id)); dispatch(toggleFormEvent()) }}>{t("edit")}</MenuItem>
                        <MenuItem onClick={() => { setOpenDeleteEvent(!openDeleteEvent); setEventSelected(rowData.id) }}>{t("delete")}</MenuItem>
                    </Menu>
                </>
        }
    ];

    return (
        <>
            <Header title={t("events")} />
            <SubHeader />

            <Container maxWidth="lg">
                <Box mt={isMobile ? 2 : 6} gridGap={16} display="flex" flexDirection={isMobile ? 'column-reverse' : 'column'}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h5" align="center">{t("all")}</Typography>
                        <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow
                            title={
                                <Box>
                                    <Typography variant="body2">{t('status')}:</Typography>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'primary'} />
                                        <Typography variant="caption">{t('active')}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'secondary'} />
                                        <Typography variant="caption">{t('inactive')}</Typography>
                                    </Box>
                                </Box>
                            }>
                            <IconButton>
                                <InfoOutlined htmlColor={'#8F99A3'} fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box >
                        <Grid container justify="space-between" spacing={1}>
                            <Grid item xs={5} sm={6} className={tableclass.actionsResponsive}>
                                <Button variant="outlined" color="secondary"
                                    onClick={() => dispatch(toggleFormEvent(true))}>
                                    {t("admin.events.create-event")}
                                </Button>
                            </Grid>
                            <Grid item xs={7} sm={6} className={tableclass.actionsResponsiveRight}>
                                <AdminContentFilter data={optionsData} handleClick={handleClickSetOptions} />
                                <CountriesFilter />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {!events.length && !loading ? <NotFilter subsection={null} handleClearFilters={handleClickSetOptions} /> :
                    <Box mt={3}>
                        <div className="table_custom">
                            <MaterialTable
                                localization={localization}
                                title="Eventos"
                                columns={columns}
                                data={events}
                                options={options}
                                icons={tableIcons} />
                        </div>
                    </Box>}

                {(showForm) &&
                    <EventsModal />
                }

                <Dialog
                    open={openDeleteEvent}
                    fullWidth={true}
                    fullScreen={isMobile && true}
                    maxWidth={!isMobile && "sm"}
                    className={formClasses.containerForm}
                    onClose={() => setOpenDeleteEvent(!openDeleteEvent)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t("delete")} {t("event")}
                            </Typography>
                            <Button onClick={() => setOpenDeleteEvent(!openDeleteEvent)}
                                color="primary" variant="text"><CloseRounded /></Button>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={formClasses.bodyForm}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} >
                                <Typography >
                                    {t("confirm-delete")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton onClick={() => setOpenDeleteEvent(!openDeleteEvent)}>
                            {t("cancel")}
                        </CancelButton>
                        <DeleteButton autoFocus id="btn-handlelDelete"
                            endIcon={<Delete />}
                            onClick={() => { dispatch(deleteEvent(eventSelected)); setOpenDeleteEvent(!openDeleteEvent) }}>
                            {t("delete")}
                        </DeleteButton>
                    </DialogActions>
                </Dialog>

                <CustomSnackBar errors={[error]} msgs={[msg]} />
                <ScreenLoading loadings={[loading]} />
            </Container>
        </>
    );
}

export default Events;