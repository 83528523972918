import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_UPDATE_GENERAL_SUMMARY_REPORT,
    SUCCESS_UPDATE_GENERAL_SUMMARY_REPORT,
    ERROR_UPDATE_GENERAL_SUMMARY_REPORT
} from '../types';
import { apiCall } from '../config/axios';

function* updateGeneralSummaryReport({ payload }) {
    try{
        const response = yield call(apiCall, 'put', `/generalsummaryreports/save`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_UPDATE_GENERAL_SUMMARY_REPORT, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_UPDATE_GENERAL_SUMMARY_REPORT, payload: error.message})
    }
}

export default function* generalsummaryreport() {
    yield takeLatest(START_UPDATE_GENERAL_SUMMARY_REPORT, updateGeneralSummaryReport);
}