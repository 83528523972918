// React
import { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Typography, makeStyles, Button, Box, Menu, Divider, Grid } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

// Custom Components
import OptionFilterUser from './OptionFilterUser';

import BrandInterface from '../../../interfaces/brand/BrandInterface';
import LineInterface from '../../../interfaces/brand/LineInterface';

// Redux actions
import { IRootState } from '../../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Custom Style
const FiltersStyle = makeStyles(theme => ({
    popover: {
        maxHeight: 340,
        maxWidth: 680,
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '8px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
    },
}));

// Types
type AdminUserFilterProps = {
    data: any,
    handleClick: any
}

type UserFilter = {
    contain: number,
    menus: number[],
    status: null | number,
    vip: null | number,
    brands: number[],
    lines: number[]
}

type IMenu = {
    id: number,
    name: string,
    code?: string
}

function AdminUserFilter({ data, handleClick }: AdminUserFilterProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = FiltersStyle();

    //Redux Hooks
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    const [anchorLanOrderBy, setAnchorLanOrderBy] = useState<null | HTMLElement>(null);
    const [numOptions, setNumOptions] = useState<number>(0);
    const [options, setOptions] = useState<UserFilter>({
        contain: 0,
        menus: [],
        status: null,
        vip: null,
        brands: [],
        lines: []
    });

    useEffect(() => {
        handleClick(options);
    }, [options]);

    // Change the options
    const handleClickOption = (input: string, value: number,) => (event: ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        let tmpOptions: any = { ...options };
        let tmpOption: any = tmpOptions[input];

        if (checked) {
            tmpOption.push(value);
            tmpOptions[input] = tmpOption;
            setNumOptions(numOptions + 1);
        } else {
            let indexOption = tmpOption.indexOf(value);
            tmpOption.splice(indexOption, 1);
            tmpOptions[input] = tmpOption;
            setNumOptions(numOptions - 1);
        }

        setOptions(tmpOptions);
    };

    //New filter functions
    const handleClickLanOrderBy = (event: MouseEvent<HTMLElement>) => {
        setAnchorLanOrderBy(event.currentTarget);
    };

    const handleCloseLanOrderBy = () => {
        setAnchorLanOrderBy(null);
    };

    return (
        <>
            <Box display="flex" alignItems="center">
                <Button
                    aria-controls="filter-by-menu"
                    aria-haspopup="true"
                    style={{ color: "#2E3338" }}
                    onClick={handleClickLanOrderBy}
                    endIcon={<ExpandMoreRoundedIcon color="primary" />}>
                    {isMobile ? 'Filtrar' : <Typography variant="body2"
                        style={{ fontWeight: 500 }}>Filtrar por {(numOptions > 0) && `+${numOptions}`}</Typography>}
                </Button>

                <Menu
                    id="filter-by-menu"
                    anchorEl={anchorLanOrderBy}
                    keepMounted
                    open={Boolean(anchorLanOrderBy)}
                    onClose={handleCloseLanOrderBy}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    classes={{ paper: classes.popover }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>

                    <Box p={2}>
                        <Box display="flex" alignItems="center" justifyContent="center" gridGap={8} pb="12px">
                            <Typography variant='body2' color='textSecondary'
                                style={{ height: 18 }}>{t("the-following-filters")}:</Typography>
                            {/* Opción seleccionada por default 'contiene' */}
                            <OptionFilterUser onChange={() => setOptions({ ...options, contain: 0 })} checked={options.contain === 0} text={t("contain")} />
                            <OptionFilterUser onChange={() => setOptions({ ...options, contain: 1 })} checked={options.contain === 1} text={t("not-contain")} />
                        </Box>
                        <Divider />
                        <Box display="flex" gridGap="0px 16px" pt="12px">
                            <Box display="flex" flexDirection="column">
                                <Typography variant='body2' color='textPrimary' style={{ height: 18, paddingBottom: 4 }}>{t("section").toLocaleUpperCase()}</Typography>
                                {authUser.menus.map((menu: IMenu) => (
                                    <OptionFilterUser key={menu.id} onChange={handleClickOption('menus', menu.id)} checked={options.menus.includes(menu.id)} text={t(`${menu.code}`)} />
                                ))}
                                {/* <OptionFilterUser text="Texto extenso" /> */}
                            </Box>

                            <Box display="flex" flexDirection="column">
                                <Typography variant='body2' color='textPrimary' style={{ height: 18, paddingBottom: 4 }}>{t("status").toLocaleUpperCase()}</Typography>
                                <OptionFilterUser onChange={() => setOptions({ ...options, status: 1 })} checked={options.status === 1} text={t("active")} />
                                <OptionFilterUser onChange={() => setOptions({ ...options, status: 0 })} checked={options.status === 0} text={t("inactive")} />
                                <br />
                                <Typography variant='body2' color='textPrimary' style={{ height: 18, paddingBottom: 4 }}>VIP</Typography>
                                <OptionFilterUser onChange={() => setOptions({ ...options, vip: 1 })} checked={options.vip === 1} text={t("active")} />
                                <OptionFilterUser onChange={() => setOptions({ ...options, vip: 0 })} checked={options.vip === 0} text={t("inactive")} />
                            </Box>

                            <Box>
                                <Typography variant='body2' color='textPrimary' style={{ height: 18, paddingBottom: 4 }}>{t("brand").toLocaleUpperCase()}</Typography>
                                {userBrands.slice(0, 9).map((brand: BrandInterface) => (
                                    <OptionFilterUser key={brand.id} onChange={handleClickOption('brands', brand.id)} checked={options.brands.includes(brand.id)} text={brand.name} />
                                ))}
                            </Box>
                            <Box>
                                {userBrands.slice(10).map((brand: BrandInterface) => (
                                    <OptionFilterUser key={brand.id} onChange={handleClickOption('brands', brand.id)} checked={options.brands.includes(brand.id)} text={brand.name} />
                                ))}
                            </Box>

                            {(options.brands.length === 1) &&
                                <Box>
                                    <Typography variant='body2' color='textPrimary' style={{ height: 18, paddingBottom: 4 }}>{t("line").toLocaleUpperCase()}</Typography>
                                    {userBrands.find((brand: BrandInterface) => brand.id === options.brands[0]).active_lines.map((line: LineInterface) => (
                                        <OptionFilterUser key={line.id} onChange={handleClickOption('lines', line.id)} checked={options.lines.includes(line.id)} text={line.name} />
                                    ))}
                                </Box>
                            }

                        </Box>
                    </Box>
                </Menu>
            </Box>
        </>
    )
}

export default AdminUserFilter;