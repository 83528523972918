import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


interface IResourcecategory {
    id: number,
    name: string
}

function BrandAutocomplete( {value, resourcetypeId, handleInputChange} : any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Redux global state
    const { resourcecategoriesOptions } = useSelector((state: IRootState) => state.resourcecategory);
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // Handle functions
    const handleAutoCompleteChange = (category: IResourcecategory | null) => {
        handleInputChange('category', category);
    }

    return (
        <Autocomplete
            id="autocomplete-resourcecategories"
            ChipProps={{ color: 'secondary' }}
            value={value}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={resourcecategoriesOptions.filter((category: any) => category.resourcetype_id === resourcetypeId)}
            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label={t("category")} 
                    variant="filled" 
                    InputLabelProps={{ shrink: true }} 
                    error={errorForm.resourcecategories} 
                    helperText={msgForm.resourcecategories}
                />
            }
        />
    )
}

export default BrandAutocomplete;