// React
import { Ref, ReactElement, forwardRef, lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';

// Material UI
import { Button, Dialog, DialogContent, Typography, Grid, AppBar, Toolbar, Slide, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';

import {
    useModal,
    useHideModal,
    useConfirmationModal
} from './ModalWrapperHooks';

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ModalWrapper() {

   
    const dispatch = useDispatch();
    // Get modal params from redux store
    const { modal } = useModal();
    const { handleOnClose } = useHideModal();
    // Import dynamic component according to component path param
    const CustomComponent = lazy(() => import(`${modal.modal.componentPath}`));

    return (
        <Dialog
            open={modal.showModal}
            fullScreen
            TransitionComponent={Transition}
            onClose={handleOnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <AppBar position="static">
                <Toolbar>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6" color="primary" >
                                {(modal.modal.title) && modal.modal.title}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Button autoFocus id="btn-handlelogin"
                                variant="contained"
                                color="primary"
                                style={{ marginRight: '15px' }}
                                onClick={() => { dispatch(modal.actionButton) }}>

                                {(modal.modal.textPrimaryButton) && modal.modal.textPrimaryButton}
                            </Button>
                            <IconButton edge="start" color="inherit" onClick={handleOnClose} aria-label="close">
                                <Close color="primary" />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            <DialogContent>
                <Suspense fallback={<CircularProgress disableShrink />}>
                    {modal.modal.componentPath ? (
                        <CustomComponent {...modal.modal.componentProps} />
                    ) : (
                        modal.modal.body
                    )}
                </Suspense>
            </DialogContent>

        </Dialog>
    );
}

export default ModalWrapper;