import React, { ChangeEvent } from 'react';
import { styled } from '@material-ui/core/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 1,
    paddingLeft: 4,
    maxHeight: 20,
    '& .MuiSwitch-thumb': {
        width: 14,
        height: 14,
        marginTop: '-39px',
        boxShadow:
            'inset 2px -2px 5px 0px rgba(0, 0, 0, 0.1), -1px 1px 3px 0px rgba(0, 0, 0, 0.16)',
        backgroundColor: '#fff',
    },
    '& .MuiSwitch-track': {
        border: 'none',
        borderRadius: 26 / 2,
        backgroundColor: '#eee',
        opacity: 1,
        boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s ease',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(28px) !important',
    },
}));

interface CustomSwitchComponentProps extends SwitchProps {
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const CustomSwitchComponent: React.FC<CustomSwitchComponentProps> = (props) => {
    const { onChange, ...rest } = props;

    return <CustomSwitch color='primary' {...rest} onChange={onChange} />;
};

export default CustomSwitchComponent;
