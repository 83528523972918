import {
    IGeneralSummaryReportState,
    START_UPDATE_GENERAL_SUMMARY_REPORT,
    SUCCESS_UPDATE_GENERAL_SUMMARY_REPORT,
    ERROR_UPDATE_GENERAL_SUMMARY_REPORT
} from '../types';

const initialState: IGeneralSummaryReportState = {
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_UPDATE_GENERAL_SUMMARY_REPORT:
            return {
                ...state,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_UPDATE_GENERAL_SUMMARY_REPORT:
            return {
                ...state,
                msg: null,
                error: false,
                loading: false
            }
        case ERROR_UPDATE_GENERAL_SUMMARY_REPORT:
            return {
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}