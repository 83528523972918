import {
    START_FILTER_USERS,
    START_LINK_USERS,
    START_STORE_USER,
    START_UPDATE_USER,
    START_SHOW_USER,
    START_SHOW_DEPENDENTS,
    START_GET_NOT_DEPENDENTS,
    START_SHOW_PROFILE_USER,
    START_TRAININGRESPONSIBLE_USERS,
    START_DELETE_USER,
    START_RESTORE_USER,
    START_ASSIGN_BRAND,
    START_ASSIGN_VIP_CONTENT,
    START_SHOW_VIP_CONTENT,
    START_DISABLE_VIP_CONTENT,
    START_REQUEST_VIP_CONTENT,
    START_DENY_VIP_CONTENT,
    START_ENABLE_USER,
    START_DISABLE_USER,
    START_ENABLE_DISABLE_MENU_USER,
    START_TOGGLE_FORM_USERS,
    START_TOGGLE_PROFILE_USER,
    START_TOGGLE_FORM_LINK_USERS,
    START_REGISTER_USER,
    START_VERIFY_COMMERCIALCODE,
    START_VERIFY_STATUS_USER,
    START_SETTING_USER_HIERARCHY,
    START_GET_MASSIVELY_ASSIGN,
    START_SET_MASSIVELY_ASSIGN,
    START_USER_CHANGE_PASSWORD,
    START_USER_REGISTRATIONS
} from '../types';

export const filterUsers = payload => ({
    type: START_FILTER_USERS,
    payload
})

export const linkUsers = payload => ({
    type: START_LINK_USERS,
    payload
})

export const storeUser = payload => ({
    type: START_STORE_USER,
    payload
})

export const updateUser = payload => ({
    type: START_UPDATE_USER,
    payload
})

export const showUser = payload => ({
    type: START_SHOW_USER,
    payload
})

export const showDependents = payload => ({
    type: START_SHOW_DEPENDENTS,
    payload
})

export const getNotDependents = payload => ({
    type: START_GET_NOT_DEPENDENTS,
    payload
})

export const showProfileUser = payload => ({
    type: START_SHOW_PROFILE_USER,
    payload
})

export const showTrainingResponsible = () => ({
    type: START_TRAININGRESPONSIBLE_USERS
})

export const deleteUser = payload => ({
    type: START_DELETE_USER,
    payload
})

export const restoreUser = payload => ({
    type: START_RESTORE_USER,
    payload
})

export const assingBrandOrLine = payload => ({
    type: START_ASSIGN_BRAND,
    payload
})

export const showVipContent = payload => ({
    type: START_SHOW_VIP_CONTENT,
    payload
})

export const disableVipContent = payload => ({
    type: START_DISABLE_VIP_CONTENT,
    payload
})

export const requestVipContent = payload => ({
    type: START_REQUEST_VIP_CONTENT,
    payload
})

export const denyVipContent = payload => ({
    type: START_DENY_VIP_CONTENT,
    payload
})

export const assingVipContent = payload => ({
    type: START_ASSIGN_VIP_CONTENT,
    payload
})

export const enableUser = payload => ({
    type: START_ENABLE_USER,
    payload
})

export const disableUser = () => ({
    type: START_DISABLE_USER
})

export const enableDisableMenu = payload => ({
    type: START_ENABLE_DISABLE_MENU_USER,
    payload
})

export const toggleFormUsers = () => ({
    type: START_TOGGLE_FORM_USERS
})

export const toggleFormLinkUsers = () => ({
    type: START_TOGGLE_FORM_LINK_USERS
})

export const toggleProfileUser = () => ({
    type: START_TOGGLE_PROFILE_USER
})

export const registerUser = payload => ({
    type: START_REGISTER_USER,
    payload
})

export const verifyCommercialcode = payload => ({
    type: START_VERIFY_COMMERCIALCODE,
    payload
})

export const verifyUser = payload => ({
    type: START_VERIFY_STATUS_USER,
    payload
})

export const settinguserHierarchy = payload => ({
    type: START_SETTING_USER_HIERARCHY,
    payload
})

export const getMassivelyAssignData = payload => ({
    type: START_GET_MASSIVELY_ASSIGN,
    payload
})

export const setMassivelyAssign = payload => ({
    type: START_SET_MASSIVELY_ASSIGN,
    payload
})

export const changeUserPassword = payload => ({
    type: START_USER_CHANGE_PASSWORD,
    payload
})

export const showUserRegistrations = payload => ({
    type: START_USER_REGISTRATIONS,
    payload
})