// Material UI
import { Grid } from '@material-ui/core';

// Custom components
import ModalInfo from './ModalInfo';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

function AdminDetailInfo({ resourceSelected }: any) {

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    return (
        <>
            {isMobile &&
                <Grid container justify="center" spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <ModalInfo title={t("roles")} options={resourceSelected.role} />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <ModalInfo title={t("countries")} options={resourceSelected.countries} />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <ModalInfo title={t("language")} options={resourceSelected.languages} />
                    </Grid>
                </Grid>
            }

        </>
    );
}

export default AdminDetailInfo;
