import {
    START_GET_SLIDERS,
    START_GET_FILTERED_SLIDERS,
    START_GET_INDEX_SLIDERS,
    START_GET_INTERNAL_BANNER,
    START_CREATE_SLIDER,
    START_SHOW_SLIDER,
    START_DELETE_SLIDER,
    START_SLIDER_SEND_EMAIL,
    START_TOGGLE_FORM_SLIDER
} from '../types';

export const getSliders = () => ({
    type: START_GET_SLIDERS
})

export const getFilteredSliders = payload => ({
    type: START_GET_FILTERED_SLIDERS,
    payload
})

export const getIndexSliders = payload => ({
    type: START_GET_INDEX_SLIDERS,
    payload
})

export const getInternalBanner = payload => ({
    type: START_GET_INTERNAL_BANNER,
    payload
})

export const createSlider = payload => ({
    type: START_CREATE_SLIDER,
    payload
})

export const showSlider = payload => ({
    type: START_SHOW_SLIDER,
    payload
})

export const deleteSlider = payload => ({
    type: START_DELETE_SLIDER,
    payload
})


export const sendSliderEmail = payload => ({
    type: START_SLIDER_SEND_EMAIL,
    payload
})

export const toggleFormSlider = () => ({
    type: START_TOGGLE_FORM_SLIDER
})