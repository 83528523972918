// Material UI
import { styled } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';

const MenuMobileItem = styled(MenuItem)(({
    theme
}) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
    padding: "4px 12px",
    gridGap: 12,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        margin: 13,
    },
    '&.admin': {
        color: theme.palette.grey[800],
    },
    '&.client': {
        color: theme.palette.grey[600],
    },
    '&:focus': {
        borderRadius: 12,
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        fontWeight: 500,
    }
}));

export default MenuMobileItem;