// Material UI
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// Styles
import SkeletonStyle from '../../../../styles/components/layouts/navigation/SkeletonStyle';
import HiddenDesktop from '../../../common/HiddenDesktop';

//Custom Components
import HiddenMobile from '../../../common/HiddenMobile';
import CardsSkeleton from './CardsSkeleton';

//Custom Hook
import useScreenWidth from '../../../../hooks/useScreenWidth';

function HomeSkeleton() {
    //Custom Hook
    const isMobile = useScreenWidth(960);
    // Styles
    const classes = SkeletonStyle();

    return (
        <Box className={classes.bodyHomeSkeleton}>
            {/* Navbar */}
            <Box className={classes.navbarContainer}>
                <Box className={classes.navbarContentSide}>
                    <Skeleton animation='wave' width={59} height={26} variant='rect' style={{ marginRight: 32 }} />
                    <HiddenMobile display='flex'>
                        <Skeleton animation='wave' width={87} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={79} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={63} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={80} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={80} height={27} variant='text' style={{ marginRight: 24 }} />
                    </HiddenMobile>
                    <HiddenDesktop>
                        <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    </HiddenDesktop>
                </Box>
                <Box className={classes.navbarContentSide}>
                    <HiddenMobile display='flex' alignItems='center'>
                        <Skeleton animation='wave' width={208} height={32} variant='rect' className={classes.sklborderRadius} />
                        <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    </HiddenMobile>
                    <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18, marginRight: 8 }} />
                </Box>
            </Box>
            {/* Bradcrumb  */}
            <HiddenDesktop>
                <Box height={50}></Box>
            </HiddenDesktop>
            {/* Rectangle of Slider */}
            <HiddenMobile>
                <Skeleton variant='rect' animation='wave' height='27.5rem' width='100%' style={{ maxWidth: '1920px' }} />
            </HiddenMobile>
            <HiddenDesktop>
                <Box p={2}>
                    <Skeleton variant='rect' animation='wave' height='144px' width='100%' className={classes.sklborderRadius} />
                </Box>
            </HiddenDesktop>
            {/* Buttons */}
            <HiddenMobile>
                <Box py={4} display='flex' justifyContent='center'>
                    <Skeleton animation='wave' width={312} height={46} variant='text' />
                </Box>
                <Box display='flex' alignItems='center' gridGap={48} pl={7} pr={7} pb={4}>
                    <Skeleton variant='rect' animation='wave' className={classes.sklButtonWorld} />
                    <Skeleton variant='rect' animation='wave' className={classes.sklButtonWorld} />
                    <Skeleton variant='rect' animation='wave' className={classes.sklButtonWorld} />
                    <Skeleton variant='rect' animation='wave' className={classes.sklButtonWorld} />
                </Box>
            </HiddenMobile>
            {/* Menu mobile */}
            <HiddenDesktop>
                <Box className={classes.positionMenuMobile}>
                    <Box className={classes.containerMenuMobile}>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={65} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={60} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={46} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={68} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={48} height={20} variant='text' />
                        </Box>
                    </Box>
                </Box>
            </HiddenDesktop>
            <Grid container spacing={isMobile ? 5 : 4} className={classes.containerHero}>
                <Grid item xs={12} md={7} style={isMobile ? { paddingTop: 2, paddingBottom: 2 } : { padding: 16 }}>
                    <Grid item xs={12} className={classes.header}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Skeleton animation='wave' width={120} height={46} variant='text' />
                            <Skeleton animation='wave' width={65} height={30} variant='text' />
                        </Box>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                        <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                        <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.header}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Skeleton animation='wave' width={120} height={46} variant='text' />
                            <Skeleton animation='wave' width={65} height={30} variant='text' />
                        </Box>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                        <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                        <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5} style={isMobile ? { paddingTop: 0 } : { padding: 16 }}>
                    <Box>
                        <Grid item xs={12} className={classes.header}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Skeleton animation='wave' width={120} height={46} variant='text' />
                                <Skeleton animation='wave' width={65} height={30} variant='text' />
                            </Box>
                        </Grid>
                        <Skeleton variant='rect' animation='wave' height={694} width='100%' className={classes.sklborderRadius} />
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}

export default HomeSkeleton;