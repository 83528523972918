// Material UI
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const SubtitleGrey = styled(Typography)(({
    theme
}) => ({
    color: theme.palette.grey[500]
}));

export default SubtitleGrey;
