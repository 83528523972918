import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_STORE_USERSETTINGS, SUCCESS_STORE_USERSETTINGS, ERROR_STORE_USERSETTINGS,
    START_KTB_VIDEO_USERSETTINGS, SUCCESS_KTB_VIDEO_USERSETTINGS, ERROR_KTB_VIDEO_USERSETTINGS,
    START_FIRST_START_VIDEO_USERSETTINGS, SUCCESS_FIRST_START_VIDEO_USERSETTINGS, ERROR_FIRST_START_VIDEO_USERSETTINGS
} from '../types';
import { apiCall } from '../config/axios';

function* storeUsersettings({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/usersettings/store', {usersetting: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_STORE_USERSETTINGS, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_STORE_USERSETTINGS, payload: error.message})
    }
}

function* ktbVideoUsersettings() {
    try{
        const response = yield call(apiCall, 'post', '/usersettings/updatektbvideo')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_KTB_VIDEO_USERSETTINGS, payload:response.data})
    } catch (error){
        yield put({ type: ERROR_KTB_VIDEO_USERSETTINGS, payload: error.message})
    }
}

function* firstStartVideoUsersettings() {
    try{
        const response = yield call(apiCall, 'post', '/usersettings/updatefirststartvideo')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_FIRST_START_VIDEO_USERSETTINGS, payload:response.data})
    } catch (error){
        yield put({ type: ERROR_FIRST_START_VIDEO_USERSETTINGS, payload: error.message})
    }
}

export default function* logs() {
    yield takeLatest(START_STORE_USERSETTINGS, storeUsersettings);
    yield takeLatest(START_KTB_VIDEO_USERSETTINGS, ktbVideoUsersettings);
    yield takeLatest(START_FIRST_START_VIDEO_USERSETTINGS, firstStartVideoUsersettings);
}