// React
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Material UI
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  BookmarkRounded,
  EventRounded,
  FlashOnRounded,
  ForumRounded,
  LocalActivityRounded,
  SchoolRounded,
  SearchRounded,
  WifiTetheringRounded,
} from "@material-ui/icons";

// Libraries
import { useTranslation } from "react-i18next";
import moment from "moment";

//Custom Hook
import useScreenWidth from "../../../hooks/useScreenWidth";

// Custom components
import InputGlobalSearch from "./InputGlobalSearch";
import Emptyimage from "../../../assets/Images/nofilter.png";

// Redux Actions
import { IRootState } from "../../../redux/types";

// Styles
import GlobalSearchStyles from "../../../styles/components/layouts/navigation/GlobalSearchStyles";
import HiddenMobile from "../../common/HiddenMobile";
import HiddenDesktop from "../../common/HiddenDesktop";
import ButtonNavbar from "./ButtonNavbar";
import { Skeleton } from "@material-ui/lab";

function GlobalSearch(props: any) {
  // Styles
  const classes = GlobalSearchStyles();

  // Translation
  const { t } = useTranslation(["global"]);

  //Redux Hooks
  const history = useHistory();
  const { globalSearch, loadingSearch } = useSelector(
    (state: IRootState) => state.global
  );

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function redirectHit(type: string, path: string, data: any) {
    handleClose();
    if ("course" === type) {
      history.push(`/${path}/${data.brand_id}/${data.slug}`);
    } else if ("novelty" === type) {
      history.push(`/${path}/${data.slug}`);
    } else {
      history.push(`/${path}/${data.id}`);
    }
  }

  function ResourceHit({ hit }: any) {
    return (
      <ListItem
        button
        onClick={() => {
          redirectHit("resource", "biblioteca", hit);
        }}
      >
        <ListItemIcon classes={{ root: classes.containerIcon }}>
          <BookmarkRounded />
        </ListItemIcon>
        <Box>
          <Tooltip title={hit.alias === null ? hit.file_name : hit.alias} arrow>
            <Typography className={classes.titleItem}>
              {hit.alias === null ? hit.file_name : hit.alias}
            </Typography>
          </Tooltip>
          <Box
            display="flex"
            gridGap={8}
            alignItems="center"
            className={classes.containerCaption}
          >
            <Typography variant="caption">{hit.description}</Typography>
            <Typography>•</Typography>
            <HiddenMobile>
              <Typography variant="caption">{`${moment(hit.created_at).format(
                "LL"
              )}`}</Typography>
            </HiddenMobile>
          </Box>
        </Box>
      </ListItem>
    );
  }

  function CourseHit({ hit }: any) {
    return (
      <ListItem
        button
        onClick={() => {
          redirectHit("course", "cursos", hit);
        }}
      >
        <ListItemIcon classes={{ root: classes.containerIcon }}>
          <SchoolRounded />
        </ListItemIcon>
        <Box>
          <Tooltip title={hit.title} arrow>
            <Typography className={classes.titleItem}>{hit.title}</Typography>
          </Tooltip>
          <Box
            display="flex"
            gridGap={8}
            alignItems="center"
            className={classes.containerCaption}
          >
            <Typography variant="caption">{hit.title}</Typography>
            <Typography>•</Typography>
            <HiddenMobile>
              <Typography variant="caption">{`${moment(hit.created_at).format(
                "LL"
              )}`}</Typography>
            </HiddenMobile>
          </Box>
        </Box>
      </ListItem>
    );
  }

  function EventHit({ hit }: any) {
    return (
      <ListItem
        button
        onClick={() => {
          redirectHit("event", "eventos", hit);
        }}
      >
        <ListItemIcon classes={{ root: classes.containerIcon }}>
          <EventRounded />
        </ListItemIcon>
        <Box>
          <Tooltip title={hit.title} arrow>
            <Typography className={classes.titleItem}>{hit.title}</Typography>
          </Tooltip>
          <Box
            display="flex"
            gridGap={8}
            alignItems="center"
            className={classes.containerCaption}
          >
            <Typography variant="caption">{hit.title}</Typography>
            <Typography>•</Typography>
            <HiddenMobile>
              <Typography variant="caption">{`${moment(hit.created_at).format(
                "LL"
              )}`}</Typography>
            </HiddenMobile>
          </Box>
        </Box>
      </ListItem>
    );
  }

  function NoveltyHit({ hit }: any) {
    return (
      <ListItem
        button
        onClick={() => {
          hit.type === 0
            ? redirectHit("novelty", "novedades", hit)
            : redirectHit("news", "noticias", hit);
        }}
      >
        <ListItemIcon classes={{ root: classes.containerIcon }}>
          {hit.type === 0 && <FlashOnRounded />}
          {hit.type === 2 && <ForumRounded />}
        </ListItemIcon>
        <Box>
          <Tooltip title={hit.title} arrow>
            <Typography className={classes.titleItem}>{hit.title}</Typography>
          </Tooltip>
          <Box
            display="flex"
            gridGap={8}
            alignItems="center"
            className={classes.containerCaption}
          >
            <HiddenMobile>
              <Typography variant="caption">{`${moment(hit.created_at).format(
                "LL"
              )}`}</Typography>
            </HiddenMobile>
          </Box>
        </Box>
      </ListItem>
    );
  }

  function OfferHit({ hit }: any) {
    return (
      <ListItem
        button
        onClick={() => {
          redirectHit("offer", "ofertas", hit);
        }}
      >
        <ListItemIcon classes={{ root: classes.containerIcon }}>
          <LocalActivityRounded />
        </ListItemIcon>
        <Box>
          <Tooltip title={hit.title} arrow>
            <Typography className={classes.titleItem}>{hit.title}</Typography>
          </Tooltip>
          <Box
            display="flex"
            gridGap={8}
            alignItems="center"
            className={classes.containerCaption}
          >
            <Typography variant="caption">{hit.description}</Typography>
            <Typography>•</Typography>
            <HiddenMobile>
              <Typography variant="caption">{`${moment(hit.created_at).format(
                "LL"
              )}`}</Typography>
            </HiddenMobile>
          </Box>
        </Box>
      </ListItem>
    );
  }

  function SeminarHit({ hit }: any) {
    return (
      <ListItem
        button
        onClick={() => {
          redirectHit("seminar", "seminarios", hit);
        }}
      >
        <ListItemIcon classes={{ root: classes.containerIcon }}>
          <WifiTetheringRounded />
        </ListItemIcon>
        <Box>
          <Tooltip title={hit.title} arrow>
            <Typography className={classes.titleItem}>{hit.title}</Typography>
          </Tooltip>
          <Box
            display="flex"
            gridGap={8}
            alignItems="center"
            className={classes.containerCaption}
          >
            <Typography variant="caption">{hit.description}</Typography>
            <Typography>•</Typography>
            <HiddenMobile>
              <Typography variant="caption">{`${moment(hit.created_at).format(
                "LL"
              )}`}</Typography>
            </HiddenMobile>
          </Box>
        </Box>
      </ListItem>
    );
  }

  function SkeletonListItem() {
    return (
      <Box display="flex" alignItems="center" pt={1} pb={1} px={2} width="100%">
        <Skeleton
          animation="wave"
          variant="rect"
          height={32}
          width={32}
          style={{ borderRadius: 4 }}
        />
        <Box ml={2} display="flex" flexDirection="column" width="100%">
          <Skeleton animation="wave" variant="text" width="70%" height={24} />
          <Skeleton animation="wave" variant="text" width={150} />
        </Box>
      </Box>
    );
  }

  //Custom Hook
  const isMobile = useScreenWidth(960);

  return (
    <>
      <HiddenMobile>
        <Tooltip title={`${t("search")}`}>
          <Button
            onClick={handleOpen}
            disableElevation
            disableFocusRipple
            disableTouchRipple
            disableRipple
            className={classes.btnContainerInput}
          >
            <Box display="flex" className={classes.btnSimulationInput}>
              <SearchRounded />
              <Typography>
                {t("searchIn") + " " + process.env.REACT_APP_NAME}
              </Typography>
            </Box>
          </Button>
        </Tooltip>
      </HiddenMobile>

      <HiddenDesktop>
        <ButtonNavbar onClick={handleOpen}>
          <SearchRounded />
        </ButtonNavbar>
      </HiddenDesktop>

      <Dialog
        open={open}
        fullWidth={true}
        fullScreen={isMobile && true}
        maxWidth={!isMobile && "md"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        classes={{ paper: classes.containerTransparent }}
        aria-describedby="alert-dialog-description"
      >
        <InputGlobalSearch handleClose={handleClose} />

        <DialogContent
          className={classes.containerModal}
          style={loadingSearch ? { overflow: "hidden" } : {}}
        >
          <Box className={classes.containerHero} pl={1}>
            <Typography variant="body2" color="textSecondary">
              Resultados de la búsqueda
            </Typography>
          </Box>

          {loadingSearch ? (
            <>
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
              <SkeletonListItem />
            </>
          ) : (
            <>
              {globalSearch.count === 0 && (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box my={2}>
                    <img
                      src={Emptyimage}
                      width={isMobile ? "200px" : "250px"}
                      alt="logo"
                    />
                  </Box>
                  <>
                    <Typography variant="subtitle1" align="center">
                      {t("search-empty-text-hero")}
                    </Typography>
                    <Typography>{t("search-empty-text-caption")}</Typography>
                  </>
                </Box>
              )}
              {globalSearch.resources.map((resource: any) => (
                <ResourceHit key={resource.id} hit={resource} />
              ))}

              {globalSearch.courses.map((course: any) => (
                <CourseHit key={course.id} hit={course} />
              ))}

              {globalSearch.events.map((event: any) => (
                <EventHit key={event.id} hit={event} />
              ))}

              {globalSearch.novelties.map((novelty: any) => (
                <NoveltyHit key={novelty.id} hit={novelty} />
              ))}

              {globalSearch.offers.map((offer: any) => (
                <OfferHit key={offer.id} hit={offer} />
              ))}

              {globalSearch.seminars.map((seminar: any) => (
                <SeminarHit key={seminar.id} hit={seminar} />
              ))}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default GlobalSearch;
