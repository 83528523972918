import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import mainTheme from "../../themes/ClientTheme";

export default makeStyles((theme) => createStyles({
    containerFilter: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 16,
        '&.noPadding':{
            padding: '0 !important',
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
            maxHeight: 64,
        },
    },
    containerResp: {
        justifyContent: 'space-between',
        padding: '0px',
        width: 'fit-content',
        gap: 8,
    },
    startIcon: {
        margin: 0,
    },
    content: {
        color: mainTheme.palette.grey[400],
    },
    contained: {
        borderRadius: 4,
    },
    languageButton: {
        color: theme.palette.grey[800],
        [theme.breakpoints.down('md')]: {
            paddingTop: 22,
            paddingBottom: 22
        },
    },
    noLine: {
        borderRight: "none !important",
    },
    buttonActive: {
        color: mainTheme.palette.primary.main,
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 170,
        [theme.breakpoints.down('xs')]: {
            borderBottom: `1px solid ${mainTheme.palette.grey[100]}`,
            height: 48,
            gap: 16,
            justifyContent: 'center',
            minWidth: '100vw',
        },
    },
    liveBtn: {
        backgroundColor: mainTheme.palette.error.main,
        color: mainTheme.palette.common.white,
        '&:hover': {
            backgroundColor: mainTheme.palette.error.dark,
        }
    }
}));