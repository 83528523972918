// React
import { ReactNode } from 'react'
// Material UI
import { Button } from '@material-ui/core';

// Types
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  noPadding?: boolean,
}

function TabPanel(props: TabPanelProps) {
    // Props
    const { children, value, index, noPadding, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {noPadding !== true && <Button disabled variant="text" color="secondary" />}
                    {children}
                </>
            )}
        </div>
    );
}


export default TabPanel;