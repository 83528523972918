// React
import { memo } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//Material UI
import { Card, CardContent, Typography, CardMedia, Box, CardActions, } from '@material-ui/core';
import { BusinessRounded, QueryBuilderRounded } from '@material-ui/icons';
import clsx from 'clsx';

// Libraries
import { useTranslation } from "react-i18next";

// Custom componentes
import LinearProgress from './LinearProgress';
import CustomChip from '../common/admin/CustomChip'
import ShareIcon from '../common/ShareIcon'
import Visibility from '../common/client/Visibility';
import TooltipCards from '../common/typography/TooltipCards';
import HiddenMobile from '../common/HiddenMobile';
import HiddenDesktop from '../common/HiddenDesktop';
import FavoriteButton from '../common/FavoriteButton';

//Styles
import VipStyles from '../../styles/components/common/VipStyles';
import cardStyles from '../../styles/components/courses/CoursesStyles';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';

// Custom Hooks
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";
import useScreenWidth from '../../hooks/useScreenWidth';

// Types
type Course = {
    id: number,
    brand_id: number,
    brandName: string,
    favoriteId: number,
    title: string,
    slug: string,
    description: string,
    duration: number,
    image_url: string,
    status: number,
    visibility: number
}
type CourseBlockProps = {
    course: Course,
    hasaccess: boolean,
    progress?: { course_id: number, value: number }
}

function CourseBlock({ course, hasaccess, progress }: CourseBlockProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    const history = useHistory();

    // Styles
    const classes = cardStyles();
    const vipclasses = VipStyles();

    // Redux Hooks
    const dispatch = useDispatch();
    const { handleClickGeneralSummaryReport } = useGeneralSummaryReport([], 0, 3);

    /* Functions */
    const showEraserChip = (status: any) => {
        if (status === 2) {
            return (
                <CustomChip type={2} label={t('draft')} color={'grey'} />
            )
        }
    };

    const handleLink = () => {
        handleClickGeneralSummaryReport(course.id)
        history.push(`/cursos/${course.brand_id}/${course.slug}`)
    };

    const handleFavoriteClick = (course_id: number) => (state: boolean) => {
        handleClickGeneralSummaryReport(course_id)
        if (state) {
            dispatch(setFavorite({
                id: course_id,
                state,
                type: 'course',
                favoritefield: 'courses'
            }));
        } else {
            dispatch(unsetFavorite({
                id: course_id,
                state,
                type: 'course',
                favoritefield: 'courses'
            }));
        }
    };

    return (

        <>
            <Card className={clsx(classes.card, (course.visibility === 1) && vipclasses.shadow)}>
                <CardMedia
                    onClick={() => handleLink()}
                    image={`${course.image_url}?${new Date().getTime()}`}
                    title={course.title}
                    className={classes.media}>
                    {(course.visibility === 1) &&
                        <Box textAlign="center" className={vipclasses.label}>
                            <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
                        </Box>
                    }
                </CardMedia>

                <CardContent classes={{ root: classes.cardText1 }}>
                    <Box
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleLink()} >
                        <LinearProgress progress={progress ? progress.value : 0} vip={course.visibility === 1} />
                        <TooltipCards titleCard={course.title} classNameCustom='courseTitle' variantCustom='subtitle1' />
                        <Box minHeight='19.5px'>
                            {course.duration !== 0 &&
                                <Box display='flex'
                                    alignItems="center"
                                    gridGap={4}
                                >
                                    <QueryBuilderRounded fontSize="small" htmlColor="#8F99A3" />
                                    <Typography variant="body1" className={classes.duration}>{t("duration")}: {course.duration + ' min'}</Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </CardContent>

                <CardActions style={{ padding: 0, justifyContent: 'space-between' }} disableSpacing>
                    <Box display='flex' pl={isMobile ? '6px' : 1}>
                        <FavoriteButton type='icon' handleClick={handleFavoriteClick(course.id)} favoriteState={Boolean(course.favoriteId)} />
                        <HiddenMobile>
                            {showEraserChip(course.status)}
                            <ShareIcon
                                meta={true}
                                visibility={course.visibility}
                                title={course.title}
                                type="course"
                                description={course.description}
                                link={`${window.location.origin}/cursos/${course.brand_id}/${course.slug}${window.location.search}`}
                                multimedia={`${course.image_url}?${new Date().getTime()}`}
                                buttonText={t("share")}
                                foreignId={course.id}
                            />
                        </HiddenMobile>
                        <HiddenDesktop>
                            <ShareIcon
                                visibility={course.visibility}
                                title={course.title}
                                description={course.description}
                                link={`${window.location.origin}/cursos/${course.brand_id}/${course.slug}${window.location.search}`}
                                type="course"
                                multimedia={`${course.image_url}?${new Date().getTime()}`}
                                foreignId={course.id}
                            />
                        </HiddenDesktop>
                    </Box>
                    <Box width='max-width' height='48px' display="flex" alignItems='flex-end' flexDirection='column' pr={isMobile ? '6px' : 1} onClick={() => handleLink()} style={{ cursor: 'pointer' }}>
                        <Box>
                            <Visibility variant={isMobile && 5} type={course.visibility} />
                        </Box>
                        <HiddenDesktop>
                            <Typography variant="caption" color="textSecondary">• {course.brandName}</Typography>
                        </HiddenDesktop>

                        <HiddenMobile display="flex" alignItems="center" gridGap={8}>
                            <BusinessRounded fontSize="small" htmlColor="#8F99A3" />
                            <Typography variant="body2" color="textSecondary">{course.brandName}</Typography>
                        </HiddenMobile>
                    </Box>
                </CardActions>

            </Card>

        </>
    );
}

export default memo(CourseBlock);
