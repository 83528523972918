import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Grid, Divider, Typography, Box, makeStyles, Card, CardContent, List } from '@material-ui/core';

// Custom components
import CustomSnackBar from '../common/admin/CustomSnackBar';
import ScreenLoading from '../common/ScreenLoading';
import CardHero from '../common/client/CardHero';
import ShareIcon from '../common/ShareIcon';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Types
interface INovelty {
    id: number,
    brand: any,
    visibility: number,
    creator: string,
    title: string,
    slug: string,
    content: string,
    image_url: string,
    created_at: string
}

// Custom Style
const ResourceStyle = makeStyles(theme => ({
    title: {
        display: 'grid',
        gap: 24,
        padding: 0,
        paddingTop: 40,
        paddingBottom: 16,
    },
    containercarousel: {
        position: "relative",
    },
    contentFeed: {
        padding: '0px 0px 8px 0px',
        '&:last-child': {
            paddingBottom: 8,
        },
    },
    card: {
        boxShadow: 'none',
        backgroundColor: 'transparent'
    },
}));

function NoveltyPanel({ novelties }: any) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const classes = ResourceStyle();

    //Redux Hooks
    const dispatch = useDispatch();
    const { error: errorNovelties, msg: msgNovelties, loading } = useSelector((state: IRootState) => state.novelty);

    const [highlightNovelties, setHighlightNovelties] = useState<INovelty[]>([]);
    const [noHighlightNovelties, setNoHighlightNovelties] = useState<INovelty[]>([]);

    useEffect(() => {
        if (novelties.length > 0) {
            let tmpHighlightNovelties = novelties.filter((novelty: any) => {
                if (novelty.highlight === 1)
                    return novelty;
            });
            let tmpNoHighlightNovelties = novelties.filter((novelty: any) => {
                if (novelty.highlight === 0 || novelty.highlight === null)
                    return novelty;
            });

            setHighlightNovelties(tmpHighlightNovelties);
            setNoHighlightNovelties(tmpNoHighlightNovelties);
        } else {
            setHighlightNovelties([]);
            setNoHighlightNovelties([]);
        }
    }, [novelties]);

    /* Functions */
    const cutContent = (content: string) => {
        let trimmedString: string = '';
        trimmedString = content.substring(0, Math.min(content.length, content.indexOf("</p>")));
        return trimmedString;
    }

    const handleFavoriteClick = (id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id,
                state,
                type: 'novelty',
                favoritefield: 'novelties'
            }));
        } else {
            dispatch(unsetFavorite({
                id,
                state,
                type: 'novelty',
                favoritefield: 'novelties'
            }));
        }
    }

    return (

        <>


            {(highlightNovelties.length >= 2) &&
                <>
                    <Box display="flex" alignItems="center" pb={3}>
                        <Typography variant="h6" style={{ minWidth: 'fit-content' }}>{t('featured')}</Typography>
                        <Divider style={{ marginLeft: 16, flexShrink: 1 }} />
                    </Box>
                    <Grid container alignContent="flex-start" style={isMobile ? { margin: '0px -12px', width: '100vw' } : { padding: '1rem' }}>

                        <Grid item xs={(isMobile) && (highlightNovelties.length >= 7) ? 8 : 12}>
                            <Carousel centerSlidePercentage={isMobile ? 80 : 40} centerMode autoPlay showThumbs={false} interval={5000} infiniteLoop className={classes.containercarousel}>
                                {
                                    highlightNovelties.map((novelty: any) => (
                                        <Box p={1} key={novelty.id}>
                                            <CardHero
                                                type={3}
                                                redirect={`/novedades/${novelty.slug}`}
                                                cardBrandName={novelty.brand?.name || ''}
                                                cardVisibility={novelty.visibility}
                                                cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${novelty.image_url}`}
                                                cardTitle={novelty.title}
                                            />
                                        </Box>
                                    ))
                                }

                            </Carousel>
                        </Grid>
                        {(highlightNovelties.length >= 7 && isMobile) &&
                            <Grid item xs={4}>
                                <Card classes={{ root: classes.card }}>
                                    <CardContent className={classes.contentFeed}>
                                        <List component="nav">
                                            {highlightNovelties.slice(-3).map((novelty: any) => (
                                                <Box pb={2} key={novelty.id}>
                                                    <CardHero
                                                        type={0}
                                                        redirect={`/novedades/${novelty.slug}`}
                                                        cardBrandName={novelty.brand?.name || ''}
                                                        cardVisibility={novelty.visibility}
                                                        cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${novelty.image_url}`}
                                                        cardTitle={novelty.title}
                                                        cardDescription={<div dangerouslySetInnerHTML={{ __html: cutContent(novelty.content) }}></div>}
                                                        cardDate={`${moment(novelty.created_at).fromNow()}`}
                                                    />
                                                </Box>
                                            ))}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                    </Grid>
                </>
            }

            <Box display="flex" alignItems="center" pt={1} pb={3}>
                <Typography variant="h6" style={{ minWidth: 'fit-content' }}>{t('last-novelties')}</Typography>
                <Divider style={{ marginLeft: 16, flexShrink: 1 }} />
            </Box>
            <Grid container spacing={2} >
                {noHighlightNovelties.map((novelty: any, index: number) => (
                    <Grid item xs={12} md={4} key={novelty.id}>
                        <CardHero
                            type={1}
                            redirect={`/novedades/${novelty.slug}`}
                            cardBrandName={novelty.brand?.name || ''}
                            cardVisibility={novelty.visibility}
                            cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${novelty.image_url}`}
                            cardTitle={novelty.title}
                            cardDescription={<div dangerouslySetInnerHTML={{ __html: cutContent(novelty.content) }}></div>}
                            cardDate={`${moment(novelty.created_at).fromNow()}`}
                            cardActions={
                                <ShareIcon
                                    visibility={novelty.visibility}
                                    title={novelty.title}
                                    description={novelty.title}
                                    link={`${window.location.origin}/novedades/${novelty.slug}${window.location.search}`}
                                    type="novelty"
                                    multimedia={`${process.env.REACT_APP_AWS_URL}/novelties/${novelty.image_url}`}
                                    buttonText={t("share")}
                                />
                            }
                            favoriteState={Boolean(novelty.favoriteId)}
                            onClickFavorite={handleFavoriteClick(novelty.id)}
                        />
                    </Grid>
                ))}
            </Grid>

            <CustomSnackBar errors={[errorNovelties]} msgs={[msgNovelties]} />
            <ScreenLoading loadings={[loading]} />

        </>

    );
}

export default NoveltyPanel;