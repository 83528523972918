import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_COURSETYPES, SUCCESS_GET_COURSETYPES, ERROR_GET_COURSETYPES
} from '../types';
import { apiCall } from '../config/axios';

function* getCoursetypes() {
    try {
        const response = yield call(apiCall, 'get', '/coursetypes/index/' + localStorage.getItem('globalLanguageId'))
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_COURSETYPES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_COURSETYPES, payload: error.message })
    }
}

export default function* coursetypes() {
    yield takeLatest(START_GET_COURSETYPES, getCoursetypes);
}