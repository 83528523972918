// React
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';

// Material UI
import { Button, Typography, Box, Tooltip, Chip, Card, CardMedia, CardContent, CardActions } from '@material-ui/core';
import { ArrowForwardRounded, FiberManualRecord, LabelImportantRounded } from '@material-ui/icons';

// Custom Component
import ShareIcon from '../common/ShareIcon';
import FavoriteButton from "../common/FavoriteButton";
import TooltipCards from "../common/typography/TooltipCards";

// Custom Hooks
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';
import clsx from 'clsx';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import SeminarStyles from '../../styles/components/seminars/SeminarStyles';
import VipStyles from '../../styles/components/common/VipStyles';


// Types
type SeminarCardProps = {
    seminar: {
        id: number,
        favoriteId: number | null,
        cover: null | string,
        title: string,
        description: string,
        datetime: null | string,
        visibility: number
    }
    type: number, //Temporal
}
//Type 0 = es para los slider

function SeminarCard({ seminar, type }: SeminarCardProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = SeminarStyles();
    const vipclasses = VipStyles();
    const history = useHistory();

    const dispatch = useDispatch();

    const { handleClickGeneralSummaryReport } = useGeneralSummaryReport([], 0, 8);

    /**Functions */
    const handleFavoriteClick = (seminar_id: number) => (state: boolean) => {
        handleClickGeneralSummaryReport(seminar_id)
        if (state) {
            dispatch(setFavorite({
                id: seminar_id,
                state,
                type: 'seminar'
            }));
        } else {
            dispatch(unsetFavorite({
                id: seminar_id,
                state,
                type: 'seminar'
            }));
        }
    };

    const handleClickCard = () => {
        handleClickGeneralSummaryReport(seminar.id)
        history.push(`/seminarios/${seminar.id}`)
    }

    let inLive = null
    let vip = null

    return (
        <>
            {type === 0
                ?
                <Box position="relative" overflow="hidden" style={{ backgroundImage: `url(${process.env.REACT_APP_AWS_URL + '/seminars/' + seminar.cover})` }} className={classes.imgPrimary}>
                    <Tooltip title={seminar.title} arrow>
                        <Box
                            onClick={() => handleClickCard()}
                            className={classes.contentPrimary}>
                            <Box display='flex' gridGap={16} alignItems='center'>
                                <Typography variant='h4' align='left'>{seminar.title}</Typography>
                                {inLive && <Chip icon={<FiberManualRecord fontSize="small" />} label='En vivo' className={classes.labelLive} />}
                            </Box>
                            {/* <Box display='flex' alignItems='bottom' gridGap={4}>
                                <PersonRounded />
                                <Typography variant='subtitle1'>{talker}</Typography>
                            </Box> */}
                            {(seminar.datetime !== null) &&
                                <Typography>{`${moment(seminar.datetime).format('LLLL')}`}</Typography>
                            }
                            <Button variant='text' className={classes.linkPrimary} endIcon={<ArrowForwardRounded />}>
                                <Typography>{t('more-info')}</Typography>
                            </Button>
                        </Box>
                    </Tooltip>
                    {vip === true &&
                        <Box position='absolute' className={vipclasses.slider} >
                            <Typography>VIP</Typography>
                        </Box>
                    }
                </Box>
                :
                <>
                    <Card className={clsx(classes.card, (seminar.visibility === 1) && vipclasses.shadow)}>
                        <>
                            <CardMedia
                                image={`${process.env.REACT_APP_AWS_URL + '/seminars/' + seminar.cover}`}
                                component='img'
                                alt={seminar.title}
                                height='130'
                                classes={{ root: classes.imgBlock }}
                                onClick={() => { history.push(`/seminarios/${seminar.id}`) }}
                            />

                            <CardContent style={{ padding: 8, paddingBottom: 0 }}>
                                <Box
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { history.push(`/seminarios/${seminar.id}`) }} >
                                    <TooltipCards titleCard={seminar.title} classNameCustom='resourceTitle' />
                                    {/* Este apartado va a utilizarce para las etiquetas */}
                                    {seminar.title === null &&
                                        <Box minHeight='19.5px'>
                                            <Box display='flex'
                                                alignItems="center"
                                                gridGap={4}
                                            >
                                                <LabelImportantRounded fontSize="small" htmlColor="#8F99A3" />
                                                <Typography variant="body2" color="textSecondary">P&Q</Typography>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </CardContent>
                            <CardActions 
                                style={isMobile
                                    ? { padding: '0px 6px 0px 0px', justifyContent: 'space-between' }
                                    : { padding: '0px 12px', justifyContent: 'space-between' }}
                            disableSpacing>
                                <Box display='flex' pl={isMobile && '6px'}>
                                    <FavoriteButton handleClick={handleFavoriteClick(seminar.id)} favoriteState={Boolean(seminar.favoriteId)} />
                                    <ShareIcon
                                        visibility={seminar.visibility}
                                        title={seminar.title}
                                        description={seminar.description}
                                        link={`${window.location.origin}/seminarios/${seminar.id}${window.location.search}`}
                                        multimedia={(seminar.cover === null) ? '' : seminar.cover}
                                        type="eventLive"
                                        multimediaType={'image'}
                                        buttonText={t("share")}
                                    />
                                </Box>
                                <Box width='max-width' height='48px' display="flex" alignItems='center' pr={isMobile ? 2 : 1} onClick={() => { history.push(`/seminarios/${seminar.id}`) }} style={{ cursor: 'pointer' }}>
                                    {(seminar.datetime !== null) &&
                                        <Typography variant="body2" color="textSecondary">{`${moment(seminar.datetime).endOf('hour').fromNow()}`}</Typography>
                                    }
                                </Box>
                            </CardActions>
                        </>
                        {vip === true &&
                            <Box position='absolute' className={clsx(vipclasses.slider, 'block')} >
                                <Typography>VIP</Typography>
                            </Box>
                        }
                        {inLive &&
                            <Box position='absolute' top={24} right={vip === true ? 80 : 16}>
                                <Chip icon={<FiberManualRecord fontSize="small" />} label='En vivo' className={classes.labelLive} />
                            </Box>
                        }
                    </Card>
                </>
            }
        </>
    );
}

export default SeminarCard;