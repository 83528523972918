import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


interface IRole {
    id: number,
    name: string
}

function RoleAutocomplete({ value, handleInputChange }: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Redux global state
    const { roles } = useSelector((state: IRootState) => state.role);
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // Handle fnuctions
    const handleAutoCompleteChange = (role: IRole | null) => {
        handleInputChange('role', role);
    }

    return (
        <Autocomplete
            id="autocomplete-roles"
            value={value}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={roles.filter((role: any) => role.code > 3)}
            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
            renderInput={(params) =>
                <TextField
                    required
                    {...params}
                    label={t("roles")}
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    error={errorForm.role}
                    helperText={msgForm.role}
                />
            }
        />
    )
}

export default RoleAutocomplete;