// React
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Container, DialogActions, Dialog, DialogTitle, DialogContent, Button, Slide,
    Box, Typography, Grid, Tab, Tabs, TextField, FormHelperText,
    Accordion, AccordionSummary, AccordionDetails, Divider
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Delete, CloseRounded, ExpandMore } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Custom components
import TabPanel from '../common/admin/TabPanel';
import CustomChip from '../common/admin/CustomChip'
import { CancelButton, DatePicker, DeleteButton, SaveButton } from '../common/forms';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Redux Actions
import { IRootState } from '../../redux/types'
import { filterLogs } from '../../redux/actions/log';

// Translate
import { useTranslation } from "react-i18next";

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import authReducer from '../../redux/reducers/authReducer';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Types
type ILog = {
    id: number,
    actionId: number,
    name: string,
    data: string,
    date: string
}

function UserHistory({ user_id }: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Styles
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { globalLanguageId } = useSelector((state: IRootState) => state.global);
    const { isValidForm, errorForm, errorTab, msgForm } = useSelector((state: IRootState) => state.validator);
    const { logs } = useSelector((state: IRootState) => state.log);

    const [openVIP, setOpenVIP] = useState(false);
    const [open, setOpen] = useState(false);

    //Tabs
    const [currentTab, setCurrentTab] = useState(0);
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        if (authUser != null) {
            let userId = null;
            if (user_id != null)
                userId = user_id;
            else if (user_id == null)
                userId = authUser.id;

            dispatch(filterLogs({ language_id: globalLanguageId, systemaction_id: 1, user_id: userId }));
        }
    }, [globalLanguageId, authUser]);

    const handleClose = () => {
        setOpen(false);
    };

    const columnsSystem: any = [
        { title: "Encargado", field: 'created_by' },
        {
            title: t("action"), field: 'name', render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8} height={24} p={1}>
                    {[3, 5, 14].includes(rowData.actionId) &&
                        <CustomChip type={2} color="primary" label={rowData.name} />
                    }
                    {(rowData.actionId === 4) &&
                        <CustomChip type={2} color="secondary" label={rowData.name} />
                    }
                    {(rowData.actionId === 6) &&
                        <CustomChip type={2} color="default" label={rowData.name} />
                    }
                </Box>
            )
        },
        { title: t('data'), field: 'data', hidden: isMobile },
        { title: t("date"), field: 'date' },
    ];

    const columnsVip: any = [
        { title: "Encargado", field: 'created_by' },
        {
            title: t("action"), field: 'name', render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8} height={24} p={1}>
                    {(rowData.actionId === 1) &&
                        <CustomChip type={2} color="primary" label={rowData.name} />
                    }
                    {(rowData.actionId === 2) &&
                        <CustomChip type={2} color="secondary" label={rowData.name} />
                    }
                    {(rowData.actionId === 7) &&
                        <CustomChip type={2} color="default" label={rowData.name} />
                    }
                </Box>
            )
        },
        { title: t("date"), field: 'date' },
    ];

    const columnsFav: any = [
        { title: "Encargado", field: 'created_by' },
        {
            title: t("action"), field: 'name', render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8} height={24} p={1}>
                    {(rowData.actionId === 8) &&
                        <CustomChip type={2} color="primary" label={rowData.name} />
                    }
                    {(rowData.actionId === 9) &&
                        <CustomChip type={2} color="secondary" label={rowData.name} />
                    }
                </Box>
            )
        },
        { title: t('data'), field: 'data', hidden: isMobile },
        { title: t("date"), field: 'date' },
    ];

    const columnsSuperior: any = [
        { title: "Encargado", field: 'created_by' },
        {
            title: t("action"), field: 'name', render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8} height={24} p={1}>
                    {([10, 12].includes(rowData.actionId)) &&
                        <CustomChip type={2} color="primary" label={rowData.name} />
                    }
                    {([11, 13].includes(rowData.actionId)) &&
                        <CustomChip type={2} color="secondary" label={rowData.name} />
                    }
                </Box>
            )
        },
        { title: t('data'), field: 'data', hidden: isMobile },
        { title: t("date"), field: 'date' },
    ];

    return (

        <>
            <Container className='noPadding'>
                <Box mb={30}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Registros del Sistema</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Box mt={2} width="100%">
                                <div className="table_custom white noMenu">
                                    <MaterialTable
                                        localization={localization}
                                        title="Favoritos"
                                        columns={columnsSystem}
                                        data={logs.filter((log: ILog) => [3, 4, 5, 6, 14].includes(log.actionId))}
                                        options={options}
                                        icons={tableIcons} />
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Registros VIP</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Box mt={2} width="100%">
                                <div className="table_custom white noMenu">
                                    <MaterialTable
                                        localization={localization}
                                        title="Vip"
                                        columns={columnsVip}
                                        data={logs.filter((log: ILog) => [1, 2, 7].includes(log.actionId))}
                                        options={options}
                                        icons={tableIcons} />
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Registros de Favoritos</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Box mt={2} width="100%">
                                <div className="table_custom white noMenu">
                                    <MaterialTable
                                        localization={localization}
                                        title="Favoritos"
                                        columns={columnsFav}
                                        data={logs.filter((log: ILog) => [8, 9].includes(log.actionId))}
                                        options={options}
                                        icons={tableIcons} />
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Registros Superiores Asociados</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <Box mt={2} width="100%">
                                <div className="table_custom white noMenu">
                                    <MaterialTable
                                        localization={localization}
                                        title="Favoritos"
                                        columns={columnsSuperior}
                                        data={logs.filter((log: ILog) => [10, 11, 12, 13].includes(log.actionId))}
                                        options={options}
                                        icons={tableIcons} />
                                </div>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>


                {(open) &&
                    <>
                        <Dialog open={open}
                            fullWidth={true}
                            fullScreen={isMobile && true}
                            maxWidth={!isMobile && "md"}
                            className={formClasses.containerForm}
                            TransitionComponent={Transition}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">

                            <DialogTitle id="alert-dialog-title">
                                <Grid container justify="space-between">
                                    <Typography variant="h6">
                                        Asignación VIP
                                    </Typography>
                                    <Button onClick={handleClose}
                                        color="primary" variant="text"><CloseRounded /></Button>
                                </Grid>
                            </DialogTitle>

                            <DialogContent className={formClasses.bodyForm}>

                                <Grid item xs={12}>
                                    <Tabs
                                        value={currentTab}
                                        indicatorColor="secondary"
                                        textColor="primary"
                                        onChange={handleChangeTab}
                                        className={formClasses.tapForm}
                                        aria-label="disabled tabs example">

                                        <Tab label={t("general")}   {...a11yProps(0)} className={errorTab.general ? formClasses.errorTab : ''} />
                                    </Tabs>
                                </Grid>

                                <TabPanel value={currentTab} index={0}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={6} md={3} >
                                            <Autocomplete
                                                id="combo-box-visibility"
                                                disableClearable
                                                onChange={handleClose}
                                                options={[]}
                                                getOptionLabel={(option: any) => option.name}
                                                renderInput={(params) => <TextField {...params} label={t("brands")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                            />
                                            <FormHelperText error={errorForm.visibility}>{msgForm.visibility}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3}>
                                            <Autocomplete
                                                id="combo-box-types"
                                                disableClearable
                                                onChange={handleClose}
                                                options={[]}
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderInput={(params) => <TextField {...params} label={t("type")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                            />
                                            <FormHelperText error={errorForm.type}>{msgForm.type}</FormHelperText>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3} >
                                            <DatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy hh:mm a"
                                                label={t("start-date")}
                                                InputLabelProps={{ shrink: true, }}
                                                fullWidth
                                                value={0}
                                                onChange={handleClose}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3}>
                                            <DatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy hh:mm a"
                                                label={t("end-date")}
                                                InputLabelProps={{ shrink: true, }}
                                                fullWidth
                                                value={0}
                                                onChange={handleClose}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                            </DialogContent>


                            <DialogActions className={!isValidForm && Object.keys(msgForm).length > 0 ? formClasses.errorDialogActions : ''}>
                                <CancelButton onClick={handleClose}>
                                    {t("cancel")}
                                </CancelButton>
                                <SaveButton autoFocus id="btn-handlelogin"
                                    onClick={handleClose}>
                                    {t("save")}
                                </SaveButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openVIP}
                            fullWidth={true}
                            fullScreen={isMobile && true}
                            maxWidth={!isMobile && "sm"}
                            className={formClasses.containerForm}
                            onClose={() => setOpenVIP(!openVIP)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                <Grid container justify="space-between">
                                    <Typography variant="h6">
                                        {t("delete")} Slider
                                    </Typography>
                                    <Button onClick={() => setOpenVIP(!openVIP)} color='primary' variant="text"><CloseRounded /></Button>
                                </Grid>
                            </DialogTitle>
                            <DialogContent className={formClasses.bodyForm}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} >
                                        <Typography>{t("admin.slider.delete-slider-text")}</Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <CancelButton onClick={() => setOpenVIP(!openVIP)} variant="contained" color="secondary">{t("cancel")}</CancelButton>
                                <DeleteButton
                                    id="btn-handlelogin"
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Delete />}
                                    onClick={() => { setOpenVIP(!openVIP) }}>
                                    {t("delete")}
                                </DeleteButton>
                            </DialogActions>
                        </Dialog>
                    </>
                }
            </Container>
        </>
    );
}

export default UserHistory;