import axios from 'axios';

axios.defaults.withCredentials = true;
const baseUrl = process.env.REACT_APP_API_URL;

export const apiCall = (method, url, data) => axios({
    method,
    url: baseUrl + url,
    data, 
    headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
}).catch(function (error) {
    console.log('Axios apiCall error');
    console.log(error);

    let errorMsg = '';
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("ResposeStatus: ", error.response.status);
        if (error.response.status === 419 || error.response.status === 401)
            errorMsg = 'Sin permisos para acceder a este recurso';
        else
            errorMsg = 'El servidor ha respondido con status: ' + error.response.status + ', ponte en contacto con el administrador';
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorMsg = 'No ha sido posible conectar con el servidor'
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        errorMsg = error.message;
    }

    console.error(errorMsg)
    throw new Error(errorMsg)
});

export const apiCallNoApi = (method, url, data) => axios({
    method,
    url: process.env.REACT_APP_API_DOMAIN + url,
    data,
    headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
}).catch(error => {
    console.log('Axios apiCallNoApi error');
    console.log(error);
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status === 401) {
            // localStorage.setItem('login_redirect', window.location.pathname + window.location.search);
        }

        return error.response.status;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
    console.log(error);


    return error;
});