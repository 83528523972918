// React
import { useState, useEffect, MouseEvent, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import {
    Grid, Container, Box, Button, Typography, Menu, MenuItem, IconButton, Dialog,
    DialogActions, DialogTitle, DialogContent, Tabs, Tab, Card
} from "@material-ui/core";
import { EditRounded, MoreVert, CloseRounded, Delete } from "@material-ui/icons/";
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../../config/table';

// Custom components
import TabPanel from '../../../components/common/admin/TabPanel';
import BrandsModal from "../../../components/brand/BrandModal";
import Header from "../../../components/common/header/Header";
import SubHeader from "../../../components/common/header/Subheader";
import CustomSnackBar from "../../../components/common/admin/CustomSnackBar";
import CustomChip from "../../../components/common/admin/CustomChip";
import ScreenLoading from "../../../components/common/ScreenLoading";
import TestimonialModal from "../../../components/brand/TestimonialModal";
import { CancelButton, DeleteButton } from "../../../components/common/forms";
import ButtonBack from "../../../components/common/ButtonBack";

// Redux Actions
import { getBrands, showBrand, changeLineStatus, deleteBrandLine } from '../../../redux/actions/brands';
import { IRootState } from "../../../redux/types";
import { deleteTestimonial, showTestimonial, getTestimonials } from "../../../redux/actions/testimonials";

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../../hooks/useScreenWidth";

// STYLES
import BrandsStyle from '../../../styles/pages/brands/BrandsStyle';
import FormsStyles from "../../../styles/components/common/forms/FormsStyles";

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CoursesView() {

    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = BrandsStyle();
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { brandSelected, showForm, error, msg, loading } = useSelector((state: IRootState) => state.brand);
    const { testimonials } = useSelector((state: IRootState) => state.testimonial);

    // Component States
    const [openDeleteLine, setOpenDeleteLine] = useState(false);
    const [lineSelected, setLineSelected] = useState(false);

    // table menu function
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(-1)

    // Ready Page
    useEffect(() => {
        dispatch(getBrands());
    }, []);

    useEffect(() => {
        if (brandSelected !== null)
            dispatch(getTestimonials(brandSelected.id));
    }, [brandSelected]);

    //Tab state
    const [currentTab, setCurrentTab] = useState(0);


    // Functions 
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const columns: any = [
        { title: t("name"), field: "name", type: string },
        {
            title: t("status"), field: 'status', render: (rowData: any) => (
                rowData.status == 1 ?
                    <Typography color="textSecondary" variant="body1"><CustomChip size="small" color="primary" /> {t("active")}</Typography>
                    :
                    <Typography color="textSecondary" variant="body1"><CustomChip size="small" color="secondary" /> {t("inactive")}</Typography>
            )
        },
        {
            title: "",
            field: "",
            render: (rowData: any) => (
                <>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="false"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}
                    >
                        <MenuItem onClick={() => dispatch(changeLineStatus(rowData.id))} >
                            {(rowData.status == 1) ?
                                <>{t("inactivate")}</>
                                :
                                <>{t("activate")}</>
                            }

                        </MenuItem>
                        <MenuItem onClick={() => { setOpenDeleteLine(!openDeleteLine); setLineSelected(rowData.id) }}>
                            {t("delete")}
                        </MenuItem>
                    </Menu>
                </>
            )
        }
    ];

    const testimonialsColumns: any = [
        { title: t("title"), field: "title", type: string },
        {
            title: "",
            field: "",
            render: (rowData: any) => (
                <>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="false"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}
                    >
                        <MenuItem onClick={() => dispatch(showTestimonial(rowData.id))}>{t("edit")}</MenuItem>
                        <MenuItem onClick={() => dispatch(deleteTestimonial(rowData.id))}>{t("delete")}</MenuItem>
                    </Menu>
                </>
            )
        }
    ];

    return (
        <>
            {showForm && (
                <BrandsModal
                />
            )}

            <Header title={t("brands")} />
            <SubHeader />

            {brandSelected !== null &&

                <Box style={{ display: showForm ? "none" : "block" }} >
                    <Container maxWidth="md">

                        <Box mt={6} pr={6} pl={6} mb={3}>

                            <ButtonBack />

                            <Box mt={6} py={2} px={6} mb={3} display="flex"
                                alignItems="flex-end"
                                className={classes.hero}
                                style={{
                                    background: `linear-gradient(180deg, rgba(250, 250, 250, 0) 31.82%, #FAFAFA 119.72%), 
                                    url(${process.env.REACT_APP_AWS_URL + '/brands/' + brandSelected.background})`
                                }}>

                                <Grid container justify="space-between" spacing={3}>
                                    <Grid item>
                                        <Typography variant="h3">{brandSelected.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={() => dispatch(showBrand(brandSelected.id))}
                                            startIcon={<EditRounded />}>
                                            {t("edit")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Card>
                            <Box pr={4} pl={4}>
                                <Box mt={4} mb={6}>
                                    <Tabs
                                        value={currentTab}
                                        indicatorColor="secondary"
                                        textColor="primary"
                                        onChange={handleChangeTab}
                                        aria-label="disabled tabs example">

                                        <Tab label={t("information")}{...a11yProps(0)} />
                                        <Tab label={t("lines")} {...a11yProps(1)} />
                                        <Tab label={t("testimonials")}  {...a11yProps(2)} />
                                        <Tab label={t("photos")}  {...a11yProps(3)} />
                                    </Tabs>
                                </Box>


                                <TabPanel value={currentTab} index={0}>
                                    <Grid container
                                        alignItems="center"
                                        justify="center"
                                        spacing={4}>

                                        <Grid item xs={12} md={4}>
                                            <Typography variant="subtitle1">{'Logo ' + t("light")}</Typography>
                                            <Box p={3} display="flex" justifyContent="center" my={2} borderRadius={8} style={{ backgroundColor: "#171A1C" }}>
                                                <img src={process.env.REACT_APP_AWS_URL + '/brands/' + brandSelected.light_logo} width="100%" height="auto"
                                                    alt="light logo" />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Typography variant="subtitle1">{'Logo ' + t("dark")}</Typography>
                                            <Box p={3} display="flex" justifyContent="center" my={2} borderRadius={8} >
                                                <img src={process.env.REACT_APP_AWS_URL + '/brands/' + brandSelected.dark_logo} width="100%" height="auto" alt="Logo dark" />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Box className={classes.cardColor}>
                                                <Box p={1} gridGap={16} width="160px">
                                                    <Typography variant="body1">{'Color ' + t("main")}</Typography>
                                                    <Typography variant="caption">{brandSelected.main_color}</Typography>
                                                </Box>
                                                <div style={{
                                                    backgroundColor: `${brandSelected.main_color}`, width: "80px", borderRadius: '0px 8px 8px 0px'
                                                }}></div>
                                            </Box>


                                            <Box mt={2}>
                                                <Box className={classes.cardColor}>
                                                    <Box p={1} gridGap={16} width="160px">
                                                        <Typography variant="body1">{'Color ' + t("secondary")}</Typography>
                                                        <Typography variant="caption">{brandSelected.secondary_color}</Typography>
                                                    </Box>
                                                    <div style={{
                                                        backgroundColor: `${brandSelected.secondary_color}`, width: "80px", borderRadius: '0px 8px 8px 0px'
                                                    }}></div>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box display="grid" gridGap={16}>
                                                <Typography variant="subtitle1">{t("description")}</Typography>
                                                <Typography variant="body1">{brandSelected.description}</Typography>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </TabPanel>


                                <TabPanel value={currentTab} index={1}>

                                    {(brandSelected.lines.length > 0) &&

                                        <Box mt={2} mb={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5">Líneas vinculadas</Typography>
                                            </Grid>
                                            <div className="table_custom">
                                                <MaterialTable
                                                    localization={localization}
                                                    title={t('lines')}
                                                    columns={columns}
                                                    data={brandSelected.lines}
                                                    options={options}
                                                    icons={tableIcons}
                                                />
                                            </div>
                                        </Box>

                                    }
                                </TabPanel>

                                <TabPanel value={currentTab} index={2}>
                                    <TestimonialModal type={0} />
                                    <div className="table_custom">
                                        <MaterialTable
                                            localization={localization}
                                            title={t('testimonials')}
                                            columns={testimonialsColumns}
                                            data={testimonials.filter((testimonial: any) => testimonial.type === 0)}
                                            options={options}
                                            icons={tableIcons}
                                        />
                                    </div>
                                </TabPanel>

                                <TabPanel value={currentTab} index={3}>
                                    <TestimonialModal type={1} />
                                    <div className="table_custom">
                                        <MaterialTable
                                            localization={localization}
                                            title={t('photos')}
                                            columns={testimonialsColumns}
                                            data={testimonials.filter((testimonial: any) => testimonial.type === 1)}
                                            options={options}
                                            icons={tableIcons}
                                        />
                                    </div>
                                </TabPanel>

                            </Box>
                        </Card>


                    </Container>
                </Box>

            }

            <Dialog
                open={openDeleteLine}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={() => setOpenDeleteLine(!openDeleteLine)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t("delete")} {t("line")}
                        </Typography>
                        <Button onClick={() => setOpenDeleteLine(!openDeleteLine)} color='primary' variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} >
                            <Typography>  {t("confirm-delete")} </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setOpenDeleteLine(!openDeleteLine)} >{t("cancel")}</CancelButton>
                    <DeleteButton
                        id="btn-handlelogin"
                        startIcon={<Delete />}
                        onClick={() => { dispatch(deleteBrandLine(lineSelected)); setOpenDeleteLine(!openDeleteLine) }}>
                        {t("delete")}
                    </DeleteButton>
                </DialogActions>
            </Dialog>




            <CustomSnackBar
                errors={[
                    error
                ]}
                msgs={[msg]}
            />
            <ScreenLoading loadings={[loading]} />
        </>
    );
}

export default CoursesView;
