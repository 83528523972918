// React
import { useEffect, useState, MouseEvent } from "react";

// Material UI
import {
  Snackbar,
  Button,
  Menu,
  MenuItem,
  makeStyles,
  Tooltip,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Share, WhatsApp, FileCopyRounded } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

// Custom components
import MenuMobileItem from "../layouts/navigation/MenuMobileItem";

// Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Libraries
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

// Custom Hooks
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";

// Types
type ShareIconProps = {
  meta?: boolean;
  light?: boolean;
  component?: string;
  visibility: number;
  title: string;
  description: string;
  link: string;
  type: "resource" | "course" | "novelty" | "news" | "eventLive" | "offer" | "event";
  multimedia?: string;
  multimediaType?: string;
  buttonText?: string;
  buttonTextGreen?: string;
  foreignId?: number;
};

//Styles
const ButtonStyles = makeStyles((theme) => ({
  button: {
    padding: "4px 0px 4px 12px",
    minWidth: "auto",
    borderRadius: "100%",
    "& .MuiButton-label": {
      justifyContent: "center",
    },
  },
  buttonURL: {
    padding: "2px 8px",
    marginBottom: 7,
    marginLeft: 16,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    minWidth: "auto",
    borderRadius: 8,
    fontSize: theme.typography.caption.fontSize,
    "& .MuiButton-label": {
      justifyContent: "center",
    },
    "&:hover, &:active": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.primary.main,
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  modal: {
    backgroundColor: "rgb(51 54 56 / 87%)",
    backdropFilter: "blur(4px)",
    "& ul": {
      padding: 0,
    },
  },
  modalOpt: {
    color: theme.palette.grey[100],
    "& li": {
      display: "flex",
      gap: 8,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  icon: {
    color: theme.palette.grey[300],
  },
  lightIcon: {
    color: theme.palette.grey[50],
  },
  buttonText: {
    height: 42,
    color: theme.palette.grey[50],
    backgroundColor: theme.palette.grey[600],
  },
  buttonTextGreen: {
    height: 40,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    "& span p": {
      fontWeight: 500,
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: 44,
      height: 44,
      "& span p": {
        paddingLeft: 8,
        fontWeight: 500,
        fontSize: theme.typography.subtitle1.fontSize,
      },
    },
  },
}));

function ShareIcon({
  meta = false,
  visibility,
  component = "button",
  light = false,
  title,
  description,
  link,
  type,
  multimedia = "",
  multimediaType = "image",
  buttonText = "",
  buttonTextGreen = "",
  foreignId = 0
}: ShareIconProps) {
  // Translate
  const { t } = useTranslation(["global"]);

  // Styles
  const classes = ButtonStyles();

  const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
  const [openOptions, setOpenOptions] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const { handleClickGeneralSummaryReport } = useGeneralSummaryReport([], 0, 3);

  // mobile validate
  const isMobile = useScreenWidth(700);

  useEffect(() => {
    (function (d: any, s: any, id: any) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, [openOptions]);

  /* Functions */
  const handleCloseOptions = () => {
    setAnchorOp(null);
    setOpenOptions(false);
  };

  const handleClickWhatsapp = () => {
    let tmpText =
      light === true
        ? t("share-header-whatsapp-text", {
          APP_NAME: process.env.REACT_APP_NAME,
        }).replace(/ /g, "%20")
        : t("share-whatsapp-text", {
          type: t(type),
          APP_NAME: process.env.REACT_APP_NAME,
        }).replace(/ /g, "%20");
    window.open(
      `https://wa.me/?text=${tmpText} ${title} ${encodeURIComponent(link)}`
    );
  };

  const handleClickCopyText = () => {
    navigator.clipboard.writeText(link);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleShareButton = (event: any) => {
    handleClickGeneralSummaryReport(foreignId)
    if (isMobile) {
      handleNavigatorShare();
    } else {
      setAnchorOp(event.currentTarget);
      setOpenOptions(true);
    }
  };

  const handleNavigatorShare = () => {
    const dataShare = {
      title: title ?? '',
      text: description ?? '',
      url: link ?? '',
    };
    if ("Navigator" in window && navigator.canShare(dataShare)) {
      navigator
        .share(dataShare)
        .then(() => console.log("Successful share"))
        .catch((error) => {
          console.log("Error sharing", error);
        });
    } else {
      handleClickCopyText();
      console.log("Share not supported on this browser.");
    }
  };

  let isLight = light === true;

  return (
    <>
      {meta && (
        <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={link} />
          <meta property={`og:${multimediaType}`} content={multimedia} />
          <meta
            property={`og:"${multimediaType}:secure_url`}
            content={multimedia}
          />
          <meta property={`og:"${multimediaType}:width`} content="400" />
          <meta property={`og:"${multimediaType}:height`} content="300" />
        </Helmet>
      )}

      {component === "button" && (
        <Tooltip title={`${t("share")}`} placement="bottom">
          <IconButton
            color="default"
            aria-controls="share-link-menu"
            aria-haspopup="false"
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              handleShareButton(event);
            }}
            className='share-options'
          >
            <Share
              className={isLight ? classes.lightIcon : classes.icon}
              style={isLight ? { fontSize: 20 } : {}}
            />
          </IconButton>
        </Tooltip>
      )}
      {component === "buttonText" && (
        <Button
          variant="contained"
          color="default"
          className={`share-options ${classes.buttonText}`}
          startIcon={<Share />}
          aria-controls="share-link-menu"
          aria-haspopup="false"
          size="small"
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            handleShareButton(event);
          }}
        >
          {buttonText}
        </Button>
      )}
      {component === "buttonTextGreen" && (
        <Button
          variant="text"
          className={`share-options ${classes.buttonTextGreen}`}
          startIcon={<Share />}
          aria-controls="share-link-menu"
          aria-haspopup="false"
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            handleShareButton(event);
          }}
        >
          <Typography variant="body2">{buttonTextGreen}</Typography>
        </Button>
      )}
      {component === "menuItem" && (
        <MenuMobileItem
          classes={isLight ? { root: "client" } : {}}
          aria-controls="share-link-menu"
          aria-haspopup="false"
          onClick={(event: MouseEvent<HTMLLIElement>) => {
            handleShareButton(event);
          }}
          className='share-options'
        >
          {isLight ? (
            <>
              <Share fontSize="small" />
              {t("share-current-url")}
            </>
          ) : (
            t("share")
          )}
        </MenuMobileItem>
      )}
      {component === "none" && <></>}

      <Menu
        style={{ width: "100%", padding: 0 }}
        id="share-link-menu"
        anchorEl={anchorOp}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={openOptions}
        classes={{ paper: classes.modal }}
        onClose={handleCloseOptions}
        onClick={handleCloseOptions}
      >
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className={classes.modalOpt}
        >
          {visibility === 2 && (
            <MenuItem>
              <iframe
                title="facebook"
                src={
                  "https://www.facebook.com/plugins/share_button.php?href=" +
                  encodeURIComponent(link) +
                  "&layout=button&size=small&appId=771862063439825&width=89&height=20"
                }
                width="89"
                height="20"
                scrolling="no"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </MenuItem>
          )}
          {/* <Tooltip title={`${t('share-on-whatsapp')}`} placement="bottom">
                        <MenuItem onClick={handleClickWhatsapp}>
                            <WhatsApp fontSize="small" />
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title={`${t('copy-link')}`} placement="bottom">
                        <MenuItem onClick={(event: MouseEvent<HTMLLIElement>) => { handleShareButton(event) }}>
                            <FileCopyRounded fontSize="small" />
                        </MenuItem>
                    </Tooltip> */}
          <MenuItem onClick={handleClickWhatsapp}>
            <WhatsApp fontSize="small" />
            <Typography variant="caption">{t("share-on-whatsapp")}</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={(event: MouseEvent<HTMLLIElement>) => {
              handleNavigatorShare();
            }}
          >
            <FileCopyRounded fontSize="small" />
            <Typography variant="caption">{t("copy-link")}</Typography>
          </MenuItem>
        </div>
      </Menu>

      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success">{t("text-copied-success")}</Alert>
      </Snackbar>
    </>
  );
}

export default ShareIcon;
