import { 
    START_FILTER_LOGS,
    START_GET_CURRENT_COMMITS
} from '../types';

export const filterLogs = payload => ({
    type: START_FILTER_LOGS,
    payload
})

export const getCurrentCommits = () => ({
    type: START_GET_CURRENT_COMMITS
})