// React
import { useEffect } from 'react';
import { useSelector } from 'react-redux'

import { IRootState } from '../redux/types';

type HasKnowToBuyProps = {
    children: any
}

export function ValidateMenu(menuId: number) {

    const { user: authUser } = useSelector((state: IRootState) => state.auth);

    if (authUser !== null && (authUser.role_id === 1 || authUser.menus.find((menu: any) => menu.id === menuId) !== undefined))
        return true;
    else
        return false;
}

function HasKnowToBuy( props: any) {
    if(ValidateMenu(6)){
        return (
            props.children
        )
    }else{
        return (
            <></>
        )
    }
}

export default HasKnowToBuy
