import { makeStyles, createStyles } from '@material-ui/core/styles';
import mainTheme from '../themes/ClientTheme'

export default makeStyles((theme) => createStyles({
    container: {
        padding: '24px 16px',
        border: '1px solid rgba(23, 26, 28, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: 8,
        gap: 24,
        maxWidth: 336,
        minWidth: 300,
        '&.info': {
            gap: 16,
            marginTop: 24,
        },
        [mainTheme.breakpoints.down('sm')]: {
            border: 'none',
            padding: '16px 0px',
            maxWidth: '100%',
        },
    },
    title: {
        lineHeight: '1.18',
    },
    titleInfo: {
        fontWeight: 500,
        color: mainTheme.palette.grey[600],
    },
    customText: {
        '&.grey6': {
            color: mainTheme.palette.grey[600],
        },
        '&.grey5': {
            color: mainTheme.palette.grey[500],
        },
        '&.light': {
            color: mainTheme.palette.primary.light,
        },
        '&.blue': {
            color: mainTheme.palette.info.main,
        },
        '&.red': {
            color: mainTheme.palette.error.dark,
        },
        '&.orange': {
            color: mainTheme.palette.warning.dark,
        },
    },
    customButton: {
        width: '100%',
        display: 'flex',
        maxHeight: 40,
        height: 40,
        [mainTheme.breakpoints.down('sm')]: {
            maxHeight: 44,
            height: 44,
            '& span p': {
                paddingLeft: 8,
                fontWeight: 500,
                fontSize: mainTheme.typography.subtitle1.fontSize,
            },
        },
        '& span p': {
            paddingLeft: 8,
            fontWeight: 500,
        },
        '&.red': {
            backgroundColor: mainTheme.palette.error.light,
            color: mainTheme.palette.error.dark,
        },
        '&.redOut': {
            color: mainTheme.palette.error.dark,
            borderColor: mainTheme.palette.error.dark,
        },
        '&.blue': {
            backgroundColor: mainTheme.palette.info.light,
            color: mainTheme.palette.info.main,
        },
        '&.orange': {
            backgroundColor: mainTheme.palette.warning.light,
            color: mainTheme.palette.warning.dark,
        },
        '&.green': {
            backgroundColor: mainTheme.palette.success.light,
            color: mainTheme.palette.success.dark,
        },
    }
}));