// React
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button, ClickAwayListener, Chip } from '@material-ui/core';
import { LabelOutlined } from '@material-ui/icons/';

// Redux Actions
import { IRootState } from '../../../redux/types';
import { assignTagResource, deleteTagResource } from '../../../redux/actions/resources';
import { assignTagCourse, deleteTagCourse } from '../../../redux/actions/courses';
import { assignTagOffer, deleteTagOffer } from '../../../redux/actions/offers';
import { assignTagNovelty, deleteTagNovelty } from '../../../redux/actions/novelties';

// Styles
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles';

interface ITag {
    id: number,
    name: string,
    countries: any,
    brands: any
}

// TODO Quitar rowCountries = 1 por defecto y buscar los paises del recurso sobre el tag en bd
function TagInput({ rowId, rowTags, rowCountries = "1", rowBrandId = null, page }: any) {
    if (!(typeof rowCountries === 'string' || rowCountries instanceof String)) {
        let tmpRowCountries: number[] = [];
        rowCountries.map((country: any) => {
            tmpRowCountries.push(country.id);
        });

        rowCountries = tmpRowCountries.toString();
    }

    // Styles
    const tableclass = TableScreenStyles();

    // Redux Hooks
    const dispatch = useDispatch();
    const { tags } = useSelector((state: IRootState) => state.tag);
    const { globalBrandId } = useSelector((state: IRootState) => state.global);

    // React State
    const [pendingTags, setPendingTags] = useState<number[]>([]);
    const [displayTags, setDisplayTags] = useState<number>(0);


    /* Functions */
    const handleMultiAutoCompleteChange = (tags: ITag[]) => {
        let tagIds: number[] = [];
        if (tags !== null) {
            for (let i = 0; i < tags.length; i++) {
                tagIds.push(tags[i].id);
            }
        }

        setPendingTags(tagIds);
    }

    const handleClickChip = (id: number) => {
        setDisplayTags(id);
    };

    const handleAddTags = (id: number) => {
        if (pendingTags.length) {
            const payload = { id, tags: pendingTags, type: page };

            if (page === 'resources')
                dispatch(assignTagResource(payload));
            else
                if (page === 'courses')
                    dispatch(assignTagCourse(payload));
                else
                    if (page === 'offers')
                        dispatch(assignTagOffer(payload));
                    else
                        if (page === 'novelties' || page === 'news')
                            dispatch(assignTagNovelty(payload));
                        else
                            alert('Page sin action')
        }

        // Clear vars
        setDisplayTags(0);
        setPendingTags([]);
    };

    const handleDeleteTag = (id: number, tagId: number) => {
        const payload = { id, tagId, type: page };

        if (page === 'resources')
            dispatch(deleteTagResource(payload));
        else
            if (page === 'courses')
                dispatch(deleteTagCourse(payload));
            else
                if (page === 'offers')
                    dispatch(deleteTagOffer(payload));
                else
                    if (page === 'novelties' || page === 'news')
                        dispatch(deleteTagNovelty(payload));
                    else
                        alert('Page sin action')
    }

    const countriesCompare = (tagsCountries: number[], resourcesCountries: number[]) => {
        for (var i = 0; i < tagsCountries.length; i++) {
            for (var j = 0; j < resourcesCountries.length; j++) {
                if (tagsCountries[i] === resourcesCountries[j])
                    return 1;
            }
        }

        return 0;
    }

    const brandsCompare = (tagsBrands: string) => {
        if (globalBrandId === null)
            return tagsBrands.split(',').includes(rowBrandId?.toString());

        return tagsBrands.split(',').includes(globalBrandId?.toString());
    }

    return (
        <>
            {rowTags === null ?
                <>
                    {displayTags !== rowId ?
                        <Button
                            key={rowId}
                            id="btn-display-tags"
                            aria-controls="tag-menu"
                            aria-haspopup="true"
                            onClick={() => { setDisplayTags(rowId) }}
                            className={tableclass.buttonTag}
                            startIcon={<LabelOutlined />}>
                        </Button>
                        :
                        <ClickAwayListener onClickAway={() => handleAddTags(rowId)}>
                            <div>
                                <Autocomplete
                                    open
                                    multiple
                                    disableCloseOnSelect
                                    disableClearable
                                    size="small"
                                    popupIcon={null}
                                    id={"autocomplete-tags" + rowId}
                                    options={tags.filter((tag: ITag) => countriesCompare(tag.countries.split(','), rowCountries.split(',')) && brandsCompare(tag.brands))}
                                    defaultValue={rowTags !== null ? tags.filter((tag: ITag) => rowTags.split(',').includes(tag.id.toString())) : []}
                                    getOptionLabel={(tag: ITag) => tag.id !== undefined ? tag.name : ''}
                                    renderInput={(params: any) => (
                                        <TextField {...params} />
                                    )}
                                    onChange={(event, newValue) => handleMultiAutoCompleteChange(newValue)}
                                />
                            </div>
                        </ClickAwayListener>
                    }
                </>
                :
                <>
                    {displayTags !== rowId && tags.length > 0 ?
                        rowTags.split(',').map((tagId: number) => {
                            const tag = tags.find((tag: ITag) => tag.id == tagId)
                            if (tag){
                                return (
                                    <Chip
                                        key={tag.id}
                                        label={tag.name}
                                        onClick={() => handleClickChip(rowId)}
                                        onDelete={() => handleDeleteTag(rowId, tag.id)}
                                    />
                                )
                            }else{
                                return rowTags;
                            }
                        })
                        :
                        <ClickAwayListener onClickAway={() => handleAddTags(rowId)}>
                            <div>
                                <Autocomplete
                                    open
                                    multiple
                                    disableCloseOnSelect
                                    disableClearable
                                    size="small"
                                    popupIcon={null}
                                    id={"autocomplete-tags" + rowId}
                                    options={tags.filter((tag: ITag) => countriesCompare(tag.countries.split(','), rowCountries.split(',')))}
                                    defaultValue={rowTags !== null ? tags.filter((tag: ITag) => rowTags.split(',').includes(tag.id.toString())) : []}
                                    getOptionLabel={(tag: ITag) => tag.id !== undefined ? tag.name : ''}
                                    renderInput={(params: any) => (
                                        <TextField {...params} />
                                    )}
                                    onChange={(event, newValue) => handleMultiAutoCompleteChange(newValue)}
                                />
                            </div>
                        </ClickAwayListener>
                    }
                </>
            }
        </>
    )
}

export default TagInput;