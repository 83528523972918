import { useDispatch, useSelector } from 'react-redux'

//Material UI
import { Box, Button, FormControl, Grid, Typography, Input, ThemeProvider } from '@material-ui/core';

// Styles
import ResourceStyle from '../../../styles/pages/LoginRegisterStyle';

// Libraries
import { useTranslation } from "react-i18next";

// Redux Actions
import { subscribeToSeminarlesson } from '../../../redux/actions/seminarlessons'
import { IRootState } from '../../../redux/types'
import { SaveButton } from '.';


const SuscriptionLive = () => {

    const { t } = useTranslation(["global"]);

    // Styles
    const classes = ResourceStyle();

    // Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { seminarlesson_selected } = useSelector((state: IRootState) => state.seminarlesson);

    const handleSeminarRegistration = () => {
        dispatch(subscribeToSeminarlesson({ seminarlesson_id: seminarlesson_selected.id }));
    }

    return (
            <Grid className={classes.form + 'live'}>
                <Grid container spacing={3} className={classes.formBody}>
                    <Grid item xs={12}>
                        <Box pb={3}>
                            <Typography className={classes.registerHeroTitle + 'live'} color="textPrimary" variant="h4" >{t("book-my-ticket")}</Typography>
                            <Typography className={classes.registerHeroTitle + 'live'} variant="body1" color="textSecondary" >{t("client.register.register-live-message")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={0.5}>
                            <Typography>{t("name")}</Typography>
                        </Box>
                        <FormControl variant="filled" fullWidth>
                            <Input
                                placeholder={t("client.register.placeholder-name") }
                                defaultValue={authUser !== null ? authUser.name : ''}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={0.5}>
                            <Typography>{t("email")}</Typography>
                        </Box>
                        <FormControl variant="filled" fullWidth>
                            <Input
                                defaultValue={authUser !== null ? authUser.email : ''}
                                placeholder={t("email") }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <TermsModal displayTerms={displayTerms} handleDisplayTerms={handleDisplayTerms} />  */}
                <Box display='flex' width='100%' justifyContent='center'>
                    <SaveButton  onClick={() => handleSeminarRegistration()} >
                        {t("continue")}
                    </SaveButton>
                </Box>
                    </Grid>
                </Grid>
            </Grid>
    );
}

export default SuscriptionLive;