// React
import { useState } from 'react'

export default function useTabManage(initState: number = 0): [number, (state: number) => void] {

  const [value, setValue] = useState<number>(initState);

  const changeState = (value: number) => {
    setValue(value);
  }

  return [value, changeState];
}