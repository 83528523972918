import { 
    IResourcecategoryState,
    START_GET_RESOURCECATEGORIES, SUCCESS_GET_RESOURCECATEGORIES, ERROR_GET_RESOURCECATEGORIES,
    START_GET_RESOURCECATEGORIES_OPTIONS, SUCCESS_GET_RESOURCECATEGORIES_OPTIONS, ERROR_GET_RESOURCECATEGORIES_OPTIONS,
    START_SHOW_RESOURCECATEGORY, SUCCESS_SHOW_RESOURCECATEGORY, ERROR_SHOW_RESOURCECATEGORY,
    START_STORE_RESOURCECATEGORY, SUCCESS_STORE_RESOURCECATEGORY, ERROR_STORE_RESOURCECATEGORY,
    START_UPDATE_RESOURCECATEGORY, SUCCESS_UPDATE_RESOURCECATEGORY, ERROR_UPDATE_RESOURCECATEGORY,
    START_DELETE_RESOURCECATEGORY, SUCCESS_DELETE_RESOURCECATEGORY, ERROR_DELETE_RESOURCECATEGORY,
    SUCCESS_TOGGLE_FORM_RESOURCECATEGORIES
 } from '../types';
    
const initialState: IResourcecategoryState = {
    resourcecategories: [],
    resourcecategoriesOptions: [],
    resourcecategorySelected: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_RESOURCECATEGORIES:
        case START_GET_RESOURCECATEGORIES_OPTIONS:
        case START_SHOW_RESOURCECATEGORY:
        case START_STORE_RESOURCECATEGORY:
        case START_UPDATE_RESOURCECATEGORY:
        case START_DELETE_RESOURCECATEGORY:
            return{
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_RESOURCECATEGORIES:
            return{
                ...state,
                resourcecategories: action.payload.resourcecategories,
                resourcecategoriesOptions: action.payload.resourcecategories,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_GET_RESOURCECATEGORIES_OPTIONS:
            return{
                ...state,
                resourcecategoriesOptions: action.payload.resourcecategories,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_RESOURCECATEGORY:
            return{
                ...state,
                resourcecategorySelected: action.payload.resourcecategory,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: true
            }
        case SUCCESS_STORE_RESOURCECATEGORY:
            return{
                ...state,
                resourcecategories: [...state.resourcecategories, action.payload.resourcecategory],
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_UPDATE_RESOURCECATEGORY:
            return{
                ...state,
                resourcecategories: state.resourcecategories.map((resourcecategory: any) => 
                    resourcecategory.id === action.payload.resourcecategory.id ? action.payload.resourcecategory : resourcecategory 
                ),
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_DELETE_RESOURCECATEGORY:
            return{
                ...state,
                resourcecategories: state.resourcecategories.filter((resourcecategory: any) => resourcecategory.id != action.payload.resourcecategory_id),
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_TOGGLE_FORM_RESOURCECATEGORIES:
            return{
                ...state,
                showForm: !state.showForm,
            }
        case ERROR_GET_RESOURCECATEGORIES:
        case ERROR_GET_RESOURCECATEGORIES_OPTIONS:
        case ERROR_SHOW_RESOURCECATEGORY:
        case ERROR_STORE_RESOURCECATEGORY:
        case ERROR_UPDATE_RESOURCECATEGORY:
        case ERROR_DELETE_RESOURCECATEGORY:
            return{
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}