import { useEffect, useState, MouseEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';

// Material UI
import { Grid, Container, Box, IconButton, Menu, MenuItem, Button, Typography, Tooltip, Fade } from '@material-ui/core';
import MaterialTable from 'material-table';
import { localization, options, tableIcons } from '../../../config/table';
import { makeStyles } from '@material-ui/core/styles';
import { DoubleArrow } from '@material-ui/icons/';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '../../../components/common/ShareIcon';

// Custom components
import MediaFileBlock from '../../../components/common/client/MediaFileBlock';
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import ScrollSubHeader from '../../../components/common/header/ScrollSubHeader';
import TypeFilter from '../../../components/common/header/TypeFilter';
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../../components/common/ScreenLoading';
import CustomChip from '../../../components/common/admin/CustomChip';
import ContentFilter from '../../../components/layouts/filters/ContentFilter';
import FiletypeIcon from '../../../components/library/FiletypeIcon';
import VisibilityIcon from '../../../components/common/admin/VisibilityIcon';
import NotFilter from '../../NotFilter';
import HiddenDesktop from '../../../components/common/HiddenDesktop';

// Custom Hooks
import useGeneralSummaryReport from "../../../hooks/useGeneralSummaryReport";
import useSkeleton from '../../../components/layouts/navigation/SkeletonLayout';
import useScreenWidth from '../../../hooks/useScreenWidth';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../../redux/actions/favorites';
import { getResourcecategories } from '../../../redux/actions/resourcecategories';
import { getResourcetypes } from '../../../redux/actions/resourcetypes'
import { getResources } from '../../../redux/actions/resources';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce'

// Custom Style
const libraryStyles = makeStyles(theme => ({
    startIcon: {
        margin: 0,
    },
    content: {
        [theme.breakpoints.up('md')]: {
            left: "90%",
            marginTop: "-14px",
        },
        [theme.breakpoints.down('md')]: {
            left: "8%",
        },
        marginBottom: 24,
        top: 0,
        position: 'relative',
    },
    contained: {
        borderRadius: 4,
    },
    nameResource: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 240,
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 170,
        },
    },
}));

// Types
interface IFilter {
    resourcetype_id: null | number,
    category_id: null | number,
    orderby_id: number,
    family_id: null | number,
    brand_id: null | number,
    line_id: null | number,
    country_id: null | number,
    role_id: null | number,
    language_id: null | number,
    tag_id: null | number,
    searchText: string
}

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

function ClientLibrary() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const classes = libraryStyles();

    // Location & History
    const { pathname, search } = useLocation();
    const { push, replace } = useHistory();
    const searchParams = new URLSearchParams(search);

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag } = useSelector((state: IRootState) => state.global);
    const { resources, error, msg, loading } = useSelector((state: IRootState) => state.resource);
    const { resourcecategories: categories, error: errorCategory, msg: msgCategory } = useSelector((state: IRootState) => state.resourcecategory);
    const { resourcetypes, error: errorResourcetype, msg: msgResourcetype } = useSelector((state: IRootState) => state.resourcetype);

    // Table Menu State
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(-1);
    const { loading: loadingSkeleton, component: componentSkeleton } = useSkeleton();
    const { handleClickGeneralSummaryReport } = useGeneralSummaryReport(resources, 0, 2);

    // Filter state
    const [filters, setFilters] = useState<IFilter>({
        resourcetype_id: null,
        category_id: null,
        orderby_id: 0,
        family_id: null,
        brand_id: null,
        line_id: null,
        country_id: null,
        role_id: null,
        language_id: null,
        tag_id: null,
        searchText: ''
    });

    // Condicional para el switch de grid a lista
    const [display, setDisplay] = useState<number>(1);
    const rowsPerPage = useRef<number>(8)
    const pageNumber = useRef<number>(1)

    const nextPage = () => {
        pageNumber.current++; 
        getResourcesFromAPI();
    }

    const getResourcesFromAPI = () => {
        const pagination = {pageNumber: pageNumber.current, rowsPerPage: rowsPerPage.current}
        dispatch(getResources({filters, pagination }));
    }

    // Ready Page
    useEffect(() => {
        if (!resourcetypes.length)
            dispatch(getResourcetypes());
    }, []);

    useEffect(() => {
        if (globalCountryId !== null)
            dispatch(getResourcecategories({ languageId: globalLanguageId, countriesId: [globalCountryId], familyId: globalFamilyId, brandId: globalBrandId }));
    }, [globalCountryId, globalFamilyId, globalBrandId]);

    useEffect(() => {
        if (filters.country_id !== null) {
            pageNumber.current = 1;
            getResourcesFromAPI();
        }
    }, [filters]);

    useEffect(() => {
        if (globalCountryId !== null) {
            setFilters({
                ...filters,
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                country_id: globalCountryId,
                line_id: globalLineId,
                role_id: globalRoleId,
                language_id: globalLanguageId,
                orderby_id: globalOrderById,
                tag_id: globalTag,
                resourcetype_id: (search && searchParams.get('resourcetype_id') !== null) ? Number(searchParams.get('resourcetype_id')) : null,
                category_id: (search && searchParams.get('category_id') !== null) ? Number(searchParams.get('category_id')) : null
            });
        }
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag]);

    /* Functions */
    const handleChangeFilter = (resourcetype_id: null | number, category_id: null | number) => {
        setFilters({ ...filters, resourcetype_id, category_id });
        if (resourcetype_id != null)
            searchParams.set("resourcetype_id", resourcetype_id.toString());
        else
            searchParams.delete("resourcetype_id")
        if (category_id != null)
            searchParams.set("category_id", category_id.toString());
        else
            searchParams.delete("category_id")
        replace(`${pathname}?${searchParams}`);
    }

    const handleClickMediaFileBlock = (id: number, hasaccess: boolean) => {
        handleClickGeneralSummaryReport(id);
        if (hasaccess)
            push('/biblioteca/' + id);
    }

    const handleDownload = (resource_id: number) => {
        handleClickGeneralSummaryReport(resource_id);
        const URL = process.env.REACT_APP_API_URL + '/resources/download/';
        window.open(URL + resource_id);
    }

    const handleFavoriteClick = (resource_id: number) => (state: boolean) => {
        handleClickGeneralSummaryReport(resource_id);
        if (state) {
            dispatch(setFavorite({
                id: resource_id,
                state,
                type: 'resource'
            }));
        } else {
            dispatch(unsetFavorite({
                id: resource_id,
                state,
                type: 'resource'
            }));
        }
    }

    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const changeDisplay = (mode: number) => {
        setDisplay(mode);
    }

    const getSubsection = () => {
        let subsection = null;
        if (filters.resourcetype_id !== null && filters.category_id === null && resourcetypes.length !== 0)
            subsection = resourcetypes.find((resourcetype: any) => resourcetype.id === filters.resourcetype_id).name;
        else
            if (filters.resourcetype_id !== null && filters.category_id !== null && categories.length !== 0) {
                const objCategories = categories.find((category: any) => category.id === filters.category_id);
                if (subsection)
                    subsection = objCategories.name
            }

        return subsection;
    }

    const columns: any = [
        {
            title: t("name"), field: "file_name", render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8}>
                    <Tooltip placement="bottom-start" TransitionComponent={Fade}
                        TransitionProps={{ timeout: 400 }} title={rowData.title}>
                        <Typography className={classes.nameResource}>{rowData.title}</Typography>
                    </Tooltip>
                    <VisibilityIcon id={rowData.visibility} />
                </Box>
            )
        },
        {
            title: t("admin.library.format"), field: 'ext', render: (rowData: any) =>
                <Box alignItems="center">
                    {isMobile ?
                        <FiletypeIcon id={rowData.filetype_id} />
                        :
                        <CustomChip custom="client" type={2} label={rowData.filetype} />
                    }
                </Box>,
            hidden: (isMobile && globalBrandId === null)
        },
        { title: t("language"), field: "language_codes", type: string, hidden: isMobile },
        { title: t("date"), field: 'date', type: string, hidden: isMobile },
        { title: t("brand"), field: 'brand', render: (rowData: any) => rowData.brand_name, hidden: filters.brand_id !== null && isMobile },
        {
            title: '', field: '', render: (rowData: any) => (
                <>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}>
                        <MenuItem onClick={() => push('/biblioteca/' + rowData.id)}>{t("view")}</MenuItem>
                        <MenuItem onClick={() => handleDownload(rowData.id)}>{t("download")}</MenuItem>
                        <ShareIcon
                            component='menuItem'
                            type="resource"
                            visibility={rowData.visibility}
                            title={(rowData.alias != null) ? rowData.alias : rowData.file_name}
                            description={rowData.description}
                            link={`${window.location.origin}/biblioteca/${rowData.id}${window.location.search}`}
                            multimedia={`${process.env.REACT_APP_AWS_URL}/resources/${rowData.file_name}`}
                        />
                    </Menu>
                </>
            )
        }
    ];

    const handleContentSearch = debounce((event: any) => {
        const value = event.target.value;
        if (value !== '' && value !== null) {
            const rawValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            setFilters({...filters, searchText: rawValue});
        } else
            setFilters({...filters, searchText: ''});
    }, 500)

    const myElement: HTMLElement | null = document.getElementById('window');
    let midOfWidth = window.innerWidth / 2

    return (
        <>
            {loadingSkeleton && componentSkeleton}

            <div style={{ display: loadingSkeleton ? 'none' : 'block' }}>
                <Header title={t("library")} />
                <SubHeader>
                    <ScrollSubHeader id='window'>
                        {resourcetypes.filter((resourcetype: any) => resourcetype.id !== 4).map((resourcetype: any, index: number) => (
                            <TypeFilter
                                key={resourcetype.id}
                                id={resourcetype.id}
                                type={resourcetype.name}
                                categories={categories.filter((category: any) => category.resourcetype_id === resourcetype.id)}
                                filterCategoryId={filters.category_id}
                                active={resourcetype.id === filters.resourcetype_id}
                                handleChangeFilter={handleChangeFilter}
                            />
                        ))}
                        <TypeFilter
                            id={null}
                            type={t("all")}
                            categories={[]}
                            active={filters.resourcetype_id === null}
                            filterCategoryId={filters.category_id}
                            handleChangeFilter={handleChangeFilter}
                        />
                    </ScrollSubHeader>
                    <HiddenDesktop>
                        <IconButton className='buttonScroll' onClick={() => { myElement?.scrollBy(midOfWidth, 0) }}>
                            <DoubleArrow />
                        </IconButton>
                    </HiddenDesktop>
                </SubHeader>

                {/* Filters */}
                <ContentFilter changeDisplay={changeDisplay} sectionName={t("library")} handleContentSearch={handleContentSearch} />

                <Container style={{ maxWidth: 1111 }} >
                    {!resources.length && !loading ? <NotFilter subsection={getSubsection()} /> :
                        display ?
                            <Box>
                                <Grid container spacing={isMobile ? 1 : 3} >
                                    {resources.map((resource: any) => (
                                        <MediaFileBlock
                                            key={resource.id}
                                            data={resource}
                                            brand={filters.brand_id !== null ? '' : resource.brand_name}
                                            vipContent={resource.visibility === 1}
                                            hasaccess={resource.visibility === 1 && (authUser.vip_lines.includes(resource.line_id) || (resource.line_id === null && authUser.vip_brands.includes(resource.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                            handleClick={handleClickMediaFileBlock}
                                            handleDownload={handleDownload}
                                            handleFavorite={handleFavoriteClick(resource.id)}
                                        />
                                    ))}
                                </Grid>
                                {resources.length % rowsPerPage.current === 0 &&
                                    <Box mt={6} pb={4} style={{ textAlign: "center" }}>
                                        <Button id="btn-see-more" variant="contained" size="large" color="primary" onClick={() => nextPage()}>{t("see-more")}</Button>
                                    </Box>
                                }
                            </Box>
                            :
                            <div>
                                <Box mt={isMobile ? 2 : 0}>
                                    <div className="table_client">
                                        <MaterialTable
                                            localization={localization}
                                            title="Usuarios Admin"
                                            columns={columns}
                                            data={resources}
                                            options={{ ...options, exportButton: false, sorting: false, search: false, toolbar: isMobile ? false : true }}
                                            icons={tableIcons}
                                        />

                                        <Box mt={6} pb={4} style={{ textAlign: "center" }}>
                                            <Button variant="contained" size="large" color="primary" onClick={() => nextPage()}>{t("see-more")}</Button>
                                        </Box>
                                    </div>
                                </Box>
                            </div>
                    }
                </Container>

                <CustomSnackBar errors={[error, errorCategory, errorResourcetype]} msgs={[msg, msgCategory, msgResourcetype]} />
                <ScreenLoading loadings={[loading]} />

            </div>
        </>
    );
}

export default ClientLibrary;
