// React
import {
    useState,
    useEffect,
    forwardRef,
    ReactElement,
    Ref,
    ChangeEvent,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    Typography,
    Grid,
    FormHelperText,
    Slide,
    Tab,
    Tabs,
    FormControl,
} from '@material-ui/core'
import { BrandingWatermarkRounded, CloseRounded } from '@material-ui/icons'
import { TransitionProps } from '@material-ui/core/transitions'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DateTimePicker } from '@material-ui/pickers'
import moment, { Moment } from 'moment'

// Custom components
import TabPanel from '../common/admin/TabPanel'
import {
    BrandAutocomplete,
    LineAutocomplete,
    InputCountry,
    LanguageAutocomplete,
    VisibilityAutocomplete,
    RoleAutocomplete,
    StatusAutocomplete,
    Dropzone,
    SaveButton,
    CancelButton,
    DatePicker,
} from '../common/forms'

// Redux Actions
import {
    storeSeminar,
    updateSeminar,
    toggleFormSeminar,
} from '../../redux/actions/seminars'
import { getSeminartypes } from '../../redux/actions/seminartypes'
import { getRoles } from '../../redux/actions/roles'
import { getFilteredLanguages } from '../../redux/actions/languages'
import { getNovelties } from '../../redux/actions/novelties'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

// Types
interface ISeminar {
    id: null | number
    brand: any
    line: any
    title: string
    description: string
    language: null | number
    countries: number[]
    visibility: any
    highlight: number
    role: any
    cover: string
    novelty: any
    seminartype: any
    status: any
    datetime: Moment | null
}

type FormElement = ChangeEvent<HTMLInputElement>

function SeminarModal() {
    //Styles
    const formClasses = FormsStyles()

    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    const visibility = [
        { id: 0, name: t('private') },
        { id: 1, name: 'VIP' },
        { id: 2, name: t('public') },
        { id: 3, name: t('preregistration') },
    ]
    const status = [
        { id: 1, name: t('active') },
        { id: 0, name: t('inactive') },
    ]

    //Redux Hooks
    const dispatch = useDispatch()
    const { userBrands } = useSelector((state: IRootState) => state.brand)
    const { seminarSelected, showForm } = useSelector(
        (state: IRootState) => state.seminar
    )
    const { seminartypes } = useSelector(
        (state: IRootState) => state.seminartype
    )
    const { novelties } = useSelector((state: IRootState) => state.novelty)
    const {
        globalFamilyId,
        globalBrandId,
        globalLanguageId,
        globalCountriesId,
    } = useSelector((state: IRootState) => state.global)
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector(
        (state: IRootState) => state.validator
    )

    // Component States
    const [seminarState, setSeminarState] = useState<ISeminar>({
        id: null,
        brand: null,
        line: null,
        novelty: null,
        title: '',
        description: '',
        language: null,
        countries: [],
        visibility: visibility[0],
        highlight: 0,
        role: null,
        cover: '',
        seminartype: null,
        status: status[0],
        datetime: null,
    })

    //Tab State
    const [currentTab, setCurrentTab] = useState(0)

    useEffect(() => {
        dispatch(getRoles())
        dispatch(getFilteredLanguages())
        if (!seminartypes.length) dispatch(getSeminartypes())
    }, [])

    useEffect(() => {
        if (!novelties.length) {
            dispatch(
                getNovelties({
                    family_id: globalFamilyId,
                    brand_id: globalBrandId,
                    language_id: globalLanguageId,
                    countries: globalCountriesId,
                    requesttype: 0,
                    type: 'novelties',
                })
            )
        }
    }, [globalFamilyId, globalBrandId, globalCountriesId, globalLanguageId])

    useEffect(() => {
        if (seminarSelected != null) {
            setSeminarState({
                id: seminarSelected.id,
                brand: seminarSelected.brand
                    ? userBrands.find(
                        (brand: any) => brand.id === seminarSelected.brand.id
                    )
                    : null,
                line: seminarSelected.line,
                countries: seminarSelected.countriesIds,
                language: seminarSelected.language,
                role: seminarSelected.role,
                novelty: seminarSelected.novelty,
                seminartype: seminarSelected.seminartype,
                title: seminarSelected.title,
                description: seminarSelected.description,
                cover: seminarSelected.cover,
                highlight: seminarSelected.highlight,
                datetime:
                    seminarSelected.datetime != null
                        ? moment(seminarSelected.datetime)
                        : null,
                status: status.find(
                    (status: any) => status.id === seminarSelected.status
                ),
                visibility: visibility.find(
                    (visibility: any) =>
                        visibility.id === seminarSelected.visibility
                ),
            })
        }
    }, [seminarSelected])

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue)
    }

    //Modal functions
    const handleInputChange = (inputName: string, value: any) => {
        setSeminarState({ ...seminarState, [inputName]: value })
    }

    const handleSubmit = () => {
        dispatch(
            formValidator({
                title: [seminarState.title, 'required', 'general'],
                description: [seminarState.description, 'required', 'general'],
                type: [seminarState.seminartype, 'required', 'general'],
                countries: [seminarState.countries, 'notempty', 'general'],
                language: [seminarState.language, 'required', 'general'],
                status: [seminarState.status, 'required', 'general'],
                visibility: [seminarState.visibility, 'required', 'general'],
                role: [seminarState.role, 'required', 'general'],
                cover: [seminarState.cover, 'required', 'addOn'],
            })
        )
    }

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator())
            if (seminarState.id !== null) {
                dispatch(updateSeminar(seminarState))
            } else {
                dispatch(storeSeminar(seminarState))
            }
        }
    }, [isValidForm])

    return (
        <Dialog
            open={showForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            TransitionComponent={Transition}
            onClose={() => {
                dispatch(toggleFormSeminar())
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {seminarState.id !== null ? t('edit') : t('create')}{' '}
                        {t('live-event')}
                    </Typography>
                    <Button
                        onClick={() => {
                            dispatch(toggleFormSeminar())
                        }}
                        color="primary"
                        variant="text"
                    >
                        <CloseRounded />
                    </Button>
                </Grid>
            </DialogTitle>
            <DialogContent className={formClasses.bodyForm}>
                <Grid
                    container
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={seminarState.brand != null ? 6 : 8}
                    >
                        <TextField
                            id="txt-seminar-title"
                            data-createseminar="title"
                            label={t('title')}
                            fullWidth
                            required
                            // margin="normal"
                            InputLabelProps={{ shrink: true }}
                            variant="filled"
                            value={seminarState.title}
                            onChange={(event: FormElement) => {
                                handleInputChange('title', event.target.value)
                            }}
                        />
                        <FormHelperText error={errorForm.title}>
                            {msgForm.title}
                        </FormHelperText>
                    </Grid>

                    {/* Brand Input */}
                    <Grid
                        item
                        xs={12}
                        sm={seminarState.brand != null ? 6 : 12}
                        md={seminarState.brand != null ? 3 : 4}
                    >
                        <FormControl fullWidth variant="filled">
                            <BrandAutocomplete
                                value={seminarState.brand}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Line Input */}
                    {seminarState.brand !== null && (
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth variant="filled">
                                <LineAutocomplete
                                    value={seminarState.line}
                                    brand={seminarState.brand}
                                    handleInputChange={handleInputChange}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={formClasses.tapForm}
                        onChange={handleChangeTab}
                        aria-label="disabled tabs example"
                    >
                        <Tab
                            label={t('general')}
                            {...a11yProps(0)}
                            className={
                                errorTab.general ? formClasses.errorTab : ''
                            }
                        />
                        <Tab
                            label={t('add-ons')}
                            {...a11yProps(1)}
                            className={
                                errorTab.addOn ? formClasses.errorTab : ''
                            }
                        />
                    </Tabs>
                </Grid>

                <TabPanel value={currentTab} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={8}>
                            <TextField
                                id="txt-seminar-description"
                                data-createseminar="description"
                                label={t('description')}
                                fullWidth
                                required
                                // margin="normal"
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                                value={seminarState.description}
                                onChange={(event: FormElement) => {
                                    handleInputChange(
                                        'description',
                                        event.target.value
                                    )
                                }}
                            />
                            <FormHelperText error={errorForm.description}>
                                {msgForm.description}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-seminarytypes"
                                disableClearable
                                value={seminarState.seminartype}
                                options={seminartypes}
                                onChange={(event, value) => {
                                    handleInputChange('seminartype', value)
                                }}
                                getOptionLabel={(option: any) =>
                                    option.id !== undefined ? option.name : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        label={t('category')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.type}>
                                {msgForm.type}
                            </FormHelperText>
                        </Grid>

                        {/* Country Input */}
                        <Grid item xs={12} sm={6} md={6}>
                            <InputCountry
                                value={seminarState.countries}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        {/* Language Input */}
                        <Grid item xs={12} sm={6} md={6}>
                            <LanguageAutocomplete
                                value={seminarState.language}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        {/* Status Input */}
                        <Grid item xs={12} sm={6} md={4}>
                            <StatusAutocomplete
                                value={seminarState.status}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        {/* Visibility Input */}
                        <Grid item xs={12} sm={6} md={4}>
                            <VisibilityAutocomplete
                                value={seminarState.visibility}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        {/* Role Input */}
                        <Grid item xs={12} sm={6} md={4}>
                            <RoleAutocomplete
                                value={seminarState.role}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="combo-box-novelties"
                                disableClearable
                                value={seminarState.novelty}
                                options={novelties}
                                onChange={(event, value) => {
                                    handleInputChange('novelty', value)
                                }}
                                getOptionLabel={(option: any) =>
                                    option.id !== undefined ? option.title : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('novelty')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6}>
                            <DatePicker
                                ampm={false}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy hh:mm a"
                                label={`${t('date')} ${t('live-event')}`}
                                InputLabelProps={{ shrink: true, }}
                                fullWidth
                                value={seminarState.datetime}
                                onChange={(newDate: any) => setSeminarState({ ...seminarState, datetime: newDate })}
                            />
                        </Grid> */}

                        <Grid item xs={12}>
                            <Dropzone
                                name={'cover'}
                                handleInputChange={handleInputChange}
                                dir={'seminars'}
                            />
                            <FormHelperText error={errorForm.cover}>
                                {msgForm.cover}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </TabPanel>
            </DialogContent>

            <DialogActions
                className={
                    !isValidForm && Object.keys(msgForm).length > 0
                        ? formClasses.errorDialogActions
                        : ''
                }
            >
                <CancelButton
                    onClick={() => {
                        dispatch(toggleFormSeminar())
                    }}
                >
                    {t('cancel')}
                </CancelButton>

                <SaveButton
                    autoFocus
                    data-createseminar="saveButton"
                    onClick={() => handleSubmit()}
                >
                    {t('save')}
                </SaveButton>
            </DialogActions>
        </Dialog>
    )
}

export default SeminarModal
