// React - Redux
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

// Redux Actions
import { IRootState } from '../../redux/types'
import { getSessionlogs } from '../../redux/actions/reports';

export default function useSessionlogs(timegap: string) {
  // Redux Hooks
  const dispatch = useDispatch();
  const { sessionlogs } = useSelector((state: IRootState) => state.report);
  const { globalRoleId } = useSelector((state: IRootState) => state.global)

  useEffect(() => {
    dispatch(getSessionlogs({timegap, roleId: globalRoleId}));
  }, [timegap, globalRoleId]);

  return { sessionTotal: sessionlogs.sessionTotal, sessionTime: sessionlogs.sessionTime }
}