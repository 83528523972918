// React - Redux
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

// Redux Actions
import { IRootState } from '../../redux/types'
import { getPopularContent } from '../../redux/actions/reports';

// Types
interface Item {
  foreign_id: number
  foreign_name: string
  image: string
  value: number
  menu: string
}

interface PopularContent {
  mostVisualized: Item[]
  mostViewed: Item[]
}

export default function usePopularContent(): PopularContent {

  // Redux Hooks
  const dispatch = useDispatch();
  const { popularContent } = useSelector((state: IRootState) => state.report);

  useEffect(() => {
    dispatch(getPopularContent());
  }, [dispatch]);

  return { mostVisualized: popularContent.mostVisualized, mostViewed: popularContent.mostViewed }
}