import { ITagState, START_GET_TAGS, SUCCESS_GET_TAGS, ERROR_GET_TAGS } from '../types';
    
const initialState: ITagState = {
    tags: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_TAGS:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_TAGS:
        return{
            ...state,
            tags: action.payload.tags,
            msg: null,
            error:false,
            loading: false
        }
        case ERROR_GET_TAGS:
        return{
            ...state,
            tags: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}