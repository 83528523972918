// Libraries
import { useTranslation } from "react-i18next";

// Types
export interface SectionData {
    client: SectionName[];
    admin?: SectionName[];
}

export interface SectionName {
    "about-plei"?: SectionContent;
    basic?: SectionContent;
    sections?: SectionContent;
}

export interface SectionContent {
    title: string;
    content: Content[];
}

export interface Content {
    question: string;
    answer: string[] | string;
}

const useSectionData = () => {

    // Translation
    const { t } = useTranslation(["helpcenter"]);

    const data: SectionData = {
        "client": [
            {
                "about-plei": {
                    "title": t("client.about-plei.title"),
                    "content": [
                        {
                            "question": t("client.about-plei.q1"),
                            "answer": [
                                t("client.about-plei.a1-p1"),
                            ]
                        },
                        {
                            "question": t("client.about-plei.q2"),
                            "answer": [
                                t("client.about-plei.a2-p1")
                            ]

                        },
                        {
                            "question": t("client.about-plei.q3"),
                            "answer": [
                                t("client.about-plei.a3-p1"),
                            ]
                        },
                        {
                            "question": t("client.about-plei.q4"),
                            "answer": [ t("client.about-plei.a4-p1") ] 
                        },
                        {
                            "question": t("client.about-plei.q5"),
                            "answer": [ t("client.about-plei.a5-p1") ] 
                        },
                    ]
                }
            },
            {
                "sections": {
                    "title": t("sections"),
                    "content": [
                        {
                            "question": t("client.library.q1"),
                            "answer": [
                                t("client.library.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.academy.q1"),
                            "answer": [
                                t("client.academy.a1-p1")
                            ]

                        },
                        {
                            "question": t("client.events.q1"),
                            "answer":[ 
                                t("client.events.a1-p1") 
                            ]
                        },
                       
                    ]
                }
            },
            {
                "basic": {
                    "title": t("client.basic.title"),
                    "content": [
                        {
                            "question": t("client.basic.q1"),
                            "answer": [
                                t("client.basic.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.basic.q2"),
                            "answer": [
                                t("client.basic.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.basic.q3"),
                            "answer": [
                                t("client.basic.a3-p1")
                            ]
                        },
                        {
                            "question": t("client.basic.q4"),
                            "answer": [
                                t("client.basic.a4-p1")
                            ]
                        },
                       
                    ]
                }
            },
        ]
    };

    return data;
}

export default useSectionData;