import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import theme from '../themes/AdminTheme';

const drawerWidth = 240;

export default makeStyles(() => createStyles({

    navLogo: {
        height: 'inherit',
        width: 'auto',
        [theme.breakpoints.up('sm')]: {
            height: 24,
            position: 'relative',
            top: '-1px',
            marginRight: '32px'
        },
    },

    appLogo: {
        height: 50,
        display: "flex",
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 50,
            paddingRight: 16,
            paddingLeft: 16,
            '& img': {
                height: 20,
            }
        },
        [theme.breakpoints.up('sm')]: {
            "& img:hover": {
                opacity: '0.7',
            },
        },
    },
    // SEARCH
    grow: {
        flexGrow: 1,
    },

    // APPBAR
    appBar: {
        height: '50px',

        '& .MuiToolbar-regular': {
            minHeight: '50px',
            alignItems: 'center'
        },

        '&.client': {
            borderBottom: "none",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
            background: theme.palette.common.white,
        },
        '&.admin': {
            boxShadow: 'none',
            background: theme.palette.primary.dark,
        }
    },
    toolbar: {
        minHeight: '50px',
        [theme.breakpoints.down("xs")]: {
            display: 'none',
        }
    },

    // DRAWER

    drawerPaper: {
        width: 240,
        paddingTop: '5px',
        border: 'none',
        height: 'calc( 100vh - 163px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '6px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        '&.admin': {
            height: 'calc( 100vh - 120px)',
            background: theme.palette.grey[900],
            borderRadius: '0px 4px 0px 0px',
            [theme.breakpoints.up('md')]: {
                marginTop: '156px',
                '&.lessMargin': {
                    marginTop: '114px',
                },
            },
        },
        '&.client': {
            background: theme.palette.grey[50],
            [theme.breakpoints.up('md')]: {
                marginTop: '189px'
            },
            '&.lessMargin': {
                marginTop: '156px'
            },
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16,
            width: 'calc(100% - 64px)',
        },
    },

    content: {
        minHeight: '98vh',
        flexGrow: 1,
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth
        },
    },
    contentHome: {
        minHeight: '98vh',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },


    // SIDENAV
    containerLogo: {
        width: 240,
        position: 'fixed',
        top: 100,
        height: 64,
        '&.dark': {
            backgroundColor: theme.palette.grey[900],
            top: 50,
        },
    },
    logo: {
        position: 'fixed',
        left: '48px',
        width: '144px',
        height: '48px',
        zIndex: 1200,
        '&.admin': {
            top: '58px',
        },
        '&.client': {
            top: '100px',
        },
    },
    lineBox: {
        borderTop: `1px solid ${theme.palette.grey[700]}`,
        position: 'fixed',
        left: '16px',
        width: '200px',
        height: 'auto',
        zIndex: 1200,
        color: theme.palette.grey[700],
        textAlign: 'center',
        '&.admin': {
            top: '118px',
        },
        '&.client': {
            top: '156px',
        },
    },


    // CLIENTLAYOUT

    clientMenu: {
        "& a": {
            height: 50,
            paddingRight: 24,
            display: "flex",
            color: theme.palette.secondary.main,
            "&:hover": {
                color: theme.palette.secondary.light,
            },
            "&:active": {
                color: theme.palette.secondary.main,
            },
        },
        "& span": {
            display: "flex",
            alignItems: 'center',
            height: 47,
            fontWeight: 500,
            borderBottom: `2px solid ${theme.palette.common.white}`,
            "&:hover": {
                borderBottom: `3px solid ${theme.palette.secondary.light}`,
            },
            "&:active": {
                borderBottom: `3px solid ${theme.palette.secondary.dark}`,
            }
        },
        '&.active span': {
            borderBottom: `3px solid ${theme.palette.secondary.main}`,
        }
    },
    buttonMoreOptions:{
        display: 'none',
    },
    optionsModalNavbar:{
        color: theme.palette.secondary.main,
        "& p":{
            fontWeight: 500,
        },
        "&:hover": {
            color: theme.palette.secondary.light,
        },
        '&.active p': {
            borderBottom: `3px solid ${theme.palette.secondary.main}`,
        }
    },
    [theme.breakpoints.down('md')]:{
        clientMenu: {
            "&:nth-last-child(-n+3)": {
                display: 'none'
            },
        },
        buttonMoreOptions:{
            display: 'flex',
        },
    }
}));
