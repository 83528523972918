import { IMenuState, START_GET_MENUS, SUCCESS_GET_MENUS, ERROR_GET_MENUS } from '../types';
    
const initialState: IMenuState = {
    menus: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_MENUS:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_MENUS:
        return{
            ...state,
            menus: action.payload.menus,
            msg: null,
            error:false,
            loading: false
        }
        case ERROR_GET_MENUS:
        return{
            ...state,
            menus: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}