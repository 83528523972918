// MUI
import { BookmarkOutlined, DirectionsBoatRounded, EventRounded, FlashOnRounded, LocalActivityRounded, LocalOfferRounded, SchoolRounded } from '@material-ui/icons';

// Types
export type IMenuIcons = {
  offers: JSX.Element,
  library: JSX.Element,
  academy: JSX.Element,
  events: JSX.Element,
  novelties: JSX.Element,
  'know-to-buy': JSX.Element,
  seminars: JSX.Element
}

type MenuIconsProps = {
  menuCode: keyof IMenuIcons
}

function MenuIcons({ menuCode }: MenuIconsProps) {

  const menuIcons: IMenuIcons = {
    offers: <LocalOfferRounded />,
    library: <BookmarkOutlined />,
    academy: <SchoolRounded />,
    events: <EventRounded />,
    novelties: <FlashOnRounded />,
    'know-to-buy': <LocalActivityRounded />,
    seminars: <DirectionsBoatRounded />
  };

  return <>{menuIcons[menuCode]}</>
}

export default MenuIcons