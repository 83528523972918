import {
    ICourseState,
    START_GET_COURSES, SUCCESS_GET_COURSES, ERROR_GET_COURSES,
    START_GET_FILTERED_COURSES, SUCCESS_GET_FILTERED_COURSES, ERROR_GET_FILTERED_COURSES,
    START_GET_COURSES_BY_BRAND, SUCCESS_GET_COURSES_BY_BRAND, ERROR_GET_COURSES_BY_BRAND,
    START_GET_HOME_COURSES, SUCCESS_GET_HOME_COURSES, ERROR_GET_HOME_COURSES,
    START_STORE_COURSE, SUCCESS_STORE_COURSE, ERROR_STORE_COURSE,
    START_SHOW_COURSE, SUCCESS_SHOW_COURSE, ERROR_SHOW_COURSE,
    START_SHOW_COURSE_BY_SLUG, SUCCESS_SHOW_COURSE_BY_SLUG, ERROR_SHOW_COURSE_BY_SLUG,
    START_DELETE_COURSE, SUCCESS_DELETE_COURSE, ERROR_DELETE_COURSE,
    START_COURSE_LESSON_STEP, SUCCESS_COURSE_LESSON_STEP, ERROR_COURSE_LESSON_STEP,
    START_ASSIGN_TAG_COURSE, SUCCESS_ASSIGN_TAG_COURSE, ERROR_ASSIGN_TAG_COURSE,
    START_DELETE_TAG_COURSE, SUCCESS_DELETE_TAG_COURSE, ERROR_DELETE_TAG_COURSE,
    START_ASSIGN_HIGHLIGHT_COURSE, SUCCESS_ASSIGN_HIGHLIGHT_COURSE, ERROR_ASSIGN_HIGHLIGHT_COURSE,
    SUCCESS_TOGGLE_FORM_COURSE,
    START_MASS_ASSIGNMENT_COURSES, SUCCESS_MASS_ASSIGNMENT_COURSES, ERROR_MASS_ASSIGNMENT_COURSES
} from '../types';

const initialState: ICourseState = {
    courses: [],
    coursesProgress: [],
    homeCourses: [],
    feedCourses: [],
    course_selected: null,
    reload: false,
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_COURSES:
        case START_GET_FILTERED_COURSES:
        case START_GET_COURSES_BY_BRAND:
        case START_STORE_COURSE:
        case START_SHOW_COURSE:
        case START_SHOW_COURSE_BY_SLUG:
        case START_DELETE_COURSE:
        case START_COURSE_LESSON_STEP:
        case START_ASSIGN_TAG_COURSE:
        case START_DELETE_TAG_COURSE:
        case START_ASSIGN_HIGHLIGHT_COURSE:
        case START_GET_HOME_COURSES:
        case START_MASS_ASSIGNMENT_COURSES:
            return {
                ...state,
                error: false,
                reload: false,
                loading: true
            }
        case SUCCESS_GET_COURSES:
        case SUCCESS_GET_FILTERED_COURSES:
            return {
                ...state,
                courses: action.payload.courses,
                reload: false,
                error: false,
                loading: false
            }
        case SUCCESS_GET_COURSES_BY_BRAND:
            return {
                ...state,
                courses: action.payload.pageNumber === 1
                    ? action.payload.courses
                    : [...state.courses, ...action.payload.courses],
                coursesProgress: action.payload.pageNumber === 1
                    ? action.payload.coursesProgress
                    : [...state.coursesProgress, ...action.payload.coursesProgress],
                reload: false,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_GET_HOME_COURSES:
            return {
                ...state,
                homeCourses: action.payload.courses,
                feedCourses: action.payload.feedCourses,
                reload: false,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_STORE_COURSE:
            return {
                ...state,
                reload: true,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_COURSE:
            return {
                ...state,
                course_selected: action.payload.course,
                reload: false,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_COURSE_BY_SLUG:
            return {
                ...state,
                course_selected: action.payload.course,
                reload: false,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_COURSE_LESSON_STEP:
            const foundLessonIndex = state.course_selected.lessons.findIndex((lesson: any) => lesson.id === action.payload.course.lesson?.id);
            let lessonsState = state.course_selected.lessons;
            if (foundLessonIndex >= 0) {
                lessonsState[foundLessonIndex].lesson_viewed = [{ ...action.payload.course.lesson }];
            }
            return {
                ...state,
                course_selected: { ...state.course_selected, progress: action.payload.course.progress, lessons: lessonsState },
                reload: false,
                error: false,
                loading: false
            }
        case SUCCESS_DELETE_COURSE:
        case SUCCESS_ASSIGN_HIGHLIGHT_COURSE:
            return {
                ...state,
                msg: action.payload.msg,
                reload: true,
                error: false,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_COURSE:
            return {
                ...state,
                course_selected: (action.payload) ? null : state.course_selected,
                showForm: !state.showForm,
                reload: false
            }
        case SUCCESS_ASSIGN_TAG_COURSE:
            let tmpCourse = state.courses.find((course: any) => course.id === action.payload.id);
            tmpCourse.tags = action.payload.tags.toString();
            return {
                ...state,
                courses: state.courses.map((course: any) =>
                    course.id === action.payload.id ? tmpCourse : course
                ),
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_DELETE_TAG_COURSE:
            let tmpCourse1 = state.courses.find((course: any) => course.id === action.payload.id);
            const tags = tmpCourse1.tags.split(',');
            const newTags = tags.filter((tagId: number) => tagId != action.payload.tagId).toString();
            tmpCourse1.tags = newTags === '' ? null : newTags;
            return {
                ...state,
                courses: state.courses.map((course: any) =>
                    course.id === action.payload.id ? tmpCourse1 : course
                ),
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_MASS_ASSIGNMENT_COURSES:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false,
                reload: true
            }
        case ERROR_GET_COURSES:
        case ERROR_GET_FILTERED_COURSES:
        case ERROR_GET_COURSES_BY_BRAND:
        case ERROR_GET_HOME_COURSES:
        case ERROR_STORE_COURSE:
        case ERROR_SHOW_COURSE:
        case ERROR_SHOW_COURSE_BY_SLUG:
        case ERROR_DELETE_COURSE:
        case ERROR_COURSE_LESSON_STEP:
        case ERROR_ASSIGN_TAG_COURSE:
        case ERROR_DELETE_TAG_COURSE:
        case ERROR_ASSIGN_HIGHLIGHT_COURSE:
        case ERROR_MASS_ASSIGNMENT_COURSES:
            return {
                ...state,
                reload: false,
                msg: action.payload,
                error: true,
                showForm: false,
                loading: false
            }
        default:
            return state;
    }
}