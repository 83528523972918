// React
import { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// Material UI
import { Container, Button, Box, Grid, Menu, MenuItem, IconButton, Typography, Tooltip, Fade } from '@material-ui/core';
import { ArrowForward, InfoOutlined, MoreVert } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../../config/table';

// Commons components
import SeminarModal from '../../../components/seminars/SeminarModal';
import CountriesFilter from '../../../components/common/admin/CountryFilter';
import CustomChip from '../../../components/common/admin/CustomChip';
import Header from '../../../components/common/header/Header';
import TypeFilter from '../../../components/common/header/TypeFilter';
import SubHeader from '../../../components/common/header/Subheader';
import ScrollSubheader from '../../../components/common/header/ScrollSubHeader';
import VisibilityIcon from '../../../components/common/admin/VisibilityIcon';
import HighlightIcon from '../../../components/common/admin/HighlightIcon';
import NotFilter from '../../NotFilter';
import SeminarDeleteModal from '../../../components/seminars/SeminarDeleteModal';

// Custom Hooks
import useBooleanState from '../../../hooks/useBooleanState';

// Redux Actions
import { getSeminars, showSeminar, toggleFormSeminar, assignHighlight } from '../../../redux/actions/seminars'
import { getSeminartypes } from '../../../redux/actions/seminartypes'
import { IRootState } from '../../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

//Styles
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles';

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

interface IFilter {
    seminartype_id: number | null,
    family_id: number | null,
    brand_id: number | null,
    countries: number[],
    language_id: number | null,
    orderby_id: number | null
}

function Seminars({ history }: RouteComponentProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const tableclass = TableScreenStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { seminars, loading, reload, showForm } = useSelector((state: IRootState) => state.seminar);
    const { seminartypes } = useSelector((state: IRootState) => state.seminartype);
    const { globalFamilyId, globalBrandId, globalCountriesId, globalLanguageId, globalOrderById } = useSelector((state: IRootState) => state.global);

    // Filter State
    const [filters, setFilters] = useState<IFilter>({
        seminartype_id: null,
        family_id: null,
        brand_id: null,
        countries: [],
        language_id: null,
        orderby_id: null
    });

    // Component States
    const [openDeleteSeminar, setOpenDeleteSeminar] = useBooleanState(false);
    const [seminarSelected, setSeminarSelected] = useState(null);
    // Table menu function
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(-1);

    // Variables Filter by
    const status = [{ id: 0, name: 'Activo' }, { id: 1, name: 'Inactivo' }];
    const visibility = [{ id: 0, name: t('close') }, { id: 1, name: 'VIP' }];
    const optionsData = [{ data: status, code: 'status', column: 'status' }, { data: visibility, code: 'visibility', column: 'visibility' }];

    useEffect(() => {
        if (!seminartypes.length && globalLanguageId !== null && globalCountriesId.length > 0)
            dispatch(getSeminartypes({
                language_id: globalLanguageId,
                countries_id: globalCountriesId
            }));
    }, [globalLanguageId, globalCountriesId]);

    useEffect(() => {
        if (reload) {
            dispatch(getSeminars({
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                countries: globalCountriesId,
                language_id: globalLanguageId,
                orderby_id: globalOrderById
            }));
        }
    }, [reload]);

    useEffect(() => {
        setFilters({
            ...filters,
            seminartype_id: null,
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            language_id: globalLanguageId,
            countries: globalCountriesId
        })
    }, [globalFamilyId, globalBrandId, globalCountriesId, globalLanguageId, globalOrderById]);

    useEffect(() => {
        if (filters.language_id !== null && filters.countries.length > 0)
            dispatch(getSeminars(filters));
    }, [filters]);

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const handleClickHighlight = (id: number, isHighlight: boolean) => {
        dispatch(assignHighlight({
            id,
            isHighlight
        }));
    };

    const handleChangeFilter = (seminartype_id: number) => {
        setFilters({ ...filters, seminartype_id });
    }

    const columns: any = [
        {
            title: t("title"), field: 'title', render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        {(() => {
                            switch (rowData.status) {
                                case 0:
                                    return (<CustomChip size="small" color={'secondary'} />)
                                case 1:
                                    return (<CustomChip size="small" color={'primary'} />)

                                default:
                                    return (<CustomChip size="small" color={'secondary'} />)
                            }
                        })()}
                        <Typography className={tableclass.nameResource}>
                            {rowData.title}
                        </Typography>
                        <HighlightIcon id={rowData.id} isHighlight={(rowData.highlight) ? true : false} handleClick={handleClickHighlight} />
                        <VisibilityIcon id={rowData.visibility} />
                    </Box>)
            }
        },
        { title: t("brand"), field: 'brandName', hidden: (globalBrandId !== null), type: string },
        { title: t("language"), field: "languageCode", hidden: isMobile, type: string },
        { title: t("rol"), field: 'roleName', hidden: isMobile },
        {
            title: `${t('date')} ${t('live-event')}`, field: 'datetime', type: string, hidden: isMobile, render: (rowData: any) => (<> {rowData.datetime} </>)
        },
        {
            title: '', field: '', render: (rowData: any) =>
                <>

                    <IconButton
                        id={`icon-button-${rowData.id}`}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id={`simple-menu-${rowData.id}`}
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}>
                        <MenuItem onClick={() => history.push(`seminarios/${rowData.id}`)}>{t("view")}</MenuItem>
                        <MenuItem onClick={() => { dispatch(showSeminar(rowData.id)); dispatch(toggleFormSeminar()) }}>{t("edit")}</MenuItem>
                        <MenuItem onClick={() => { setOpenDeleteSeminar(!openDeleteSeminar); setSeminarSelected(rowData.id) }}>{t("delete")}</MenuItem>
                    </Menu>
                </>
        }
    ];

    return (
        <>

            {showForm &&
                <SeminarModal />
            }

            <Header title={t("seminars")} />
            <SubHeader>
                <ScrollSubheader>
                    <TypeFilter
                        id={null}
                        type={t("all")}
                        categories={[]}
                        active={filters.seminartype_id === null}
                        handleChangeFilter={handleChangeFilter}
                    />
                    {seminartypes.map((seminartype: any, index: number) => (
                        <TypeFilter
                            key={seminartype.id}
                            id={seminartype.id}
                            type={seminartype.name}
                            categories={[]}
                            active={seminartype.id === filters.seminartype_id}
                            handleChangeFilter={handleChangeFilter}
                        />
                    ))}
                    <ArrowForward />
                </ScrollSubheader>
            </SubHeader>

            <Container maxWidth="lg">
                <Box mt={isMobile ? 2 : 6} gridGap={16} display="flex" flexDirection={isMobile ? 'column-reverse' : 'column'}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h5" align="center">{t("all")}</Typography>
                        <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow
                            title={
                                <Box>
                                    <Typography variant="body2">{t('status')}:</Typography>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'primary'} />
                                        <Typography variant="caption">{t('active')}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'secondary'} />
                                        <Typography variant="caption">{t('inactive')}</Typography>
                                    </Box>
                                </Box>
                            }>
                            <IconButton>
                                <InfoOutlined htmlColor={'#8F99A3'} fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box >
                        <Grid container justify="space-between" spacing={1}>
                            <Grid item xs={5} sm={6} className={tableclass.actionsResponsive}>
                                <Button id="btn-create-seminar" variant="outlined" color="secondary"
                                    onClick={() => dispatch(toggleFormSeminar(true))}>
                                    {`${t('create')} ${t('live-event')}`}
                                </Button>
                            </Grid>
                            <Grid item xs={7} sm={6} className={tableclass.actionsResponsiveRight}>
                                {/* <AdminContentFilter data={optionsData} handleClick={null} /> */}
                                <CountriesFilter />
                            </Grid>
                        </Grid>
                    </Box>


                    {!seminars.length && !loading ? <NotFilter /> :
                        <Box mt={3}>
                            <div className="table_custom">
                                <MaterialTable
                                    localization={localization}
                                    title="Seminarios Admin"
                                    columns={columns}
                                    data={seminars}
                                    options={options}
                                    icons={tableIcons}
                                />
                            </div>
                        </Box>
                    }

                </Box>
                <SeminarDeleteModal open={openDeleteSeminar} setOpen={setOpenDeleteSeminar} seminar_id={seminarSelected} />
            </Container>

        </>
    );
}

export default Seminars;