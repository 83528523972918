// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Material UI
import { Box, makeStyles, Grid, Typography } from '@material-ui/core';

// Custom Components
import CardHero from './CardHero';
import ShareIcon from '../ShareIcon';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../../redux/actions/favorites';
import { getClientNovelties } from '../../../redux/actions/novelties';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';

// Custom Style
const NewsFeedStyle = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid  #C7CCD1',
        marginTop: 32,
        marginBottom: 24,
        "& h5": {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                fontWeight: 500,
            },
        },
        "& p": {
            whiteSpace: "nowrap",
        }
    },
    seeMore: {
        textDecoration: 'underline',
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
}));

function NewsFeed() {

    // Translate
    const { t } = useTranslation(["global"]);

    // Styles
    const classes = NewsFeedStyle();

    /* Functions */
    const history = useHistory();

    //Redux Hooks
    const dispatch = useDispatch();
    const { news } = useSelector((state: IRootState) => state.novelty);
    const { globalCountryId, globalLanguageId } = useSelector((state: IRootState) => state.global);

    useEffect(() => {
        dispatch(getClientNovelties({
            country_id: globalCountryId,
            language_id: globalLanguageId,
            type: 'news',
            requesttype: 2
        }));
    }, [globalCountryId, globalLanguageId]);

    /* Functions */
    const cutContent = (content: string) => {
        let trimmedString: string = '';
        trimmedString = content.substring(0, Math.min(content.length, content.indexOf("</p>")));
        return trimmedString;
    }

    const handleFavoriteClick = (id: number, type: string, inputField: string) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id,
                state,
                type,
                favoritefield: inputField
            }));
        } else {
            dispatch(unsetFavorite({
                id,
                state,
                type: type,
                favoritefield: inputField
            }));
        }
    };

    return (
        <>
            {/* Noticias */}
            {(news.length > 0) &&
                <Grid item xs={12} className={classes.header}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">{t("featured")}</Typography>
                        <Typography onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); history.push(`/noticias`) }} color="textSecondary" className={classes.seeMore}>{t("see-more")}</Typography>
                    </Box>
                </Grid>
            }
            <Grid container spacing={2}>
                {news.slice(0, 3).map((currentNews: any, index: number) => (
                    <Grid key={currentNews.id} item xs={12} md={6} lg={4}>
                        <CardHero
                            type={1}
                            redirect={`/noticias/${currentNews.slug}`}
                            cardBrandName={currentNews.brand?.name || null}
                            cardVisibility={currentNews.visibility}
                            cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${currentNews.image_url}`}
                            cardTitle={currentNews.title}
                            cardDescription={<div dangerouslySetInnerHTML={{ __html: cutContent(currentNews.content) }}></div>}
                            cardDate={`${moment(currentNews.created_at).fromNow()}`}
                            cardActions={
                                <ShareIcon
                                    visibility={currentNews.visibility}
                                    title={currentNews.title}
                                    description={currentNews.title}
                                    link={`${window.location.origin}/noticias/${currentNews.slug}${window.location.search}`}
                                    type="news"
                                    multimedia={`${process.env.REACT_APP_AWS_URL}/novelties/${currentNews.image_url}`}
                                    buttonText={t("share")}
                                />
                            }
                            favoriteState={Boolean(currentNews.favoriteId)}
                            onClickFavorite={handleFavoriteClick(currentNews.id, 'novelty', 'news')}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default NewsFeed;
