import { makeStyles, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => createStyles({
    buttonUser: {
        cursor: "pointer",
        display: "flex",
        marginRight: 16,
        position: 'relative',
        color: theme.palette.grey[400],
        padding: 8,
        "&:hover svg": {
            color: theme.palette.primary.main,
        },
        '&.admin': {
            "&:hover p": {
                color: theme.palette.grey[50],
            },
            "&:hover svg": {
                color: theme.palette.grey[50],
            },
        },
        "& p": {
            padding: `0px  ${theme.spacing(1)}px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: 0,
            marginLeft: 16,
        },
    },
    avatarBtn: {
        height: 24,
        width: 24,
        fontSize: 13,
        backgroundColor: theme.palette.info.dark,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        },
    },
    avatarBtnMobile: {
        height: 35,
        width: 35,
        fontSize: 14,
        fontWeight: 300,
        backgroundColor: '#003f8f',
        '&:hover': {
            backgroundColor: theme.palette.info.main,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 14,
            marginRight: 14,
            backgroundColor: theme.palette.info.main,
        },
    },
    drvLogo: {
        margin: 'auto 0',
        height: 14,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        '&.admin': {
            filter: 'brightness(0) invert(1)',
        }
    },
    buttonBrand: {
        cursor: "pointer",
        display: "flex",
        alignItems: 'center',
        color: theme.palette.grey[400],
        "&:hover": {
            color: theme.palette.grey[200],
        },
        "&:hover p": {
            color: theme.palette.grey[400],
        },
    },

    productBy: {
        display: 'flex',
        position: "absolute",
        bottom: 0,
        gridGap: 10,
        padding: '10px 0px',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: theme.palette.grey[50],
        boxShadow: '-2px -2px 8px 0px #00000012',
    },

    drawerPaper: {
        width: 240,
        paddingTop: '5px',
        border: 'none',
        maxHeight: '-webkit-fill-available',
        height: '-webkit-fill-available',
        overflowY: 'auto',
        overflowX: 'hidden',
        background: theme.palette.grey[50],
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '6px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '189px'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16,
            width: 'calc(100% - 64px)',
        },
    },
    btnCloseDrawer: {
        top: 0,
        right: 0,
        position: 'fixed',
        margin: '22px 17px',
    },
}));
