// React
import { useLocation } from "react-router-dom";

// Material UI
import { Box, Typography,  Container, } from '@material-ui/core';

// Custom components
import ShareIcon from '../ShareIcon';
import InterSlider from './InternalSlider';

// Libraries
import { useTranslation } from "react-i18next";

// Custom Style
import HeaderStyles from '../../../styles/components/common/header/HeaderStyles';

export default function Header(props: any) {
    // Translate
    const { t } = useTranslation(["global"]);
    // Props 
    const { title } = props;
    const { type } = props;

    // Styles
    const classes = HeaderStyles();

    //Location
    const location = useLocation();
    let noShare = location.pathname.includes('admin') || (location.pathname === '/escritorio' || location.pathname === '/usuarios/perfil' || location.pathname === '/favoritos');    
    let isHidden = location.pathname === '/comercial' || location.pathname === '/favoritos';
    

    return (
        <Box className={classes.body}>
            <InterSlider isHidden={isHidden}/>
            {type === 1 ? 
                <></>
                :
                <>
                    <Container maxWidth="lg" className={classes.contentTitle}>
                        <Box
                            display='flex'
                            flexDirection="column"
                            gridGap={16}
                            pb={2}
                            pt={2}>

                            <Box className={classes.border}>
                                <Typography variant="h2" className={classes.title}> {title}</Typography>
                                {(noShare) ?
                                    <></>
                                    : 
                                        <ShareIcon
                                            meta={true}
                                            light={true}
                                            visibility={0}
                                            title={'Biblioteca'}
                                            description={'Biblioteca'}
                                            link={window.location.href}
                                            multimedia={``}
                                            component="button"
                                            type="resource"
                                        />
                                }
                            </Box>
                        </Box>
                    </Container>
                </>
            }
            
        </Box>
    );
}
