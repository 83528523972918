import { makeStyles, createStyles } from '@material-ui/core/styles';
import mainTheme from './themes/ClientTheme';

export default makeStyles((theme) => createStyles({
    container: {
        //Layout
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    logoContainer: {
        zIndex: 30,
        position: 'absolute',
        top: 0,
        left: 0,
        padding: 48,
        [mainTheme.breakpoints.down('sm')]: {
            padding: 32,
        },
    },
    logo: {
        maxHeight: 36,
    },
    surfaceImage: {
        //Layout
        height: '84vh',
        width: '100vw',
        //Styles
        zIndex: 10,
        position: 'absolute',
        bottom: 0,
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        [mainTheme.breakpoints.down('sm')]: {
            height: '44vh',
        },
    },
    surfaceGradient: {
        //Layout
        height: '100vh',
        width: '100vw',
        //Style
        zIndex: 20,
        position: 'absolute',
        top: 0,
        overflow: 'hidden',
        background: 'linear-gradient(180deg, #FFF 17.98%, rgba(217, 217, 217, 0.00) 51.13%)',
        [mainTheme.breakpoints.down('sm')]: {
            height: '60vh',
            background: '#FFACBE',
        },
    },
    surfacePaper: {
        //Layout
        display: 'flex',
        maxWidth: 550,
        padding: '48px 32px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 40,
        marginBottom: '24vh',
        [mainTheme.breakpoints.down('sm')]: {
            margin: 24,
            padding: 32,
        },
        // Style
        borderRadius: 16,
        background: 'rgba(30, 30, 30, 0.25)',
        backdropFilter: 'blur(40px)',
        zIndex: 40,
    },
    dividerGradient: {
        height: 2,
        width: '100%',
        background: 'linear-gradient(270deg, rgba(244, 244, 244, 0.00) 2.08%, rgba(244, 244, 244, 0.84) 33.33%, rgba(244, 244, 244, 0.72) 66.67%, rgba(244, 244, 244, 0.00) 100%)',
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: 24,
        alignSelf: 'stretch'
    },
    messagePaper: {
        color: '#FFF',
    },
    buttonBack: {
        color: '#FFF',
        height: 42,
        width: 'unset',
        paddingLeft: 80,
        paddingRight: 80,
        borderRadius: 8,
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.16)',
        backgroundColor: mainTheme.palette.primary.main,
        [mainTheme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '&:hover':{
            backgroundColor: mainTheme.palette.primary.dark,
        }
    },
}));