// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// MATERIAL UI
import { Button, TextField, Dialog, DialogActions, DialogTitle, DialogContent, Grid, Typography } from '@material-ui/core';
import { CloseRounded, Save } from '@material-ui/icons/';

// Redux Actions
import { changePassword } from '../../../redux/actions/auth';
import { formValidator, resetFormValidator } from '../../../redux/actions/validator';
import { IRootState } from '../../../redux/types';

//Custom Components
import { CancelButton, SaveButton } from '../../common/forms';

// Libraries
import { useTranslation } from "react-i18next";
//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';
//Styles
import FormsStyles from '../../../styles/components/common/forms/FormsStyles';

// Types
type UserProfileProps = {
    showForm: boolean,
    setShowForm: any
}

function UserProfile({ showForm, setShowForm }: UserProfileProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const formClasses = FormsStyles();

    // Redux Hooks
    const dispatch = useDispatch();
    const { isValidForm, errorForm, msgForm } = useSelector((state: IRootState) => state.validator);
    const { user: authUser, error: errorAuth, msg: msgAuth } = useSelector((state: IRootState) => state.auth);

    // UserPassword State
    const [userPassword, setUserPassword] = useState({
        oldPassword: '',
        password: '',
        confirmPassword: '',
        showPassword: false
    });

    // Send Form UserPassword
    useEffect(() => {
        if (isValidForm && errorForm.password == false && errorForm.confirmPassword == false) {
            dispatch(resetFormValidator());
            dispatch(changePassword({ userPassword }));
            setUserPassword({
                oldPassword: '',
                password: '',
                confirmPassword: '',
                showPassword: false
            });
            setShowForm();
        }
    }, [isValidForm]);

    /* Functions */
    // Toggle Show Password
    const handleClickShowPassword = () => {
        setUserPassword({ ...userPassword, showPassword: !userPassword.showPassword });
    };
    // Input State Form
    const handleChangePassword = (inputName: string) => (event: any) => {
        setUserPassword({ ...userPassword, [inputName]: event.target.value });
    };
    // Validate password Form
    const handleSubmitPassword = () => {
        dispatch(formValidator({
            password: [userPassword.password, 'required|max:45|min:3'],
            confirmPassword: [userPassword.confirmPassword, 'required|max:45|min:3|equal:' + userPassword.password]
        }));
    }

    return (
        <Dialog
            open={showForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "sm"}
            onClose={() => setShowForm()}
            className={formClasses.containerForm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {t("login.change-password")}
                    </Typography>
                    <Button
                        onClick={() => setShowForm()}
                        color="primary"
                        variant="text"
                    >
                        <CloseRounded />
                    </Button>
                </Grid>
            </DialogTitle>
            <DialogContent className={formClasses.bodyForm}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={t("login.current-password")}
                            type={userPassword.showPassword ? 'text' : 'password'}
                            fullWidth
                            required
                            margin="normal"
                            value={userPassword.oldPassword}
                            onChange={handleChangePassword('oldPassword')}
                            InputLabelProps={{ shrink: true }}
                            variant="filled" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t("login.new-password")}
                            type={userPassword.showPassword ? 'text' : 'password'}
                            fullWidth
                            required
                            margin="normal"
                            value={userPassword.password}
                            error={errorForm.password}
                            helperText={msgForm.password}
                            onChange={handleChangePassword('password')}
                            InputLabelProps={{ shrink: true }}
                            variant="filled" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t("login.confirm-password")}
                            type={userPassword.showPassword ? 'text' : 'password'}
                            fullWidth
                            required
                            margin="normal"
                            value={userPassword.confirmPassword}
                            error={errorForm.confirmPassword}
                            helperText={msgForm.confirmPassword}
                            onChange={handleChangePassword('confirmPassword')}
                            InputLabelProps={{ shrink: true }}
                            variant="filled" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => setShowForm()}>{t("cancel")}</CancelButton>
                <SaveButton id="btn-handlelogin"
                    onClick={() => handleSubmitPassword()}>
                    {t("login.change-password")}
                </SaveButton>
            </DialogActions>
        </Dialog>
    );
}

export default UserProfile;