// React
import { useState, MouseEvent } from "react";
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import {
    Box, Typography, Card, CardContent, IconButton, Menu, MenuItem,
    DialogActions, Dialog, DialogTitle, DialogContent, Grid, Button
} from "@material-ui/core";
import { QueryBuilderRounded, MoreVert, Delete, Visibility, VisibilityOff, PlayCircleOutline, Cached, ReplayRounded, PlayCircleFilled, CloseRounded } from "@material-ui/icons/";
import clsx from 'clsx';

// Custom Components
import CustomSnackBar from "../../components/common/admin/CustomSnackBar";
import ScreenLoading from "../../components/common/ScreenLoading";

// Redux Actions
import { showLesson, setViewLesson, deleteLesson } from '../../redux/actions/lessons';
import { courseLessonStep } from '../../redux/actions/courses';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// STYLES
import CoursesStyle from '../../styles/components/courses/CoursesStyles';
import VipStyles from '../../styles/components/common/VipStyles';
import FormsStyles from "../../styles/components/common/forms/FormsStyles";
import { CancelButton, DeleteButton, SaveButton } from "../common/forms";

// Types
type LessonTemplateProps = {
    lesson: any,
    hasaccess: boolean,
    vipContent: boolean,
}

function LessonsDetail({ lesson, vipContent, hasaccess }: LessonTemplateProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = CoursesStyle();
    const vipclasses = VipStyles();
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { loading, msg, error } = useSelector((state: IRootState) => state.lesson);
    // Component States
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(false);
    const [openDeleteLesson, setOpenDeleteLesson] = useState(false);
    const [lessonSelected, setLessonSelected] = useState(false);

    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(false);
    };

    const handleClickLesson = () => {
        if (authUser !== null && hasaccess)
            dispatch(courseLessonStep({ course_id: lesson.course_id, lesson_order: lesson.order }));

        dispatch(setViewLesson(lesson))
    }

    return (
        <>


            <Card className={clsx(classes.lessons, lesson.lesson_viewed.length > 0 ? 'active' : '', hasaccess ? '' : 'block', vipContent && vipclasses.lessons, 'websiteCourseLesson')}>
                <CardContent>
                    <Grid container justify="space-between" alignItems="center">

                        <Grid item xs={1} sm={2} onClick={() => handleClickLesson()}>
                            <Box width='100%' justifyContent="center" display="flex">
                                {(lesson.lesson_viewed.length > 0) ?
                                    <ReplayRounded className={classes.lessonPlay} />
                                    :
                                    <PlayCircleFilled className={classes.lessonPlay} />
                                }
                            </Box>
                        </Grid>

                        <Grid item xs={9} onClick={() => handleClickLesson()}>
                            <Box pl={isMobile ? 2 : 0}>
                                <Typography variant="subtitle1">{lesson.title}</Typography>

                                <Box display="flex" gridGap={8} alignItems="center" pt={isMobile ? 0 : 1}>
                                    <QueryBuilderRounded fontSize='inherit' />
                                    <Typography color="inherit">{lesson.duration} Min</Typography>
                                </Box>
                                <Box display="flex" gridGap={8} alignItems="center" pt={isMobile ? 0 : 1} className={(vipContent) ? vipclasses.text : classes.textPrimary}>
                                    {(lesson.lesson_viewed.length > 0) ?
                                        <>
                                            {(vipContent) ?
                                                <Visibility color="inherit" className={vipclasses.icon} />
                                                :
                                                <Visibility color="inherit" />
                                            }
                                            <Typography variant="body1" color="inherit">Lección reproducida</Typography>
                                        </>

                                        :

                                        <>
                                            {(vipContent) ?
                                                <VisibilityOff color="inherit" className={vipclasses.icon} />
                                                :
                                                <VisibilityOff color="inherit" />
                                            }
                                            <Typography variant="body1" color="inherit">Por reproducir</Typography>
                                        </>
                                    }
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={2} sm={1}>
                            {(authUser != null && [1, 2, 5].includes(authUser.role_id)) &&
                                <>
                                    <IconButton
                                        id={`icon-button-${lesson.id}`}
                                        aria-controls="icon-menu"
                                        aria-haspopup="false"
                                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                            setAnchorOp(event.currentTarget);
                                            setOpenOptions(true);
                                        }}>
                                        {/* USE IN VIP */}
                                        {/* <MoreVert style={{ fill: "#B49568", }}/> */}
                                        <MoreVert />
                                    </IconButton>
                                    <Menu
                                        id={`simple-menu-${lesson.id}`}
                                        anchorEl={anchorOp}
                                        getContentAnchorEl={null}
                                        keepMounted
                                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                        open={openOptions}
                                        onBackdropClick={handleCloseOptions}
                                        onClick={handleCloseOptions}
                                        onClose={handleCloseOptions}>
                                        <MenuItem onClick={() => dispatch(setViewLesson(lesson))}>{t("view")}</MenuItem>
                                        <MenuItem onClick={() => dispatch(showLesson(lesson.id))}>{t("edit")}</MenuItem>
                                        <MenuItem onClick={() => { setOpenDeleteLesson(!openDeleteLesson); setLessonSelected(lesson.id) }}>{t("delete")}</MenuItem>
                                    </Menu>
                                </>
                            }
                        </Grid>


                    </Grid>

                </CardContent>
                {(vipContent && !hasaccess) &&
                    <Box alignContent="center" textAlign="center" className={vipclasses.lessonBlock}>
                        <Typography variant="subtitle1" color="inherit">Este es un contenido</Typography>
                        <Typography variant="h5" color="inherit">VIP</Typography>
                    </Box>
                }
            </Card>


            <Dialog
                open={openDeleteLesson}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={() => setOpenDeleteLesson(!openDeleteLesson)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            Eliminar Lección
                        </Typography>
                        <Button onClick={() => setOpenDeleteLesson(!openDeleteLesson)} color='primary' variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} >
                            <Typography>¿Está seguro que desea eliminar el lección?</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setOpenDeleteLesson(!openDeleteLesson)} >{t("cancel")}</CancelButton>
                    <DeleteButton
                        id="btn-handlelogin"
                        onClick={() => { dispatch(deleteLesson(lessonSelected)); setOpenDeleteLesson(!openDeleteLesson) }}
                    >
                        {t("delete")}
                    </DeleteButton>
                </DialogActions>
            </Dialog>

            <CustomSnackBar errors={[error]} msgs={[msg]} />
            <ScreenLoading loadings={[loading]} />
        </>
    );
}

export default LessonsDetail;
