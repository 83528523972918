// Material UI
import {
    Button,
    Dialog,
    Typography,
    Grid,
    DialogContent,
    DialogTitle,
    DialogActions,
} from '@material-ui/core'
import { CloseRounded, Cancel } from '@material-ui/icons'

// Custom components
import { SaveButton, CancelButton } from '../forms'

// Custom hooks
import useMassAssignmentData from '../../../hooks/useMassAssignmentData'
import useMassAssignmentAddedInputs from '../../../hooks/useMassAssignmentAddedInputs'
import useModalState from '../../../hooks/useModalState'

// Interfaces
import { IAddedInputs } from '../../../interfaces/MassAssignmentAddedInputs'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth'

// Styles
import FormsStyles from '../../../styles/components/common/forms/FormsStyles'
import MassAssigmentBody from "./MassAssigmentBody";

interface MassAssignmentModalProps {
    rows: any
    fields: string[]
    handleSubmit: (data: any) => void
}

const MassAssignmentModal = ({
    rows,
    fields,
    handleSubmit,
}: MassAssignmentModalProps) => {
    const { t } = useTranslation(['global'])
    const formClasses = FormsStyles()
    //Custom Hook
    const isMobile = useScreenWidth(700);

    const { open, openModal, closeModal } = useModalState()
    const [data, setData] = useMassAssignmentData()
    const { addedInputs, buttons, addInput, removeInput, handleCheckChange } =
        useMassAssignmentAddedInputs(fields)

    const handleSave = () => {
        const finalData: any = addedInputs.map((input: IAddedInputs) => ({
            name: input.name,
            value: data[input.name].id ?? data[input.name],
            overwrite: input.checked
        }))

        handleSubmit({ data: finalData, rows })
        closeModal()
    }

    return (
        <>
            {rows.length > 0 && (
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => openModal()}
                >
                    {t('admin.people.massively-assign')}
                </Button>
            )}

            <Dialog
                open={open}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                className={formClasses.containerForm}
                onClose={() => closeModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t('admin.people.massively-assign')}
                        </Typography>
                        <Button
                            onClick={() => closeModal()}
                            color="primary"
                            variant="text"
                        >
                            <CloseRounded />
                        </Button>
                    </Grid>
                </DialogTitle>

                <DialogContent className={formClasses.bodyForm}>
                    <MassAssigmentBody
                        data={data}
                        setData={setData}
                        addedInputs={addedInputs}
                        buttons={buttons}
                        addInput={addInput}
                        removeInput={removeInput}
                        handleCheckChange={handleCheckChange}
                    />
                </DialogContent>
                <DialogActions>
                    <CancelButton
                        onClick={() => closeModal()}
                        aria-label="close"
                    >
                        {t('cancel')}
                    </CancelButton>

                    <SaveButton
                        autoFocus
                        id="btn-save"
                        onClick={() => handleSave()}
                    >
                        {t('save')}
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MassAssignmentModal
