import { useState } from 'react';
// Material UI
import { IconButton, InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import { CloseRounded, SearchRounded } from '@material-ui/icons';
import clsx from 'clsx';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Custom Style
const SearchStyle = makeStyles(theme => ({
    search: {
        position: 'relative',
        backgroundColor: theme.palette.grey[100],
        marginLeft: 0,
        borderRadius: 8,
    },
    searchSmall: {
        position: 'relative',
        margin: '6px 24px',
        height: 20,
        width: 192,
        borderRadius: 4,
        '&.client': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[300],
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover, &:active, &:focus, &:focus-within': {
            color: theme.palette.grey[900],
            backgroundColor: theme.palette.grey[200],
            borderRadius: 8,
            fontWeight: 500,
            '& svg': {
                color: `${theme.palette.primary.main} !important`,
            },
        },
        '&.small': {
            padding: theme.spacing(0, 1),
            transform: 'scale(0.8)',
            '& svg': {
                color: `${theme.palette.grey[300]} !important`,
            },
        },
    },
    closeIcon: {
        margin: 0,
        position: 'absolute',
        right: 32,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px',
        },
    },
    inputRoot: {
        color: theme.palette.grey[600],
        '&.client': {
            '&:hover, &:active, &:focus, &:focus-within': {
                color: theme.palette.grey[900],
                backgroundColor: theme.palette.grey[200],
                borderRadius: 8,
                fontWeight: 500,
                '& svg': {
                    color: `${theme.palette.primary.main} !important`,
                },
            },
        },
        '&.admin': {
            color: theme.palette.grey[100],
            backgroundColor: theme.palette.grey[600],
            border: 'none',
            borderRadius: 4,
            '& svg': {
                color: `${theme.palette.grey[300]} !important`,
            },
            '&:hover, &:active, &:focus, &:focus-within': {
                color: theme.palette.grey[50],
                backgroundColor: theme.palette.grey[700],
                border: 'none',
                borderRadius: 4,
                '& svg': {
                    color: `${theme.palette.secondary.main} !important`,
                },
            },
        }
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        '&.small': {
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 400,
            '&:focus': {
                fontWeight: 500,
            },
        },
        '&.header': {
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '18ch',
                '&:focus': {
                    width: '20ch',
                },
            },
            [theme.breakpoints.down('sm')]: {
                width: '0.1ch',
                '&:focus': {
                    width: `calc(${window.innerWidth}px - 88px)`,
                    height: 40,
                },
            },
        }
    },
}));

// Types
type ContentSeacrhProps = {
    sectionName?: any,
    small?: boolean,
    layout?: any,
    handleContentSearch?: any
}

function ContentSearch({ sectionName = null, small = false, layout = null, handleContentSearch }: ContentSeacrhProps) {

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // inputFocus state
    const [focusState, setfocusState] = useState<boolean | HTMLElement>(false);

    // Styles
    const classes = SearchStyle();
    //Función que habilita el icono de cerrar input de búsqueda y el placeholder en breackpoint < 1280

    const handleCloseInput = () => {
        setfocusState(false);
    };

    const searchActive = focusState === true;
    let smallSize = small === true;

    return (
        <div className={clsx(((smallSize) ? classes.searchSmall : classes.search), layout)}>
            <InputBase
                onChange={handleContentSearch}
                placeholder={isMobile ? searchActive ? t("searchIn") + ' ' + `${sectionName}` : "" : t("searchIn") + ' ' + `${sectionName}`}
                classes={{
                    root: clsx(classes.inputRoot, ((smallSize) ? 'small' : ''), layout),
                    input: clsx(classes.inputInput, ((smallSize) ? 'small' : 'header'), layout),
                }}
                id='txt-content-filter-search'
                inputProps={{ 'aria-label': 'search' }}
                startAdornment={
                    <InputAdornment position="start" className={clsx(classes.searchIcon, layout, ((smallSize) ? 'small' : ''))}>
                        <SearchRounded />
                    </InputAdornment>
                }
                endAdornment={searchActive &&
                    <InputAdornment position="end" className={clsx(classes.searchIcon, layout, ((smallSize) ? 'small' : ''))}>
                        <IconButton
                            aria-label="toggle search close"
                            onClick={handleCloseInput}
                            edge="end"
                        >
                            <CloseRounded />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    )
}

export default ContentSearch;