import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_FILTER_LOGS, SUCCESS_FILTER_LOGS, ERROR_FILTER_LOGS,
    START_GET_CURRENT_COMMITS, SUCCESS_GET_CURRENT_COMMITS, ERROR_GET_CURRENT_COMMITS,
} from '../types';
import { apiCall } from '../config/axios';

function* filterLogs({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/logs/filter', {filters: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_FILTER_LOGS, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_FILTER_LOGS, payload: error.message})
    }
}

function* getCurrentCommits() {
    try{
        const response = yield call(apiCall, 'get', '/logs/currentcommits')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_CURRENT_COMMITS, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_CURRENT_COMMITS, payload: error.message})
    }
}

export default function* logs() {
    yield takeLatest(START_FILTER_LOGS, filterLogs);
    yield takeLatest(START_GET_CURRENT_COMMITS, getCurrentCommits);
}