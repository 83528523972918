import { Hidden } from "@material-ui/core";
import {
	makeStyles,
	createStyles,
} from "@material-ui/core/styles";
import mainTheme from "../../components/themes/ClientTheme";

export default makeStyles((theme) =>
	createStyles({
		hero:{
			backgroundSize:"cover !important",
			height: "220px",
			backgroundPosition:"center !important",
			borderRadius: theme.spacing(2),
			boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.1)',
		},
		cardColor: {
			boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.1)',
			borderRadius: theme.spacing(1),
			backgroundColor: mainTheme.palette.common.white,
			display: 'flex',
			maxWidth: '256px',
		},
	})
);
