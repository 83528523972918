import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_FILTER_SEMINARS, SUCCESS_FILTER_SEMINARS, ERROR_FILTER_SEMINARS,
    START_STORE_SEMINAR, SUCCESS_STORE_SEMINAR, ERROR_STORE_SEMINAR,
    START_UPDATE_SEMINAR, SUCCESS_UPDATE_SEMINAR, ERROR_UPDATE_SEMINAR,
    START_SHOW_SEMINAR, SUCCESS_SHOW_SEMINAR, ERROR_SHOW_SEMINAR,
    START_DELETE_SEMINAR, SUCCESS_DELETE_SEMINAR, ERROR_DELETE_SEMINAR,
    START_ASSIGN_HIGHLIGHT_SEMINAR, SUCCESS_ASSIGN_HIGHLIGHT_SEMINAR, ERROR_ASSIGN_HIGHLIGHT_SEMINAR,
    START_TOGGLE_FORM_SEMINAR, SUCCESS_TOGGLE_FORM_SEMINAR,
    START_SET_VIEW_SEMINARLESSON
} from '../types';
import { apiCall } from '../config/axios';

function* getSeminars({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/seminars/filter', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_FILTER_SEMINARS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_FILTER_SEMINARS, payload: error.message })
    }
}

function* storeSeminar({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/seminars/store', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_STORE_SEMINAR, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_STORE_SEMINAR, payload: error.message })
    }
}

function* updateSeminar({ payload }) {
    try {
        const response = yield call(apiCall, 'put', `/seminars/update/${payload.id}`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_UPDATE_SEMINAR, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_UPDATE_SEMINAR, payload: error.message })
    }
}

function* showSeminar({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/seminars/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: START_SET_VIEW_SEMINARLESSON, payload: null })
        yield put({ type: SUCCESS_SHOW_SEMINAR, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_SEMINAR, payload: error.message })
    }
}

function* deleteSeminar({ payload }) {
    try {
        const response = yield call(apiCall, 'delete', `/seminars/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_SEMINAR, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_SEMINAR, payload: error.message })
    }
}

function* assignHighlight({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/seminars/assignhighlight`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_HIGHLIGHT_SEMINAR, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_HIGHLIGHT_SEMINAR, payload: error.message })
    }
}

function* toggleFormSeminar({ payload }) {
    yield put({ type: SUCCESS_TOGGLE_FORM_SEMINAR, payload })
}

export default function* seminars() {
    yield takeLatest(START_FILTER_SEMINARS, getSeminars);
    yield takeLatest(START_STORE_SEMINAR, storeSeminar);
    yield takeLatest(START_UPDATE_SEMINAR, updateSeminar);
    yield takeLatest(START_SHOW_SEMINAR, showSeminar);
    yield takeLatest(START_DELETE_SEMINAR, deleteSeminar);
    yield takeLatest(START_ASSIGN_HIGHLIGHT_SEMINAR, assignHighlight);
    yield takeLatest(START_TOGGLE_FORM_SEMINAR, toggleFormSeminar);
}