import {
    IUserdisplaytimeState, 
    START_STORE_DISPLAYTIME, SUCCESS_STORE_DISPLAYTIME, ERROR_STORE_DISPLAYTIME,
    START_UPDATE_DISPLAYTIME, SUCCESS_UPDATE_DISPLAYTIME, ERROR_UPDATE_DISPLAYTIME,
} from '../types';
    
const initialState: IUserdisplaytimeState = {
    displaytimeSelected: null,
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_STORE_DISPLAYTIME:
        case START_UPDATE_DISPLAYTIME:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_STORE_DISPLAYTIME:
        return{
            ...state,
            displaytimeSelected: action.payload.userdisplaytime,
            msg: null,
            error:false,
            loading: false
        }
        case SUCCESS_UPDATE_DISPLAYTIME:
        return{
            ...state,
            displaytimeSelected: null,
            msg: null,
            error:false,
            loading: false
        }
        case ERROR_STORE_DISPLAYTIME:
        case ERROR_UPDATE_DISPLAYTIME:
        return{
            ...state,
            tags: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}