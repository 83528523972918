import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_FILTER_USERS, SUCCESS_FILTER_USERS, ERROR_FILTER_USERS,
    START_LINK_USERS, SUCCESS_LINK_USERS, ERROR_LINK_USERS,
    START_STORE_USER, SUCCESS_STORE_USER, ERROR_STORE_USER,
    START_UPDATE_USER, SUCCESS_UPDATE_USER, ERROR_UPDATE_USER,
    START_SHOW_DEPENDENTS, SUCCESS_SHOW_DEPENDENTS, ERROR_SHOW_DEPENDENTS,
    START_GET_NOT_DEPENDENTS, SUCCESS_GET_NOT_DEPENDENTS, ERROR_GET_NOT_DEPENDENTS,
    START_SHOW_USER, SUCCESS_SHOW_USER, ERROR_SHOW_USER,
    START_SHOW_PROFILE_USER, SUCCESS_SHOW_PROFILE_USER, ERROR_SHOW_PROFILE_USER,
    START_TRAININGRESPONSIBLE_USERS, SUCCESS_TRAININGRESPONSIBLE_USERS, ERROR_TRAININGRESPONSIBLE_USERS,
    START_DELETE_USER, SUCCESS_DELETE_USER, ERROR_DELETE_USER,
    START_RESTORE_USER, SUCCESS_RESTORE_USER, ERROR_RESTORE_USER,
    START_ASSIGN_BRAND, SUCCESS_ASSIGN_BRAND, ERROR_ASSIGN_BRAND,
    START_ASSIGN_VIP_CONTENT, SUCCESS_ASSIGN_VIP_CONTENT, ERROR_ASSIGN_VIP_CONTENT,
    START_SHOW_VIP_CONTENT, SUCCESS_SHOW_VIP_CONTENT, ERROR_SHOW_VIP_CONTENT,
    START_DISABLE_VIP_CONTENT, SUCCESS_DISABLE_VIP_CONTENT, ERROR_DISABLE_VIP_CONTENT,
    START_REQUEST_VIP_CONTENT, SUCCESS_REQUEST_VIP_CONTENT, ERROR_REQUEST_VIP_CONTENT,
    START_DENY_VIP_CONTENT, SUCCESS_DENY_VIP_CONTENT, ERROR_DENY_VIP_CONTENT,
    START_ENABLE_USER, SUCCESS_ENABLE_USER, ERROR_ENABLE_USER,
    START_DISABLE_USER, SUCCESS_DISABLE_USER, ERROR_DISABLE_USER,
    START_ENABLE_DISABLE_MENU_USER, SUCCESS_ENABLE_DISABLE_MENU_USER, ERROR_ENABLE_DISABLE_MENU_USER,
    START_TOGGLE_FORM_USERS, SUCCESS_TOGGLE_FORM_USERS,
    START_TOGGLE_FORM_LINK_USERS, SUCCESS_TOGGLE_FORM_LINK_USERS,
    START_TOGGLE_PROFILE_USER, SUCCESS_TOGGLE_PROFILE_USER,
    START_REGISTER_USER, SUCCESS_REGISTER_USER, ERROR_REGISTER_USER,
    START_VERIFY_COMMERCIALCODE, SUCCESS_VERIFY_COMMERCIALCODE, ERROR_VERIFY_COMMERCIALCODE,
    START_VERIFY_STATUS_USER, SUCCESS_VERIFY_STATUS_USER, ERROR_VERIFY_STATUS_USER,
    START_SETTING_USER_HIERARCHY, SUCCESS_SETTING_USER_HIERARCHY, ERROR_SETTING_USER_HIERARCHY,
    START_GET_MASSIVELY_ASSIGN, SUCCESS_GET_MASSIVELY_ASSIGN, ERROR_GET_MASSIVELY_ASSIGN,
    START_SET_MASSIVELY_ASSIGN, SUCCESS_SET_MASSIVELY_ASSIGN, ERROR_SET_MASSIVELY_ASSIGN,
    START_USER_CHANGE_PASSWORD, SUCCESS_USER_CHANGE_PASSWORD, ERROR_USER_CHANGE_PASSWORD,
    START_LOGOUT_USER,
    START_USER_REGISTRATIONS,
    SUCCESS_USER_REGISTRATIONS,
    ERROR_USER_REGISTRATIONS
} from '../types';
import { apiCall } from '../config/axios';

function* filterUsers({ payload }) {
    try{
        const response = yield call(apiCall, 'post', `/users/filter`, {filters: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_FILTER_USERS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_FILTER_USERS, payload: error.message})
    }
}

function* linkUsers({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/users/link', {users: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_LINK_USERS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_LINK_USERS, payload: error.message})
    }
}

function* storeUser({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/users/store', {user: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_STORE_USER, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_STORE_USER, payload: error.message})
    }
}

function* updateUser({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/users/update', {user: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_UPDATE_USER, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_UPDATE_USER, payload: error.message})
    }
}

function* showUser({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/users/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_SHOW_USER, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_SHOW_USER, payload: error.message})
    }
}

function* showDependents({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/users/dependents/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_SHOW_DEPENDENTS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_SHOW_DEPENDENTS, payload: error.message})
    }
}

function* getNotDependents({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/users/notdependents/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_NOT_DEPENDENTS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_NOT_DEPENDENTS, payload: error.message})
    }
}

function* showProfileUser({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/users/showprofile/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_PROFILE_USER, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_SHOW_PROFILE_USER, payload: error.message})
    }
}

function* showTrainingResponsible() {
    try {
        const response = yield call(apiCall, 'get', `/users/trainingresponsible`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_TRAININGRESPONSIBLE_USERS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_TRAININGRESPONSIBLE_USERS, payload: error.message })
    }
}

function* deleteUser({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/users/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_DELETE_USER, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_DELETE_USER, payload: error.message})
    }
}

function* restoreUser({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/users/restore/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_RESTORE_USER, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_RESTORE_USER, payload: error.message})
    }
}

function* assingBrandOrLine({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/assignbrandorline', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_BRAND, payload: error.message })
    }
}

function* assingVipContent({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/assignvipcontent', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_VIP_CONTENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_VIP_CONTENT, payload: error.message })
    }
}

function* showVipContent({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/showvipcontent', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_VIP_CONTENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_VIP_CONTENT, payload: error.message })
    }
}

function* disableVipContent({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/disablevipcontent', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DISABLE_VIP_CONTENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DISABLE_VIP_CONTENT, payload: error.message })
    }
}

function* requestVipContent({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/requestvipcontent', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_REQUEST_VIP_CONTENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_REQUEST_VIP_CONTENT, payload: error.message })
    }
}

function* denyVipContent({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/denyvipcontent', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DENY_VIP_CONTENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DENY_VIP_CONTENT, payload: error.message })
    }
}

function* enableUser({payload}) {
    try {
        const response = yield call(apiCall, 'post', `/users/enable/${payload}`, null)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ENABLE_USER, payload: response.data })
        yield put({ type: START_SHOW_PROFILE_USER, payload })
    } catch (error) {
        yield put({ type: ERROR_ENABLE_USER, payload: error.message })
    }
}

function* disableUser() {
    try {
        const response = yield call(apiCall, 'post', '/users/disable', null)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DISABLE_USER, payload: response.data })
        yield put({ type: START_LOGOUT_USER })
    } catch (error) {
        yield put({ type: ERROR_DISABLE_USER, payload: error.message })
    }
}

function* enableDisableMenu({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/menus/enablemenu', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ENABLE_DISABLE_MENU_USER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ENABLE_DISABLE_MENU_USER, payload: error.message })
    }
}

function* toggleFormUsers() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_USERS})
}

function* toggleFormLinkUsers() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_LINK_USERS})
}

function* toggleProfileUser() {
    yield put({ type: SUCCESS_TOGGLE_PROFILE_USER})
}

function* registerUser({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/register', {user: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_REGISTER_USER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_REGISTER_USER, payload: error.message })
    }
}

function* verifyCommercialcode({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/users/verifycommercialcode/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_VERIFY_COMMERCIALCODE, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_VERIFY_COMMERCIALCODE, payload: error.message})
    }
}

function* verifyUser({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/verification', {user: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_VERIFY_STATUS_USER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_VERIFY_STATUS_USER, payload: error.message })
    }
}

function* settinguserHierarchy({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/settinguserhierarchy', {hierarchy: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SETTING_USER_HIERARCHY, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SETTING_USER_HIERARCHY, payload: error.message })
    }
}

function* getMassivelyAssignData({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/massivelyassigndata', {users: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_MASSIVELY_ASSIGN, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_MASSIVELY_ASSIGN, payload: error.message })
    }
}

function* setMassivelyAssignData({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/massivelyassign', { massivelyassign: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SET_MASSIVELY_ASSIGN, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SET_MASSIVELY_ASSIGN, payload: error.message })
    }
}

function* changeUserPassword({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/changepassword', {changePassword: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_USER_CHANGE_PASSWORD, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_USER_CHANGE_PASSWORD, payload: error.message })
    }
}

function* changeUserRegistrations({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/users/userregistrations', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_USER_REGISTRATIONS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_USER_REGISTRATIONS, payload: error.message })
    }
}

export default function* users() {
    yield takeLatest(START_FILTER_USERS, filterUsers);
    yield takeLatest(START_LINK_USERS, linkUsers);
    yield takeLatest(START_STORE_USER, storeUser);
    yield takeLatest(START_UPDATE_USER, updateUser);
    yield takeLatest(START_SHOW_USER, showUser);
    yield takeLatest(START_SHOW_DEPENDENTS, showDependents);
    yield takeLatest(START_GET_NOT_DEPENDENTS, getNotDependents);
    yield takeLatest(START_SHOW_PROFILE_USER, showProfileUser);
    yield takeLatest(START_TRAININGRESPONSIBLE_USERS, showTrainingResponsible);
    yield takeLatest(START_DELETE_USER, deleteUser);
    yield takeLatest(START_RESTORE_USER, restoreUser);
    yield takeLatest(START_ASSIGN_BRAND, assingBrandOrLine);
    yield takeLatest(START_ASSIGN_VIP_CONTENT, assingVipContent);
    yield takeLatest(START_SHOW_VIP_CONTENT, showVipContent);
    yield takeLatest(START_DISABLE_VIP_CONTENT, disableVipContent);
    yield takeLatest(START_REQUEST_VIP_CONTENT, requestVipContent);
    yield takeLatest(START_DENY_VIP_CONTENT, denyVipContent);
    yield takeLatest(START_ENABLE_USER, enableUser);
    yield takeLatest(START_DISABLE_USER, disableUser);
    yield takeLatest(START_ENABLE_DISABLE_MENU_USER, enableDisableMenu);
    yield takeLatest(START_TOGGLE_FORM_USERS, toggleFormUsers);
    yield takeLatest(START_TOGGLE_FORM_LINK_USERS, toggleFormLinkUsers);
    yield takeLatest(START_TOGGLE_PROFILE_USER, toggleProfileUser);
    yield takeLatest(START_REGISTER_USER, registerUser);
    yield takeLatest(START_VERIFY_COMMERCIALCODE, verifyCommercialcode);
    yield takeLatest(START_VERIFY_STATUS_USER, verifyUser);
    yield takeLatest(START_SETTING_USER_HIERARCHY, settinguserHierarchy);
    yield takeLatest(START_GET_MASSIVELY_ASSIGN, getMassivelyAssignData);
    yield takeLatest(START_SET_MASSIVELY_ASSIGN, setMassivelyAssignData);
    yield takeLatest(START_USER_CHANGE_PASSWORD, changeUserPassword);
    yield takeLatest(START_USER_REGISTRATIONS, changeUserRegistrations);
}