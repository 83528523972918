// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

// Custom Style
const BackdropCustom = withStyles({
    root: {
        zIndex: 9999
    },
})(Backdrop);

interface ScreenLoadingProps {
    loadings: boolean[]
}

export default function ScreenLoading({ loadings }: ScreenLoadingProps) {
    return (
        <>
            {loadings.some((loading) => loading) &&
                <BackdropCustom open={true} className="root" >
                    <CircularProgress color="inherit" />
                </BackdropCustom>
            }
        </>
    );
}
