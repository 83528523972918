import { put, call, takeLatest } from 'redux-saga/effects';
import { START_GET_ROLES, SUCCESS_GET_ROLES, ERROR_GET_ROLES } from '../types';
import { apiCall } from '../config/axios';

function* getRoles() {
    try{
        const response = yield call(apiCall, 'get', '/roles')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_ROLES, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_ROLES, payload: error.message})
    }
}

export default function* roles() {
    yield takeLatest(START_GET_ROLES, getRoles);
}