import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_NOTIFICATIONS, SUCCESS_GET_NOTIFICATIONS, ERROR_GET_NOTIFICATIONS,
    START_GET_UNREAD_NOTIFICATIONS, SUCCESS_GET_UNREAD_NOTIFICATIONS, ERROR_GET_UNREAD_NOTIFICATIONS,
    START_SET_NOTIFICATION_AS_READ, SUCCESS_SET_NOTIFICATION_AS_READ, ERROR_SET_NOTIFICATION_AS_READ,
    START_SET_ALL_NOTIFICATIONS_AS_READ, SUCCESS_SET_ALL_NOTIFICATIONS_AS_READ, ERROR_SET_ALL_NOTIFICATIONS_AS_READ
} from '../types';
import { apiCall } from '../config/axios';

function* getNotifications() {
    try{
        const response = yield call(apiCall, 'get', '/notifications')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_NOTIFICATIONS, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_GET_NOTIFICATIONS, payload: error.message})
    }
}

function* getUnreadNotifications() {
    try{
        const response = yield call(apiCall, 'get', '/notifications/unreadnotifications')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_UNREAD_NOTIFICATIONS, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_GET_UNREAD_NOTIFICATIONS, payload: error.message})
    }
}

function* setNotificationAsRead({ payload }) {
    try{
        const response = yield call(apiCall, 'post', `/notifications/readnotification/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SET_NOTIFICATION_AS_READ, payload: response.data })
        if (response.data.notification.data.path !== undefined && response.data.notification.data.path !== null)
            window.location.href = `${response.data.notification.data.path}`;
    } catch (error){
        yield put({ type: ERROR_SET_NOTIFICATION_AS_READ, payload: error.message})
    }
}

function* setAllNotificationsAsRead() {
    try{
        const response = yield call(apiCall, 'post', '/notifications/readallnotifications')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SET_ALL_NOTIFICATIONS_AS_READ, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_SET_ALL_NOTIFICATIONS_AS_READ, payload: error.message})
    }
}

export default function* notifications() {
    yield takeLatest(START_GET_NOTIFICATIONS, getNotifications);
    yield takeLatest(START_GET_UNREAD_NOTIFICATIONS, getUnreadNotifications);
    yield takeLatest(START_SET_NOTIFICATION_AS_READ, setNotificationAsRead);
    yield takeLatest(START_SET_ALL_NOTIFICATIONS_AS_READ, setAllNotificationsAsRead);
}