import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";


export default makeStyles((theme) =>
    createStyles({
        reviewItem:{
            display: 'flex',
            gap: 40,
            marginTop: 32,
            '& p':{
                fontWeight: 500,
            },
            [theme.breakpoints.down('xs')]:{
                flexWrap: 'wrap',
            },
        }
    })
);
