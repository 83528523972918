// React
import { useDispatch } from 'react-redux'

// MUI
import { Tooltip, Button } from '@material-ui/core';
import { FeedbackRounded, RefreshRounded, WarningRounded } from '@material-ui/icons';

// Redux Actions
import { clearStorage as actionClearStorage } from '../../../redux/actions/auth';

// Custom Style
import HeaderStyles from '../../../styles/components/common/header/HeaderStyles';
import cslx from "clsx";

// Libraries
import { useTranslation } from "react-i18next";

function ReportButton(admin: any) {

  // Redux Hooks
  const dispatch = useDispatch();

  // Styles
  const classes = HeaderStyles();

  // Translate
  const { t } = useTranslation(["global"]);

  /** Functions */
  const handleClick = () => { window.open("https://drv.es/reportar-error-en-plei/") }

  return (
    <Tooltip title={`${t("report-a-problem")}`}>
      <Button variant="text" color="secondary"
        className={cslx(classes.buttonURL, admin ? "admin" : "")}
        startIcon={<FeedbackRounded className={classes.lightIcon} style={{ fontSize: 20 }} />}
        aria-controls="share-link-menu"
        aria-haspopup="false"
        onClick={handleClick}>
        {t("reportar")}
      </Button>
    </Tooltip>
  )
}

export default ReportButton