// React
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
// Material UI
import {
  Container,
  Typography,
  Button,
  Box,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Divider,
} from "@material-ui/core";

// Custom Components
import Header from "../components/common/header/Header";
import SubHeader from "../components/common/header/Subheader";
import RankingUsers from "../components/graphs/RankigUsers";
import PopularContent from "../components/graphs/PopularContent";
import MostPopularContent from "../components/graphs/MostPopularContent";
import BestCTR from "../components/graphs/BestCTR";

// Redux Actions
import { IRootState } from "../redux/types";

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../hooks/useScreenWidth";

// Custom Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcomeText: {
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(4),
      },
    },
    buttonOption: {
      height: 110,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 292,
      },
      "&:hover": {
        border: "transparent",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
      },
    },
  })
);

function Dashboard({ history }: RouteComponentProps) {
  // Translate
  const { t } = useTranslation(["global"]);
  //Custom Hook
  const isMobile = useScreenWidth(700);
  // Redux Hooks
  const { user } = useSelector((state: IRootState) => state.auth);
  //Styles
  const classes = useStyles();

  /* Global Variables */
  let menuItems: any = [];
  if (user !== null) {
    if (user.role_id === 3) {
      // responsables
      menuItems = [
        {
          id: 1,
          name: t("admin.people.people"),
          path: "/admin/usuarios",
        },
      ];
    } else if ([8, 9].includes(user.role_id)) {
      // commercial
      menuItems = [
        {
          id: 1,
          name: t("admin.people.people"),
          path: "/admin/usuarios",
        },
      ];
    } else if ([1, 2, 5].includes(user.role_id)) {
      menuItems = [
        {
          id: 1,
          name: t("slider"),
          path: "/admin/sliders",
        },
        {
          id: 2,
          name: t("offers"),
          path: "/admin/ofertas",
        },
        {
          id: 3,
          name: t("updates"),
          path: "/admin/novedades",
        },
        {
          id: 4,
          name: t("news"),
          path: "/admin/noticias",
        },
        {
          id: 5,
          name: t("admin.library.library"),
          path: "/admin/biblioteca",
        },
        {
          id: 6,
          name: t("academy"),
          path: "/admin/cursos",
        },
        {
          id: 7,
          name: t("events"),
          path: "/admin/events",
        },
        {
          id: 8,
          name: t("seminars"),
          path: "/admin/seminarios",
        },
      ];
    }
  }

  const haveGraphs = user.role_id === 1;

  return (
    <>
      <Header title={t("common.admin")} />
      <SubHeader />
      <>
        {haveGraphs ?
          <Container maxWidth="xl" style={{ marginTop: isMobile ? '16px' : '36px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} style={{ borderRight: 'solid 1px #C6C6C6', borderBottom: 'solid 1px #C6C6C6' }}>
                <PopularContent />
              </Grid>
              <Grid item xs={12} lg={6} style={{ borderBottom: 'solid 1px #C6C6C6' }}>
                <BestCTR />
              </Grid>
              <Grid item xs={12} lg={6} style={{ borderRight: 'solid 1px #C6C6C6' }}>
                <RankingUsers />
              </Grid>
              <Grid item xs={12} lg={6} >
                <MostPopularContent />
              </Grid>
            </Grid>
          </Container>
          :
          <Container>
            <Box className={classes.welcomeText}>
              <Typography variant="h6" align="center">
                {t("client.register.welcome-to-plei", {
                  APP_NAME: process.env.REACT_APP_NAME,
                })}{" "}
                {user !== null ? user.name : ""}, {t("manage-today")}
              </Typography>
            </Box>
            <Box
              pt={4}
              display="flex"
              justifyContent="center"
              gridGap={isMobile ? 24 : 48}
              flexWrap="wrap"
            >
              {menuItems.map((item: any, index: any) => (
                <Button
                  key={item.name}
                  variant="outlined"
                  color="secondary"
                  onClick={() => history.push(`${item.path}`)}
                  classes={{ root: classes.buttonOption }}
                >
                  <Typography
                    variant={isMobile ? "subtitle1" : "body1"}
                  >
                    {item.name}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Container>
        }
      </>
    </>
  );
}

export default Dashboard;
