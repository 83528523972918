// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { Container } from '@material-ui/core';

// Custom components
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import ContentFilter from '../../../components/layouts/filters/ContentFilter';
import NoveltyPanel from '../../../components/novelties/NoveltyPanel';

// Custom Hooks
import useSkeleton from '../../../components/layouts/navigation/SkeletonLayout';

// Redux Actions
import { getClientNovelties } from '../../../redux/actions/novelties';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

// Types
interface INovelty {
    id: number,
    brand: any,
    visibility: number,
    creator: string,
    title: string,
    slug: string,
    content: string,
    image_url: string,
    created_at: string
}

function NoveltyHome() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Redux Hooks
    const dispatch = useDispatch();
    const { novelties } = useSelector((state: IRootState) => state.novelty);
    const { globalFamilyId, globalBrandId, globalCountryId, globalLanguageId, globalTag, globalRoleId } = useSelector((state: IRootState) => state.global);

    const { loading: loadingSkeleton, component: componentSkeleton } = useSkeleton();

    useEffect(() => {
        dispatch(getClientNovelties({
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            country_id: globalCountryId,
            language_id: globalLanguageId,
            tag_id: globalTag,
            role_id: globalRoleId,
            type: 'novelties',
            requesttype: 0
        }));
    }, [globalFamilyId, globalBrandId, globalCountryId, globalLanguageId, globalTag, globalRoleId]);

    if (loadingSkeleton) {
        return (
            <>
                {componentSkeleton}
            </>
        )
    }

    return (

        <>

            <Header title={t('updates')} />
            <SubHeader />
            <ContentFilter />

            <Container maxWidth='lg'>

                <NoveltyPanel
                    novelties={novelties}
                />

            </Container>
        </>
    );
}

export default NoveltyHome;