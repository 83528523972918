import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
// import locale file(s)
import 'moment/locale/es';
import * as Sentry from "@sentry/react";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

/** LAYOUTS */
import NavLayout from './components/layouts/NavLayout';
import HelpCenterLayout from './components/layouts/HelpCenterLayout';
import AdminLayout from './components/layouts/AdminLayout';
import ClientLayout from './components/layouts/ClientLayout';
import DetailLayout from './components/layouts/DetailLayout';
import ErrorBoundary from './components/layouts/ErrorBoundary';

import PrivateRoute from './components/common/PrivateRoute';
import PublicRoute from './components/common/PublicRoute';

/** MODAL */
import ModalWrapper from './components/common/admin/modalWrapper/ModalWrapper';

/** DESKTOP NOTIFICATION */
import DesktopNotification from './pages/DesktopNotification';

import './styles/main.scss';

const myFallback = <ErrorBoundary />;

function App({ store }: any) {
  moment.locale('es');
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={myFallback} showDialog>
            <Switch>
              {/* Auth */}
              <Route exact path="/" component={NavLayout} />
              <Route exact path="/limpiar" component={NavLayout} />
              <Route exact path="/iniciar-sesion" component={NavLayout} />
              <Route exact path="/registro" component={NavLayout} />
              <Route exact path="/recordar-contrasena" component={NavLayout} />
              <Route exact path="/recordar-contrasena/:token" component={NavLayout} />
              <Route exact path="/asignar-contrasena/:token" component={NavLayout} />

              {/* Profile */}
              <PrivateRoute exact path="/admin/perfil" component={AdminLayout} />
              <PrivateRoute exact path="/perfil" component={ClientLayout} />

              {/* Client */}
              <PrivateRoute exact path="/inicio" component={ClientLayout} />
              <PrivateRoute exact path="/cursos" component={ClientLayout} />
              <PublicRoute exact path="/cursos/:brandId/:slug" component={DetailLayout} />
              <PrivateRoute exact path="/biblioteca" component={ClientLayout} />
              <PublicRoute exact path="/biblioteca/:id" component={DetailLayout} />
              <PrivateRoute exact path="/biblioteca/detalle" component={ClientLayout} />
              <PrivateRoute exact path="/eventos" component={ClientLayout} />
              <PublicRoute exact path="/eventos/:id" component={DetailLayout} />
              <PrivateRoute exact path="/seminarios" component={ClientLayout} />
              <PublicRoute exact path="/seminarios/:id" component={DetailLayout} />
              <PrivateRoute exact path="/ofertas" component={ClientLayout} />
              <PublicRoute exact path="/ofertas/:id" component={DetailLayout} />
              <PrivateRoute exact path="/noticias" component={ClientLayout} />
              <PublicRoute exact path="/noticias/:slug" component={DetailLayout} />
              <PrivateRoute exact path="/novedades" component={ClientLayout} />
              <PublicRoute exact path="/novedades/:slug" component={DetailLayout} />

              {/* Categories */}
              <PrivateRoute exact path="/admin/cursos/categorias" component={AdminLayout} />

              {/* Lessons */}
              <PrivateRoute exact path="/admin/courseName/lessonName" component={AdminLayout} />

              {/* Brands */}
              <PrivateRoute exact path="/admin/marcas" component={AdminLayout} />
              <PrivateRoute exact path="/admin/marcasView" component={AdminLayout} />

              {/* Users */}
              <PrivateRoute exact path="/escritorio" component={AdminLayout} />
              <PrivateRoute exact path="/admin/usuarios" component={AdminLayout} />
              <PrivateRoute exact path="/admin/usuarios/:id" component={AdminLayout} />
              <PrivateRoute exact path="/usuarios/perfil" component={AdminLayout} />
              <PrivateRoute exact path="/admin/integraciones" component={AdminLayout} />

              {/* Courses */}
              <PrivateRoute exact path="/admin/cursos" component={AdminLayout} />
              <PrivateRoute exact path="/admin/cursos/:brandId/:slug" component={AdminLayout} />

              {/* Sliders */}
              <PrivateRoute exact path="/admin/sliders" component={AdminLayout} />
              <PrivateRoute exact path="/admin/sliders/:id" component={AdminLayout} />

              {/* Offers */}
              <PrivateRoute exact path="/admin/ofertas" component={AdminLayout} />
              <PrivateRoute exact path="/admin/ofertas/:id" component={AdminLayout} />

              {/* News */}
              <PrivateRoute exact path="/admin/noticias" component={AdminLayout} />
              <PrivateRoute exact path="/admin/noticias/:slug" component={AdminLayout} />

              {/* Updates */}
              <PrivateRoute exact path="/admin/novedades" component={AdminLayout} />
              <PrivateRoute exact path="/admin/novedades/:slug" component={AdminLayout} />

              {/* Events */}
              <PrivateRoute exact path="/admin/events" component={AdminLayout} />
              <PrivateRoute exact path="/admin/events/:id" component={AdminLayout} />

              {/* Live */}
              <PrivateRoute exact path="/admin/seminarios" component={AdminLayout} />
              <PrivateRoute exact path="/admin/seminarios/:id" component={AdminLayout} />

              {/* Terms */}
              <PrivateRoute exact path="/terms" component={ClientLayout} />

              {/* Know Buy */}
              <PrivateRoute exact path="/comercial" component={ClientLayout} />

              {/* Library */}
              <PrivateRoute exact path="/admin/biblioteca" component={AdminLayout} />
              <PrivateRoute exact path="/admin/biblioteca/:id" component={AdminLayout} />
              <PrivateRoute exact path="/admin/biblioteca/nuevo" component={AdminLayout} />
              <PrivateRoute exact path="/admin/biblioteca/crear" component={AdminLayout} />
              <PrivateRoute exact path="/admin/biblioteca/categorias" component={AdminLayout} />

              <PrivateRoute exact path="/cursos/detalle/nuevo" component={ClientLayout} />

              {/* Favorites */}
              <PrivateRoute exact path="/favoritos" component={ClientLayout} />

              {/* Help Center */}
              <PrivateRoute exact path="/ayuda" component={HelpCenterLayout} />
              <PrivateRoute exact path="/ayuda/contacto" component={HelpCenterLayout} />
              <PrivateRoute exact path="/ayuda/tutoriales/:type" component={HelpCenterLayout} />
              <PrivateRoute exact path="/ayuda/tutoriales/:type/:section/:questionid" component={HelpCenterLayout} />
              <PrivateRoute exact path="/ayuda/comoempezar" component={HelpCenterLayout} />
              <PrivateRoute exact path="/ayuda/comoempezar/info/:section/:questionid" component={HelpCenterLayout} />

              <Route path="*" component={NavLayout} />
            </Switch>

            <ModalWrapper />
            <DesktopNotification />
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
