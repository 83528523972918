// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Container, Box, Typography } from '@material-ui/core';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table';

// Redux Actions
import { IRootState } from '../../redux/types'
import { showUserRegistrations } from '../../redux/actions/users';

// Translate
import { useTranslation } from "react-i18next";
import authReducer from '../../redux/reducers/authReducer';

function UserRegistrations({ user_id }: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { inscriptions } = useSelector((state: IRootState) => state.user);

    useEffect(() => {
        if (authUser != null) {
            let userId = null;
            if (user_id != null)
                userId = user_id;
            else if (user_id == null)
                userId = authUser.id;

            dispatch(showUserRegistrations({ user_id: userId }));
        }
    }, [authUser]);


    const columns: any = [
        { title: t("type"), field: 'type' },
        { title: t("seminar"), field: 'seminar' },
        { title: t("lesson"), field: 'lesson' },
        { title: t("date"), field: 'date' }
    ];

    return (
        <Container className='noPadding'>
            <Typography variant="subtitle1" color="textSecondary">Inscripciones</Typography>
            <Box mt={2}>
                <div className="table_custom white">
                    <MaterialTable
                        localization={localization}
                        title="Seminarios"
                        columns={columns}
                        data={inscriptions}
                        options={options}
                        icons={tableIcons} />
                </div>
            </Box>
        </Container>
    );
}

export default UserRegistrations;