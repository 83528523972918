import { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import {
  Toolbar,
  AppBar,
  Grid,
  Box,
  Container,
  Link,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

// Custom Components
import ClientMobileMenu from "./ClientMobileMenu";
import BrandMobileFilter from "../../filters/BrandMobileFilter";
import LanguageFilter from "../../filters/LanguageFilter";
import AccountMenu from "../../common/AccountMenu";
import HiddenMobile from "../../../common/HiddenMobile";
import GlobalSearch from "../GlobalSearch";

// Libraries
import { useTranslation } from "react-i18next";

//Redux
import { IRootState } from "../../../../redux/types";
import { getMenus } from "../../../../redux/actions/menus";

//Custom Hook
import useScreenWidth from "../../../../hooks/useScreenWidth";

//Assets
import logo from "../../../../assets/Images/logos/logodark.svg";

// Styles
import LayoutStyles from "../../../../styles/components/layouts/MainLayout";
import { MoreHoriz } from "@material-ui/icons";
import { classNames } from "@react-pdf-viewer/core";



function ClientAppBar() {
  const layoutClasses = LayoutStyles();

  const [menuItems, setMenuItems] = useState<any>([]);
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const { userBrands } = useSelector((state: IRootState) => state.brand);
  const { menus } = useSelector((state: IRootState) => state.menu);
  const { globalLanguageId } = useSelector((state: IRootState) => state.global);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  // Translation
  const { t } = useTranslation(["global"]);
  //Custom Hook
  const isMobile = useScreenWidth(700);
  const isLaptop = useScreenWidth(1120);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (authUser !== null && authUser.me && menus.length > 0) {
      let tmpMenuItems: any = [];
      authUser.menus.map((menu: any) => {
        return tmpMenuItems.push({
          id: menu.id,
          name: t(`${menu.code}`),
          code: menu.code,
          path: `/${menu.path}`,
          list: [],
        });
      });
      setMenuItems(tmpMenuItems);
    }
  }, [menus]);

  useEffect(() => {
    if (authUser !== null && globalLanguageId !== null)
      dispatch(getMenus());
  }, [globalLanguageId, authUser]);

  return (
    <>
      <AppBar position="fixed" className={layoutClasses.appBar + " client"}>
        <Container maxWidth="xl" style={{ padding: '0px !important' }}>
          <Toolbar
            style={isMobile ? { maxHeight: 50, padding: 0 } : {}}
          >
            <Link href="/inicio" className={layoutClasses.appLogo}>
              <img
                src={String(logo)}
                alt={`Logo de ${process.env.REACT_APP_NAME}`}
                className={layoutClasses.navLogo}
              ></img>
            </Link>

            {authUser !== null && authUser.me ? (
              <>
                {window.location.pathname !== "/terms" && (
                  <HiddenMobile display="flex" height={50}>
                    <Grid container style={{ margin: 0 }}>
                      {menuItems.map((item: any, index: number) => (
                        <Grid
                          item
                          key={index}
                          style={
                            isLaptop && index > 4
                              ? { display: "none" }
                              : {}
                          }
                          className={clsx(
                            layoutClasses.clientMenu,
                            window.location.pathname.split("/")[1] ===
                              item.path.split("/")[1]
                              ? "active"
                              : ""
                          )}
                        >
                          <Link data-testid={"menu-" + item.path.replace('/', '')} component={RouterLink} to={item.path}>
                            <span>{item.name}</span>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                    <IconButton className={layoutClasses.buttonMoreOptions} aria-controls="navbar-menu" aria-haspopup="true" onClick={handleClick}>
                      <MoreHoriz color="primary" />
                    </IconButton>
                    <Menu
                      id="navbar-menu"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      keepMounted
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      onClick={handleClose}
                    >
                      {menuItems.slice(-3).map((item: any, index: number) => (
                        <MenuItem
                          onClick={handleClose}
                          key={index}
                          data-testid={"menu-" + item.path.replace('/', '')}
                          component={RouterLink}
                          className={clsx(
                            layoutClasses.optionsModalNavbar,
                            window.location.pathname.split("/")[1] ===
                              item.path.split("/")[1]
                              ? "active"
                              : ""
                          )}
                          to={item.path}
                        >
                          <Typography>{item.name}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </HiddenMobile>
                )}

                {userBrands.length !== 0 && (
                  <BrandMobileFilter theme="client" type="nav" />
                )}

                {isMobile === false && (
                  <div className={layoutClasses.grow}></div>
                )}
                <HiddenMobile>
                  <GlobalSearch />
                </HiddenMobile>
                <LanguageFilter theme="client" />
                {isMobile === true && (
                  <div className={layoutClasses.grow}></div>
                )}
                <AccountMenu theme="client" history={history} />
              </>
            ) : (
              <>
                <div className={layoutClasses.grow}></div>
                <Box display="flex" alignItems="center" gridGap={24}>
                  <Button href="/inicio" variant="text" color="primary">
                    Iniciar sesión
                  </Button>

                  <Button href="/registro" variant="contained" color="primary">
                    Registrarse
                  </Button>
                </Box>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <div className={layoutClasses.toolbar}></div>

      <ClientMobileMenu menus={menuItems} />
    </>
  );
}

export default ClientAppBar;
