// React
import { useState, useEffect } from "react";

// Material UI
import { Box, Button, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import clsx from 'clsx';

// Libraries
import { useTranslation } from "react-i18next";

//Styles
import DocumentStyles from '../../styles/components/common/DetailPanelStyle';
import '../../styles/components/common/ButtonLike.scss';

const ButtonStyles = makeStyles(theme => ({
    snackContainer: {
        textAlign: "center",
        backgroundColor: theme.palette.info.main,
    },
    snackText: {
        width: '100%',
        fontWeight: 500,
    },
    heartButton: {
        '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 0%) !important',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 32,
        },
    }

}));

type FavoriteButtonProps = {
    favoriteState: boolean,
    handleClick: (state: boolean) => void,
    type?: 'icon' | 'button'
}

function FavoriteButton({ favoriteState, handleClick, type = "icon" }: FavoriteButtonProps) {

    // Translate
    const { t } = useTranslation(["global"]);

    const classes = ButtonStyles();
    const classesDocument = DocumentStyles();

    const [state, setState] = useState<Boolean>(favoriteState);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);

    useEffect(() => {
        if (isAnimating) {
            const animationTimeout = setTimeout(() => {
                setIsAnimating(false);
            }, 800);

            return () => clearTimeout(animationTimeout);
        }
    }, [isAnimating]);

    useEffect(() => {
        setState(favoriteState);
    }, [favoriteState]);

    /** Functions */
    const onHandleClick = () => {
        if (state) {
            handleClick(false);
        } else {
            setIsAnimating(true);
            handleClick(true);
        }
        setState(!state);
    };

    return (
        <Tooltip title='Me gusta' className="heartContainer">
            <>
                {type === "icon" &&
                    <Tooltip title={state ? `${t("remove-from-favorites")}` : `${t("add-to-favorites")}`} placement="bottom">
                        <IconButton
                            className={clsx(classes.heartButton, isAnimating && 'is_animating')}
                            disableRipple={true}
                            disableTouchRipple={true}
                            disableFocusRipple={true}
                            onClick={onHandleClick}
                        >
                            <Box className={clsx('heartAbsolute', state && 'is_active', isAnimating && 'is_animating')} />
                        </IconButton>
                    </Tooltip>
                }
                {type === "button" &&
                    <Button
                        variant='outlined'
                        className={clsx(classesDocument.customButton, 'redOut', isAnimating && 'is_animating')}
                        onClick={onHandleClick}
                    >
                        <Box className={clsx('heart', state && 'is_active', isAnimating && 'is_animating')} />
                        <Typography variant='body2'>{state ? `${t("remove-from-favorites")}` : `${t("add-to-favorites")}`}</Typography>
                    </Button>
                }
            </>
        </Tooltip>
    );
}

export default FavoriteButton;
