// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, useLocation } from 'react-router-dom';

// Material UI
import { ThemeProvider, FormControl, Select, MenuItem, Box, Tooltip, Button } from '@material-ui/core';
import { TranslateRounded, HelpRounded } from '@material-ui/icons';
import LoginRegisterStyle from '../../styles/pages/LoginRegisterStyle';
import clsx from 'clsx';

// Custom Components
import LoginPage from '../../pages/auth/Login';
import ClearPage from '../../pages/Clear';
import RegisterPage from '../../pages/auth/Register';
import RememberPasswordPage from '../../pages/auth/RememberPassword';
import NotFound from '../../pages/NotFound';
import Footer from './common/Footer';
import AssignPassword from '../../pages/auth/AssignPassword';

// Redux Actions
import { getLanguages } from '../../redux/actions/languages';
import { getCurrentCommits } from '../../redux/actions/log';
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";


// Styles
import mainTheme from "../../styles/components/themes/NavLayoutTheme";
import HiddenMobile from '../common/HiddenMobile';

function NavLayout() {
    // Translation
    const { i18n } = useTranslation();
    //Location
    const location = useLocation();
    // Styles
    const classes = LoginRegisterStyle();

    //Redux Hooks
    const dispatch = useDispatch();
    const { languages } = useSelector((state: IRootState) => state.language);

    const [globalLanguage, setGlobalLanguage] = useState('1');

    // Translation
    const { t } = useTranslation(["global"]);

    // Ready Page
    useEffect(() => {
        dispatch(getLanguages());
        dispatch(getCurrentCommits());
    }, []);

    /* Functions */
    const handleGlobalLanguage = (language_id: number, language_code: string) => {
        localStorage.setItem("globalLanguageId", language_id.toString());
        localStorage.setItem("globalLanguageCode", language_code);
        setGlobalLanguage(language_id.toString());
        i18n.changeLanguage(language_code);
    }

    let isWhite = location.pathname.includes('/iniciar-sesion') || location.pathname.includes('/registro') || location.pathname.includes('/recordar-contrasena') || location.pathname.includes('/asignar-contrasena') || location.pathname === '/'
    

    return (
        <ThemeProvider theme={mainTheme}>
            
            <Box className={classes.blockLanguage} display='flex'>
            {process.env.REACT_APP_NAME === 'PLEI' &&
                <HiddenMobile>
                    <Tooltip title={`${t('help')}`}>
                        <Button  classes={{ root: classes.buttonHelp }} href="https://plei.drv.es/preguntas-frecuentes/">
                            <HelpRounded color="primary"/>
                        </Button>
                    </Tooltip>
                </HiddenMobile>
            }
                <FormControl>
                    <Select
                        labelId="languages"
                        id="login-languages"
                        value={globalLanguage}
                        className={clsx(classes.inputLanguage, !isWhite && 'dark')}>
                        {languages.map((language: any) => (
                            <MenuItem key={language.id} className={classes.optionLanguage} onClick={() => handleGlobalLanguage(language.id, language.code)} value={language.id}><TranslateRounded className={classes.translateIcon}/>{language.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Switch>
                <Route exact path="/" component={LoginPage} />
                <Route exact path="/limpiar" component={ClearPage} />
                <Route exact path="/iniciar-sesion" component={LoginPage} />
                <Route exact path="/registro" component={RegisterPage} />
                <Route exact path="/recordar-contrasena" component={RememberPasswordPage} />
                <Route exact path="/recordar-contrasena/:token" component={RememberPasswordPage} />
                <Route exact path="/asignar-contrasena/:token" component={AssignPassword} />
                <Route component={NotFound} />
            </Switch>
            <Footer dark={!isWhite} />
        </ThemeProvider>
    );
}

export default NavLayout;
