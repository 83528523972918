// MATERIAL UI
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Styles
import DocumentStyles from '../../../styles/components/common/DetailPanelStyle';

// Libraries
import { useTranslation } from "react-i18next";
import { DataUsageRounded, FolderRounded, LocalOfferRounded, ScheduleRounded, TodayRounded } from '@material-ui/icons';

function DetailInfoPanel(props: any) {
    const { attributes } = props;
    // Translate
    const { t } = useTranslation(["global"]);
    // Styles
    const classes = DocumentStyles();

    const icons: any[string] = []
    icons["tag"] = <LocalOfferRounded fontSize="large" />
    icons["file"] = <FolderRounded fontSize="large" />
    icons["time"] = <ScheduleRounded fontSize="large" />
    icons["size"] = <DataUsageRounded fontSize="large" />
    icons["date"] = <TodayRounded fontSize="large" />

    const typeDetail = (type: any) => {
        return icons[type]
    }

    return (
        <Box className={clsx(classes.container, 'info')}>
            <Box width="100%">
                <Typography variant='subtitle1' className={classes.titleInfo}>{t("additional-information")}</Typography>
            </Box>
            <Box display="flex" gridGap={8} flexDirection="column" width="100%">
                {attributes.item.map((item: any) =>
                (
                    <Box display="flex" gridGap={8} flexDirection="row" width="100%" key={item.id}>
                        <Box className={clsx(classes.customText, `${item.color}`)}>{typeDetail(item.type)}</Box>
                        <Box display="flex" gridGap={4} flexDirection="column" width="100%">
                            <Typography className={clsx(classes.customText, `${item.color}`)}>{item.title}</Typography>
                            <Typography variant='body2' color='textSecondary'>{item.info}</Typography>
                        </Box>
                    </Box>
                )
                )}
            </Box>
        </Box>
    );
}

export default DetailInfoPanel;