import { put, call, takeLatest } from 'redux-saga/effects';
import { START_SEND_CONTACT_US, SUCCESS_SEND_CONTACT_US, ERROR_SEND_CONTACT_US } from '../types';
import { apiCall } from '../config/axios';

function* sendContactUs({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/help/contactus', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SEND_CONTACT_US, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SEND_CONTACT_US, payload: error.message })
    }
}

export default function* menus() {
    yield takeLatest(START_SEND_CONTACT_US, sendContactUs);
}