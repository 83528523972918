import { put, call, takeLatest } from 'redux-saga/effects';
import { START_GET_SEMINARTYPES, SUCCESS_GET_SEMINARTYPES, ERROR_GET_SEMINARTYPES } from '../types';
import { apiCall } from '../config/axios';

function* getSeminartypes({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/seminartypes/index', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_SEMINARTYPES, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_GET_SEMINARTYPES, payload: error.message})
    }
}

export default function* seminartypes() {
    yield takeLatest(START_GET_SEMINARTYPES, getSeminartypes);
}