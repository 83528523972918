import {
    ISliderState,
    START_GET_SLIDERS, SUCCESS_GET_SLIDERS, ERROR_GET_SLIDERS,
    START_GET_FILTERED_SLIDERS, SUCCESS_GET_FILTERED_SLIDERS, ERROR_GET_FILTERED_SLIDERS,
    START_GET_INDEX_SLIDERS, SUCCESS_GET_INDEX_SLIDERS, ERROR_GET_INDEX_SLIDERS,
    START_GET_INTERNAL_BANNER, SUCCESS_GET_INTERNAL_BANNER, ERROR_GET_INTERNAL_BANNER,
    START_CREATE_SLIDER, SUCCESS_CREATE_SLIDER, ERROR_CREATE_SLIDER,
    START_SHOW_SLIDER, SUCCESS_SHOW_SLIDER, ERROR_SHOW_SLIDER,
    START_DELETE_SLIDER, SUCCESS_DELETE_SLIDER, ERROR_DELETE_SLIDER,
    START_SLIDER_SEND_EMAIL, SUCCESS_SLIDER_SEND_EMAIL, ERROR_SLIDER_SEND_EMAIL,
    SUCCESS_TOGGLE_FORM_SLIDER
} from '../types';

const initialState: ISliderState = {
    reload: false,
    sliders: [],
    homeLinks: [],
    internalbanner: [],
    slider_selected: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_SLIDERS:
        case START_GET_FILTERED_SLIDERS:
        case START_GET_INDEX_SLIDERS:
        case START_GET_INTERNAL_BANNER:
        case START_CREATE_SLIDER:
        case START_SHOW_SLIDER:
        case START_DELETE_SLIDER:
        case START_SLIDER_SEND_EMAIL:
            return {
                ...state,
                error: false,
                loading: true
            }
        case SUCCESS_GET_SLIDERS:
        case SUCCESS_GET_FILTERED_SLIDERS:
            return {
                ...state,
                reload: false,
                sliders: action.payload.sliders,
                error: false,
                loading: false
            }
        case SUCCESS_GET_INDEX_SLIDERS:
            return {
                ...state,
                sliders: action.payload.sliders,
                homeLinks: action.payload.homeLinks,
                reload: false,
                error: false,
                loading: false
            }
        case SUCCESS_GET_INTERNAL_BANNER:
            return {
                ...state,
                internalbanner: action.payload.internalbanner,
                reload: false,
                error: false,
                loading: false
            }
        case SUCCESS_CREATE_SLIDER:
            return {
                ...state,
                showForm: !state.showForm,
                reload: true,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_SLIDER:
            return {
                ...state,
                slider_selected: action.payload.slider,
                reload: false,
                showForm: !state.showForm,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_DELETE_SLIDER:
            return {
                ...state,
                reload: true,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SLIDER_SEND_EMAIL:
            return {
                ...state,
                reload: false,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_SLIDER:
            return {
                ...state,
                reload: false,
                slider_selected: null,
                showForm: !state.showForm,
            }
        case ERROR_GET_SLIDERS:
        case ERROR_GET_FILTERED_SLIDERS:
        case ERROR_GET_INDEX_SLIDERS:
        case ERROR_GET_INTERNAL_BANNER:
        case ERROR_CREATE_SLIDER:
        case ERROR_SHOW_SLIDER:
        case ERROR_DELETE_SLIDER:
        case ERROR_SLIDER_SEND_EMAIL:
            return {
                ...state,
                reload: false,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}