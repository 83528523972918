import { 
    IUsersettingState,
    START_STORE_USERSETTINGS, SUCCESS_STORE_USERSETTINGS, ERROR_STORE_USERSETTINGS,
    START_KTB_VIDEO_USERSETTINGS, SUCCESS_KTB_VIDEO_USERSETTINGS, ERROR_KTB_VIDEO_USERSETTINGS,
    START_FIRST_START_VIDEO_USERSETTINGS, SUCCESS_FIRST_START_VIDEO_USERSETTINGS, ERROR_FIRST_START_VIDEO_USERSETTINGS
 } from '../types';
    
const initialState: IUsersettingState = {
    usersettings: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_STORE_USERSETTINGS:
        case START_KTB_VIDEO_USERSETTINGS:
        case START_FIRST_START_VIDEO_USERSETTINGS:
            return{
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_STORE_USERSETTINGS:
        case SUCCESS_KTB_VIDEO_USERSETTINGS:
        case SUCCESS_FIRST_START_VIDEO_USERSETTINGS:
            return{
                ...state,
                usersettings: action.payload.usersettings,
                error: false,
                loading: false,
            }
        case ERROR_STORE_USERSETTINGS:
        case ERROR_KTB_VIDEO_USERSETTINGS:
        case ERROR_FIRST_START_VIDEO_USERSETTINGS:
            return{
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}