// Material UI
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// Styles
import SkeletonStyle from '../../../../styles/components/layouts/navigation/SkeletonStyle';

//Custom Components
import HiddenMobile from '../../../common/HiddenMobile';

function CardsSkeleton() {
    // Styles
    const classes = SkeletonStyle();

    return (
        <Box className={classes.cardContainer}>
            <Grid container classes={{ root: classes.cardBody }}>
                <Grid item xs={4} md={12}>
                    <Skeleton animation='wave' variant='rect' className={classes.cardImg} height="135px" width='100%' />
                </Grid>
                <Grid item xs={8} md={12} style={{ padding: 8, paddingTop: 0 }}>
                    <Box className={classes.cardText1}>
                        <Skeleton animation='wave' width='100%' height={26} variant='text' />
                        <Skeleton animation='wave' width='100%' height={26} variant='text' />
                        <HiddenMobile>
                            <Skeleton animation='wave' width={120} height={16} variant='text' />
                        </HiddenMobile>
                    </Box>
                    <Box style={{ padding: 0, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                        <Box display='flex'>
                            <Skeleton animation='wave' variant='circle' height={30} width={30} style={{ margin: 12 }} />
                            <Skeleton animation='wave' variant='circle' height={30} width={30} style={{ margin: 12 }} />
                        </Box>
                        <HiddenMobile>

                            <Box display="flex" flexDirection='column' pr={2}>
                                <Box>
                                    <Skeleton animation='wave' width={110} height={24} variant='text' />
                                </Box>
                                <Box display="flex" alignItems="center" gridGap={2}>
                                    <Skeleton animation='wave' width={80} height={24} variant='text' />
                                </Box>
                            </Box>
                        </HiddenMobile>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CardsSkeleton;