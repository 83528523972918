import { makeStyles, createStyles, fade } from '@material-ui/core/styles';



export default makeStyles((theme) => createStyles({

    //Buton & icon Filter Slidebar
    filterClear: {
        display: 'flex',
        justifyContent: 'space-between',
        '&.client': {
            color: theme.palette.warning.main,
        },
        '&.admin': {
            color: theme.palette.common.white,
        },
    },
    buttonClearFilter: {
        borderRadius: 8,
        maxHeight: 29,
        padding: '4px 8px !important',
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 500,
        '&.client': {
            backgroundColor: theme.palette.warning.light,
        },
    },

    // Menu al families filters
    mainFamily: {
        margin: '8px 16px',
        borderRadius: 8,
        height: 104,
        width: '100%',
        padding: 16,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[700],
        '& h6': {
            fontWeight: 500,
        },
        '&.aesthetic': {
            background: 'radial-gradient(100% 190.76% at 0% 100%, #ED7E2D 0%, #D83F2D 100%)',
        },
        '&.cosmetic': {
            background: 'radial-gradient(100% 190.76% at 0% 100%, #ED7E2D 0%, #D83F2D 100%)',
        },
        '&.appliance': {
            background: 'radial-gradient(100% 190.76% at 0% 100%, #3089AB 0%, #313E82 100%)',
        },
        '&.specialized-aesthetics': {
            background: 'radial-gradient(100% 279.07% at 0% 4.03%, #2E22AC 0%, #DA499C 100%)',
        },
        '&.depilation': {
            background: 'radial-gradient(107.12% 320.04% at 100% 119.02%, #C290C5 0%, #363E7D 100%)',
        },
        '&.hands-nails-feet': {
            background: 'radial-gradient(105.34% 183.33% at 2.86% 114.79%, #E19047 1.34%, #D3CA9E 100%)',
        },
        '&.small': {
            height: 32,
        }
    },


    rootBrand: {
        '&.admin': {
            '& .MuiTreeItem-content:hover': {
                backgroundColor: theme.palette.grey[700],
            },
            '& > div svg': {
                color: theme.palette.grey[200],
                fontSize: '30px'
            },
        },
        '&.client': {
            '& .MuiTreeItem-content:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            '& > div svg': {
                color: theme.palette.grey[600],
                fontSize: '30px'
            },
        }
    },
    selectedItem: {
        '&.admin > .MuiTreeItem-content': {
            backgroundColor: theme.palette.grey[700],
            borderLeft: '4px solid',
            paddingLeft: 21,
            borderColor: theme.palette.secondary.main,
        },
        
        '&.client > .MuiTreeItem-content': {
            backgroundColor: theme.palette.grey[100],
            borderLeft: '4px solid',
            paddingLeft: 21,
            borderColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.up('md')]: {
            '&.admin > .MuiTreeItem-content': {
                paddingLeft: 5,
            },
            '&.client > .MuiTreeItem-content': {
                paddingLeft: 5,
            },
        },


    },
    rootLine: {
        height: 28,
        display: 'flex',
        '&.client:hover > .MuiTreeItem-content': {
            backgroundColor: theme.palette.grey[100],
        },
        '&': {
            '& svg': {
                color: theme.palette.secondary.main
            },
        },
        [theme.breakpoints.down('xs')]: {
            height: 32,
            '& p': {
                fontSize: '16px !important',
            },
        },
    },
    chipLine:{
        width: 5,
        height: 5,
        '&.client': {
            backgroundColor: theme.palette.primary.light
        },
        '&.admin': {
            backgroundColor: theme.palette.secondary.light
        }
    },

    selectedLine: {
        borderLeft: `4px solid ${theme.palette.secondary.main}`,
        '& > .MuiTreeItem-content': {
            marginLeft: '-4px'
        },
        '&.client': {
            backgroundColor: theme.palette.grey[100]
        },
        '&.admin': {
            backgroundColor: theme.palette.grey[700]
        }
    },

    contentBrand: {
        height: 28,
        paddingLeft: 24,
        width: 'calc(100% - 24px)',
        [theme.breakpoints.up('md')]: {
            height: 28,
            paddingLeft: 8,
            width: 'calc(100% - 8px)',
        },
        [theme.breakpoints.down('xs')]: {
            height: 48,
            '& p': {
                fontSize: '16px !important',
            },
        },
    },

    groupItem: {
        marginLeft: '0px !important',
    },

    treeLabel: {
        backgroundColor: 'transparent !important'
    },


    // Common with brand tagfilter

    borderSidenav: {
        borderBottom: '1px solid ' + theme.palette.grey[100],
        paddingTop: '8px',
        paddingBottom: '16px',
        maxWidth: 240,
        display: 'flex',
        flexWrap: 'wrap',
        '&.admin': {
            borderTop: '1px solid ' + theme.palette.grey[100],
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            width: '100%',
        },
    },

    // Menu al brand filters

    brandItemFilter: {
        '& .MuiListItem-gutters': {
            padding: '0 24px 0px 30px',
        },
        '& .MuiListItem-root:hover': {
            cursor: 'pointer',
            '& .MuiTypography-root': {
                textDecoration: 'none'
            }
        },
        '& .MuiListItem-root.active': {
            borderLeft: '4px solid',
            borderColor: theme.palette.secondary.main,
            paddingLeft: '25px'
        },
        '& .MuiTypography-root': {
            fontSize: '15px',
            fontWeight: 400,
        },
        '&.admin ': {
            ' & .MuiListItem-root:hover': {
                background: theme.palette.grey[700],
                '& .MuiTypography-root': {
                    color: theme.palette.grey[50],
                }
            },
            '& .MuiListItem-root.active': {
                background: theme.palette.grey[700],
            },
            '& .MuiTypography-root': {
                color: theme.palette.grey[300],
            },
        },

        '&.client ': {
            ' & .MuiListItem-root:hover': {
                background: theme.palette.grey[100],
                '& .MuiTypography-root': {
                    color: theme.palette.grey[700],
                }
            },
            '& .MuiListItem-root.active': {
                background: theme.palette.grey[100],
            },
            '& .MuiTypography-root': {
                color: theme.palette.grey[700],
            },
        },

    },

    /* //See more lines
    buttonSeeMore: {
        height: 28,
    },
    labelSeeMore: {
        paddingLeft: 40,
        justifyContent: 'flex-start',
        '&.client': {
            color: theme.palette.secondary.main,
            '& .MuiTypography-root': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                textDecoration: 'underline',
                background: theme.palette.grey[100],
                color: theme.palette.secondary.light,
                '& .MuiTypography-root': {
                    color: theme.palette.secondary.light,
                },
            },
            '&:active': {
                textDecoration: 'underline',
                background: theme.palette.grey[100],
                color: theme.palette.secondary.dark,
                '& .MuiTypography-root': {
                    color: theme.palette.secondary.dark,
                },
            }
        },
        '&.admin': {
            '&:hover': {
                textDecoration: 'underline',
                background: theme.palette.grey[700],
                color: theme.palette.secondary.light,
                '& .MuiTypography-root': {
                    color: theme.palette.secondary.light,
                },
            },
            '&:active': {
                background: theme.palette.grey[700],
                textDecoration: 'underline',
                color: theme.palette.secondary.dark,
                '& .MuiTypography-root': {
                    color: theme.palette.secondary.dark,
                },
            }
        },
    },

    */

}));
