
import { useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';

import { IRootState } from '../redux/types';

export default function SetQueryParams() {
    const location = useLocation();
    const { replace } = useHistory();

    //Redux Hooks
    const { globalFamilyId, globalBrandId, globalLineId, globalLanguageId, globalCountryId, globalRoleId, globalOrderById, globalTag } = useSelector((state: IRootState) => state.global);

    useEffect(() => {
        if( location.search.length === 0){
            let newParams: any = {}

            if (globalFamilyId !== null)
                newParams.globalFamilyId = globalFamilyId.toString();

            if (globalBrandId !== null)
                newParams.globalBrandId = globalBrandId.toString();

            if (globalLineId !== null)
                newParams.globalLineId = globalLineId.toString();

            if (globalLanguageId !== null)
                newParams.globalLanguageId = globalLanguageId.toString();

            if (globalCountryId !== null)
                newParams.globalCountryId = globalCountryId.toString();

            if (globalRoleId !== null)
                newParams.globalRoleId = globalRoleId.toString();

            if (globalOrderById !== null)
                newParams.globalOrderById = globalOrderById.toString();

            if (globalTag !== null)
                newParams.globalTag = globalTag.toString();

            const searchQuery = new URLSearchParams(newParams).toString();
            replace(`${location.pathname}?${searchQuery}`);
        }
    }, [location]);

    return (
        <Fragment>

        </Fragment>
    )
}