// React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';

// Redux Actions
import { IRootState } from '../redux/types';
import { setGlobalFamily, setGlobalBrand, setGlobalLine, setGlobalTag, setGlobalCountries } from '../redux/actions/global';

// Libraries
import { useTranslation } from "react-i18next";


interface IFiltersName {
    brandName: null | string, 
    lineName: null | string, 
    tagName: null | string, 
    roleName: null | string
}

// Export object with the current filters
export default function useNoResultsFound({handleClearFilters = () => {}}, subsection = null) {
    const { t } = useTranslation(["global"]);

    const { pathname, search } = useLocation();
    const { replace } = useHistory();

    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { globalCountryId, globalBrandId, globalLineId, globalRoleId, globalLanguageId, globalTag } = useSelector((state: IRootState) => state.global);
    const { roles } = useSelector((state: IRootState) => state.role);
    const { tags } = useSelector((state: IRootState) => state.tag);
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    const [noResultsMsg, setNoResultsMsg] = useState<string>('')
    const [languageName, setLanguageName ] = useState<null | string>(null)
    const [countryName, setCountryName ] = useState<null | string>(null)

    useEffect(() => {
        getFiltersDataNames()
    }, [globalBrandId, globalLineId, globalCountryId, globalLanguageId, globalTag, globalRoleId])

    const getFiltersDataNames = (): void => {
        let objBrand = userBrands.find((brand: any) => brand.id === globalBrandId);
        if (globalBrandId === null)
            objBrand = { name: t("all"), active_lines: [] };

        let lineName = null;
        if (globalLineId !== null) {
            if (globalLineId === 0)
                lineName = t('without-line')
            else
                lineName = getFilterDataName(globalLineId, objBrand.active_lines, 'linea')
        }

        const brandName = getFilterDataName(globalBrandId, userBrands, 'marca')
        const countryName = getFilterDataName(globalCountryId, authUser.countries_name, 'pais')
        const languageName = getFilterDataName(globalLanguageId, authUser.languages_name, 'idioma')
        const tagName = getFilterDataName(globalTag, tags, 'tag')
        const roleName = getFilterDataName(globalRoleId, roles, 'rol')

        const noResultsMsg = buildNoResultsMsg({brandName, lineName, tagName, roleName});
        setNoResultsMsg(noResultsMsg)
        setLanguageName(languageName)
        setCountryName(countryName)
    }

    const buildNoResultsMsg = ({brandName, lineName, tagName, roleName}: IFiltersName): string => {
        if (brandName === null && lineName === null && tagName === null && roleName === null && subsection === null)
            return ''

        let msg: string = 'Prueba cambiar';
        if (brandName !== null)
            msg += ' la marca [' + brandName + ']';

        if (lineName !== null)
            msg += ' la linea [' + lineName + ']';

        if (tagName !== null)
            msg += ' la categoria [' + tagName + ']';

        if (roleName !== null)
            msg += ' el rol [' + roleName + ']';

        if (subsection !== null)
            msg += ' el apartado [' + subsection + ']';

        return msg;
    }

    const clearFilters = (reload: number = 0) :void =>  {
        localStorage.setItem("globalFamilyId", "null");
        localStorage.setItem("globalBrandId", "null");
        localStorage.setItem("globalLineId", "null");
        localStorage.setItem("globalTag", "null");

        dispatch(setGlobalFamily(null));
        dispatch(setGlobalBrand(null));
        dispatch(setGlobalLine(null));
        dispatch(setGlobalTag(null));
        dispatch(setGlobalCountries(authUser.countries));

        const searchParams = new URLSearchParams(search);
        searchParams.set("globalFamilyId", "null");
        searchParams.set("globalBrandId", "null");
        searchParams.delete("globalLineId");
        searchParams.delete("globalTag")
        replace(`${pathname}?${searchParams}`);

        handleClearFilters();

        if (reload)
            window.location.reload()
    };  

    const getFilterDataName = (globalFilter: null | number, searchObject: any, filterName: string): null | string => {
        let filter = null;
        if (filterAndObjectNotEmpty(globalFilter, searchObject)){
            const filterFound = searchObject.find((item: any) => item.id === globalFilter);
            if (filterFound){
                filter = filterFound.name;
            }else{
                alert('No tienes permiso para acceder a este '+ filterName +', se reiniciaran todos los filtros')
                console.log(`${pathname}?${search}`)
                console.error('No tienes permiso para acceder a este '+ filterName +', se reiniciaran todos los filtros')
                clearFilters(1);
            }
        }

        return filter;
    }

    const filterAndObjectNotEmpty = (globalFilter: null | number, searchObject: any) => (
        globalFilter !== null && searchObject.length > 0
    )

    return {clearFilters, noResultsMsg, countryName, languageName};
}