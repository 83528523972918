// React
import {
    useEffect,
    useState,
    ChangeEvent,
    forwardRef,
    ReactElement,
    Ref,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    Grid,
    FormHelperText,
    Slide,
    DialogActions,
    Tab,
    Tabs,
    Box,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { CloseRounded, BackupRounded } from '@material-ui/icons/'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Custom components
import { CancelButton, SaveButton } from '../common/forms'
import TabPanel from '../common/admin/TabPanel'
import InputCountry from '../common/admin/modal/InputCountry'
import TinyRichEditor from '../common/admin/TinyRichEditor'

// Redux Actions
import { createNovelty, toggleFormNovelty } from '../../redux/actions/novelties'
import {
    getCountries,
    getFilteredCountries,
} from '../../redux/actions/countries'
import { getUserbrands } from '../../redux/actions/brands'
import { getLanguages } from '../../redux/actions/languages'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'
import { getRoles } from '../../redux/actions/roles'
import { IRootState } from '../../redux/types'

// Libraries
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

// Types
interface INovelty {
    id: null | number
    brand: null | any
    countries: number[]
    languages: number[]
    creator: string
    title: string
    content: string
    fileName: string[]
    type: number
    status: number
    role: null | any
    highlight: number
    visibility: number
}

type FormElement = ChangeEvent<HTMLInputElement>

function NewsModal() {
    // Translate
    const { t } = useTranslation(['global'])
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const formClasses = FormsStyles()

    /* Global Variables */
    const status = [
        { id: 0, name: t('active') },
        { id: 1, name: t('inactive') },
        { id: 2, name: t('draft') },
    ]
    const visibility = [
        { id: 0, name: t('private') },
        { id: 1, name: 'VIP' },
        { id: 2, name: t('public') },
    ]

    //Redux Hooks
    const dispatch = useDispatch()
    const { newsSelected, showForm } = useSelector(
        (state: IRootState) => state.novelty
    )
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { userBrands } = useSelector((state: IRootState) => state.brand)
    const { languages } = useSelector((state: IRootState) => state.language)
    const { roles } = useSelector((state: IRootState) => state.role)
    const { isValidForm, errorForm, errorTab, msgForm } = useSelector(
        (state: IRootState) => state.validator
    )

    // React Hooks
    const [noveltyState, setNoveltyState] = useState<INovelty>({
        id: null,
        brand: null,
        countries: [],
        languages: [],
        creator: '',
        title: '',
        content: '',
        fileName: [],
        type: 2,
        status: 0,
        role: null,
        highlight: 0,
        visibility: 0,
    })

    //Tab State
    const [currentTab, setCurrentTab] = useState(0)

    // Ready Page
    useEffect(() => {
        if (roles.length == 0) {
            dispatch(getRoles())
        }
        if (languages.length == 0) {
            dispatch(getLanguages())
        }
    }, [])

    useEffect(() => {
        if (newsSelected != null) {
            setNoveltyState({
                id: newsSelected.id,
                brand: newsSelected.brand,
                countries: newsSelected.countriesIds,
                languages: newsSelected.languagesIds,
                creator: newsSelected.creator.name,
                title: newsSelected.title,
                content: newsSelected.content,
                fileName: [],
                type: newsSelected.type,
                status: newsSelected.status,
                role: newsSelected.role,
                highlight: newsSelected.highlight,
                visibility: newsSelected.visibility,
            })
        }
    }, [newsSelected])

    useEffect(() => {
        if (authUser != null) {
            if (authUser.role_id == 1) dispatch(getCountries())
            else dispatch(getFilteredCountries())
        }
    }, [authUser])

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(
                createNovelty({ ...noveltyState, typeNovelty: 'newsSelected' })
            )
            handleCloseNewsModal()
        }
    }, [isValidForm])

    /* Functions */

    const handleEditorChange = (e: any) => {
        setNoveltyState({ ...noveltyState, content: e.target.getContent() })
    }

    const handleState = (inputName: string, value: any) => {
        setNoveltyState({ ...noveltyState, [inputName]: value })
    }

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue)
    }

    //Dropzone
    const getUploadParams = ({ meta }: any) => {
        let tmpFileNames = [...noveltyState.fileName]
        tmpFileNames.push(meta.name)
        setNoveltyState({ ...noveltyState, fileName: tmpFileNames })
        return {
            url: `${process.env.REACT_APP_API_URL}/novelties/uploaddropzone/${meta.name}`,
        }
    }

    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value
        setNoveltyState({ ...noveltyState, [inputName]: value })
    }

    const handleAutoCompleteChange =
        (inputName: string) => async (event: any, value: any) => {
            setNoveltyState({ ...noveltyState, [inputName]: value })
        }

    const handleMultiAutoCompleteChange =
        (inputName: string) => (event: any, value: any) => {
            let optionsMulti = []
            if (value !== null) {
                for (let i = 0; i < value.length; i++) {
                    optionsMulti.push(value[i].id)
                }
            }
            setNoveltyState({ ...noveltyState, [inputName]: optionsMulti })
        }

    const handleNumberChange =
        (inputName: string) => (event: any, value: any) => {
            if (value != null)
                setNoveltyState({ ...noveltyState, [inputName]: value.id })
            else setNoveltyState({ ...noveltyState, [inputName]: value })
        }

    const handleCloseNewsModal = () => {
        dispatch(resetFormValidator())
        dispatch(toggleFormNovelty({ type: 'newsSelected', change: false }))
    }

    const handleSubmit = () => {
        if (noveltyState.id == null) {
            dispatch(
                formValidator({
                    countries: [noveltyState.countries, 'notempty', 'general'],
                    languages: [noveltyState.languages, 'notempty', 'general'],
                    title: [noveltyState.title, 'required|max:250|min:2'],
                    content: [noveltyState.content, 'notempty', 'general'],
                    fileName: [noveltyState.fileName, 'notempty', 'addOn'],
                    role: [noveltyState.role, 'required', 'general'],
                })
            )
        } else {
            dispatch(
                formValidator({
                    id: [noveltyState.id, 'required'],
                    countries: [noveltyState.countries, 'notempty', 'general'],
                    languages: [noveltyState.languages, 'notempty', 'general'],
                    title: [noveltyState.title, 'required|max:250|min:2'],
                    content: [noveltyState.content, 'notempty', 'general'],
                    role: [noveltyState.role, 'required', 'general'],
                })
            )
        }
    }

    return (
        <Dialog
            open={showForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            TransitionComponent={Transition}
            onClose={handleCloseNewsModal}
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title"
            disableEnforceFocus
        >
            <DialogTitle id="form-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {t('create') + ' ' + t('news')}
                    </Typography>
                    <Button
                        onClick={handleCloseNewsModal}
                        color="primary"
                        variant="text"
                    >
                        <CloseRounded />
                    </Button>
                </Grid>
            </DialogTitle>
            <DialogContent className={formClasses.bodyForm}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={2}
                >
                    <Grid item xs={12} md={8}>
                        <TextField
                            label={t('title')}
                            fullWidth
                            required
                            variant="filled"
                            margin="normal"
                            value={noveltyState.title}
                            error={errorForm.title}
                            helperText={msgForm.title}
                            onChange={handleInputChange('title')}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            id="combo-box-brands"
                            value={noveltyState.brand}
                            onChange={handleAutoCompleteChange('brand')}
                            options={userBrands}
                            getOptionLabel={(option: any) =>
                                option.id !== undefined ? option.name : ''
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('brand')}
                                    error={errorForm.brand}
                                    variant="filled"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                        <FormHelperText error={errorForm.brand}>
                            {msgForm.brand}
                        </FormHelperText>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={formClasses.tapForm}
                        onChange={handleChangeTab}
                        aria-label="disabled tabs example"
                    >
                        <Tab
                            label={t('general')}
                            {...a11yProps(0)}
                            className={
                                errorTab.general ? formClasses.errorTab : ''
                            }
                        />
                        <Tab
                            label={t('add-ons')}
                            {...a11yProps(1)}
                            {...a11yProps(1)}
                            className={
                                errorTab.addOn ? formClasses.errorTab : ''
                            }
                        />
                    </Tabs>
                </Grid>

                <TabPanel value={currentTab} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <InputCountry
                                value={noveltyState.countries}
                                handleInputChange={handleState}
                            />
                            <FormHelperText error={errorForm.status}>
                                {msgForm.status}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="combo-box-languages"
                                value={languages.filter((language: any) =>
                                    noveltyState.languages.includes(language.id)
                                )}
                                onChange={handleMultiAutoCompleteChange(
                                    'languages'
                                )}
                                options={languages}
                                multiple
                                getOptionLabel={(option: any) =>
                                    option.id !== undefined ? option.name : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('language')}
                                        error={errorForm.languages}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.languages}>
                                {msgForm.languages}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="combo-box-roles"
                                ChipProps={{ color: 'secondary' }}
                                value={noveltyState.role}
                                onChange={handleAutoCompleteChange('role')}
                                options={roles.filter(
                                    (role: any) => role.code > 3
                                )}
                                getOptionLabel={(option: any) =>
                                    option.id !== undefined ? option.name : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        error={errorForm.role}
                                        label={t('rol')}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.role}>
                                {msgForm.role}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-status"
                                disableClearable
                                value={status.find(
                                    (stt: any) => stt.id == noveltyState.status
                                )}
                                onChange={handleNumberChange('status')}
                                options={status}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('status')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.status}>
                                {msgForm.status}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-visibility"
                                disableClearable
                                value={visibility.find(
                                    (vsb: any) =>
                                        vsb.id == noveltyState.visibility
                                )}
                                onChange={handleNumberChange('visibility')}
                                options={visibility}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('visibility')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.visibility}>
                                {msgForm.visibility}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t('author')}
                                fullWidth
                                disabled
                                variant="filled"
                                margin="normal"
                                value={noveltyState.creator}
                                onChange={() => { }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TinyRichEditor
                                handleEditorChange={handleEditorChange}
                                initialContent={noveltyState.content}
                                uploadURL="/novelties/upload"
                            />
                            <FormHelperText error={errorForm.content}>
                                {msgForm.content}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                                {t('cover-image') + '*'}
                            </Typography>
                            <Dropzone
                                getUploadParams={getUploadParams}
                                // onChangeStatus={handleChangeStatus('light_logo')}
                                maxFiles={1}
                                multiple={false}
                                canCancel={false}
                                classNames={{
                                    dropzone: 'dzu-dropzone-custom',
                                    inputLabel: 'dzu-inputlabel-custom',
                                }}
                                inputContent={
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        <BackupRounded />
                                        {t('dropzone-text')}
                                    </Box>
                                }
                            />
                            <FormHelperText error={errorForm.fileName}>
                                {msgForm.fileName}
                            </FormHelperText>
                            {newsSelected !== null &&
                                newsSelected.image_url !== null &&
                                noveltyState.fileName.length === 0 && (
                                    <Box
                                        p={1}
                                        display="grid"
                                        justifyContent="center"
                                        my={2}
                                        borderRadius={8}
                                        gridArea={8}
                                        style={{ backgroundColor: '#FAFAFA' }}
                                    >
                                        <Typography variant="body2">
                                            {t('preview') + ':'}
                                        </Typography>
                                        <img
                                            src={
                                                process.env.REACT_APP_AWS_URL +
                                                '/novelties/' +
                                                newsSelected.image_url
                                            }
                                            width="100%"
                                            height="auto"
                                            alt={newsSelected.title}
                                        />
                                    </Box>
                                )}
                        </Grid>
                    </Grid>
                </TabPanel>
            </DialogContent>

            <DialogActions
                className={
                    !isValidForm && Object.keys(msgForm).length > 0
                        ? formClasses.errorDialogActions
                        : ''
                }
            >
                <CancelButton onClick={handleCloseNewsModal}>
                    {t('cancel')}
                </CancelButton>
                <SaveButton onClick={() => handleSubmit()}>
                    {t('save')}
                </SaveButton>
            </DialogActions>
        </Dialog>
    )
}

export default NewsModal
