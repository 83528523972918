// Material UI
import { styled } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';

const DatePicker = styled(DateTimePicker)(({
    theme
}) => ({
    height: '69px',
    "& label": {
        margin: '8px 10px',
    },
    "& input": {
        paddingTop: '19px',
        paddingLeft: '8px',
    },
    "& .MuiInputBase-root": {
        height: '100%',
        boxShadow: 'none',
    },
}));

export default DatePicker;