import {
    START_GET_EVENTS,
    START_GET_FILTERED_EVENTS,
    START_GET_EVENTS_BY_BRAND,
    START_GET_CALENDAR_EVENTS,
    START_CREATE_EVENT,
    START_SHOW_EVENT,
    START_DELETE_EVENT,
    START_TOGGLE_FORM_EVENT
} from '../types';

export const getEvents = () => ({
    type: START_GET_EVENTS
})

export const getFilteredEvents = payload => ({
    type: START_GET_FILTERED_EVENTS,
    payload
})

export const getEventsByBrand = payload => ({
    type: START_GET_EVENTS_BY_BRAND,
    payload
})

export const getCalendarEvents = payload => ({
    type: START_GET_CALENDAR_EVENTS,
    payload
})

export const createEvent = payload => ({
    type: START_CREATE_EVENT,
    payload
})

export const showEvent = payload => ({
    type: START_SHOW_EVENT,
    payload
})

export const deleteEvent = payload => ({
    type: START_DELETE_EVENT,
    payload
})

export const toggleFormEvent = (payload = false) => ({
    type: START_TOGGLE_FORM_EVENT,
    payload
})