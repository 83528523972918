// React
import { MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Material UI
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@material-ui/core'
import {
    CloseRounded,
    Delete,
    DoubleArrow,
    MoreVert,
} from '@material-ui/icons/'
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../../config/table'

// Custom components
import CountriesFilter from '../../../components/common/admin/CountryFilter'
import CustomChip from '../../../components/common/admin/CustomChip'
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar'
import HighlightIcon from '../../../components/common/admin/HighlightIcon'
import TagInput from '../../../components/common/admin/TagInput'
import VisibilityIcon from '../../../components/common/admin/VisibilityIcon'
import { CancelButton, DeleteButton } from '../../../components/common/forms'
import Header from '../../../components/common/header/Header'
import ScrollSubheader from '../../../components/common/header/ScrollSubHeader'
import SubHeader from '../../../components/common/header/Subheader'
import TypeFilter from '../../../components/common/header/TypeFilter'
import ScreenLoading from '../../../components/common/ScreenLoading'
import ShareIcon from '../../../components/common/ShareIcon'
import AdminContentFilter from '../../../components/layouts/filters/AdminContentFilter'
import CategoriesModal from '../../../components/library/CategoriesModal'
import CreateMediaModal from '../../../components/library/CreateMediaModal'
import MassAssignmentModal from '../../../components/common/admin/MassAssignmentModal'
import FiletypeIcon from '../../../components/library/FiletypeIcon'
import ResourceModal from '../../../components/library/ResourceModal'
import HasKnowToBuy from '../../../utils/HasKnowToBuy'
import NotFilter from '../../NotFilter'

// Redux Actions
import { getLanguages } from '../../../redux/actions/languages'
import {
    getResourcecategories,
    showResourcecategory,
    toggleFormResourcecategories,
} from '../../../redux/actions/resourcecategories'
import {
    assignHighlight,
    deleteResource,
    filterResources,
    showResource,
    toggleFormCreateResources,
    storeMassAssignment,
} from '../../../redux/actions/resources'
import { getResourcetypes } from '../../../redux/actions/resourcetypes'
import { getRoles } from '../../../redux/actions/roles'
import { getTags } from '../../../redux/actions/tags'
import { IRootState } from '../../../redux/types'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth'

// Styles
import HiddenDesktop from '../../../components/common/HiddenDesktop'
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles'
import FormsStyles from '../../../styles/components/common/forms/FormsStyles'

// Types
type IType =
    | 'string'
    | 'boolean'
    | 'numeric'
    | 'date'
    | 'datetime'
    | 'time'
    | 'currency'
const string: IType = 'string'

interface IFilter {
    resourcetype_id: null | number
    category_id: null | number
    family_id: null | number
    brand_id: null | number
    line_id: null | number
    country_id: null | number
    role_id: null | number
    countries: number[],
    filterBy: any
}

function AdminLibrary() {
    const history = useHistory()

    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const tableclass = TableScreenStyles()
    const formClasses = FormsStyles()

    //Redux Hooks
    const dispatch = useDispatch()
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const {
        globalFamilyId,
        globalBrandId,
        globalLineId,
        globalCountriesId,
        globalLanguageId,
    } = useSelector((state: IRootState) => state.global)
    const {
        resources,
        showCreateForm,
        showEditForm,
        reload,
        error,
        msg,
        loading,
    } = useSelector((state: IRootState) => state.resource)
    const {
        resourcecategories: categories,
        showForm: showFormCategories,
        error: errorCategory,
        msg: msgCategory,
        loading: loadingCategory,
    } = useSelector((state: IRootState) => state.resourcecategory)
    const {
        languages,
        error: errorLanguage,
        msg: msgLanguage,
    } = useSelector((state: IRootState) => state.language)
    const {
        resourcetypes,
        error: errorResourcetype,
        msg: msgResourcetype,
    } = useSelector((state: IRootState) => state.resourcetype)
    const {
        roles,
        error: errorRole,
        msg: msgRole,
    } = useSelector((state: IRootState) => state.role)
    const {
        tags,
        error: errorTag,
        msg: msgTag,
    } = useSelector((state: IRootState) => state.tag)

    // Table Menu State
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null)
    const [openOptions, setOpenOptions] = useState(-1)
    const [rowsSelected, setRowsSelected] = useState<any>([])

    // Filter State
    const [filters, setFilters] = useState<IFilter>({
        resourcetype_id: null,
        category_id: null,
        family_id: null,
        brand_id: null,
        line_id: null,
        country_id: null,
        role_id: null,
        countries: [],
        filterBy: []
    })

    // Variables Filter by
    const visibility = [
        { id: 0, name: t('private') },
        { id: 1, name: 'VIP' },
        { id: 2, name: t('public') },
    ]

    const optionsData = [
        { data: visibility, code: 'visibility', column: 'visibility' },
        { data: tags, code: 'categories', column: 'resource_tag.tag_id' },
    ]

    // Component States
    const [openDeleteResource, setOpenDeleteResource] = useState(false)
    const [resourceSelected, setResourceSelected] = useState(false)
    const [resourcetypeId, setResourcetypeId] = useState<number>(1)

    // Ready Page
    useEffect(() => {
        if (!roles.length) dispatch(getRoles())
        if (!languages.length) dispatch(getLanguages())
    }, [])

    useEffect(() => {
        setFilters({
            ...filters,
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            countries: globalCountriesId,
        })
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountriesId])

    useEffect(() => {
        if (globalLanguageId !== null)
            dispatch(getResourcetypes())
    }, [globalLanguageId])

    useEffect(() => {
        if (globalLanguageId !== null)
            dispatch(getTags())
    }, [globalFamilyId, globalLanguageId])

    useEffect(() => {
        if (globalCountriesId !== null && globalLanguageId !== null) {
            dispatch(
                getResourcecategories({
                    languageId: globalLanguageId,
                    countriesId: globalCountriesId,
                    familyId: globalFamilyId,
                    brandId: globalBrandId,
                })
            )
        }
    }, [globalCountriesId, globalFamilyId, globalBrandId, globalLanguageId])

    useEffect(() => {
        if (filters.countries.length > 0) dispatch(filterResources(filters))
    }, [filters])

    useEffect(() => {
        if (reload) {
            dispatch(filterResources(filters))
            setRowsSelected([])
        }
    }, [reload])

    /* Functions */
    const handleDownload = (resource_id: number) => {
        const URL = process.env.REACT_APP_API_URL + '/resources/download/'
        window.open(URL + resource_id)
    }

    const handleChangeFilter = (
        resourcetype_id: number,
        category_id: null | number
    ) => {
        setFilters({ ...filters, resourcetype_id, category_id })
    }

    const handleCloseOptions = () => {
        setAnchorOp(null)
        setOpenOptions(-1)
    }

    const handleAction = (action: string, id: number) => {
        if (action === 'add') {
            setResourcetypeId(id)
            dispatch(toggleFormResourcecategories())
        }
        else if (action === 'edit') dispatch(showResourcecategory(id))
    }

    const handleClickHighlight = (id: number, isHighlight: boolean) => {
        dispatch(assignHighlight({ id, isHighlight }))
    }

    const handleClickSetOptions = (options: any) => {
        setFilters({ ...filters, filterBy: options })
    }

    const handleClearFilters = () => {
        setFilters({ ...filters, resourcetype_id: null, category_id: null })
    }

    const myElement: HTMLElement | null =
        document.getElementById('windowLibraryAdmin')
    let midOfWidth = window.innerWidth / 2

    const columns: any = [
        {
            title: t('name'),
            field: 'title',
            render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        <Tooltip
                            placement="bottom-start"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 400 }}
                            title={rowData.title}
                        >
                            <Typography className={tableclass.nameResource}>
                                {rowData.title}
                            </Typography>
                        </Tooltip>
                        <HighlightIcon
                            id={rowData.id}
                            isHighlight={rowData.highlight ? true : false}
                            handleClick={handleClickHighlight}
                        />
                        <VisibilityIcon id={rowData.visibility} />
                    </Box>
                )
            },
        },
        {
            title: '',
            field: 'tags',
            hidden: isMobile,
            render: (rowData: any) => (
                <TagInput
                    rowId={rowData.id}
                    rowTags={rowData.tags}
                    rowBrandId={rowData.brand_id}
                    page={'resources'}
                />
            ),
        },
        {
            title: t('type'),
            field: 'filetype',
            render: (rowData: any) => (
                <>
                    {isMobile ? (
                        <FiletypeIcon id={rowData.filetype_id} />
                    ) : (
                        <CustomChip type={2} label={rowData.filetype} />
                    )}
                </>
            ),
            hidden: isMobile && globalBrandId === null,
        },
        {
            title: t('brand'),
            field: 'brand_name',
            hidden: globalBrandId !== null,
        },
        {
            title: t('language'),
            field: 'language_codes',
            hidden: isMobile,
            type: string,
        },
        {
            title: t('rol'),
            field: 'role',
            type: string,
            hidden: isMobile,
        },
        {
            title: t('upload-date'),
            field: 'date',
            hidden: isMobile,
            type: string,
        },
        {
            title: '',
            field: '',
            render: (rowData: any) => (
                <>
                    <IconButton
                        className="row-options"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget)
                            setOpenOptions(rowData.id)
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={rowData.id === openOptions}
                        onClose={handleCloseOptions}
                        onClick={handleCloseOptions}
                    >
                        <MenuItem
                            onClick={() =>
                                history.push('/admin/biblioteca/' + rowData.id)
                            }
                        >
                            {t('view')}
                        </MenuItem>
                        <MenuItem className="edit-row-option"
                            onClick={() => {
                                dispatch(showResource(rowData.id))
                            }}
                        >
                            {t('edit')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setOpenDeleteResource(!openDeleteResource)
                                setResourceSelected(rowData.id)
                            }}
                        >
                            {t('delete')}
                        </MenuItem>
                        <MenuItem onClick={() => handleDownload(rowData.id)}>
                            {t('download')}
                        </MenuItem>
                        <ShareIcon
                            visibility={rowData.visibility}
                            component="menuItem"
                            type="resource"
                            title={rowData.title}
                            description={rowData.title}
                            link={`${window.location.origin}/biblioteca/${rowData.id}${window.location.search}`}
                            multimedia={`${process.env.REACT_APP_AWS_URL}/resources/${rowData.file_name}`}
                        />
                    </Menu>
                </>
            ),
        },
    ]

    const massEditableFields = [
        'countries',
        'brand',
        'line',
        'resourcetype',
        'role',
        'languages',
        'visibility',
    ]

    const handleSubmitMassAssignment = (data: any) => {
        dispatch(storeMassAssignment(data))
    }

    return (
        <>
            {showEditForm && <ResourceModal />}
            {showCreateForm && <CreateMediaModal />}
            {showFormCategories && <CategoriesModal resourcetypeId={resourcetypeId} />}

            <Header title={t('admin.library.library')} />
            <SubHeader>
                <ScrollSubheader id="windowLibraryAdmin">
                    <TypeFilter
                        id={null}
                        type={t('all')}
                        categories={[]}
                        active={filters.resourcetype_id === null}
                        filterCategoryId={filters.category_id}
                        handleChangeFilter={handleChangeFilter}
                    />
                    {resourcetypes.map((resourcetype: any, index: number) => {
                        if (resourcetype.id === 4) {
                            return (
                                <HasKnowToBuy key={resourcetype.id}>
                                    <TypeFilter
                                        id={resourcetype.id}
                                        type={resourcetype.name}
                                        categories={[]}
                                        active={
                                            resourcetype.id ===
                                            filters.resourcetype_id
                                        }
                                        handleChangeFilter={handleChangeFilter}
                                    />
                                </HasKnowToBuy>
                            )
                        } else {
                            return (
                                <TypeFilter
                                    key={resourcetype.id}
                                    id={resourcetype.id}
                                    type={resourcetype.name}
                                    categories={categories.filter(
                                        (category: any) =>
                                            category.resourcetype_id ===
                                            resourcetype.id
                                    )}
                                    active={
                                        resourcetype.id ===
                                        filters.resourcetype_id
                                    }
                                    admin={authUser.role_id === 1}
                                    filterCategoryId={filters.category_id}
                                    handleChangeFilter={handleChangeFilter}
                                    handleAction={handleAction}
                                />
                            )
                        }
                    })}
                </ScrollSubheader>
                <HiddenDesktop>
                    <IconButton
                        className="buttonScroll"
                        onClick={() => {
                            myElement?.scrollBy(midOfWidth, 0)
                        }}
                    >
                        <DoubleArrow />
                    </IconButton>
                </HiddenDesktop>
            </SubHeader>

            <Container maxWidth="lg">
                <Box>
                    <Box
                        mt={isMobile ? 2 : 6}
                        gridGap={16}
                        display="flex"
                        flexDirection={
                            isMobile
                                ? 'column-reverse'
                                : 'column'
                        }
                    >
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h5">
                                {filters.resourcetype_id === null ||
                                    filters.category_id === null
                                    ? t('all')
                                    : categories.find(
                                        (category: any) =>
                                            category.id ===
                                            filters.category_id
                                    )?.name}
                            </Typography>
                        </Box>
                        <Box>
                            <Grid container justify="space-between" spacing={1}>
                                <Grid
                                    item
                                    xs={5}
                                    sm={6}
                                    className={tableclass.actionsResponsive}
                                >
                                    {rowsSelected.length === 0 && (
                                        <Button
                                            id="btn-create-resource"
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() =>
                                                dispatch(
                                                    toggleFormCreateResources()
                                                )
                                            }
                                        >
                                            {t('admin.library.create-resource')}
                                        </Button>
                                    )}
                                    <MassAssignmentModal
                                        rows={rowsSelected}
                                        fields={massEditableFields}
                                        handleSubmit={
                                            handleSubmitMassAssignment
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    sm={6}
                                    className={tableclass.actionsResponsiveRight}
                                >
                                    <AdminContentFilter
                                        data={optionsData}
                                        handleClick={handleClickSetOptions}
                                    />
                                    <CountriesFilter />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    {!resources.length && !loading ? (
                        <NotFilter
                            subsection={null}
                            handleClearFilters={handleClearFilters}
                        />
                    ) : (
                        <Box mt={3}>
                            <div className="table_custom">
                                <MaterialTable
                                    localization={localization}
                                    title="Usuarios Admin"
                                    columns={columns}
                                    data={resources}
                                    options={{ ...options, selection: true }}
                                    icons={tableIcons}
                                    onSelectionChange={(rows) =>
                                        setRowsSelected(
                                            rows.map((row: any) => row.id)
                                        )
                                    }
                                />
                            </div>
                        </Box>
                    )}
                </Box>
            </Container>

            <Dialog
                open={openDeleteResource}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={() => setOpenDeleteResource(!openDeleteResource)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t('delete')} {t('document')}
                        </Typography>
                        <Button
                            onClick={() =>
                                setOpenDeleteResource(!openDeleteResource)
                            }
                            color="primary"
                            variant="text"
                        >
                            <CloseRounded />
                        </Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} >
                            <Typography>{t('confirm-delete')}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CancelButton
                        onClick={() =>
                            setOpenDeleteResource(!openDeleteResource)
                        }
                    >
                        {t('cancel')}
                    </CancelButton>
                    <DeleteButton
                        autoFocus
                        id="btn-handleldelete"
                        endIcon={<Delete />}
                        onClick={() => {
                            dispatch(deleteResource(resourceSelected))
                            setOpenDeleteResource(!openDeleteResource)
                        }}
                    >
                        {t('delete')}
                    </DeleteButton>
                </DialogActions>
            </Dialog>

            <CustomSnackBar
                errors={[
                    error,
                    errorLanguage,
                    errorCategory,
                    errorRole,
                    errorResourcetype,
                    errorTag,
                ]}
                msgs={[
                    msg,
                    msgLanguage,
                    msgCategory,
                    msgRole,
                    msgResourcetype,
                    msgTag,
                ]}
            />
            <ScreenLoading loadings={[loading, loadingCategory]} />
        </>
    )
}

export default AdminLibrary
