import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import theme from '../../themes/ClientTheme';

export default makeStyles(() => createStyles({
    // ============= [[[BUTTON]]]================
    btnContainerInput:{
        padding: 0,
    },
    btnSimulationInput:{
        cursor: 'text',
        color: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[100],
        width: 200,
        height: 24,
        alignItems: 'center',
        padding: 4,
        borderRadius: 4,
        '& p':{
            marginLeft: 8,
            fontWeight: 400,
        },
        '&:Hover':{
            color: theme.palette.grey[500],
            fontWeight: 500,
            '& svg': {
                color: `${theme.palette.primary.main} !important`,
            },
        },
    },
    //=========={{{{{{{MODAL}}}}}}}=========
    //-------------- MODALBASE--------------
    containerTransparent:{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            margin: 16,
        },
    },
    containerModal:{
        padding: '0px 0px 16px',
        backgroundColor: theme.palette.common.white,
        marginTop: 8,
        borderRadius: 8,
        position: 'relative',
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '8px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        minHeight: 350,
        maxHeight: 400,
        [theme.breakpoints.up('md')]:{
            minHeight: 450,
        },
    },
    //-----------InputSearch-------
    search: {
        position: 'relative',
        backgroundColor: theme.palette.grey[100],
        marginLeft: 0,
        borderRadius: 8,
    },
    searchSmall: {
        position: 'relative',
        margin: '6px 24px',
        height: 20,
        width: 192,
        borderRadius: 4,
        '&.client': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[300],
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover, &:active, &:focus, &:focus-within': {
            color: theme.palette.grey[900],
            backgroundColor: theme.palette.grey[200],
            borderRadius: 8,
            fontWeight: 500,
            '& svg': {
                color: `${theme.palette.primary.main} !important`,
            },
        },
        '&.small': {
            padding: theme.spacing(0, 1),
            '& svg': {
                color: `${theme.palette.grey[300]} !important`,
            },
        },
    },
    closeIcon: {
        margin: 0,
        position: 'absolute',
        right: 0,
        height: '100%',
    },
    clearicon:{
        '& svg':{
            color: `${theme.palette.grey[300]} !important`,
        }
    },
    ShorcutSearch: {
        color: `${theme.palette.grey[400] } !important`,
        backgroundColor: theme.palette.grey[100],
        '& svg': {
            color: `${theme.palette.grey[400] } !important`,
        },
        '&:hover, &:active, &:focus, &:focus-within': {
            color: `${theme.palette.grey[500] } !important`,
            backgroundColor: theme.palette.grey[200],
            boxShadow: 'none',
            '& svg': {
                color: `${theme.palette.grey[500] } !important`,
            },
        },
    },
    inputRoot: {
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[50],
        borderWidth: 2,
        border: 'solid',
        borderColor: theme.palette.primary.light,
        borderRadius: 8,
        fontWeight: 500,
        '& svg': {
            color: `${theme.palette.primary.main}`,
        },
    },
    inputInput: {
        padding: theme.spacing(1.5),
        '&.small': {
            paddingLeft: 40,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 500,
        },
        '&.header': {
            paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
            transition: theme.transitions.create('width'),
        }
    },
    //-------------Hero------------------
    containerHero:{
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: theme.palette.common.white,
        zIndex: 10,
        height: 24,
        paddingTop: 8,
        '& p': {
            fontWeight: 400,
        },
        position: 'sticky',
        left: 0,
        right: 0,
        top: 0,
    },
    //----------------ListItem------------------
    containerIcon:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        height: 32,
        width: 32,
        maxWidth: 32,
        minWidth: 32,
        marginRight: 16,
    },
    containerCaption:{
        '& p, span':{
            lineHeight: '14px',
            color: theme.palette.grey[500],
        }
    },
    titleItem:{
        fontWeight: 400,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 'calc(800px - 80px)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc(100vw - 112px)',
        },
    },
    linkMoreInfo:{
        textDecoration: 'underline',
        color: theme.palette.info.dark,
        textUnderlinePosition: 'under',
        '& p':{
            fontWeight: 500,
        },
    }
}));
