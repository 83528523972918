import {
    ISeminartypeState,
    START_GET_SEMINARTYPES, SUCCESS_GET_SEMINARTYPES, ERROR_GET_SEMINARTYPES
} from '../types';
    
const initialState: ISeminartypeState = {
    seminartypes: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_SEMINARTYPES:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_SEMINARTYPES:
        return{
            ...state,
            seminartypes: action.payload.seminartypes,
            msg: action.payload.msg,
            error:false,
            loading: false
        }
        case ERROR_GET_SEMINARTYPES:
        return{
            ...state,
            seminartypes: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}