import React from 'react';
import ReactDOM from 'react-dom';
import "./config/i18n";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux/store';
import * as Sentry from "@sentry/react";
const store = configureStore();

if (process.env.NODE_ENV === 'production'){
  Sentry.init({
    dsn: "https://6f0a4f51ebe74abd9a01008173625248@o4504977750294528.ingest.sentry.io/4504987056013312",
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampler: 0.25
  });
}


ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
