// React
import { useEffect, useState, MouseEvent, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ColumnDef, TableState } from '@tanstack/react-table'

// Material UI
import { Grid, Container, Box, Button, Menu, MenuItem, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { CloseRounded, Delete, MoreVert } from "@material-ui/icons/";

//Custom components
import BrandsModal from "../../../components/brand/BrandModal";
import CustomSnackBar from "../../../components/common/admin/CustomSnackBar";
import ScreenLoading from "../../../components/common/ScreenLoading";
import Header from "../../../components/common/header/Header";
import SubHeader from "../../../components/common/header/Subheader";
import HighlightIcon from '../../../components/common/admin/HighlightIcon';
import { CancelButton, DeleteButton } from "../../../components/common/forms";
import ReactTable from '../../../components/table/ReactTable';

// Redux Actions
import { getBrands, showBrand, viewBrand, deleteBrand, toggleFormBrands, assignHighlight, orderedBrands } from '../../../redux/actions/brands';
import { getFamilies } from '../../../redux/actions/families';
import { IRootState } from "../../../redux/types";

// Libraries
import { useTranslation } from "react-i18next";

//Styles
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles';
import FormsStyles from '../../../styles/components/common/forms/FormsStyles';
import useScreenWidth from "../../../hooks/useScreenWidth";

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";
type IFamily = {
    id: number,
    name: string,
    code: string
}
type IBrands = {
    id: number,
    name: string,
    description: string,
    background: string,
    main_color: string,
    secondary_color: string,
    highlight: number,
    light_logo: string,
    dark_logo: string,
    familiesIds: number[],
    families: IFamily[],
    order: number
}
type BrandsOrder = {
    id: number,
    order: number
}

type MenuOptions = {
    open: boolean,
    id?: number
}

function Brands({ history }: RouteComponentProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const tableclass = TableScreenStyles();
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { brands, showForm, error, msg, loading } = useSelector((state: IRootState) => state.brand);

    // table states
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState<MenuOptions>({ open: false })
    const [openDeleteBrand, setOpenDeleteBrand] = useState(false);
    const [brandSelected, setBrandSelected] = useState<number>();
    const [newBrandsOrder, setNewBrandsOrder] = useState<BrandsOrder[]>();
    const [isOrderModified, setIsOrderModified] = useState<boolean>(false);

    // Ready Page
    useEffect(() => {
        dispatch(getBrands());
        dispatch(getFamilies());
    }, []);

    const handleClickHighlight = useCallback((id: number, isHighlight: boolean) => {
        dispatch(assignHighlight({
            id,
            isHighlight
        }));
    }, []);

    /* FUnctions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions({ open: false });
    };

    const getOrderedBrands = (data: IBrands[]) => {
        const orderedBrandsById = data.map((brand, index) => {
            return {
                id: brand.id,
                order: index + 1
            }
        });
        setNewBrandsOrder(orderedBrandsById);
        setIsOrderModified(true);
    }

    const tableState: Partial<TableState> = { columnVisibility: (isMobile) ? { color: false } : {} }

    const defaultColumns: ColumnDef<IBrands>[] = useMemo<ColumnDef<IBrands>[]>(() => [
        {
            header: t('name'), accessorKey: 'name', cell: rowData => (
                <Box display="flex" alignItems="center" gridGap={8}>
                    <Typography className={tableclass.nameResource}>
                        {rowData.row.original.name}
                    </Typography>
                    <HighlightIcon id={rowData.row.original.id} isHighlight={(rowData.row.original.highlight) ? true : false} handleClick={handleClickHighlight} />
                </Box>
            )
        },
        { header: t('family'), id: 'families', accessorFn: row => row.families, cell: (rowData: any) => (rowData.getValue().length > 0) ? <span>{rowData.getValue().map((family: any) => { return t(`${family.code}`) }).join(', ')}</span> : <></> },
        {
            header: t('color'), id: 'color', cell: rowData => (
                <Box
                    style={{
                        backgroundColor: `${rowData.row.original.main_color}`,
                        borderRadius: '4px',
                        height: '20px',
                        width: '48px'
                    }}>
                </Box>
            )
        },
        { header: t('description'), accessorKey: 'description' },
        {
            header: '', id: 'options', cell: rowData => (
                <>
                    <IconButton
                        id={`icon-button-${rowData.row.original.id}`}
                        aria-controls="simple-menu"
                        aria-haspopup="false"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions({ open: true, id: rowData.row.original.id });
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                </>
            )
        }
    ], [handleClickHighlight, t]);

    return (

        <>
            {showForm &&
                <BrandsModal />
            }
            <Header title={t("brands")} />
            <SubHeader />
            {authUser !== null && (
                <>
                    <Container>
                        <Box style={{ display: showForm ? "none" : "block" }} mb={6}>
                            <Container maxWidth="lg">
                                <Box mt={isMobile ? 2 : 6}>
                                    <Grid container alignItems="center" spacing={3}>
                                        <Grid item xs={12} md={2}>
                                            <Button id="btn-create-brand" variant="outlined" color="secondary"
                                                onClick={() => dispatch(toggleFormBrands())}>
                                                {t("create-brand")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography variant="h5" align="center">{t("brands")} | {t("all")}</Typography>
                                        </Grid>
                                        <Grid item xs md={2}>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={3}>
                                    <ReactTable
                                        key='brand-react-table'
                                        columns={defaultColumns}
                                        data={brands}
                                        rowId='order'
                                        state={tableState}
                                        draggableRow={true}
                                        getDataOrdered={getOrderedBrands}
                                    />
                                </Box>
                                {isOrderModified &&
                                    <Button id="btn-save-order" variant="outlined" color="secondary"
                                        onClick={() => { setIsOrderModified(false); dispatch(orderedBrands(newBrandsOrder)) }}>
                                        {t("save-order")}
                                    </Button>
                                }
                            </Container>
                        </Box>
                    </Container>

                    <Dialog
                        open={openDeleteBrand}
                        fullWidth={true}
                        fullScreen={isMobile && true}
                        maxWidth={!isMobile && "sm"}
                        className={formClasses.containerForm}
                        onClose={() => setOpenDeleteBrand(!openDeleteBrand)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            <Grid container justify="space-between">
                                <Typography variant="h6">
                                    {t("delete")} {t("brand")}
                                </Typography>
                                <Button onClick={() => setOpenDeleteBrand(!openDeleteBrand)}
                                    color="primary" variant="text"><CloseRounded /></Button>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={formClasses.bodyForm}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} >
                                    <Typography >
                                        {t("confirm-delete")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton onClick={() => setOpenDeleteBrand(!openDeleteBrand)}>
                                {t("cancel")}
                            </CancelButton>
                            <DeleteButton autoFocus id="btn-handleldelete"
                                endIcon={<Delete />}
                                onClick={() => { dispatch(deleteBrand(brandSelected)); setOpenDeleteBrand(!openDeleteBrand) }}>
                                {t("delete")}
                            </DeleteButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}


            <Menu
                id={`simple-menu-${openOptions.id}`}
                anchorEl={anchorOp}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={openOptions.open}
                onClick={handleCloseOptions}
                onClose={handleCloseOptions}
            >
                <MenuItem onClick={() => { dispatch(viewBrand(openOptions.id)); history.push('/admin/marcasView') }} >
                    {t("view")}
                </MenuItem>
                <MenuItem onClick={() => dispatch(showBrand(openOptions.id))}>
                    {t("edit")}
                </MenuItem>
                <MenuItem onClick={() => { setOpenDeleteBrand(!openDeleteBrand); setBrandSelected(openOptions.id) }}>
                    {t("delete")}
                </MenuItem>
            </Menu>

            <CustomSnackBar
                errors={[
                    error,
                ]}
                msgs={[msg]}
            />
            <ScreenLoading loadings={[loading]} />
        </>
    );
}

export default Brands;
