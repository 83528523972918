import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_BRANDS, SUCCESS_GET_BRANDS, ERROR_GET_BRANDS,
    START_GET_USER_BRANDS, SUCCESS_GET_USER_BRANDS, ERROR_GET_USER_BRANDS,
    START_SHOW_BRAND, SUCCESS_SHOW_BRAND, ERROR_SHOW_BRAND,
    START_VIEW_BRAND, SUCCESS_VIEW_BRAND, ERROR_VIEW_BRAND,
    START_STORE_BRAND, SUCCESS_STORE_BRAND, ERROR_STORE_BRAND,
    START_UPDATE_BRAND, SUCCESS_UPDATE_BRAND, ERROR_UPDATE_BRAND,
    START_DELETE_BRAND, SUCCESS_DELETE_BRAND, ERROR_DELETE_BRAND,
    START_TOGGLE_FORM_BRANDS, SUCCESS_TOGGLE_FORM_BRANDS,
    START_CHANGE_LINE_STATUS, SUCCESS_CHANGE_LINE_STATUS, ERROR_CHANGE_LINE_STATUS,
    START_DELETE_BRAND_LINE, SUCCESS_DELETE_BRAND_LINE, ERROR_DELETE_BRAND_LINE,
    START_ASSIGN_HIGHLIGHT_BRAND, SUCCESS_ASSIGN_HIGHLIGHT_BRAND, ERROR_ASSIGN_HIGHLIGHT_BRAND,
    START_ORDERED_BRAND, SUCCESS_ORDERED_BRAND, ERROR_ORDERED_BRAND,
} from '../types';
import { apiCall } from '../config/axios';

function* getBrands() {
    try {
        const response = yield call(apiCall, 'get', '/brands/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_BRANDS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_BRANDS, payload: error.message })
    }
}

function* getUserBrands() {
    try {
        const response = yield call(apiCall, 'get', '/brands/userbrands/')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_USER_BRANDS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_USER_BRANDS, payload: error.message })
    }
}

function* showBrand({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/brands/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_BRAND, payload: error.message })
    }
}

function* viewBrand({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/brands/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_VIEW_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_VIEW_BRAND, payload: error.message })
    }
}

function* storeBrand({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/brands/store', { brand: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        window.location.reload();
        yield put({ type: SUCCESS_STORE_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_STORE_BRAND, payload: error.message })
    }
}

function* updateBrand({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/brands/update', { brand: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        window.location.reload();
        yield put({ type: SUCCESS_UPDATE_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_UPDATE_BRAND, payload: error.message })
    }
}



function* deleteBrand({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/brands/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        window.location.reload();
        yield put({ type: SUCCESS_DELETE_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_BRAND, payload: error.message })
    }
}

function* toggleFormBrands() {
    yield put({ type: SUCCESS_TOGGLE_FORM_BRANDS })
}

function* changeLineStatus({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/brands/statusline/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CHANGE_LINE_STATUS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CHANGE_LINE_STATUS, payload: error.message })
    }
}

function* deleteBrandLine({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/brands/deleteline/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        window.location.reload();
        yield put({ type: SUCCESS_DELETE_BRAND_LINE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_BRAND_LINE, payload: error.message })
    }
}

function* assignHighlight({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/brands/assignhighlight`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_HIGHLIGHT_BRAND, payload: response.data })
        yield put({ type: START_GET_BRANDS })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_HIGHLIGHT_BRAND, payload: error.message })
    }
}

function* orderedBrands({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/brands/ordered`, { orderedBrandsById: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ORDERED_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ORDERED_BRAND, payload: error.message })
    }
}

export default function* brands() {
    yield takeLatest(START_GET_BRANDS, getBrands);
    yield takeLatest(START_GET_USER_BRANDS, getUserBrands);
    yield takeLatest(START_SHOW_BRAND, showBrand);
    yield takeLatest(START_VIEW_BRAND, viewBrand);
    yield takeLatest(START_STORE_BRAND, storeBrand);
    yield takeLatest(START_UPDATE_BRAND, updateBrand);
    yield takeLatest(START_DELETE_BRAND, deleteBrand);
    yield takeLatest(START_TOGGLE_FORM_BRANDS, toggleFormBrands);
    yield takeLatest(START_CHANGE_LINE_STATUS, changeLineStatus);
    yield takeLatest(START_DELETE_BRAND_LINE, deleteBrandLine);
    yield takeLatest(START_ASSIGN_HIGHLIGHT_BRAND, assignHighlight);
    yield takeLatest(START_ORDERED_BRAND, orderedBrands);
}