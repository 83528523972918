import { put, call, takeLatest } from 'redux-saga/effects';
import { 
  START_GET_REPORT_POPULAR_CONTENT, SUCCESS_GET_REPORT_POPULAR_CONTENT, ERROR_GET_REPORT_POPULAR_CONTENT,
  START_GET_REPORT_BEST_CTR, SUCCESS_GET_REPORT_BEST_CTR, ERROR_GET_REPORT_BEST_CTR,
  START_GET_REPORT_SESSION_LOGS, SUCCESS_GET_REPORT_SESSION_LOGS, ERROR_GET_REPORT_SESSION_LOGS,
  START_GET_REPORT_POPULAR_DETAIL_CONTENT, SUCCESS_GET_REPORT_POPULAR_DETAIL_CONTENT, ERROR_GET_REPORT_POPULAR_DETAIL_CONTENT,
} from '../types';
import { apiCall } from '../config/axios';

function* getPopularContent() {
  try {
    const response = yield call(apiCall, 'get', '/reports/popular_content')
    if (response.data.error)
      throw new Error(response.data.msg);

    yield put({ type: SUCCESS_GET_REPORT_POPULAR_CONTENT, payload: response.data })
  } catch (error) {
    yield put({ type: ERROR_GET_REPORT_POPULAR_CONTENT, payload: error.message })
  }
}

function* getBestCTR() {
  try {
    const response = yield call(apiCall, 'get', '/reports/bestCTR')
    if (response.data.error)
      throw new Error(response.data.msg);

    yield put({ type: SUCCESS_GET_REPORT_BEST_CTR, payload: response.data })
  } catch (error) {
    yield put({ type: ERROR_GET_REPORT_BEST_CTR, payload: error.message })
  }
}

function* getSessionlogs({ payload }) {
  try {
    const response = yield call(apiCall, 'get', `/reports/sessionlogs/${payload.timegap}/${payload.roleId}`)
    if (response.data.error)
      throw new Error(response.data.msg);

    yield put({ type: SUCCESS_GET_REPORT_SESSION_LOGS, payload: response.data })
  } catch (error) {
    yield put({ type: ERROR_GET_REPORT_SESSION_LOGS, payload: error.message })
  }
}

function* getPopularDetailContent() {
  try {
    const response = yield call(apiCall, 'get', '/reports/populardetailcontent')
    if (response.data.error)
      throw new Error(response.data.msg);

    yield put({ type: SUCCESS_GET_REPORT_POPULAR_DETAIL_CONTENT, payload: response.data })
  } catch (error) {
    yield put({ type: ERROR_GET_REPORT_POPULAR_DETAIL_CONTENT, payload: error.message })
  }
}

export default function* report() {
  yield takeLatest(START_GET_REPORT_POPULAR_CONTENT, getPopularContent);
  yield takeLatest(START_GET_REPORT_BEST_CTR, getBestCTR);
  yield takeLatest(START_GET_REPORT_SESSION_LOGS, getSessionlogs);
  yield takeLatest(START_GET_REPORT_POPULAR_DETAIL_CONTENT, getPopularDetailContent);
}