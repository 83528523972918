// Material UI
import { styled } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

const ButtonNavbar = styled(ListItem)(({
        theme
    }) => ({
        padding: '0 24px 1px 16px',
        '& + div':{
            width:'100%'
        },
        '& .MuiListItemIcon-root': {
            minWidth: '30px',
            '& .MuiSvgIcon-root': {
                color: theme.palette.grey[700],
            },
        },
        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '0.0015em',
        },
        '& .MuiTouchRipple-root':{
            display: 'none'
        },
        '&:hover': {
            '& .MuiTypography-root': {
                color: theme.palette.grey[50],
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.grey[50],
            },
        },
        '&:active, &.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: theme.palette.grey[700],
            '& .MuiTypography-root': {
                color: theme.palette.grey[50],
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.grey[50] + ' !important',
            },
        },


        '&.client': {
            '& .MuiTypography-root': {
                color: theme.palette.grey[700]
            },
            '&:active, &:hover': {
                background: theme.palette.grey[100],
                '& .MuiTypography-root': {
                    color: theme.palette.grey[700]
                },
            }
        },

        '&.admin .MuiTypography-root': {
            color: theme.palette.primary.light
        }
    }));

export default ButtonNavbar;