import {
    START_SET_GLOBAL_FAMILY,
    START_SET_GLOBAL_BRAND,
    START_SET_GLOBAL_LINE,
    START_SET_GLOBAL_LANGUAGE,
    START_SET_GLOBAL_COUNTRY,
    START_SET_GLOBAL_COUNTRIES,
    START_SET_GLOBAL_ROLE,
    START_SET_GLOBAL_ORDERBY,
    START_SET_GLOBAL_TAG,
    START_GLOBAL_SEARCH_QUERY
} from '../types';

export const setGlobalFamily = payload => ({
    type: START_SET_GLOBAL_FAMILY,
    payload
})

export const setGlobalBrand = payload => ({
    type: START_SET_GLOBAL_BRAND,
    payload
})

export const setGlobalLine = payload => ({
    type: START_SET_GLOBAL_LINE,
    payload
})

export const setGlobalLanguage = payload => ({
    type: START_SET_GLOBAL_LANGUAGE,
    payload
})

export const setGlobalCountry = payload => ({
    type: START_SET_GLOBAL_COUNTRY,
    payload
})

export const setGlobalCountries = payload => ({
    type: START_SET_GLOBAL_COUNTRIES,
    payload
})

export const setGlobalRole = payload => ({
    type: START_SET_GLOBAL_ROLE,
    payload
})

export const setGlobalOrderBy = payload => ({
    type: START_SET_GLOBAL_ORDERBY,
    payload
})

export const setGlobalTag = payload => ({
    type: START_SET_GLOBAL_TAG,
    payload
})

export const globalSearchQuery = payload => ({
    type: START_GLOBAL_SEARCH_QUERY,
    payload
})