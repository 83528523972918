import { IResourcetypeState, START_GET_RESOURCETYPES, SUCCESS_GET_RESOURCETYPES, ERROR_GET_RESOURCETYPES } from '../types';
    
const initialState: IResourcetypeState = {
    resourcetypes: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_RESOURCETYPES:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_RESOURCETYPES:
        return{
            ...state,
            resourcetypes: action.payload.resourcetypes,
            msg: action.payload.msg,
            error:false,
            loading: false
        }
        case ERROR_GET_RESOURCETYPES:
        return{
            ...state,
            resourcetypes: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}