// React
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Material UI
import { Grid, Typography, Button, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

// Redux Actions
import { showOffer } from '../../redux/actions/offers'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import { RelatedProducts } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

const recommendClient = recommend(`${process.env.REACT_APP_ALGOLIA_ID}`, `${process.env.REACT_APP_ALGOLIA_KEY}`);
const indexName = 'offers';



// Custom Style
const offerRecommendStyles = makeStyles((theme) => createStyles({
    container: {
        display: 'flex',
        gap: '16px',
        marginTop: '16px'
    }
}));

type OffersRecommendProps = {
    id: number
}

function RelatedItem({ item }: any) {

    const history = useHistory();
    //Redux Hooks
    const dispatch = useDispatch();

    /* Functions */
    const handleShowOffer = (offerId: number) => {
        dispatch(showOffer(offerId));
    }

    return (
        <Tooltip title={item.title}>
            <Button style={{ padding: 0, borderRadius: 8 }} onClick={() => { history.push(`/ofertas/${item.id}`); handleShowOffer(item.id); }} >
                <img
                    src={`${encodeURI(`${process.env.REACT_APP_AWS_URL}/offers/${item.image}`)}`}
                    alt={item.title}
                    style={{ borderRadius: '8px 8px 8px 8px', maxWidth: '100%', cursor: 'pointer' }}
                />
            </Button>
        </Tooltip>
    );
}

function ListView(props: any) {
    return (
        <>
            {props.items.map((item: any) => (
                <Grid item xs={6} md={4} key={item.objectID}>
                    <props.itemComponent item={item} />
                </Grid>
            ))}
        </>
    );
}

function OffersRecommend({ id }: OffersRecommendProps) {

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const classes = offerRecommendStyles();

    //Redux Hooks
    const { isAuth } = useSelector((state: IRootState) => state.auth);
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    let filterBrands: string = '';
    userBrands.map((brand: any, index: number) => {
        if (index > 0) {
            filterBrands += ' OR ';
        }
        filterBrands += `brand_id=${brand.id}`;
    });

    return (
        <>
            {(isAuth && userBrands.length > 0) &&
                <Grid container style={isMobile ? { marginTop: 16 } : { marginTop: 24 }}>
                    <Typography variant="subtitle1">{t('related-offers')}</Typography>
                    <RelatedProducts
                        view={ListView}
                        classNames={{ root: classes.container }}
                        maxRecommendations={3}
                        translations={{ title: '' }}
                        recommendClient={recommendClient}
                        objectIDs={[`App\\Models\\Offer::${id}`]}
                        indexName={indexName}
                        itemComponent={RelatedItem}
                        queryParameters={{ filters: filterBrands }}
                    />
                </Grid>
            }
        </>
    );
}

export default OffersRecommend
    ;