// React
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, DialogActions, Dialog, DialogTitle, DialogContent,
    Grid, TextField, Typography, FormHelperText, Tabs, Tab, Box
} from '@material-ui/core';
import { CloseRounded, BackupRounded } from '@material-ui/icons/';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Custom components
import { SaveButton, CancelButton } from '../common/forms';
import TabPanel from '../common/admin/TabPanel';

// Redux Actions
import { storeBrand, updateBrand, toggleFormBrands } from '../../redux/actions/brands';
import { formValidator, resetFormValidator } from '../../redux/actions/validator'
import { IRootState } from '../../redux/types';

// Libraries
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { useTranslation } from "react-i18next";
import InputColor from 'react-input-color';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/* Types */
interface IBrand {
    id: number,
    families: number[],
    name: null | string,
    description: null | string,
    light_logo: null | string,
    dark_logo: null | string,
    main_color: any,
    secondary_color: any,
    background: null | string,
    lines: any
}

type FormElement = ChangeEvent<HTMLInputElement>;

const filter = createFilterOptions();

function BrandsModal() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { brandSelected, showForm } = useSelector((state: IRootState) => state.brand);
    const { families } = useSelector((state: IRootState) => state.family);
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector((state: IRootState) => state.validator);

    // Brand state
    const [brand, setBrand] = useState<IBrand>({
        id: 0,
        families: [],
        name: null,
        description: null,
        light_logo: null,
        dark_logo: null,
        main_color: { hex: '#5e72e4' },
        secondary_color: { hex: '#5e72e4' },
        background: null,
        lines: []
    });

    //Tab state
    const [currentTab, setCurrentTab] = useState(0);

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator());

            if (brand.id === 0)
                dispatch(storeBrand(brand));
            else
                dispatch(updateBrand(brand));
        }
    }, [isValidForm]);

    // Assign brand state
    useEffect(() => {
        if (brandSelected !== null && brandSelected.id !== brand.id) {
            setBrand({
                id: brandSelected.id,
                families: (brandSelected.familiesIds.length > 0) ? brandSelected.familiesIds : (brandSelected.family_id !== null) ? [brandSelected.family_id] : [],
                // families: [],
                name: brandSelected.name,
                description: brandSelected.description,
                light_logo: brandSelected.light_logo,
                dark_logo: brandSelected.dark_logo,
                main_color: { hex: brandSelected.main_color },
                secondary_color: { hex: brandSelected.secondary_color },
                background: brandSelected.background,
                lines: brandSelected.lines,
            });
        }
    }, [brandSelected]);

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value;
        setBrand({ ...brand, [inputName]: value });
    }

    const getUploadParams = ({ meta }: any) => {
        return { url: `${process.env.REACT_APP_API_URL}/brands/upload/${meta.name}` };
    }

    const handleChangeStatus = (inputName: string) => ({ meta, remove }: any, status: string) => {
        if (status === 'done' || status === 'headers_received')
            setBrand({ ...brand, [inputName]: meta.name });
        else
            setBrand({ ...brand, [inputName]: null });
    }

    const handleMultiAutoCompleteChange = (inputName: string, value: any) => {
        let optionsMulti: number[] = [];

        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                optionsMulti.push(value[i].id);
            }
        }

        setBrand({ ...brand, [inputName]: optionsMulti });
    }

    const handleSubmit = () => {
        dispatch(formValidator({
            families: [brand.families, 'notempty'],
            name: [brand.name, 'required'],
            description: [brand.description, 'required|max:500|min:3', 'addOn'],
            dark_logo: [brand.dark_logo, 'required', 'general'],
            light_logo: [brand.light_logo, 'required', 'general'],
            main_color: [brand.main_color, 'required', 'general'],
            background: [brand.background, 'required', 'general']
        }));
    }

    const handleCloseModalBrand = () => {
        dispatch(resetFormValidator());
        dispatch(toggleFormBrands());
    }

    return (
        <>
            <Dialog
                open={showForm}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                className={formClasses.containerForm}
                onClose={handleCloseModalBrand}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {brandSelected !== null ? t("create") : t("edit")} {t("brand")}
                        </Typography>
                        <Button onClick={handleCloseModalBrand}
                            color="primary" variant="text"><CloseRounded />
                        </Button>
                    </Grid>
                </DialogTitle>

                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                id="brand-name"
                                label={t("name") + '*'}
                                type="text"
                                variant="filled"
                                value={brand.name ?? ''}
                                error={errorForm.name}
                                helperText={msgForm.name}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleInputChange('name')}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                id="combo-box-families"
                                value={families.filter((family: any) => brand.families.includes(family.id))}
                                onChange={(event, value) => handleMultiAutoCompleteChange('families', value)}
                                options={families}
                                multiple
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField required {...params} error={errorForm.families} label={t("family")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.families}>{msgForm.families}</FormHelperText>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Tabs
                            value={currentTab}
                            indicatorColor="secondary"
                            textColor="primary"
                            onChange={handleChangeTab}
                            className={formClasses.tapForm}
                            aria-label="disabled tabs example">

                            <Tab label={t("general")} {...a11yProps(0)} className={errorTab.general ? formClasses.errorTab : ''} />
                            <Tab label={t("add-ons")} {...a11yProps(1)} className={errorTab.addOn ? formClasses.errorTab : ''} />
                        </Tabs>
                    </Grid>

                    <TabPanel value={currentTab} index={0}>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                                {brandSelected !== null && brandSelected.light_logo !== null && brandSelected.light_logo === brand.light_logo &&
                                    <Box p={3} display="flex" justifyContent="center" my={2} borderRadius={8} style={{ backgroundColor: "#171A1C" }} >
                                        <img src={process.env.REACT_APP_AWS_URL + '/brands/' + brandSelected.light_logo}
                                            width="auto" height="77px"
                                            alt="light logo" />
                                    </Box>
                                }
                                <Typography variant="body2" color="textSecondary" >{t("light-logo") + '*'}</Typography>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus('light_logo')}
                                    maxFiles={1}
                                    multiple={false}
                                    canCancel={false}
                                    classNames={{ dropzone: 'dzu-dropzone-custom dark', inputLabel: 'dzu-inputlabel-custom' }}
                                    inputContent={
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center">
                                            <BackupRounded />
                                            {t("dropzone-text")}
                                        </Box>
                                    }
                                />
                                <FormHelperText error={errorForm.light_logo}>{msgForm.light_logo}</FormHelperText>
                            </Grid>
                            {/* Componente en uso, esta pendiente la logica para pasar los props de imageCache
                            <Grid item xs={12} sm={6}>
                                <CustomDropzone
                                    logic={brandSelected !== null && brandSelected.light_logo !== null && brandSelected.light_logo === brand.light_logo}
                                    imageCache={logica imagen que aparece en editar // process.env.REACT_APP_AWS_URL + '/brands/' + brandSelected.light_logo}
                                    label={t("light-logo") + '*'}
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus('light_logo')}
                                    multiple={true}
                                    maxFiles={10}
                                    dark={true}
                                    error={errorForm.light_logo}
                                    msg={msgForm.light_logo}
                                />
                            </Grid> */}
                            <Grid item xs={12} sm={6}>

                                {brandSelected !== null && brandSelected.dark_logo !== null && brandSelected.dark_logo === brand.dark_logo &&
                                    <Box p={3} display="flex" justifyContent="center" my={2} borderRadius={8}>
                                        <img src={process.env.REACT_APP_AWS_URL + '/brands/' + brandSelected.dark_logo}
                                            width="auto" height="77px"
                                            alt="dark_logo" />
                                    </Box>
                                }
                                <Typography variant="body2" color="textSecondary" >{t("dark-logo") + '*'}</Typography>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus('dark_logo')}
                                    maxFiles={1}
                                    multiple={false}
                                    canCancel={false}
                                    classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                    inputContent={
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center">
                                            <BackupRounded />
                                            {t("dropzone-text")}
                                        </Box>
                                    }
                                />
                                <FormHelperText error={errorForm.dark_logo}>{msgForm.dark_logo}</FormHelperText>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant="body2" color="textSecondary" >{t("main-color")}</Typography>
                                <div className="input-color">
                                    <InputColor
                                        initialValue={brand.main_color.hex}
                                        onChange={event => { setBrand({ ...brand, main_color: event }) }} />
                                </div>
                                <Typography variant="body2" color="textSecondary">{t("secondary-color")}</Typography>
                                <div className="input-color">
                                    <InputColor
                                        initialValue={brand.secondary_color.hex}
                                        onChange={event => { setBrand({ ...brand, secondary_color: event }) }}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                {brandSelected !== null && brandSelected.background !== null && brandSelected.background === brand.background &&
                                    <Box display="flex" justifyContent="center" my={2} borderRadius={8} maxHeight={168}>
                                        <img src={process.env.REACT_APP_AWS_URL + '/brands/' + brandSelected.background}
                                            width="100%" height="auto"
                                            alt="background" />
                                    </Box>
                                }

                                <Typography variant="body2" color="textSecondary" >{t("background") + '*'}</Typography>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    onChangeStatus={handleChangeStatus('background')}
                                    maxFiles={1}
                                    multiple={false}
                                    canCancel={false}
                                    classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                    inputContent={
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center">
                                            <BackupRounded />
                                            {t("dropzone-text")}
                                        </Box>}
                                />
                                <FormHelperText error={errorForm.background}>{msgForm.background}</FormHelperText>

                            </Grid>
                        </Grid>

                    </TabPanel>

                    <TabPanel value={currentTab} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    autoFocus
                                    id="brand-description"
                                    label={t("description") + '*'}
                                    multiline
                                    variant="filled"
                                    value={brand.description ?? ''}
                                    error={errorForm.description}
                                    helperText={msgForm.description}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleInputChange('description')}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    value={brand.lines}
                                    onChange={(event, value) => setBrand({ ...brand, lines: value })}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                id: 0,
                                                name: `${params.inputValue}`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    id="combo-box-lines"
                                    options={brand.lines}
                                    getOptionLabel={option => option.id !== undefined ? option.name : ''}
                                    selectOnFocus
                                    handleHomeEndKeys
                                    renderOption={(option) => option.name}
                                    multiple
                                    debug={true}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label={t("lines")}
                                            variant="filled"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                    </TabPanel>
                </DialogContent>

                <DialogActions className={!isValidForm && Object.keys(msgForm).length > 0 ? formClasses.errorDialogActions : ''}>
                    <CancelButton onClick={handleCloseModalBrand}>
                        {t("cancel")}
                    </CancelButton>
                    <SaveButton id="btn-save-brand" onClick={handleSubmit}>
                        {t("save")}
                    </SaveButton>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default BrandsModal;