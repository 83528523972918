import { RouteComponentProps } from 'react-router-dom'

// Material UI
import { Box, Container, Divider, List, ListItem, ListItemText, Typography, Grid } from '@material-ui/core';

//Custom components
import CustomBreadcrumb from '../../components/helpcenter/CustomBreadcrumb';
import useSectionStart, { SectionData } from '../../hooks/helpcenter/useSectionStart';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import HelpCenterStyles from '../../styles/components/HelpCenterStyles';


const SectionStart = ({ history }: RouteComponentProps) => {
    // Translation
    const { t } = useTranslation(["helpcenter"]);
    // Styles
    const classes = HelpCenterStyles();

    const handleInfo = (section: string, iQuestion: number) => {
        history.push('/ayuda/comoempezar/info/' + section + '/' + iQuestion);
    }

    // const handleInfo =  (index: any) =>  {
    //     history.push('/ayuda/comoempezar/info/' + index);
    // }

    const data: SectionData = useSectionStart();

    const objSections = ['about-plei', 'sections', 'basic'];

    return (
        <Container style={{ padding: 0, marginTop: 50 }} maxWidth={"xl"}>
            <CustomBreadcrumb />
            <Container maxWidth={"md"}>
                <Box mt={6} mb={1}>
                    <Typography variant="h4" className={classes.sectionTitle}>{t("how-start")}</Typography>
                    <Typography>
                        {t("welcome-plei")}
                    </Typography>
                    <br /><br />
                </Box>

                <Box display="grid" gridGap={64}>

                    <Grid container spacing={4}>
                        {data.client.filter((sections: any, index) => sections[objSections[index]] !== undefined).map((sections: any, index) => (
                            <Grid key={sections[objSections[index]].title} item xs={12} md={6}>
                                <Box pb={2} pt={2}>
                                    <Typography variant="subtitle1">{sections[objSections[index]].title}</Typography>
                                    <List component="nav">
                                        {sections[objSections[index]].content.map((section: any, index2: number) => {
                                            return (
                                                <div key={section.question}>
                                                    <ListItem button onClick={() => handleInfo(objSections[index], index2)}>
                                                        <ListItemText primary={section.question} />
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            )
                                        })}
                                    </List>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
}

export default SectionStart;