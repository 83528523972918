// React
import { useSelector } from 'react-redux'

// Material UI
import { InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

// Custom components
import {ValidateMenu} from '../../../utils/HasKnowToBuy';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


function ResourcetypeSelect( {value, handleInputChange} : any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Redux global state
    const { resourcetypes } = useSelector((state: IRootState) => state.resourcetype);
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // Handle fnuctions
    const handleChange = (event: any) => {
        const resourcetypeId: number = event.target.value;
        handleInputChange('resourcetypeId', resourcetypeId)
    };

    return (
        <>
            <InputLabel shrink={true} id="resourcetype-label">{t("type")}*</InputLabel>    
            <Select
                id="select-resourcetypes"
                value={value}
                labelId="label-resourcetypes"
                fullWidth
                label={t("type")}
                onChange={(event) => handleChange(event)}>
                {resourcetypes.map((resourcetype: any) => (
                    <MenuItem key={resourcetype.id} value={resourcetype.id}>{resourcetype.name}</MenuItem>
                ))}
            </Select>
            <FormHelperText error={errorForm.resourcetypeId}>{msgForm.resourcetypeId}</FormHelperText>
        </>
    )
}

export default ResourcetypeSelect;