// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux Actions
import { updateGeneralSummaryReport } from '../redux/actions/generalsummaryreport';
import { IRootState } from "../redux/types";

const useGeneralSummaryReport = (results: any, paginate: number, menuId: number) => {

    const dispatch = useDispatch();
    const { isAuth } = useSelector((state: IRootState) => state.auth);
    
    // handleShowGeneralSummaryReport
    useEffect(() => {
        if (results.length !== 0 && isAuth) {
            const resultsDisplayed = results.filter((result: any, index: number) => paginate > index)
            const resultsDisplayedFormatted = resultsDisplayed.map((result: any) => (
                { menu_id: menuId, foreign_id: result.id }
            ))

            dispatch(updateGeneralSummaryReport({ data: resultsDisplayedFormatted, action: 'times_displayed' }))
        }
    }, [results]);

    const handleClickGeneralSummaryReport = (foreignId: number) => {
        if (isAuth) {
            const data = [{ menu_id: menuId, foreign_id: foreignId }];
            dispatch(updateGeneralSummaryReport({ data, action: 'times_clicked' }))
        }
    }

    const handleViewGeneralSummaryReport = (foreignId: number) => {
        if (isAuth) {
            const data = [{ menu_id: menuId, foreign_id: foreignId }];
            dispatch(updateGeneralSummaryReport({ data, action: 'times_viewed' }))
        }
    }

    return { handleClickGeneralSummaryReport, handleViewGeneralSummaryReport }
}

export default useGeneralSummaryReport;