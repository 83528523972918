
// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Switch, RouteComponentProps } from 'react-router-dom';

// Material UI
import { ThemeProvider, Box, } from '@material-ui/core';
import PrivateRoute from '../../components/common/PrivateRoute';
import CustomSnackBar from '../common/admin/CustomSnackBar';

// Custom Components 
import Footer from './common/Footer';
import Sidebar from './navigation/Sidebar';
import SetQueryParams from '../../utils/SetQueryParams';
import ClientAppBar from './navigation/client/ClientAppBar';
import ClientBreadcumb from './navigation/client/ClientBreadcumb';

// Redux
import { getCurrentCommits } from '../../redux/actions/log';
import { updateDisplaytime } from '../../redux/actions/userdisplaytime';
import { getUnreadNotifications } from '../../redux/actions/notifications';
import { IRootState } from '../../redux/types';

// Pages
import Home from '../../pages/Home';
import UserProfile from '../users/UserProfile';
import Terms from '../../pages/terms/Terms';
import Courses from '../../pages/courses/client/CourseList';
import Library from '../../pages/library/client/ClientLibrary';
import EventList from '../../pages/events/client/EventList';
import OffersHome from '../../pages/offers/client/OffersHome';
import NewsPanel from '../../pages/news/client/NewsPanel';
import NoveltyHome from '../../pages/novelties/client/NoveltyHome';
import KnowBuy from '../../pages/knowbuy/KnowBuy';
import SeminarsHome from '../../pages/seminars/client/SeminarsHome';
import Favorites from '../../pages/favorites/Favorites';

// Styles
import ClientTheme from '../../styles/components/themes/ClientTheme';
import LayoutStyles from '../../styles/components/layouts/MainLayout';
import '../../styles/messagebird.scss';

function ClientLayout({ history }: RouteComponentProps) {
    const layoutClasses = LayoutStyles();

    const dispatch = useDispatch();

    const { user: authUser, error: errorAuth, msg: msgAuth } = useSelector((state: IRootState) => state.auth);
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    if (window.location.pathname !== "/biblioteca")
        localStorage.removeItem('paginate');

    useEffect(() => {
        if (localStorage.getItem("accept_terms") === "true" && authUser !== null && authUser.me && authUser.verification === 1)
            history.push("/terms");

        const userdisplaytime = localStorage.getItem('userdisplaytime');
        if (userdisplaytime) {
            const updateUserdisplaytime = JSON.parse(userdisplaytime);
            dispatch(updateDisplaytime(updateUserdisplaytime));
            localStorage.removeItem('userdisplaytime');
        }

        dispatch(getUnreadNotifications());
        dispatch(getCurrentCommits());
    }, []);

    if (localStorage.getItem('login_redirect')) {
        let tmpPath = localStorage.getItem('login_redirect');
        localStorage.removeItem('login_redirect');
        window.location.href = tmpPath || "/inicio";
    }

    return (
        <ThemeProvider theme={ClientTheme}>

            <ClientAppBar />
            <ClientBreadcumb />

            <Box data-testid="client-main-container">
                {authUser !== null && authUser.me && userBrands.length !== 0 &&
                    <Sidebar theme="client" />
                }
                <main
                    className={window.location.pathname === "/inicio" || (authUser === null) ? layoutClasses.contentHome : layoutClasses.content}
                    style={{ padding: '0px' }}>
                    <Switch>
                        {/* Home */}
                        <PrivateRoute exact path="/inicio" component={Home} />

                        {/* Profile */}
                        <PrivateRoute exact path="/perfil" component={UserProfile} />

                        {/* Terms */}
                        <PrivateRoute exact path="/terms" component={Terms} />

                        {/* Know Buy */}
                        <PrivateRoute exact path="/comercial" component={KnowBuy} />

                        {/* Courses */}
                        <PrivateRoute exact path="/cursos" component={Courses} />

                        <PrivateRoute exact path="/biblioteca" component={Library} />

                        <PrivateRoute exact path="/eventos" component={EventList} />

                        <PrivateRoute exact path="/seminarios" component={SeminarsHome} />

                        <PrivateRoute exact path="/ofertas" component={OffersHome} />

                        <PrivateRoute exact path="/noticias" component={NewsPanel} />

                        <PrivateRoute exact path="/novedades" component={NoveltyHome} />

                        {/* Favorites */}
                        <PrivateRoute exact path="/favoritos" component={Favorites} />

                    </Switch>

                </main>
            </Box>

            <Footer />
            <CustomSnackBar errors={[errorAuth]} msgs={[msgAuth]} />

            <SetQueryParams />
            {/* <ShowMobileBanner /> */}
        </ThemeProvider>
    );
}


export default ClientLayout;