// React
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';

// Redux Actions
import { IRootState } from '../../../redux/types';

//Custom Components
import HomeSkeleton from './skeletons/HomeSkeleton';
import WebsiteSkeleton from './skeletons/WebsiteSkeleton';

const pathnamesCards = ['/cursos', '/biblioteca', '/eventos', '/novedades', '/noticias', '/ofertas', '/seminarios'];

function LoadSkeleton() {

    // Redux Hooks
    const location = useLocation();

    if (location.pathname === '/inicio') {
        return (
            <HomeSkeleton />
        )
    }

    if (pathnamesCards.includes(location.pathname)) {
        return (
            <WebsiteSkeleton />
        )
    }

    return (
        <></>
    );
}

type skeletonProps = {
    loading: boolean,
    component: JSX.Element
}

export function useSkeleton(): skeletonProps {

    // Redux Hooks
    const location = useLocation();
    const { loading: loadingAuth } = useSelector((state: IRootState) => state.auth);
    const { loading: loadingUserBrand } = useSelector((state: IRootState) => state.brand);
    const { loading: loadingCourses } = useSelector((state: IRootState) => state.coursetype);
    const { news, loading: loadingNovelties } = useSelector((state: IRootState) => state.novelty);
    const { loading: loadingOffers } = useSelector((state: IRootState) => state.offer);
    const { loading: loadingResources } = useSelector((state: IRootState) => state.resourcetype);
    const { loading: loadingSeminars } = useSelector((state: IRootState) => state.seminar);
    const { sliders } = useSelector((state: IRootState) => state.slider);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if ((!loadingAuth || !loadingUserBrand) && location.pathname === '/inicio') {
            setLoading((!news.length || !sliders.length));
        }
    }, [loadingAuth, loadingUserBrand, news, sliders]);

    useEffect(() => {
        if (location.pathname === '/cursos') {
            setLoading(loadingCourses);
        }
    }, [loadingCourses]);

    useEffect(() => {
        if (location.pathname === '/biblioteca') {
            setLoading(loadingResources);
        }
    }, [loadingResources]);

    useEffect(() => {
        if (location.pathname === '/novedades') {
            setLoading(loadingNovelties);
        }

        if (location.pathname === '/noticias') {
            setLoading(loadingNovelties);
        }
    }, [loadingNovelties]);

    useEffect(() => {
        if (location.pathname === '/ofertas') {
            setLoading(loadingOffers);
        }
    }, [loadingOffers]);

    useEffect(() => {
        if (location.pathname === '/seminarios') {
            setLoading(loadingSeminars);
        }
    }, [loadingSeminars]);

    return {
        loading,
        component: LoadSkeleton()
    };
}

export default useSkeleton;