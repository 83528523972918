// Libraries
import { Editor } from '@tinymce/tinymce-react';

// Types
type TinyRichEditorProps = {
    handleEditorChange: any,
    initialContent: string,
    uploadURL: string
}

function TinyRichEditor({ handleEditorChange, initialContent, uploadURL }: TinyRichEditorProps) {

    return (
        <Editor
            apiKey="8q7dwlyjsykn5l4scxhhx07jspv5g0mxrs1tsoipl0pnbb9j"
            initialValue={initialContent}
            init={{
                height: 500,
                menubar: 'insert',
                plugins: [
                    'advlist autolink lists link image',
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount'
                ],
                toolbar:
                    'undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent | image | code | help',
                language: 'es',
                images_upload_url: `${process.env.REACT_APP_API_URL}${uploadURL}`
            }}
            onChange={handleEditorChange}
        />
    );
}

export default TinyRichEditor;