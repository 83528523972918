// React
import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// Material UI
import { Container, Box, Typography, Tabs, Tab, withStyles, IconButton, Tooltip, Grid } from '@material-ui/core';
import { VisibilityRounded } from "@material-ui/icons/";

// Custom components
import Header from '../../components/common/header/Header';
import SubHeader from '../../components/common/header/Subheader';
import ButtonBack from '../../components/common/ButtonBack';
import ClientTheme from '../../styles/components/themes/ClientTheme';
import TabPanel from '../../components/common/admin/TabPanel';
import FavoriteButton from '../../components/common/FavoriteButton';

// Redux Actions
import { getFavorites, unsetFavorite } from '../../redux/actions/favorites';
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import HiddenMobile from '../../components/common/HiddenMobile';
import HiddenDesktop from '../../components/common/HiddenDesktop';

//Styles
import UserStyles from '../../styles/components/common/UsersStyle';

type IFavorite = {
    resources: any,
    courses: any,
    novelties: any,
    news: any,
    offers: any,
    catalogs: any,
    seminars: any
}

//Custom Tabs
function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
const ContainerTab = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        }
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 8,
        '& > span': {
            backgroundColor: theme.palette.primary.main,
            height: 8,
            maxWidth: 100,
            borderRadius: '8px 8px 0px 0px',
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: 4,
            '& > span': {
                height: 4,
                borderRadius: '4px 4px 0px 0px',
            },
        }
    },
}))((props: StyledTabsProps) => <Tabs variant='scrollable' scrollButtons='on' {...props} TabIndicatorProps={{ children: <span /> }} />);

function Favorites({ history }: RouteComponentProps) {
    // Translate
    const { t } = useTranslation(["global"]);

    //Styles
    const classes = UserStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { favorites } = useSelector((state: IRootState) => state.favorite);

    //Tab State
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [tmpFavorites, setTmpFavorites] = useState<IFavorite>({
        resources: [],
        courses: [],
        novelties: [],
        news: [],
        offers: [],
        catalogs: [],
        seminars: []
    });

    useEffect(() => {
        dispatch(getFavorites());
    }, []);

    useEffect(() => {
        setTmpFavorites(favorites);
    }, [favorites]);

    useEffect(() => {
        Object.entries(tmpFavorites).some(([key, value], index: number) => {
            if (value.length > 0) {
                setCurrentTab(index);
                return true;
            }
        })
    }, [tmpFavorites]);

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const redirectClick = (type: string, path: string, data: any) => {
        if ('course' === type) {
            history.push(`${path}/${data.brand_id}/${data.slug}`);
        } else if ('novelty' === type) {
            history.push(`${path}/${data.slug}`);
        } else {
            history.push(`${path}/${data.id}`);
        }
    };

    const handleFavoriteClick = (id: number, type: string, indexPath: string) => (state: boolean) => {
        dispatch(unsetFavorite({
            id,
            type
        }));

        let tmpIndex = tmpFavorites[indexPath as keyof IFavorite].findIndex((value: any) => value.id === id);
        tmpFavorites[indexPath as keyof IFavorite].splice(tmpIndex, 1);
    };

    const FavoriteTabPanel = (data: any, resourceType: string, indexPath: string, historyPath: string, imgPath: string, currentTab: number, index: number) => (
        <TabPanel value={currentTab} index={index}>
            <Grid container spacing={2}>
                {data.map((value: any) => (
                    <Grid key={value.id} item xs={12} sm={6} lg={4}>
                        <Box className={classes.containerListFav}>
                            <Box display='flex' alignItems='center' gridGap={16}
                                onClick={() => redirectClick(resourceType, historyPath, value)} className={classes.containerInfoFav}>
                                <img
                                    src={(imgPath === 'courses') ? `${value.image}` : `${process.env.REACT_APP_AWS_URL}/${imgPath}/${value.image}`}
                                    title={value.title}
                                    alt={value.title}
                                    width='100%'
                                    className={classes.imgFav}
                                />
                                <Tooltip title={value.title}>
                                    <Typography className={classes.titleFav}>
                                        {value.title}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box>
                                <FavoriteButton handleClick={handleFavoriteClick(value.id, resourceType, indexPath)} favoriteState={true} />
                                <Tooltip title={`${t('view')}`}>
                                    <IconButton onClick={() => redirectClick(resourceType, historyPath, value)}>
                                        <VisibilityRounded />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </TabPanel>
    )

    return (
        <>
            <Header title='Mis Favoritos' />
            {/* <Header title={t("events")} /> */}
            <HiddenMobile>
                <SubHeader />
            </HiddenMobile>

            <Container maxWidth="lg">
                <HiddenDesktop>
                    <Box textAlign='center' mb={1} pt={9} pb={3} borderRadius="0px 0px 24px 24px" boxShadow='0px 6px 20px rgb(0 0 0 / 7%)' color={ClientTheme.palette.common.white} style={{ backgroundColor: ClientTheme.palette.primary.main }}>
                        <Typography variant='h5'> Mis Favoritos</Typography>
                    </Box>
                </HiddenDesktop>
                <HiddenMobile>
                    <ButtonBack />
                </HiddenMobile>
                <ContainerTab
                    value={currentTab}
                    onChange={handleChangeTab}
                    aria-label="disabled tabs example">

                    <Tab label={t("library")}  {...a11yProps(0)} style={{ display: (tmpFavorites.resources.length > 0) ? '' : 'none' }} />
                    <Tab label={t("academy")}  {...a11yProps(1)} style={{ display: (tmpFavorites.courses.length > 0) ? '' : 'none' }} />
                    <Tab label={t("novelties")}  {...a11yProps(2)} style={{ display: (tmpFavorites.novelties.length > 0) ? '' : 'none' }} />
                    <Tab label={t("news")}  {...a11yProps(3)} style={{ display: (tmpFavorites.news.length > 0) ? '' : 'none' }} />
                    <Tab label={t("offers")} {...a11yProps(4)} style={{ display: (tmpFavorites.offers.length > 0) ? '' : 'none' }} />
                    <Tab label={t("catalogs")}  {...a11yProps(5)} style={{ display: (tmpFavorites.catalogs.length > 0) ? '' : 'none' }} />
                    <Tab label={t("seminars")}  {...a11yProps(6)} style={{ display: (tmpFavorites.seminars.length > 0) ? '' : 'none' }} />
                </ContainerTab>

                {FavoriteTabPanel(tmpFavorites.resources, 'resource', 'resources', 'biblioteca', 'resources', currentTab, 0)}
                {FavoriteTabPanel(tmpFavorites.courses, 'course', 'courses', 'cursos', 'courses', currentTab, 1)}
                {FavoriteTabPanel(tmpFavorites.novelties, 'novelty', 'novelties', 'novedades', 'novelties', currentTab, 2)}
                {FavoriteTabPanel(tmpFavorites.news, 'novelty', 'news', 'noticias', 'novelties', currentTab, 3)}
                {FavoriteTabPanel(tmpFavorites.offers, 'offer', 'offers', 'ofertas', 'offers', currentTab, 4)}
                {FavoriteTabPanel(tmpFavorites.catalogs, 'resource', 'catalogs', 'biblioteca', 'resources', currentTab, 5)}
                {FavoriteTabPanel(tmpFavorites.seminars, 'seminar', 'seminars', 'seminarios', 'seminars', currentTab, 6)}

            </Container>

        </>
    );
}

export default Favorites;