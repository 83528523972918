import {
    IOfferState,
    START_GET_OFFERS, SUCCESS_GET_OFFERS, ERROR_GET_OFFERS,
    START_GET_CLIENT_OFFERS, SUCCESS_GET_CLIENT_OFFERS, ERROR_GET_CLIENT_OFFERS,
    START_CREATE_OFFER, SUCCESS_CREATE_OFFER, ERROR_CREATE_OFFER,
    START_UPDATE_OFFER, SUCCESS_UPDATE_OFFER, ERROR_UPDATE_OFFER,
    START_SHOW_OFFER, SUCCESS_SHOW_OFFER, ERROR_SHOW_OFFER,
    START_DELETE_OFFER, SUCCESS_DELETE_OFFER, ERROR_DELETE_OFFER,
    SUCCESS_TOGGLE_FORM_OFFER,
    START_ASSIGN_TAG_OFFER, SUCCESS_ASSIGN_TAG_OFFER, ERROR_ASSIGN_TAG_OFFER,
    START_DELETE_TAG_OFFER, SUCCESS_DELETE_TAG_OFFER, ERROR_DELETE_TAG_OFFER,
    START_ASSIGN_HIGHLIGHT_OFFER, SUCCESS_ASSIGN_HIGHLIGHT_OFFER, ERROR_ASSIGN_HIGHLIGHT_OFFER,
    START_COUNTER_VIEWS_OFFER, SUCCESS_COUNTER_VIEWS_OFFER, ERROR_COUNTER_VIEWS_OFFER
} from '../types';

const initialState: IOfferState = {
    reload: false,
    offers: [],
    offer_selected: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_OFFERS:
        case START_GET_CLIENT_OFFERS:
        case START_CREATE_OFFER:
        case START_UPDATE_OFFER:
        case START_SHOW_OFFER:
        case START_DELETE_OFFER:
        case START_ASSIGN_TAG_OFFER:
        case START_DELETE_TAG_OFFER:
        case START_ASSIGN_HIGHLIGHT_OFFER:
            return {
                ...state,
                error: false,
                loading: true
            }
        case SUCCESS_GET_OFFERS:
            return {
                ...state,
                offers: action.payload.offers,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_GET_CLIENT_OFFERS:
            return {
                ...state,
                offers: action.payload.offers,
                msg: null,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_CREATE_OFFER:
        case SUCCESS_UPDATE_OFFER:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: true,
                loading: false
            }
        case SUCCESS_SHOW_OFFER:
            return {
                ...state,
                offer_selected: action.payload.offer,
                msg: null,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_DELETE_OFFER:
        case SUCCESS_ASSIGN_HIGHLIGHT_OFFER:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: true,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_OFFER:
            return {
                ...state,
                offer_selected: (action.payload) ? null : state.offer_selected,
                showForm: !state.showForm,
                reload: false
            }
        case SUCCESS_ASSIGN_TAG_OFFER:
            let tmpOffer = state.offers.find((offer: any) => offer.id === action.payload.id);
            tmpOffer.tags = action.payload.tags.toString();
            return {
                ...state,
                offers: state.offers.map((offer: any) =>
                    offer.id === action.payload.id ? tmpOffer : offer
                ),
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_DELETE_TAG_OFFER:
            let tmpOffer1 = state.offers.find((offer: any) => offer.id === action.payload.id);
            const tags = tmpOffer1.tags.split(',');
            const newTags = tags.filter((tagId: number) => tagId != action.payload.tagId).toString();
            tmpOffer1.tags = newTags === '' ? null : newTags;
            return {
                ...state,
                offers: state.offers.map((offer: any) =>
                    offer.id === action.payload.id ? tmpOffer1 : offer
                ),
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_COUNTER_VIEWS_OFFER:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: false,
                loading: false
            }
        case ERROR_GET_OFFERS:
        case ERROR_GET_CLIENT_OFFERS:
        case ERROR_CREATE_OFFER:
        case ERROR_UPDATE_OFFER:
        case ERROR_SHOW_OFFER:
        case ERROR_DELETE_OFFER:
        case ERROR_ASSIGN_TAG_OFFER:
        case ERROR_DELETE_TAG_OFFER:
        case ERROR_ASSIGN_HIGHLIGHT_OFFER:
        case ERROR_COUNTER_VIEWS_OFFER:
            return {
                ...state,
                msg: action.payload,
                error: true,
                reload: false,
                loading: false,
                showForm: false
            }
        default:
            return state;
    }
}