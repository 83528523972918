import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


interface IProvince {
    id: number,
    name: string,
    country_id: number
}

function ProvinceAutocomplete( {value, country, handleInputChange} : any) {
    // Translate
    const { t } = useTranslation(["global"]);

    const { provinces } = useSelector((state: IRootState) => state.country);
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // Handle functions
    const handleAutoCompleteChange = (province: IProvince | null) => {
        handleInputChange('province', province);
    }

    const getOptions = () => {
        if (country === null) return [];

        if (Array.isArray(country)){
            return provinces.filter((province: any) => country.includes(province.country_id))
        }else{
            return provinces.filter((province: IProvince) => country.id === province.country_id)
        }
    }

    return (
        <Autocomplete
            id="autocomplete-provinces"
            value={value}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={getOptions()}
            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ""}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={errorForm.province}
                    helperText={msgForm.province}
                    label={t("province") + "*"}
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    )
}

export default ProvinceAutocomplete;


