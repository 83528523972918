import {
    ISeminarState,
    START_FILTER_SEMINARS, SUCCESS_FILTER_SEMINARS, ERROR_FILTER_SEMINARS,
    START_STORE_SEMINAR, SUCCESS_STORE_SEMINAR, ERROR_STORE_SEMINAR,
    START_UPDATE_SEMINAR, SUCCESS_UPDATE_SEMINAR, ERROR_UPDATE_SEMINAR,
    START_SHOW_SEMINAR, SUCCESS_SHOW_SEMINAR, ERROR_SHOW_SEMINAR,
    START_DELETE_SEMINAR, SUCCESS_DELETE_SEMINAR, ERROR_DELETE_SEMINAR,
    START_ASSIGN_HIGHLIGHT_SEMINAR, SUCCESS_ASSIGN_HIGHLIGHT_SEMINAR, ERROR_ASSIGN_HIGHLIGHT_SEMINAR,
    SUCCESS_TOGGLE_FORM_SEMINAR,
    SUCCESS_CREATE_SEMINARLESSON,
    SUCCESS_DELETE_SEMINARLESSON,
    SUCCESS_UPDATE_SUBSCRIBE_TO_SEMINARLESSON
} from '../types';

const initialState: ISeminarState = {
    seminars: [],
    seminarSelected: null,
    msg: null,
    error: false,
    loading: false,
    reload: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    let tmpSeminarSelected = { ...state.seminarSelected };
    switch (action.type) {
        case START_FILTER_SEMINARS:
        case START_STORE_SEMINAR:
        case START_UPDATE_SEMINAR:
        case START_SHOW_SEMINAR:
        case START_DELETE_SEMINAR:
        case START_ASSIGN_HIGHLIGHT_SEMINAR:
            return {
                ...state,
                msg: null,
                error: false,
                reload: false,
                loading: true
            }
        case SUCCESS_FILTER_SEMINARS:
            return {
                ...state,
                seminars: action.payload.seminars,
                msg: '',
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_STORE_SEMINAR:
        case SUCCESS_UPDATE_SEMINAR:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false,
                reload: true,
                showForm: false
            }
        case SUCCESS_CREATE_SEMINARLESSON:
            let seminarlessonIndex = tmpSeminarSelected.lessons.findIndex((lesson: any) => lesson.id === action.payload.seminarlesson.id);
            if (seminarlessonIndex < 0) {
                tmpSeminarSelected.lessons.push(action.payload.seminarlesson);
            } else {
                tmpSeminarSelected.lessons[seminarlessonIndex] = action.payload.seminarlesson;
            }
            return {
                ...state,
                seminarSelected: tmpSeminarSelected,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_DELETE_SEMINARLESSON:
            let deletedIndex = tmpSeminarSelected.lessons.findIndex((lesson: any) => lesson.id === action.payload.seminarlesson.id);
            delete tmpSeminarSelected.lessons[deletedIndex];
            return {
                ...state,
                seminarlesson_selected: tmpSeminarSelected,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_SEMINAR:
            return {
                ...state,
                seminarSelected: action.payload.seminar,
                msg: null,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_DELETE_SEMINAR:
        case SUCCESS_ASSIGN_HIGHLIGHT_SEMINAR:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: true,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_SEMINAR:
            return {
                ...state,
                seminarSelected: (action.payload) ? null : state.seminarSelected,
                showForm: !state.showForm,
                reload: false
            }
        case SUCCESS_UPDATE_SUBSCRIBE_TO_SEMINARLESSON:
            return {
                ...state,
                seminarSelected: {
                    ...state.seminarSelected, lessons: state.seminarSelected.lessons.map((lesson: any) => {
                        if (lesson.id === action.payload.seminarlesson_id) {
                            lesson.isRegistered = action.payload.seminarlesson_id;
                        }
                        return lesson;
                    })
                },
                msg: null,
                error: false,
                reload: false,
                loading: false
            }
        case ERROR_FILTER_SEMINARS:
        case ERROR_STORE_SEMINAR:
        case ERROR_UPDATE_SEMINAR:
        case ERROR_SHOW_SEMINAR:
        case ERROR_DELETE_SEMINAR:
        case ERROR_ASSIGN_HIGHLIGHT_SEMINAR:
            return {
                ...state,
                seminars: [],
                msg: action.payload,
                error: true,
                reload: false,
                loading: false
            }
        default:
            return state;
    }
}