import { makeStyles, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => createStyles({
    text: {
        backgroundImage: "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)",
        fill: "#B49568",
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },

    icon: {
        fill: '#BFA75E',
    },

    block: {
        position: 'absolute',
        backgroundColor: 'rgba(250, 250, 250, 0.32)',
        backdropFilter: 'blur(6px)',
        display: 'grid',
        width: '100%',
        borderRadius: '0px 0px 16px 16px',
        '& h6, h5': {
            backgroundImage: "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)",
            fill: "#B49568",
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 500,
        },
        [theme.breakpoints.up('md')]: {
            top: '166px',
            height: '46%'
        },
        [theme.breakpoints.down('sm')]: {
            top: '132px',
            height: '41%'
        },
    },

    lessonBlock: {
        cursor: 'initial',
        position: 'absolute',
        backgroundColor: 'rgba(250, 250, 250, 0.32)',
        backdropFilter: 'blur(6px)',
        display: 'grid',
        width: '100%',
        borderRadius: '0px 0px 16px 16px',
        top: 0,
        height: '100%',
        '& h6, h5': {
            backgroundImage: "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)",
            fill: "#B49568",
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 500,
        },
    },

    lessons: {
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
        marginTop: theme.spacing(4),
        borderRadius: 16,
        '&.active': {
            background: `linear-gradient(#fafafa,#fafafa) padding-box,
            "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)" border-box;`,
            border: '2px solid transparent',
            boxShadow: 'none'
        },
        "& h6": {
            fontWeight: 500,
        },
        '&:hover': {
            background: 'rgb(213 179 133 / 23%)',
        }
    },

    label: {
        position: 'absolute',
        right: '0',
        top: 8,
        width: 64,
        borderRadius: '4px 0px 0px 4px',
        padding: '0px 4px',
        background: "linear-gradient(81.59deg, #A6813C -6.65%, #F2E3B3 50.89%, #BFA75E 110.5%)",
        '& h6': {
            fontWeight: 500,
        },
        [theme.breakpoints.down('sm')]: {
            top: 0,
            padding: 0,
            '& p': {
                fontWeight: 500,
            },
        },
    },

    outline: {
        border: `2px solid #D5B385`,
        borderRadius: 8,
        '& span': {
            backgroundImage: "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)",
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            '& svg': {
                color: `#D5B385`,
            },
        },
        '&:hover': {
            '& span': {
                WebkitTextFillColor: 'inherit',
                color: theme.palette.grey[50],
                '& svg': {
                    color: theme.palette.grey[50],
                },
            },
            border: 0,
            backgroundColor: 'none',
            background: "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)",
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 24,
            width: '100%',
            maxWidth: '100%',
        }
    },

    chip: {
        backgroundColor: 'none',
        background: "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)",
        maxHeight: 20,
        borderRadius: 4,
        [theme.breakpoints.down('sm')]: {
            marginTop: 24,
            width: '100%',
            maxWidth: '100%',
        }
    },

    button: {
        backgroundColor: 'none',
        background: "linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)",
        [theme.breakpoints.down('sm')]: {
            marginTop: 24,
            width: '100%',
            maxWidth: '100%',
        }
    },

    btnLinkDetail: {
        marginLeft: 'auto',
        marginRight: 'auto',
        background: ' linear-gradient(89.97deg, #D5B385 -12.76%, #B49568 113.26%)',
        '& span': {
            WebkitTextFillColor: 'inherit',
            color: theme.palette.common.white,
            fontWeight: 400,
        },
        '&:hover': {
            background: 'linear-gradient(81.59deg, #A6813C -6.65%, #F2E3B3 50.89%, #BFA75E 110.5%)',
            '& span': {
                backgroundImage: `linear-gradient(0.29deg, #171A1C 0.22%, ${process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F"} 125.3%)`,
                fill: "#B49568",
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 500,
            },
        },
    },

    barProgress: {
        backgroundColor: 'none',
        background: "linear-gradient(81.59deg, #A6813C -6.65%, #F2E3B3 50.89%, #BFA75E 110.5%)",
    },

    shadow: {
        boxShadow: '0px 4px 32px 0px rgba(230, 185, 128, 0.3) !important'
    },

    slider: {
        background: 'linear-gradient(81.59deg, #A6813C -6.65%, #F2E3B3 50.89%, #BFA75E 110.5%)',
        top: 24,
        right: 0,
        padding: '2px 16px',
        borderRadius: '4px 0px 0px 4px',
        '& p': {
            fontWeight: 500,
        },
        '&.block': {
            padding: '1px 16px',
            right: 16,
        },
    },
    //=======Seminarios=======
    containerHeroDetail: {
        padding: '8px',
        borderRadius: 10,
        display: 'column',
        boxSizing: 'content-box',
        position: 'relative',
        border: `1px solid #D5B385`,
        boxShadow: '0px 8px 30px 0px rgba(230, 185, 128, 0.3)',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            boxShadow: 'none',
            border: 'none',
        },
    },
    //Details&Description  =================
    containerDetail: {
        borderTop: `2px solid #D5B385 !important`,
        borderBottom: `2px solid #D5B385 !important`,
        borderRadius: 16,
    },
}))