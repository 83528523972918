import {
    START_GET_SEMINARLESSONS,
    START_CREATE_SEMINARLESSON,
    // START_STORE_SEMINARLESSONMULTIMEDIA,
    START_SHOW_SEMINARLESSON,
    START_DELETE_SEMINARLESSON,
    START_DELETE_SEMINARLESSONMULTIMEDIA,
    START_SUBSCRIBE_TO_SEMINARLESSON,
    START_GET_SEMINARLESSON_REGISTEREDUSERS,
    START_TOGGLE_FORM_SEMINARLESSON,
    START_SET_VIEW_SEMINARLESSON
} from '../types';

export const getSeminarLessons = () => ({
    type: START_GET_SEMINARLESSONS
})

export const createSeminarLesson = (payload) => ({
    type: START_CREATE_SEMINARLESSON,
    payload
})

// export const storeLessonMultimedia = payload => ({
//     type: START_STORE_LESSONMULTIMEDIA,
//     payload
// })

export const showSeminarLesson = payload => ({
    type: START_SHOW_SEMINARLESSON,
    payload
})

export const deleteSeminarLesson = payload => ({
    type: START_DELETE_SEMINARLESSON,
    payload
})

export const deleteSeminarLessonMultimedia = payload => ({
    type: START_DELETE_SEMINARLESSONMULTIMEDIA,
    payload
})

export const subscribeToSeminarlesson = payload => ({
    type: START_SUBSCRIBE_TO_SEMINARLESSON,
    payload
})

export const getRegisteredUsers = payload => ({
    type: START_GET_SEMINARLESSON_REGISTEREDUSERS,
    payload
})

export const toggleFormSeminarLesson = () => ({
    type: START_TOGGLE_FORM_SEMINARLESSON
})

export const setViewSeminarLesson = payload => ({
    type: START_SET_VIEW_SEMINARLESSON,
    payload
})