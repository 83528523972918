import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


interface IStatus {
    id: number,
    name: string
}

function StatusAutocomplete( {value, handleInputChange} : any) {
    // Translate
    const { t } = useTranslation(["global"]);

    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    const status = [
        { id: 1, name: t('active') },
        { id: 0, name: t('inactive') }
    ];

    // Handle functions
    const handleAutoCompleteChange = (status: IStatus | null) => {
        handleInputChange('status', status);
    }

    return (
        <Autocomplete
            id="autocomplete-status"
            disableClearable
            value={value}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={status}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label={t("status")} 
                    variant="filled" 
                    InputLabelProps={{ shrink: true }} 
                    error={errorForm.status} 
                    helperText={msgForm.status}
                />
            }
        />
    )
}

export default StatusAutocomplete;