import {
    ISeminarLessonState,
    START_GET_SEMINARLESSONS, SUCCESS_GET_SEMINARLESSONS, ERROR_GET_SEMINARLESSONS,
    START_CREATE_SEMINARLESSON, ERROR_CREATE_SEMINARLESSON,
    // START_STORE_SEMINARLESSONMULTIMEDIA, SUCCESS_STORE_SEMINARLESSONMULTIMEDIA, ERROR_STORE_SEMINARLESSONMULTIMEDIA,
    START_SHOW_SEMINARLESSON, SUCCESS_SHOW_SEMINARLESSON, ERROR_SHOW_SEMINARLESSON,
    START_DELETE_SEMINARLESSON, ERROR_DELETE_SEMINARLESSON,
    START_SUBSCRIBE_TO_SEMINARLESSON, SUCCESS_SUBSCRIBE_TO_SEMINARLESSON, ERROR_SUBSCRIBE_TO_SEMINARLESSON,
    SUCCESS_GET_SEMINARLESSON_REGISTEREDUSERS, ERROR_GET_SEMINARLESSON_REGISTEREDUSERS,
    SUCCESS_TOGGLE_FORM_SEMINARLESSON,
    SUCCESS_SET_VIEW_SEMINARLESSON
} from '../types';

const initialState: ISeminarLessonState = {
    seminarlessons: [],
    seminarlesson_selected: null,
    registeredUsers: [],
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_SEMINARLESSONS:
        case START_CREATE_SEMINARLESSON:
        case START_SHOW_SEMINARLESSON:
        case START_DELETE_SEMINARLESSON:
        case START_SUBSCRIBE_TO_SEMINARLESSON:
            return {
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_SEMINARLESSONS:
            return {
                ...state,
                seminarlessons: action.payload.seminarlessons,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_SEMINARLESSON:
            return {
                ...state,
                seminarlesson_selected: action.payload.seminarlesson,
                showForm: !state.showForm,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_SUBSCRIBE_TO_SEMINARLESSON:
            let tmpSeminarLessonSelected = { ...state.seminarlesson_selected };
            tmpSeminarLessonSelected.isRegistered = action.payload.seminarlesson_id;
            return {
                ...state,
                seminarlesson_selected: tmpSeminarLessonSelected,
                msg: action.payload.msg,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_GET_SEMINARLESSON_REGISTEREDUSERS:
            return {
                ...state,
                registeredUsers: action.payload.registeredUsers,
                msg: null,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_SEMINARLESSON:
            return {
                ...state,
                seminarlesson_selected: null,
                showForm: !state.showForm,
                error: false,
                loading: false
            }
        case SUCCESS_SET_VIEW_SEMINARLESSON:
            return {
                ...state,
                seminarlesson_selected: action.payload,
                registeredUsers: [],
                showForm: false
            }
        case ERROR_GET_SEMINARLESSONS:
        case ERROR_CREATE_SEMINARLESSON:
        case ERROR_SHOW_SEMINARLESSON:
        case ERROR_DELETE_SEMINARLESSON:
        case ERROR_SUBSCRIBE_TO_SEMINARLESSON:
        case ERROR_GET_SEMINARLESSON_REGISTEREDUSERS:
            return {
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}