import {
    InputCountry,
    BrandAutocomplete,
    LineAutocomplete,
    RoleAutocomplete,
    VisibilityAutocomplete,
    ResourcetypeSelect,
    LanguageMultiAutocomplete,
    CountryAutocomplete,
    ProvinceAutocomplete,
    TownAutocomplete,
    PostalcodeAutocomplete
} from '../components/common/forms'

export const MassAssignmentInputList: any = {
    country: {
        component: CountryAutocomplete,
        name: 'country',
        overwriteDisabled: true,
        checked: true,
    },
    province: {
        component: ProvinceAutocomplete,
        name: 'province',
        overwriteDisabled: true,
        checked: true,
    },
    town: {
        component: TownAutocomplete,
        name: 'town',
        overwriteDisabled: true,
        checked: true,
    },
    postalcode: {
        component: PostalcodeAutocomplete,
        name: 'postalcode',
        overwriteDisabled: true,
        checked: true,
    },
    countries: {
        component: InputCountry,
        name: 'countries',
        overwriteDisabled: false,
        checked: false,
    },
    brand: {
        component: BrandAutocomplete,
        name: 'brand',
        overwriteDisabled: true,
        checked: true,
    },
    line: {
        component: LineAutocomplete,
        name: 'line',
        overwriteDisabled: true,
        checked: true,
    },
    resourcetype: {
        component: ResourcetypeSelect,
        name: 'resourcetype',
        overwriteDisabled: true,
        checked: true,
    },
    role: {
        component: RoleAutocomplete,
        name: 'role',
        overwriteDisabled: true,
        checked: true,
    },
    languages: {
        component: LanguageMultiAutocomplete,
        name: 'languages',
        overwriteDisabled: false,
        checked: false,
    },
    visibility: {
        component: VisibilityAutocomplete,
        name: 'visibility',
        overwriteDisabled: true,
        checked: true,
    },
}