
import { useEffect, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
// Material UI
import { AppBar, ButtonGroup, Button, Typography, Box, makeStyles } from '@material-ui/core';
import { ArrowBackRounded, MoreHorizRounded } from '@material-ui/icons';

// Custom components
import BrandMobileFilter from '../../filters/BrandMobileFilter';
import HiddenDesktop from '../../../common/HiddenDesktop';
import MenuIcons, { IMenuIcons } from '../../../common/MenuIcons';

// Redux
import { IRootState } from '../../../../redux/types';

// Translation
import { useTranslation } from "react-i18next";


// Types
type ClientMobileMenuProps = {
    menus: any
}

type IMenu = {
    id: number,
    name: string,
    code: keyof IMenuIcons,
    path: string
}

// Styles
const Styles = makeStyles(theme => ({
    appBarMobile: {
        top: 'auto',
        bottom: 0,
        padding: 8,
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },

    menuContent: {
        borderRadius: 16,
        backgroundColor: theme.palette.grey[50],
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
        [theme.breakpoints.between('sm', 'md')]: {
            width: 500,
            margin: '0 auto',
        },
    },
    menuWidth: {
        minWidth: '20%',
        maxHeight: 71,
        padding: 0,
        border: '0px !important',
    },
    menuWidthLess: {
        minWidth: '25%',
        maxHeight: 71,
        padding: 0,
        border: '0px !important',
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 8,
        paddingBottom: 10,

    },
    menuIcon: {
        margin: 0,
        "& svg": {
            color: theme.palette.grey[400],
            fontSize: '24px !important',
        },
        '&.active svg': {
            color: theme.palette.primary.main
        }

    },


}));

function ClientMobileMenu({ menus }: ClientMobileMenuProps) {
    let history = useHistory();
    const classes = Styles();


    // Translation
    const { t } = useTranslation(["global"]);

    const [showMenu, setShowMenu] = useState(true);

    const { accepted: acceptedTerms } = useSelector((state: IRootState) => state.term);
    const { user: authUser, error: errorAuth, msg: msgAuth } = useSelector((state: IRootState) => state.auth);

    useEffect(() => {
        if (localStorage.getItem("accept_terms") === "true" && authUser !== null && authUser.me && authUser.verification === 1) {
            setShowMenu(false);
        }
    }, []);

    useEffect(() => {
        if (acceptedTerms) {
            setShowMenu(true);
        }
    }, [acceptedTerms]);

    // Handle dropdown menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    let activeTest = ['/comercial', '/seminarios',].includes(window.location.pathname)


    return (
        <HiddenDesktop>
            {showMenu &&
                <>
                    <AppBar position="fixed" className={classes.appBarMobile}>

                        <ButtonGroup classes={{ root: classes.menuContent, grouped: classes.menuWidth }}>
                            {menus.slice(0, 4).map((menu: any) => (
                                <Button
                                    key={menu.id}
                                    fullWidth
                                    startIcon={<MenuIcons menuCode={menu.code} />}
                                    onClick={() => history.push(menu.path)}
                                    classes={{ label: classes.menuItem, startIcon: clsx(classes.menuIcon, (window.location.pathname === menu.path ? 'active' : '')) }}>
                                    <Typography variant="caption" color={window.location.pathname === menu.path ? 'primary' : 'textSecondary'}>{menu.name}</Typography>
                                </Button>
                            ))}
                            {menus.length > 3 &&
                                <Button fullWidth startIcon={<MoreHorizRounded />}
                                    onClick={handleProfileMenuOpen}
                                    classes={{ label: classes.menuItem, startIcon: clsx(classes.menuIcon, ['/comercial', '/seminarios',].includes(window.location.pathname) ? 'active' : '') }}>
                                    <Typography
                                        variant="caption"
                                        color={['/comercial', '/seminarios',].includes(window.location.pathname) ? 'primary' : 'textSecondary'}>
                                        {t("see-more")}
                                    </Typography>
                                </Button>
                            }
                        </ButtonGroup>

                    </AppBar>

                    <Box display="flex">
                        {isMenuOpen &&
                            <AppBar position="fixed" className={classes.appBarMobile}>

                                <ButtonGroup classes={{ root: classes.menuContent, grouped: classes.menuWidth }}>
                                    <Button fullWidth startIcon={<ArrowBackRounded />}
                                        aria-controls={'primary-search-account-menu'}
                                        aria-haspopup="true"
                                        onClick={handleMenuClose}
                                        classes={{ label: classes.menuItem, startIcon: classes.menuIcon }}>
                                        <Typography variant="caption" color="textSecondary">{t("back")}</Typography>
                                    </Button>
                                    {menus.slice(4).map((menu: any) => (
                                        <Button key={menu.id} fullWidth startIcon={<MenuIcons menuCode={menu.code} />} onClick={() => history.push(menu.path)}
                                            classes={{ label: classes.menuItem, startIcon: clsx(classes.menuIcon, (window.location.pathname === menu.path ? 'active' : '')) }}>
                                            <Typography variant="caption" style={{ lineHeight: '0.85rem' }} color={window.location.pathname === menu.path ? 'primary' : 'textSecondary'}>{menu.name}</Typography>
                                        </Button>
                                    ))}
                                    <BrandMobileFilter theme="client" type="mobile" />
                                </ButtonGroup>

                            </AppBar>
                        }
                    </Box>
                </>
            }
        </HiddenDesktop>
    );
}

export default ClientMobileMenu;
