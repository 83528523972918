// React
import { useState, MouseEvent, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

// Material UI
import {
    Drawer, Typography, Box, Menu, Button, Tooltip, Badge, Divider, List, ListItem, Tab, Tabs
} from '@material-ui/core';
import { BubbleChart, CloseRounded, FlashOnRounded, ForumRounded, NotificationsOutlined, NotificationsRounded, SchoolRounded } from '@material-ui/icons';
import clsx from 'clsx';

// Custom Components
import TabPanel from '../../common/admin/TabPanel';
import ButtonNavbar from '../../layouts/navigation/ButtonNavbar';
import HiddenMobile from '../../common/HiddenMobile';
import HiddenDesktop from '../../common/HiddenDesktop';

// Redux
import { getNotifications, getUnreadNotifications, setNotificationAsRead, setAllNotificationsAsRead } from '../../../redux/actions/notifications';
import { IRootState } from '../../../redux/types';

// Libraries
import moment from 'moment';

// Styles
import NotificationPanelStyles from '../../../styles/components/common/NotificationPanelStyle';


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function NotificationPanel(props: any) {
    const { theme } = props;

    const panelClasses = NotificationPanelStyles();

    // Translation
    const { t } = useTranslation(["global"]);

    //Redux Hooks
    const dispatch = useDispatch();
    const { notifications } = useSelector((state: IRootState) => state.notification);

    //Tab State
    const [currentTab, setCurrentTab] = useState(0);

    // Handle dropdown menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const [showMenu, setShowMenu] = useState(true);

    // Handle drawerUser
    const [mobileUserOpen, setMobileUserOpen] = useState(false);

    const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
        if (newValue === 0) {
            dispatch(getUnreadNotifications());
        }
        if (newValue === 1) {
            dispatch(getNotifications());
        }
    };

    const handleClickNotification = (notification: any) => {
        dispatch(setNotificationAsRead(notification.id));
        setAnchorEl(null);
    };

    const icons: any[string] = []
    icons["news"] = <ForumRounded fontSize="large" />
    icons["novelty"] = < FlashOnRounded fontSize="large" />
    icons["course"] = <SchoolRounded fontSize="large" />
    const typeDetail = (type: any) => {
        return icons[type]
    }

    return (
        <>
            <HiddenMobile>
                <Tooltip title={`${t('admin.people.notifications')}`} >
                    <ButtonNavbar
                        className={theme}
                        onClick={handleProfileMenuOpen}>
                        <Badge badgeContent={notifications.filter((notification: any) => notification.read_at === null).length} max={99}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                            color={theme === 'client' ? "primary" : "secondary"}>
                            <NotificationsRounded />
                        </Badge>
                    </ButtonNavbar>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="notification-panel"
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                    open={isMenuOpen}
                    onClose={() => setAnchorEl(null)}>
                    {showMenu &&
                        <Box p={3} width={448}>
                            <Box display='flex' alignItems='center'>
                                <Typography variant='h6' style={{ fontWeight: 500 }}>{t('admin.people.notifications')}</Typography>
                                {/* <Button onClick={() => setAnchorEl(null)} variant='text' className={panelClasses.settingsBtn}>
                                    <Typography>Ajustes</Typography><Settings />
                                </Button> */}
                            </Box>

                            <Box>
                                <Tabs
                                    value={currentTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleChangeTab}
                                    style={{ minHeight: 'fit-content' }}
                                    aria-label="disabled tabs example">
                                    <Tab label={t("admin.people.pending")}  {...a11yProps(0)} className={panelClasses.tapBtn} />
                                    <Tab label={t("all")}  {...a11yProps(1)} className={panelClasses.tapBtn} />
                                </Tabs>
                            </Box>

                            <TabPanel value={currentTab} index={0} noPadding={true}>
                                {notifications.length === 0 ?
                                    <Box width='100%' gridGap={20} height={300} display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                        <BubbleChart style={{ fontSize: '100px' }} htmlColor={`${process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296"}`} />
                                        <Typography variant='subtitle1'>{t('you-havent-notifications')}</Typography>
                                        <Button variant='contained' size='small' color='primary' onClick={() => setAnchorEl(null)}>{t('back')}</Button>
                                    </Box>
                                    :
                                    <>
                                        <Box width='100%' display='flex' justifyContent='flex-end'>
                                            <Button onClick={() => { dispatch(setAllNotificationsAsRead()) }} classes={{ root: panelClasses.cleanBtn }} variant="text">{t("mark-all-as-read")}</Button>
                                            {/* Si el usuario marca todas como leidas se espera este botón */}
                                            {/* <Button classes={{root: panelClasses.clenBtn}} variant="text">{t('delete') + ' ' + t('all').toLowerCase()} </Button> */}
                                        </Box>
                                        <List classes={{ root: panelClasses.list }}>
                                            {notifications.map((notification: any) => (
                                                <ListItem key={notification.id} button onClick={() => { dispatch(setNotificationAsRead(notification.id)); setAnchorEl(null) }} classes={{ root: panelClasses.contentItem }}>
                                                    <Box display="flex" my={1} gridGap={8} flexDirection="row" width="100%">
                                                        <Box display='flex' pr={2} alignItems='center' className={panelClasses.iconItem}>{typeDetail(notification.data.type)}</Box>
                                                        <Box display="flex" flexDirection="column" width="100%">
                                                            <Typography variant='body2' color='textSecondary'>{notification.data.description}</Typography>
                                                            {(notification.read_at) ?
                                                                <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                                :
                                                                <Badge color="primary" variant='dot' classes={{ root: panelClasses.badgeItem, anchorOriginTopRightRectangle: panelClasses.dotItem }}>
                                                                    <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                                </Badge>
                                                            }
                                                            <Typography variant='caption' color='textSecondary' style={{ marginTop: '-4px' }}>{`${moment(notification.created_at).fromNow()}`}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Divider light />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                }
                            </TabPanel>

                            <TabPanel value={currentTab} index={1} noPadding={true}>
                                {notifications.length === 0 ?
                                    <Box width='100%' gridGap={20} height={300} display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                        <BubbleChart style={{ fontSize: '100px' }} htmlColor={`${process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296"}`} />
                                        <Typography variant='subtitle1'>{t('you-havent-notifications')}</Typography>
                                        <Button variant='contained' size='small' color='primary' onClick={() => setAnchorEl(null)}>{t('back')}</Button>
                                    </Box>
                                    :
                                    <>
                                        <Box width='100%' display='flex' justifyContent='flex-end'>
                                            {/* Si el usuario marca todas como leidas se espera este botón */}
                                            {/* <Button classes={{ root: panelClasses.cleanBtn}} variant="text">{t('delete') + ' ' + t('all').toLowerCase()} </Button> */}
                                        </Box>
                                        <List classes={{ root: panelClasses.list }}>
                                            {notifications.map((notification: any) => (
                                                <ListItem key={notification.id} button onClick={() => { dispatch(setNotificationAsRead(notification.id)); setAnchorEl(null) }} classes={{ root: panelClasses.contentItem }}>
                                                    <Box display="flex" my={1} gridGap={8} flexDirection="row" width="100%">
                                                        <Box display='flex' pr={2} alignItems='center' className={panelClasses.iconItem}>{typeDetail(notification.data.type)}</Box>
                                                        <Box display="flex" flexDirection="column" width="100%">
                                                            <Typography variant='body2' color='textSecondary'>{notification.data.description}</Typography>
                                                            {(notification.read_at) ?
                                                                <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                                :
                                                                <Badge color="primary" variant='dot' classes={{ root: panelClasses.badgeItem, anchorOriginTopRightRectangle: panelClasses.dotItem }}>
                                                                    <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                                </Badge>
                                                            }
                                                            <Typography variant='caption' color='textSecondary' style={{ marginTop: '-4px' }}>{`${moment(notification.created_at).fromNow()}`}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Divider light />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                }
                            </TabPanel>
                        </Box>
                    }
                </Menu>
            </HiddenMobile>

            <HiddenDesktop display="flex">
                <ButtonNavbar
                    className={theme}
                    onClick={() => setMobileUserOpen(!mobileUserOpen)}>
                    <Badge badgeContent={notifications.filter((notification: any) => notification.read_at === null).length} max={99}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                        color={theme === 'client' ? "primary" : "secondary"}>
                        <NotificationsOutlined />
                    </Badge>
                </ButtonNavbar>
                <Drawer
                    variant="temporary"
                    anchor='right'
                    open={mobileUserOpen}
                    onClose={() => setMobileUserOpen(!mobileUserOpen)}
                    classes={{ paper: clsx(panelClasses.drawerPaper, 'client') }}
                    ModalProps={{ keepMounted: true, }} >
                    <Box p={2} maxHeight="97vh">
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Typography variant='h6' style={{ fontWeight: 500 }}>{t('admin.people.notifications')}</Typography>
                            <Button onClick={() => setMobileUserOpen(!mobileUserOpen)} variant='text' className={panelClasses.settingsBtn}>
                                <CloseRounded />
                            </Button>
                        </Box>

                        <Box>
                            <Tabs
                                value={currentTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChangeTab}
                                style={{ minHeight: 'fit-content' }}
                                aria-label="disabled tabs example">
                                <Tab label={t("admin.people.pending")}  {...a11yProps(0)} className={panelClasses.tapBtn} />
                                <Tab label={t("all")}  {...a11yProps(1)} className={panelClasses.tapBtn} />
                            </Tabs>
                        </Box>

                        <TabPanel value={currentTab} index={0} noPadding={true}>
                            {notifications.length === 0 ?
                                <Box width='100%' gridGap={20} height='80vh' display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                    <BubbleChart style={{ fontSize: '100px' }} htmlColor={`${process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296"}`} />
                                    <Typography variant='subtitle1'>{t('you-havent-notifications')}</Typography>
                                    <Button variant='contained' size='small' color='primary' onClick={() => setMobileUserOpen(!mobileUserOpen)}>{t('back')}</Button>
                                </Box>
                                :
                                <>
                                    <Box width='100%' display='flex' justifyContent='flex-end'>
                                        <Button classes={{ root: panelClasses.cleanBtn }} variant="text" size='large'>{t("mark-all-as-read")}</Button>
                                        {/* Si el usuario marca todas como leidas se espera este botón */}
                                        {/* <Button classes={{root: panelClasses.clenBtn}} variant="text">{t('delete') + ' ' + t('all').toLowerCase()} </Button> */}
                                    </Box>
                                    <List classes={{ root: panelClasses.list }}>
                                        {notifications.map((notification: any) => (
                                            <ListItem key={notification.id} button onClick={() => { handleClickNotification(notification); }} classes={{ root: panelClasses.contentItem }}>
                                                <Box display="flex" my={1} gridGap={8} flexDirection="row" width="100%">
                                                    <Box display='flex' pr={2} alignItems='center' className={panelClasses.iconItem}>{typeDetail(notification.data.type)}</Box>
                                                    <Box display="flex" flexDirection="column" width="100%">
                                                        <Typography variant='body2' color='textSecondary'>{notification.data.description}</Typography>
                                                        {(notification.read_at) ?
                                                            <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                            :
                                                            <Badge color="primary" variant='dot' classes={{ root: panelClasses.badgeItem, anchorOriginTopRightRectangle: panelClasses.dotItem }}>
                                                                <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                            </Badge>
                                                        }
                                                        <Typography variant='caption' color='textSecondary' style={{ marginTop: '-4px' }}>{`${moment(notification.created_at).fromNow()}`}</Typography>
                                                    </Box>
                                                </Box>
                                                <Divider light />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            }
                        </TabPanel>

                        <TabPanel value={currentTab} index={1} noPadding={true}>
                            {notifications.length === 0 ?
                                <Box width='100%' gridGap={20} height='80vh' display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                    <BubbleChart style={{ fontSize: '100px' }} htmlColor={`${process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296"}`} />
                                    <Typography variant='subtitle1'>{t('you-havent-notifications')}</Typography>
                                    <Button variant='contained' size='small' color='primary' onClick={() => setMobileUserOpen(!mobileUserOpen)}>{t('back')}</Button>
                                </Box>
                                :
                                <>
                                    <Box width='100%' display='flex' justifyContent='flex-end'>
                                        {/* Si el usuario marca todas como leidas se espera este botón */}
                                        {/* <Button classes={{ root: panelClasses.cleanBtn}} variant="text">{t('delete') + ' ' + t('all').toLowerCase()} </Button> */}
                                    </Box>
                                    <List classes={{ root: panelClasses.list }}>
                                        {notifications.map((notification: any) => (
                                            <ListItem key={notification.id} button onClick={() => { handleClickNotification(notification); }} classes={{ root: panelClasses.contentItem }}>
                                                <Box display="flex" my={1} gridGap={8} flexDirection="row" width="100%">
                                                    <Box display='flex' pr={2} alignItems='center' className={panelClasses.iconItem}>{typeDetail(notification.data.type)}</Box>
                                                    <Box display="flex" flexDirection="column" width="100%">
                                                        <Typography variant='body2' color='textSecondary'>{notification.data.description}</Typography>
                                                        {(notification.read_at) ?
                                                            <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                            :
                                                            <Badge color="primary" variant='dot' classes={{ root: panelClasses.badgeItem, anchorOriginTopRightRectangle: panelClasses.dotItem }}>
                                                                <Typography variant='subtitle1' style={{ fontWeight: 500, lineHeight: '27px' }}>{notification.data.title}</Typography>
                                                            </Badge>
                                                        }
                                                        <Typography variant='caption' color='textSecondary' style={{ marginTop: '-4px' }}>{`${moment(notification.created_at).fromNow()}`}</Typography>
                                                    </Box>
                                                </Box>
                                                <Divider light />
                                            </ListItem>
                                        ))}
                                    </List>
                                </>
                            }
                        </TabPanel>
                    </Box>
                </Drawer>
            </HiddenDesktop>

            {/* <ScreenLoading loadings={[loading]} /> */}

        </>
    );
}

export default NotificationPanel;