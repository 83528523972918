import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import { NoEncryption } from '@material-ui/icons';
import theme from '../../themes/ClientTheme';

export default makeStyles(() => createStyles({
    bodyHomeSkeleton: {
        maxWidth: '1920px',
        margin: '0 auto',
    },
    navbarContainer: {
        height: 50,
        minHeight: 50,
        maxHeight: 50,
        display: 'flex',
        left: 0,
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        alignItems: 'center',
        padding: '0px 24px',
        backgroundColor: '#FAFAFA',
        position: 'fixed',
        top: 0,
        zIndex: 1200,
        width: '100%',
    },
    navbarContentSide: {
        display: 'flex',
        alignItems: 'center',
    },
    breadcrumbContainer: {
        display: 'flex',
        padding: 5,
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        marginTop: 50,
        height: 42,
    },
    filterContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: '0% 5% 0% 5%',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0% 15% 0% 15%',
        },
        marginTop: 15,
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        height: 50,
    },
    sklborderRadius: {
        borderRadius: 8,
    },
    sklButtonWorld: {
        borderRadius: 8,
        height: 88,
        width: '100%',
        boxShadow: '23px -23px 43px #FFFFFF, 28px 28px 50px rgba(13, 39, 80, 0.16)',
    },
    positionMenuMobile: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 'auto',
        bottom: 0,
        padding: 8,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        zIndex: 1200,
    },
    containerMenuMobile: {
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
        borderRadius: 16,
        backgroundColor: '#FAFAFA',
        display: 'flex',
    },
    containerbtnMenuMobile: {
        paddingTop: 8,
        paddingBottom: 10,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        borderBottom: '1px solid  #C7CCD1',
        marginTop: 32,
        marginBottom: 24,
    },
    headerMainWebsiteDesktop: {
        marginLeft: 25,
        height: 150,
        width: '100%',
        boxShadow: '23px -23px 43px #FFFFFF, 28px 28px 50px rgba(13, 39, 80, 0.16)',
    },
    headerWebsiteDesktop: {
        borderRadius: '0px 0px 0px 15px',
        marginTop: 5,
        marginLeft: 25,
        height: 50,
        width: '100%',
        boxShadow: '23px -23px 43px #FFFFFF, 28px 28px 50px rgba(13, 39, 80, 0.16)',
    },
    headerWebsiteMobile: {
        height: 60,
        width: '100%',
        boxShadow: '23px -23px 43px #FFFFFF, 28px 28px 50px rgba(13, 39, 80, 0.16)',
    },
    containerHero: {
        width: '100%',
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            margin: '-40px auto 0px',
        },
    },
    containerWebsiteHero: {
        width: '100%',
        margin: 0,
    },
    cardContainer: {
        boxShadow: '4px 6px 20px rgb(0 0 0 / 10%)',
        position: 'relative',
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
    },
    cardBody: {
        [theme.breakpoints.up('lg')]: {
            display: 'inherit',
        },
        display: 'flex',
    },
    cardImg: {
        [theme.breakpoints.up('sm')]: {
            height: '190px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '100px'
        },
        borderRadius: '8px 8px 8px 0px',
    },
    cardText1: {
        padding: 12,
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 8,
        },
    },
}));
