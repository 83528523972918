import { 
    START_GET_RESOURCES, 
    START_SHOW_RESOURCE, 
    START_VIEW_RESOURCE, 
    START_STORE_RESOURCE, 
    START_UPDATE_RESOURCE, 
    START_DELETE_RESOURCE, 
    START_TOGGLE_FORM_EDIT_RESOURCES, 
    START_TOGGLE_FORM_CREATE_RESOURCES, 
    START_TOGGLE_FORM_VIEW_RESOURCES, 
    START_FILTER_RESOURCES,
    START_ASSIGN_TAG_RESOURCE,
    START_DELETE_TAG_RESOURCE,
    START_ASSIGN_HIGHLIGHT_RESOURCE,
    START_GET_HOME_RESOURCES,
    START_MASS_ASSIGNMENT_RESOURCES
} from '../types';

export const getResources = payload => ({
    type: START_GET_RESOURCES,
    payload
})

export const showResource = payload => ({
    type: START_SHOW_RESOURCE,
    payload
})

export const viewResource = payload => ({
    type: START_VIEW_RESOURCE,
    payload
})

export const storeResource = payload => ({
    type: START_STORE_RESOURCE,
    payload
})

export const updateResource = payload => ({
    type: START_UPDATE_RESOURCE,
    payload
})

export const deleteResource = payload => ({
    type: START_DELETE_RESOURCE,
    payload
})

export const toggleFormEditResources = () => ({
    type: START_TOGGLE_FORM_EDIT_RESOURCES
})

export const toggleFormCreateResources = () => ({
    type: START_TOGGLE_FORM_CREATE_RESOURCES
})

export const toggleFormViewResources = () => ({
    type: START_TOGGLE_FORM_VIEW_RESOURCES
})

export const filterResources = payload => ({
    type: START_FILTER_RESOURCES,
    payload
})

export const assignTagResource = payload => ({
    type: START_ASSIGN_TAG_RESOURCE,
    payload
})

export const deleteTagResource = payload => ({
    type: START_DELETE_TAG_RESOURCE,
    payload
})

export const assignHighlight = payload => ({
    type: START_ASSIGN_HIGHLIGHT_RESOURCE,
    payload
})

export const getHomeResources = payload => ({
    type: START_GET_HOME_RESOURCES,
    payload
})

export const storeMassAssignment = payload => ({
    type: START_MASS_ASSIGNMENT_RESOURCES,
    payload
})