// React
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";

// Material UI
import { Container, Box, Button, Grid, Typography } from '@material-ui/core';
import { QueryBuilderRounded, PlayCircleOutline } from "@material-ui/icons/";

// Custom components
import CoursesModal from "../../components/courses/CoursesModal";
import DetailHeroPanel from '../../components/layouts/common/DetailHeroPanel';
import ShareIcon from '../../components/common/ShareIcon';
import DetailInfoPanel from '../../components/layouts/common/DetailInfoPanel';
import LessonBlock from '../../components/lessons/LessonBlock';
import LessonEditorText from "../../components/lessons/LessonEditorText";
import LessonDetail from '../../components/lessons/LessonDetail';
import VerifyAccessRoute from '../../components/common/VerifyAccessRoute';
import ButtonBack from '../../components/common/ButtonBack';
import LinearProgress from '../../components/courses/LinearProgress';
import CustomSnackBar from "../../components/common/admin/CustomSnackBar";
import ScreenLoading from "../../components/common/ScreenLoading";
import AdminDetailInfo from '../../components/common/admin/AdminDetailInfo';
import NotFound from '../../pages/NotFound';

// Custom Hooks
import useUserDisplaytime from '../../hooks/useUserDisplaytime';
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';
import { showCourseBySlug, courseLessonStep, toggleFormCourse } from '../../redux/actions/courses';
import { setViewLesson, toggleFormLesson, showLesson } from '../../redux/actions/lessons';
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import CardStyles from '../../styles/components/courses/CoursesStyles';
import VipStyles from '../../styles/components/common/VipStyles';

// Types
interface ParamTypes {
    brandId: string,
    slug: string
}

function CourseDetail() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);

    const location = useLocation();

    // Styles
    const classes = CardStyles();
    const vipclasses = VipStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { brandId, slug } = useParams<ParamTypes>();
    const { course_selected, loading, error, msg, showForm } = useSelector((state: IRootState) => state.course);
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { lesson_selected, showForm: showLessonForm } = useSelector((state: IRootState) => state.lesson);
    const [favoriteState, setFavoriteState] = useState<boolean>(false);

    // Send track of user
    useUserDisplaytime({ foreign: course_selected });
    const { handleViewGeneralSummaryReport } = useGeneralSummaryReport([], 0, 3);

    // Ready Page
    useEffect(() => {
        dispatch(showCourseBySlug({ brandId, slug }));
        dispatch(setViewLesson(null));
    }, [brandId, slug]);

    useEffect(() => {
    }, [favoriteState]);

    useEffect(() => {
        if (course_selected != null && course_selected.slug === slug) {
            if (course_selected.lessons.length === 1 && course_selected.visibility !== 1) {
                dispatch(setViewLesson(course_selected.lessons[0]));
            }
            handleViewGeneralSummaryReport(course_selected.id)

            // Set favorite state
            setFavoriteState(Boolean(course_selected.favoriteId));
        }
    }, [course_selected]);

    // Functions
    const handleChangeLesson = (order: number) => {
        const lessonOrder = course_selected.lessons.find((lesson: any) => lesson.order === order);
        if (lessonOrder !== undefined) {
            dispatch(setViewLesson(lessonOrder));
        }

        if (authUser !== null) {
            dispatch(courseLessonStep({
                course_id: course_selected.id,
                lesson_order: order
            }));
        }
    };

    const handleFavoriteClick = (course_id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id: course_id,
                type: 'course'
            }));
        } else {
            dispatch(unsetFavorite({
                id: course_id,
                type: 'course',
            }));
        }
        setFavoriteState(state);
    };

    let isLesson = lesson_selected != null && showLessonForm == false
    let infoDetail = (course_selected != null) && {
        "item": [
            {
                "id": 0,
                "type": "tag",
                "title": "Tags",
                "color": "light",
                "info": `${course_selected.tags.map((tag: any) => { return tag.name.toUpperCase() }).join(', ')}`,
            },
            {
                "id": 1,
                "type": "date",
                "title": `${t("admin.people.created-date")}`,
                "color": "grey6",
                "info": `${moment(course_selected.created_at).format('LL')}`,
            },
        ]
    }

    {/* Validate if course exist, status is enable and if is admin site */ }
    if (course_selected !== null && course_selected.status === 0 && !(location.pathname.includes('admin') || location.pathname === '/escritorio')) {
        return (
            <NotFound showLogo={false} />
        )
    }

    return (
        <>
            {course_selected != null &&
                <Container maxWidth="lg">

                    {showForm && authUser !== null && (
                        <CoursesModal />
                    )}

                    {lesson_selected === null && showLessonForm === false ?
                        <ButtonBack data-coursedetail="goback" dataName="course" />
                        :
                        <ButtonBack data-lessondetail="goback" goBack={false} customFunction={setViewLesson(null)} dataName="lesson" />
                    }

                    <Grid container spacing={5} >

                        <Grid item xs={12} md={8}>

                            <VerifyAccessRoute visibility={course_selected.visibility} />

                            {lesson_selected !== null && showLessonForm === false ?
                                <LessonDetail lesson={lesson_selected} course={course_selected} />
                                :
                                <>

                                    {location.pathname.includes('admin') &&
                                        <AdminDetailInfo resourceSelected={course_selected} />
                                    }

                                    {showLessonForm && (
                                        <LessonEditorText courseId={course_selected.id} courseTitle={course_selected.title} courseLastOrder={course_selected.lastOrder} />
                                    )}

                                    <Grid container item spacing={3} data-testid="course-detail-container">
                                        <Grid xs={12} md={6} item>
                                            <Box
                                                height={286} borderRadius={8}
                                                style={{
                                                    backgroundImage: `url(${course_selected.image_url}?${new Date().getHours()})`, backgroundRepeat: "no-repeat",
                                                    backgroundSize: "cover", backgroundPosition: "center center",
                                                }} />
                                        </Grid>

                                        <Grid xs={12} md={6} item>

                                            {/* {course_selected.description !== 'null' &&  <Typography>{course_selected.description}</Typography> } */}

                                            {course_selected.leaded !== 'null' &&
                                                <Box pt={1}>
                                                    <Typography variant="subtitle1" color="textSecondary">¿A quién va dirijido?</Typography>
                                                    <Typography>{course_selected.leaded}</Typography>
                                                </Box>
                                            }

                                            {course_selected.requirements !== 'null' &&
                                                <Box pt={1}>
                                                    <Typography variant="subtitle1" color="textSecondary">{t("requirements")}</Typography>
                                                    <Typography>{course_selected.requirements}</Typography>
                                                </Box>
                                            }

                                            {course_selected.duration !== 0 &&
                                                <Box display="flex" alignItems="center" gridGap={8} color="primary" mb={2} mt={2}>
                                                    <QueryBuilderRounded />
                                                    <Typography color="inherit">{Math.round(course_selected.duration / 60)} {t("hours")}</Typography>
                                                </Box>
                                            }

                                            {course_selected.progress && (authUser != null && (authUser.vip_lines.includes(course_selected.line_id) || (course_selected.line_id === null && authUser.vip_brands.includes(course_selected.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))) &&
                                                <>
                                                    <Typography variant="body2">{t("admin.courses.course-progress")}: {course_selected.progress.total_lessons_viewed}/{course_selected.lessons.length} </Typography>

                                                    <LinearProgress progress={course_selected.progress ? course_selected.progress.value : 0} vip={course_selected.visibility === 1} />

                                                    {(course_selected.lessons.length > course_selected.progress.total_lessons_viewed) &&
                                                        <Box mt={2}>
                                                            <Button
                                                                onClick={() => handleChangeLesson(course_selected.progress.total_lessons_viewed + 1)}
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth
                                                                className={(course_selected.visibility === 1) ? vipclasses.button : ''}
                                                                startIcon={<PlayCircleOutline />}>
                                                                {t("continue")}
                                                            </Button>
                                                        </Box>
                                                    }
                                                </>
                                            }

                                        </Grid>

                                    </Grid>

                                    {course_selected.lessons.map((lesson: any) => (
                                        <LessonBlock
                                            key={lesson.id}
                                            lesson={lesson}
                                            vipContent={(course_selected.visibility === 1)}
                                            hasaccess={course_selected.visibility === 1 && authUser != null && (authUser.vip_lines.includes(course_selected.line_id) || (course_selected.line_id === null && authUser.vip_brands.includes(course_selected.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                        />
                                    ))}
                                </>
                            }
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <DetailHeroPanel
                                visibility={course_selected.visibility}
                                brand={course_selected.brand !== null && course_selected.brand.name}
                                title={isLesson ? lesson_selected.title : course_selected.title}
                                author={course_selected.responsibles.length > 0 ? course_selected.responsibles[0].name : null}
                                statusC={<>
                                    {(((isLesson) ? lesson_selected.status : course_selected.status) === 0) && `${t('inactive')}`}
                                    {(((isLesson) ? lesson_selected.status : course_selected.status) === 1) && `${t('active')}`}
                                    {(((isLesson) ? lesson_selected.status : course_selected.status) === 2) && `${t('draft')}`}
                                </>}
                                description={course_selected.description !== 'null' ? course_selected.description : null}
                                // Actions
                                edit={() => { isLesson ? dispatch(showLesson(lesson_selected.id)) : dispatch(toggleFormCourse()) }}
                                add={() => { dispatch(toggleFormLesson()) }}
                                onClickFavorite={handleFavoriteClick(course_selected.id)}
                                favoriteState={favoriteState}
                                share={<ShareIcon
                                    meta={true}
                                    visibility={course_selected.visibility}
                                    title={course_selected.title}
                                    description={course_selected.description || ''}
                                    link={window.location.href}
                                    multimedia={`${course_selected.image_url}?${new Date().getTime()}`}
                                    component="buttonTextGreen"
                                    type="course"
                                    buttonTextGreen={t("share")}
                                />}
                                data={{ add: 'addlesson', edit: 'editcourse' }}
                            />
                            <DetailInfoPanel attributes={infoDetail} />
                        </Grid>

                    </Grid>
                </Container>
            }

            <CustomSnackBar errors={[error]} msgs={[msg]} />
            <ScreenLoading loadings={[loading]} />
        </>
    );
}

export default CourseDetail;

