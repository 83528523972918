import { MassAssignmentInputList } from '../../utils/MassAssignmentInputList'

interface DynamicInputComponentProps {
    inputName: string
    data: any
    setData: (data: any) => {}
}

export const DynamicInputComponent = ({
    inputName,
    data,
    setData,
}: DynamicInputComponentProps) => {
    const handleInputChange = (inputName: string, value: any) => {
        if (inputName === 'resourcetypeId')
            setData({ ...data, resourcetype: value })
        else setData({ ...data, [inputName]: value })
    }

    const SpecificComponent = MassAssignmentInputList[inputName].component
    return (
        <>
            {inputName === 'line' ? (
                <SpecificComponent
                    value={data[inputName]}
                    handleInputChange={handleInputChange}
                    brand={data.brand}
                />
            ) : ['town','postalcode'].includes(inputName) ? (
                <SpecificComponent
                    value={data[inputName]}
                    handleInputChange={handleInputChange}
                    province={data.province}
                />
            ) : inputName === 'province' ? (
                    <SpecificComponent
                        value={data[inputName]}
                        handleInputChange={handleInputChange}
                        country={data.country}
                    />
            ) : (
                <SpecificComponent
                    value={data[inputName]}
                    handleInputChange={handleInputChange}
                />
            )}
        </>
    )
}