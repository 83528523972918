// Material UI
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const HiddenMobile = styled(Box)(({
        theme
    }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }));

export default HiddenMobile;