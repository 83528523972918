import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_FAVORITES, SUCCESS_GET_FAVORITES, ERROR_GET_FAVORITES,
    START_SET_FAVORITE, SUCCESS_SET_FAVORITE, ERROR_SET_FAVORITE,
    START_UNSET_FAVORITE, SUCCESS_UNSET_FAVORITE, ERROR_UNSET_FAVORITE
} from '../types';
import { apiCall } from '../config/axios';

function* getFavorites() {
    try {
        const response = yield call(apiCall, 'get', '/favorites/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_FAVORITES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_FAVORITES, payload: error.message })
    }
}

function* setFavorite({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/favorites/set', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SET_FAVORITE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SET_FAVORITE, payload: error.message })
    }
}

function* unsetFavorite({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/favorites/unset', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_UNSET_FAVORITE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_UNSET_FAVORITE, payload: error.message })
    }
}

export default function* favorites() {
    yield takeLatest(START_GET_FAVORITES, getFavorites);
    yield takeLatest(START_SET_FAVORITE, setFavorite);
    yield takeLatest(START_UNSET_FAVORITE, unsetFavorite);
}