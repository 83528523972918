import { put, takeLatest } from 'redux-saga/effects';
import {
    START_SHOW_MODAL, SUCCESS_SHOW_MODAL, ERROR_SHOW_MODAL,
    START_HIDE_MODAL, SUCCESS_HIDE_MODAL, ERROR_HIDE_MODAL,
    START_ASSIGN_ACTION_MODAL, SUCCESS_ASSIGN_ACTION_MODAL, ERROR_ASSIGN_ACTION_MODAL
} from '../types';

function* showModal({ payload }) {
    try {
        yield put({ type: SUCCESS_SHOW_MODAL, payload })
    } catch (error) {
        yield put({ type: ERROR_SHOW_MODAL, payload: error.message })
    }
}

function* hideModal() {
    try {
        yield put({ type: SUCCESS_HIDE_MODAL, payload: {} })
    } catch (error) {
        yield put({ type: ERROR_HIDE_MODAL, payload: error.message })
    }
}

function* assignActionModal({payload}) {
    try {
        yield put({ type: SUCCESS_ASSIGN_ACTION_MODAL, payload })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_ACTION_MODAL, payload: error.message })
    }
}

export default function* terms() {
    yield takeLatest(START_SHOW_MODAL, showModal);
    yield takeLatest(START_HIDE_MODAL, hideModal);
    yield takeLatest(START_ASSIGN_ACTION_MODAL, assignActionModal);
}