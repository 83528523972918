
// React
import { useState } from 'react';
import { useParams } from "react-router-dom";

// Material UI
import { Box, Container, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core';

//Custom components
import CustomBreadcrumb from '../../components/helpcenter/CustomBreadcrumb';
import useSectionStart, { SectionData, SectionContent } from '../../hooks/helpcenter/useSectionStart';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import HelpCenterStyles from '../../styles/components/HelpCenterStyles';
import ButtonBack from '../../components/common/ButtonBack';

// Types
interface ParamTypes {
    section: string,
    questionid: string
}

const SectionStartQuestion = () => {

    // Translation
    const { t } = useTranslation(["helpcenter"]);

    const { section, questionid } = useParams<ParamTypes>();
    const [iQuestion, setIQuestion] = useState<number>(parseInt(questionid));


    const classes = HelpCenterStyles();

    const handleInfo = (newValue: number) => {
        setIQuestion(newValue);
    };

    const data: SectionData = useSectionStart();
    let dataSection: SectionContent = {
        title: '',
        content: []
    };
    data.client.filter((sections: any) => {
        if (sections[section] !== undefined) {
            dataSection = sections[section];
        }
    });

    return (
        <Container style={{ padding: 0, marginTop: 50 }} maxWidth={"xl"}>
            <CustomBreadcrumb />
            <Container maxWidth={"md"}>
                <Box display="flex" flexWrap="wrap" mt={7} mb={15} className={classes.detailContainer}>
                    <Box className={classes.navContent}>
                        {data.client.filter((sections: any) => sections[section] !== undefined).map((sections: any) => (
                            <div key={sections[section].title}>
                                <Typography variant="subtitle1">{sections[section].title}</Typography>
                                <List component="nav">
                                    {sections[section].content.map((section: any, index: number) => {
                                        return (
                                            <div key={section.question}>
                                                <ListItem button selected={index === iQuestion} onClick={() => handleInfo(index)}>
                                                    <ListItemText primary={section.question} />
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        )
                                    })}
                                </List>
                            </div>
                        ))}
                    </Box>

                    <Box pb={4} className={classes.textContent}>
                        <Box mb={3}>
                            <ButtonBack />
                        </Box>

                        {data.client.filter((sections: any) => sections[section] !== undefined).map((sections: any) => (
                            <div key={sections[section].title}>
                                {sections[section].content.filter((section: any, index: number) => index === iQuestion).map((section: any, index: number) => (
                                    <div key={section.question}>
                                        <Box mb={7}>
                                            <Typography variant="h4" style={{ fontWeight: 300 }}>{section.question}</Typography>
                                        </Box>

                                        {section.answer.map((answer: any, index2: number) => (
                                            <Typography dangerouslySetInnerHTML={{
                                                __html: answer
                                            }} />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Box>
                </Box>
            </Container>
        </Container>

    );
}

export default SectionStartQuestion;