// React
import {
    useState,
    useEffect,
    ChangeEvent,
    forwardRef,
    ReactElement,
    Ref,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Typography,
    Grid,
    FormHelperText,
    Slide,
    DialogActions,
    Tabs,
    Tab,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { CloseRounded } from '@material-ui/icons/'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Custom components
import TabPanel from '../common/admin/TabPanel'
import InputCountry from '../common/admin/modal/InputCountry'
import { BrandAutocomplete, LineAutocomplete, RoleAutocomplete, LanguageMultiAutocomplete, Dropzone, CancelButton, SaveButton } from '../common/forms'

// Redux Actions
import { getUserbrands } from '../../redux/actions/brands'
import { createCourse, toggleFormCourse } from '../../redux/actions/courses'
import { getCoursetypes } from '../../redux/actions/coursetypes'
import {
    getCountries,
    getFilteredCountries,
} from '../../redux/actions/countries'
import { getLanguages } from '../../redux/actions/languages'
import { showTrainingResponsible } from '../../redux/actions/users'
import { getRoles } from '../../redux/actions/roles'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

// Styles
import FormasStyles from '../../styles/components/common/forms/FormsStyles'
import Brand from '../../interfaces/brand/BrandInterface'
import Line from '../../interfaces/brand/LineInterface'

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

// Types
interface ICourse {
    id: null | number
    brand: null | Brand
    line: null | Line
    coursetype: null | { id: number, name: string }
    title: string
    duration: number
    description: string
    leaded: string
    requirements: string
    responsibles: number[]
    file_image: null | any
    video_url: string
    visibility: number
    highlight: number
    status: number
    countries: number[]
    languages: number[]
    role: any
}

type FormElement = ChangeEvent<HTMLInputElement>

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

function CoursesModal() {
    // Translate
    const { t } = useTranslation(['global'])
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const formClasses = FormasStyles()

    /* Global Constants */
    // const highlight = [{ id: 0, name: 'Top' }, { id: 1, name: 'Home' }, { id: 2, name: 'Top y Home' }];
    // const level = [{ id: 0, name: 'Básico' }, { id: 1, name: 'Intermedio' }, { id: 2, name: 'Avanzado' }];
    const STATUS = [
        { id: 0, name: 'Inactivo' },
        { id: 1, name: 'Activo' },
        { id: 2, name: 'Borrador' },
    ]
    const VISIBILITY = [
        { id: 0, name: t('private') },
        { id: 1, name: 'VIP' },
        { id: 2, name: t('public') },
    ]

    //Redux Hooks
    const dispatch = useDispatch()
    const { course_selected, showForm } = useSelector(
        (state: IRootState) => state.course
    )
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { userBrands } = useSelector((state: IRootState) => state.brand)
    const { coursetypes } = useSelector((state: IRootState) => state.coursetype)
    const { countries } = useSelector((state: IRootState) => state.country)
    const { languages } = useSelector((state: IRootState) => state.language)
    const { users } = useSelector((state: IRootState) => state.user)
    const { roles } = useSelector((state: IRootState) => state.role)
    const { isValidForm, errorForm, errorTab, msgForm } = useSelector(
        (state: IRootState) => state.validator
    )

    // Course state
    const [courseState, setCourseState] = useState<ICourse>({
        id: null,
        brand: null,
        line: null,
        coursetype: null,
        title: '',
        duration: 0,
        description: '',
        leaded: '',
        requirements: '',
        responsibles: [],
        file_image: null,
        video_url: '',
        highlight: 0,
        visibility: 0,
        status: 2,
        countries: [],
        languages: [],
        role: [],
    })
    //Tabs State
    const [currentTab, setCurrentTab] = useState(0)

    // Ready Page
    useEffect(() => {
        dispatch(showTrainingResponsible())
        if (!countries.length) {
            if (authUser.role_id === 1) dispatch(getCountries())
            else dispatch(getFilteredCountries())
        }
        if (!userBrands.length) dispatch(getUserbrands());
        if (!coursetypes.length) dispatch(getCoursetypes())
        if (!languages.length) dispatch(getLanguages())
        if (!roles.length) dispatch(getRoles())
    }, [])

    useEffect(() => {
        if (course_selected != null) {
            setCourseState({
                id: course_selected.id,
                brand: course_selected.brand,
                line: course_selected.line,
                coursetype: course_selected.coursetype,
                title: course_selected.title,
                duration: course_selected.duration,
                description: course_selected.description,
                leaded: course_selected.leaded,
                requirements: course_selected.requirements,
                responsibles:
                    course_selected.responsibles.length > 0
                        ? course_selected.responsibles.map(
                            (responsible: any) => {
                                return responsible.id
                            }
                        )
                        : course_selected.user_id !== null
                            ? [course_selected.user_id]
                            : [],
                file_image: null,
                video_url: course_selected.video_url,
                highlight: course_selected.highlight,
                visibility: course_selected.visibility,
                status: course_selected.status,
                countries: course_selected.countries.map(
                    (country: any) => country.id
                ),
                languages: course_selected.languages.map(
                    (language: any) => language.id
                ),
                role: course_selected.role,
            })
        }
    }, [course_selected])

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator())
            dispatch(createCourse(courseState))
            handleCloseModalCourse()
        }
    }, [isValidForm])

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue)
    }

    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value
        setCourseState({ ...courseState, [inputName]: value })
    }

    const handleMultiAutoCompleteChange =
        (inputName: string) => (event: any, value: any) => {
            let optionsMulti = []
            if (value !== null) {
                for (let i = 0; i < value.length; i++) {
                    optionsMulti.push(value[i].id)
                }
            }
            setCourseState({ ...courseState, [inputName]: optionsMulti })
        }

    const handleState = (inputName: string, value: any) => {
        setCourseState({ ...courseState, [inputName]: value })
    }

    const handleSubmit = () => {
        dispatch(
            formValidator({
                title: [courseState.title, 'required|max:255|min:2'],
                duration: [
                    courseState.duration,
                    'required|numeric|min:0',
                    'addOn',
                ],
                brands: [courseState.brand, 'required'],
                countries: [courseState.countries, 'notempty', 'general'],
                languages: [courseState.languages, 'notempty', 'general'],
                responsibles: [courseState.responsibles, 'notempty', 'general'],
                role: [courseState.role, 'notempty', 'general'],
            })
        )
    }

    const handleCloseModalCourse = () => {
        dispatch(resetFormValidator())
        dispatch(toggleFormCourse())
    }

    return (
        <>
            <Dialog
                id='dialog-course-container'
                open={showForm}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                TransitionComponent={Transition}
                className={formClasses.containerForm}
                onClose={handleCloseModalCourse}
                aria-describedby="alert-dialog-description"
                aria-labelledby="dialog-course-title"
            >
                <DialogTitle id="dialog-course-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {courseState.id !== null ? t('edit') : t('create')}{' '}
                            {t('course')}
                        </Typography>
                        <Button
                            onClick={handleCloseModalCourse}
                            color="primary"
                            variant="text"
                        >
                            <CloseRounded />
                        </Button>
                    </Grid>
                </DialogTitle>

                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={courseState.brand != null ? 6 : 8}
                        >
                            <TextField
                                id="txt-course-name"
                                label={t('name')}
                                fullWidth
                                required
                                value={courseState.title}
                                error={errorForm.title}
                                helperText={msgForm.title}
                                onChange={handleInputChange('title')}
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={courseState.brand != null ? 3 : 4}
                        >
                            <BrandAutocomplete value={courseState.brand} handleInputChange={handleState} props={{ required: true }} />
                        </Grid>

                        {courseState.brand != null && (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={courseState.brand != null ? 3 : 4}
                            >
                                <LineAutocomplete value={courseState.line} brand={courseState.brand} handleInputChange={handleState} />
                            </Grid>
                        )}
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}>
                            <Tabs
                                id="tabs-modal-course"
                                value={currentTab}
                                indicatorColor="secondary"
                                textColor="primary"
                                onChange={handleChangeTab}
                                className={formClasses.tapForm}
                                aria-label="disabled tabs example"
                            >
                                <Tab
                                    data-testid="course-modal-tab-general"
                                    label={t('general')}
                                    {...a11yProps(0)}
                                    className={
                                        errorTab.general ? formClasses.errorTab : ''
                                    }
                                />
                                <Tab
                                    label={t('add-ons')}
                                    {...a11yProps(1)}
                                    className={
                                        errorTab.addOn ? formClasses.errorTab : ''
                                    }
                                />
                            </Tabs>
                        </Grid>
                    </Grid>

                    <TabPanel value={currentTab} index={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <RoleAutocomplete
                                    value={courseState.role}
                                    handleInputChange={handleState}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <InputCountry
                                    value={courseState.countries}
                                    handleInputChange={handleState}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <LanguageMultiAutocomplete
                                    value={courseState.languages}
                                    handleInputChange={handleState}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="autocomplete-course-status"
                                    data-createcourse="status"
                                    disableClearable
                                    value={STATUS.find(
                                        (stt: any) =>
                                            stt.id === courseState.status
                                    )}
                                    onChange={(_, value) => handleState('status', value.id)}
                                    options={STATUS}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('status')}
                                            variant="filled"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="autocomplete-course-visibility"
                                    data-createcourse="visibility"
                                    disableClearable
                                    value={VISIBILITY.find(
                                        (vsb: any) =>
                                            vsb.id === courseState.visibility
                                    )}
                                    onChange={(_, value) => handleState('visibility', value.id)}
                                    options={VISIBILITY}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('visibility')}
                                            variant="filled"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="autocomplete-course-responsible"
                                    data-testid="course-modal-input-responsible"
                                    fullWidth
                                    multiple
                                    value={users.filter((user: any) =>
                                        courseState.responsibles.includes(
                                            user.id
                                        )
                                    )}
                                    onChange={handleMultiAutoCompleteChange(
                                        'responsibles'
                                    )}
                                    options={users}
                                    getOptionLabel={(option: any) =>
                                        option.id !== undefined
                                            ? option.name
                                            : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('trainers')}
                                            error={errorForm.responsibles}
                                            helperText={msgForm.responsibles}
                                            variant="filled"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="autocomplete-course-categories"
                                    data-createcourse="categories"
                                    value={courseState.coursetype}
                                    onChange={(event, value) => handleState('coursetype', value)}
                                    options={coursetypes}
                                    getOptionLabel={(option: any) =>
                                        option.id !== undefined
                                            ? option.name
                                            : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('category')}
                                            variant="filled"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    id="txt-course-requirements"
                                    label={t('requirements')}
                                    fullWidth
                                    margin="normal"
                                    value={courseState.requirements}
                                    error={errorForm.requirements}
                                    helperText={msgForm.requirements}
                                    onChange={handleInputChange('requirements')}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Dropzone
                                    name={'file_image'}
                                    handleInputChange={handleState}
                                    dir={"courses"}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={currentTab} index={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="txt-course-duration"
                                    label={t('duration') + '(Min)'}
                                    type="number"
                                    fullWidth
                                    required
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            'data-createcourse': 'duration',
                                        },
                                    }}
                                    // inputProps={{ 'data-createcourse': 'duration' }}
                                    value={courseState.duration}
                                    error={errorForm.duration}
                                    helperText={msgForm.duration}
                                    onChange={handleInputChange('duration')}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                />
                                <FormHelperText error={errorForm.duration}>
                                    {msgForm.duration}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="txt-course-description"
                                    label={t('description')}
                                    fullWidth
                                    margin="normal"
                                    value={courseState.description}
                                    error={errorForm.description}
                                    helperText={msgForm.description}
                                    onChange={handleInputChange('description')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        'data-createcourse': 'description',
                                    }}
                                    variant="filled"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="txt-course-audience"
                                    label={t('audience')}
                                    fullWidth
                                    margin="normal"
                                    value={courseState.leaded}
                                    error={errorForm.leaded}
                                    helperText={msgForm.leaded}
                                    onChange={handleInputChange('leaded')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        'data-createcourse': 'audience',
                                    }}
                                    variant="filled"
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </DialogContent>
                <DialogActions
                    className={
                        !isValidForm && Object.keys(msgForm).length > 0
                            ? formClasses.errorDialogActions
                            : ''
                    }
                >
                    <CancelButton onClick={handleCloseModalCourse}>
                        {t('cancel')}
                    </CancelButton>
                    <SaveButton
                        id="btn-save-course"
                        onClick={handleSubmit}
                    >
                        {t('save')}
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CoursesModal
