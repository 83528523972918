import { put, call, takeLatest } from 'redux-saga/effects';
import { START_GET_MENUS, SUCCESS_GET_MENUS, ERROR_GET_MENUS } from '../types';
import { apiCall } from '../config/axios';

function* getMenus() {
    try{
        const response = yield call(apiCall, 'get', '/menus/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_MENUS, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_GET_MENUS, payload: error.message})
    }
}

export default function* menus() {
    yield takeLatest(START_GET_MENUS, getMenus);
}