// React
import { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// Material UI
import { Container, DialogActions, Dialog, DialogTitle, DialogContent, Button, Box, Menu, Grid, MenuItem, IconButton, Typography, Tooltip, Fade } from '@material-ui/core';
import { CloseRounded, Delete, InfoOutlined, MoreVert } from '@material-ui/icons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../../config/table';

// Commons components
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../../components/common/ScreenLoading';
import SliderModal from '../../../components/sliders/SliderModal';
import CountriesFilter from '../../../components/common/admin/CountryFilter';
import CustomChip from '../../../components/common/admin/CustomChip';
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import ScrollSubHeader from '../../../components/common/header/ScrollSubHeader';
import TypeFilter from '../../../components/sliders/TypeFilter';
import AdminContentFilter from '../../../components/layouts/filters/AdminContentFilter';
import VisibilityIcon from '../../../components/common/admin/VisibilityIcon';
import NotFilter from '../../NotFilter';
import { CancelButton, DeleteButton } from '../../../components/common/forms';

// Redux Actions
import { getFilteredSliders, showSlider, deleteSlider, toggleFormSlider } from '../../../redux/actions/sliders';
import { IRootState } from '../../../redux/types'

// Libraries
import moment from 'moment';
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Styles
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles';
import FormsStyles from '../../../styles/components/common/forms/FormsStyles';

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

interface IFilter {
    id: number,
    family_id: null | number,
    category_id: null | number,
    brand_id: null | number,
    line_id: null | number,
    countries: number[]
    internaltype: number,
    position: number,
    modal_title: string
}

function Sliders({ history }: RouteComponentProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // styles
    const tableclass = TableScreenStyles();
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { sliders, reload, showForm, error, msg, loading } = useSelector((state: IRootState) => state.slider);
    const { globalFamilyId, globalBrandId, globalLineId, globalCountriesId } = useSelector((state: IRootState) => state.global);

    // Component States
    const [openDeleteSlider, setOpenDeleteSlider] = useState(false);
    const [sliderSelected, setSliderSelected] = useState(false);
    // Filter State
    const [filters, setFilters] = useState<IFilter>({
        id: 1,
        family_id: null,
        category_id: null,
        brand_id: null,
        line_id: null,
        countries: [],
        internaltype: 1,
        position: 1,
        modal_title: t('slider')
    });
    // Table Menu State
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(-1);

    // Variables Filter by
    const status = [{ id: 0, name: 'Activo' }, { id: 1, name: 'Inactivo' }];
    const visibility = [{ id: 0, name: t('close') }, { id: 1, name: 'VIP' }, { id: 2, name: 'Pago' }];
    const optionsData = [{ data: status, code: 'status', column: 'status' }, { data: visibility, code: 'visibility', column: 'visibility' }];

    useEffect(() => {
        if (reload) {
            dispatch(getFilteredSliders({
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                line_id: globalLineId,
                countries: globalCountriesId,
                internaltype: filters.internaltype,
                position: filters.position
            }));
        }
    }, [reload]);

    useEffect(() => {
        setFilters({
            ...filters,
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            countries: globalCountriesId
        });
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountriesId]);

    useEffect(() => {
        if (filters.countries.length > 0) {
            dispatch(getFilteredSliders({
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                line_id: globalLineId,
                countries: globalCountriesId,
                internaltype: filters.internaltype,
                position: filters.position
            }));
        }
    }, [filters]);

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const handleChangeFilter = (id: number, internaltype: number, position: number, title: string) => {
        setFilters({ ...filters, id, internaltype, position, modal_title: title });
    }

    const handleClickSetOptions = (options: any) => {
        dispatch(getFilteredSliders({
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            countries: globalCountriesId,
            internaltype: filters.internaltype,
            position: filters.position,
            filterBy: options
        }));
    };

    const columns: any = [
        {
            title: t("title"), field: "title", render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        {(() => {
                            switch (rowData.status) {
                                case 0:
                                    return (<CustomChip size="small" color={'primary'} />)
                                case 1:
                                    return (<CustomChip size="small" color={'secondary'} />)
                                case 2:
                                    return (<CustomChip size="small" color={'grey'} />)

                                default:
                                    return (<CustomChip size="small" color={'grey'} />)
                            }
                        })()}
                        <Tooltip placement="bottom-start" TransitionComponent={Fade}
                            TransitionProps={{ timeout: 400 }} title={rowData.title}>
                            <Typography className={tableclass.nameResource}>
                                {rowData.title}
                            </Typography>
                        </Tooltip>
                        <VisibilityIcon id={rowData.visibility} />
                    </Box>)
            }
        },
        { title: t("position"), field: 'order' },
        { title: t("brand"), field: 'brand.name', hidden: isMobile, type: string },
        {
            title: t("language"), field: "language", render: (rowData: any) => (
                rowData.languages.map((language: any) => { return language.code.toUpperCase() }).join(' - ')
            ), hidden: isMobile, type: string
        },
        { title: t("rol"), field: 'role.name', hidden: isMobile, type: string },
        {
            title: t("initial-date"), field: 'date_ini', type: string, hidden: isMobile, render: (rowData: any) => {
                if (rowData.date_ini != null) {
                    return (<> {`${moment(rowData.date_ini).format('MMM - DD')}`} </>)
                }
            }
        },
        {
            title: t("final-date"), field: 'date_end', type: string, hidden: isMobile, render: (rowData: any) => {
                if (rowData.date_end != null) {
                    return (<> {`${moment(rowData.date_end).format('MMM - DD')}`} </>)
                }
            }
        },
        {
            title: '', field: '', render: (rowData: any) =>
                <>

                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}>
                        <MenuItem onClick={() => history.push(`sliders/${rowData.id}`)}>{t("view")}</MenuItem>
                        <MenuItem onClick={() => { dispatch(showSlider(rowData.id)) }}>{t("edit")}</MenuItem>
                        <MenuItem onClick={() => { setOpenDeleteSlider(!openDeleteSlider); setSliderSelected(rowData.id) }}>{t("delete")}</MenuItem>
                    </Menu>
                </>
        }
    ];

    return (

        <>
            <Header title="Slider" />
            <SubHeader>
                <ScrollSubHeader>
                    <TypeFilter
                        id={1}
                        type={t("Sliders")}
                        categories={[]}
                        active={1 === filters.id}
                        handleChangeFilter={handleChangeFilter}
                    />
                    <TypeFilter
                        id={2}
                        type={t("link")}
                        categories={[{ id: 3, name: t("top") }, { id: 4, name: t("leftBottom") }, { id: 5, name: t("rightBottom") }]}
                        active={[2, 3, 4, 5].includes(filters.id)}
                        filterCategoryId={filters.id}
                        handleChangeFilter={handleChangeFilter}
                    />
                    <TypeFilter
                        id={6}
                        type={t("internal")}
                        categories={[]}
                        active={6 === filters.id}
                        handleChangeFilter={handleChangeFilter}
                    />
                    <ArrowForwardIcon />
                </ScrollSubHeader>
            </SubHeader>

            <Container maxWidth="lg">

                <Box mt={isMobile ? 2 : 6} gridGap={16} display="flex" flexDirection={isMobile ? 'column-reverse' : 'column'}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h5" align="center">{t("all")}</Typography>
                        <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow
                            title={
                                <Box>
                                    <Typography variant="body2">{t('status')}:</Typography>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'primary'} />
                                        <Typography variant="caption">{t('active')}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'secondary'} />
                                        <Typography variant="caption">{t('inactive')}</Typography>
                                    </Box>
                                </Box>
                            }>
                            <IconButton>
                                <InfoOutlined htmlColor={'#8F99A3'} fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box >
                        <Grid container justify="space-between" spacing={1}>
                            <Grid item xs={5} sm={6} className={tableclass.actionsResponsive}>
                                <Button variant="outlined" color="secondary" onClick={() => dispatch(toggleFormSlider())}>
                                    {t('create')} {filters.modal_title}
                                </Button>
                            </Grid>
                            <Grid item xs={7} sm={6} className={tableclass.actionsResponsiveRight}>
                                <AdminContentFilter data={optionsData} handleClick={handleClickSetOptions} />
                                <CountriesFilter />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {!sliders.length && !loading ? <NotFilter subsection={null} handleClearFilters={handleClickSetOptions} /> :
                    <Box mt={3}>
                        <div className="table_custom">
                            <MaterialTable
                                localization={localization}
                                title="Slider"
                                columns={columns}
                                data={sliders}
                                options={options}
                                icons={tableIcons} />
                        </div>
                    </Box>}


                {(showForm) &&
                    <SliderModal type={filters.internaltype} position={filters.position} title={filters.modal_title} />
                }

                <Dialog
                    open={openDeleteSlider}
                    fullWidth={true}
                    fullScreen={isMobile && true}
                    maxWidth={!isMobile && "sm"}
                    className={formClasses.containerForm}
                    onClose={() => setOpenDeleteSlider(!openDeleteSlider)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t("delete")} Slider
                            </Typography>
                            <Button onClick={() => setOpenDeleteSlider(!openDeleteSlider)}
                                color="primary" variant="text"><CloseRounded /></Button>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={formClasses.bodyForm}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} >
                                <Typography >
                                    {t("admin.slider.delete-slider-text")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton onClick={() => setOpenDeleteSlider(!openDeleteSlider)}>{t("cancel")}</CancelButton>
                        <DeleteButton autoFocus id="btn-handleldelete"
                            endIcon={<Delete />}
                            onClick={() => { dispatch(deleteSlider(sliderSelected)); setOpenDeleteSlider(!openDeleteSlider) }}>
                            {t("delete")}
                        </DeleteButton>
                    </DialogActions>
                </Dialog>

                <CustomSnackBar errors={[error]} msgs={[msg]} />
                <ScreenLoading loadings={[loading]} />
            </Container>
        </>
    );
}

export default Sliders;