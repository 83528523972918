// Material UI
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const ButtonHeader = styled(Button)(({
    theme
}) => ({
    color: theme.palette.grey[50],
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 16,
    padding: '12px 15px',
    '&:hover, &.active': {
        backgroundColor: 'rgb(255, 255, 255, 0.2)',
        marginTop: '-1px',
    },
    '&.active .MuiTouchRipple-root':{
        borderTop: '3px solid',
        borderColor: theme.palette.primary.dark
        
    },
    [theme.breakpoints.up('md')]: {
        borderRadius: '0px 0px 0px 10px',
    }
}));

  export default ButtonHeader;