// Material UI
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// Styles
import SkeletonStyle from '../../../../styles/components/layouts/navigation/SkeletonStyle';
import HiddenDesktop from '../../../common/HiddenDesktop';

//Custom Components
import HiddenMobile from '../../../common/HiddenMobile';
import CardsSkeleton from './CardsSkeleton';

//Custom Hook
import useScreenWidth from '../../../../hooks/useScreenWidth';

function WebsiteSkeleton() {
    //Custom Hook
    const isMobile = useScreenWidth(960);
    // Styles
    const classes = SkeletonStyle();

    return (
        <Box className={classes.bodyHomeSkeleton}>
            {/* Navbar */}
            <Box className={classes.navbarContainer}>
                <Box className={classes.navbarContentSide}>
                    <Skeleton animation='wave' width={59} height={26} variant='rect' style={{ marginRight: 32 }} />
                    <HiddenMobile display='flex'>
                        <Skeleton animation='wave' width={87} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={79} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={63} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={80} height={27} variant='text' style={{ marginRight: 24 }} />
                        <Skeleton animation='wave' width={80} height={27} variant='text' style={{ marginRight: 24 }} />
                    </HiddenMobile>
                    <HiddenDesktop>
                        <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    </HiddenDesktop>
                </Box>
                <Box className={classes.navbarContentSide}>
                    <HiddenMobile display='flex' alignItems='center'>
                        <Skeleton animation='wave' width={208} height={32} variant='rect' className={classes.sklborderRadius} />
                        <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    </HiddenMobile>
                    <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18 }} />
                    <Skeleton animation='wave' width={24} height={24} variant='circle' style={{ marginLeft: 18, marginRight: 8 }} />
                </Box>
            </Box>
            {/* Header */}
            <HiddenMobile>
                <Box>
                    <Skeleton animation='wave' variant='rect' className={classes.headerMainWebsiteDesktop} />
                </Box>
                <Box>
                    <Skeleton animation='wave' variant='rect' className={classes.headerWebsiteDesktop} />
                </Box>
            </HiddenMobile>
            <HiddenDesktop>
                <Box display='flex' alignItems='center' gridGap={5}>
                    <Skeleton variant='rect' animation='wave' className={classes.headerWebsiteMobile} />
                    <Skeleton variant='rect' animation='wave' className={classes.headerWebsiteMobile} />
                </Box>
            </HiddenDesktop>
            {/* Filters */}
            <HiddenMobile>
                <Box className={classes.filterContainer}>
                    <Box>
                        <Skeleton animation='wave' width={220} height={35} variant='rect' style={{ borderRadius: 10 }} />
                    </Box>
                    <Box display='flex' alignItems='center' gridGap={5}>
                        <Skeleton width={100} height={25} variant='rect' animation='wave' />
                        <Skeleton width={100} height={25} variant='rect' animation='wave' />
                        <Skeleton width={100} height={25} variant='rect' animation='wave' />
                        <Skeleton width={100} height={25} variant='rect' animation='wave' />
                    </Box>
                </Box>
            </HiddenMobile>
            <HiddenDesktop>
                <Box className={classes.filterContainer}>
                    <Box display='flex' alignItems='center' gridGap={5}>
                        <Skeleton animation='wave' width={50} height={35} variant='rect' style={{ borderRadius: 10 }} />
                    </Box>
                    <Box display='flex' alignItems='center' gridGap={5}>
                        <Skeleton width={70} height={25} variant='rect' animation='wave' />
                        <Skeleton width={70} height={25} variant='rect' animation='wave' />
                    </Box>
                </Box>
            </HiddenDesktop>
            {/* Menu mobile */}
            <HiddenDesktop>
                <Box className={classes.positionMenuMobile}>
                    <Box className={classes.containerMenuMobile}>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={65} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={60} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={46} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={68} height={20} variant='text' />
                        </Box>
                        <Box className={classes.containerbtnMenuMobile}>
                            <Skeleton variant='rect' animation='wave' height='24px' width='24px' />
                            <Skeleton animation='wave' width={48} height={20} variant='text' />
                        </Box>
                    </Box>
                </Box>
            </HiddenDesktop>
            <Grid container spacing={isMobile ? 5 : 4} className={classes.containerWebsiteHero}>
                <Grid item xs={12} style={isMobile ? { paddingTop: 10, paddingBottom: 2 } : { paddingLeft: '15%', paddingRight: '15%' }}>
                    <Grid container spacing={2} style={{ paddingBottom: 10 }}>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                        <Grid item xs={6} md={4} lg={3}><CardsSkeleton /></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box display='flex' flexDirection='column' alignItems='center' mt={3}>
                <Skeleton animation='wave' width={80} height={35} variant='rect' style={{ borderRadius: 10 }} />
            </Box>
        </Box>
    )
}

export default WebsiteSkeleton;