import { 
    ILogState,
    START_FILTER_LOGS, SUCCESS_FILTER_LOGS, ERROR_FILTER_LOGS,
    START_GET_CURRENT_COMMITS, SUCCESS_GET_CURRENT_COMMITS, ERROR_GET_CURRENT_COMMITS,
 } from '../types';
    
const initialState: ILogState = {
    logs: [],
    logSelected: null,
    commitAPI: '',
    commitFront: '',
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case SUCCESS_FILTER_LOGS:
            return{
                ...state,
                logs: action.payload.logs,
                error: false,
                loading: false,
            }
        case SUCCESS_GET_CURRENT_COMMITS:
            return{
                ...state,
                commitAPI: action.payload.currentGitCommitAPI,
                commitFront: action.payload.currentGitCommitFront,
                error: false,
                loading: false,
            }
        case ERROR_GET_CURRENT_COMMITS:
        case ERROR_FILTER_LOGS:
            return{
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}