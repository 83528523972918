// React
import { useState, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { Card, CardActionArea, CardContent, CardMedia, CardActions, Grid, Typography, Box, ListItem, Tooltip, Button, Collapse, IconButton } from '@material-ui/core';
import { FlagRounded, BusinessRounded, ExpandMoreRounded } from '@material-ui/icons';
import clsx from 'clsx';

// Custom components
import Visibility from '../client/Visibility';
import TooltipCards from '../typography/TooltipCards';
import HiddenMobile from '../HiddenMobile';
import FavoriteButton from '../FavoriteButton';

// Libraries
import { useTranslation } from "react-i18next";

//Custon Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Style
import ContentHeroStyle from '../../../styles/components/common/client/CardHeroStyles';

type CardHeroProps = {
    type: number,
    redirect: string,
    cardBrandName?: string,
    cardVisibility: number,
    cardImage: string,
    cardTitle: string,
    cardDescription?: ReactElement,
    cardDate?: string,
    cardActions?: ReactElement,
    onClickCard?: () => void,
    favoriteState?: boolean,
    onClickFavorite?: (state: boolean) => void
}

function CardHero({ type, redirect, cardVisibility, cardImage, cardTitle, cardDescription, cardDate, cardBrandName = '',
    onClickCard = () => { }, cardActions = <></>, favoriteState = false, onClickFavorite }: CardHeroProps) {

    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = ContentHeroStyle();
    // Translate
    const { t } = useTranslation(["global"]);

    const [isClick, setClick] = useState(false);

    /* Functions */
    const history = useHistory();
    const handleRedirect = () => {
        history.push(redirect);
        onClickCard();
    }


    return (
        <>
            {type === 1 ?
                <Card classes={{ root: classes.cardContainer }}>

                    <Grid container classes={{ root: classes.cardBody }}>
                        <Grid item xs={4} md={12}>
                            <CardMedia
                                classes={{ root: classes.cardImg }}
                                component="img"
                                image={cardImage}
                                alt={cardTitle}
                                onClick={() => handleRedirect()}
                            />
                        </Grid>
                        <Grid item xs={8} md={12} style={{ padding: 8, paddingTop: 0 }}>
                            <CardContent classes={{ root: classes.cardText1 }}>
                                <Box onClick={() => handleRedirect()} style={{ cursor: 'pointer' }}>
                                    <TooltipCards titleCard={cardTitle} classNameCustom='cardHeroTitle' variantCustom="subtitle1" />
                                    <Typography variant="caption" color="textSecondary" style={{ paddingTop: '5px' }}>
                                        {cardDate}
                                    </Typography>
                                </Box>
                            </CardContent>
                            <CardActions style={{ padding: 0, justifyContent: 'space-between' }} disableSpacing>
                                <Box display='flex'>
                                    {onClickFavorite &&
                                        <FavoriteButton handleClick={onClickFavorite} favoriteState={favoriteState} />
                                    }
                                    {cardActions}
                                </Box>
                                <Box display="flex" flexDirection='column' pr={isMobile ? '6px' : 2} onClick={() => handleRedirect()} style={{ cursor: 'pointer' }}>
                                    <Box>
                                        <Visibility type={cardVisibility} />
                                    </Box>
                                    <Box>
                                        <HiddenMobile display="flex" alignItems="center" gridGap={2}>
                                            {(cardBrandName !== null) &&
                                                <>
                                                    <BusinessRounded fontSize="small" htmlColor="#8F99A3" />
                                                    <Typography variant="body2" color="textSecondary">{cardBrandName}</Typography>
                                                </>
                                            }
                                        </HiddenMobile>
                                    </Box>
                                </Box>
                            </CardActions>
                        </Grid>
                    </Grid>
                </Card>
                : type === 0 ?
                    // Usado para la lista de destacados ultimos 3 elementos
                    <ListItem button divider onClick={() => handleRedirect()} className={classes.containerFeed}>
                        <FlagRounded classes={{ root: classes.iconFeed }} />
                        <Box pl={2}>
                            <TooltipCards titleCard={cardTitle} classNameCustom='cardHeroType0' />
                            <Typography variant="caption" color="textSecondary">{cardDate}</Typography>
                        </Box>
                    </ListItem>
                    : type === 3 ?
                        <Card style={{ backgroundColor: '#FAFAFA' }} elevation={0}>
                            <Tooltip title={cardTitle}>
                                <CardActionArea onClick={() => handleRedirect()}>
                                    <Box>
                                        <CardMedia
                                            classes={{ root: classes.cardImg }}
                                            component="img"
                                            alt={cardTitle}
                                            height='200px'
                                            image={cardImage}
                                        />
                                        <CardContent classes={{ root: classes.cardTextMobile }}>
                                            <Typography align="left" variant='body2'>{cardTitle}</Typography>
                                        </CardContent>
                                    </Box>
                                </CardActionArea>
                            </Tooltip>
                        </Card>
                        : type === 4 ?
                            <Card classes={{ root: classes.cardContainer }} style={{ padding: 8 }}>
                                <CardActionArea onClick={() => handleRedirect()}>
                                    <Grid container classes={{ root: classes.cardBody }}>
                                        <Grid item lg={12}>
                                            <CardMedia
                                                component="img"
                                                image={cardImage}
                                                height="240"
                                                alt={cardTitle}
                                            />
                                        </Grid>
                                        <Grid item lg={12}>
                                            <CardContent classes={{ root: classes.cardTextHome }} style={{ textAlign: 'initial' }}>
                                                <TooltipCards titleCard={cardTitle} classNameCustom='cardHerotitleCorousel' variantCustom="subtitle1" />
                                                <Typography variant="caption" color="textSecondary">
                                                    {cardDate}
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                            :
                            <Card style={{ backgroundColor: '#FAFAFA' }}>
                                <CardActionArea onClick={() => handleRedirect()}>
                                    <Grid container classes={{ root: classes.cardBody }}>
                                        <Grid item xs={4} lg={12}>
                                            <CardMedia
                                                component="img"
                                                alt={cardTitle}
                                                height="135"
                                                image={cardImage}
                                            />
                                        </Grid>
                                        <Grid item xs={8} lg={12}>
                                            <CardContent classes={{ root: classes.cardText }}>
                                                <TooltipCards titleCard={cardTitle} classNameCustom='cardHeroUnderline' variantCustom="subtitle1" />
                                                <Typography variant="body2" className={classes.cardPh}>{cardDescription}</Typography>
                                                <Typography color="textSecondary">{cardDate}</Typography>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                                <CardActions>
                                    {cardActions}
                                </CardActions>
                            </Card>
            }
        </>
    );
}

export default CardHero;
