import {
    IBrandState,
    START_GET_BRANDS, SUCCESS_GET_BRANDS, ERROR_GET_BRANDS,
    START_GET_USER_BRANDS, SUCCESS_GET_USER_BRANDS, ERROR_GET_USER_BRANDS,
    START_SHOW_BRAND, SUCCESS_SHOW_BRAND, ERROR_SHOW_BRAND,
    START_VIEW_BRAND, SUCCESS_VIEW_BRAND, ERROR_VIEW_BRAND,
    START_STORE_BRAND, SUCCESS_STORE_BRAND, ERROR_STORE_BRAND,
    START_UPDATE_BRAND, SUCCESS_UPDATE_BRAND, ERROR_UPDATE_BRAND,
    START_DELETE_BRAND, SUCCESS_DELETE_BRAND, ERROR_DELETE_BRAND,
    SUCCESS_TOGGLE_FORM_BRANDS,
    START_CHANGE_LINE_STATUS, SUCCESS_CHANGE_LINE_STATUS, ERROR_CHANGE_LINE_STATUS,
    START_DELETE_BRAND_LINE, SUCCESS_DELETE_BRAND_LINE, ERROR_DELETE_BRAND_LINE,
    START_ASSIGN_HIGHLIGHT_BRAND, SUCCESS_ASSIGN_HIGHLIGHT_BRAND, ERROR_ASSIGN_HIGHLIGHT_BRAND,
    START_ORDERED_BRAND, SUCCESS_ORDERED_BRAND, ERROR_ORDERED_BRAND
} from '../types';

const initialState: IBrandState = {
    brands: [],
    userFamilies: [],
    userBrands: [],
    brandSelected: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_BRANDS:
        case START_GET_USER_BRANDS:
        case START_SHOW_BRAND:
        case START_VIEW_BRAND:
        case START_STORE_BRAND:
        case START_UPDATE_BRAND:
        case START_DELETE_BRAND:
        case START_CHANGE_LINE_STATUS:
        case START_DELETE_BRAND_LINE:
        case START_ASSIGN_HIGHLIGHT_BRAND:
        case START_ORDERED_BRAND:
            return {
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_BRANDS:
            return {
                ...state,
                brands: action.payload.brands,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_GET_USER_BRANDS:
            return {
                ...state,
                userFamilies: action.payload.families,
                userBrands: action.payload.brands,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_BRAND:
            return {
                ...state,
                brandSelected: action.payload.brand,
                msg: null,
                error: false,
                loading: false,
                showForm: true
            }
        case SUCCESS_VIEW_BRAND:
            return {
                ...state,
                brandSelected: action.payload.brand,
                msg: null,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_STORE_BRAND:
            return {
                ...state,
                brands: [...state.brands, action.payload.brand],
                msg: null,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_UPDATE_BRAND:
            return {
                ...state,
                brands: state.brands.map((brand: any) =>
                    brand.id === action.payload.brand.id ? action.payload.brand : brand
                ),
                msg: null,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_DELETE_BRAND:
            return {
                ...state,
                brands: state.brands.filter((brand: any) => brand.id != action.payload.brand_id),
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_BRANDS:
            return {
                ...state,
                showForm: !state.showForm,
                brandSelected: null,
            }
        case SUCCESS_CHANGE_LINE_STATUS:
        case SUCCESS_DELETE_BRAND_LINE:
            let tmpBrand = state.brandSelected;
            tmpBrand.lines = action.payload.lines;
            return {
                ...state,
                brandSelected: tmpBrand,
                msg: action.payload.msg,
                loading: false
            }

        case SUCCESS_ASSIGN_HIGHLIGHT_BRAND:
        case SUCCESS_ORDERED_BRAND:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case ERROR_GET_BRANDS:
        case ERROR_GET_USER_BRANDS:
        case ERROR_SHOW_BRAND:
        case ERROR_VIEW_BRAND:
        case ERROR_STORE_BRAND:
        case ERROR_UPDATE_BRAND:
        case ERROR_DELETE_BRAND:
        case ERROR_CHANGE_LINE_STATUS:
        case ERROR_DELETE_BRAND_LINE:
        case ERROR_ASSIGN_HIGHLIGHT_BRAND:
        case ERROR_ORDERED_BRAND:
            return {
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}