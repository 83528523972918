// Material UI
import { Container, Box, Button } from "@material-ui/core";
import { NavigateBeforeRounded } from "@material-ui/icons/";

// Custom Components
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import LessonDetail from '../../../components/lessons/LessonDetail';
import ButtonBack from "../../../components/common/ButtonBack";

// Libraries
import { useTranslation } from "react-i18next";

function LessonsView() {
    // Translate
    const { t } = useTranslation(["global"]);

    return (
        <>
            <Header title={t("acedemy")} />
            <SubHeader />

            <Container maxWidth="md">
                <Box display="flex" mt={6}>
                    <ButtonBack />
                </Box>

                <LessonDetail lesson={[]} course={[]} />

            </Container>
        </>
    );
}

export default LessonsView;
