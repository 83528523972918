import {
    ILessonState,
    START_GET_LESSONS, SUCCESS_GET_LESSONS, ERROR_GET_LESSONS,
    START_CREATE_LESSON, SUCCESS_CREATE_LESSON, ERROR_CREATE_LESSON,
    START_STORE_LESSONMULTIMEDIA, SUCCESS_STORE_LESSONMULTIMEDIA, ERROR_STORE_LESSONMULTIMEDIA,
    START_SHOW_LESSON, SUCCESS_SHOW_LESSON, ERROR_SHOW_LESSON,
    START_DELETE_LESSON, SUCCESS_DELETE_LESSON, ERROR_DELETE_LESSON,
    SUCCESS_TOGGLE_FORM_LESSON,
    SUCCESS_SET_VIEW_LESSON
} from '../types';

const initialState: ILessonState = {
    lessons: [],
    lesson_selected: null,
    confirm: false,
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_LESSONS:
        case START_CREATE_LESSON:
        case START_STORE_LESSONMULTIMEDIA:
        case START_SHOW_LESSON:
        case START_DELETE_LESSON:
            return {
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_LESSONS:
            return {
                ...state,
                lessons: action.payload.lessons,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_CREATE_LESSON:
            return {
                ...state,
                lesson_selected: action.payload.lesson,
                confirm: action.payload.confirm,
                category_selected: null,
                showForm: action.payload.confirm,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_LESSON:
            return {
                ...state,
                lesson_selected: action.payload.lesson,
                showForm: !state.showForm,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_STORE_LESSONMULTIMEDIA:
            let tmpLessonMultimedia = state.lesson_selected;
            tmpLessonMultimedia.multimedia = tmpLessonMultimedia.multimedia.map((multimedia: any) => {
                if (multimedia.id === action.payload.lessonMultimedia.id){
                    multimedia.file_name = action.payload.lessonMultimedia.file_name;
                    multimedia.cover = action.payload.lessonMultimedia.cover;
                }
                return multimedia;
            });
            return {
                ...state,
                lesson_selected: tmpLessonMultimedia,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_DELETE_LESSON:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_LESSON:
            return {
                ...state,
                lesson_selected: null,
                showForm: !state.showForm,
                confirm: false,
            }
        case SUCCESS_SET_VIEW_LESSON:
            return {
                ...state,
                lesson_selected: action.payload,
                showForm: false
            }
        case ERROR_GET_LESSONS:
        case ERROR_CREATE_LESSON:
        case ERROR_STORE_LESSONMULTIMEDIA:
        case ERROR_SHOW_LESSON:
        case ERROR_DELETE_LESSON:
            return {
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}