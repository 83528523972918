// React
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Material UI
import { Button, Grid, Typography, Box, Tooltip } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'

// Custom Component
import ShareIcon from '../common/ShareIcon';
import Visibility from '../common/client/Visibility';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';

// Libraries
import { useTranslation } from "react-i18next";
import FavoriteButton from '../common/FavoriteButton';

// Custom Style
const offerStyles = makeStyles((theme) => createStyles({
    offer: {
        borderRadius: '8px 8px 8px 8px',
        maxWidth: '100%',
        cursor: 'pointer',
    },
    containerPrimary: {
        height: 376,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            height: 241,
            padding: 0,
        },
    },
    bodyImage: {
        backgroundSize: "cover !important",
        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "center center !important",
        width: "97%",
        height: 360,
        position: "absolute",
        borderRadius: 8,
        [theme.breakpoints.down('xs')]: {
            height: 241,
            borderRadius: 8,
            width: "100%",
        },
    },
    visibilityBlock: {
        '& button': {
            marginBottom: '-7px',
            position: 'relative',
            bottom: 6
        }
    },
}));

// Types
type OfferBlockProps = {
    offer: any,
    type: any
}
//Type 0 = es para los slider

function OfferBlock({ offer, type }: OfferBlockProps) {
    // Translate
    const { t } = useTranslation(["global"]);

    const history = useHistory();

    // Styles
    const classes = offerStyles();

    // React hooks
    const dispatch = useDispatch();

    /**Functions */
    const handleFavoriteClick = (offer_id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id: offer_id,
                state,
                type: 'offer'
            }));
        } else {
            dispatch(unsetFavorite({
                id: offer_id,
                state,
                type: 'offer'
            }));
        }
    };

    return (
        <>
            {type === 0
                ?
                <Box position="relative" overflow="hidden" className={classes.containerPrimary}>
                    <Tooltip title={offer.title}>
                        <Button fullWidth style={{ height: '100%', padding: 0, borderRadius: 8 }}>
                            <Box
                                onClick={() => { history.push(`/ofertas/${offer.id}`) }}
                                className={classes.bodyImage}
                                style={{
                                    background: `${encodeURI(`url(${process.env.REACT_APP_AWS_URL}/offers/${offer.image})`)}`
                                }} />
                        </Button>
                    </Tooltip>
                </Box>
                :
                <>
                    <Grid container spacing={1} justify="center">
                        <Grid item>
                            <Tooltip title={offer.title}>
                                <Button style={{ padding: 0, borderRadius: 8 }} onClick={() => { history.push(`/ofertas/${offer.id}`) }} >
                                    <img src={`${encodeURI(`${process.env.REACT_APP_AWS_URL}/offers/${offer.image}`)}`} alt={offer.title} className={classes.offer} />
                                </Button>
                            </Tooltip>
                            <Box display="flex" justifyContent="space-between" alignContent='center' pt={1}>
                                <Box className={classes.visibilityBlock}>
                                    <FavoriteButton handleClick={handleFavoriteClick(offer.id)} favoriteState={Boolean(offer.favoriteId)} />
                                    <ShareIcon
                                        visibility={offer.visibility}
                                        title={offer.title}
                                        description={offer.description}
                                        link={`${window.location.origin}/ofertas/${offer.id}${window.location.search}`}
                                        multimedia={offer.image_url}
                                        type="offer"
                                    />
                                </Box>
                                <Visibility type={offer.visibility} />
                            </Box>
                        </Grid>
                    </Grid>

                </>
            }
        </>
    );
}

export default OfferBlock;