import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";

import imageFav from '../../../../assets/Images/fav.png';

export default makeStyles((theme) =>
    createStyles({

        cardBodyHero: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        cardTextHero: {
            padding: 0,
            paddingRight: 16,
            '& h6:hover': {
                textDecoration: 'underline',
            }
        },
        cardHeaderArticle: {
            display: 'grid',
            gap: 24,
            padding: 0,
            paddingTop: 40,
            paddingBottom: 16,
        },
        cardContainer: {
            boxShadow: '0px 0px 6px 1px rgb(0 0 0 / 8%) !important',
            position: 'relative',
            borderRadius: 8,
            backgroundColor: theme.palette.common.white,
            '&::last-child': {
                marginBottom: 16
            }
        },
        cardText1: {
            padding: 12,
            paddingBottom: 0,
            [theme.breakpoints.down('sm')]: {
                padding: 8,
            },
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        cardBody: {
            [theme.breakpoints.up('lg')]: {
                display: 'inherit',
            },
            [theme.breakpoints.down('md')]: {
                '& > div:nth-child(1)': {
                    paddingRight: 10
                }

            },
            [theme.breakpoints.up('sm')]: {
                '& img': {
                    height: '190px'
                },

            },
            [theme.breakpoints.down('sm')]: {
                '& img': {
                    height: '100%'
                },

            },
            display: 'flex',
        },
        containerImgLike: {
            position: 'relative',
        },
        cardImg: {
            borderRadius: '8px 8px 8px 0px',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        cardText: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '0.5rem',
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: '1rem',
            },
            padding: '1rem',
            paddingTop: 16,
        },
        cardTextMobile: {
            padding: 0,
            paddingRight: 16,
            paddingTop: 8,
            paddingBottom: '0 !important',
            display: 'grid',
            '& p': {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                // maxWidth: 230,
                lineHeight: '19px',
                fontWeight: 500,
            }
        },
        cardTextHome: {
            padding: 0,
            paddingBottom: 8,
            ' & h6': {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                overflow: 'hidden',
            },
            "&:last-child": {
                paddingBottom: 16,
                paddingTop: 0,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '1rem',
            },
        },
        cardPh: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3,
                overflow: 'hidden',
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
            }
        },
        iconFeed: {
            fill: theme.palette.primary.main
        },
        containerFeed: {
            padding: ' 8px 16px',
            '& svg:': {
                height: 18,
                width: 14,
            },
            '& p': {
                maxWidth: 207,
                letterSpacing: '0.0015em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                overflow: 'hidden',
            },
            [theme.breakpoints.down('sm')]: {
                '& p': {
                    maxWidth: 250,
                },
            },
        },
    })
);
