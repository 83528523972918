// React
import { useState, MouseEvent } from "react";
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import {
    Box, Typography, Card, CardContent, IconButton, Menu, MenuItem,
    DialogActions, Dialog, DialogTitle, DialogContent, Grid, Button
} from "@material-ui/core";
import { MoreVert, CalendarTodayRounded, LabelImportantRounded, ArrowForward, CloseRounded } from "@material-ui/icons/";

// Custom Components
import CustomSnackBar from "../common/admin/CustomSnackBar";
import ScreenLoading from "../common/ScreenLoading";
import { CancelButton, DeleteButton, SaveButton } from "../common/forms";

// Redux Actions
import { showSeminarLesson, setViewSeminarLesson, deleteSeminarLesson } from '../../redux/actions/seminarlessons';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// STYLES
import CoursesStyle from '../../styles/components/courses/CoursesStyles';
import VipStyles from '../../styles/components/common/VipStyles';
import FormsStyles from "../../styles/components/common/forms/FormsStyles";


// Types
type SeminarLessonBlockProps = {
    seminarlesson: any,
    hasaccess: boolean,
    vipContent: boolean,
}

function SeminarLessonBlock({ seminarlesson, vipContent, hasaccess }: SeminarLessonBlockProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = CoursesStyle();
    const vipclasses = VipStyles();
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { loading, msg, error } = useSelector((state: IRootState) => state.lesson);
    // Component States
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(false);
    const [openDeleteLesson, setOpenDeleteLesson] = useState(false);
    const [lessonSelected, setLessonSelected] = useState(false);

    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(false);
    };

    const handleClickLesson = () => {
        dispatch(setViewSeminarLesson(seminarlesson));
    }

    return (
        <>


            <Card className={classes.lessons}>
                <CardContent>
                    <Grid container justify="space-between" alignItems="center">

                        <Grid item xs={1} sm={2} onClick={() => handleClickLesson()}>
                            <Box width='100%' justifyContent="center" display="flex">
                                {/* <PlayCircleFilled className={classes.lessonPlay} />   Para cuando la fecha pase la fecha actual (que sea haya pasado)*/}
                                {/* <WifiTetheringRounded className={clsx(classes.lessonPlay, 'live')} />  cuando este en vivo el contenido*/}
                                <LabelImportantRounded className={clsx(classes.lessonPlay, 'new')} />
                            </Box>
                        </Grid>

                        <Grid item xs={9} onClick={() => handleClickLesson()}>
                            <Box pl={isMobile ? 2 : 0}>
                                <Typography variant="subtitle1">{seminarlesson.title}</Typography>
                                {seminarlesson.datatime != null &&
                                    <Box display="flex" gridGap={8} alignItems="center" pt={isMobile ? 0 : 1}>
                                        <CalendarTodayRounded fontSize='inherit' />
                                        <Typography color="inherit">{seminarlesson.datetime}</Typography>
                                    </Box>}
                                <Box display="flex" gridGap={8} alignItems="center" pt={isMobile ? 0 : 1} className={(vipContent) ? vipclasses.text : classes.textPrimary}>
                                    <>
                                        {/* Para cuando la fecha pase la fecha actual (que sea haya pasado)
                                        {(vipContent) ?
                                            <Visibility color="inherit" style={{ fill: "#B49568" }} />
                                            :
                                            <Visibility color="inherit" />
                                        }
                                        <Typography variant="body1" color="inherit">Reproducir</Typography> */}
                                        {/* <Typography>{t('live')}</Typography> cuando este en vivo el contenido */}
                                        <Typography>{t('more-info')}</Typography>
                                        {(vipContent) ?
                                            <ArrowForward color='inherit' style={{ fill: "#B49568" }} />
                                            :
                                            <ArrowForward color='inherit' />
                                        }
                                    </>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={2} sm={1}>
                            {(authUser != null && [1, 2, 5].includes(authUser.role_id)) &&
                                <>
                                    <IconButton
                                        id={`icon-button-${seminarlesson.id}`}
                                        aria-controls="icon-menu"
                                        aria-haspopup="false"
                                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                            setAnchorOp(event.currentTarget);
                                            setOpenOptions(true);
                                        }}>
                                        {/* USE IN VIP */}
                                        {/* <MoreVert style={{ fill: "#B49568", }}/> */}
                                        <MoreVert />
                                    </IconButton>
                                    <Menu
                                        id={`simple-menu-${seminarlesson.id}`}
                                        anchorEl={anchorOp}
                                        getContentAnchorEl={null}
                                        keepMounted
                                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                        open={openOptions}
                                        onClick={handleCloseOptions}
                                        onClose={handleCloseOptions}>
                                        <MenuItem onClick={() => dispatch(setViewSeminarLesson(seminarlesson))}>{t("view")}</MenuItem>
                                        <MenuItem onClick={() => dispatch(showSeminarLesson(seminarlesson.id))}>{t("edit")}</MenuItem>
                                        <MenuItem onClick={() => { setOpenDeleteLesson(!openDeleteLesson); setLessonSelected(seminarlesson.id) }}>{t("delete")}</MenuItem>
                                    </Menu>
                                </>
                            }
                        </Grid>


                    </Grid>

                </CardContent>
                {(vipContent && !hasaccess) &&
                    <Box alignContent="center" textAlign="center" className={vipclasses.lessonBlock}>
                        <Typography variant="subtitle1" color="inherit">Este es un contenido</Typography>
                        <Typography variant="h5" color="inherit">VIP</Typography>
                    </Box>
                }
            </Card>


            <Dialog
                open={openDeleteLesson}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={() => setOpenDeleteLesson(!openDeleteLesson)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            Eliminar Lección
                        </Typography>
                        <Button onClick={() => setOpenDeleteLesson(!openDeleteLesson)} color='primary' variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} >
                            <Typography>¿Está seguro que desea eliminar el lección?</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setOpenDeleteLesson(!openDeleteLesson)}>
                        {t("cancel")}
                    </CancelButton>
                    <DeleteButton
                        id="btn-handlelogin"
                        onClick={() => { dispatch(deleteSeminarLesson(lessonSelected)); setOpenDeleteLesson(!openDeleteLesson) }}>
                        {t("delete")}
                    </DeleteButton>
                </DialogActions>
            </Dialog>

            <CustomSnackBar errors={[error]} msgs={[msg]} />
            <ScreenLoading loadings={[loading]} />
        </>
    );
}

export default SeminarLessonBlock;
