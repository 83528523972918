// Material UI
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ButtonNavbar = styled(Button)(({
    theme
}) => ({
    padding: '8px',
    borderRadius: '50%',
    maxHeight: 36,
    maxWidth: 36,
    color: theme.palette.grey[400],
    marginLeft: 8,
    minWidth: "auto !important",
    '& span': {
        maxHeight: 24,
        maxWidth: 24,
    },
    '&:active, &:hover': {
        backgroundColor: theme.palette.grey[100],
        '& svg': {
            color: theme.palette.primary.main,
        },
    },
    '&.admin': {
        '&:active, &:hover': {
            backgroundColor: theme.palette.grey[800],
            '& svg': {
                color: theme.palette.common.white,
            },
        },
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: 44,
        padding: 1,
        maxHeight: 50,
        height: 50,
        width: 50,
    }
}));

export default ButtonNavbar;