import { put, call, takeLatest } from 'redux-saga/effects';
import { START_GET_TAGS, SUCCESS_GET_TAGS, ERROR_GET_TAGS } from '../types';
import { apiCall } from '../config/axios';

function* getTags() {
    try{
        const response = yield call(apiCall, 'get', `/tags/index/${localStorage.getItem('globalLanguageId')}/${(localStorage.getItem('globalFamilyId') !== "null") ? localStorage.getItem('globalFamilyId') : ''}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_TAGS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_TAGS, payload: error.message})
    }
}

export default function* tags() {
    yield takeLatest(START_GET_TAGS, getTags);
}