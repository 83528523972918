import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    header: {
        borderBottom: '1px solid  #C7CCD1',
        marginTop: 32,
        marginBottom: 24,
        "& h5": {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                fontWeight: 500,
            },
        },
        "& p": {
            whiteSpace: "nowrap",
        }
    },
    seeMore: {
        textDecoration: 'underline',
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
    slide: {
        maxWidth: '100%'
    },
    containerCard: {
        lineHeight: 0,
        margin: 0,
        position: "relative",
        "& h5, h6, p": {
            color: "#FAFAFA",
            textShadow: "0 1px 1px #222",
        },
        '&:hover': {
            cursor: 'pointer',
        },

    },
    containerMobile: {
        [theme.breakpoints.down('sm')]: {
            margin: '10px 0px 20px',
        },
    },
    containercarousel: {
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            '& .carousel': {
                height: 140,
                display: 'contents',
            }
        },
    },
    bodyImage: {
        backgroundSize: "cover !important",
        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "center center !important",
        width: "100%",
        height: "100%",
        position: "absolute",
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },
    bodyText: {
        backgroundColor: "transparent",
        bottom: 0,
        left: 0,
        right: 0,
        overflow: "hidden",
        paddingTop: "6%",
        position: "absolute",
        zIndex: 999,
        padding: "0px 22px 15px",
        backgroundImage: "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4))",
        [theme.breakpoints.down('xs')]: {
            maxWidth: '97%',
            borderRadius: '0px 0px 8px 8px',
            padding: "0px 16px 8px",
        },
    },
    contentText: {
        border: 0,
        margin: 0,
        padding: 0,
        verticalAlign: "baseline",
    },
    titleLink: {
        lineHeight: '22px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textAlign: 'start',
        fontWeight: '500',
        [theme.breakpoints.down('xs')]: {
            lineHeight: '16px',
            fontSize: theme.typography.body2.fontSize,
        },
    },
    containerPrimary: {
        height: "17.5rem",
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },
    containerPrimaryHighest: {
        height: "17.5rem",
        [theme.breakpoints.down('xl')]: {
            height: "27.5rem",
        },
        [theme.breakpoints.up('xl')]: {
            height: "32.5rem",
        },
        [theme.breakpoints.down('xs')]: {
            height: 200,
        },
    },
    containerLarge: {
        [theme.breakpoints.down('xl')]: {
            padding: '0px !important',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 50,
        },
    },
    noPointerSlider: {
        cursor: 'default !important',
    },
    //Section worlds
    containerTextWorld: {
        '& h6': {
            fontWeight: 500,
            whiteSpace: 'nowrap',
        }
    },
    divider: {
        backgroundColor: theme.palette.grey[700],
        height: 1,
        width: '100%',
    },
    containerButtonsWorld: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    buttonWorld: {
        height: 88,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        boxShadow: '23px -23px 43px #FFFFFF, 28px 28px 50px rgba(13, 39, 80, 0.16)',
        '&:hover': {
            boxShadow: '23px -23px 43px #FFFFFF, 28px 28px 50px rgba(13, 39, 80, 0.16)',
        },
        '& span': {
            gap: 16,
            fontWeight: 700,
            '& h6, p': {
                width: 'min-content',
            }
        },
        '& span h6': {
            textAlign: 'left',
            lineHeight: '22px',
            fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
            height: 80,
            width: '48%',
        },
        [theme.breakpoints.down('xs')]: {
            height: 80,
            width: '100%',
        },
        '&.fixed': {
            maxWidth: 400,
        }
    },
    hiddenSm: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    // Container new, calendar and cards
    containerHero: {
        width: 'calc(100% + 36px)',
        [theme.breakpoints.up('sm')]: {
            marginTop: '-40px',
        },
    },
    //Modal Styles
    modalVideo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerVideo: {
        boxSizing: 'content-box',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 24,
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
            width: '85%',
        },
    },
    videoModal: {
        width: 'inherit',
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    videoContent: {
        '& iframe': {
            border: 'none',
            height: '80vh',
            width: '80vh',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '& iframe': {
                width: '100%',
                border: 'none',
                height: '80vh',
            },
        },
    },
}));