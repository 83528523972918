import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'

// Material UI
import { Grid, Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import { CloseRounded, Unsubscribe } from '@material-ui/icons/';

// Redux Actions
import { disableUser } from '../../redux/actions/users';

//Custom Components
import { CancelButton, DeleteButton, SaveButton } from '../common/forms';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Translate
import { useTranslation } from "react-i18next";

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

type UnsubscribeUserProps = {
    authUser: any,
    userProfileSelected: any
}

function DisableUser({ authUser, userProfileSelected }: UnsubscribeUserProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const formClasses = FormsStyles();
    //Tabs
    const [open, setOpen] = React.useState(false);

    //Redux Hooks
    const dispatch = useDispatch();

    const handleClickUnsubscribe = () => {
        setOpen(!open)
    };

    const handleSubmitUnsubscribe = () => {
        dispatch(disableUser());
        setOpen(!open);
    };


    return (
        <>
            {(authUser.id === userProfileSelected.id) &&
                <Grid item>
                    <Button onClick={handleClickUnsubscribe} size='medium' variant="outlined" color="primary" startIcon={<Unsubscribe />}>
                        {t("admin.people.unsubscribe")}
                    </Button>
                </Grid>
            }

            <Dialog
                open={open}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t("admin.people.unsubscribe")}
                        </Typography>
                        <Button onClick={() => setOpen(false)} color='primary' variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} >
                            <Typography>{t("admin.people.unsubscribe-user-text")}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setOpen(false)} variant="contained" color="secondary">{t("cancel")}</CancelButton>
                    <SaveButton
                        id="btn-handlelogin"
                        endIcon={<Unsubscribe />}
                        onClick={() => handleSubmitUnsubscribe()}>
                        {t("allow")}
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DisableUser;