import {
    ILanguageState, 
    START_GET_LANGUAGES, SUCCESS_GET_LANGUAGES, ERROR_GET_LANGUAGES,
    START_GET_FILTERED_LANGUAGES, SUCCESS_GET_FILTERED_LANGUAGES, ERROR_GET_FILTERED_LANGUAGES
} from '../types';
    
const initialState: ILanguageState = {
    languages: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_LANGUAGES:
        case START_GET_FILTERED_LANGUAGES:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_LANGUAGES:
        case SUCCESS_GET_FILTERED_LANGUAGES:
        return{
            ...state,
            languages: action.payload.languages,
            msg: null,
            error:false,
            loading: false
        }
        case ERROR_GET_LANGUAGES:
        case ERROR_GET_FILTERED_LANGUAGES:
        return{
            ...state,
            languages: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}