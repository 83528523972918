import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_GET_TESTIMONIALS, SUCCESS_GET_TESTIMONIALS, ERROR_GET_TESTIMONIALS,
    START_SHOW_TESTIMONIAL, SUCCESS_SHOW_TESTIMONIAL, ERROR_SHOW_TESTIMONIAL,
    START_STORE_TESTIMONIAL, SUCCESS_STORE_TESTIMONIAL, ERROR_STORE_TESTIMONIAL,
    START_UPDATE_TESTIMONIAL, SUCCESS_UPDATE_TESTIMONIAL, ERROR_UPDATE_TESTIMONIAL,
    START_DELETE_TESTIMONIAL, SUCCESS_DELETE_TESTIMONIAL, ERROR_DELETE_TESTIMONIAL,
    START_TOGGLE_FORM_TESTIMONIALS, SUCCESS_TOGGLE_FORM_TESTIMONIALS,
} from '../types';
import { apiCall } from '../config/axios';

function* getTestimonials({ payload }) {
    try {
        const response = yield call(apiCall, 'get', '/testimonials/index/'+payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_TESTIMONIALS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_TESTIMONIALS, payload: error.message})
    }
}

function* showTestimonial({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/testimonials/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_SHOW_TESTIMONIAL, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_SHOW_TESTIMONIAL, payload: error.message})
    }
}

function* storeTestimonial({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/testimonials/store', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_STORE_TESTIMONIAL, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_STORE_TESTIMONIAL, payload: error.message})
    }
}

function* updateTestimonial({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/testimonials/update', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_UPDATE_TESTIMONIAL, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_UPDATE_TESTIMONIAL, payload: error.message})
    }
}

function* deleteTestimonial({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/testimonials/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_DELETE_TESTIMONIAL, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_DELETE_TESTIMONIAL, payload: error.message})
    }
}

function* toggleFormTestimonials() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_TESTIMONIALS})
}

export default function* testimonials() {
    yield takeLatest(START_GET_TESTIMONIALS, getTestimonials);
    yield takeLatest(START_SHOW_TESTIMONIAL, showTestimonial);
    yield takeLatest(START_STORE_TESTIMONIAL, storeTestimonial);
    yield takeLatest(START_UPDATE_TESTIMONIAL, updateTestimonial);
    yield takeLatest(START_DELETE_TESTIMONIAL, deleteTestimonial);
    yield takeLatest(START_TOGGLE_FORM_TESTIMONIALS, toggleFormTestimonials);
}