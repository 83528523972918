// React
import { useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

// Material UI
import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@material-ui/core";
import { useParams, Link as RouterLink } from "react-router-dom";
import { NavigateBeforeRounded } from "@material-ui/icons/";

// Redux
import { IRootState } from "../../redux/types";

//Custom components
import CustomBreadcrumb from "../../components/helpcenter/CustomBreadcrumb";
import useSectionData, {
  SectionData,
  SectionName,
} from "../../hooks/helpcenter/useSectionData";

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import HelpCenterStyles from "../../styles/components/HelpCenterStyles";
import ButtonBack from "../../components/common/ButtonBack";

// Types
interface ParamTypes {
  section: string;
  questionid: string;
  type: string;
}

const SectionDetail = ({ history }: RouteComponentProps) => {
  // Translation
  const { t } = useTranslation(["helpcenter"]);

  const { user: authUser } = useSelector((state: IRootState) => state.auth);

  // Styles
  const classes = HelpCenterStyles();
  const { section, questionid, type } = useParams<ParamTypes>();
  const [iQuestion, setIQuestion] = useState<number>(parseInt(questionid));

  const handleInfo = (iQuestion: number) => {
    setIQuestion(iQuestion);
  };

  const data: any = useSectionData();
  const roles: string[] = [
    "",
    "admin",
    "admin",
    "responsible",
    "comercial",
    "admin",
    "client",
    "client",
    "comercial",
    "comercial",
  ];

  let role: string = "admin";
  role = roles[authUser.role_id];

  return (
    <Container style={{ padding: 0, marginTop: 50 }} maxWidth={"xl"}>
      <CustomBreadcrumb />
      <Container maxWidth={"md"}>
        <Box
          display="flex"
          flexWrap="wrap"
          mt={7}
          mb={15}
          className={classes.detailContainer}
        >
          <Box className={classes.navContent}>
            {data[type].filter((sections: any) => sections[section] !== undefined).map((sections: any) => (
              <div key={sections[section].title}>
                <Typography variant="subtitle1">
                  {sections[section].title}
                </Typography>
                <List component="nav">
                  {sections[section].content.map(
                    (section: any, index: number) => {
                      return (
                        <div key={section.question}>
                          <ListItem
                            button
                            selected={index === iQuestion}
                            onClick={() => handleInfo(index)}
                          >
                            <ListItemText primary={section.question} />
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    }
                  )}
                </List>
              </div>
            ))}
          </Box>

          <Box pb={4} className={classes.textContent}>
            <Box mb={3}>
              <ButtonBack />
            </Box>

            {data[type].filter((sections: any) => sections[section] !== undefined).map((sections: any) => (
              <div key={sections[section].title}>
                {sections[section].content.filter((_section: any, index: number) => index === iQuestion).map((section: any, index: number) => (
                  <div key={section.question}>
                    <Box mb={7}>
                      <Typography
                        variant="h4"
                        style={{ fontWeight: 300 }}
                      >
                        {section.question}
                      </Typography>
                    </Box>

                    {section.answer.map(
                      (answer: any, _index2: number) => {
                        return (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: answer,
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                ))}
              </div>
            ))}
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default SectionDetail;
