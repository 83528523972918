// React
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";

// Material UI
import {
  ThemeProvider,
  FormControl,
  Link,
  Select,
  MenuItem,
  Box,
  AppBar,
  Toolbar,
  Container,
  Grid,
  Typography,
  Button,
  InputLabel,
} from "@material-ui/core";
import { TranslateRounded } from "@material-ui/icons";

// Custom Components
import Home from "../../pages/helpcenter/Home";
import ContactForm from "../../pages/helpcenter/ContactForm";
import Section from "../../pages/helpcenter/Section";
import SectionDetail from "../../pages/helpcenter/SectionDetail";
import SectionStart from "../../pages/helpcenter/SectionStart";
import SectionStartQuestion from "../../pages/helpcenter/SectionStartQuestion";

//import ClientPassword from '../../pages/auth/ClientPassword';
import NotFound from "../../pages/NotFound";
import Footer from "./common/Footer";

// Redux Actions
import { getLanguages } from "../../redux/actions/languages";
import { setGlobalLanguage } from "../../redux/actions/global";
import { IRootState } from "../../redux/types";

// Libraries
import { useTranslation } from "react-i18next";

//Assets
import logo from "../../assets/Images/logos/logodark.svg";

// Styles
import mainTheme from "../../styles/components/themes/ClientTheme";
import HelpCenterStyles from "../../styles/components/HelpCenterStyles";

function NavLayout() {
  // Translation
  const { i18n } = useTranslation();
  const { t } = useTranslation(["helpcenter"]);

  //Styles
  const classes = HelpCenterStyles();

  //Redux Hooks
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const { globalCountryId, globalBrandId, globalLanguageId } = useSelector(
    (state: IRootState) => state.global
  );

  // Ready Page
  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  /* Functions */
  const handleGlobalLanguage = (languageId: number, languageCode: string) => {
    localStorage.setItem("globalLanguageId", languageId.toString());
    localStorage.setItem("globalLanguageCode", languageCode);
    dispatch(setGlobalLanguage(languageId));
    i18n.changeLanguage(languageCode);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Box
        style={{ display: "flex", flexDirection: "column", minHeight: "90vh" }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Container maxWidth="xl" style={{ padding: 0 }}>
              <Grid container justify="space-between">
                <Grid item xs={6} sm={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap={24}
                    minHeight={50}
                  >
                    <Link href="/ayuda" className={classes.appLogo}>
                      <img
                        src={String(logo)}
                        alt={`Logo de ${process.env.REACT_APP_NAME}`}
                      ></img>
                    </Link>
                    <Typography color="textSecondary">|</Typography>
                    <Typography color="textSecondary">
                      {t("helpcenter")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap={24}
                    justifyContent="flex-end"
                    minHeight={50}
                  >
                    <Box display="flex" alignItems="center" gridGap={24}>
                      <Button
                        href="/inicio"
                        variant="contained"
                        color="primary"
                      >
                        Volver a {process.env.REACT_APP_NAME}
                      </Button>
                    </Box>
                    {authUser !== null &&
                      authUser.languages_name !== undefined &&
                      globalLanguageId !== null &&
                      authUser.languages_name.find(
                        (language: any) => language.id == globalLanguageId
                      ) !== undefined && (
                        <FormControl className={classes.formLenguage}>
                          <TranslateRounded fontSize="small" />
                          <InputLabel id="legnuages"></InputLabel>
                          <Select
                            labelId="legnuages"
                            id="optionsLenguage"
                            defaultValue={
                              globalLanguageId !== null ? globalLanguageId : 1
                            }
                            className={classes.inputLenguage}
                          >
                            {authUser.languages_name.map((language: any) => (
                              <MenuItem
                                key={language.id}
                                onClick={() =>
                                  handleGlobalLanguage(
                                    language.id,
                                    language.code
                                  )
                                }
                                value={language.id}
                              >
                                {language.code.toUpperCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
        <Switch>
          <Route exact path="/ayuda" component={Home} />
          <Route exact path="/ayuda/contacto" component={ContactForm} />
          <Route exact path="/ayuda/tutoriales/:type" component={Section} />
          <Route
            exact
            path="/ayuda/tutoriales/:type/:section/:questionid"
            component={SectionDetail}
          />
          <Route exact path="/ayuda/comoempezar" component={SectionStart} />
          <Route
            exact
            path="/ayuda/comoempezar/info/:section/:questionid"
            component={SectionStartQuestion}
          />
          <Route component={NotFound} />
        </Switch>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default NavLayout;
