import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_GET_RESOURCECATEGORIES, SUCCESS_GET_RESOURCECATEGORIES, ERROR_GET_RESOURCECATEGORIES,
    START_GET_RESOURCECATEGORIES_OPTIONS, SUCCESS_GET_RESOURCECATEGORIES_OPTIONS, ERROR_GET_RESOURCECATEGORIES_OPTIONS,
    START_SHOW_RESOURCECATEGORY, SUCCESS_SHOW_RESOURCECATEGORY, ERROR_SHOW_RESOURCECATEGORY,
    START_STORE_RESOURCECATEGORY, SUCCESS_STORE_RESOURCECATEGORY, ERROR_STORE_RESOURCECATEGORY,
    START_UPDATE_RESOURCECATEGORY, SUCCESS_UPDATE_RESOURCECATEGORY, ERROR_UPDATE_RESOURCECATEGORY,
    START_DELETE_RESOURCECATEGORY, SUCCESS_DELETE_RESOURCECATEGORY, ERROR_DELETE_RESOURCECATEGORY,
    START_TOGGLE_FORM_RESOURCECATEGORIES, SUCCESS_TOGGLE_FORM_RESOURCECATEGORIES
} from '../types';
import { apiCall } from '../config/axios';

function* getResourcecategories({payload}) {
    try{
        const response = yield call(apiCall, 'post', '/resourcecategories/index', {resourcecategory: payload})
        if (response.data.error)
            throw new Error(response.data.msg);
            
        yield put ({ type: SUCCESS_GET_RESOURCECATEGORIES, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_RESOURCECATEGORIES, payload: error.message})
    }
}

function* getResourcecategoriesOptions({payload}) {
    try{
        const response = yield call(apiCall, 'post', '/resourcecategories/index', {resourcecategory: payload})
        if (response.data.error)
            throw new Error(response.data.msg);
            
        yield put ({ type: SUCCESS_GET_RESOURCECATEGORIES_OPTIONS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_RESOURCECATEGORIES_OPTIONS, payload: error.message})
    }
}

function* showResourcecategory({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/resourcecategories/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_SHOW_RESOURCECATEGORY, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_SHOW_RESOURCECATEGORY, payload: error.message})
    }
}

function* storeResourcecategory({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/resourcecategories/store', {resourcecategory: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_STORE_RESOURCECATEGORY, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_STORE_RESOURCECATEGORY, payload: error.message})
    }
}

function* updateResourcecategory({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/resourcecategories/update', {resourcecategory: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_UPDATE_RESOURCECATEGORY, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_UPDATE_RESOURCECATEGORY, payload: error.message})
    }
}



function* deleteResourcecategory({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/resourcecategories/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_DELETE_RESOURCECATEGORY, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_DELETE_RESOURCECATEGORY, payload: error.message})
    }
}

function* toggleFormResourcecategories() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_RESOURCECATEGORIES})
}

export default function* resourcecategories() {
    yield takeLatest(START_GET_RESOURCECATEGORIES, getResourcecategories);
    yield takeLatest(START_GET_RESOURCECATEGORIES_OPTIONS, getResourcecategoriesOptions);
    yield takeLatest(START_SHOW_RESOURCECATEGORY, showResourcecategory);
    yield takeLatest(START_STORE_RESOURCECATEGORY, storeResourcecategory);
    yield takeLatest(START_UPDATE_RESOURCECATEGORY, updateResourcecategory);
    yield takeLatest(START_DELETE_RESOURCECATEGORY, deleteResourcecategory);
    yield takeLatest(START_TOGGLE_FORM_RESOURCECATEGORIES, toggleFormResourcecategories);
}