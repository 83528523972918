// React
import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

// Redux Actions
import { getUserbrands } from '../../redux/actions/brands';
import { verifyUser } from '../../redux/actions/auth'
import { IRootState } from '../../redux/types';

const PrivateRoute = ({component: Component, ...props}: any) => {
    // Redux Hooks
    const dispatch = useDispatch();
    const { isAuth, user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userBrands } = useSelector((state: IRootState) => state.brand);
    if (!localStorage.getItem('isAuth') && !isAuth) {
        // localStorage.setItem('login_redirect', window.location.pathname + window.location.search);
    }

    // Ready Page
    useEffect(() => {
        const lastRequestMe: number = parseInt(localStorage.getItem('lastRequestMe') || "0");
        const dateNow: number = Date.now()
        const milisecondsDiff: number = dateNow - lastRequestMe
        if ( milisecondsDiff > 900000 || authUser === null || !authUser?.me === true )
            dispatch(verifyUser());

        if (!userBrands.length)
            dispatch(getUserbrands());
    }, []);

    return ( 
        <Route { ...props } render={ props => !localStorage.getItem('isAuth') && !isAuth ? (
            <Redirect to="/" />
        ) : (
            authUser !== null && authUser.me === true && userBrands.length > 0 &&
                <Component {...props} />
        )}/>
    );
}
 
export default PrivateRoute;