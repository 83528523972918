// React
import { useState, useEffect, forwardRef, ReactElement, Ref } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    Dialog,
    Tab,
    Tabs,
    Slide,
    FormControl,
    TextField,
    Typography,
    Grid,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { CloseRounded, Description, Videocam } from '@material-ui/icons'

// Custom components
import {
    Dropzone,
    BrandAutocomplete,
    LineAutocomplete,
    ResourcetypeSelect,
    RoleAutocomplete,
    LanguageMultiAutocomplete,
    VisibilityAutocomplete,
    ResourcecategoryAutocomplete,
    SaveButton,
    CancelButton,
    DeleteButton,
} from '../common/forms'
import TabPanel from '../common/admin/TabPanel'
import InputCountry from '../common/admin/modal/InputCountry'

// Redux Actions
import { IRootState } from '../../redux/types'
import {
    toggleFormEditResources,
    updateResource,
    deleteResource,
} from '../../redux/actions/resources'
import { getFilteredCountries } from '../../redux/actions/countries';
import { getLanguages } from '../../redux/actions/languages'
import { getRoles } from '../../redux/actions/roles'
import { getResourcetypes } from '../../redux/actions/resourcetypes'
import { getResourcecategories } from '../../redux/actions/resourcecategories'
import { getResourcecategoriesOptions } from '../../redux/actions/resourcecategories'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'
import ResourceStyle from '../../styles/components/library/ResourceStyle'

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

// Types
interface IResource {
    id: number
    fileName: string | null
    cover: null | string
    alias: null | string
    resourcetypeId: null | number
    category: any
    languages: number[]
    brand: null | any
    line: null | any
    countries: number[]
    role: any
    visibility: any
    description: string | null
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

function ResourceModal() {
    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    const visibility = [
        { id: 0, name: t('private') },
        { id: 1, name: 'VIP' },
        { id: 2, name: t('public') },
    ]

    //Styles
    const formClasses = FormsStyles()
    const resourceClasses = ResourceStyle()

    //Redux Hooks
    const dispatch = useDispatch()
    const {
        globalCountriesId,
        globalLanguageId,
        globalFamilyId,
        globalBrandId,
    } = useSelector((state: IRootState) => state.global)
    const { resourceSelected, showEditForm } = useSelector(
        (state: IRootState) => state.resource
    )
    const { isValidForm, errorForm, errorTab, msgForm } = useSelector(
        (state: IRootState) => state.validator
    )
    const { resourcecategories: categories } = useSelector(
        (state: IRootState) => state.resourcecategory
    )
    const { languages } = useSelector((state: IRootState) => state.language)
    const { roles } = useSelector((state: IRootState) => state.role)
    const { countries } = useSelector((state: IRootState) => state.country)
    const { resourcetypes } = useSelector(
        (state: IRootState) => state.resourcetype
    )

    // Resource state
    const [resource, setResource] = useState<IResource>({
        id: 0,
        fileName: null,
        cover: null,
        alias: null,
        resourcetypeId: null,
        category: [],
        languages: [],
        brand: [],
        line: [],
        countries: [],
        role: [],
        visibility: [],
        description: null,
    })
    //Tab State
    const [currentTab, setCurrentTab] = useState(0)

    useEffect(() => {
        if (!countries.length) dispatch(getFilteredCountries());
        if (!roles.length) dispatch(getRoles())
        if (!languages.length) dispatch(getLanguages())
    }, [])

    useEffect(() => {
        if (globalLanguageId !== null) {
            if (!resourcetypes.length) dispatch(getResourcetypes())

            if (!resourcetypes.length)
                dispatch(
                    getResourcecategories({
                        languageId: globalLanguageId,
                        countriesId: globalCountriesId,
                        familyId: globalFamilyId,
                        brandId: globalBrandId,
                    })
                )
        }
    }, [globalLanguageId])

    // Ready Page
    useEffect(() => {
        if (resourceSelected !== null && resourceSelected.id !== resource.id) {
            let objCategory = []
            if (resourceSelected.category_id !== null)
                objCategory = categories.find(
                    (category: any) =>
                        category.id === resourceSelected.category_id
                )

            setResource({
                id: resourceSelected.id,
                fileName: resourceSelected.file_name,
                cover: resourceSelected.cover,
                alias: resourceSelected.alias,
                resourcetypeId: resourceSelected.resourcetype_id,
                category: objCategory,
                languages: resourceSelected.languages.map(
                    (language: any) => language.id
                ),
                brand: resourceSelected.brand,
                line: resourceSelected.line,
                countries: resourceSelected.countriesIds,
                role: resourceSelected.role,
                visibility: visibility.find(
                    (v: any) => v.id === resourceSelected.visibility
                ),
                description: resourceSelected.description,
            })
        }
    }, [resourceSelected])

    // Send Form
    useEffect(() => {
        console.log('isValidForm')
        console.log(resource)
        if (isValidForm) {
            dispatch(resetFormValidator())
            dispatch(updateResource(resource))
        }
    }, [isValidForm])

    const handleSubmit = () => {
        console.log('handleSubmit')
        console.log(resource)
        dispatch(
            formValidator({
                id: [resource.id, 'numeric'],
                brands: [resource.brand, 'notempty'],
                languages: [resource.languages, 'notempty', 'general'],
                countries: [resource.countries, 'notempty', 'general'],
                role: [resource.role, 'notempty', 'general'],
                visibility: [resource.visibility, 'notempty', 'general'],
                resourcetypeId: [resource.resourcetypeId, 'notempty', 'general'],
            })
        )
    }

    /* Functions */
    const handleInputTextChange = (inputName: string) => (event: any) => {
        const value = event.target.value
        setResource({ ...resource, [inputName]: value })
    }

    const handleInputChange = (inputName: string, value: any) => {
        if (inputName === 'brand' && value !== null)
            dispatch(
                getResourcecategoriesOptions({
                    languageId: globalLanguageId,
                    countriesId: globalCountriesId,
                    familyId: null,
                    brandId: value.id,
                })
            )

        setResource({ ...resource, [inputName]: value })
    }

    const TypeFile = () => {
        const fileNameExtension = resourceSelected.ext

        const $imageExtensions = [
            'jpg',
            'JPG',
            'gif',
            'png',
            'jpeg',
            'bmp',
            'jfif',
        ]
        const $fileExtensions = ['pdf', '']
        const $videoExtensions = ['mp4', 'mov', 'wmv', 'avi']
        if ($imageExtensions.includes(fileNameExtension)) {
            return (
                <img
                    src={
                        process.env.REACT_APP_AWS_URL +
                        '/resources/' +
                        resourceSelected.file_name
                    }
                    style={{ maxWidth: '100%', maxHeight: '400px' }}
                    alt="media"
                />
            )
        } else if ($videoExtensions.includes(fileNameExtension))
            return <Videocam />
        else if ($fileExtensions.includes(fileNameExtension)) {
            return (
                <Box className={resourceClasses.media}>
                    <embed
                        src={
                            process.env.REACT_APP_AWS_URL +
                            '/resources/' +
                            resourceSelected.file_name
                        }
                        type="application/pdf"
                        height="560px"
                        className={resourceClasses.embed}
                        width="100%"
                    ></embed>
                </Box>
            )
        } else return <Description />
    }

    return (
        <Dialog
            open={showEditForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            TransitionComponent={Transition}
            onClose={() => dispatch(toggleFormEditResources())}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="dialog-resource-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {t('edit')} media {msgForm.id}
                    </Typography>
                    <Button
                        onClick={() => dispatch(toggleFormEditResources())}
                        color="primary"
                        variant="text"
                    >
                        <CloseRounded />
                    </Button>
                </Grid>
            </DialogTitle>

            <DialogContent className={formClasses.bodyForm}>
                {resourceSelected !== null && (
                    <form noValidate autoComplete="off">
                        <Grid
                            container
                            alignItems="flex-start"
                            justify="space-between"
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                md={resource.brand != null ? 4 : 6}
                            >
                                <FormControl fullWidth variant="filled">
                                    <BrandAutocomplete
                                        value={resource.brand}
                                        handleInputChange={handleInputChange}
                                        props={{ required: true }}
                                    />
                                </FormControl>
                            </Grid>

                            {resource.brand != null && (
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="filled">
                                        <LineAutocomplete
                                            value={resource.line}
                                            brand={resource.brand}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid
                                item
                                xs={12}
                                md={resource.brand != null ? 4 : 6}
                            >
                                <FormControl fullWidth variant="filled">
                                    <ResourcetypeSelect
                                        value={resource.resourcetypeId}
                                        handleInputChange={handleInputChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Tabs
                                id="tabs-edit-resources"
                                value={currentTab}
                                indicatorColor="secondary"
                                textColor="primary"
                                onChange={(event, newValue) =>
                                    setCurrentTab(newValue)
                                }
                                className={formClasses.tapForm}
                                aria-label="disabled tabs example"
                            >
                                <Tab
                                    label={t('general')}
                                    {...a11yProps(0)}
                                    className={
                                        errorTab.general
                                            ? formClasses.errorTab
                                            : ''
                                    }
                                />
                                <Tab
                                    label={t('document')}
                                    {...a11yProps(1)}
                                    className={
                                        errorTab.general
                                            ? formClasses.errorTab
                                            : ''
                                    }
                                />
                                <Tab
                                    label={t('cover-image')}
                                    {...a11yProps(2)}
                                    className={
                                        errorTab.general
                                            ? formClasses.errorTab
                                            : ''
                                    }
                                />
                            </Tabs>
                        </Grid>

                        <TabPanel value={currentTab} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth variant="filled">
                                        <RoleAutocomplete
                                            value={resource.role}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <InputCountry
                                        value={resource.countries}
                                        handleInputChange={handleInputChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth variant="filled">
                                        <LanguageMultiAutocomplete
                                            value={resource.languages}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <VisibilityAutocomplete
                                        value={resource.visibility}
                                        handleInputChange={handleInputChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="filled">
                                        <TextField
                                            autoFocus
                                            id="txt-alias"
                                            label={t('title')}
                                            type="text"
                                            variant="filled"
                                            value={resource.alias}
                                            error={errorForm.alias}
                                            helperText={msgForm.alias}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleInputTextChange(
                                                'alias'
                                            )}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth variant="filled">
                                        <ResourcecategoryAutocomplete
                                            value={resource.category}
                                            resourcetypeId={
                                                resource.resourcetypeId
                                            }
                                            handleInputChange={
                                                handleInputChange
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="filled">
                                        <TextField
                                            autoFocus
                                            multiline
                                            id="txt-description"
                                            label={t('description')}
                                            type="text"
                                            variant="filled"
                                            value={resource.description}
                                            error={errorForm.description}
                                            helperText={msgForm.description}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleInputTextChange(
                                                'description'
                                            )}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={currentTab} index={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        p={1}
                                        display="flex"
                                        flexDirection="column"
                                        width="100%"
                                        justifyContent="center"
                                        my={2}
                                        borderRadius={8}
                                        gridGap={8}
                                        style={{ backgroundColor: '#FAFAFA' }}
                                    >
                                        <Dropzone
                                            name={'fileName'}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                        />

                                        {(resource.fileName === null ||
                                            resource.fileName ===
                                            resourceSelected.file_name) && (
                                                <>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                    >
                                                        {t(
                                                            'admin.library.preview'
                                                        ) + ':'}
                                                    </Typography>
                                                    <TypeFile />
                                                </>
                                            )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={currentTab} index={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {t('cover-image') + ':'}
                                    </Typography>
                                    <Dropzone
                                        name={'cover'}
                                        handleInputChange={handleInputChange}
                                    />
                                    {resourceSelected.cover !== null &&
                                        resourceSelected.cover ===
                                        resource.cover && (
                                            <Box
                                                p={1}
                                                display="grid"
                                                justifyContent="center"
                                                my={2}
                                                borderRadius={8}
                                                gridGap={8}
                                                style={{
                                                    backgroundColor: '#FAFAFA',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {t(
                                                        'admin.library.preview'
                                                    ) + ':'}
                                                </Typography>
                                                <img
                                                    src={
                                                        process.env
                                                            .REACT_APP_AWS_URL +
                                                        '/resources/' +
                                                        resourceSelected.cover
                                                    }
                                                    width="75%"
                                                    height="auto"
                                                    alt={resourceSelected.cover}
                                                />
                                            </Box>
                                        )}
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </form>
                )}
            </DialogContent>

            <DialogActions
                className={
                    !isValidForm && Object.keys(msgForm).length > 0
                        ? formClasses.errorDialogActions
                        : ''
                }
            >
                <DeleteButton id='btn-delete-resource'
                    onClick={() => dispatch(deleteResource(resource.id))}
                >
                    {t('delete')}
                </DeleteButton>
                <CancelButton id='btn-cancel-resource'
                    onClick={() => dispatch(toggleFormEditResources())}
                >
                    {t('cancel')}
                </CancelButton>
                <SaveButton id='btn-save-resource' onClick={() => handleSubmit()}>
                    {t('save')}
                </SaveButton>
            </DialogActions>
        </Dialog>
    )
}

export default ResourceModal
