import brands from './brands';
import lessons from './lessons';
import languages from './languages';
import roles from './roles';
import countries from './countries';
import courses from './courses';
import coursetypes from './coursetypes';
import validator from './validator';
import auth from './auth';
import users from './users';
import resources from './resources';
import resourcetypes from './resourcetypes';
import resourcecategories from './resourcecategories';
import terms from './terms';
import sliders from './sliders';
import modal from './modal';
import events from './events';
import global from './global';
import offers from './offers';
import log from './log';
import tags from './tags';
import novelties from './novelties';
import usersettings from './usersettings';
import menus from './menus';
import help from './help';
import families from './families';
import testimonials from './testimonials';
import notifications from './notifications';
import seminars from './seminars';
import seminarlesson from './seminarlesson';
import seminartypes from './seminartypes';
import favorites from './favorites';
import integrations from './integrations';
import userdisplaytime from './userdisplaytime';
import generalsummaryreport from './generalsummaryreport';
import reports from './reports';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
    yield all([
        brands(),
        courses(),
        coursetypes(),
        validator(),
        auth(),
        languages(),
        lessons(),
        roles(),
        countries(),
        users(),
        resources(),
        resourcetypes(),
        resourcecategories(),
        terms(),
        sliders(),
        modal(),
        events(),
        global(),
        offers(),
        log(),
        tags(),
        novelties(),
        usersettings(),
        menus(),
        help(),
        families(),
        testimonials(),
        notifications(),
        seminars(),
        seminarlesson(),
        seminartypes(),
        favorites(),
        integrations(),
        userdisplaytime(),
        generalsummaryreport(),
        reports(),
    ]);
}