// React
import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, Dialog, TextField, Chip, Typography, Grid, FormControl, IconButton,
    DialogContent, DialogTitle, DialogContentText, DialogActions, Tabs, Tab, Tooltip
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table'
import { localization, optionsSelect, tableIcons } from '../../config/table';
import { CloseRounded, ArrowForward, ArrowBack, TapAndPlay } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Custom components
import { CancelButton, SaveButton } from '../common/forms';
import TabPanel from '../common/admin/TabPanel';

// Custom Hook
import useBooleanState from '../../hooks/useBooleanState';
import useScreenWidth from '../../hooks/useScreenWidth';

// Redux Actions
import { toggleFormLinkUsers, linkUsers } from '../../redux/actions/users';
import { storeLocation } from '../../redux/actions/countries';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

interface ILocation {
    input: string,
    name: string,
    province: any
}

type UserTransferModalProps = {
    showBotton?: boolean,
}

interface IFilter {
    brandsL: any,
    countriesL: any,
    provinceL: any,
    postalcodesL: any,
    brandsR: any,
    countriesR: any,
    provinceR: any,
    postalcodesR: any,
    typeLink: number
}

const tableStyles = makeStyles((theme: Theme) =>
    createStyles({
        customTable: {
            "& h6": {
                display: 'none',
            }
        },
    }),
);

function UserTransferModal({ showBotton = true }: UserTransferModalProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const formClasses = FormsStyles();
    const clsassesTable = tableStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userProfileSelected, dependents, notdependents, showLinkForm } = useSelector((state: IRootState) => state.user);
    const { countries, provinces, postalcodes } = useSelector((state: IRootState) => state.country);
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    //Tab State
    const [currentTab, setCurrentTab] = useState(0);
    // Component States
    const [open, toggleOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [parent, setParent] = useState<any>(null);
    const [disableParentInput, setDisableParentInput] = useBooleanState(false);
    const [usersFiltered, setUsersFiltered] = useState({
        left: [],
        right: []
    });
    const [selectedLeftRows, setSelectedLeftRows] = useState<any>([]);
    const [selectedRightRows, setSelectedRightRows] = useState<any>([]);
    const [tmpRows, setTmpRows] = useState({
        left: dependents,
        right: []
    });
    const [rightOriginalRows, setRightOriginalRows] = useState([]);
    const [filters, setFilters] = useState<IFilter>({
        brandsL: [],
        countriesL: [],
        provinceL: [],
        postalcodesL: [],
        brandsR: [],
        countriesR: [],
        provinceR: [],
        postalcodesR: [],
        typeLink: 1
    });
    const [createLocation, setCreateLocation] = useState<ILocation>({
        input: '',
        name: '',
        province: null
    });

    useEffect(() => {
        applyUsersFilter();
    }, [filters]);

    useEffect(() => {
        applyUsersFilter();
    }, [tmpRows]);

    useEffect(() => {
        if (parent !== null) {
            if (JSON.stringify(rightOriginalRows) !== JSON.stringify(tmpRows.right)) {
                setDisableParentInput(true);
            } else {
                setDisableParentInput(false);
            }
        }
    }, [tmpRows.right]);

    useEffect(() => {
        setTmpRows({ ...tmpRows, left: dependents, right: [] });
    }, [dependents]);

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleCloseModalLocations = () => {
        setCreateLocation({
            input: '',
            name: '',
            province: null
        });

        toggleOpen(false);
    };

    const handleSubmitCreteLocation = (event: any) => {
        event.preventDefault();
        dispatch(storeLocation(createLocation));

        handleCloseModalLocations();
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleAutoCompleteChange = (inputName: string) => (event: any, value: any) => {

        // Parent Filter
        if (inputName === 'parent') {
            let usersFilteredR: any = [];
            if (value !== null) {
                let parentFilter: any = [];
                notdependents.map((user: any) => {
                    if (user.parents !== null && user.parents.split(',').includes(value.id.toString())) {
                        parentFilter.push(user);
                    }
                });
                usersFilteredR = parentFilter;
                setParent(value);
            } else
                setParent(null);

            setTmpRows({ ...tmpRows, right: usersFilteredR });
            setRightOriginalRows(usersFilteredR);
        } else {
            if (value !== null)
                setFilters({ ...filters, [inputName]: value });
            else
                setFilters({ ...filters, [inputName]: [] });
        }
    }

    const handleMultiAutoCompleteChange = (inputName: string) => (event: any, value: any) => {
        let optionsMulti = [];
        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                if (['postalcodesL', 'postalcodesR'].includes(inputName)) {
                    optionsMulti.push(value[i].code);
                } else {
                    optionsMulti.push(value[i].id);
                }
            }
        }

        setFilters({ ...filters, [inputName]: optionsMulti });
    }

    // Manage tranfer list movement
    const handleToogle = (toward: string) => {
        let leftRowsResult: any = [];
        let rigthRowsResult: any = [];
        let filter_ids: any = [];
        if (toward === '>') {
            // Set ckecked props of selected rows to false
            let selectedLeftRowsPure = [...selectedLeftRows];
            for (const index in selectedLeftRowsPure) {
                if (Object.prototype.hasOwnProperty.call(selectedLeftRowsPure, index)) {
                    selectedLeftRowsPure[index].tableData.checked = false;
                    filter_ids.push(selectedLeftRowsPure[index].id);

                    // Avoid assign duplicate rows
                    if (tmpRows.right.some((rightRow: any) => rightRow.id === selectedLeftRowsPure[index].id))
                        selectedLeftRowsPure.splice(parseInt(index), 1);
                }
            }

            // Add to right list, Rows checked from left
            rigthRowsResult = [...tmpRows.right, ...selectedLeftRowsPure];

            // Delete selected rows
            if (filters.typeLink === 1) {
                leftRowsResult = tmpRows.left.filter((row: any) => !filter_ids.includes(row.id));
            } else {
                leftRowsResult = tmpRows.left;
            }

            setTmpRows({ ...tmpRows, left: leftRowsResult, right: rigthRowsResult });
            setSelectedLeftRows([]);
        } else
            if (toward === '<') {
                // Set ckecked props of selected rows to false
                let selectedRightRowsPure = [...selectedRightRows];
                for (const index in selectedRightRowsPure) {
                    if (Object.prototype.hasOwnProperty.call(selectedRightRowsPure, index)) {
                        selectedRightRowsPure[index].tableData.checked = false;
                        filter_ids.push(selectedRightRowsPure[index].id);

                        // Avoid assign duplicate rows
                        if (tmpRows.left.some((leftRow: any) => leftRow.id === selectedRightRowsPure[index].id))
                            selectedRightRowsPure.splice(parseInt(index), 1);
                    }
                }

                // Add to left list, Rows checked from right
                leftRowsResult = [...tmpRows.left, ...selectedRightRowsPure];

                // Delete selected rows
                rigthRowsResult = tmpRows.right.filter((row: any) => !filter_ids.includes(row.id));

                setTmpRows({ ...tmpRows, right: rigthRowsResult, left: leftRowsResult });
                setSelectedRightRows([]);
            }
    }

    const handleSelectionRows = (side: string, rows: any) => {
        if (side === 'left')
            setSelectedLeftRows(rows);
        else
            if (side === 'right')
                setSelectedRightRows(rows);
    }

    const customList = (side: string, data: any) => (
        <MaterialTable
            localization={localization}
            title="Usuarios Admin"
            columns={columns}
            data={data}
            actions={[]}
            options={optionsSelect}
            onSelectionChange={(rows) => handleSelectionRows(side, rows)}
            icons={tableIcons}
            components={{
                Toolbar: props => (
                    <div className={clsassesTable.customTable}>
                        <MTableToolbar {...props} />
                    </div>
                )
            }}
        />
    );

    const handleModalCreate = (type: number) => {
        // Reset filters
        setFilters({
            brandsL: [],
            countriesL: [],
            provinceL: [],
            postalcodesL: [],
            brandsR: [],
            countriesR: [],
            provinceR: [],
            postalcodesR: [],
            typeLink: type
        });

        // Reset tables
        setTmpRows({ ...tmpRows, left: dependents, right: [] });

        // Clean parent 
        setParent(null);

        // Open modal
        dispatch(toggleFormLinkUsers());


    }

    const applyUsersFilter = async () => {
        let usersFilteredR: any = tmpRows.right.filter((row: any) => row.role_id !== userProfileSelected.role_id);
        let usersFilteredL: any = tmpRows.left.filter((row: any) => row.role_id !== userProfileSelected.role_id);

        // Left Side
        // Postalcodes Filter
        if (filters.postalcodesL.length) {
            let postalcodesFilter: any = [];
            await usersFilteredL.map((user: any) => {
                filters.postalcodesL.map((postalcode: any) => {
                    if (user.postalcode === postalcode)
                        postalcodesFilter.push(user);
                })
            });
            usersFilteredL = postalcodesFilter;
        }

        // Province Filter
        if (filters.provinceL.id !== undefined) {
            let provinceFilter: any = [];
            await usersFilteredL.map((user: any) => {
                if (user.province_id === filters.provinceL.id) {
                    provinceFilter.push(user);
                }
            });
            usersFilteredL = provinceFilter;
        }

        // Brands Filter
        if (filters.brandsL.length) {
            let brandFilter: any = [];
            await usersFilteredL.map((user: any) => {
                filters.brandsL.map((brand: any) => {
                    if (user.brands_ids.split(',').includes(brand.toString()))
                        brandFilter.push(user);
                })
            });
            usersFilteredL = brandFilter;
        }

        // Countries Filter
        if (filters.countriesL.length) {
            let countriesFilter: any = [];
            await usersFilteredL.map((user: any) => {
                filters.countriesL.map((country: any) => {
                    if (user.countries_ids.split(',').includes(country.toString()))
                        countriesFilter.push(user);
                })
            });
            usersFilteredL = countriesFilter;
        }

        // Right Side

        // Postalcodes Filter
        if (filters.postalcodesR.length) {
            let postalcodesFilter: any = [];
            await usersFilteredR.map((user: any) => {
                filters.postalcodesR.map((postalcode: any) => {
                    if (user.postalcode === postalcode)
                        postalcodesFilter.push(user);
                })
            });
            usersFilteredR = postalcodesFilter;
        }

        // Province Filter
        if (filters.provinceR.id !== undefined) {
            let provinceFilter: any = [];
            await usersFilteredR.map((user: any) => {
                if (user.province_id === filters.provinceR.id) {
                    provinceFilter.push(user);
                }
            });
            usersFilteredR = provinceFilter;
        }

        // Brands Filter
        if (filters.brandsR.length) {
            let brandFilter: any = [];
            await usersFilteredR.map((user: any) => {
                filters.brandsR.map((brand: any) => {
                    if (user.brands_ids.split(',').includes(brand.toString()))
                        brandFilter.push(user);
                })
            });
            usersFilteredR = brandFilter;
        }

        // Countries Filter
        if (filters.countriesR.length) {
            let countriesFilter: any = [];
            await usersFilteredR.map((user: any) => {
                filters.countriesR.map((country: any) => {
                    if (user.countries_ids.split(',').includes(country.toString()))
                        countriesFilter.push(user);
                })
            });
            usersFilteredR = countriesFilter;
        }

        setUsersFiltered({ ...usersFiltered, right: usersFilteredR, left: usersFilteredL });
    }

    const handleSubmit = () => {
        // Get user ids of Trasnfer Lists
        let rows: any = { ...tmpRows };
        let leftUserIds: number[] = [];
        let rightUserIds: number[] = [];
        for (const key in rows) {
            if (Object.prototype.hasOwnProperty.call(rows, key)) {
                for (const index in rows[key]) {
                    if (Object.prototype.hasOwnProperty.call(rows[key], index)) {
                        if (key === 'left')
                            leftUserIds.push(rows[key][index].id);
                        else
                            if (key === 'right')
                                rightUserIds.push(rows[key][index].id);
                    }
                }
            }
        }

        // Save Link Users
        dispatch(linkUsers({
            userProfileId: userProfileSelected.id,
            parent_id: Object.keys(parent) ? parent.id : null,
            leftUserIds,
            rightUserIds
        }));
    }

    const columns: any = [
        { title: t("id"), field: 'id' },
        { title: t("name"), field: 'name', type: string },
        { title: t("email"), field: 'email', type: string },
        { title: t("postalcode"), field: 'postalcode', type: string },
        { title: t("rol"), field: 'role', type: string },
    ];

    return (
        <>
            {showBotton && [1, 2, 3].includes(authUser.role_id) &&
                <>
                    <Grid container spacing={2} justify={isMobile ? 'space-around' : 'flex-start'} >
                        <Grid item>
                            <Tooltip title={`${t("admin.people.link-people-message")}`}>
                                <Button variant="contained" color="primary" onClick={() => handleModalCreate(2)} size="small">
                                    {t("admin.people.link-people")}
                                </Button>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip title={`${t("admin.people.change-link-message")}`}>
                                <Button variant="outlined" color="primary" onClick={() => handleModalCreate(1)} size="small">
                                    {t("admin.people.change-link")}
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </>
            }


            <Dialog
                open={showLinkForm}
                fullWidth={true}
                fullScreen={true}
                className={formClasses.containerForm}
                onClose={() => dispatch(toggleFormLinkUsers())}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {filters.typeLink === 1 ? t("admin.people.change-link") : t("admin.people.link-people")}
                        </Typography>
                        <Button onClick={() => dispatch(toggleFormLinkUsers())}
                            color="primary" variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>


                <DialogContent className={formClasses.bodyForm}>
                    <form noValidate autoComplete="nope">
                        <Grid container alignItems="flex-start" justify="space-between" spacing={2}>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label={t("admin.people.person-in-charge")}
                                    fullWidth
                                    disabled
                                    margin="normal"
                                    value={userProfileSelected.name}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled" />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="filled">
                                    <Autocomplete
                                        id="combo-box-people"
                                        value={parent}
                                        onChange={handleAutoCompleteChange('parent')}
                                        disabled={disableParentInput}
                                        options={notdependents.filter((user: any) => user.role_id !== 6)}
                                        getOptionLabel={(option: any) => option.id !== undefined ? `${option.name} <${option.email}>` : ''}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={`${t("search")} ${t("admin.people.people-linked-with")}`}
                                                variant="filled"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid item xs={12}>
                            <Tabs
                                value={currentTab}
                                indicatorColor="secondary"
                                textColor="primary"
                                onChange={handleChangeTab}
                                className={formClasses.tapForm}
                                aria-label="disabled tabs example">

                                <Tab label={t("admin.people.quick")}  {...a11yProps(0)} />
                                <Tab label={t("admin.people.advanced")}  {...a11yProps(1)} />
                            </Tabs>
                        </Grid>

                        <TabPanel value={currentTab} index={0}>

                        </TabPanel>

                        <TabPanel value={currentTab} index={1}>

                            <Grid container spacing={2}>

                                <Grid item xs={12} md={true}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-brandsL"
                                                ChipProps={{ color: 'secondary' }}
                                                value={userBrands.filter((brand: any) => filters.brandsL.includes(brand.id))}
                                                onChange={handleMultiAutoCompleteChange('brandsL')}
                                                options={userBrands}
                                                multiple
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip key={option.name} label={option.name} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => <TextField {...params} label={t("brands")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-countriesL"
                                                ChipProps={{ color: 'secondary' }}
                                                value={countries.filter((country: any) => filters.countriesL.includes(country.id))}
                                                onChange={handleMultiAutoCompleteChange('countriesL')}
                                                options={authUser.role_id >= 2 ? countries.filter((country: any) => authUser.countries.includes(country.id)) : countries}
                                                multiple
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            key={option.name}
                                                            color="secondary"
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={t("countries")}
                                                        variant="filled"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-provincesL"
                                                value={filters.provinceL}
                                                onChange={handleAutoCompleteChange('provinceL')}
                                                options={provinces.filter((province: any) => filters.countriesL.includes(province.country_id))}
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={t("province")}
                                                        variant="filled"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-postalcodesL"
                                                value={postalcodes.filter((postalcode: any) => filters.postalcodesL.includes(postalcode.code))}
                                                onChange={handleMultiAutoCompleteChange('postalcodesL')}
                                                options={postalcodes.filter((postalcode: any) => filters.provinceL.id === postalcode.province_id)}
                                                multiple
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.code : ''}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={t("postalcode")}
                                                        variant="filled"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* --------------------------------------------------------- */}

                                <Grid item xs={12} md={1}> </Grid>

                                <Grid item xs={12} md={true}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-brandsR"
                                                ChipProps={{ color: 'secondary' }}
                                                value={userBrands.filter((brand: any) => filters.brandsR.includes(brand.id))}
                                                onChange={handleMultiAutoCompleteChange('brandsR')}
                                                options={userBrands}
                                                multiple
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip key={option.name} label={option.name} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => <TextField {...params} label={t("brands")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-countriesR"
                                                ChipProps={{ color: 'secondary' }}
                                                value={countries.filter((country: any) => filters.countriesR.includes(country.id))}
                                                onChange={handleMultiAutoCompleteChange('countriesR')}
                                                options={authUser.role_id >= 2 ? countries.filter((country: any) => authUser.countries.includes(country.id)) : countries}
                                                multiple
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            key={option.name}
                                                            color="secondary"
                                                            label={option.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={t("countries")}
                                                        variant="filled"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-provincesR"
                                                value={filters.provinceR}
                                                onChange={handleAutoCompleteChange('provinceR')}
                                                options={provinces.filter((province: any) => filters.countriesR.includes(province.country_id))}
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={t("province")}
                                                        variant="filled"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                id="combo-box-postalcodesR"
                                                value={postalcodes.filter((postalcode: any) => filters.postalcodesR.includes(postalcode.code))}
                                                onChange={handleMultiAutoCompleteChange('postalcodesR')}
                                                options={postalcodes.filter((postalcode: any) => filters.provinceR.id === postalcode.province_id)}
                                                multiple
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.code : ''}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        label={t("postalcode")}
                                                        variant="filled"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </TabPanel>

                        <Grid container spacing={2} justify="center" alignItems="center" style={{ paddingTop: 8 }}>
                            <Grid item xs={12} md={true}>
                                <div className="table_custom white border">
                                    {customList('left', usersFiltered.left)}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Grid container direction="column" alignItems="center">
                                    <IconButton
                                        onClick={() => handleToogle('>')}
                                        disabled={selectedLeftRows.length === 0 || parent === null}
                                        color="secondary"
                                        aria-label="move selected right">
                                        <ArrowForward />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleToogle('<')}
                                        disabled={selectedRightRows.length === 0 || parent === null}
                                        aria-label="move selected left"
                                        color="secondary">
                                        <ArrowBack />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={true}>
                                <div className="table_custom white border">
                                    {customList('right', usersFiltered.right)}
                                </div>
                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>

                <DialogActions>
                    <CancelButton onClick={() => dispatch(toggleFormLinkUsers())} aria-label="close">
                        {t("cancel")}
                    </CancelButton>

                    <SaveButton autoFocus id="btn-handlelogin" disabled={!parent}
                        onClick={() => handleOpenConfirm()}>
                        {t("save")}
                    </SaveButton>
                </DialogActions>

            </Dialog>

            {/* Modal de confirmación */}
            <Dialog
                open={openConfirm}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={handleCloseModalLocations}
                aria-labelledby="form-dialog-title">

                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t("continue")} {filters.typeLink === 1 ? t("admin.people.change-link") : t("admin.people.link-people")}
                        </Typography>
                        <Button onClick={handleCloseModalLocations} color='primary' variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} >
                            <Typography>
                                {filters.typeLink === 1 ? t("admin.people.change-link-message") : t("admin.people.link-people-message")}
                                <br />
                                {t("confirm-action")}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <CancelButton onClick={() => handleCloseConfirm()} aria-label="close">
                        {t("cancel")}
                    </CancelButton>

                    <SaveButton autoFocus id="btn-handlelogin"
                        onClick={() => { handleSubmit(); handleCloseConfirm() }}>
                        {t("allow")}
                    </SaveButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={handleCloseModalLocations}
                aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmitCreteLocation}>
                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t("add")}{createLocation.input === 'town' ? 'Municipio' : 'Codigo Postal'}
                            </Typography>
                            <Button onClick={handleCloseModalLocations} color='primary' variant="text"><CloseRounded /></Button>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={formClasses.bodyForm}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} >
                                <Typography>
                                    No encontraste lo que buscabas, Agregalo!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    value={createLocation.name}
                                    onChange={(event) => setCreateLocation({ ...createLocation, name: event.target.value })}
                                    label={createLocation.input === 'town' ? 'Nombre' : 'Codigo'}
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>

                        <Button onClick={handleCloseModalLocations}
                            color="primary">
                            {t("cancel")}
                        </Button>

                        <Button type="submit" color="primary">
                            {t("add")}
                        </Button>

                    </DialogActions>

                </form>
            </Dialog>

        </>
    );
}

export default UserTransferModal;
