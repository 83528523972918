import { 
    IIntegrationState,
    START_GET_INTEGRATIONS, SUCCESS_GET_INTEGRATIONS, ERROR_GET_INTEGRATIONS,
    START_UPDATE_INTEGRATION, SUCCESS_UPDATE_INTEGRATION, ERROR_UPDATE_INTEGRATION,
    START_SHOW_INTEGRATION, SUCCESS_SHOW_INTEGRATION, ERROR_SHOW_INTEGRATION,
    START_TOGGLE_FORM_INTEGRATIONS, SUCCESS_TOGGLE_FORM_INTEGRATIONS
 } from '../types';
    
const initialState: IIntegrationState = {
    integrations: [],
    integrationSelected: [],
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_INTEGRATIONS:
        case START_UPDATE_INTEGRATION:
        case START_SHOW_INTEGRATION:
        case START_TOGGLE_FORM_INTEGRATIONS:
            return{
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_INTEGRATIONS:             
            return{
                ...state,
                integrations: action.payload.integrations,
                msg: null,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_SHOW_INTEGRATION:             
            return{
                ...state,
                integrationSelected: action.payload.integration,
                msg: null,
                error: false,
                loading: false,
                showForm: true
            }
        case SUCCESS_UPDATE_INTEGRATION:
            return{
                ...state,
                integrations: state.integrations.map((integration: any) => 
                    integration.id === action.payload.integration.id ? action.payload.integration : integration 
                ),
                msg: null,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_TOGGLE_FORM_INTEGRATIONS:
            return{
                ...state,
                showForm: !state.showForm,
                loading: false
            }
        case ERROR_GET_INTEGRATIONS:
        case ERROR_UPDATE_INTEGRATION:
        case ERROR_SHOW_INTEGRATION:
            return{
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}