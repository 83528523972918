import {
    ICoursetypeState,
    START_GET_COURSETYPES, SUCCESS_GET_COURSETYPES, ERROR_GET_COURSETYPES
} from '../types';

const initialState: ICoursetypeState = {
    coursetypes: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_COURSETYPES:
            return {
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_COURSETYPES:
            return {
                ...state,
                coursetypes: action.payload.coursetypes,
                msg: null,
                error: false,
                loading: false
            }
        case ERROR_GET_COURSETYPES:
            return {
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}