// React
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

// MATERIAL UI
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Styles
import DocumentStyles from '../../../styles/components/common/DetailPanelStyle';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import Visibility from '../../common/client/Visibility';
import { AddRounded, CloudDownloadRounded, EditRounded, FiberManualRecord, FileCopyRounded, LocalActivityRounded } from '@material-ui/icons';
import FavoriteButton from '../../common/FavoriteButton';

type DataObject = {
    edit: string,
    add: string
}

type DetailHeroPanelProps = {
    visibility?: number | null,
    brand?: string | null,
    title: string,
    author?: string | null,
    status?: number | null,
    statusC?: ReactElement | null,
    description?: string | null,
    onClickFavorite?: (state: boolean) => void,
    favoriteState?: boolean,
    edit?: () => void | null,
    event?: string | null,
    eventText?: string | null,
    customButton?: () => void | null,
    customButtonText?: string | null,
    add?: () => void | null,
    share?: ReactElement | null,
    hasDescription?: boolean,
    copyDescription?: () => void | null,
    download?: () => void | null,
    data?: DataObject
}

function DetailHeroPanel({ visibility, brand, title, author, status, statusC, description, onClickFavorite, favoriteState = false,
    edit, event, eventText, add, share, hasDescription = false, copyDescription, download, customButton, customButtonText, data = {
        edit: 'defaultEdit',
        add: 'defaultAdd'
    } }: DetailHeroPanelProps) {

    // Translate
    const { t } = useTranslation(["global"]);
    //Redux Hooks
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    // Styles
    const classes = DocumentStyles();

    return (
        <Box className={classes.container}>
            <Box width="100%">
                <Box display='flex' justifyContent="space-between">
                    {visibility != null &&
                        <Box display="flex" gridGap={8} alignItems="center" pb="4px">
                            <Typography variant='caption' color="textSecondary">{t("visibility")}</Typography>
                            <Typography variant='caption' color="textSecondary">|</Typography>
                            <Visibility type={visibility} variant={1} />
                        </Box>}
                    {brand != null &&
                        <Box display="flex" alignItems="center" pb="4px">
                            <Typography variant='caption' color="textSecondary" style={{ height: 14 }}>{brand}</Typography>
                        </Box>}
                </Box>
                <Typography variant='h6' className={classes.title}>{title}</Typography>
            </Box>
            <Box>
                {author != null &&
                    <Box display="flex" gridGap={8} alignItems="center" pb="4px">
                        <Typography variant='caption' className={clsx(classes.customText, 'light')}>{t("author")}:</Typography>
                        <Typography variant='caption' color="textSecondary">{author}</Typography>
                    </Box>}
                {(status != null && authUser !== null && [1, 2, 3, 5].includes(authUser.role_id)) &&
                    <Box display="flex" gridGap={8} alignItems="center" pb="4px">
                        <Typography variant='caption' className={clsx(classes.customText, 'blue')}>{t("status")}:</Typography>
                        <Typography variant='caption' color="textSecondary">{status == 0 ? t("active") : t("inactive")}</Typography>
                    </Box>}
                {statusC != null &&
                    <Box display="flex" gridGap={8} alignItems="center" pb="4px">
                        <Typography variant='caption' className={clsx(classes.customText, 'blue')}>{t("status")}:</Typography>
                        <Typography variant='caption' color="textSecondary">{statusC}</Typography>
                    </Box>}
                {description != null &&
                    <Box display="flex" gridGap={8} flexDirection="column" pb="4px">
                        <Typography style={{ fontWeight: 500 }}>{t("description")}:</Typography>
                        <Typography variant='body2' className={clsx(classes.customText, 'grey5')}>{description}</Typography>
                    </Box>}
            </Box>
            <Box display="flex" gridGap={16} flexDirection="column" width="100%" id='detailHeroDescription'>
                {authUser !== null && onClickFavorite &&
                    <FavoriteButton handleClick={onClickFavorite} favoriteState={favoriteState} type='button' />
                }
                {customButton != null &&
                    <Button variant='text' className={clsx(classes.customButton, 'blue')} onClick={customButton}>
                        <FiberManualRecord />
                        <Typography variant='body2'>{customButtonText}</Typography>
                    </Button>}
                {(edit != null && authUser !== null && [1, 2, 3, 5].includes(authUser.role_id)) &&
                    <Button data-editheropanel={data.edit} variant='text' className={clsx(classes.customButton, 'red')} onClick={edit}>
                        <EditRounded />
                        <Typography variant='body2'>{t("edit")}</Typography>
                    </Button>}
                {event != null &&
                    <Button variant='text' className={clsx(classes.customButton, 'orange')} component="a" target="_blank" href={event}>
                        <LocalActivityRounded />
                        <Typography variant='body2'>{eventText}</Typography>
                    </Button>}
                {(add != null && authUser !== null && [1, 2, 3, 5].includes(authUser.role_id)) &&
                    <Button data-addheropanel={data.add} variant='outlined' className={clsx(classes.customButton, 'redOut')} onClick={add}>
                        <AddRounded />
                        <Typography variant='body2'>{t("admin.courses.add-lesson")}</Typography>
                    </Button>}
                {share != null && <>{share}</>}
                {hasDescription && (
                    <Button variant='text' className={clsx(classes.customButton, 'orange')} onClick={copyDescription}>
                        <FileCopyRounded />
                        <Typography variant='body2'>{t("copy-description")}</Typography>
                    </Button>)}
                {download != null &&
                    <Button variant='text' className={clsx(classes.customButton, 'blue')} onClick={download}>
                        <CloudDownloadRounded />
                        <Typography variant='body2'>{t("download")}</Typography>
                    </Button>}
            </Box>
        </Box>
    );
}

export default DetailHeroPanel;