import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_SEMINARLESSONS, SUCCESS_GET_SEMINARLESSONS, ERROR_GET_SEMINARLESSONS,
    START_CREATE_SEMINARLESSON, SUCCESS_CREATE_SEMINARLESSON, ERROR_CREATE_SEMINARLESSON,
    // START_STORE_SEMINARLESSONMULTIMEDIA, SUCCESS_STORE_SEMINARLESSONMULTIMEDIA, ERROR_STORE_SEMINARLESSONMULTIMEDIA,
    START_SHOW_SEMINARLESSON, SUCCESS_SHOW_SEMINARLESSON, ERROR_SHOW_SEMINARLESSON,
    START_DELETE_SEMINARLESSON, SUCCESS_DELETE_SEMINARLESSON, ERROR_DELETE_SEMINARLESSON,
    START_DELETE_SEMINARLESSONMULTIMEDIA, SUCCESS_DELETE_SEMINARLESSONMULTIMEDIA, ERROR_DELETE_SEMINARLESSONMULTIMEDIA,
    START_SUBSCRIBE_TO_SEMINARLESSON, SUCCESS_SUBSCRIBE_TO_SEMINARLESSON, ERROR_SUBSCRIBE_TO_SEMINARLESSON,
    START_GET_SEMINARLESSON_REGISTEREDUSERS, SUCCESS_GET_SEMINARLESSON_REGISTEREDUSERS, ERROR_GET_SEMINARLESSON_REGISTEREDUSERS,
    START_TOGGLE_FORM_SEMINARLESSON, SUCCESS_TOGGLE_FORM_SEMINARLESSON,
    START_SET_VIEW_SEMINARLESSON, SUCCESS_SET_VIEW_SEMINARLESSON,
    SUCCESS_UPDATE_SUBSCRIBE_TO_SEMINARLESSON
} from '../types';
import { apiCall } from '../config/axios';

function* getSeminarLessons() {
    try {
        const response = yield call(apiCall, 'get', '/seminarlessons/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_SEMINARLESSONS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_SEMINARLESSONS, payload: error.message })
    }
}

function* createSeminarLesson({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/seminarlessons/store`, { seminarlesson: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CREATE_SEMINARLESSON, payload: response.data })
        yield put({ type: SUCCESS_TOGGLE_FORM_SEMINARLESSON })
    } catch (error) {
        yield put({ type: ERROR_CREATE_SEMINARLESSON, payload: error.message })
    }
}

// function* storeLessonMultimedia({ payload }) {
//     try {
//         const response = yield call(apiCall, 'post', '/lessons/storemultimedia', { lessonmultimedia: payload })
//         if (response.data.error)
//             throw new Error(response.data.msg);

//         yield put({ type: SUCCESS_STORE_LESSONMULTIMEDIA, payload: response.data })
//     } catch (error) {
//         yield put({ type: ERROR_STORE_LESSONMULTIMEDIA, payload: error.message })
//     }
// }

function* showSeminarLesson({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/seminarlessons/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_SEMINARLESSON, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_SEMINARLESSON, payload: error.message })
    }
}

function* deleteSeminarLesson({ payload }) {
    try {
        const response = yield call(apiCall, 'delete', `/seminarlessons/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_SEMINARLESSON, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_SEMINARLESSON, payload: error.message })
    }
}

function* deleteSeminarLessonMultimedia({ payload }) {
    try {
        const response = yield call(apiCall, 'delete', `/seminarlessons/deletemultimedia/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_SEMINARLESSONMULTIMEDIA, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_SEMINARLESSONMULTIMEDIA, payload: error.message })
    }
}

function* subscribeToSeminarlesson({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/seminarlessons/registration', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SUBSCRIBE_TO_SEMINARLESSON, payload: response.data })
        yield put({ type: SUCCESS_UPDATE_SUBSCRIBE_TO_SEMINARLESSON, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SUBSCRIBE_TO_SEMINARLESSON, payload: error.message })
    }
}

function* getRegisteredUsers({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/seminarlessons/registeredusers/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_SEMINARLESSON_REGISTEREDUSERS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_SEMINARLESSON_REGISTEREDUSERS, payload: error.message })
    }
}

function* toggleFormSeminarLesson() {
    yield put({ type: SUCCESS_TOGGLE_FORM_SEMINARLESSON })
}

function* setViewSeminarLesson({ payload }) {
    yield put({ type: SUCCESS_SET_VIEW_SEMINARLESSON, payload })
}

export default function* seminarlessons() {
    yield takeLatest(START_GET_SEMINARLESSONS, getSeminarLessons);
    yield takeLatest(START_CREATE_SEMINARLESSON, createSeminarLesson);
    // yield takeLatest(START_STORE_LESSONMULTIMEDIA, storeLessonMultimedia);
    yield takeLatest(START_SHOW_SEMINARLESSON, showSeminarLesson);
    yield takeLatest(START_DELETE_SEMINARLESSON, deleteSeminarLesson);
    yield takeLatest(START_DELETE_SEMINARLESSONMULTIMEDIA, deleteSeminarLessonMultimedia);
    yield takeLatest(START_SUBSCRIBE_TO_SEMINARLESSON, subscribeToSeminarlesson);
    yield takeLatest(START_GET_SEMINARLESSON_REGISTEREDUSERS, getRegisteredUsers);
    yield takeLatest(START_TOGGLE_FORM_SEMINARLESSON, toggleFormSeminarLesson);
    yield takeLatest(START_SET_VIEW_SEMINARLESSON, setViewSeminarLesson);
}