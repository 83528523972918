// React
import { useState } from 'react';
import { useSelector } from 'react-redux'

// Material UI
import { FormControl, FormHelperText, Input, InputAdornment, Tooltip, Fade, Box, Typography, IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons/';

// Custom components
import CustomChip from "../admin/CustomChip";

// Translate
import { useTranslation } from "react-i18next";

// Redux Actions
import { IRootState } from '../../../redux/types';

// Types
type IStrongPassword = {
    number: boolean,
    letters: boolean,
    lowerLetter: boolean,
    upperLetter: boolean,
    isCorrect: boolean,
    open: boolean
}

export type StrongPasswordProps = {
    password: string,
    property: string,
    handleInputChange: (property: string, password: string, isCorrect: boolean) => void
}

function StrongPassword({ password, property, handleInputChange }: StrongPasswordProps) {

    // Translate
    const { t } = useTranslation(["global"]);

    // Redux Hooks
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // strongPassword state
    const [strongPassword, setStrongPassword] = useState<IStrongPassword>({
        number: false,
        letters: false,
        lowerLetter: false,
        upperLetter: false,
        isCorrect: false,
        open: false
    });

    /** Functions */
    const handleChange = (event: any) => {
        const value = event.target.value;
        verifyStrongPassword(value);
    }

    function verifyStrongPassword(password: string) {
        let numberRexExp = new RegExp("^(?=.*[0-9])").test(password);
        let lettersRexExp = new RegExp("^(?=.{8,})").test(password);
        let lowerLetterRexExp = new RegExp("^(?=.*[a-z])").test(password);
        let upperLetterRexExp = new RegExp("^(?=.*[A-Z])").test(password);
        let isCorrectRexExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})").test(password);
        setStrongPassword({
            ...strongPassword,
            number: numberRexExp,
            letters: lettersRexExp,
            lowerLetter: lowerLetterRexExp,
            upperLetter: upperLetterRexExp,
            isCorrect: isCorrectRexExp
        });
        handleInputChange(property, password, isCorrectRexExp);
    }

    return (
        <FormControl variant="filled" fullWidth>
            <Input
                endAdornment={
                    <InputAdornment position="end">
                        <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} arrow placement="right"
                            title={
                                <Box>
                                    <Typography variant="body2">{t("requirements")}:</Typography>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={strongPassword.letters ? 'primary' : 'grey'} />
                                        <Typography variant="caption">{t("client.register.minimum-8-letters")}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={strongPassword.lowerLetter ? 'primary' : 'grey'} />
                                        <Typography variant="caption">{t("client.register.minimum-one-lowercase-letter")}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={strongPassword.upperLetter ? 'primary' : 'grey'} />
                                        <Typography variant="caption">{t("client.register.minimum-one-capital-letter")}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={strongPassword.number ? 'primary' : 'grey'} />
                                        <Typography variant="caption">{t("client.register.Minimum-one-number")}</Typography>
                                    </Box>
                                </Box>
                            }
                            open={strongPassword.open}
                        >
                            <IconButton>
                                <InfoOutlined htmlColor={'#8F99A3'} fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }
                type="password"
                placeholder={t("client.register.placeholder-password")}
                onChange={handleChange}
                onFocus={() => { setStrongPassword({ ...strongPassword, open: true }) }}
                onBlur={() => { setStrongPassword({ ...strongPassword, open: false }) }}
                error={!strongPassword.isCorrect}
                value={password}
            />
        </FormControl>
    );
}

export default StrongPassword;