import { START_GET_RESOURCECATEGORIES, START_GET_RESOURCECATEGORIES_OPTIONS, START_SHOW_RESOURCECATEGORY, START_STORE_RESOURCECATEGORY, START_UPDATE_RESOURCECATEGORY, START_DELETE_RESOURCECATEGORY, START_TOGGLE_FORM_RESOURCECATEGORIES } from '../types';

export const getResourcecategories = payload => ({
    type: START_GET_RESOURCECATEGORIES,
    payload
})

export const getResourcecategoriesOptions = payload => ({
    type: START_GET_RESOURCECATEGORIES_OPTIONS,
    payload
})

export const showResourcecategory = payload => ({
    type: START_SHOW_RESOURCECATEGORY,
    payload
})

export const storeResourcecategory = payload => ({
    type: START_STORE_RESOURCECATEGORY,
    payload
})

export const updateResourcecategory = payload => ({
    type: START_UPDATE_RESOURCECATEGORY,
    payload
})

export const deleteResourcecategory = payload => ({
    type: START_DELETE_RESOURCECATEGORY,
    payload
})

export const toggleFormResourcecategories = () => ({
    type: START_TOGGLE_FORM_RESOURCECATEGORIES
})