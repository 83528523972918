//React
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';


// Material UI
import { Button, Box } from '@material-ui/core';
import { NavigateBeforeRounded } from "@material-ui/icons/";

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Libraries
import { useTranslation } from "react-i18next";

// Types
type ButtonBackProps = {
    goBack?: boolean,
    customFunction?: any,
    dataName?: string,
    async?: boolean,
    customText?: string
}

const ButtonBack = ({ customFunction, goBack = true, dataName, async = true, customText }: ButtonBackProps) => {
    // const { customFunction, goBack = true } = props
    const history = useHistory();

    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Redux Hooks
    const dispatch = useDispatch();
    //Location
    const location = useLocation();

    let isAdmin = location.pathname.includes('admin') || location.pathname === '/escritorio'

    const handleClick = () => {
        if (goBack)
            history.goBack();

        if (customFunction !== undefined && !async)
            customFunction();

        if (customFunction !== undefined && async)
            dispatch(customFunction);
    }

    return (
        <Box mt={isAdmin ? isMobile ? 10 : 7 : 2} mb={isAdmin ? isMobile ? 2 : 6 : 2}>
            <Button data-goback={dataName} variant="text" color={isAdmin ? "secondary" : "primary"}
                startIcon={<NavigateBeforeRounded />}
                onClick={handleClick}
            >
                {customText ?
                    `${customText}`
                    :
                    `${t("back")}`
                }
            </Button>
        </Box>
    )
}

export default ButtonBack;
