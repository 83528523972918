// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from "react-router-dom";

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

// Custom Components
import ButtonBack from '../../../components/common/ButtonBack';
import DetailHeroPanel from '../../../components/layouts/common/DetailHeroPanel';
import DetailInfoPanel from '../../../components/layouts/common/DetailInfoPanel';
import VerifyAccessRoute from '../../../components/common/VerifyAccessRoute';
import AdminDetailInfo from '../../../components/common/admin/AdminDetailInfo';

// Redux Actions
import { showSlider } from '../../../redux/actions/sliders';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Custom Style
const sliderStyles = makeStyles({
    slide: {
        borderRadius: '0px 0px 0px 16px',
        maxWidth: '100%'
    }
});

// Types
interface ParamTypes {
    id: string
}

function SliderDetail() {

    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = sliderStyles();

    // URL Params
    const { id } = useParams<ParamTypes>();
    //Redux Hooks
    const dispatch = useDispatch();
    const { slider_selected } = useSelector((state: IRootState) => state.slider);

    const location = useLocation();

    // Ready Page
    useEffect(() => {
        dispatch(showSlider(id))
    }, []);

    let infoDetail = (slider_selected != null) && {
        "item": [
            {
                "id": 1,
                "type": "date",
                "title": `${t("admin.people.created-date")}`,
                "color": "grey6",
                "info": `${moment(slider_selected.created_at).format('LL')}`,
            },
        ]
    }


    return (

        <>
            {(slider_selected != null) &&
                <Container>
                    <ButtonBack />
                    <Grid container spacing={5} direction='row-reverse' >

                        <Grid item xs={12} md={4}>
                            <DetailHeroPanel
                                visibility={slider_selected.visibility}
                                status={slider_selected.status}
                                brand={slider_selected.brand_id}
                                title={slider_selected.title}
                                author={slider_selected.created_by}
                                description={slider_selected.description}
                            />
                            {isMobile ? <></> : <DetailInfoPanel attributes={infoDetail} />}
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <VerifyAccessRoute visibility={slider_selected.visibility} />

                            {location.pathname.includes('admin') &&
                                <AdminDetailInfo resourceSelected={slider_selected} />
                            }

                            {slider_selected.image != null &&
                                <img src={slider_selected.image} className={classes.slide} />
                            }

                            {isMobile ? <DetailInfoPanel attributes={infoDetail} /> : <></>}
                        </Grid>

                    </Grid>

                </Container>
            }
        </>

    );
}

export default SliderDetail;
