import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_NOVELTIES, SUCCESS_GET_NOVELTIES, ERROR_GET_NOVELTIES,
    START_GET_CLIENT_NOVELTIES, SUCCESS_GET_CLIENT_NOVELTIES, ERROR_GET_CLIENT_NOVELTIES,
    START_CREATE_NOVELTY, SUCCESS_CREATE_NOVELTY, ERROR_CREATE_NOVELTY,
    START_UPDATE_NOVELTY, SUCCESS_UPDATE_NOVELTY, ERROR_UPDATE_NOVELTY,
    START_SHOW_NOVELTY, SUCCESS_SHOW_NOVELTY, ERROR_SHOW_NOVELTY,
    START_SHOW_NOVELTY_BY_SLUG, SUCCESS_SHOW_NOVELTY_BY_SLUG, ERROR_SHOW_NOVELTY_BY_SLUG,
    START_DELETE_NOVELTY, SUCCESS_DELETE_NOVELTY, ERROR_DELETE_NOVELTY,
    START_TOGGLE_FORM_NOVELTY, SUCCESS_TOGGLE_FORM_NOVELTY,
    START_ASSIGN_TAG_NOVELTY, SUCCESS_ASSIGN_TAG_NOVELTY, ERROR_ASSIGN_TAG_NOVELTY,
    START_DELETE_TAG_NOVELTY, SUCCESS_DELETE_TAG_NOVELTY, ERROR_DELETE_TAG_NOVELTY,
    START_ASSIGN_HIGHLIGHT_NOVELTY, SUCCESS_ASSIGN_HIGHLIGHT_NOVELTY, ERROR_ASSIGN_HIGHLIGHT_NOVELTY
} from '../types';
import { apiCall } from '../config/axios';

function* getNovelties({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/novelties/index', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        response.data.type = payload.type;

        yield put({ type: SUCCESS_GET_NOVELTIES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_NOVELTIES, payload: error.message })
    }
}

function* getClientNovelties({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/novelties/indexclient`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        response.data.type = payload.type;

        yield put({ type: SUCCESS_GET_CLIENT_NOVELTIES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_CLIENT_NOVELTIES, payload: error.message })
    }
}

function* createNovelty({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/novelties/store', {novelty: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CREATE_NOVELTY, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CREATE_NOVELTY, payload: error.message })
    }
}

function* updateNovelty({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/novelties/update', {novelty: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_UPDATE_NOVELTY, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_UPDATE_NOVELTY, payload: error.message })
    }
}

function* showNovelty({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/novelties/show/${payload.id}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        response.data.type = payload.type;

        yield put({ type: SUCCESS_SHOW_NOVELTY, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_NOVELTY, payload: error.message })
    }
}

function* showNoveltyBySlug({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/novelties/showbyslug/${payload.slug}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        response.data.type = payload.type;

        yield put({ type: SUCCESS_SHOW_NOVELTY_BY_SLUG, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_NOVELTY_BY_SLUG, payload: error.message })
    }
}

function* deleteNovelty({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/novelties/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_NOVELTY, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_NOVELTY, payload: error.message })
    }
}

function* assignHighlight({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/novelties/assignhighlight`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_HIGHLIGHT_NOVELTY, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_HIGHLIGHT_NOVELTY, payload: error.message })
    }
}

function* toggleFormNovelty({ payload }) {
    yield put({ type: SUCCESS_TOGGLE_FORM_NOVELTY, payload })
}

function* assignTagNovelty({ payload }) {
    try{
        const response = yield call(apiCall, 'post', `/novelties/assigntag`, {novelty: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        response.data.type = payload.type;

        yield put({ type: SUCCESS_ASSIGN_TAG_NOVELTY, payload})
    } catch (error){
        yield put({ type: ERROR_ASSIGN_TAG_NOVELTY, payload: error.message})
    }
}

function* deleteTagNovelty({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/novelties/deletetag/${payload.id}/${payload.tagId}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        response.data.type = payload.type;

        yield put({ type: SUCCESS_DELETE_TAG_NOVELTY, payload})
    } catch (error){
        yield put({ type: ERROR_DELETE_TAG_NOVELTY, payload: error.message})
    }
}

export default function* novelties() {
    yield takeLatest(START_GET_NOVELTIES, getNovelties);
    yield takeLatest(START_GET_CLIENT_NOVELTIES, getClientNovelties);
    yield takeLatest(START_CREATE_NOVELTY, createNovelty);
    yield takeLatest(START_UPDATE_NOVELTY, updateNovelty);
    yield takeLatest(START_SHOW_NOVELTY, showNovelty);
    yield takeLatest(START_SHOW_NOVELTY_BY_SLUG, showNoveltyBySlug);
    yield takeLatest(START_DELETE_NOVELTY, deleteNovelty);
    yield takeLatest(START_ASSIGN_HIGHLIGHT_NOVELTY, assignHighlight);
    yield takeLatest(START_TOGGLE_FORM_NOVELTY, toggleFormNovelty);
    yield takeLatest(START_ASSIGN_TAG_NOVELTY, assignTagNovelty);
    yield takeLatest(START_DELETE_TAG_NOVELTY, deleteTagNovelty);
}