
// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Switch, RouteComponentProps } from 'react-router-dom';

// Material UI
import { ThemeProvider, Box } from '@material-ui/core';
import PublicRoute from '../../components/common/PublicRoute';
import CustomSnackBar from '../common/admin/CustomSnackBar';

// Custom Components 
import Footer from './common/Footer';
import SetQueryParams from '../../utils/SetQueryParams';
import ClientAppBar from './navigation/client/ClientAppBar';
import ClientBreadcumb from './navigation/client/ClientBreadcumb';
import InternalSlider from '../common/header/InternalSlider';

// Redux
import { updateDisplaytime } from '../../redux/actions/userdisplaytime';
import { getCurrentCommits } from '../../redux/actions/log';
import { IRootState } from '../../redux/types';

// Pages
import LibraryDetail from '../../pages/library/ResourceDetail';
import CoursesDetail from '../../pages/courses/CourseDetail';
import NewsDetail from '../../pages/news/NewsDetail';
import NoveltyDetail from '../../pages/novelties/NoveltyDetail';
import EventDetail from '../../pages/events/EventDetail';
import OfferDetail from '../../pages/offers/OfferDetail';
import SeminarDetail from '../../pages/seminars/SeminarDetail';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import ClientTheme from '../../styles/components/themes/ClientTheme';
import LayoutStyles from '../../styles/components/layouts/MainLayout';


function DetailLayout({ history }: RouteComponentProps) {
    const layoutClasses = LayoutStyles();
    const dispatch = useDispatch();
    let isMobile = useScreenWidth(960);

    const { user: authUser, error: errorAuth, msg: msgAuth } = useSelector((state: IRootState) => state.auth);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCurrentCommits());
    }, []);

    useEffect(() => {
        const userdisplaytime = localStorage.getItem('userdisplaytime');
        if (userdisplaytime) {
            const updateUserdisplaytime = JSON.parse(userdisplaytime);
            dispatch(updateDisplaytime(updateUserdisplaytime));
            localStorage.removeItem('userdisplaytime');
        }
    }, []);

    if (localStorage.getItem('login_redirect')) {
        let tmpPath = localStorage.getItem('login_redirect');
        localStorage.removeItem('login_redirect');
        window.location.pathname = tmpPath || "/inicio";
    }

    return (
        <ThemeProvider theme={ClientTheme}>


            <ClientAppBar />
            <InternalSlider detail={true} />
            {isMobile
                ?
                <Box height={60} />
                :
                < ClientBreadcumb />
            }

            <Box>
                <main
                    className={layoutClasses.contentHome}
                    style={{ padding: '0px' }}>
                    <Switch>
                        <PublicRoute exact path="/biblioteca/:id" component={LibraryDetail} />
                        <PublicRoute exact path="/eventos/:id" component={EventDetail} />
                        <PublicRoute exact path="/seminarios/:id" component={SeminarDetail} />
                        <PublicRoute exact path="/cursos/:brandId/:slug" component={CoursesDetail} />
                        <PublicRoute exact path="/noticias/:slug" component={NewsDetail} />
                        <PublicRoute exact path="/novedades/:slug" component={NoveltyDetail} />
                        <PublicRoute exact path="/ofertas/:id" component={OfferDetail} />
                    </Switch>

                </main>
            </Box>


            <Footer noMargin={true} />

            <CustomSnackBar errors={[errorAuth]} msgs={[msgAuth]} />


            {/* <ShowMobileBanner /> */}
            <SetQueryParams />
        </ThemeProvider>
    );
}


export default DetailLayout;