import { put, call, takeLatest } from 'redux-saga/effects';
import { START_GET_RESOURCETYPES, SUCCESS_GET_RESOURCETYPES, ERROR_GET_RESOURCETYPES } from '../types';
import { apiCall } from '../config/axios';

function* getResourcetypes() {
    try{
        const response = yield call(apiCall, 'get', '/resourcetypes/index/'+localStorage.getItem('globalLanguageId'))
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_RESOURCETYPES, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_RESOURCETYPES, payload: error.message})
    }
}

export default function* resourcetypes() {
    yield takeLatest(START_GET_RESOURCETYPES, getResourcetypes);
}