// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Material UI
import { Box, makeStyles, Grid, Typography } from '@material-ui/core';

// Custom Components
import HomeResourcesFeedSkeleton from '../../layouts/navigation/skeletons/HomeResourcesFeedSkeleton';

// Redux Actions
import { getHomeResources } from '../../../redux/actions/resources';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import CardsFeed from './CardsFeed';

// Custom Style
const ResourcesFeedStyle = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid  #C7CCD1',
        marginTop: 32,
        marginBottom: 24,
        "& h5": {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                fontWeight: 500,
            },
        },
        "& p": {
            whiteSpace: "nowrap",
        }
    },
    seeMore: {
        textDecoration: 'underline',
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
}));

function ResourcesFeed() {

    // Translate
    const { t } = useTranslation(["global"]);

    // Styles
    const classes = ResourcesFeedStyle();

    /* Functions */
    const history = useHistory();

    //Redux Hooks
    const dispatch = useDispatch();
    const { feedResources, loading: resourcesLoading } = useSelector((state: IRootState) => state.resource);
    const { globalCountryId, globalLanguageId } = useSelector((state: IRootState) => state.global);

    useEffect(() => {
        dispatch(getHomeResources({
            country_id: globalCountryId,
            language_id: globalLanguageId
        }));
    }, [globalCountryId, globalLanguageId]);

    return (
        <>
            {/* Resources */}
            {(resourcesLoading === true) ?
                <HomeResourcesFeedSkeleton />
                :
                <>
                    {(feedResources.length > 0) &&
                        <Grid item xs={12} className={classes.header}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h5">{t("library-updates")}</Typography>
                                <Typography onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); history.push(`/biblioteca`) }} color="textSecondary" style={{ textDecoration: 'underline', cursor: "pointer" }}>{t("see-more")}</Typography>
                            </Box>
                        </Grid>
                    }
                </>
            }

            <Box width='100%'>
                <CardsFeed resources={feedResources} history={history} />
            </Box>

        </>
    );
}

export default ResourcesFeed;
