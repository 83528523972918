// Material UI
import { Button, Typography, Box} from '@material-ui/core';

// Redux Actions
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';
import clsx from 'clsx';

//Styles
import SeminarStyles from '../../styles/components/seminars/SeminarStyles';
import VipStyles from '../../styles/components/common/VipStyles';
import { AssistantPhotoRounded, CalendarTodayRounded } from "@material-ui/icons";

// Types
type SeminarShortcutProps = {
    seminar:{
        id: number,
        title: string,
        datetime: null | string,
        visibility: number
    },
    seminarlesson?: {
        id: number,
        seminar_id: number,
        title: string,
        datetime: null | string
    }
}

function SeminarShortcut({ seminar, seminarlesson }: SeminarShortcutProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    // Styles
    const classes = SeminarStyles();
    const vipclasses = VipStyles();

    return (
        <>
            <Box className={classes.containerDetail}>
                <Box className={classes.talkerDateDetail} >
                    <Box display='flex' gridGap={16}>
                        <AssistantPhotoRounded />
                        <Typography>{(seminarlesson) ? seminarlesson.title : seminar.title}</Typography>
                    </Box>
                    <Box display='flex' gridGap={16}>
                        <CalendarTodayRounded />
                        <Typography>{(seminarlesson) ? `${moment(seminarlesson.datetime).format('LLLL')}` : `${moment(seminar.datetime).format('LLLL')}`}</Typography>
                    </Box>
                </Box>

                <Button
                    id="btn-shortcutseminars"
                    href={(seminarlesson) ? `/seminarios/${seminarlesson.seminar_id}?lesson=${seminarlesson.id}` : `/seminarios/${seminar.id}`}
                    variant='contained'
                    color='primary'
                    size="large"
                    className={((seminarlesson === undefined) && seminar.visibility === 1) ? vipclasses.btnLinkDetail : classes.btnLinkDetail}>
                    {t('book-my-ticket')}
                </Button>
            </Box>
        </>
    );
}

export default SeminarShortcut;