import {
    IFavoriteState,
    START_GET_FAVORITES, SUCCESS_GET_FAVORITES, ERROR_GET_FAVORITES,
    START_SET_FAVORITE, SUCCESS_SET_FAVORITE, ERROR_SET_FAVORITE,
    START_UNSET_FAVORITE, SUCCESS_UNSET_FAVORITE, ERROR_UNSET_FAVORITE
} from '../types';

const initialState: IFavoriteState = {
    favorites: {
        resources: [],
        courses: [],
        novelties: [],
        news: [],
        offers: [],
        catalogs: [],
        seminars: []
    },
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_FAVORITES:
        case START_SET_FAVORITE:
        case START_UNSET_FAVORITE:
            return {
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_FAVORITES:
            return {
                ...state,
                favorites: action.payload.favorites,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SET_FAVORITE:
        case SUCCESS_UNSET_FAVORITE:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case ERROR_SET_FAVORITE:
        case ERROR_UNSET_FAVORITE:
            return {
                ...state,
                favorites: [],
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}