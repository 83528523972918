import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


interface IBrand {
    id: number,
    name: string
}

function BrandAutocomplete( {value, handleInputChange} : any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Redux global state
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    // Handle functions
    const handleMultiAutoCompleteChange = (brands: IBrand[]) => {
        let optionsMulti: number[] = [];
        if (brands !== null) {
            for (let i = 0; i < brands.length; i++) {
                optionsMulti.push(brands[i].id);
            }
        }

        handleInputChange('brands', optionsMulti);
    }

    return (
        <Autocomplete
            id="combo-box-brands"
            ChipProps={{ color: 'secondary' }}
            value={userBrands.filter((brand: any) => value.includes(brand.id))}
            onChange={(event, value) => handleMultiAutoCompleteChange(value)}
            options={userBrands}
            multiple
            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label={t("brands")}
                    variant="filled" 
                    InputLabelProps={{ shrink: true }} 
                />
            }
        />
    )
}

export default BrandAutocomplete;