import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    root: {
        '&.containerFeed': {
            maxWidth: '100%',
            letterSpacing: '0.0015em',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            fontWeight: 500,
        },
        '&.cardHeroTitle': {
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                fontSize: '16px',
                lineHeight: '22px',
                letterSpacing: '0.0015em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                position: 'relative',
                // top: 10,
                // paddingRight: 18,
                // marginBottom: 10,
                fontWeight: 400,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '16px',
                letterSpacing: '0.0015em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                position: 'relative',
                // top: 10,
                // paddingRight: 18,
                // marginBottom: 10,
                fontWeight: 400,
            },
        },
        '&.cardHeroType0': {
            fontWeight: 500,
        },
        '&.cardHeroUnderline': {
            lineHeight: '1.25rem',
            '& :hover': {
                textDecoration: 'underline',
            },
        },
        '&.cardHerotitleCorousel': {
            lineHeight: '1.25rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            overflow: 'hidden',
        },
        '&.resourceTitle': {
            fontFamily: theme.typography.body1.fontFamily,
            marginBottom: '0.35em',
            letterSpacing: '0.0015em',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
                // minHeight: 48,
                lineHeight: '24px',
            },
            [theme.breakpoints.down('sm')]: {
                lineHeight: '16px',
                fontSize: '14px',
                minHeight: 32,
            }
        },
        '&.courseTitle': {
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                marginBottom: '0.35em',
                fontSize: '16px',
                lineHeight: '22px',
                minHeight: ' 43px',
                letterSpacing: '0.0015em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                position: 'relative',
                top: 4,
                paddingRight: 4,
            },
            [theme.breakpoints.down('sm')]: {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                maxHeight: 32,
                minHeight: 32,
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
        '&.courseBlock': {
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                marginBottom: '0.35em',
                fontSize: '16px',
                lineHeight: '22px',
                minHeight: ' 43px',
                letterSpacing: '0.0015em',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                position: 'relative',
                top: 4,
                paddingRight: 4,
            },
            [theme.breakpoints.down('sm')]: {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                maxHeight: 32,
                minHeight: 32,
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
    },
}));
