// React
import {
    useState,
    useEffect,
    forwardRef,
    Ref,
    ReactElement,
    ChangeEvent,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Slide,
    Tab,
    Tabs,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    IconButton,
    Grid,
} from '@material-ui/core'
import { Delete, AddCircle, CloseRounded } from '@material-ui/icons'
import { TransitionProps } from '@material-ui/core/transitions'

// Commons components
import InputCountry from '../common/admin/modal/InputCountry'
import {
    ResourcetypeSelect,
    BrandMultiAutocomplete,
    RoleAutocomplete,
    CancelButton,
    SaveButton,
    DeleteButton,
} from '../common/forms'
import TabPanel from '../common/admin/TabPanel'

// Interfaces
import RoleInterface from '../../interfaces/RoleInterface'

// Redux Actions
import {
    getResourcecategories,
    showResourcecategory,
    storeResourcecategory,
    updateResourcecategory,
    deleteResourcecategory,
    toggleFormResourcecategories,
} from '../../redux/actions/resourcecategories'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hooks
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'

// Types
interface IResourcecategory {
    id: number
    name: string
    resourcetypeId: number
    countries: any
    brands: any
    role: null | RoleInterface
    translations: any
}
interface ICategoriesModalProps {
    resourcetypeId: number
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

function CategoriesModal({ resourcetypeId = 1 }: ICategoriesModalProps) {
    // Translate
    const { t } = useTranslation(['global'])
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const formClasses = FormsStyles()

    //Redux Hooks
    const dispatch = useDispatch()
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { resourcecategorySelected, showForm } = useSelector(
        (state: IRootState) => state.resourcecategory
    )
    const { isValidForm, errorTab, msgForm } = useSelector(
        (state: IRootState) => state.validator
    )

    // Resource Category State
    const [resourcecategory, setResourcecategory] = useState<IResourcecategory>(
        {
            id: 0,
            name: 'default',
            resourcetypeId: resourcetypeId,
            countries: [],
            brands: [],
            role: null,
            translations: [{ language_id: 1, text: '' }],
        }
    )

    //Tab State
    const [currentTab, setCurrentTab] = useState(0)

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator())

            if (resourcecategory.id === 0)
                dispatch(storeResourcecategory(resourcecategory))
            else dispatch(updateResourcecategory(resourcecategory))
        }
    }, [isValidForm])

    useEffect(() => {
        if (
            resourcecategorySelected !== null &&
            resourcecategorySelected.id !== resourcecategory.id
        ) {
            setResourcecategory({
                id: resourcecategorySelected.id,
                name: resourcecategorySelected.name,
                resourcetypeId: resourcecategorySelected.resourcetype_id,
                brands: resourcecategorySelected.brands,
                countries: resourcecategorySelected.countries,
                role: resourcecategorySelected.role,
                translations: resourcecategorySelected.translations,
            })
        } else {
            setResourcecategory({
                id: 0,
                name: 'default',
                resourcetypeId: resourcetypeId,
                countries: [],
                brands: [],
                role: null,
                translations: [{ language_id: 1, text: '' }],
            })
        }
    }, [resourcecategorySelected])

    /* Functions */
    const handleInputChange = (inputName: string, value: any) => {
        setResourcecategory({ ...resourcecategory, [inputName]: value })
    }

    const handleSubmit = () => {
        dispatch(
            formValidator({
                name: [resourcecategory.name, 'required'],
                resourcetypeId: [resourcecategory.resourcetypeId, 'required'],
                role: [resourcecategory.role, 'required'],
                countries: [resourcecategory.countries, 'notempty'],
                translations: [resourcecategory.translations, 'notempty'],
            })
        )
    }

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue)
    }

    const addTranslation = () => {
        const newTranslation = { language_id: 1, text: '' }
        let stateValue = resourcecategory.translations
        stateValue.push(newTranslation)

        setResourcecategory({ ...resourcecategory, translations: stateValue })
    }

    const deleteTranslation = (key: number) => {
        let stateValue = [...resourcecategory.translations]
        stateValue.splice(key, 1)

        setResourcecategory({ ...resourcecategory, translations: stateValue })
    }

    const handleChangeTranslations = (nameInput: string, key: number) => (event: any) => {
        let stateValue = resourcecategory.translations
        stateValue[key][nameInput] = event.target.value

        setResourcecategory({
            ...resourcecategory,
            translations: stateValue,
        })
    }

    return (
        <Dialog
            open={showForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            TransitionComponent={Transition}
            onClose={() => dispatch(toggleFormResourcecategories())}
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {t('admin.library.create-category')} {msgForm.id}
                    </Typography>
                    <Button
                        onClick={() => dispatch(toggleFormResourcecategories())}
                        color="primary"
                        variant="text"
                    >
                        <CloseRounded />
                    </Button>
                </Grid>
            </DialogTitle>

            <DialogContent className={formClasses.bodyForm}>
                <Grid
                    container
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={2}
                >
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="filled">
                            <ResourcetypeSelect
                                value={resourcecategory.resourcetypeId}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth variant="filled">
                            <InputCountry
                                value={resourcecategory.countries}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth variant="filled">
                            <BrandMultiAutocomplete
                                value={resourcecategory.brands}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="filled">
                            <RoleAutocomplete
                                value={resourcecategory.role}
                                handleInputChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        indicatorColor="secondary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        className={formClasses.tapForm}
                        aria-label="disabled tabs example"
                    >
                        <Tab
                            label={t('general') + '-' + t('language')}
                            {...a11yProps(0)}
                            className={
                                errorTab.general ? formClasses.errorTab : ''
                            }
                        />
                    </Tabs>
                </Grid>
                <TabPanel value={currentTab} index={0}>
                    {resourcecategory.translations.map(
                        (translation: any, index: number) => (
                            <Grid container spacing={2} key={translation.text}>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        id={'txt-text' + index}
                                        label={t('name')}
                                        type="text"
                                        variant="filled"
                                        value={translation.text}
                                        autoFocus={true}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleChangeTranslations(
                                            'text',
                                            index
                                        )}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            shrink={true}
                                            id="languages-label"
                                        >
                                            {t('languages') + '*'}
                                        </InputLabel>
                                        <Select
                                            value={translation.language_id}
                                            labelId="languages-label"
                                            id={
                                                'languages-select-outlined' +
                                                index
                                            }
                                            fullWidth
                                            label={t('languages')}
                                            onChange={handleChangeTranslations(
                                                'language_id',
                                                index
                                            )}
                                        >
                                            {authUser.languages_name.map(
                                                (
                                                    language: any,
                                                    index: number
                                                ) => (
                                                    <MenuItem
                                                        key={language.id}
                                                        value={language.id}
                                                    >
                                                        {language.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <IconButton
                                        aria-label="add"
                                        color="primary"
                                        size="medium"
                                        onClick={() => addTranslation()}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                    {index !== 0 && (
                                        <IconButton
                                            aria-label="delete"
                                            color="primary"
                                            size="medium"
                                            onClick={() =>
                                                deleteTranslation(
                                                    resourcecategory
                                                        .translations.index
                                                )
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        )
                    )}
                </TabPanel>
            </DialogContent>

            <DialogActions
                className={
                    !isValidForm && Object.keys(msgForm).length > 0
                        ? formClasses.errorDialogActions
                        : ''
                }
            >
                {resourcecategory.id !== 0 && (
                    <DeleteButton
                        onClick={() =>
                            dispatch(
                                deleteResourcecategory(resourcecategory.id)
                            )
                        }
                    >
                        {t('delete')}
                    </DeleteButton>
                )}
                <CancelButton
                    onClick={() => dispatch(toggleFormResourcecategories())}
                >
                    {t('cancel')}
                </CancelButton>
                <SaveButton onClick={handleSubmit}>{t('save')}</SaveButton>
            </DialogActions>
        </Dialog>
    )
}

export default CategoriesModal
