// React
import { useEffect, useState, MouseEvent } from "react";
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';

// Material UI
import { Snackbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import { StarBorder, Star } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

// Libraries
import { useTranslation } from "react-i18next";

// Types
type HighlightIconProps = {
    id: number,
    isHighlight: boolean,
    handleClick: (id: number, isHighlight: boolean) => void
}

function HighlightIcon({ id, isHighlight, handleClick }: HighlightIconProps) {
    // Translate
    const { t } = useTranslation(["global"]);

    useEffect(() => {

    }, [])

    return (
        <IconButton
            aria-controls="share-link-menu"
            aria-haspopup="false"
            style={{ padding: '0px', marginBottom: '3px' }}
            onClick={() => handleClick(id, isHighlight)}>
            {(isHighlight) ?
                <Star htmlColor={"#FDDD35"} fontSize="small"/>
                :
                <StarBorder fontSize="small" />
            }
        </IconButton>
    );
}

export default HighlightIcon;