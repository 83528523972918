// React
import { useState, useEffect, forwardRef, ReactElement, Ref } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Input,
  Slide,
  Box,
  ThemeProvider,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";

// Libraries
import { useTranslation } from "react-i18next";

//Custom components
import ButtonBack from "../common/ButtonBack";
import { CancelButton, SaveButton } from "../common/forms";

// Redux Actions
import { IRootState } from "../../redux/types";
import {
  formValidator,
  resetFormValidator,
} from "../../redux/actions/validator";
import {
  updateIntegration,
  toggleFormIntegrations,
} from "../../redux/actions/integrations";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Assets
import logo from "../../assets/Images/logos/logodark.svg";

//Styles
import FormStyles from "../../styles/components/common/forms/FormsStyles";
import ResourceStyle from "../../styles/pages/LoginRegisterStyle";
import mainTheme from "../../styles/components/themes/NavLayoutTheme";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IIntegration {
  id: number;
  name: null | string;
  api_key: null | string;
  token: null | string;
  url: null | string;
  status: null | boolean;
}

function TokentModal({ stateModal, handleClose }: any) {
  //Styles
  const classes = FormStyles();
  const classses = ResourceStyle();

  // Translate
  const { t } = useTranslation(["global"]);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);

  //Custom Hook
  const isMobile = useScreenWidth(700);

  // Redux Hooks
  const dispatch = useDispatch();
  const { integrationSelected } = useSelector(
    (state: IRootState) => state.integration
  );
  const { isValidForm, errorForm, msgForm } = useSelector(
    (state: IRootState) => state.validator
  );

  const handleGoBack = () => {
    setShowRegistration(false);
    setShowLogin(false);
  };

  const [integration, setIntegration] = useState<IIntegration>({
    id: 0,
    name: null,
    api_key: null,
    token: null,
    url: null,
    status: null,
  });

  useEffect(() => {
    if (
      integrationSelected !== null &&
      integrationSelected.id !== integration.id
    ) {
      setIntegration({
        id: integrationSelected.id,
        name: integrationSelected.name,
        api_key: integrationSelected.api_key,
        token: integrationSelected.token,
        url: integrationSelected.url,
        status: integrationSelected.status,
      });
    }
  }, [integrationSelected]);

  useEffect(() => {
    if (isValidForm) {
      dispatch(resetFormValidator());
      dispatch(updateIntegration(integration));
    }
  }, [isValidForm]);

  const handleSubmit = () => {
    dispatch(
      formValidator({
        api_key: [integration.api_key, "required|max:45|min:2"],
      })
    );
  };

  const handleInputChange = (inputName: string) => (event: any) => {
    const value = event.target.value;
    setIntegration({ ...integration, [inputName]: value });
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog
        open={stateModal}
        fullWidth={true}
        fullScreen={isMobile && true}
        maxWidth={(!showLogin && !showRegistration) || isMobile ? false : "sm"}
        TransitionComponent={Transition}
        onClose={() => {
          dispatch(toggleFormIntegrations());
        }}
        aria-labelledby="alert-dialog-title"
        className={classes.containerForm}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            alignItems="center"
            justify={
              !showLogin && !showRegistration ? "flex-end" : "space-between"
            }
          >
            {!showLogin && !showRegistration ? (
              <Button
                onClick={() => {
                  dispatch(toggleFormIntegrations());
                }}
                color="primary"
                variant="text"
              >
                <CloseRounded />
              </Button>
            ) : (
              <>
                <img src={logo} height="24" alt="logo" />
                <ButtonBack
                  customFunction={handleGoBack}
                  goBack={false}
                  async={false}
                />
              </>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.bodyFormLink}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            justify={
              !showLogin && !showRegistration ? "flex-end" : "space-between"
            }
          >
            <Grid className={classses.form + "live"}>
              <Grid container spacing={3} className={classses.formBody}>
                <Grid item xs={12}>
                  <Typography
                    className={classses.registerHeroTitle + "live"}
                    color="textPrimary"
                    variant="h4"
                  >
                    Integración de {process.env.REACT_APP_NAME} con Zapier
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={0.5}>
                    <Typography>API Key</Typography>
                  </Box>
                  <FormControl variant="filled" fullWidth>
                    <Input
                      placeholder={"API Key"}
                      value={integration.api_key}
                      onChange={handleInputChange("api_key")}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={0.5}>
                    <Typography>URL</Typography>
                  </Box>
                  <FormControl variant="filled" fullWidth>
                    <Input
                      placeholder={"URL"}
                      value={integration.url}
                      onChange={handleInputChange("url")}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={0.5}>
                    <Typography>Token</Typography>
                  </Box>
                  <FormControl variant="filled" fullWidth>
                    <Input
                      placeholder={"Token"}
                      value={integration.token}
                      onChange={handleInputChange("token")}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={0.5}>
                    <Typography>Estado</Typography>
                  </Box>
                  <FormControl fullWidth variant="filled">
                    <Select
                      value={integration.status}
                      labelId="lbl-roles"
                      id="combo-box-roles"
                      fullWidth
                      label="rol"
                      onChange={handleInputChange("status")}
                    >
                      {["Inactivo", "Activo"].map(
                        (status: string, key: number) => (
                          <MenuItem key={status} value={key}>
                            {status}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {/* <TermsModal displayTerms={displayTerms} handleDisplayTerms={handleDisplayTerms} />  */}
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    gridGap={24}
                  >
                    <CancelButton
                      className={classes.inscription}
                      onClick={() => {
                        dispatch(toggleFormIntegrations());
                      }}
                    >
                      {t("cancel")}
                    </CancelButton>
                    <SaveButton
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {t("continue")}
                    </SaveButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default TokentModal;
