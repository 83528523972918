import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_SLIDERS, SUCCESS_GET_SLIDERS, ERROR_GET_SLIDERS,
    START_GET_FILTERED_SLIDERS, SUCCESS_GET_FILTERED_SLIDERS, ERROR_GET_FILTERED_SLIDERS,
    START_GET_INDEX_SLIDERS, SUCCESS_GET_INDEX_SLIDERS, ERROR_GET_INDEX_SLIDERS,
    START_GET_INTERNAL_BANNER, SUCCESS_GET_INTERNAL_BANNER, ERROR_GET_INTERNAL_BANNER,
    START_CREATE_SLIDER, SUCCESS_CREATE_SLIDER, ERROR_CREATE_SLIDER,
    START_SHOW_SLIDER, SUCCESS_SHOW_SLIDER, ERROR_SHOW_SLIDER,
    START_DELETE_SLIDER, SUCCESS_DELETE_SLIDER, ERROR_DELETE_SLIDER,
    START_SLIDER_SEND_EMAIL, SUCCESS_SLIDER_SEND_EMAIL, ERROR_SLIDER_SEND_EMAIL,
    START_TOGGLE_FORM_SLIDER, SUCCESS_TOGGLE_FORM_SLIDER
} from '../types';
import { apiCall } from '../config/axios';

function* getSliders() {
    try {
        const response = yield call(apiCall, 'get', '/sliders/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_SLIDERS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_SLIDERS, payload: error.message })
    }
}

function* getFilteredSliders({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/sliders/filteredsliders', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_FILTERED_SLIDERS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_FILTERED_SLIDERS, payload: error.message })
    }
}

function* getIndexSliders({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/sliders/indexclient', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_INDEX_SLIDERS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_INDEX_SLIDERS, payload: error.message })
    }
}

function* getInternalBanner({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/sliders/internalbanner', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_INTERNAL_BANNER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_INTERNAL_BANNER, payload: error.message })
    }
}

function* createSlider({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/sliders/store', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CREATE_SLIDER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CREATE_SLIDER, payload: error.message })
    }
}

function* showSlider({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/sliders/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_SLIDER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_SLIDER, payload: error.message })
    }
}

function* deleteSlider({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/sliders/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_SLIDER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_SLIDER, payload: error.message })
    }
}

function* sendSliderEmail({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/sliders/sendemail', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SLIDER_SEND_EMAIL, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SLIDER_SEND_EMAIL, payload: error.message })
    }
}

function* toggleFormSlider() {
    yield put({ type: SUCCESS_TOGGLE_FORM_SLIDER })
}

export default function* categories() {
    yield takeLatest(START_GET_SLIDERS, getSliders);
    yield takeLatest(START_GET_FILTERED_SLIDERS, getFilteredSliders);
    yield takeLatest(START_GET_INDEX_SLIDERS, getIndexSliders);
    yield takeLatest(START_GET_INTERNAL_BANNER, getInternalBanner);
    yield takeLatest(START_CREATE_SLIDER, createSlider);
    yield takeLatest(START_SHOW_SLIDER, showSlider);
    yield takeLatest(START_DELETE_SLIDER, deleteSlider);
    yield takeLatest(START_SLIDER_SEND_EMAIL, sendSliderEmail);
    yield takeLatest(START_TOGGLE_FORM_SLIDER, toggleFormSlider);
}