// React
import { useState, useEffect, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Box,
    Menu,
    MenuItem,
    IconButton,
    Container,
    Grid,
    Typography,
    Tooltip,
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core'
import { InfoOutlined, MoreVert, Delete, RotateLeftRounded, CloseRounded, DoubleArrow } from '@material-ui/icons'
import VerifiedIcon from '../../assets/vector/VerifiedCustomIcon.svg'
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table'

// Commons components
import CustomSnackBar from '../../components/common/admin/CustomSnackBar'
import ScreenLoading from '../../components/common/ScreenLoading'
import UserModal from '../../components/users/UserModal'
import UserMassivelyAssign from '../../components/users/UserMassivelyAssign'
import Header from '../../components/common/header/Header'
import SubHeader from '../../components/common/header/Subheader'
import TypeFilter from '../../components/common/header/TypeFilter'
import ScrollSubHeader from '../../components/common/header/ScrollSubHeader'
import CountriesFilter from '../../components/common/admin/CountryFilter'
import CustomChip from '../../components/common/admin/CustomChip'
import AdminUserFilter from '../../components/layouts/filters/AdminUserFilter'
import NotFilter from '../NotFilter'
import { CancelButton, DeleteButton, SaveButton } from '../../components/common/forms'

// Redux Actions
import { deleteUser, restoreUser, filterUsers, showUser } from '../../redux/actions/users'
import { getRoles } from '../../redux/actions/roles'
import { getLocations } from '../../redux/actions/countries'
import { getLanguages, getFilteredLanguages } from '../../redux/actions/languages'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import UsersStyle from '../../styles/components/common/UsersStyle';
import TableScreenStyles from '../../styles/components/common/admin/TableScreenStyles';
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import HiddenDesktop from '../../components/common/HiddenDesktop';

// Types
type IType = 'string' | 'boolean' | 'numeric' | 'date' | 'datetime' | 'time' | 'currency'
const string: IType = 'string'
interface IFilter {
    role_id: number
    countries: number[]
    verificationId: null | number
    requestVip: null | number
    filterBy?: any,
    header_role_id?: number | null,
    subheader_role_id?: number | null
}

function Users() {
    //Styles
    const classes = UsersStyle()
    const tableclass = TableScreenStyles()
    const formClasses = FormsStyles()

    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Redux Hooks
    const dispatch = useDispatch()
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { users, reload, error, msg, loading } = useSelector((state: IRootState) => state.user)
    const { countries, provinces, error: errorCountry, msg: msgCountry } = useSelector((state: IRootState) => state.country)
    const { languages } = useSelector((state: IRootState) => state.language);
    const { roles, rolehierarchy, error: errorRole, msg: msgRole } = useSelector((state: IRootState) => state.role)
    const { globalCountriesId } = useSelector((state: IRootState) => state.global)

    // Delete states
    const [openDeleteUser, setOpenDeleteUser] = useState(false)
    const [userSelected, setUserSelected] = useState<any>(false)

    // Table Menu State
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null)
    const [openOptions, setOpenOptions] = useState(-1)
    // Filters State
    const [filters, setFilters] = useState<IFilter>({
        role_id: rolehierarchy.length !== 0 ? rolehierarchy[0] : 0,
        countries: [],
        verificationId: null,
        requestVip: null,
    })
    // Massive Assign id of users
    const [userIds, setUserIds] = useState<number[]>([])

    // Variables
    const status = [
        { id: 0, name: 'Inactivo' },
        { id: 1, name: 'Activo' },
    ]
    const optionsData = [{ data: status, code: 'status', column: 'status' }]
    let extraRoleOptions: any = []
    extraRoleOptions[2] = []
    extraRoleOptions[3] = []
    extraRoleOptions[4] = [{ id: 4, name: t('admin.people.pending-vip') }]
    extraRoleOptions[5] = []
    extraRoleOptions[6] = [
        { id: 1, name: t('admin.people.verified') },
        { id: 0, name: t('admin.people.pending') },
        { id: 3, name: t('admin.people.denied') },
        { id: 4, name: t('admin.people.pending-vip') },
    ]
    extraRoleOptions[7] = [{ id: 4, name: t('admin.people.pending-vip') }]

    // Ready Page
    useEffect(() => {
        if (!roles.length) dispatch(getRoles())
        if (!provinces.length) dispatch(getLocations())

        if (!languages.length) {
            if (authUser.role_id === 1) dispatch(getLanguages())
            else dispatch(getFilteredLanguages())
        }
    }, [])

    useEffect(() => {
        if (reload && !error && filters.role_id !== 0) {
            dispatch(filterUsers(filters));
            setUserIds([]);
        }
    }, [reload])

    useEffect(() => {
        if (filters.role_id !== 0 && filters.countries.length > 0)
            dispatch(filterUsers(filters))
    }, [filters])

    useEffect(() => {
        setFilters({ ...filters, countries: globalCountriesId })
    }, [globalCountriesId])

    useEffect(() => {
        if (rolehierarchy.length !== 0) setFilters({ ...filters, role_id: rolehierarchy[0], countries: globalCountriesId, header_role_id: rolehierarchy[0] })
    }, [rolehierarchy])

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null)
        setOpenOptions(-1)
    }

    const ApplyChangeFilter = ({ role_id, verificationId = null, requestVip = null, header_role_id, subheader_role_id }: { role_id: number, verificationId?: null | number, requestVip?: null | number, header_role_id: number, subheader_role_id: number | null }) => {
        setFilters({ ...filters, role_id, verificationId, requestVip, header_role_id, subheader_role_id })
    }

    const handleChangeFilter = (role_id: number, verificationId: null | number) => {
        if (verificationId === 4) ApplyChangeFilter({ role_id, verificationId: null, requestVip: 1, header_role_id: role_id, subheader_role_id: verificationId })
        else if (role_id === 4 && (verificationId === 8 || verificationId === 9)) {
            ApplyChangeFilter({ role_id, verificationId, header_role_id: role_id, subheader_role_id: verificationId });
        } else if (role_id === 6) {
            if (verificationId != null) ApplyChangeFilter({ role_id, verificationId, header_role_id: role_id, subheader_role_id: verificationId })
            else ApplyChangeFilter({ role_id, header_role_id: role_id, subheader_role_id: null })
        } else {
            if (verificationId != null) ApplyChangeFilter({ role_id: verificationId, header_role_id: verificationId, subheader_role_id: null })
            else ApplyChangeFilter({ role_id, header_role_id: role_id, subheader_role_id: verificationId })
        }
        setUserIds([])
    }

    const handleClickSetOptions = (options: any) => {
        if (authUser !== null && filters.role_id !== 0) setFilters({ ...filters, filterBy: options })
    }

    const handleClearFilters = () => {
        const options = { contain: 0, menu: [], status: [], brand: [] }
        setFilters({ ...filters, filterBy: options })
    }

    const handleRoleOptions = (role_id: number) => {
        const childRoles = roles.filter((role: any) => role_id === role.parent_id)
        let filterExtraRoleOptions: any

        // Remove pending VIPs for commercials
        if ([4, 8, 9].includes(authUser.role_id)) filterExtraRoleOptions = extraRoleOptions[role_id].filter((roleOption: any) => roleOption.id !== 4)
        else filterExtraRoleOptions = extraRoleOptions[role_id]

        return childRoles.concat(filterExtraRoleOptions)
    }

    const myElement: HTMLElement | null = document.getElementById('windowUsers')
    let midOfWidth = window.innerWidth / 2

    const columns: any = [
        { title: 'ID', field: 'id', hidden: isMobile, render: (rowData: any) => 'PL-' + rowData.id },
        {
            title: t('name'),
            field: 'name',
            render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        {(() => {
                            if (rowData.deleted_at != null) {
                                return <CustomChip size="small" color={'gray'} />
                            }
                            switch (rowData.status) {
                                case 0:
                                    return <CustomChip size="small" color={'secondary'} />
                                case 1:
                                    return <CustomChip size="small" color={'primary'} />
                                default:
                                    return <CustomChip size="small" color={'secondary'} />
                            }
                        })()}
                        <Tooltip placement="bottom-start" TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={rowData.name}>
                            <Typography className={tableclass.nameResource}>{rowData.name}</Typography>
                        </Tooltip>
                    </Box>
                )
            },
        },
        {
            title: t('admin.people.verification'),
            field: '',
            render: (rowData: any) => {
                if (rowData.verification === 1) {
                    return (
                        <Box pl={4} pt={1}>
                            <object data={VerifiedIcon} aria-label={t('admin.people.verified')} className={classes.iconVerified} />
                        </Box>
                    )
                }
                if (rowData.verification === 3) {
                    return (
                        <Box pl={4} pt={1}>
                            <object data={VerifiedIcon} aria-label={t('deny')} className={classes.iconDenied} />
                        </Box>
                    )
                } else {
                    return (
                        <Box pl={4} pt={1}>
                            <object data={VerifiedIcon} aria-label={t('admin.people.unverified')} className={classes.iconUnverified} />
                        </Box>
                    )
                }
            },
            hidden: filters.role_id !== 6,
        },
        {
            title: t('code'),
            field: 'code',
            hidden: ![4, 8, 9].includes(filters.role_id),
        },
        { title: t('province'), field: 'province', hidden: isMobile, type: string },
        { title: t('postal-code'), field: 'postalcode', hidden: isMobile, type: string },
        { title: t('phone'), field: 'phone', hidden: isMobile, type: string },
        {
            title: t('email'),
            field: 'email',
            hidden: isMobile,
            render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        <Tooltip placement="bottom-start" TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={rowData.email}>
                            <Typography className={tableclass.emailText}>{rowData.email}</Typography>
                        </Tooltip>
                    </Box>
                )
            },
        },
        {
            title: '',
            field: '',
            render: (rowData: any) => (
                <>
                    <IconButton
                        id={`icon-button-${rowData.id}`}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget)
                            setOpenOptions(rowData.id)
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    {rowData.deleted_at != null ? (
                        <Menu
                            id={`simple-menu-${rowData.id}`}
                            anchorEl={anchorOp}
                            getContentAnchorEl={null}
                            keepMounted
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            open={rowData.id === openOptions}
                            onClick={handleCloseOptions}
                            onClose={handleCloseOptions}
                        >
                            {authUser.role.code <= 2 && (
                                <MenuItem
                                    onClick={() => {
                                        setOpenDeleteUser(!openDeleteUser)
                                        setUserSelected(rowData)
                                    }}
                                >
                                    {t('restore')}
                                </MenuItem>
                            )}
                        </Menu>
                    ) : (
                        <Menu
                            id={`simple-menu-${rowData.id}`}
                            anchorEl={anchorOp}
                            getContentAnchorEl={null}
                            keepMounted
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            open={rowData.id === openOptions}
                            onClick={handleCloseOptions}
                            onClose={handleCloseOptions}>
                            <MenuItem onClick={() => { window.open(`/admin/usuarios/${rowData.id}`, '_blank') }}>{t("view")}</MenuItem>
                            <MenuItem onClick={() => { dispatch(showUser(rowData.id)) }}>{t("edit")}</MenuItem>
                            {(authUser.role.code <= 3) &&
                                <MenuItem onClick={() => { setOpenDeleteUser(!openDeleteUser); setUserSelected(rowData) }}>{t("delete")}</MenuItem>
                            }
                        </Menu>
                    )}
                </>
            ),
        },
    ]

    return (
        <>
            <Header title={t('admin.people.people')} />
            <SubHeader>
                <ScrollSubHeader className="usersHeader" id="windowUsers">
                    {roles.filter((role: any) => rolehierarchy.includes(role.id) && [2, 3, 4, 5, 6, 7].includes(role.id)).map((role: any) => (
                        <TypeFilter
                            key={role.id}
                            id={role.id}
                            type={role.name}
                            categories={handleRoleOptions(role.id)}
                            active={role.id === filters.header_role_id}
                            filterCategoryId={filters.subheader_role_id}
                            handleChangeFilter={handleChangeFilter}
                        />
                    ))}
                </ScrollSubHeader>
                <HiddenDesktop>
                    <IconButton
                        className="buttonScroll"
                        onClick={() => {
                            myElement?.scrollBy(midOfWidth, 0)
                        }}
                    >
                        <DoubleArrow />
                    </IconButton>
                </HiddenDesktop>
            </SubHeader>

            <Container>
                <Box
                    mt={isMobile ? 2 : 6}
                    gridGap={16}
                    display="flex"
                    flexDirection={isMobile ? 'column-reverse' : 'column'}
                >
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h5" align="center">
                            {roles.length && filters.role_id !== 0 ? roles.find((role: any) => role.id === filters.role_id).name : ''}
                        </Typography>
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            arrow
                            title={
                                <Box>
                                    <Typography variant="body2">{t('status')}:</Typography>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'primary'} />
                                        <Typography variant="caption">{t('active')}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'secondary'} />
                                        <Typography variant="caption">{t('inactive')}</Typography>
                                    </Box>
                                    <Box display="flex" gridGap={8} alignItems="center">
                                        <CustomChip size="small" color={'gray'} />
                                        <Typography variant="caption">{t('deleted')}</Typography>
                                    </Box>
                                </Box>
                            }
                        >
                            <IconButton>
                                <InfoOutlined htmlColor={'#8F99A3'} fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Grid container justify="space-between" spacing={1}>
                            <Grid item xs={5} sm={6} className={tableclass.actionsResponsive}>
                                {userIds.length > 0 ? <UserMassivelyAssign userIds={userIds} /> : <UserModal role_id={filters.role_id} />}
                            </Grid>
                            <Grid item xs={7} sm={6} className={tableclass.actionsResponsiveRight}>
                                <AdminUserFilter data={optionsData} handleClick={handleClickSetOptions} />
                                <CountriesFilter />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {!users.length && !loading ? <NotFilter subsection={null} handleClearFilters={handleClearFilters} /> :
                    <Box mt={2} mb={5}>
                        <div className="table_custom">
                            <MaterialTable
                                localization={localization}
                                title="Usuarios Admin"
                                columns={columns}
                                data={users}
                                actions={[]}
                                options={{ ...options, selection: true }}
                                icons={tableIcons}
                                onSelectionChange={(rows: any) => { setUserIds(rows.map((row: any) => row.id)) }}
                            />
                        </div>
                    </Box>}

                <Dialog
                    open={openDeleteUser}
                    fullWidth={true}
                    fullScreen={isMobile && true}
                    maxWidth={!isMobile && "sm"}
                    className={formClasses.containerForm}
                    onClose={() => setOpenDeleteUser(!openDeleteUser)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {userSelected.deleted_at != null ? t('restore') : t('delete')} {t('user')}
                            </Typography>
                            <Button onClick={() => setOpenDeleteUser(!openDeleteUser)} color="primary" variant="text">
                                <CloseRounded />
                            </Button>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={formClasses.bodyForm}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} >
                                <Typography>
                                    {userSelected.deleted_at != null ? t('admin.people.restore-user-text') : t('admin.people.delete-user-text')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton onClick={() => setOpenDeleteUser(!openDeleteUser)}>{t('cancel')}</CancelButton>
                        {userSelected.deleted_at != null ? (
                            <SaveButton
                                autoFocus
                                id="btn-handlelRestore"
                                endIcon={<RotateLeftRounded />}
                                onClick={() => {
                                    dispatch(restoreUser(userSelected.id))
                                    setOpenDeleteUser(!openDeleteUser)
                                }}
                            >
                                {t('restore')}
                            </SaveButton>
                        ) : (
                            <DeleteButton
                                autoFocus
                                id="btn-handleldelete"
                                endIcon={<Delete />}
                                onClick={() => {
                                    dispatch(deleteUser(userSelected.id))
                                    setOpenDeleteUser(!openDeleteUser)
                                }}
                            >
                                {t('delete')}
                            </DeleteButton>
                        )}
                    </DialogActions>
                </Dialog>

                <ScreenLoading loadings={[loading]} />
                <CustomSnackBar errors={[error, errorCountry, errorRole]} msgs={[msg, msgCountry, msgRole]} />
            </Container>
        </>
    )
}

export default Users
