import {
    IGlobalState,
    SUCCESS_SET_GLOBAL_FAMILY,
    SUCCESS_SET_GLOBAL_BRAND,
    SUCCESS_SET_GLOBAL_LINE,
    SUCCESS_SET_GLOBAL_LANGUAGE,
    SUCCESS_SET_GLOBAL_COUNTRY,
    SUCCESS_SET_GLOBAL_COUNTRIES,
    SUCCESS_SET_GLOBAL_ROLE,
    SUCCESS_SET_GLOBAL_ORDERBY,
    SUCCESS_SET_GLOBAL_TAG,
    START_GLOBAL_SEARCH_QUERY, SUCCESS_GLOBAL_SEARCH_QUERY
} from '../types';

// Convert string[] to int[] from localStorage
const split = (countries_ids: string) => {
    if (!countries_ids)
        return []

    let countriesIds = countries_ids.split(',');

    let countriesIdsFormat = [];
    for (let index = 0; index < countriesIds.length; index++) {
        countriesIdsFormat.push(parseInt(countriesIds[index]));
    }

    return countriesIdsFormat;
}

const initialState: IGlobalState = {
    globalFamilyId: isNaN(parseInt(localStorage.getItem('globalFamilyId') || '')) ? null : parseInt(localStorage.getItem('globalFamilyId') || ''),
    globalBrandId: isNaN(parseInt(localStorage.getItem('globalBrandId') || '')) ? null : parseInt(localStorage.getItem('globalBrandId') || ''),
    globalLineId: isNaN(parseInt(localStorage.getItem('globalLineId') || '')) ? null : parseInt(localStorage.getItem('globalLineId') || ''),
    globalLanguageId: isNaN(parseInt(localStorage.getItem('globalLanguageId') || '')) ? null : parseInt(localStorage.getItem('globalLanguageId') || ''),
    globalCountryId: isNaN(parseInt(localStorage.getItem('globalCountryId') || '')) ? null : parseInt(localStorage.getItem('globalCountryId') || ''),
    globalCountriesId: split(localStorage.getItem('globalCountriesId') || ''),
    globalRoleId: isNaN(parseInt(localStorage.getItem('globalRoleId') || '')) ? null : parseInt(localStorage.getItem('globalRoleId') || ''),
    globalOrderById: isNaN(parseInt(localStorage.getItem('globalOrderById') || '')) ? 0 : parseInt(localStorage.getItem('globalOrderById') || ''),
    globalTag: isNaN(parseInt(localStorage.getItem('globalTag') || '')) ? null : parseInt(localStorage.getItem('globalTag') || ''),
    globalSearch: {
        courses: [],
        events: [],
        offers: [],
        novelties: [],
        resources: [],
        seminars: [],
        count: 0
    },
    loadingSearch: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GLOBAL_SEARCH_QUERY:
            return {
                ...state,
                loadingSearch: true,
            }
        case SUCCESS_SET_GLOBAL_FAMILY:
            return {
                ...state,
                globalFamilyId: action.payload,
            }
        case SUCCESS_SET_GLOBAL_BRAND:
            return {
                ...state,
                globalBrandId: action.payload,
            }
        case SUCCESS_SET_GLOBAL_LINE:
            return {
                ...state,
                globalLineId: action.payload,
            }
        case SUCCESS_SET_GLOBAL_LANGUAGE:
            return {
                ...state,
                globalLanguageId: action.payload,
            }
        case SUCCESS_SET_GLOBAL_COUNTRY:
            return {
                ...state,
                globalCountryId: action.payload,
            }
        case SUCCESS_SET_GLOBAL_COUNTRIES:
            return {
                ...state,
                globalCountriesId: action.payload,
            }
        case SUCCESS_SET_GLOBAL_ROLE:
            return {
                ...state,
                globalRoleId: action.payload,
            }
        case SUCCESS_SET_GLOBAL_ORDERBY:
            return {
                ...state,
                globalOrderById: action.payload,
            }
        case SUCCESS_SET_GLOBAL_TAG:
            return {
                ...state,
                globalTag: action.payload,
            }
        case SUCCESS_GLOBAL_SEARCH_QUERY:
            return {
                ...state,
                globalSearch: action.payload.globalsearch,
                loadingSearch: false
            }
        default:
            return state;
    }
}