// React
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// Material UI
import {
  Grid,
  Typography,
  Box,
  Divider,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import {
  Facebook,
  LinkedIn,
  Instagram,
  BugReportRounded,
} from "@material-ui/icons";
import clsx from "clsx";

import { IRootState } from "../../../redux/types";

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../../hooks/useScreenWidth";

const FooterStyles = makeStyles((theme) => ({
  footerResponsive: {
    marginBottom: "-100px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 100,
    },
  },
  footerDinamyc: {
    [theme.breakpoints.down("sm")]: {
      flexFlow: "wrap-reverse",
      "& hr": {
        display: "none",
      },
    },
  },
  footer: {
    "&.dark": {
      backgroundColor: theme.palette.grey[800],
      "& span": {
        color: `${theme.palette.grey[200]} !important`,
      },
      "& a": {
        "& span": {
          color: `${theme.palette.primary.main} !important`,
        },
        "& svg": {
          color: `${theme.palette.common.white} !important`,
        },
      },
    },
    "& a": {
      maxHeight: 22,
      textDecorationColor: "transparent",
    },
  },
  footerText: {
    display: "flex",
    gap: "8px",
    "& a": {
      color: theme.palette.secondary.main,
      textDecorationColor: "transparent",
      display: "flex",
      "& svg": {
        color: theme.palette.grey[400],
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
      "& a": {
        "& svg": {
          fontSize: 28,
        },
      },
    },
  },
  footerTextLR: {
    justifyContent: "center",
    display: "flex",
    alignItems: "flex-end",
    gap: "8px",
    paddingTop: 8,
    flexWrap: "wrap",
    textAlign: "center",
    "& a": {
      color: theme.palette.secondary.main,
      textDecorationColor: "transparent",
      display: "flex",
      "& svg": {
        color: theme.palette.grey[400],
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
      "& a": {
        "& svg": {
          fontSize: 28,
        },
      },
    },
  },
}));

// Types
type FooterProps = {
  dark?: boolean;
  noMargin?: boolean;
};

const Footer = ({ dark = false, noMargin = false }: FooterProps) => {
  // Translate
  const { t } = useTranslation(["global"]);
  //Custom Hook
  const isTablet = useScreenWidth(960);
  // Styles
  const classes = FooterStyles();
  //Location
  const location = useLocation();

  // Redux Hooks
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const isAdmin =
    location.pathname.includes("admin") ||
    location.pathname === "/escritorio" ||
    location.pathname === "/usuarios/perfil";
  const isInHomePage = location.pathname === "/inicio";
  const shouldShowMobileMenu = isAdmin || (isInHomePage || isTablet);

  return (
    <>
      {authUser !== null ? (
        <Box
          mt={6}
          bottom="0"
          top="100%"
          ml={shouldShowMobileMenu || noMargin ? "0px" : "235px"}
          className={clsx(classes.footerResponsive, dark && "dark")}
        >
          <Divider style={{ backgroundColor: "#C7CCD1" }} />
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            className={classes.footerDinamyc}
          >
            <Box display="flex" gridGap={8} alignItems="center">
              <Divider
                style={{ backgroundColor: "#C7CCD1" }}
                orientation="vertical"
                flexItem
              />
              <Tooltip placement="top-start" title={`${t("report-a-problem")}`}>
                <Typography
                  align="center"
                  variant="caption"
                  color="textSecondary"
                  className={classes.footerText}
                >
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://drv.es/reportar-error-en-plei/"
                  >
                    <BugReportRounded fontSize="small" />
                  </a>
                </Typography>
              </Tooltip>
              <Divider
                style={{ backgroundColor: "#C7CCD1" }}
                orientation="vertical"
                flexItem
              />
            </Box>
            <Box
              alignContent="center"
              gridGap={16}
              justifyContent="center"
              display="flex"
              flexWrap="wrap"
              p={1}
              alignItems="center"
              margin="0 auto"
            >
              <Typography
                align="center"
                variant="caption"
                color="textSecondary"
                className={classes.footerText}
              >
                {t("footer.shortfooter", {
                  year: `${new Date().getFullYear()}`,
                  COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME,
                })}
              </Typography>
              {location.pathname.includes("ayuda") ? (
                <Typography
                  align="center"
                  variant="caption"
                  color="textSecondary"
                  className={classes.footerText}
                >
                  |
                </Typography>
              ) : (
                <Typography
                  align="center"
                  variant="caption"
                  color="textSecondary"
                  className={classes.footerText}
                >
                  |
                  <Link to="/ayuda">
                    <Typography variant="caption" style={{ color: "##FC3E6F" }}>
                      {t("help")}
                    </Typography>
                  </Link>
                  |
                </Typography>
              )}
              <Typography
                align="center"
                variant="caption"
                color="textSecondary"
                className={classes.footerText}
              >
                <a href="https://www.facebook.com/GrupoDRV/">
                  <Facebook fontSize="small" />
                </a>
                <a href="https://www.linkedin.com/company/grupo-drv-phytolab/">
                  <LinkedIn fontSize="small" />
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Grid item xs={12}>
          <Box
            p={3}
            px={4}
            maxWidth="100%"
            gridGap={16}
            className={clsx(classes.footer, dark && "dark")}
          >
            {location.pathname !== "/registro" ? (
              location.pathname === "/contrasena-cliente" ? (
                ""
              ) : (
                <Box className={classes.footerTextLR}>
                  <Typography variant="caption" color="textSecondary">
                    {t("footer.signupcopy")}
                  </Typography>
                  <Link to="/registro">
                    <Typography variant="caption" color="primary">
                      {t("footer.signup")}
                    </Typography>
                  </Link>
                </Box>
              )
            ) : (
              <Box className={classes.footerTextLR}>
                <Typography variant="caption" color="textSecondary">
                  {t("login.logincopy")}
                </Typography>
                <Link to="/iniciar-sesion">
                  <Typography variant="caption" color="primary">
                    {t("login.login")}
                  </Typography>
                </Link>
              </Box>
            )}
            <Box>
              <Typography
                align="center"
                variant="caption"
                color="textSecondary"
                className={classes.footerTextLR}
              >
                {t("footer.shortfooter", {
                  year: `${new Date().getFullYear()}`,
                  COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME,
                })}
                <a href={process.env.REACT_APP_RRSS_FACEBOOK_URL}>
                  <Facebook fontSize="small" />
                </a>
                <a href={process.env.REACT_APP_RRSS_INSTAGRAM_URL}>
                  <Instagram fontSize="small" />
                </a>
              </Typography>
            </Box>
            <Box className={classes.footerTextLR}>
              <Tooltip title={`${t("report-a-problem")}`}>
                <Typography
                  align="center"
                  variant="caption"
                  color="textSecondary"
                  className={classes.footerTextLR}
                >
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://drv.es/reportar-error-en-plei/"
                  >
                    <BugReportRounded fontSize="small" />
                  </a>
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default Footer;
