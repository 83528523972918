import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_GET_COUNTRIES, SUCCESS_GET_COUNTRIES, ERROR_GET_COUNTRIES,
    START_GET_FILTERED_COUNTRIES, SUCCESS_GET_FILTERED_COUNTRIES, ERROR_GET_FILTERED_COUNTRIES,
    START_GET_LOCATIONS, SUCCESS_GET_LOCATIONS, ERROR_GET_LOCATIONS,
    START_STORE_LOCATION, SUCCESS_STORE_LOCATION, ERROR_STORE_LOCATION,
 } from '../types';
import { apiCall } from '../config/axios';

function* getCountries() {
    try{
        const response = yield call(apiCall, 'get', '/countries')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_COUNTRIES, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_COUNTRIES, payload: error.message})
    }
}

function* getFilteredCountries() {
    try{
        const response = yield call(apiCall, 'get', '/countries/filteredcountries')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_FILTERED_COUNTRIES, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_GET_FILTERED_COUNTRIES, payload: error.message})
    }
}

function* getLocations() {
    try{
        const response = yield call(apiCall, 'get', '/countries/locations')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_LOCATIONS, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_LOCATIONS, payload: error.message})
    }
}

function* storeLocation({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/countries/store', {location: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_STORE_LOCATION, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_STORE_LOCATION, payload: error.message})
    }
}

export default function* countries() {
    yield takeLatest(START_GET_COUNTRIES, getCountries);
    yield takeLatest(START_GET_FILTERED_COUNTRIES, getFilteredCountries);
    yield takeLatest(START_GET_LOCATIONS, getLocations);
    yield takeLatest(START_STORE_LOCATION, storeLocation);
}