import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_GET_RESOURCES, SUCCESS_GET_RESOURCES, ERROR_GET_RESOURCES,
    START_SHOW_RESOURCE, SUCCESS_SHOW_RESOURCE, ERROR_SHOW_RESOURCE,
    START_VIEW_RESOURCE, SUCCESS_VIEW_RESOURCE, ERROR_VIEW_RESOURCE,
    START_STORE_RESOURCE, SUCCESS_STORE_RESOURCE, ERROR_STORE_RESOURCE,
    START_UPDATE_RESOURCE, SUCCESS_UPDATE_RESOURCE, ERROR_UPDATE_RESOURCE,
    START_DELETE_RESOURCE, SUCCESS_DELETE_RESOURCE, ERROR_DELETE_RESOURCE,
    START_TOGGLE_FORM_EDIT_RESOURCES, SUCCESS_TOGGLE_FORM_EDIT_RESOURCES,
    START_TOGGLE_FORM_CREATE_RESOURCES, SUCCESS_TOGGLE_FORM_CREATE_RESOURCES,
    START_TOGGLE_FORM_VIEW_RESOURCES, SUCCESS_TOGGLE_FORM_VIEW_RESOURCES,
    START_FILTER_RESOURCES, SUCCESS_FILTER_RESOURCES, ERROR_FILTER_RESOURCES,
    START_ASSIGN_TAG_RESOURCE, SUCCESS_ASSIGN_TAG_RESOURCE, ERROR_ASSIGN_TAG_RESOURCE,
    START_DELETE_TAG_RESOURCE, SUCCESS_DELETE_TAG_RESOURCE, ERROR_DELETE_TAG_RESOURCE,
    START_ASSIGN_HIGHLIGHT_RESOURCE, SUCCESS_ASSIGN_HIGHLIGHT_RESOURCE, ERROR_ASSIGN_HIGHLIGHT_RESOURCE,
    START_GET_HOME_RESOURCES, SUCCESS_GET_HOME_RESOURCES, ERROR_GET_HOME_RESOURCES, 
    START_MASS_ASSIGNMENT_RESOURCES, SUCCESS_MASS_ASSIGNMENT_RESOURCES, ERROR_MASS_ASSIGNMENT_RESOURCES,
} from '../types';
import { apiCall } from '../config/axios';

function* getResources({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/resources/index', { filters: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_RESOURCES, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_RESOURCES, payload: error.message})
    }
}

function* showResource({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/resources/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_SHOW_RESOURCE, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_SHOW_RESOURCE, payload: error.message})
    }
}

function* viewResource({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/resources/view/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_VIEW_RESOURCE, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_VIEW_RESOURCE, payload: error.message})
    }
}

function* storeResource({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/resources/store', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_STORE_RESOURCE, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_STORE_RESOURCE, payload: error.message})
    }
}

function* updateResource({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/resources/update', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_UPDATE_RESOURCE, payload:response.data})
        if (window.location.pathname.includes('/biblioteca/'))
            window.location.reload()
    } catch (error){
        yield put ({ type: ERROR_UPDATE_RESOURCE, payload: error.message})
    }
}

function* deleteResource({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/resources/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_DELETE_RESOURCE, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_DELETE_RESOURCE, payload: error.message})
    }
}

function* toggleFormEditResources() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_EDIT_RESOURCES})
}

function* toggleFormCreateResources() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_CREATE_RESOURCES})
}

function* toggleFormViewResources() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_VIEW_RESOURCES})
}

function* filterResources({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/resources/filter', {filters: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_FILTER_RESOURCES, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_FILTER_RESOURCES, payload: error.message})
    }
}

function* assignTagResource({ payload }) {
    try{
        const response = yield call(apiCall, 'post', `/resources/assigntag`, {resource: payload})
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_ASSIGN_TAG_RESOURCE, payload})
    } catch (error){
        yield put ({ type: ERROR_ASSIGN_TAG_RESOURCE, payload: error.message})
    }
}

function* deleteTagResource({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/resources/deletetag/${payload.id}/${payload.tagId}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_DELETE_TAG_RESOURCE, payload})
    } catch (error){
        yield put ({ type: ERROR_DELETE_TAG_RESOURCE, payload: error.message})
    }
}

function* assignHighlight({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/resources/assignhighlight`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ASSIGN_HIGHLIGHT_RESOURCE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_ASSIGN_HIGHLIGHT_RESOURCE, payload: error.message })
    }
}

function* getHomeResources({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/resources/homeresources`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_HOME_RESOURCES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_HOME_RESOURCES, payload: error.message })
    }
}

function* putMassAssignment({ payload }) {
    try {
        const response = yield call(apiCall, 'put', `/resources/massassignment`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_MASS_ASSIGNMENT_RESOURCES, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_MASS_ASSIGNMENT_RESOURCES, payload: error.message })
    }
}

export default function* resources() {
    yield takeLatest(START_GET_RESOURCES, getResources);
    yield takeLatest(START_SHOW_RESOURCE, showResource);
    yield takeLatest(START_VIEW_RESOURCE, viewResource);
    yield takeLatest(START_STORE_RESOURCE, storeResource);
    yield takeLatest(START_UPDATE_RESOURCE, updateResource);
    yield takeLatest(START_DELETE_RESOURCE, deleteResource);
    yield takeLatest(START_TOGGLE_FORM_EDIT_RESOURCES, toggleFormEditResources);
    yield takeLatest(START_TOGGLE_FORM_CREATE_RESOURCES, toggleFormCreateResources);
    yield takeLatest(START_TOGGLE_FORM_VIEW_RESOURCES, toggleFormViewResources);
    yield takeLatest(START_FILTER_RESOURCES, filterResources);
    yield takeLatest(START_ASSIGN_TAG_RESOURCE, assignTagResource);
    yield takeLatest(START_DELETE_TAG_RESOURCE, deleteTagResource);
    yield takeLatest(START_ASSIGN_HIGHLIGHT_RESOURCE, assignHighlight);
    yield takeLatest(START_GET_HOME_RESOURCES, getHomeResources);
    yield takeLatest(START_MASS_ASSIGNMENT_RESOURCES, putMassAssignment);
}