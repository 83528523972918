import {
    START_GET_LESSONS,
    START_CREATE_LESSON,
    START_STORE_LESSONMULTIMEDIA,
    START_SHOW_LESSON,
    START_DELETE_LESSON,
    START_DELETE_LESSONMULTIMEDIA,
    START_TOGGLE_FORM_LESSON,
    START_SET_VIEW_LESSON
} from '../types';

export const getLessons = () => ({
    type: START_GET_LESSONS
})

export const createLesson = (payload) => ({
    type: START_CREATE_LESSON,
    payload
})

export const storeLessonMultimedia = payload => ({
    type: START_STORE_LESSONMULTIMEDIA,
    payload
})

export const showLesson = payload => ({
    type: START_SHOW_LESSON,
    payload
})

export const deleteLesson = payload => ({
    type: START_DELETE_LESSON,
    payload
})

export const deleteLessonMultimedia = payload => ({
    type: START_DELETE_LESSONMULTIMEDIA,
    payload
})

export const toggleFormLesson = () => ({
    type: START_TOGGLE_FORM_LESSON
})

export const setViewLesson = payload => ({
    type: START_SET_VIEW_LESSON,
    payload
})