import { useSelector } from "react-redux";

// Material UI
import { Grid, Switch, Box, Typography } from "@material-ui/core";

// Redux Actions
import { IRootState } from "../../redux/types";

// Translate
import { useTranslation } from "react-i18next";

//Styles
import UsersStyle from "../../styles/components/common/UsersStyle";
import CustomSwitchComponent from "../common/SwitchCustom";

function UserNotifications() {
  //Styles
  const classes = UsersStyle();
  // Translate
  const { t } = useTranslation(["global"]);
  //Redux Hooks
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const { userProfileSelected, error, reload } = useSelector(
    (state: IRootState) => state.user
  );

  return (
    <>
      {authUser !== null && userProfileSelected !== null && (
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={10}>
              <Typography>
                Avisame todas las actualizaciones de{" "}
                {process.env.REACT_APP_NAME}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <CustomSwitchComponent /> */}
            </Grid>
          </Grid>

          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={10}>
              <Typography>
                Deseo recibir correos electrónicos cuando existan cambios en{" "}
                {process.env.REACT_APP_NAME}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <CustomSwitchComponent /> */}
            </Grid>
          </Grid>

          <Typography variant="body2" color="textSecondary">
            {process.env.REACT_APP_NAME} | Website
          </Typography>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={10}>
              <Typography>Habilitar alertas de Ofertas</Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <CustomSwitchComponent /> */}
            </Grid>
          </Grid>

          <Typography variant="body2" color="textSecondary">
            {process.env.REACT_APP_NAME} | Website
          </Typography>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={10}>
              <Typography>Habilitar alertas de Academia</Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <CustomSwitchComponent /> */}
            </Grid>
          </Grid>

          <Typography variant="body2" color="textSecondary">
            {process.env.REACT_APP_NAME} | Admin
          </Typography>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={10}>
              <Typography>Avisame cuando creen un nuevo recurso</Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <CustomSwitchComponent /> */}
            </Grid>
          </Grid>

          <Typography variant="body2" color="textSecondary">
            {process.env.REACT_APP_NAME} | Admin
          </Typography>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={10}>
              <Typography>Avisame cuando editen un recurso</Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <CustomSwitchComponent /> */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default UserNotifications;
