// React
import { useEffect, useState, useRef, MouseEvent, KeyboardEvent } from 'react';

// Material UI
import { Popper, Paper, MenuList, MenuItem, Grow, ClickAwayListener, makeStyles, Button, Box } from '@material-ui/core';
import { AddRounded, CheckRounded, EditRounded, ExpandMore } from '@material-ui/icons/';

// Common components
import ButtonHeader from './ButtonHeader';

// Libraries
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

// Custom Style
import TypeFilterStyles from '../../../styles/components/common/header/TypeFilterStyles';

function TypeFilter(props: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Props
    const { id, type, categories, active, handleChangeFilter, handleAction, admin, filterCategoryId } = props;

    // Styles
    const classes = TypeFilterStyles();

    // Filter States
    const [openMenu, setOpenMenu] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(openMenu);
    useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = openMenu;
    }, [openMenu]);

    /* Functions */
    const handleItemSelected = (category_id: null | number) => {
        handleChangeFilter(id, category_id);
        setOpenMenu(false);
    }

    const handleToggle = () => {
        if (categories.length > 0)
            setOpenMenu((prevOpen) => !prevOpen);
        else
            if (admin === true)
                setOpenMenu((prevOpen) => !prevOpen);
            else
                handleItemSelected(null);
    };

    const handleClose = (event: MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenMenu(false);
    };

    function handleListKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMenu(false);
        }
    }


    return (
        <>
            <ButtonHeader
                ref={anchorRef}
                aria-controls={openMenu ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="primary"
                className={`${clsx({ 'active ': active })} ${classes.main} header-type`}
                endIcon={admin === true ? <ExpandMore color="inherit" /> : categories.length > 0 && <ExpandMore color="inherit" />}
                onClick={handleToggle}>
                {type}
            </ButtonHeader>
            {admin === true
                ?
                <Popper
                    open={openMenu}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    className={classes.dropdown}>

                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
                            <Paper elevation={0} square>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={() => handleItemSelected(null)} >

                                            <CheckRounded className={clsx(classes.svgSubMenu, filterCategoryId === null && active && 'active', 'admin')} />
                                            {t("all")}
                                        </MenuItem>
                                        {categories.map((category: any) => {
                                            const activeCategory = filterCategoryId !== null && category.id === filterCategoryId;

                                            return (
                                                <MenuItem key={category.id} className={clsx(classes.optionCategory, activeCategory && 'active', 'admin')} onClick={() => handleItemSelected(category.id)}>
                                                    <Box display='flex'>
                                                        <CheckRounded className={clsx(classes.svgSubMenu, activeCategory && 'active', 'admin')} />
                                                        {category.name}
                                                    </Box>
                                                    <Button
                                                        endIcon={<EditRounded color="inherit" />}
                                                        className={classes.buttonCategory}
                                                        onClick={() => handleAction('edit', category.id)}>
                                                    </Button>
                                                </MenuItem>
                                            )
                                        })}

                                        {/* Ejemplo MenuItem para agregar */}
                                        <MenuItem className={classes.optionCategory}>
                                            {t("add")}
                                            <Button
                                                endIcon={<AddRounded color="inherit" />}
                                                className={classes.buttonCategory}
                                                onClick={() => handleAction('add', id)}>
                                            </Button>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                :
                categories.length > 0 &&
                <>
                    <Popper
                        open={openMenu}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.dropdown}>

                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
                                <Paper elevation={0} square>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            <MenuItem className={clsx(classes.optionCategory, filterCategoryId === null && active && 'active')} onClick={() => handleItemSelected(null)}>
                                                {t("all")}
                                                <CheckRounded className={clsx(classes.svgSubMenu, filterCategoryId === null && active && 'active')} />
                                            </MenuItem>
                                            {categories.map((category: any, index: number) => {
                                                const activeCategory = filterCategoryId !== null && category.id === filterCategoryId;

                                                return (
                                                    <MenuItem key={category.id} className={clsx(classes.optionCategory, activeCategory && 'active')} onClick={() => handleItemSelected(category.id)}>
                                                        {category.name}
                                                        <CheckRounded className={clsx(classes.svgSubMenu, activeCategory && 'active')} />
                                                    </MenuItem>
                                                )
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            }
        </>
    );
}

export default TypeFilter;
