import { START_GET_COUNTRIES, START_GET_FILTERED_COUNTRIES, START_GET_LOCATIONS, START_STORE_LOCATION } from '../types';
    
export const getCountries = () => ({
    type: START_GET_COUNTRIES
})

export const getFilteredCountries = () => ({
    type: START_GET_FILTERED_COUNTRIES
})

export const getLocations = () => ({
    type: START_GET_LOCATIONS
})

export const storeLocation = payload => ({
    type: START_STORE_LOCATION,
    payload
})