// Material UI
import { Box, Grid, IconButton } from '@material-ui/core';
import { BackupRounded, CloseOutlined } from '@material-ui/icons';

// Libraries
import { useTranslation } from "react-i18next";
import Dropzone, { ILayoutProps, IPreviewProps } from 'react-dropzone-uploader'

// Styles
import 'react-dropzone-uploader/dist/styles.css'


const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            {files.length > 0 && submitButton}
        </div>
    )
}

const Preview = ({ meta }: IPreviewProps) => {
    const { name, percent, status, previewUrl } = meta;
    return (
        <Grid item xs={6}>
            <div className="preview-box">
                <img src={previewUrl} alt='Preview uploaded' /> <span className="name">{name}</span> - <span className="status">{status}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
            </div>
        </Grid>
    )
}

export function RemoveContent({ files, onHandleClick }: { files: string[], onHandleClick: (index: number) => void }) {
    return (
        <Grid container spacing={2} justify='center' alignContent='center'>
            {files.map((file, index) => (
                <Grid key={file} item>
                    <div style={{ display: 'flex' }}>
                        <img style={{ objectFit: 'scale-down' }} src={file} alt='Preview uploaded' width={'80rem'} height={'80rem'} />
                        <IconButton style={{ backgroundColor: 'transparent' }} aria-label='Remove File' size='small' onClick={() => onHandleClick(index)}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </Grid>
            ))}
        </Grid>
    )
}

function DropzoneForm({ name, handleInputChange, dir = 'resources' }: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Handle functions
    const getUploadParams = ({ meta }: any) => {
        return { url: `${process.env.REACT_APP_API_URL}/${dir}/upload/${meta.name}` }
    }

    const handleChangeStatus = ({ meta, file }: any, status: string) => {
        if (status === 'done' || status === 'headers_received')
            handleInputChange(name, meta.name)
        else
            handleInputChange(name, null)
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={({ meta, file }, status) => handleChangeStatus({ meta, file }, status)}
            LayoutComponent={Layout}
            maxFiles={1}
            multiple={false}
            classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
            inputContent={
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center">
                    <BackupRounded />
                    {t("dropzone-text")}
                </Box>
            }
            PreviewComponent={Preview}
        />
    )
}

export default DropzoneForm;