// React
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from "react-router-dom";

// Material UI
import {
    FormControl, Grid, Button, Box, Checkbox, FormControlLabel, Input, InputAdornment, IconButton, Typography,
    List, ListItem, ListItemText, Divider
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';

// Commons components
import CustomSnackBar from '../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../components/common/ScreenLoading';

// Assets
import logo from '../../assets/Images/logos/logodark.svg';
import icon from '../../assets/Images/icon.png';

// Redux Actions
import { login } from '../../redux/actions/auth'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import ResourceStyle from '../../styles/pages/LoginRegisterStyle';
import HiddenDesktop from '../../components/common/HiddenDesktop';
import HiddenMobile from '../../components/common/HiddenMobile';

const Login = ({ authFrom = 'root' }: any) => {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = ResourceStyle();

    // Redux Hooks
    const dispatch = useDispatch();
    const { isAuth, user: authUser, loading, msg, error } = useSelector((state: IRootState) => state.auth);

    const history = useHistory();
    // User State
    const [user, setUser] = useState({
        email: '',
        password: '',
        rememberme: false,
        showPassword: false
    })

    // Ready Page
    useEffect(() => {
        if (localStorage.getItem('isAuth') && authFrom === 'root') {
            if (isAuth && authUser !== null && authUser.verification === 1) {
                history.push('/inicio');
            } else {
                if (localStorage.getItem('lastSeminarLessonId') != null && isAuth && authUser !== null && authUser.verification === 0) {
                    const lastSeminarLessonId = localStorage.getItem('lastSeminarLessonId');
                    localStorage.removeItem('lastSeminarLessonId');
                    history.push('/seminarios/' + lastSeminarLessonId);
                } else {
                    history.push('/inicio');
                }
            }
        }
    }, [])

    useEffect(() => {
        if (isAuth && authUser !== null && authFrom === 'root') {
            if (authUser.verification === 1) {
                history.push('/inicio');
            } else {
                if (localStorage.getItem('lastSeminarLessonId') != null && isAuth && authUser !== null && authUser.verification === 0) {
                    const lastSeminarLessonId = localStorage.getItem('lastSeminarLessonId');
                    localStorage.removeItem('lastSeminarLessonId');
                    history.push('/seminarios/' + lastSeminarLessonId);
                } else {
                    history.push('/inicio');
                }
            }
        }
    }, [isAuth])

    let isLive = authFrom === 'seminar' ? 'seminar' : '';
    /* Functions */
    const handleSubmit = () => {
        const { email, password, rememberme } = user;

        dispatch(login({ email, password, rememberme, authFrom }));
    }

    const handleClickShowPassword = () => {
        setUser({ ...user, showPassword: !user.showPassword });
    };

    const handleChangeInput = (nameInput: string) => (event: any) => {
        setUser({ ...user, [nameInput]: event.target.value })
    }

    const handleCheckChange = (event: any) => {
        setUser({ ...user, rememberme: event.target.checked });
    };

    return (
        <Grid container className={clsx(classes.container, isLive)}>

            <Grid className={clsx(classes.form, isLive)}>
                <Grid container spacing={isMobile ? 2 : 3} className={classes.formBody}>
                    {authFrom === 'root' &&
                        <Grid item xs={12} style={{ marginBottom: 40 }}>
                            <img src={logo} height="36" alt="logo" />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                            <Box mb={0.5}>
                                <Typography>{t("email")}</Typography>
                            </Box>
                            <Input
                                inputProps={{ 'data-testid': 'txt-email' }}
                                id="txt-email"
                                placeholder={t("email")}
                                onChange={handleChangeInput('email')}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                            <Box display="flex" justifyContent="space-between" mb={0.5}>
                                <Typography>{t("login.password")}</Typography>
                                <Link className={classes.link} to={"/recordar-contrasena"}>{t("login.forgot-your-password")}</Link>
                            </Box>
                            <Input
                                inputProps={{ 'data-testid': 'txt-password' }}
                                placeholder={t("login.password")}
                                id="txt-password"
                                required
                                type={user.showPassword ? 'text' : 'password'}
                                onChange={handleChangeInput('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {user.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={<Checkbox id="chck-remember-me" checked={user.rememberme} onChange={handleCheckChange} name="jason" />}
                                label={t("login.remember-me")}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            data-testid="btn-login"
                            id="btn-login"
                            variant="contained"
                            fullWidth
                            onClick={() => handleSubmit()}>
                            {t("login.login")}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <HiddenDesktop>
                            <Box display='flex' alignItems='center' width='100%' gridGap='16px' justifyContent='center' mb={2} overflow='hidden'>
                                <Divider style={{ width: '100%' }} />
                                <Typography>o</Typography>
                                <Divider style={{ width: '100%' }} />
                            </Box>
                            <Button
                                id="btn-login"
                                variant="outlined"
                                fullWidth
                                className={classes.registerBtn}
                                href="/registro">
                                {t("footer.signup")}
                            </Button>
                        </HiddenDesktop>
                    </Grid>

                    {process.env.REACT_APP_NAME === 'PLEI' &&
                        <>
                            <Grid item xs={12}>
                                <Box className={classes.warning} mt={6}>
                                    <Typography align='center' >¿TIENES CUENTA EN PLEI?<br /> RESTAURA TU CLAVE ANTES DE ENTRAR</Typography>
                                    <List component="nav" className={classes.list}>
                                        <ListItem>
                                            <ListItemText>
                                                1. Haz clic en <Button size="small" color="primary" href="/recordar-contrasena">¿Has olvidado tu contraseña?</Button>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem><ListItemText primary="2. Mira tu correo y reestablece la contraseña" /></ListItem>
                                        <ListItem><ListItemText primary="3. Accede a PLEI con tu nueva contraseña" /></ListItem>
                                    </List>
                                    <HiddenMobile>
                                        <img src={icon} alt="logo" />
                                    </HiddenMobile>
                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <Typography style={{ color: 'grey', marginTop: '18px', fontSize: '13px' }}>*IMPORTANTE: todos los usuarios que ya tenían anteriormente una cuenta en plei, deberán restablecer su contraseña para poder acceder a esta nueva plataforma</Typography>
                            </Grid>
                        </>
                    }

                    <Grid item xs={12}>
                        <HiddenDesktop>
                            <Button
                                id="btn-login"
                                variant="outlined"
                                fullWidth
                                href="https://plei.drv.es/preguntas-frecuentes/"
                                className={classes.registerBtn}>
                                {t("help")}
                            </Button>
                        </HiddenDesktop>
                    </Grid>

                </Grid>

                <CustomSnackBar errors={[error]} msgs={[msg]} />
                <ScreenLoading loadings={[loading]} />
            </Grid>
        </Grid>
    );
}

export default Login;