// React
import { useState, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

// Material UI
import {
  Container,
  Button,
  Link,
  Checkbox,
  FormHelperText,
  Typography,
  Box,
} from "@material-ui/core";

// Commons components
import CustomSnackBar from "../../components/common/admin/CustomSnackBar";
import ScreenLoading from "../../components/common/ScreenLoading";

// Redux Actions
import { getTerms, acceptTerms } from "../../redux/actions/terms";
import {
  resetFormValidator,
  formValidator,
} from "../../redux/actions/validator";
import { IRootState } from "../../redux/types";

// Libraries
import { useTranslation } from "react-i18next";

// Types
interface ITerms {
  term1: boolean;
  term2: boolean;
  term3: boolean;
  term4: boolean;
}

type FormElement = ChangeEvent<HTMLInputElement>;

function Terms({ history }: RouteComponentProps) {
  // Translate
  const { t } = useTranslation(["terms"]);

  //Redux Hooks
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const { accepted, error, msg, loading } = useSelector((state: IRootState) => state.term);
  const { isValidForm, errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

  // Terms State
  const [termState, setTermState] = useState<ITerms>({
    term1: false,
    term2: false,
    term3: false,
    term4: false,
  });

  // Set data User
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTerms());
  }, []);

  // Send Form
  useEffect(() => {
    if (isValidForm) {
      dispatch(resetFormValidator());
      dispatch(
        acceptTerms({
          term2: termState.term2,
        })
      );
    }
  }, [isValidForm]);

  // Send Form
  useEffect(() => {
    if (accepted && authUser !== null) {
      if ([1, 2, 5].includes(authUser.role_id)) history.push("/escritorio");
      else history.push("/inicio");
    }
  }, [accepted, authUser]);

  // Input State Form
  const handleChange = (inputName: string) => (event: FormElement) => {
    setTermState({ ...termState, [inputName]: event.target.checked });
  };

  // Form Validator
  const handleSubmit = () => {
    dispatch(
      formValidator({
        term1: [termState.term1, "boolean"],
        term3: [termState.term3, "boolean"],
        term4: [termState.term4, "boolean"],
      })
    );
  };

  return (
    <Container maxWidth="md">
      <Typography>
        {t("p1", { APP_NAME: process.env.REACT_APP_NAME })}
        <br />
        <br />
        <strong>{t("p2")}</strong>
        <br />
        <br />
        {t("p3", { APP_NAME: process.env.REACT_APP_NAME })}
        <br />
        <br />
        {t("p4", { APP_NAME: process.env.REACT_APP_NAME })}{" "}
        <Link
          href="https://drv.es/terminos-de-uso-plei/"
          color="primary"
          target="_blank"
        >
          {t("here")}
        </Link>
        .
        <br />
        <br />
      </Typography>

      {!accepted ? (
        <>
          <Box mt={2} mb={4} style={{ display: "flex" }}>
            <Checkbox id="term1" onChange={handleChange("term1")} />
            <Typography>
              {t("term1", { APP_NAME: process.env.REACT_APP_NAME, APP_COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME })}
            </Typography>
            <FormHelperText error={errorForm.term1}>
              {msgForm.term1}
            </FormHelperText>
          </Box>

          <Box mb={4} style={{ display: "flex" }}>
            <Checkbox id="term2" onChange={handleChange("term2")} />
            <Typography>
              {t("term2", { APP_NAME: process.env.REACT_APP_NAME })}
            </Typography>
          </Box>

          <Box mt={2} mb={4} style={{ display: "flex" }}>
            <Checkbox id="term3" onChange={handleChange("term3")} />
            <Typography>{t("term3", { APP_COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME })}</Typography>
            <FormHelperText error={errorForm.term3}>
              {msgForm.term3}
            </FormHelperText>
          </Box>

          <Box mt={2} mb={4} style={{ display: "flex" }}>
            <Checkbox id="term4" onChange={handleChange("term4")} />
            <Typography>
              {t("term4", { APP_NAME: process.env.REACT_APP_NAME })}
            </Typography>
            <FormHelperText error={errorForm.term4}>
              {msgForm.term4}
            </FormHelperText>
          </Box>

          <Box mt={6}>
            <Button
              id="btn-terms-continue"
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              onClick={() => handleSubmit()}
            >
              {t("continue")}
            </Button>
          </Box>
        </>
      ) : (
        false
      )}

      <CustomSnackBar errors={[error]} msgs={[msg]} />
      <ScreenLoading loadings={[loading]} />
    </Container>
  );
}

export default Terms;
