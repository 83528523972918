// React
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";

// Custom components
import CustomSnackBar from "../common/admin/CustomSnackBar";
import { SaveButton, CancelButton } from "../common/forms";

// Redux Actions
import { sendSliderEmail } from "../../redux/actions/sliders";
import { formValidator } from "../../redux/actions/validator";
import { IRootState } from "../../redux/types";

// Libraries
import { useTranslation } from "react-i18next";
//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";
//Styles
import FormsStyles from "../../styles/components/common/forms/FormsStyles";
import { CloseRounded } from "@material-ui/icons";

type SliderMoreInfoProps = {
  open: boolean;
  setOpen: any;
  slider: any;
};

function SliderMoreInfo({ slider, open, setOpen }: SliderMoreInfoProps) {
  // Translate
  const { t } = useTranslation(["global"]);
  //Custom Hook
  const isMobile = useScreenWidth(700);
  //Styles
  const formClasses = FormsStyles();

  //Redux Hooks
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const { error, msg } = useSelector((state: IRootState) => state.slider);
  const { isValidForm, errorForm, msgForm } = useSelector(
    (state: IRootState) => state.validator
  );
  // Info State
  const [infoRequirement, setInfoRequirement] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (authUser != null) {
      setInfoRequirement({
        name: authUser.name,
        email: authUser.email,
        phone: authUser.phone,
      });
    }
  }, [authUser]);

  // Send Form
  useEffect(() => {
    if (isValidForm && errorForm.email === false && slider.id !== null) {
      dispatch(
        sendSliderEmail({
          slider_id: slider.id,
          name: infoRequirement.name,
          email: infoRequirement.email,
          phone: infoRequirement.phone,
        })
      );
      setOpen(false);
    }
  }, [isValidForm]);

  const handleSubmit = () => {
    dispatch(
      formValidator({
        name: [infoRequirement.name, "required"],
        email: [infoRequirement.email, "required|email"],
        phone: [infoRequirement.phone, "required|max:45|min:6|numeric"],
      })
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        fullWidth={true}
        fullScreen={isMobile && true}
        maxWidth={!isMobile && "sm"}
        className={formClasses.containerForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          <Grid container justify="space-between">
            <Typography variant="h6">
              {"¿ Quieres recibir más información?"}
            </Typography>
            <Button onClick={() => setOpen(!open)} color='primary' variant="text"><CloseRounded /></Button>
          </Grid>
        </DialogTitle>
        <DialogContent className={formClasses.bodyForm}>
          <DialogContentText id="alert-dialog-description">
            Dejános tus datos y te llamamos para asesorarte sin compromiso
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t("name")}
                fullWidth
                required
                margin="normal"
                value={infoRequirement.name}
                error={errorForm.name}
                helperText={msgForm.name}
                onChange={(event) =>
                  setInfoRequirement({
                    ...infoRequirement,
                    name: event.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("email")}
                fullWidth
                required
                margin="normal"
                value={infoRequirement.email}
                error={errorForm.email}
                helperText={msgForm.email}
                onChange={(event) =>
                  setInfoRequirement({
                    ...infoRequirement,
                    email: event.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("phone")}
                fullWidth
                required
                margin="normal"
                value={infoRequirement.phone}
                error={errorForm.phone}
                helperText={msgForm.phone}
                onChange={(event) =>
                  setInfoRequirement({
                    ...infoRequirement,
                    phone: event.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton variant="contained" onClick={() => setOpen(!open)}>
            {t("cancel")}
          </CancelButton>
          <SaveButton
            onClick={() => handleSubmit()}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t("send")}
          </SaveButton>
        </DialogActions>
      </Dialog>

      <CustomSnackBar errors={[error]} msgs={[msg]} />
    </>
  );
}

export default SliderMoreInfo;
