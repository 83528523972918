// React
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import LanguageIcon from '@material-ui/icons/Language';
import { TextField, Grid, Radio } from '@material-ui/core';

// Redux Actions
import { IRootState } from '../../../redux/types';
import { getFilteredCountries } from '../../../redux/actions/countries';
import { setGlobalCountries } from '../../../redux/actions/global';

// Custom Styles
import useStyles from '../../../styles/components/common/admin/CountryFilterStyles';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Global constants
const optionAll = [{ id: 0, code: 'AL', name: 'Todos' }];

// Types
interface ICountry {
    id: number,
    code: string,
    name: string
}

function CountryFilter() {
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = useStyles();

    // Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { countries, loading } = useSelector((state: IRootState) => state.country);
    const { globalCountriesId } = useSelector((state: IRootState) => state.global);
    const [allCheck, setAllCheck] = useState(true);

    /* Functions */
    const handleMultiAutoCompleteChange = (value: ICountry[]) => {
        let optionsMulti: number[] = [];
        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                if (value[i].id === 0) {
                    if (globalCountriesId.length === 0) {
                        setAllCheck(true);
                        optionsMulti = authUser.countries;
                    } else {
                        setAllCheck(false);
                        optionsMulti = [];
                    }

                    break;
                }

                optionsMulti.push(value[i].id);
            }
        }

        dispatch(setGlobalCountries(optionsMulti));
    }

    // Set all options
    useEffect(() => {
        if (!countries.length)
            dispatch(getFilteredCountries());

        if (authUser !== null && localStorage.getItem('globalCountriesId') === null)
            dispatch(setGlobalCountries(authUser.countries));
    }, [authUser]);

    return (
        <Grid container spacing={1} justify="space-between" alignItems="center" className={classes.container}>
            <Grid item>
                <LanguageIcon className={classes.icon} color="primary" />
            </Grid>

            {authUser !== null && countries.length > 0 &&
                <Grid item>
                    <Autocomplete
                        id="multiple-limit-tags"
                        options={[...optionAll, ...countries]}
                        value={countries.filter((country: ICountry) => globalCountriesId.includes(country.id))}
                        limitTags={1}
                        disableCloseOnSelect
                        disableClearable
                        multiple
                        loading={loading}
                        getOptionLabel={(country: ICountry) => country.id !== undefined ? country.name : ''}
                        renderTags={(countries: ICountry[]) => (isMobile ? '+' + countries.length : countries.length === 1 ? countries[0].name : countries[0].name + ', +' + countries.length)}
                        renderOption={(country: ICountry, { selected }: any) => (
                            <>
                                <Radio
                                    key={country.id}
                                    checked={country.id === 0 ? allCheck : selected}
                                />
                                {country.name}
                            </>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                        onChange={(event, newValue) => handleMultiAutoCompleteChange(newValue)}
                        className={classes.input + ' admin'}
                        classes={{ popper: classes.popperResp }}
                    />
                </Grid>
            }
        </Grid>

    );
}

export default CountryFilter;
