import React, { useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Grid, Typography, Box, FormControlLabel, Checkbox,
    FormControl, Button
} from '@material-ui/core';
import { ExpandMore, ChevronRight, CreateRounded, RadioButtonUncheckedOutlined, CheckCircleOutlineRounded, EmailRounded, Phone, TranslateRounded, VpnKey, ConfirmationNumber, AssignmentIndRounded, AssignmentTurnedIn, AssignmentTurnedInRounded, RoomRounded, ExploreRounded, HomeWorkRounded, MyLocationRounded, MarkunreadMailboxRounded, CloudUploadRounded, TodayRounded, } from '@material-ui/icons/';
import { TreeView, TreeItem } from '@material-ui/lab';

// Custom components
import SubtitleGrey from '../../components/common/typography/SubtitleGrey';
import UserModal from './UserModal';
import ModalInfo from '../common/admin/ModalInfo'
import UserChangePassword, { StateChangePasswordModal } from './UserChangePassword'

// Redux Actions
import { enableDisableMenu, verifyUser } from '../../redux/actions/users';
import { IRootState } from '../../redux/types';

// Translate
import { useTranslation } from "react-i18next";
import HiddenMobile from '../common/HiddenMobile';
import HiddenDesktop from '../common/HiddenDesktop';
import UserItemMobile from '../common/UserItemMobile';

type UserInfoProps = {
    user_id: number | string | null,
}

function UserInfo({ user_id }: UserInfoProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userProfileSelected } = useSelector((state: IRootState) => state.user);

    const [openChangePassword, setOpenChangePassword] = StateChangePasswordModal();

    // Table menu function
    const [verification, setVerification] = React.useState(userProfileSelected.verification);

    // Handle Check Verification tree
    const handleCheckChange = (event: any) => {
        const verificationId: number = parseInt(event.target.value);
        setVerification(verificationId);
        dispatch(verifyUser({ user_id, verificationId }));
    };

    useEffect(() => {
        if (userProfileSelected !== null)
            setVerification(userProfileSelected.verification);
    }, [userProfileSelected]);

    /* Functions */
    const handleClickAccessMenu = (menu_id: number) => (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(enableDisableMenu({
            user_id: userProfileSelected.id,
            menu_id,
            status: event.target.checked
        }));
    };

    return (

        <>
            {authUser !== null && userProfileSelected !== null &&
                <>
                    <UserModal role_id={userProfileSelected.role_id} showBotton={false} />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="flex-start" spacing={2}>

                                <Grid item xs={12} md={6} lg={4}>
                                    <HiddenMobile>
                                        <Box pb={3}>
                                            <Typography color="textSecondary" variant="subtitle1">{t("personal-info").toUpperCase()}</Typography>
                                        </Box>

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("email")}</SubtitleGrey>
                                            <Typography variant="body1">{userProfileSelected.email}</Typography>
                                        </Box>

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("phone")}</SubtitleGrey>
                                            <Typography variant="body1" >{userProfileSelected.phone}</Typography>
                                        </Box>

                                        {[4, 8, 9].includes(userProfileSelected.role_id) &&
                                            <Box pb={1}>
                                                <SubtitleGrey variant="subtitle1">{t("admin.people.code")}</SubtitleGrey>
                                                <Typography variant="body1">{userProfileSelected.commercial_code}</Typography>
                                            </Box>
                                        }

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("language")}</SubtitleGrey>
                                            <Typography variant="body1" >{userProfileSelected.languages.map((language: any) => { return language.name }).join(' - ')}</Typography>
                                        </Box>

                                        {([1, 2, 3, 5, 8, 9].includes(authUser.role_id) && authUser.role.code < userProfileSelected.roleCode) &&
                                            <Button fullWidth={true} onClick={() => { setOpenChangePassword(!openChangePassword) }} variant="contained" color="primary" startIcon={<CreateRounded />}>
                                                {t("login.change-password")}
                                            </Button>
                                        }
                                    </HiddenMobile>
                                    <HiddenDesktop>
                                        <Typography variant="body1" style={{ fontWeight: 700 }}>{t("personal-info")}</Typography>

                                        <UserItemMobile label={t("email")} content={userProfileSelected.email} icon={<EmailRounded />} />

                                        <UserItemMobile label={t("phone")} content={userProfileSelected.phone} icon={<Phone />} />

                                        {[4, 8, 9].includes(userProfileSelected.role_id) &&
                                            <UserItemMobile label={t("admin.people.code")} content={userProfileSelected.commercial_code} icon={<AssignmentTurnedInRounded />} />
                                        }

                                        <UserItemMobile label={t("language")} content={userProfileSelected.languages.map((language: any) => { return language.name }).join(', ')} icon={<TranslateRounded />} />

                                        {([1, 2, 3, 5, 8, 9].includes(authUser.role_id) && authUser.role.code < userProfileSelected.roleCode) &&
                                            <Box
                                                typeof='button'
                                                display="flex"
                                                alignItems="center"
                                                style={{ color: "#3483FA", fontWeight: 500 }}
                                                p={'14px'} gridGap={14}
                                                onClick={() => { setOpenChangePassword(!openChangePassword) }}
                                            >
                                                <VpnKey />
                                                <Typography variant="body2">{t("login.change-password")}</Typography>
                                            </Box>
                                        }
                                    </HiddenDesktop>


                                </Grid>


                                <Grid item xs={12} md={6} lg={4}>
                                    <HiddenMobile>
                                        <Box pb={3}>
                                            <Typography color="textSecondary" variant="subtitle1">
                                                {userProfileSelected.role_id === 6 ?
                                                    <>{t("center-data").toUpperCase()}</>
                                                    :
                                                    <>{t("address-data").toUpperCase()}</>
                                                }
                                            </Typography>
                                        </Box>

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("country")}</SubtitleGrey>
                                            <Typography variant="body1" >{userProfileSelected.countryName}</Typography>
                                        </Box>

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("province")}</SubtitleGrey>
                                            <Typography variant="body1" >{userProfileSelected.provinceName}</Typography>
                                        </Box>


                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("town")}</SubtitleGrey>
                                            <Typography variant="body1" >{userProfileSelected.townName}</Typography>
                                        </Box>

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("address")}</SubtitleGrey>
                                            <Typography variant="body1" >{userProfileSelected.address}</Typography>
                                        </Box>

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("postal-code")}</SubtitleGrey>
                                            <Typography variant="body1" >{userProfileSelected.postalcode}</Typography>
                                        </Box>
                                    </HiddenMobile>

                                    <HiddenDesktop>
                                        <Typography variant="body1" style={{ fontWeight: 700 }}>
                                            {userProfileSelected.role_id === 6 ?
                                                <>{t("center-data")}</>
                                                :
                                                <>{t("address-data")}</>
                                            }
                                        </Typography>

                                        <UserItemMobile label={t("country")} content={userProfileSelected.countryName} icon={<ExploreRounded />} />

                                        <UserItemMobile label={t("province")} content={userProfileSelected.provinceName} icon={<RoomRounded />} />

                                        <UserItemMobile label={t("town")} content={userProfileSelected.townName} icon={<HomeWorkRounded />} />

                                        <UserItemMobile label={t("address")} content={userProfileSelected.address} icon={<MyLocationRounded />} />

                                        <UserItemMobile label={t("postal-code")} content={userProfileSelected.postalcode} icon={<MarkunreadMailboxRounded />} />

                                    </HiddenDesktop>

                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>

                                    <HiddenMobile>
                                        <Box pb={3}>
                                            <Typography color="textSecondary" variant="subtitle1">{t("admin.people.accesses-and-records").toUpperCase()}</Typography>
                                        </Box>
                                    </HiddenMobile>
                                    <HiddenDesktop>
                                        <Box pb={2}>
                                            <Typography variant="body1" style={{ fontWeight: 700 }}>{t("admin.people.accesses-and-records")}</Typography>
                                        </Box>
                                    </HiddenDesktop>

                                    {/*
                                    <Box pb={1}>
                                        <SubtitleGrey variant="subtitle1">{t("brands")}</SubtitleGrey>
                                        <Typography variant="body1" >{userProfileSelected.brands.map((brand: any) => { return brand.name }).join(' - ')}</Typography>
                                    </Box>
                                    */}



                                    {/*
                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("referenced-by")}</SubtitleGrey>
                                            <Typography variant="body1" >Michael Rusbet</Typography>
                                        </Box>
                                    */}
                                    {[1, 2, 3].includes(authUser.role_id) &&
                                        <Box pb={1}>
                                            <FormControl fullWidth style={{ marginBottom: 0 }}>
                                                <TreeView
                                                    defaultCollapseIcon={<ExpandMore />}
                                                    defaultExpandIcon={<ChevronRight />}
                                                    multiSelect
                                                >
                                                    <TreeItem nodeId="1" label={<SubtitleGrey variant="body1" style={{ height: 40, display: 'flex', alignItems: 'center' }}>{t('restrict-access')}</SubtitleGrey>}>
                                                        {(authUser.menus).map((menu: any) => (
                                                            <FormControlLabel
                                                                key={menu.id}
                                                                control={<Checkbox
                                                                    size="small"
                                                                    color="default"
                                                                    icon={<RadioButtonUncheckedOutlined fontSize="small" />}
                                                                    checkedIcon={<CheckCircleOutlineRounded fontSize="small" />}
                                                                    onChange={handleClickAccessMenu(menu.id)}
                                                                    checked={userProfileSelected.menusIds.includes(menu.id)}
                                                                    name={`${menu.name}`} />}
                                                                label={t(`${menu.code}`)}
                                                                style={{ width: '100%' }}
                                                            />
                                                        ))}
                                                    </TreeItem>
                                                </TreeView>
                                            </FormControl>
                                        </Box>
                                    }

                                    {([1, 2].includes(authUser.role_id) || authUser.can_verify === 1) &&
                                        <Box pb={1}>
                                            <FormControl fullWidth style={{ marginBottom: 0 }}>
                                                <TreeView
                                                    defaultCollapseIcon={<ExpandMore />}
                                                    defaultExpandIcon={<ChevronRight />}
                                                    multiSelect
                                                >
                                                    <TreeItem nodeId="1" label={<SubtitleGrey variant="body1" style={{ height: 40, display: 'flex', alignItems: 'center' }}>{t("admin.people.verification")}</SubtitleGrey>}>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                size="small"
                                                                color="default"
                                                                icon={<RadioButtonUncheckedOutlined fontSize="small" />}
                                                                checkedIcon={<CheckCircleOutlineRounded fontSize="small" />}
                                                                checked={verification === 1}
                                                                onChange={handleCheckChange} value="1" />}
                                                            label={t("admin.people.verified")} style={{ width: '100%' }}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                size="small"
                                                                color="default"
                                                                icon={<RadioButtonUncheckedOutlined fontSize="small" />}
                                                                checkedIcon={<CheckCircleOutlineRounded fontSize="small" />}
                                                                checked={verification === 0}
                                                                onChange={handleCheckChange} value="0" />}
                                                            label={t("admin.people.pending")} style={{ width: '100%' }}
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                size="small"
                                                                color="default"
                                                                icon={<RadioButtonUncheckedOutlined fontSize="small" />}
                                                                checkedIcon={<CheckCircleOutlineRounded fontSize="small" />}
                                                                checked={verification === 3}
                                                                onChange={handleCheckChange} value="3" />}
                                                            label={t("deny")} style={{ width: '100%' }}
                                                        />
                                                    </TreeItem>
                                                </TreeView>
                                            </FormControl>
                                        </Box>
                                    }

                                    <Box pb={1}>
                                        <ModalInfo title={t("admin.people.manage-countries")} options={userProfileSelected.countries} />
                                    </Box>
                                    <HiddenMobile>
                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("admin.people.created-date")}</SubtitleGrey>
                                            <Typography variant="body1">{userProfileSelected.created_date}</Typography>
                                        </Box>

                                        <Box pb={1}>
                                            <SubtitleGrey variant="subtitle1">{t("admin.people.rec-from")}</SubtitleGrey>
                                            <Typography variant="body1">{userProfileSelected.rec_from === null ? 'unknown' : userProfileSelected.rec_from}</Typography>
                                        </Box>
                                    </HiddenMobile>
                                    <HiddenDesktop>

                                        <UserItemMobile label={t("admin.people.created-date")} content={userProfileSelected.created_date} icon={<TodayRounded />} />

                                        <UserItemMobile label={t("admin.people.rec-from")} content={userProfileSelected.rec_from === null ? 'unknown' : userProfileSelected.rec_from} icon={<CloudUploadRounded />} />

                                    </HiddenDesktop>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

            <UserChangePassword user_id={userProfileSelected.id} open={openChangePassword} handleChangeOpen={setOpenChangePassword} />

        </>
    );
}

export default UserInfo;