import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    //Layout
    // APPBAR
    appBar: {
        height: '50px',
        borderBottom: "none",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
        background: theme.palette.common.white,
        '& .MuiToolbar-regular': {
            minHeight: '50px',
            alignItems: 'center'
        },
        '& img': {
            height: 'inherit',
            width: 'auto',
            [theme.breakpoints.up('sm')]: {
                height: 24,
            },
        },
    },

    appLogo: {
        height: 24,
        [theme.breakpoints.down('sm')]: {
            height: 20,
        },
    },

    formLenguage: {
        color: theme.palette.grey[400],
        borderBottom: "none",
        flexDirection: "row",
        alignItems: "center",
        margin: "0px",
        width: "50px",
        cursor: "pointer",
        "&::before": {
            borderBottom: "none",
        },
        "& :hover": {
            color: theme.palette.grey[200],
        },
    },
    inputLenguage: {
        cursor: "pointer",
        height: "auto",
        margin: "0px",
        top: "-1px",
        right: "20px",
        paddingLeft: theme.spacing(3),
        color: theme.palette.grey[400],
        "& svg": {
            display: "none",
        },
        "&::before": {
            borderBottom: "none !important",
        },
    },


    //Hero
    hero: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },


    //Cards
    containerCards: {
        marginTop: '-153px',
    },
    cardContainer: {
        maxWidth: 294,
        textAlign: 'center',
        borderRadius: 16,
        boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.1)',
    },
    cardHover: {
        backgroundColor: 'transparent',
    },
    focusCard: {
        padding: 32,
    },
    cardIcon: {
        height: 87,
        backgroundSize: 'inherit',
    },
    textCard: {
        marginTop: 16,
        gap: 16,
        display: 'grid',
        padding: 0,
        '&:hover h5': {
            color: theme.palette.primary.main,
        }
    },
    //Options
    buttonOption: {
        height: 110,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 155
        },
        "&:hover": {
            border: 'transparent',
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
        }
    },
    //Contact
    buttonContact: {
        marginTop: 32,
        marginBottom: 120,
        height: 41,
        width: 124,
    },

    // Forms
    form: {
        border: 'none',
        boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
        padding: '9px 14px',
        background: '#FAFAFA',
        borderRadius: '8px',
        fontSize: '1rem',
        maxWidth: '100%',
        maxHeight: '42px',
        color: "#73808C",
    },
    formText: {
        border: 'none',
        boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
        padding: '9px 14px',
        background: '#FAFAFA',
        borderRadius: '8px',
        fontSize: '1rem',
        maxWidth: '100%',
        color: "#73808C",
    },

    //Section
    sectionTitle: {
        color: theme.palette.grey[600],
        marginBottom: 12,
    },
    menuContainer: {
        [theme.breakpoints.between('xs', 'md')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            gap: 88,
        },
    },

    //AskDetail
    detailContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    textContent:{
        paddingRight: 32,
        
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 53,
            width: 'calc( 100% - 352px)'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
    navContent:{
        [theme.breakpoints.up('sm')]: {
            maxWidth: '260px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 'max-content',
        },
    },
    selectedOption:{
        color: theme.palette.primary.main,
        '&:hover':{
            backgroundColor: `${theme.palette.common.white} !important` ,
        }
    },
    disabled:{
        opacity: 'inherit !important',
    },
}));