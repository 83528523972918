// React
import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Redux Actions
import { IRootState } from '../../../redux/types'

// Libraries
import { useTranslation } from 'react-i18next'

interface ILine {
    id: number
    name: string
}

function LineAutocomplete({ value, brand, handleInputChange }: any) {
    // Translate
    const { t } = useTranslation(['global'])

    // Redux global state
    const { errorForm, msgForm } = useSelector(
        (state: IRootState) => state.validator
    )

    // Handle fnuctions
    const handleAutoCompleteChange = (line: ILine | null) => {
        handleInputChange('line', line)
    }

    return (
        <Autocomplete
            id="autocomplete-lines"
            value={value}
            ChipProps={{ color: 'secondary' }}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={brand?.active_lines ?? []}
            getOptionLabel={(option: any) =>
                option.id !== undefined ? option.name : ''
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t('line')}
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    error={errorForm.lines}
                    helperText={msgForm.lines}
                />
            )}
        />
    )
}

export default LineAutocomplete
