import {
    IEventState,
    START_GET_EVENTS, SUCCESS_GET_EVENTS, ERROR_GET_EVENTS,
    START_GET_FILTERED_EVENTS, SUCCESS_GET_FILTERED_EVENTS, ERROR_GET_FILTERED_EVENTS,
    START_GET_EVENTS_BY_BRAND, SUCCESS_GET_EVENTS_BY_BRAND, ERROR_GET_EVENTS_BY_BRAND,
    START_GET_CALENDAR_EVENTS, SUCCESS_GET_CALENDAR_EVENTS, ERROR_GET_CALENDAR_EVENTS,
    START_CREATE_EVENT, SUCCESS_CREATE_EVENT, ERROR_CREATE_EVENT,
    START_SHOW_EVENT, SUCCESS_SHOW_EVENT, ERROR_SHOW_EVENT,
    START_DELETE_EVENT, SUCCESS_DELETE_EVENT, ERROR_DELETE_EVENT,
    SUCCESS_TOGGLE_FORM_EVENT
} from '../types';

const initialState: IEventState = {
    events: [],
    calendarEvents: [],
    event_selected: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false,
    reload: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_EVENTS:
        case START_GET_FILTERED_EVENTS:
        case START_GET_EVENTS_BY_BRAND:
        case START_GET_CALENDAR_EVENTS:
        case START_CREATE_EVENT:
        case START_SHOW_EVENT:
        case START_DELETE_EVENT:
            return {
                ...state,
                error: false,
                loading: true
            }
        case SUCCESS_GET_EVENTS:
        case SUCCESS_GET_FILTERED_EVENTS:
        case SUCCESS_GET_EVENTS_BY_BRAND:
            return {
                ...state,
                events: action.payload.events,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_GET_CALENDAR_EVENTS:
            return {
                ...state,
                calendarEvents: action.payload.events,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_CREATE_EVENT:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: true,
                loading: false
            }
        case SUCCESS_SHOW_EVENT:
            return {
                ...state,
                event_selected: action.payload.event,
                msg: null,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_DELETE_EVENT:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: true,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_EVENT:
            return {
                ...state,
                event_selected: (action.payload) ? null : state.event_selected,
                showForm: !state.showForm,
                reload: false
            }
        case ERROR_GET_EVENTS:
        case ERROR_GET_FILTERED_EVENTS:
        case ERROR_GET_EVENTS_BY_BRAND:
        case ERROR_GET_CALENDAR_EVENTS:
        case ERROR_CREATE_EVENT:
        case ERROR_SHOW_EVENT:
        case ERROR_DELETE_EVENT:
            return {
                ...state,
                msg: action.payload,
                error: true,
                reload: false,
                loading: false
            }
        default:
            return state;
    }
}