// React - Redux
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

// Redux Actions
import { IRootState } from '../../redux/types'
import { getBestCTR } from '../../redux/actions/reports';

// Types
interface Item {
  foreign_id: number
  foreign_name: string
  image: string
  value: number
  menu: string
}

interface BestCTR {
  weekCTR: Item[]
  monthCTR: Item[]
}

export default function useBestCTR(): BestCTR {

  // Redux Hooks
  const dispatch = useDispatch();
  const { bestCTR } = useSelector((state: IRootState) => state.report);

  useEffect(() => {
    dispatch(getBestCTR()); 
  }, [dispatch]);

  return { monthCTR: bestCTR.monthCTR, weekCTR: bestCTR.weekCTR }
}