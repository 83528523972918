import { createMuiTheme } from '@material-ui/core/styles';

const mainTheme = createMuiTheme({
    typography: {
        fontFamily: "Poppins, sans-serif",
        h1: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "3.75rem",
        },
        h2: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "3rem",
        },
        h3: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "2.625rem",
        },
        h4: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "2rem",
        },
        h5: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.75rem",
        },
        h6: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.5rem",
        },
        subtitle1: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.125rem",
        },
        body1: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1rem",
        },
        body2: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "0.875rem",
        },
        caption: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "0.75rem",
        },
    },
    palette: {
        primary: {
            dark: '#171A1C',
            main: '#2E3338',
            light: '#ABB3BA',
            contrastText: '#fff',
        },
        secondary: {
            light: process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296",
            main: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
            dark: process.env.REACT_APP_COLOR_PRIMARY_DARK || "#FB0E4B",
        },
        grey: {
            50: "#FAFAFA",
            100: "#E3E5E8",
            200: "#C7CCD1",
            300: "#ABB3BA",
            400: "#8F99A3",
            500: "#73808C",
            600: "#5C6670",
            700: "#454D54",
            800: "#2E3338",
            900: "#171A1C",
        },
        error: {
            light: "rgba(242, 61, 79, 0.2)",
            main: "#FE4634",
            dark: "#F23D4F",
        },
        warning: {
            light: "rgba(255, 119, 51, 0.2)",
            main: "#FF8833",
            dark: "#FF7733",
        },
        success: {
            light: "rgba(0, 166, 80, 0.2)",
            main: "#8DDD55",
            dark: "#00A650",
        },
        info: {
            light: "rgba(65, 137, 230, 0.2)",
            main: "#3483FA",
        },
        text: {
            primary: "#2E3338",
            secondary: "#8F99A3",
            disabled: "#FAFAFA",
        },
    },
    overrides: {
        // BASE
        MuiButton: {
            root: {
                textTransform: 'capitalize',
                fontWeight: 400,
            },
            contained: {
                boxShadow: 'none',
                borderRadius: '8px'
            },
        },
        MuiSvgIcon: {
            colorPrimary: {
                color: "#ABB3BA",
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: 'none',
            }
        },
        MuiCardActionArea: {
            focusHighlight: {
                backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F"
            }
        },
        MuiDialogActions: {
            root: {
                background: '#E3E5E8',
                padding: '16px 11px'
            }
        },
        MuiTab: {
            root: {
                padding: '0px 15px',
                minWidth: 'fit-content !important',
                minHeight: '33px',
                textAlign: 'center',
                textTransform: 'capitalize'
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                ZIndex: "99999",
            },
        },
        // FORMS
        MuiFormControl: {
            root: {
                marginBottom: '13px'
            },
            marginNormal: {
                marginTop: '0px'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'transparent',
                border: '1px solid #C7CCD1',
                borderRadius: '8px !important',
                paddingBottom: '8px',
                paddingTop: '24px',
                paddingLeft: '8px',
                paddingRight: '8px',
                minHeight: '69px',
            },
            input: {
                padding: '8px',
            },
            underline: {
                '&:before': {
                    display: 'none',
                },
                '&:after': {
                    opacity: 0,
                }
            }
        },
        MuiInputLabel: {
            shrink: {
                top: '1px',
                left: '-1px',
                fontSize: '14px',
                color: '#73808C',
                fontWeight: 400,
                letterSpacing: '0.5px',
            }
        },
        MuiInputBase: {
            root: {
                border: '1px solid #c7ccd1',
                borderRadius: '8px',
                marginTop: '0px'
            }

        },
        MuiInput: {
            underline: {
                '&:after, &:before': {
                    display: 'none'
                }
            }
        },
        MuiChip: {
            root: {
                borderRadius: '8px',
                backgroundColor: "#ABB3BA",
                color: "#FAFAFA",
                maxHeight: '26px',
            },
            colorPrimary: {
                backgroundColor: "#8DDD55",
            },
            colorSecondary: {
                backgroundColor: "#FF8833",
            },
            deletable: {
                backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
                height: "26px",
            },
            deleteIcon: {
                color: "#FAFAFA",
                width: "auto",
                height: "17px",
            },
            sizeSmall: {
                height: '18px',
                fontSize: '12px'
            },
        },
        MuiLink: {
            root: {
                '&:hover, &:hover h1, &:hover h2, &:hover h3, &:hover h4, &:hover h5, &:hover h6, &:hover p': {
                    color: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F"
                }
            },
            underlineHover: {
                '&:hover': {
                    textDecoration: 'none',
                    cursor: 'pointer'
                }
            }
        },

        MuiDialogTitle: {
            root: {
                "& h6": {
                    fontWeight: 700,
                },
                fontWeight: 700,
            }
        },

        MuiCard: {
            root: {
                borderRadius: '16px',
                '&.soft': {
                    boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                },
                '&.small': {
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
                },
                '&.lessons': {
                    boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                    display: 'flex'
                }
            },
        },
        MuiDivider: {
            root: {
                width: '100%',
                height: '1px',
                margin: '0px',
                backgroundColor: "#8F99A3",
            }
        }

    }

});

export default mainTheme;