import { START_GET_INTEGRATIONS, START_UPDATE_INTEGRATION, START_SHOW_INTEGRATION, START_TOGGLE_FORM_INTEGRATIONS,  } from '../types';

export const getIntegrations = () => ({
    type: START_GET_INTEGRATIONS
})

export const updateIntegration = payload => ({
    type: START_UPDATE_INTEGRATION,
    payload
})

export const showIntegration = payload => ({
    type: START_SHOW_INTEGRATION,
    payload
})

export const toggleFormIntegrations = () => ({
    type: START_TOGGLE_FORM_INTEGRATIONS
})