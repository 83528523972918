import { START_GET_FAVORITES, START_SET_FAVORITE, START_UNSET_FAVORITE } from '../types';

export const getFavorites = () => ({
    type: START_GET_FAVORITES
})

export const setFavorite = payload => ({
    type: START_SET_FAVORITE,
    payload
})

export const unsetFavorite = payload => ({
    type: START_UNSET_FAVORITE,
    payload
})