// React
import { useHistory } from 'react-router-dom'

// Material UI
import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  Box,
  Typography,
} from "@material-ui/core";

// Assets
import logo from "../assets/Images/logos/logodark.svg";
import vector from "../assets/vector/errorvector.svg";

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../hooks/useScreenWidth";

// Custom Styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.grey[50],
      // position: 'absolute',
      // top: '0',
      width: "100%",
      minHeight: "90vh",
    },
    backgroundColor: {
      backgroundColor: theme.palette.primary.main,
      width: 16,
    },
    buttonBack: {
      maxWidth: "442px",
      marginBottom: 64,
    },
  })
);

// Types
type NotFoundProps = {
  showLogo?: boolean;
};

function NotFound({ showLogo = true }: NotFoundProps) {
  const classes = useStyles();

  const history = useHistory();

  // Translate
  const { t } = useTranslation(["dashboard"]);
  //Custom Hook
  const isTablet = useScreenWidth(960);

  return (
    <Box className={classes.container}>
      {showLogo && (
        <Box p={7} pb={0}>
          <img src={logo} width="86.37" alt="logo" />
        </Box>
      )}
      <Box
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        pt={5}
      >
        <img
          src={vector}
          width={isTablet ? "75%" : "25%"}
          alt="logo"
        />
        <Box
          justifyContent="center"
          alignContent="center"
          display="flex"
          pt={8}
          pb={8}
          px={4}
          maxWidth="630px"
          gridGap={32}
        >
          <Box width="4px" height="auto" className={classes.backgroundColor} />
          <Box gridGap={16} display="grid">
            <Typography variant="h3" color="inherit">
              Pagina no encontrada
            </Typography>
            <Typography variant="body1" color="inherit">
              No te preocupes, puedes seguir navegando en{" "}
              {process.env.REACT_APP_NAME} sin ningun problema. Haz click en el
              botón Ir a inicio para explorar lo que{" "}
              {process.env.REACT_APP_NAME} tiene para tí.
            </Typography>
          </Box>
        </Box>
        <Box p={3} width="-webkit-fill-available" textAlign="center">
          <Button
            id="btn-login"
            variant="contained"
            fullWidth
            className={classes.buttonBack}
            onClick={() => history.goBack()}
          >
            {t("back")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NotFound;
