import {
    START_GET_BRANDS,
    START_GET_USER_BRANDS,
    START_SHOW_BRAND,
    START_VIEW_BRAND,
    START_STORE_BRAND,
    START_UPDATE_BRAND,
    START_DELETE_BRAND,
    START_TOGGLE_FORM_BRANDS,
    START_CHANGE_LINE_STATUS,
    START_DELETE_BRAND_LINE,
    START_ASSIGN_HIGHLIGHT_BRAND,
    START_ORDERED_BRAND
} from '../types';

export const getBrands = () => ({
    type: START_GET_BRANDS
})

export const getUserbrands = () => ({
    type: START_GET_USER_BRANDS
})

export const showBrand = payload => ({
    type: START_SHOW_BRAND,
    payload
})

export const viewBrand = payload => ({
    type: START_VIEW_BRAND,
    payload
})

export const storeBrand = payload => ({
    type: START_STORE_BRAND,
    payload
})

export const updateBrand = payload => ({
    type: START_UPDATE_BRAND,
    payload
})

export const deleteBrand = payload => ({
    type: START_DELETE_BRAND,
    payload
})

export const toggleFormBrands = () => ({
    type: START_TOGGLE_FORM_BRANDS
})

export const changeLineStatus = payload => ({
    type: START_CHANGE_LINE_STATUS,
    payload
})

export const deleteBrandLine = payload => ({
    type: START_DELETE_BRAND_LINE,
    payload
})

export const assignHighlight = payload => ({
    type: START_ASSIGN_HIGHLIGHT_BRAND,
    payload
})

export const orderedBrands = payload => ({
    type: START_ORDERED_BRAND,
    payload
})