import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

const DynamicUserBreadcrumb = ({ match }: any) => (
    <span>Test</span>
);

// define custom breadcrumbs for certain routes.
// breadcumbs can be components or strings.
const routes = [
    { path: '/', breadcrumb: 'Inicio' },
    { path: '/biblioteca', breadcrumb: DynamicUserBreadcrumb },
];

// map, render, and wrap your breadcrumb components however you want.
const Breadcrumbs = ({ breadcrumbs }: any) => (
    <div>
        {breadcrumbs.map(({
            match,
            breadcrumb
        }: any) => (
            <span key={match.url}>
                <NavLink to={match.url}>{breadcrumb} / </NavLink>
            </span>
        ))}
    </div>
);

export default withBreadcrumbs(routes, { excludePaths: ['/inicio'] })(Breadcrumbs);