// React
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// Material UI
import {
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Tooltip,
} from "@material-ui/core";
import {
  BackspaceRounded,
  CancelRounded,
  KeyboardReturn,
  SearchRounded,
} from "@material-ui/icons";
import clsx from "clsx";

// Libraries
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce'

// Redux Actions
import { globalSearchQuery } from "../../../redux/actions/global";

//Custom Hook
import useScreenWidth from "../../../hooks/useScreenWidth";

// Styles
import GlobalSearchStyles from "../../../styles/components/layouts/navigation/GlobalSearchStyles";

function InputGlobalSearch({ handleClose }: any) {
  // Styles
  const classes = GlobalSearchStyles();

  // Translation
  const { t } = useTranslation(["global"]);

  //Custom Hook
  const isMobile = useScreenWidth(960);

  //Redux Hooks
  const dispatch = useDispatch();
  const inputRef = useRef<string>('');

  const handleEnterKey = debounce((event: any) => {
    console.log("ENTERKEY", event);
    inputRef.current = event.target.value;
    if (event.key === "Enter") {
      handleOnSubmit(inputRef.current);
    } else {
      handleOnSubmit(inputRef.current);
    }
  }, 500);

  function handleOnSubmit(query: string) {
    dispatch(globalSearchQuery(query));
  }

  function handleOnReset() {
    handleOnSubmit("");
  }

  useEffect(() => {
    handleOnSubmit("");
  }, []);

  return (
    <InputBase
      autoFocus={true}
      fullWidth
      placeholder={`${t("searchIn")} ${process.env.REACT_APP_NAME}`}
      classes={{
        root: clsx(classes.inputRoot, isMobile ? "small" : ""),
        input: clsx(classes.inputInput, isMobile ? "small" : "header"),
      }}
      id="search-focus"
      inputProps={{ "aria-label": "search", "ref": inputRef }}
      startAdornment={
        <InputAdornment position="start" className={classes.searchIcon}>
          <IconButton
            aria-label="toggle search submit"
            onClick={() => {
              handleOnSubmit(inputRef.current);
            }}
            edge="start"
          >
            <SearchRounded />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end" className={classes.closeIcon}>
          <Tooltip title={`${t("clear-search")}`}>
            <IconButton
              classes={{ root: classes.clearicon }}
              onClick={() => {
                handleOnReset();
              }}
            >
              <BackspaceRounded fontSize="small" />
            </IconButton>
          </Tooltip>
          <Button
            size="small"
            variant="contained"
            aria-label="toggle search submit"
            className={classes.ShorcutSearch}
            endIcon={<KeyboardReturn />}
            onClick={() => {
              handleOnSubmit(inputRef.current);
            }}
          >
            {`${t("search")}`}
          </Button>
          <Tooltip title={`${t("cancel")}`}>
            <IconButton onClick={handleClose}>
              <CancelRounded />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
      onKeyUp={handleEnterKey}
    />
  );
}

export default InputGlobalSearch;
