// Material UI
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// Styles
import SkeletonStyle from '../../../../styles/components/layouts/navigation/SkeletonStyle';

//Custom Components
import HiddenMobile from '../../../common/HiddenMobile';
import HiddenDesktop from '../../../common/HiddenDesktop';

function HomeSliderSkeleton() {

    // Styles
    const classes = SkeletonStyle();

    return (
        <>
            {/* Bradcrumb  */}
            <HiddenDesktop>
                <Box height={50}></Box>
            </HiddenDesktop>
            {/* Rectangle of Slider */}
            <HiddenMobile>
                <Skeleton variant='rect' animation='wave' height='27.5rem' width='100%' style={{ maxWidth: '1920px' }} />
            </HiddenMobile>
            <HiddenDesktop>
                <Box p={2}>
                    <Skeleton variant='rect' animation='wave' height='144px' width='100%' className={classes.sklborderRadius} />
                </Box>
            </HiddenDesktop>
        </>
    )
}

export default HomeSliderSkeleton