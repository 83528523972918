// React
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Container, IconButton } from '@material-ui/core';
import { ArrowForward, DoubleArrow } from '@material-ui/icons';

// Custom components
import Header from '../../../components/common/header/Header';
import TypeFilter from '../../../components/common/header/TypeFilter';
import SubHeader from '../../../components/common/header/Subheader';
import ScrollSubheader from '../../../components/common/header/ScrollSubHeader';
import ContentFilter from '../../../components/layouts/filters/ContentFilter';
import SeminarPanel from '../../../components/seminars/SeminarPanel';
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../../components/common/ScreenLoading';

// Custom Hooks
import useSkeleton from '../../../components/layouts/navigation/SkeletonLayout';
import useGeneralSummaryReport from "../../../hooks/useGeneralSummaryReport";

// Interface
import SeminarInterface from '../../../interfaces/SeminarInterface';

// Redux Actions
import { getSeminars } from '../../../redux/actions/seminars';
import { getSeminartypes } from '../../../redux/actions/seminartypes';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import HiddenDesktop from '../../../components/common/HiddenDesktop';

// Types
interface IFilter {
    seminartype_id: number | null,
    family_id: number | null,
    brand_id: number | null,
    line_id: number | null,
    country_id: number | null,
    language_id: number | null,
    orderby_id: number | null
}

function SeminarsHome() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Redux Hooks
    const dispatch = useDispatch();
    const { seminars, error, msg, loading } = useSelector((state: IRootState) => state.seminar);
    const { seminartypes } = useSelector((state: IRootState) => state.seminartype);
    const { globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalLanguageId, globalOrderById } = useSelector((state: IRootState) => state.global);

    const { loading: loadingSkeleton, component: componentSkeleton } = useSkeleton();
    useGeneralSummaryReport(seminars, 20, 8);

    // Filter State
    const [filters, setFilters] = useState<IFilter>({
        seminartype_id: null,
        family_id: null,
        brand_id: null,
        line_id: null,
        country_id: null,
        language_id: null,
        orderby_id: null
    });

    useEffect(() => {
        if (!seminartypes.length)
            dispatch(getSeminartypes({
                language_id: globalLanguageId,
                country_id: globalCountryId
            }));
    }, [globalLanguageId, globalCountryId]);

    useEffect(() => {
        setFilters({
            ...filters,
            seminartype_id: null,
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            language_id: globalLanguageId,
            country_id: globalCountryId,
            orderby_id: globalOrderById
        });
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalLanguageId, globalOrderById]);

    useEffect(() => {
        dispatch(getSeminars(filters));
    }, [filters]);

    /** Functions */
    const handleChangeFilter = (seminartype_id: number) => {
        setFilters({ ...filters, seminartype_id });
    }

    const getSubsection = (): null | string => {
        let subsection = null;
        if (filters.seminartype_id !== null && seminartypes.length !== 0)
            subsection = seminartypes.find((seminartype: any) => seminartype.id === filters.seminartype_id).name;

        return subsection;
    }

    if (loadingSkeleton) {
        return (
            <>
                {componentSkeleton}
            </>
        )
    }

    const myElement: HTMLElement | null = document.getElementById('window');
    let midOfWidth = window.innerWidth / 2

    return (

        <>
            <Header title={t('seminars')} />
            <SubHeader>
                <ScrollSubheader>
                    <TypeFilter
                        id={null}
                        type={t("all")}
                        categories={[]}
                        active={filters.seminartype_id === null}
                        handleChangeFilter={handleChangeFilter}
                    />
                    {seminartypes.map((seminartype: any) => (
                        <TypeFilter
                            key={seminartype.id}
                            id={seminartype.id}
                            type={seminartype.name}
                            categories={[]}
                            active={seminartype.id === filters.seminartype_id}
                            handleChangeFilter={handleChangeFilter}
                        />
                    ))}
                    <ArrowForward />
                </ScrollSubheader>
                <HiddenDesktop>
                    <IconButton className='buttonScroll' onClick={() => { myElement?.scrollBy(midOfWidth, 0) }}>
                        <DoubleArrow />
                    </IconButton>
                </HiddenDesktop>
            </SubHeader>

            <ContentFilter showFilterBy={false} />

            <Container maxWidth='lg'>
                <SeminarPanel
                    highlightSeminars={seminars.filter((seminar: SeminarInterface) => seminar.highlight === 1)}
                    seminars={seminars}
                    loading={loading}
                    subsection={getSubsection()} />
            </Container>

            <CustomSnackBar errors={[error]} msgs={[msg]} />
            <ScreenLoading loadings={[loading]} />
        </>
    );
}

export default SeminarsHome;