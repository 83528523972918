// Material UI
import { Box, SvgIconTypeMap, Typography } from '@material-ui/core';
//Styles
import UsersStyle from '../../styles/components/common/UsersStyle';

// Types
type UserItemMobileProps = {
    label: string,
    content?: string,
    icon?: JSX.Element
}

export default function UserItemMobile({ label, content, icon }: UserItemMobileProps) {
    //Styles
    const classes = UsersStyle();

    return (
        <>
            <Box display='flex' py={'4px'} alignItems={'center'}>
                <Box p={'14px'} className={classes.iconItemMobile}>
                    {icon}
                </Box>
                <Box>
                    <Typography
                        variant='caption'
                        color='textSecondary'
                        style={{ fontWeight: 500 }}>
                        {label}
                    </Typography>
                    <Typography
                        variant='body2'
                        style={{ fontWeight: 400 }}>
                        {content}
                    </Typography>
                </Box>
            </Box>
        </>
    );
}