import {
    IAuthState,
    START_LOGIN, SUCCESS_LOGIN, ERROR_LOGIN,
    START_VERIFY_USER, SUCCESS_VERIFY_USER, ERROR_VERIFY_USER, ERROR_REQUEST_VERIFY_USER,
    START_LOGOUT_USER, SUCCESS_LOGOUT_USER, ERROR_LOGOUT_USER,
    START_REMEMBER_PASSWORD, SUCCESS_REMEMBER_PASSWORD, ERROR_REMEMBER_PASSWORD,
    START_RESET_PASSWORD, SUCCESS_RESET_PASSWORD, ERROR_RESET_PASSWORD,
    START_CHANGE_PASSWORD, SUCCESS_CHANGE_PASSWORD, ERROR_CHANGE_PASSWORD,
    START_CLEAR_STORAGE, SUCCESS_CLEAR_STORAGE, ERROR_CLEAR_STORAGE
} from '../types';

const initialState: IAuthState = {
    isAuth: false,
    authFrom: null,
    user: null,
    msg: null,
    loading: false,
    error: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_VERIFY_USER:
        case START_LOGIN:
        case START_LOGOUT_USER:
        case START_REMEMBER_PASSWORD:
        case START_RESET_PASSWORD:
        case START_CHANGE_PASSWORD:
        case START_CLEAR_STORAGE:
            return {
                ...state,
                loading: true,
                error: false,
                msg: null
            }
        case SUCCESS_LOGIN:
            localStorage.setItem('isAuth', '1');
            if (localStorage.getItem('userId') != action.payload.user.id) {
                localStorage.setItem('userId', action.payload.user.id);
                localStorage.setItem('globalLanguageId', action.payload.global_language_id);
                localStorage.setItem('globalLanguageCode', action.payload.global_language_code);
            }

            localStorage.setItem('accept_terms', action.payload.accept_terms);
            localStorage.setItem('globalFamilyId', "null");
            localStorage.setItem('globalBrandId', "null");
            localStorage.setItem('globalCountryId', action.payload.global_country_id);
            localStorage.setItem('lastSeminarLessonId', action.payload.lastSeminarLessonId);
            localStorage.removeItem('globalLineId');
            localStorage.removeItem('globalCountriesId');
            localStorage.removeItem('globalRoleId');
            localStorage.removeItem('globalOrderById');
            localStorage.removeItem('statusInstallApp');
            localStorage.removeItem('login_redirect');
            return {
                ...state,
                isAuth: true,
                authFrom: action.payload.authFrom,
                user: action.payload.user,
                loading: false,
                error: false
            }
        case SUCCESS_LOGOUT_USER:
        case ERROR_LOGOUT_USER:
            localStorage.removeItem('isAuth');
            localStorage.removeItem('globalTag');
            localStorage.removeItem('globalBrandId');
            localStorage.removeItem('globalFamilyId');
            localStorage.removeItem('globalLineId');
            localStorage.removeItem('globalCountryId');
            localStorage.removeItem('globalCountriesId');
            localStorage.removeItem('globalRoleId');
            localStorage.removeItem('globalOrderById');
            localStorage.removeItem('statusInstallApp');
            localStorage.removeItem('lastSeminarLessonId');
            localStorage.removeItem('accept_terms');
            localStorage.removeItem('lastRequestMe');
            localStorage.removeItem('login_redirect');
            return {
                ...state,
                isAuth: false,
                loading: false,
                error: false
            }
        case SUCCESS_VERIFY_USER:
            localStorage.setItem('lastRequestMe', Date.now().toString());
            return {
                ...state,
                user: action.payload.user,
                isAuth: true,
                loading: false,
                error: false
            }
        case ERROR_VERIFY_USER:
            localStorage.removeItem('isAuth');
            return {
                ...state,
                user: null,
                isAuth: false,
                loading: false,
                error: true,
                msg: 'Su session ha terminado'
            }
        case SUCCESS_REMEMBER_PASSWORD:
            return {
                ...state,
                loading: false,
                error: false,
                msg: action.payload.msg
            }
        case SUCCESS_RESET_PASSWORD:
            localStorage.setItem('isAuth', '1');
            if (localStorage.getItem('userId') != action.payload.user.id) {
                localStorage.setItem('userId', action.payload.user.id);
                localStorage.setItem('globalLanguageId', action.payload.global_language_id);
                localStorage.setItem('globalLanguageCode', action.payload.global_language_code);
            }

            localStorage.setItem('accept_terms', action.payload.accept_terms);
            localStorage.setItem('globalFamilyId', "null");
            localStorage.setItem('globalBrandId', "null");
            localStorage.setItem('globalCountryId', action.payload.global_country_id);
            localStorage.setItem('lastSeminarLessonId', action.payload.lastSeminarLessonId);

            localStorage.removeItem('globalLineId');
            localStorage.removeItem('globalCountriesId');
            localStorage.removeItem('globalRoleId');
            localStorage.removeItem('globalOrderById');
            localStorage.removeItem('statusInstallApp');
            return {
                ...state,
                isAuth: true,
                user: action.payload.user,
                authFrom: action.payload.authFrom,
                loading: false,
                error: false,
                msg: action.payload.msg
            }
        case SUCCESS_CHANGE_PASSWORD:
            localStorage.setItem('isAuth', '1');
            return {
                ...state,
                isAuth: true,
                loading: false,
                error: false,
                msg: action.payload.msg
            }
        case ERROR_RESET_PASSWORD:
        case ERROR_LOGIN:
        case ERROR_REMEMBER_PASSWORD:
        case ERROR_CHANGE_PASSWORD:
            return {
                ...state,
                loading: false,
                error: true,
                msg: action.payload
            }
        case ERROR_REQUEST_VERIFY_USER:
            return {
                ...state,
                loading: false,
            }
        case SUCCESS_CLEAR_STORAGE:
        case ERROR_CLEAR_STORAGE:
            localStorage.removeItem('isAuth');
            localStorage.removeItem('globalBrandId');
            localStorage.removeItem('globalLineId');
            localStorage.removeItem('globalCountryId');
            localStorage.removeItem('globalCountriesId');
            localStorage.removeItem('globalRoleId');
            localStorage.removeItem('globalOrderById');
            localStorage.removeItem('statusInstallApp');
            localStorage.removeItem('globalTag');
            localStorage.removeItem('globalLanguageCode');
            localStorage.removeItem('globalLanguageId');
            localStorage.removeItem('userId');
            localStorage.removeItem('login_redirect');
            localStorage.removeItem('accept_terms');
            localStorage.removeItem('lastSeminarLessonId');
            localStorage.removeItem('lastRequestMe');

            window.location.href = '/';
            return {
                ...state,
                loading: false,
                error: false,
                msg: null
            }
        default:
            return state;
    }
}