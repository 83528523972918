// React
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Material UI
import {
  StepButton,
  Step,
  Stepper,
  FormControl,
  Grid,
  Button,
  Box,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Fade,
  FormControlLabel,
  Input,
  InputAdornment,
  Checkbox,
  Typography,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import { CloseRounded, InfoOutlined } from "@material-ui/icons/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";

// Redux Actions
import { getCountries, getLocations } from "../../redux/actions/countries";
import { registerUser, verifyCommercialcode } from "../../redux/actions/users";
import {
  formValidator,
  resetFormValidator,
} from "../../redux/actions/validator";
import { getLanguages } from "../../redux/actions/languages";
import { getRoles } from "../../redux/actions/roles";
import { login } from "../../redux/actions/auth";
import { IRootState } from "../../redux/types";

// Custom components
import TermsModal from "../../components/common/TermsModal";
import CustomSnackBar from "../../components/common/admin/CustomSnackBar";
import ScreenLoading from "../../components/common/ScreenLoading";
import CustomChip from "../../components/common/admin/CustomChip";
import {
  CancelButton,
  LanguageMultiAutocomplete,
  SaveButton,
} from "../../components/common/forms";
import ButtonBack from "../../components/common/ButtonBack";

// Assets
import logo from "../../assets/Images/logos/logodark.svg";

// Styles
import FormsStyles from "../../styles/components/common/forms/FormsStyles";
import ResourceStyle from "../../styles/pages/LoginRegisterStyle";

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Types
type IUser = {
  name: null | string;
  phone: null | string;
  email: null | string;
  password: null | string;
  languages: number[];
  code: null | string;
  centerName: null | string;
  country: any;
  province: any;
  town: any;
  address: null | string;
  postalcode: any;
  clientType: null | number;
  terms: boolean;
  recFrom: string;
  howDidUKnow: null | number;
};

type IStrongPassword = {
  number: boolean;
  letters: boolean;
  lowerLetter: boolean;
  upperLetter: boolean;
  isCorrect: boolean;
  open: boolean;
};

const Register = ({ authFrom = "root" }: any) => {
  const { t } = useTranslation(["global"]);
  const history = useHistory();

  // Styles
  const formClasses = FormsStyles();

  //Custom Hook
  const isMobile = useScreenWidth(700);

  // Const
  const clientTypes = [
    { id: 0, name: t("admin.people.esthetic-professional") },
    { id: 1, name: t("admin.people.final-audience") },
    { id: 2, name: t("admin.people.esthetic-academy-student") },
    { id: 3, name: t("admin.people.esthetic-academy-professor") },
  ];

  const HowDidUKnowAnswers = [
    { id: 0, name: t("client.register.social-networks") },
    { id: 1, name: t("client.register.it-was-recommended-to-me") },
    { id: 2, name: t("client.register.my-commercial") },
    { id: 3, name: t("client.register.google-and-other-search-engines") },
    { id: 4, name: t("client.register.digital-advertising") },
    { id: 5, name: t("client.register.media-advertising") },
    {
      id: 6,
      name: t("client.register.website", {
        COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME,
      }),
    },
  ];

  //Redux Hooks
  const dispatch = useDispatch();
  const { isAuth, user: authUser } = useSelector(
    (state: IRootState) => state.auth
  );
  const {
    countries,
    provinces,
    towns,
    error: errorCountry,
    msg: msgCountry,
  } = useSelector((state: IRootState) => state.country);
  const {
    languages,
    error: errorLanguage,
    msg: msgLanguage,
  } = useSelector((state: IRootState) => state.language);
  const { isValidForm, errorForm, msgForm } = useSelector(
    (state: IRootState) => state.validator
  );
  const { userSelected, code, loading, error, msg } = useSelector(
    (state: IRootState) => state.user
  );

  const [displayTerms, setDisplayTerms] = useState(false);
  const [user, setUser] = useState<IUser>({
    name: null,
    phone: null,
    email: null,
    password: null,
    languages: [],
    code: null,
    centerName: null,
    country: [],
    province: [],
    town: [],
    address: "",
    postalcode: [],
    clientType: null,
    terms: false,
    recFrom: authFrom,
    howDidUKnow: null,
  });

  const [strongPassword, setStrongPassword] = useState<IStrongPassword>({
    number: false,
    letters: false,
    lowerLetter: false,
    upperLetter: false,
    isCorrect: false,
    open: false,
  });

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getCountries());
    dispatch(getRoles());
    dispatch(getLocations());
  }, []);

  /* Functions */
  const handleInputFormsChange = (inputName: string, value: any) => {
    setUser({ ...user, [inputName]: value });
  };

  const handleAutoCompleteChange =
    (inputName: string) => (event: any, value: any) => {
      if (value !== null) setUser({ ...user, [inputName]: value });
    };

  const handleInputChange = (inputName: string) => (event: any) => {
    const value = event.target.value;
    if (inputName === "password") verifyStrongPassword(value);
    setUser({ ...user, [inputName]: value });
  };

  const handleCheckChange = (event: any) => {
    setUser({ ...user, terms: event.target.checked });
  };

  const handleDisplayTerms = (display: boolean) => {
    setDisplayTerms(display);
  };

  const handleSubmit = () => {
    if (activeStep === 0) {
      dispatch(
        formValidator({
          name: [user.name, "required|max:100|min:3"],
          phone: [user.phone, "required|max:45|min:6|numeric"],
          email: [user.email, "required|max:100|min:5|email"],
          password: [user.password, "required|max:50|min:8"],
          languages: [user.languages, "notempty"],
        })
      );
    }

    if (activeStep === 1 && user.code !== null && user.code !== "") {
      dispatch(
        formValidator({
          code: [user.code, "required|max:100|min:3"],
        })
      );
    }

    if (activeStep === 2) {
      dispatch(
        formValidator({
          centerName: [user.centerName, "required|max:100|min:3"],
          country: [user.country, "notempty"],
          province: [user.province, "notempty"],
          town: [user.town, "notempty"],
          postalcode: [user.postalcode, "required|max:20|min:5"],
          clientType: [user.clientType, "required"],
          howDidUKnow: [user.howDidUKnow, "required"],
          terms: [user.terms, "boolean"],
        })
      );
    }
  };

  // Send Form
  useEffect(() => {
    if (isValidForm) {
      dispatch(resetFormValidator());
      if (activeStep === 1 && user.code !== null && user.code !== "")
        dispatch(verifyCommercialcode(user.code));
      else handleComplete();
    }
  }, [isValidForm]);

  // Send Form
  useEffect(() => {
    if (code.code !== undefined && !error) handleComplete();
  }, [code]);

  function getSteps() {
    return [
      t("client.register.about-me"),
      t("admin.people.code"),
      t("admin.people.center"),
    ];
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3} className={classes.formBody}>
            <Grid item xs={12}>
              <Box mb={0.5}>
                <Typography>{t("name")}</Typography>
              </Box>
              <FormControl variant="filled" fullWidth>
                <Input
                  placeholder={t("client.register.placeholder-name")}
                  onChange={handleInputChange("name")}
                  error={errorForm.name}
                  value={user.name}
                />
                <FormHelperText error id="name-helper-text">
                  {msgForm.name}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box mb={0.5}>
                <Typography>{t("phone")}</Typography>
              </Box>
              <FormControl variant="filled" fullWidth>
                <Input
                  required
                  placeholder={t("client.register.placeholder-phone")}
                  onChange={handleInputChange("phone")}
                  error={errorForm.phone}
                  value={user.phone}
                />
                <FormHelperText error id="phone-helper-text">
                  {msgForm.phone}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box mb={0.5}>
                <Typography>{t("email")}</Typography>
              </Box>
              <FormControl variant="filled" fullWidth>
                <Input
                  placeholder={t("client.register.placeholder-email")}
                  onChange={handleInputChange("email")}
                  error={errorForm.email}
                  value={user.email}
                />
                <FormHelperText error id="email-helper-text">
                  {msgForm.email}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box mb={0.5}>
                <Typography>{t("login.password")}</Typography>
              </Box>
              <FormControl variant="filled" fullWidth>
                <Input
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 400 }}
                        arrow
                        placement="right"
                        title={
                          <Box>
                            <Typography variant="body2">
                              {t("requirements")}:
                            </Typography>
                            <Box display="flex" gridGap={8} alignItems="center">
                              <CustomChip
                                size="small"
                                color={
                                  strongPassword.letters ? "primary" : "grey"
                                }
                              />
                              <Typography variant="caption">
                                {t("client.register.minimum-8-letters")}
                              </Typography>
                            </Box>
                            <Box display="flex" gridGap={8} alignItems="center">
                              <CustomChip
                                size="small"
                                color={
                                  strongPassword.lowerLetter
                                    ? "primary"
                                    : "grey"
                                }
                              />
                              <Typography variant="caption">
                                {t(
                                  "client.register.minimum-one-lowercase-letter"
                                )}
                              </Typography>
                            </Box>
                            <Box display="flex" gridGap={8} alignItems="center">
                              <CustomChip
                                size="small"
                                color={
                                  strongPassword.upperLetter
                                    ? "primary"
                                    : "grey"
                                }
                              />
                              <Typography variant="caption">
                                {t(
                                  "client.register.minimum-one-capital-letter"
                                )}
                              </Typography>
                            </Box>
                            <Box display="flex" gridGap={8} alignItems="center">
                              <CustomChip
                                size="small"
                                color={
                                  strongPassword.number ? "primary" : "grey"
                                }
                              />
                              <Typography variant="caption">
                                {t("client.register.Minimum-one-number")}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        open={strongPassword.open}
                      >
                        <IconButton>
                          <InfoOutlined
                            htmlColor={"#8F99A3"}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                  type="password"
                  placeholder={t("client.register.placeholder-password")}
                  onChange={handleInputChange("password")}
                  onFocus={() => {
                    setStrongPassword({ ...strongPassword, open: true });
                  }}
                  onBlur={() => {
                    setStrongPassword({ ...strongPassword, open: false });
                  }}
                  error={errorForm.password}
                  value={user.password}
                />
                <FormHelperText error id="password-helper-text">
                  {msgForm.password}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Languages Input */}
            <Grid item xs={12}>
              <Box mb={0.5}>
                <Typography>{t("languages")}</Typography>
              </Box>
              <LanguageMultiAutocomplete
                type={1}
                value={user.languages}
                handleInputChange={handleInputFormsChange}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <>
            <Typography color="textSecondary">
              Con el códifo tienes acceso instantaneo al contenido exclusivo,
              con lo cual, podrás ahorrarte el tiempo que toma la validación por
              parte de nuestro equipo. <br />
              Si no lo tienes, ¡no te preocupes! haz clic en el botón "Omitir",
              finaliza los pasos y el resto lo haremos nosotros. ¡Estas cada vez
              más cerca de disfrutar {process.env.REACT_APP_NAME} al 100%!
            </Typography>

            <Box mb={0.5} mt={3}>
              <Typography>{t("admin.people.code")}</Typography>
            </Box>
            <FormControl variant="filled" fullWidth>
              <Input
                placeholder={t("client.register.placeholder-code")}
                onChange={handleInputChange("code")}
                value={user.code}
              />
            </FormControl>
          </>
        );
      case 2:
        return (
          <>
            <Grid container spacing={3} className={classes.formBody}>
              <Grid item xs={12}>
                <Box mb={0.5}>
                  <Typography>{t("admin.people.center-name")}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Input
                    placeholder={t("client.register.placeholder-center")}
                    onChange={handleInputChange("centerName")}
                    error={errorForm.centerName}
                    value={user.centerName}
                  />
                  <FormHelperText error id="centername-helper-text">
                    {msgForm.centerName}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={0.5}>
                  <Typography>{t("country")}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-country"
                    value={user.country}
                    onChange={handleAutoCompleteChange("country")}
                    options={countries}
                    getOptionLabel={(option: any) =>
                      option.id !== undefined ? option.name : ""
                    }
                    className={classes.autocomplete}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        error={errorForm.country}
                        helperText={msgForm.country}
                        placeholder={t("client.register.placeholder-country")}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box mb={0.5}>
                  <Typography>{t("province")}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-provinces"
                    value={user.province}
                    onChange={handleAutoCompleteChange("province")}
                    options={provinces.filter(
                      (province: any) => user.country.id === province.country_id
                    )}
                    getOptionLabel={(option: any) =>
                      option.id !== undefined ? option.name : ""
                    }
                    className={classes.autocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errorForm.province}
                        helperText={msgForm.province}
                        placeholder={t("client.register.placeholder-province")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box mb={0.5}>
                  <Typography>{t("town")}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-towns"
                    value={user.town}
                    onChange={handleAutoCompleteChange("town")}
                    options={towns.filter(
                      (town: any) => town.province_id === user.province.id
                    )}
                    getOptionLabel={(option: any) =>
                      option.id !== undefined ? option.name : ""
                    }
                    className={classes.autocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errorForm.town}
                        helperText={msgForm.town}
                        placeholder={t("client.register.placeholder-town")}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box mb={0.5}>
                  <Typography>
                    {t("address")} ({t("optional")})
                  </Typography>
                </Box>
                <FormControl variant="filled" fullWidth>
                  <Input
                    placeholder={t("client.register.placeholder-address")}
                    onChange={handleInputChange("address")}
                    value={user.address}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box mb={0.5}>
                  <Typography>{t("postal-code")}</Typography>
                </Box>
                <FormControl variant="filled" fullWidth>
                  <Input
                    placeholder={t("client.register.placeholder-postalcode")}
                    onChange={handleInputChange("postalcode")}
                    error={errorForm.postalcode}
                    value={user.postalcode}
                  />
                  <FormHelperText error id="postalcode-helper-text">
                    {msgForm.postalcode}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box mb={0.5}>
                  <Typography>{t("admin.people.client-type")}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Select
                    id="select-client-type"
                    label={t("client.register.placeholder-clienttype")}
                    value={user.clientType}
                    error={errorForm.clientType}
                    onChange={handleInputChange("clientType")}
                    displayEmpty
                  >
                    <MenuItem value="null" disabled>
                      {t("client.register.placeholder-clienttype")}
                    </MenuItem>
                    {clientTypes.map((clientType: any, index: number) => (
                      <MenuItem key={clientType.id} value={clientType.id}>
                        {clientType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <Box mb={0.5}>
                  <Typography>
                    {t("client.register.how-did-u-know-plei", {
                      APP_NAME: process.env.REACT_APP_NAME,
                    })}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <Select
                    id="select-how-did-u-know"
                    label={t("client.register.how-did-u-know-plei", {
                      APP_NAME: process.env.REACT_APP_NAME,
                    })}
                    value={user.howDidUKnow}
                    error={errorForm.howDidUKnow}
                    onChange={handleInputChange("howDidUKnow")}
                    displayEmpty
                  >
                    {HowDidUKnowAnswers.map((answer: any, index: number) => (
                      <MenuItem key={answer.id} value={answer.id}>
                        {answer.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox checked={user.terms} onChange={handleCheckChange} />
              }
              label={t("client.register.placeholder-terms")}
              style={{ margin: "0px" }}
            />
            <Box pl={4}>
              <Button
                variant="text"
                color="primary"
                target="_blank"
                href="https://drv.es/politica-de-privacidad/"
              >
                {t("client.register.terms", {
                  COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME,
                })}
              </Button>
            </Box>
            <FormHelperText error id="postalcode-helper-text">
              {msgForm.terms}
            </FormHelperText>

            <TermsModal
              displayTerms={displayTerms}
              handleDisplayTerms={handleDisplayTerms}
            />
          </>
        );
      default:
        return "Unknown step";
    }
  }
  // Styles
  const classes = ResourceStyle();
  // Component States
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();

  /* Functions */
  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    if (isLastStep()) {
      dispatch(registerUser(user));
    } else {
      handleNext();
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  function isStepComplete(step: number) {
    return completed.has(step);
  }

  function verifyStrongPassword(password: string) {
    let numberRexExp = new RegExp("^(?=.*[0-9])").test(password);
    let lettersRexExp = new RegExp("^(?=.{8,})").test(password);
    let lowerLetterRexExp = new RegExp("^(?=.*[a-z])").test(password);
    let upperLetterRexExp = new RegExp("^(?=.*[A-Z])").test(password);
    let isCorrectRexExp = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    ).test(password);
    setStrongPassword({
      ...strongPassword,
      number: numberRexExp,
      letters: lettersRexExp,
      lowerLetter: lowerLetterRexExp,
      upperLetter: upperLetterRexExp,
      isCorrect: isCorrectRexExp,
    });
  }

  const finishRegister = () => {
    if (userSelected.verification === 0 && authFrom === "root")
      window.location.href = "/";
    else
      dispatch(
        login({
          email: user.email,
          password: user.password,
          rememberme: false,
          authFrom,
        })
      );
  };

  useEffect(() => {
    if (isAuth && authUser !== null && authFrom === "root")
      history.push("/inicio");
  }, [isAuth]);

  let isLive = authFrom === "seminar" ? "seminar" : "";


  return (
    <>
      {authFrom === "root" && (
        <Box m={7} mb={0} className={classes.registerLogoContent}>
          <img
            src={logo}
            className={classes.registerLogo}
            height="36"
            alt="logo"
          />
          <ButtonBack />
        </Box>
      )}
      <Grid container className={clsx(classes.container, isLive)}>
        <Grid className={clsx(classes.registerForm, isLive)}>
          <Typography
            className={classes.registerHeroTitle}
            color="textPrimary"
            variant="h3"
            align="center"
          >
            {t("client.register.enjoy-plei", {
              APP_NAME: process.env.REACT_APP_NAME,
            })}
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center">
            {t("client.register.register-message")}
          </Typography>
          <Stepper
            alternativeLabel
            nonLinear
            activeStep={activeStep}
            style={{ background: "none" }}
            classes={{ root: classes.stepper }}
          >
            {steps.map((label, index) => {
              const stepProps = { completed: false };
              const buttonProps = { optional: <></> };
              if (isStepOptional(index)) {
                buttonProps.optional = (
                  <Typography variant="caption">{t("optional")}</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    onClick={handleStep(index)}
                    completed={isStepComplete(index)}
                    {...buttonProps}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <Box>{getStepContent(activeStep)}</Box>
          <Box alignContent="center" display="flex" mt={5}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
            >
              {t("back")}
            </Button>
            {isStepOptional(activeStep) &&
              (user.code === null || user.code === "") ? (
              <Button variant="contained" fullWidth onClick={handleSkip}>
                {t("client.register.skip")}
              </Button>
            ) : (
              <Button
                disabled={activeStep === 0 && !strongPassword.isCorrect}
                variant="contained"
                fullWidth
                onClick={handleSubmit}
              >
                {completedSteps() === totalSteps() - 1 && isLastStep()
                  ? t("register")
                  : t("next")}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>

      {userSelected !== null && !error && (
        <Dialog
          open={true}
          fullWidth={true}
          fullScreen={isMobile && true}
          maxWidth={!isMobile && "md"}
          className={formClasses.containerForm}
          onClose={() => finishRegister()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justify="space-between">
              <Typography variant="h6">
                {t("client.register.welcome-to-plei", {
                  APP_NAME: process.env.REACT_APP_NAME,
                })}
              </Typography>
              <Button
                onClick={() => finishRegister()}
                color="primary"
                variant="text"
              >
                <CloseRounded />
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent className={formClasses.bodyForm}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2">
                  {t("client.register.success-register-message")}{" "}
                  {userSelected.verification === 0 &&
                    ", " + t("admin.people.pending-verification")}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SaveButton onClick={() => finishRegister()}>
              {t("continue")}
            </SaveButton>
          </DialogActions>
        </Dialog>
      )}

      <CustomSnackBar
        errors={[error, errorCountry, errorLanguage]}
        msgs={[msg, msgCountry, msgLanguage]}
      />
      <ScreenLoading loadings={[loading]} />
    </>
  );
};

export default Register;
