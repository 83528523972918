// React
import { useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';

// Material UI
import { Box, Drawer, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { DoneRounded, TranslateRounded } from '@material-ui/icons';
import clsx from 'clsx';

// Custom components
import ButtonNavbar from '../navigation/ButtonNavbar';

// Redux
import { IRootState } from '../../../redux/types';
import { setGlobalLanguage } from '../../../redux/actions/global';
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Styles
import LanguageStyles from '../../../styles/components/layouts/filters/LanguageFiltersStyles';

function LanguageFilter(props: any) {
    const { theme } = props;
    const classes = LanguageStyles();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    //Custom Hook
    const isSmallMobile = useScreenWidth(380);
    const isMobile = useScreenWidth(700);

    // Location & History
    const { pathname, search } = useLocation();
    const { replace } = useHistory();
    const searchParams = new URLSearchParams(search);

    const [anchorLan, setAnchorLan] = useState<null | HTMLElement>(null);
    const { user: authUser, error: errorAuth, msg: msgAuth } = useSelector((state: IRootState) => state.auth);
    const { globalLanguageId } = useSelector((state: IRootState) => state.global);
    // Mobile Function
    const [mobileOpen, setMobileOpen] = useState<null | HTMLElement>(null);

    const handleClickLan = (event: MouseEvent<HTMLElement>) => {
        setAnchorLan(event.currentTarget);
    };

    const handleMobileClose = () => {
        setMobileOpen(null);
    };

    const handleClickMobile = (event: MouseEvent<HTMLElement>) => {
        setMobileOpen(event.currentTarget);
    };

    const handleGlobalLanguage = (languageId: number, languageCode: string) => {
        localStorage.setItem("globalLanguageId", languageId.toString());
        localStorage.setItem("globalLanguageCode", languageCode);
        dispatch(setGlobalLanguage(languageId));
        i18n.changeLanguage(languageCode);
        searchParams.set("globalLanguageId", languageId.toString());
        replace(`${pathname}?${searchParams}`);
    }

    useEffect(() => {
        if (search && searchParams.get('globalLanguageId') !== null && globalLanguageId !== Number(searchParams.get('globalLanguageId'))) {
            const searchParamLanguage = authUser.languages_name.find((language: any) => language.id == Number(searchParams.get('globalLanguageId')));
            if (searchParamLanguage) {
                handleGlobalLanguage(searchParamLanguage.id, searchParamLanguage.code);
            } else {
                searchParams.set("globalLanguageId", authUser.languages[0]);
                replace(`${pathname}?${searchParams}`);
            }
        }
        else if (authUser !== null && authUser.languages_name.length > 0) {
            const languageCodeFound = authUser.languages_name.find((language: any) => language.id == localStorage.getItem('globalLanguageId'));
            if (languageCodeFound) {
                i18n.changeLanguage(languageCodeFound.code);
            } else {
                alert('No tienes permiso para acceder a este lenguaje, se asignará el primer lenguaje disponible.');
                console.error(`No tienes permiso para acceder a este lenguaje, se asignará el primer lenguaje disponible. USER: ${authUser.id}`)
                try {
                    i18n.changeLanguage(authUser.languages_name[0].code);
                } catch (error) {
                    alert('Ha ocurrido un error inesperado, contacta con un administrador.');
                    console.error(error);
                }
            }
        }
    }, [authUser])

    return (
        <>
            {globalLanguageId !== null && authUser.languages_name.find((language: any) => language.id === globalLanguageId) !== undefined &&
                <>
                    {isSmallMobile ? <></> :
                        <Tooltip title='idioma'>
                            <ButtonNavbar
                                aria-controls="language-menu"
                                aria-haspopup="true"
                                className={theme}
                                onClick={isMobile ? handleClickMobile : handleClickLan}>
                                <TranslateRounded fontSize='small' />
                            </ButtonNavbar>
                        </Tooltip>}

                    {isMobile ?
                        <Drawer
                            variant="temporary"
                            anchor='bottom'
                            ModalProps={{ keepMounted: true, }}
                            open={Boolean(mobileOpen)}
                            onClose={handleMobileClose}
                            onClick={handleMobileClose}
                            classes={{ paper: classes.dropdownActions }}>
                            <Box className={clsx(classes.containerActions, 'filter')}>
                                {authUser.languages_name.map((language: any, index: number) => (
                                    <MenuItem className={classes.itemBtn} key={language.id} onClick={() => { handleGlobalLanguage(language.id, language.code); handleMobileClose() }} >
                                        {(language.id === globalLanguageId) && <DoneRounded fontSize='small' />}
                                        {language.code.toUpperCase()}
                                    </MenuItem>
                                ))}
                            </Box>
                        </Drawer>
                        :
                        <Menu
                            id="language-menu"
                            anchorEl={anchorLan}
                            keepMounted
                            open={Boolean(anchorLan)}
                            onClose={() => setAnchorLan(null)}
                            onClick={() => setAnchorLan(null)}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                            {authUser.languages_name.map((language: any, index: number) => (
                                <MenuItem className={classes.itemBtn} key={language.id} onClick={() => handleGlobalLanguage(language.id, language.code)} >
                                    {language.code.toUpperCase()}
                                    {(language.id === globalLanguageId) && <DoneRounded fontSize='small' />}
                                </MenuItem>
                            ))}
                        </Menu>
                    }
                </>
            }
        </>
    );
}

export default LanguageFilter;