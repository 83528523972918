// Material UI
import { Box, Typography } from "@material-ui/core";

// Libraries
// import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, ResponsiveContainer, CartesianGrid, Legend, Tooltip, YAxis, PieChart, Pie, Cell, Label, Sector } from "recharts";
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Styles
import GraphsStyles from "../../styles/components/graphs/GraphsStyles";
//
function CustomShapePieChart(this: any, { data, dataKey, typeDataName }: any) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(960);
    const isLaptop = useScreenWidth(1243);
    //Styles
    const classes = GraphsStyles();

    /* Functions */
    const handleClickItem = (value: any) => {
        window.open(value)
    }
    let responsiveWidth = !isMobile && isLaptop

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} className={classes.textHeroPie}>{`${name}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={20} textAnchor={textAnchor} className={classes.textPie} >{`${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={40} textAnchor={textAnchor} className={classes.textPie} >
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };


    return (
        <ResponsiveContainer className={classes.container} height={360} width={responsiveWidth ? 'calc(100% - 24px)' : '100%'}>
            <PieChart width={500} height={500}>
                <Pie
                    data={data}
                    dataKey={dataKey}
                    cx="50%" cy="50%"
                    innerRadius={isMobile ? 50 : 110}
                    outerRadius={isMobile ? 80 : 140}
                    activeIndex={[0, 1, 2, 3, 4]}
                    activeShape={renderActiveShape}
                >
                    {data.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}


export default CustomShapePieChart;
