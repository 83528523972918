import {
    IResourceState,
    START_GET_RESOURCES, SUCCESS_GET_RESOURCES, ERROR_GET_RESOURCES,
    START_SHOW_RESOURCE, SUCCESS_SHOW_RESOURCE, ERROR_SHOW_RESOURCE,
    START_VIEW_RESOURCE, SUCCESS_VIEW_RESOURCE, ERROR_VIEW_RESOURCE,
    START_STORE_RESOURCE, SUCCESS_STORE_RESOURCE, ERROR_STORE_RESOURCE,
    START_UPDATE_RESOURCE, SUCCESS_UPDATE_RESOURCE, ERROR_UPDATE_RESOURCE,
    START_DELETE_RESOURCE, SUCCESS_DELETE_RESOURCE, ERROR_DELETE_RESOURCE,
    SUCCESS_TOGGLE_FORM_EDIT_RESOURCES, SUCCESS_TOGGLE_FORM_CREATE_RESOURCES, SUCCESS_TOGGLE_FORM_VIEW_RESOURCES,
    START_FILTER_RESOURCES, SUCCESS_FILTER_RESOURCES, ERROR_FILTER_RESOURCES,
    START_ASSIGN_TAG_RESOURCE, SUCCESS_ASSIGN_TAG_RESOURCE, ERROR_ASSIGN_TAG_RESOURCE,
    START_DELETE_TAG_RESOURCE, SUCCESS_DELETE_TAG_RESOURCE, ERROR_DELETE_TAG_RESOURCE,
    START_ASSIGN_HIGHLIGHT_RESOURCE, SUCCESS_ASSIGN_HIGHLIGHT_RESOURCE, ERROR_ASSIGN_HIGHLIGHT_RESOURCE,
    START_GET_HOME_RESOURCES, SUCCESS_GET_HOME_RESOURCES, ERROR_GET_HOME_RESOURCES,
    START_MASS_ASSIGNMENT_RESOURCES, SUCCESS_MASS_ASSIGNMENT_RESOURCES, ERROR_MASS_ASSIGNMENT_RESOURCES
} from '../types';

const initialState: IResourceState = {
    reload: false,
    resources: [],
    feedResources: [],
    resourceSelected: null,
    msg: null,
    error: false,
    loading: false,
    showEditForm: false,
    showCreateForm: false,
    showViewForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_RESOURCES:
        case START_SHOW_RESOURCE:
        case START_STORE_RESOURCE:
        case START_UPDATE_RESOURCE:
        case START_DELETE_RESOURCE:
        case START_VIEW_RESOURCE: 
        case START_ASSIGN_TAG_RESOURCE:
        case START_DELETE_TAG_RESOURCE:
        case START_ASSIGN_HIGHLIGHT_RESOURCE:
        case START_FILTER_RESOURCES:
        case START_GET_HOME_RESOURCES:
        case START_MASS_ASSIGNMENT_RESOURCES:
            return {
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_RESOURCES:
            return {
                ...state,
                resources: action.payload.pageNumber === 1 
                    ? action.payload.resources 
                    : [...state.resources, ...action.payload.resources],
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_SHOW_RESOURCE:
            return {
                ...state,
                resourceSelected: action.payload.resource,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showEditForm: true
            }
        case SUCCESS_VIEW_RESOURCE:
            return {
                ...state,
                resourceSelected: action.payload.resource,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showViewForm: true,
                showEditForm: false,
                showCreateForm: false
            }
        case SUCCESS_STORE_RESOURCE:
            return {
                ...state,
                reload: true,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showEditForm: false,
                showCreateForm: false
            }
        case SUCCESS_UPDATE_RESOURCE:
            return {
                ...state,
                reload: true,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showEditForm: false,
                showCreateForm: false
            }
        case SUCCESS_DELETE_RESOURCE:
            return {
                ...state,
                resources: state.resources.filter((resource: any) => resource.id != action.payload.resource_id),
                msg: action.payload.msg,
                error: false,
                loading: false,
                showEditForm: false,
                showCreateForm: false
            }
        case SUCCESS_TOGGLE_FORM_EDIT_RESOURCES:
            return {
                ...state,
                showEditForm: !state.showEditForm
            }
        case SUCCESS_TOGGLE_FORM_CREATE_RESOURCES:
            return {
                ...state,
                showCreateForm: !state.showCreateForm,
            }
        case SUCCESS_TOGGLE_FORM_VIEW_RESOURCES:
            return {
                ...state,
                showViewForm: !state.showViewForm
            }
        case SUCCESS_FILTER_RESOURCES:
            return {
                ...state,
                resources: action.payload.resources,
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_ASSIGN_TAG_RESOURCE:
            let tmpResource = state.resources.find((resource: any) => resource.id === action.payload.id);
            tmpResource.tags = action.payload.tags.toString();
            return {
                ...state,
                resources: state.resources.map((resource: any) =>
                    resource.id === action.payload.id ? tmpResource : resource
                ),
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_DELETE_TAG_RESOURCE:
            let tmpResource1 = state.resources.find((resource: any) => resource.id === action.payload.id);
            const tags = tmpResource1.tags.split(',');
            const newTags = tags.filter((tagId: number) => tagId != action.payload.tagId).toString();
            tmpResource1.tags = newTags === '' ? null : newTags;
            return {
                ...state,
                resources: state.resources.map((resource: any) =>
                    resource.id === action.payload.id ? tmpResource1 : resource
                ),
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_ASSIGN_HIGHLIGHT_RESOURCE:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false,
                reload: true
            }
        case SUCCESS_GET_HOME_RESOURCES:
            return {
                ...state,
                feedResources: action.payload.resources,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_MASS_ASSIGNMENT_RESOURCES:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false,
                reload: true
            }
        case ERROR_GET_RESOURCES:
        case ERROR_SHOW_RESOURCE:
        case ERROR_VIEW_RESOURCE:
        case ERROR_STORE_RESOURCE:
        case ERROR_UPDATE_RESOURCE:
        case ERROR_DELETE_RESOURCE:
        case ERROR_FILTER_RESOURCES:
        case ERROR_ASSIGN_TAG_RESOURCE:
        case ERROR_DELETE_TAG_RESOURCE:
        case ERROR_ASSIGN_HIGHLIGHT_RESOURCE:
        case ERROR_GET_HOME_RESOURCES:
        case ERROR_MASS_ASSIGNMENT_RESOURCES:
            return {
                ...state,
                reload: false,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}