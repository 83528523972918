import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Grid, Typography, Box, IconButton, MenuItem, Menu, Tooltip, Fade
} from '@material-ui/core';
import { MoreVert, InfoOutlined, } from '@material-ui/icons/';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table';

// Custom components
import UserTransferModal from '../../components/users/UserTransferModal';
import UserModal from '../../components/users/UserModal';
import CustomChip from '../common/admin/CustomChip'

// Redux Actions
import { showUser, deleteUser, showDependents, getNotDependents } from '../../redux/actions/users';
import { IRootState } from '../../redux/types';

// Translate
import { useTranslation } from "react-i18next";

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

type UserLinkedProps = {
    user_id: number | string | null,
}

function UserLinked({ user_id }: UserLinkedProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userProfileSelected, dependents, notdependents } = useSelector((state: IRootState) => state.user);

    // Table menu function
    const [anchorOp, setAnchorOp] = React.useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = React.useState(-1);

    useEffect(() => {
        if (authUser != null){
            let userId = null;
            if (user_id != null)
                userId = user_id;
            else if (user_id == null)
                userId = authUser.id;
    
            if (!dependents.length)
                dispatch(showDependents(userId));
            if (!notdependents.length)
                dispatch(getNotDependents(userId));
        }
    }, [authUser]);

    const columns: any = [
        {
            title: t("name"), field: 'name', render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8}>
                    <CustomChip size="small" color="primary" />
                    <Typography>
                        {rowData.name}
                    </Typography>
                </Box>
            )
        },
        { title: "ID", field: 'id' },
        // {
        //     title: t("verification"), field: '', render: (rowData: any) => {
        //         if (rowData.verification === 1) {
        //             return (<Box pl={4} pt={1}>
        //                 <object data={VerifiedIcon} aria-label={t("verified")} className={classes.iconVerified} />
        //             </Box>)
        //         }
        //         if (rowData.verification === 3) {
        //             return (<Box pl={4} pt={1}>
        //                 <object data={VerifiedIcon} aria-label={"denegado"} className={classes.iconDenied} />
        //             </Box>)
        //         }
        //         else {
        //             return (<Box pl={4} pt={1}>
        //                 <object data={VerifiedIcon} aria-label={t("unverified")} className={classes.iconUnverified} />
        //             </Box>)
        //         }
        //     }, hidden: filters.role_id !== 6
        // },
        // {
        //     title: t("admin.people.linked"), field: '', render: (rowData: any) => (
        //         <Box>
        //             <CustomSwitch />
        //         </Box>
        //     )
        // },
        { title: t("rol"), field: 'role', type: string },
        { title: t("email"), field: 'email', type: string },
        { title: t("postalcode"), field: 'postalcode', type: string },
        {
            title: '', field: '', render: (rowData: any) => (
                <>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}>
                        <MenuItem onClick={() => { dispatch(showUser(rowData.id)) }}>{t("edit")}</MenuItem>
                        <MenuItem onClick={() => { dispatch(deleteUser(rowData.id)) }}>{t("delete")}</MenuItem>
                    </Menu>
                </>)
        }
    ];

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    return (

        <>
            {authUser !== null && userProfileSelected !== null &&
                <>
                    <UserModal role_id={userProfileSelected.role_id} showBotton={false} />

                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Box gridGap={24} display="flex" flexDirection="column">
                                <Box display="flex" justifyContent="center" width="100%">
                                    <Typography variant="h5" align="center">{t("admin.people.linked")}</Typography>
                                    <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow
                                        title={
                                            <Box>
                                                <Typography variant="body2">{t('status')}:</Typography>
                                                <Box display="flex" gridGap={8} alignItems="center">
                                                    <CustomChip size="small" color={'primary'} />
                                                    <Typography variant="caption">{t('active')}</Typography>
                                                </Box>
                                                <Box display="flex" gridGap={8} alignItems="center">
                                                    <CustomChip size="small" color={'secondary'} />
                                                    <Typography variant="caption">{t('inactive')}</Typography>
                                                </Box>
                                            </Box>
                                        }>
                                        <IconButton>
                                            <InfoOutlined htmlColor={'#8F99A3'} fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                {userProfileSelected !== null && notdependents.length &&
                                    <UserTransferModal />}

                            </Box>
                            <Box mt={2} mb={5}>
                                <div className="table_custom white">
                                    <MaterialTable
                                        localization={localization}
                                        title=""
                                        columns={columns}
                                        data={dependents}
                                        actions={[]}
                                        options={options}
                                        icons={tableIcons}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
}

export default UserLinked;