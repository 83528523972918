// Material UI
import { styled } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

const SubListItemMenu = styled(List)(({
        theme
    }) => ({
        paddingBottom: '10px',
        '& a .MuiTypography-root': {
            color: theme.palette.grey[300],
            fontSize: 15
        },
        '& a:hover .MuiTypography-root': {
            color: theme.palette.grey[50]
        },
        '& .MuiListItem-root': {
            padding: '0 24px 0px 55px',
        }
    }));

export default SubListItemMenu;