// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from "react-router-dom";

// Material UI
import { Container, Grid } from '@material-ui/core';

// Custom Components
import EventsModal from '../../components/events/EventsModal';
import EventTemplate from '../../components/events/EventTemplate';
import ButtonBack from '../../components/common/ButtonBack';
import DetailHeroPanel from '../../components/layouts/common/DetailHeroPanel';
import DetailInfoPanel from '../../components/layouts/common/DetailInfoPanel';
import VerifyAccessRoute from '../../components/common/VerifyAccessRoute';
import AdminDetailInfo from '../../components/common/admin/AdminDetailInfo';
import ShareIcon from '../../components/common/ShareIcon';
import CustomSnackBar from "../../components/common/admin/CustomSnackBar";
import ScreenLoading from "../../components/common/ScreenLoading";

// Custom Hooks
import useUserDisplaytime from '../../hooks/useUserDisplaytime';
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";

// Redux Actions
import { showEvent, toggleFormEvent } from '../../redux/actions/events';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';

// Types
interface ParamTypes {
    id: string
}

function EventDetail() {

    // Translate
    const { t } = useTranslation(["global"]);

    //Redux Hooks
    const dispatch = useDispatch();
    const { id } = useParams<ParamTypes>();
    const { event_selected, showForm, error, msg, loading } = useSelector((state: IRootState) => state.event);

    const location = useLocation();

    // Send track of user
    useUserDisplaytime({ foreign: event_selected });
    const { handleViewGeneralSummaryReport } = useGeneralSummaryReport([], 0, 4);

    // Ready Page
    useEffect(() => {
        handleViewGeneralSummaryReport(parseInt(id))
        dispatch(showEvent(id))
    }, [id]);

    let infoDetail = (event_selected != null) && {
        "item": [
            {
                "id": 1,
                "type": "date",
                "title": `${t("start-date")}`,
                "color": "grey6",
                "info": `${moment(event_selected.date_ini).format('LLL')}`,
            },
            {
                "id": 2,
                "type": "date",
                "title": `${t("end-date")}`,
                "color": "grey6",
                "info": `${moment(event_selected.date_end).format('LLL')}`,
            },
        ]
    }

    return (

        <>
            {(event_selected != null) &&
                <Container maxWidth="lg">

                    {(showForm) &&
                        <EventsModal />
                    }

                    <ButtonBack />

                    <Grid container spacing={5}>

                        <Grid item xs={12} md={8}>
                            <VerifyAccessRoute visibility={event_selected.visibility} />

                            {location.pathname.includes('admin') &&
                                <AdminDetailInfo resourceSelected={event_selected} />
                            }

                            <EventTemplate event={event_selected} />

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <DetailHeroPanel
                                visibility={event_selected.visibility}
                                status={event_selected.status}
                                brand={event_selected.brand_id}
                                title={event_selected.title}
                                author={event_selected.created_by}
                                description={event_selected.description}
                                edit={() => { dispatch(toggleFormEvent()) }}
                                event={event_selected.link}
                                eventText={(event_selected.text_button != null) ? event_selected.text_button : t("admin.events.join-here")}
                                share={<ShareIcon
                                    link={`${window.location.href}`}
                                    visibility={event_selected.visibility}
                                    description={event_selected.description}
                                    multimedia={event_selected.image}
                                    title={event_selected.title}
                                    type="event"
                                    component="buttonTextGreen"
                                    buttonTextGreen={t("share")}
                                />}
                            />
                            <DetailInfoPanel attributes={infoDetail} />
                        </Grid>

                    </Grid>

                </Container>
            }

            <CustomSnackBar errors={[error]} msgs={[msg]} />
            <ScreenLoading loadings={[loading]} />
        </>

    );
}

export default EventDetail;
