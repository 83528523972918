import { IFamilyState, START_GET_FAMILIES, SUCCESS_GET_FAMILIES, ERROR_GET_FAMILIES } from '../types';
    
const initialState: IFamilyState = {
    families: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_FAMILIES:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_FAMILIES:
        return{
            ...state,
            families: action.payload.families,
            msg: null,
            error:false,
            loading: false
        }
        case ERROR_GET_FAMILIES:
        return{
            ...state,
            families: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}