import { 
    IUserState,
    START_FILTER_USERS, SUCCESS_FILTER_USERS, ERROR_FILTER_USERS,
    START_LINK_USERS, SUCCESS_LINK_USERS, ERROR_LINK_USERS,
    START_STORE_USER, SUCCESS_STORE_USER, ERROR_STORE_USER,
    START_UPDATE_USER, SUCCESS_UPDATE_USER, ERROR_UPDATE_USER,
    START_SHOW_USER, SUCCESS_SHOW_USER, ERROR_SHOW_USER,
    START_SHOW_DEPENDENTS, SUCCESS_SHOW_DEPENDENTS, ERROR_SHOW_DEPENDENTS,
    START_GET_NOT_DEPENDENTS, SUCCESS_GET_NOT_DEPENDENTS, ERROR_GET_NOT_DEPENDENTS,
    START_SHOW_PROFILE_USER, SUCCESS_SHOW_PROFILE_USER, ERROR_SHOW_PROFILE_USER,
    START_TRAININGRESPONSIBLE_USERS, SUCCESS_TRAININGRESPONSIBLE_USERS, ERROR_TRAININGRESPONSIBLE_USERS,
    START_DELETE_USER, SUCCESS_DELETE_USER, ERROR_DELETE_USER,
    START_RESTORE_USER, SUCCESS_RESTORE_USER, ERROR_RESTORE_USER,
    START_ASSIGN_BRAND, SUCCESS_ASSIGN_BRAND, ERROR_ASSIGN_BRAND,
    START_ASSIGN_VIP_CONTENT, SUCCESS_ASSIGN_VIP_CONTENT, ERROR_ASSIGN_VIP_CONTENT,
    START_SHOW_VIP_CONTENT, SUCCESS_SHOW_VIP_CONTENT, ERROR_SHOW_VIP_CONTENT,
    START_DISABLE_VIP_CONTENT, SUCCESS_DISABLE_VIP_CONTENT, ERROR_DISABLE_VIP_CONTENT,
    START_REQUEST_VIP_CONTENT, SUCCESS_REQUEST_VIP_CONTENT, ERROR_REQUEST_VIP_CONTENT,
    START_DENY_VIP_CONTENT, SUCCESS_DENY_VIP_CONTENT, ERROR_DENY_VIP_CONTENT,
    START_ENABLE_USER, SUCCESS_ENABLE_USER, ERROR_ENABLE_USER,
    START_DISABLE_USER, SUCCESS_DISABLE_USER, ERROR_DISABLE_USER,
    START_ENABLE_DISABLE_MENU_USER, SUCCESS_ENABLE_DISABLE_MENU_USER, ERROR_ENABLE_DISABLE_MENU_USER,
    START_TOGGLE_FORM_USERS, SUCCESS_TOGGLE_FORM_USERS,
    START_TOGGLE_FORM_LINK_USERS, SUCCESS_TOGGLE_FORM_LINK_USERS,
    START_TOGGLE_PROFILE_USER, SUCCESS_TOGGLE_PROFILE_USER,
    START_REGISTER_USER, SUCCESS_REGISTER_USER, ERROR_REGISTER_USER,
    START_VERIFY_COMMERCIALCODE, SUCCESS_VERIFY_COMMERCIALCODE, ERROR_VERIFY_COMMERCIALCODE,
    START_VERIFY_STATUS_USER, SUCCESS_VERIFY_STATUS_USER, ERROR_VERIFY_STATUS_USER,
    START_SETTING_USER_HIERARCHY, SUCCESS_SETTING_USER_HIERARCHY, ERROR_SETTING_USER_HIERARCHY,
    START_GET_MASSIVELY_ASSIGN, SUCCESS_GET_MASSIVELY_ASSIGN, ERROR_GET_MASSIVELY_ASSIGN,
    START_SET_MASSIVELY_ASSIGN, SUCCESS_SET_MASSIVELY_ASSIGN, ERROR_SET_MASSIVELY_ASSIGN,
    START_USER_CHANGE_PASSWORD, SUCCESS_USER_CHANGE_PASSWORD, ERROR_USER_CHANGE_PASSWORD, START_USER_REGISTRATIONS, ERROR_USER_REGISTRATIONS, SUCCESS_USER_REGISTRATIONS
} from '../types';

const initialState: IUserState = {
    users: [],
    dependents: [],
    notdependents: [],
    inscriptions: [],
    userSelected: null,
    userProfileSelected: null,
    vipContentSelected: null,
    massivelyAssignData: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false,
    showLinkForm: false,
    reload: false,
    code: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    let tmpUserProfile = state.userProfileSelected;
    switch(action.type) {
        case START_FILTER_USERS:
        case START_STORE_USER:
        case START_LINK_USERS:
        case START_UPDATE_USER:
        case START_SHOW_USER:
        case START_SHOW_DEPENDENTS:
        case START_GET_NOT_DEPENDENTS:
        case START_SHOW_PROFILE_USER:
        case START_TRAININGRESPONSIBLE_USERS:
        case START_DELETE_USER:
        case START_RESTORE_USER:
        case START_ASSIGN_BRAND:
        case START_ASSIGN_VIP_CONTENT:
        case START_SHOW_VIP_CONTENT:
        case START_DISABLE_VIP_CONTENT:
        case START_REQUEST_VIP_CONTENT:
        case START_DENY_VIP_CONTENT:
        case START_ENABLE_USER:
        case START_DISABLE_USER:
        case START_ENABLE_DISABLE_MENU_USER:
        case START_TOGGLE_FORM_USERS:
        case START_TOGGLE_FORM_LINK_USERS:
        case START_TOGGLE_PROFILE_USER:
        case START_REGISTER_USER:
        case START_VERIFY_COMMERCIALCODE:
        case START_VERIFY_STATUS_USER:
        case START_SETTING_USER_HIERARCHY:
        case START_GET_MASSIVELY_ASSIGN:
        case START_SET_MASSIVELY_ASSIGN:
        case START_USER_CHANGE_PASSWORD:
        case START_USER_REGISTRATIONS:
            return{
                ...state,
                msg: null,
                error: false,
                loading: true,
                reload: false,
            }
        case SUCCESS_FILTER_USERS:
        case SUCCESS_TRAININGRESPONSIBLE_USERS:
            return{
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false,
                users: action.payload.users
            }
        case SUCCESS_LINK_USERS:
            return{
                ...state,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false,
                showLinkForm: false,
                reload: true
            }
        case SUCCESS_STORE_USER:
            return{
                ...state,
                users: action.payload.user.reload ? [...state.users, action.payload.user] : state.users,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false
            }
        case SUCCESS_UPDATE_USER:
            return {
                ...state,
                users: state.users.map((user: any) => 
                    user.id === action.payload.user.id ? action.payload.user : user 
                ),
                userSelected: null,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false,
                reload: true
            }
        case SUCCESS_SHOW_USER:
            return {
                ...state,
                userSelected: action.payload.user,
                error: false,
                loading: false,
                showForm: true,
                msg: null
            }
        case SUCCESS_SHOW_DEPENDENTS:
            return {
                ...state,
                dependents: action.payload.dependents,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_USER_REGISTRATIONS:
            return {
                ...state,
                inscriptions: action.payload.inscriptions,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_GET_NOT_DEPENDENTS:
            return {
                ...state,
                notdependents: action.payload.notdependents,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_SHOW_PROFILE_USER:
            return {
                ...state,
                userProfileSelected: action.payload.user,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_DELETE_USER:
            return{
                ...state,
                users: state.users.filter((user: any) => user.id != action.payload.user_id),
                dependents: state.dependents.filter((user: any) => user.id != action.payload.user_id),
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_RESTORE_USER:
            return{
                ...state,
                users: state.users.map((user: any) => {
                    if(user.id == action.payload.user_id)
                        user.deleted_at = null

                    return user;
                }),
                dependents: state.dependents.filter((user: any) => user.id != action.payload.user_id),
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_ASSIGN_BRAND:
        case SUCCESS_ASSIGN_VIP_CONTENT:
        case SUCCESS_DISABLE_VIP_CONTENT:
            tmpUserProfile.brands = action.payload.user.brands;
            tmpUserProfile.brandsIds = action.payload.user.brandsIds;
            tmpUserProfile.brandlines = action.payload.user.brandlines;
            tmpUserProfile.brandlinesIds = action.payload.user.brandlinesIds;
            return{
                ...state,
                userProfileSelected: tmpUserProfile,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_REQUEST_VIP_CONTENT:
            tmpUserProfile.brands = action.payload.user.brands;
            tmpUserProfile.brandsIds = action.payload.user.brandsIds;
            tmpUserProfile.brandlines = action.payload.user.brandlines;
            tmpUserProfile.brandlinesIds = action.payload.user.brandlinesIds;
            return{
                ...state,
                userProfileSelected: tmpUserProfile,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_DENY_VIP_CONTENT:
            tmpUserProfile.brands = action.payload.user.brands;
            tmpUserProfile.brandsIds = action.payload.user.brandsIds;
            tmpUserProfile.brandlines = action.payload.user.brandlines;
            tmpUserProfile.brandlinesIds = action.payload.user.brandlinesIds;
            return{
                ...state,
                userProfileSelected: tmpUserProfile,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_ENABLE_USER:
        case SUCCESS_DISABLE_USER:
        case SUCCESS_USER_CHANGE_PASSWORD:
            return{
                ...state,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_ENABLE_DISABLE_MENU_USER:
            tmpUserProfile.menus = action.payload.user.menus;
            tmpUserProfile.menusIds = action.payload.user.menusIds;
            return {
                ...state,
                userProfileSelected: tmpUserProfile,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_SHOW_VIP_CONTENT:
            return{
                ...state,
                vipContentSelected: action.payload.vipContent,
                error: false,
                loading: false,
                msg: null
            }
        case SUCCESS_TOGGLE_FORM_USERS:
            return{
                ...state,
                showForm: !state.showForm,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_LINK_USERS:
            return{
                ...state,
                showLinkForm: !state.showLinkForm,
                loading: false
            }
        case SUCCESS_TOGGLE_PROFILE_USER:
            return{
                ...state,
                userProfileSelected: null,
                loading: false
            }
        case SUCCESS_REGISTER_USER:
            return{
                ...state,
                userSelected: action.payload.user,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_VERIFY_COMMERCIALCODE:
            return{
                ...state,
                code: action.payload.code,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_VERIFY_STATUS_USER:
            let tmpUserProfileSelected = {...state.userProfileSelected};
            tmpUserProfileSelected.verification = action.payload.user.verification;
            tmpUserProfileSelected.status = action.payload.user.status;
            return{
                ...state,
                userProfileSelected: tmpUserProfileSelected,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_SETTING_USER_HIERARCHY:
            tmpUserProfile.parents = action.payload.user.parents;
            return {
                ...state,
                userProfileSelected: tmpUserProfile,
                error: false,
                loading: false,
                msg: action.payload.msg
            }
        case SUCCESS_GET_MASSIVELY_ASSIGN:
            return {
                ...state,
                massivelyAssignData: action.payload.massivelyassign,
                error: false,
                loading: false
            }
        case SUCCESS_SET_MASSIVELY_ASSIGN:
            return {
                ...state,
                massivelyAssignData: null,
                error: false,
                loading: false,
                reload: true
            }
        case ERROR_STORE_USER:
        case ERROR_LINK_USERS:
        case ERROR_FILTER_USERS:
        case ERROR_UPDATE_USER:
        case ERROR_SHOW_USER:
        case ERROR_SHOW_DEPENDENTS:
        case ERROR_GET_NOT_DEPENDENTS:
        case ERROR_SHOW_PROFILE_USER:
        case ERROR_TRAININGRESPONSIBLE_USERS:
        case ERROR_DELETE_USER:
        case ERROR_RESTORE_USER:
        case ERROR_ASSIGN_BRAND:
        case ERROR_SHOW_VIP_CONTENT:
        case ERROR_ASSIGN_VIP_CONTENT:
        case ERROR_DISABLE_VIP_CONTENT:
        case ERROR_REQUEST_VIP_CONTENT:
        case ERROR_DENY_VIP_CONTENT:
        case ERROR_ENABLE_USER:
        case ERROR_DISABLE_USER:
        case ERROR_ENABLE_DISABLE_MENU_USER:
        case ERROR_REGISTER_USER:
        case ERROR_VERIFY_COMMERCIALCODE:
        case ERROR_VERIFY_STATUS_USER:
        case ERROR_SETTING_USER_HIERARCHY:
        case ERROR_GET_MASSIVELY_ASSIGN:
        case ERROR_SET_MASSIVELY_ASSIGN:
        case ERROR_USER_CHANGE_PASSWORD:
        case ERROR_USER_REGISTRATIONS:
            return{
                ...state,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}