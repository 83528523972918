// React
import { useDispatch } from 'react-redux'

// MUI
import { Tooltip, Button } from '@material-ui/core';
import { RefreshRounded } from '@material-ui/icons';
import cslx from "clsx";

// Redux Actions
import { clearStorage as actionClearStorage } from '../../../redux/actions/auth';

// Custom Style
import HeaderStyles from '../../../styles/components/common/header/HeaderStyles';

// Libraries
import { useTranslation } from "react-i18next";

function ResetButton(admin: any) {

  // Redux Hooks
  const dispatch = useDispatch();

  // Styles
  const classes = HeaderStyles();

  // Translate
  const { t } = useTranslation(["global"]);

  /** Functions */
  const handleClick = () => {
    dispatch(actionClearStorage());
  }

  return (
    <Tooltip title={`${t("clear-cache")}`}>
      <Button variant="text" color="default"
        className={cslx(classes.buttonURL, admin ? "admin" : "")}
        startIcon={<RefreshRounded className={classes.lightIcon} style={{ fontSize: 20 }} />}
        aria-controls="share-link-menu"
        aria-haspopup="false"
        onClick={handleClick}>
        {t("restart")}
      </Button>
    </Tooltip>
  )
}

export default ResetButton