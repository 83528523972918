// React
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Material UI
import { Container, Typography, Button, Box, makeStyles, createStyles, Theme, Divider } from '@material-ui/core';

// Custom Components
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import ButtonBack from '../../../components/common/ButtonBack';
import CustomChip from '../../../components/common/admin/CustomChip';
import TokentModal from '../../../components/integrations/TokenModal';

// Redux Actions
import { IRootState } from "../../../redux/types";
import { getIntegrations, showIntegration } from '../../../redux/actions/integrations';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Custom Styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        welcomeText: {
            paddingTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(4),
                paddingBottom: theme.spacing(4),
                paddingTop: 0,
            },
        },
        buttonOption: {
            padding: '0px 32px',
        },
        buttonOptionApi: {
            color: '#FF4A00',
            backgroundColor: theme.palette.warning.light,
            border: '1px solid #FF4A00',
            height: 110,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                maxWidth: 292,
            },
            "&:hover": {
                border: 'transparent',
                backgroundColor: '#FF4A00',
                color: theme.palette.common.white,
            },
            '& h6': {
                marginRight: 16,
            },
        },
    }),
);


function IntegrationsAPI() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Redux Hooks
    const dispatch = useDispatch();
    const { integrations, showForm } = useSelector((state: IRootState) => state.integration);

    //Styles
    const classes = useStyles();

    useEffect(() => {
        dispatch(getIntegrations());
    }, []);

    return (
        <>
            <Header title='Integraciones' />
            <SubHeader />

            <Container >
                <ButtonBack />
                <Box display="flex" alignItems="center" className={classes.welcomeText}>
                    <Divider style={{ flexShrink: 1 }} />
                    <Box className={classes.buttonOption}>
                        <Typography variant="h6" style={{ minWidth: 'fit-content' }}>API</Typography>
                    </Box>
                    <Divider style={{ flexShrink: 1 }} />
                </Box>

                {integrations.map((integration: any) => (
                    <Box key={integration.id} mb={25} gridGap={isMobile ? 24 : 48} flexWrap="wrap">
                        <Button
                            onClick={() => { dispatch(showIntegration(integration.id)) }}
                            variant='contained'
                            className={classes.buttonOptionApi}>
                            <Typography variant='subtitle1'>{integration.name}</Typography>
                            {integration.status ? <CustomChip type={1} status={0} /> : <CustomChip type={1} status={1} />}
                        </Button>
                    </Box>
                ))}

                {showForm &&
                    <TokentModal stateModal={showForm} />
                }
            </Container>
        </>

    );
}

export default IntegrationsAPI;
