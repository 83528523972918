import { 
    START_GET_NOTIFICATIONS,
    START_GET_UNREAD_NOTIFICATIONS,
    START_SET_NOTIFICATION_AS_READ,
    START_SET_ALL_NOTIFICATIONS_AS_READ
} from '../types';
    
export const getNotifications = () => ({
    type: START_GET_NOTIFICATIONS
})

export const getUnreadNotifications = () => ({
    type: START_GET_UNREAD_NOTIFICATIONS
})

export const setNotificationAsRead = (payload) => ({
    type: START_SET_NOTIFICATION_AS_READ,
    payload
})

export const setAllNotificationsAsRead = () => ({
    type: START_SET_ALL_NOTIFICATIONS_AS_READ
})