// React
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'

// Redux Actions
import { clearStorage } from '../redux/actions/auth';

// Material UI
import { CircularProgress, Backdrop, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Clear() {
    // Redux Hooks
    const dispatch = useDispatch();

    const classes = useStyles();

    // Ready Page
    useEffect(() => {
        dispatch(clearStorage());
    }, [])

    return (
        <>
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default Clear;
