import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";

export default makeStyles((theme) =>
    createStyles({
        containerCover: {

            borderRadius: 16,
            display: 'block',
            margin: '0 auto',
            height: '70vh',
            marginTop: '30px',
            [theme.breakpoints.up('md')]: {
                maxWidth: '800px',
            },
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
            },
            /* backgroundSize: 'cover',
            backgroundColor: 'transparent',*/
        },
        startIcon: {
            margin: 0,
        },
        content: {
            [theme.breakpoints.up('md')]: {
                left: "90%",
                marginTop: "-14px",
            },
            [theme.breakpoints.down('md')]: {
                left: "8%",
            },
            marginBottom: 24,
            top: 0,
            position: 'relative',
        },
        contained: {
            borderRadius: 4,
        },
        nameResource: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: 240,
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 170,
            },
        },
        share: {
            '& button': {
                transition: theme.transitions.create('width'),
                transitionDelay: '0ms',
                padding: 0,
                width: '0ch',
                '& svg': {
                    transition: theme.transitions.create('color'),
                    transitionDelay: '0ms',
                    color: 'transparent',
                },
            },
            [theme.breakpoints.up('sm')]: {
                '&:hover': {
                    '& button': {
                        marginLeft: 10,
                        transitionDelay: '400ms',
                        width: '2ch',
                        '& svg': {
                            transitionDelay: '400ms',
                            color: theme.palette.primary.main,
                        },
                    }
                },
            },
            '& .MuiButton-label': {
                justifyContent: 'center',
            }
        },
    }));