// Libraries
import { useTranslation } from "react-i18next";

// Types
export interface SectionData {
    client: SectionName[];
    comercial: SectionName[];
    admin: SectionName[];
    responsible: SectionName[];
}

export interface SectionName {
    "about-plei"?: SectionContent;
    profile?: SectionContent;
    section?: SectionContent;
    library?: SectionContent;
    academy?: SectionContent;
    events?: SectionContent;
    basic?: SectionContent;  
    offers?: SectionContent;
    slider?: SectionContent;
    clients?: SectionContent;
    people?: SectionContent;
    panel?: SectionContent;
    content?: SectionContent;   
    data?: SectionContent;   
    brands?: SectionContent;    
  
}

export interface SectionContent {
    title: string;
    content: Content[];
}

export interface Content {
    question: string;
    answer: string[] | string;
}

const useSectionData = () => {

    // Translation
    const { t } = useTranslation(["helpcenter"]);

    const data: SectionData = {
        "client": [
            {
                "about-plei": {
                    "title": t("client.about-plei.title"),
                    "content": [
                        {
                            "question": t("client.about-plei.q1"),
                            "answer": [
                                t("client.about-plei.a1-p1"),
                            ]
                        },
                        {
                            "question": t("client.about-plei.q2"),
                            "answer": [
                                t("client.about-plei.a2-p1")
                            ]

                        },
                        {
                            "question": t("client.about-plei.q3"),
                            "answer": [
                                t("client.about-plei.a3-p1")
                            ]

                        },
                        {
                            "question": t("client.about-plei.q4"),
                            "answer": [
                                t("client.about-plei.a4-p1")
                            ]

                        },
                        {
                            "question": t("client.about-plei.q5"),
                            "answer": [
                                t("client.about-plei.a5-p1")
                            ]

                        }
                       
                    ]
                }
            },
            {
                "profile": {
                    "title": t("client.profile.title"),
                    "content": [
                        {
                            "question": t("client.profile.q1"),
                            "answer": [
                                t("client.profile.a1-p1"),
                            ]
                        },
                        {
                            "question": t("client.profile.q2"),
                            "answer": [
                                t("client.profile.a2-p1")
                            ]

                        },
                        {
                            "question": t("client.profile.q3"),
                            "answer": [
                                t("client.profile.a3-p1")
                            ]

                        }
                      
                       
                    ]
                }
            },
            {
                "library": {
                    "title": t("client.library.library"),
                    "content": [
                        {
                            "question": t("client.library.q1"),
                            "answer": [
                                t("client.library.a1-p1")
                            ]

                        },
                        {
                            "question": t("client.library.q2"),
                            "answer": [
                                t("client.library.a2-p1")
                            ]

                        },
                        {
                            "question": t("client.library.q3"),
                            "answer": [
                                t("client.library.a3-p1")
                            ]

                        }
                    ]
                }
            },
            {
                "academy": {
                    "title": t("client.academy.academy"),
                    "content": [
                        {
                            "question": t("client.academy.q1"),
                            "answer": [
                                t("client.academy.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.academy.q2"),
                            "answer": [
                                t("client.academy.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.academy.q3"),
                            "answer": [
                                t("client.academy.a3-p1")
                            ]
                        },
                        
                      
                    ]
                }
            },
            {
                "events": {
                    "title": t("client.events.events"),
                    "content": [
                        {
                            "question": t("client.events.q1"),
                            "answer": [
                                t("client.events.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.events.q2"),
                            "answer": [
                                t("client.events.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.events.q3"),
                            "answer": [
                                t("client.events.a3-p1")
                            ]
                        },
                       
                    ]
                }
            },
            {
                "basic": {
                    "title": t("client.basic.title"),
                    "content": [
                        {
                            "question": t("client.basic.q1"),
                            "answer": [
                                t("client.basic.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.basic.q2"),
                            "answer": [
                                t("client.basic.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.basic.q3"),
                            "answer": [
                                t("client.basic.a3-p1")
                            ]
                        },
                        {
                            "question": t("client.basic.q4"),
                            "answer": [
                                t("client.basic.a4-p1")
                            ]
                        },
                       
                    ]
                }
            },
           
        ],
        "comercial": [  
            {
                "panel": {
                    "title": t("client.panel.title"),
                    "content": [
                        {
                            "question": t("client.panel.q1"),
                            "answer": [
                                t("client.panel.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.panel.q2"),
                            "answer": [
                                t("client.panel.a2-p1")
                            ]
                        },
                        
                      
                    ]
                }
            },
            {
                "clients": {
                    "title": t("client.clients.title"),
                    "content": [
                        {
                            "question": t("client.clients.q1"),
                            "answer": [
                                t("client.clients.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q2"),
                            "answer": [
                                t("client.clients.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q3"),
                            "answer": [
                                t("client.clients.a3-p1"),
                                t("client.clients.a3-p2"),
                                t("client.clients.a3-p3")
                            ]
                        },
                        {
                            "question": t("client.clients.q4"),
                            "answer": [
                                t("client.people.a4-p1")
                            ]
                        },
                        
                      
                    ]
                }
            },
        ],
        "responsible": [  
            {
                "panel": {
                    "title": t("client.panel.title"),
                    "content": [
                        {
                            "question": t("client.panel.q1"),
                            "answer": [
                                t("client.panel.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.panel.q4"),
                            "answer": [
                                t("client.panel.a4-p1")
                            ]
                        },
                        {
                            "question": t("client.panel.q2"),
                            "answer": [
                                t("client.panel.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.panel.q3"),
                            "answer": [
                                t("client.panel.a3-p1")
                            ]
                        },
                        
                      
                    ]
                }
            },
            {
                "clients": {
                    "title": t("client.clients.title"),
                    "content": [
                        {
                            "question": t("client.clients.q1"),
                            "answer": [
                                t("client.clients.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q2"),
                            "answer": [
                                t("client.clients.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q3"),
                            "answer": [
                                t("client.clients.a3-p1"),
                                t("client.clients.a3-p2"),
                                t("client.clients.a3-p3")
                            ]
                        },
                        {
                            "question": t("client.clients.q4"),
                            "answer": [
                                t("client.clients.a4-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q5"),
                            "answer": [
                                t("client.clients.a5-p1"),
                                t("client.clients.a5-p2")
                            ]
                        },
                        {
                            "question": t("client.clients.q6"),
                            "answer": [
                                t("client.clients.a6-p1")
                            ]
                        },
                       
                    ]
                },
            },
            {
                "people": {
                    "title": t("client.people.title"),
                    "content": [
                        {
                            "question": t("client.people.q1"),
                            "answer": [
                                t("client.people.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q2"),
                            "answer": [
                                t("client.people.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q3"),
                            "answer": [
                                t("client.people.a3-p1"),
                            ]
                        },
                        {
                            "question": t("client.people.q4"),
                            "answer": [
                                t("client.people.a4-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q5"),
                            "answer": [
                                t("client.people.a5-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q6"),
                            "answer": [
                                t("client.people.a6-p1")
                            ]
                        },
                       
                    ]
                }
                
            },
        ],
        "admin": [  
            {
                "panel": {
                    "title": t("client.panel.title"),
                    "content": [
                        {
                            "question": t("client.panel.q1"),
                            "answer": [
                                t("client.panel.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.panel.q4"),
                            "answer": [
                                t("client.panel.a4-p1")
                            ]
                        },
                        {
                            "question": t("client.panel.q2"),
                            "answer": [
                                t("client.panel.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.panel.q3"),
                            "answer": [
                                t("client.panel.a3-p1")
                            ]
                        },
                        
                      
                    ]
                }
            },
            {
                "clients": {
                    "title": t("client.clients.title"),
                    "content": [
                        {
                            "question": t("client.clients.q1"),
                            "answer": [
                                t("client.clients.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q2"),
                            "answer": [
                                t("client.clients.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q3"),
                            "answer": [
                                t("client.clients.a3-p1"),
                                t("client.clients.a3-p2"),
                                t("client.clients.a3-p3")
                            ]
                        },
                        {
                            "question": t("client.clients.q4"),
                            "answer": [
                                t("client.clients.a4-p1")
                            ]
                        },
                        {
                            "question": t("client.clients.q5"),
                            "answer": [
                                t("client.clients.a5-p1"),
                                t("client.clients.a5-p2")
                            ]
                        },
                        {
                            "question": t("client.clients.q6"),
                            "answer": [
                                t("client.clients.a6-p1")
                            ]
                        },
                       
                    ]
                },
            },
            {
                "people": {
                    "title": t("client.people.title"),
                    "content": [
                        {
                            "question": t("client.people.q1"),
                            "answer": [
                                t("client.people.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q2"),
                            "answer": [
                                t("client.people.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q3"),
                            "answer": [
                                t("client.people.a3-p1"),
                            ]
                        },
                        {
                            "question": t("client.people.q4"),
                            "answer": [
                                t("client.people.a4-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q5"),
                            "answer": [
                                t("client.people.a5-p1")
                            ]
                        },
                        {
                            "question": t("client.people.q6"),
                            "answer": [
                                t("client.people.a6-p1")
                            ]
                        },
                       
                    ]
                }
                
            },
            {
                "content": {
                    "title": t("client.content.title"),
                    "content": [
                        {
                            "question": t("client.content.q1"),
                            "answer": [
                                t("client.content.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.content.q2"),
                            "answer": [
                                t("client.content.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.content.q3"),
                            "answer": [
                                t("client.content.a3-p1")
                            ]
                        },
                        {
                            "question": t("client.content.q4"),
                            "answer": [
                                t("client.content.a4-p1")
                            ]
                        },
                        {
                            "question": t("client.content.q5"),
                            "answer": [
                                t("client.content.a5-p1"),
                                t("client.content.a5-p2")
                            ]
                        },
                        
                      
                    ]
                }
            },
            {
                "data": {
                    "title": t("client.data.title"),
                    "content": [
                        {
                            "question": t("client.data.q1"),
                            "answer": [
                                t("client.data.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.data.q2"),
                            "answer": [
                                t("client.data.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.data.q3"),
                            "answer": [
                                t("client.data.a3-p1")
                            ]
                        },
                        {
                            "question": t("client.data.q4"),
                            "answer": [
                                t("client.data.a4-p1")
                            ]
                        },
                      
                    ]
                }
            },
            {
                "slider": {
                    "title": t("client.slider.title"),
                    "content": [
                        {
                            "question": t("client.slider.q1"),
                            "answer": [
                                t("client.slider.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.slider.q2"),
                            "answer": [
                                t("client.slider.a2-p1")
                            ]
                        },
                        {
                            "question": t("client.slider.q3"),
                            "answer": [
                                t("client.slider.a3-p1")
                            ]
                        },

                   
 
                      
                    ]
                }
            },
            {
                "brands": {
                    "title": t("client.brands.title"),
                    "content": [
                        {
                            "question": t("client.brands.q1"),
                            "answer": [
                                t("client.brands.a1-p1")
                            ]
                        },
                        {
                            "question": t("client.brands.q2"),
                            "answer": [
                                t("client.brands.a2-p1")
                            ]
                        },
 
                      
                    ]
                }
            },
        ],
    };

    return data;
}

export default useSectionData;