import { useState, useEffect } from 'react';

const useScreenWidth = (maxWidth: number): boolean => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < maxWidth);

  const handleResize = (): void => {
    setIsMobile(window.innerWidth < maxWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Limpia el evento del resize cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useScreenWidth;
