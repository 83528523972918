// React
import { useState, MouseEvent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Drawer,
  Typography,
  Box,
  Menu,
  MenuItem,
  Link,
  Tooltip,
  Avatar,
  Divider,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  CloseRounded,
  FavoriteBorder,
  FeedbackRounded,
  HelpOutlineRounded,
  HelpRounded,
  KeyboardArrowRightRounded,
  RefreshRounded,
  SpeedOutlined,
  VpnKey,
  ExitToAppRounded
} from "@material-ui/icons";
import clsx from "clsx";

// Custom Components
import ChangeUserPassword from "./ChangeUserPassword";
import ShareIcon from "../../common/ShareIcon";
import NotificationPanel from "./NotificationPanel";
import ButtonNavbar from "../../layouts/navigation/ButtonNavbar";
import HiddenMobile from "../../common/HiddenMobile";
import HiddenDesktop from "../../common/HiddenDesktop";
import MenuMobileItem from "../navigation/MenuMobileItem";

// Redux
import { IRootState } from "../../../redux/types";
import { logout } from "../../../redux/actions/auth";
import { clearStorage } from "../../../utils/ClearStorage";

//Assets
import drvLogo from "../../../assets/Images/drv/drvLogo.svg";

// Styles
import AccountMenuStyles from "../../../styles/components/layouts/common/AccountMenuStyles";
import GlobalSearch from "../navigation/GlobalSearch";

function AccountMenu(props: any) {
  const { theme } = props;
  const classes = AccountMenuStyles();
  const history = useHistory();

  // Translation
  const { t } = useTranslation(["global"]);

  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state: IRootState) => state.auth);

  // Handle dropdown menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [userPassword, setUserPassword] = useState(false);

  // Handle drawerUser
  const [mobileUserOpen, setMobileUserOpen] = useState(false);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenuMobile = () => {
    if (mobileUserOpen) setMobileUserOpen(false);
  };

  const handleProfileUser = () => {
    if (theme === "client") history.push("/perfil");
    else history.push("/usuarios/perfil");
  };

  let userName =
    authUser !== null ? authUser.name.split(" ") : process.env.REACT_APP_NAME;
  let countWords = userName.length;
  let textAvatar =
    countWords <= 1
      ? `${userName[0].charAt(0).toLocaleUpperCase()}`
      : userName[0] === "(C)" || "(Cc)"
        ? `${userName[0].charAt(0).toLocaleUpperCase()}${userName[1]
          .charAt(0)
          .toLocaleUpperCase()}`
        : `${userName[1].charAt(0).toLocaleUpperCase()}${userName[2]
          .charAt(0)
          .toLocaleUpperCase()}`;

  return (
    <>
      <HiddenMobile display="flex" alignItems="center">
        <Tooltip title={`${t("help")}`}>
          <ButtonNavbar
            className={theme}
            onClick={() => history.push("/ayuda")}
          >
            <HelpRounded />
          </ButtonNavbar>
        </Tooltip>
        {theme === "client" && <NotificationPanel theme={theme} />}
        <Tooltip title={`${t("your-profile-and-options")}`}>
          <ButtonNavbar
            className={theme}
            // aria-controls="primary-search-account-menu"
            // aria-haspopup="true"
            // underline="none"
            onClick={handleProfileMenuOpen}
          >
            <Avatar className={classes.avatarBtn}>{textAvatar}</Avatar>
          </ButtonNavbar>
        </Tooltip>
        {/* <img src={`${drvLogo}`} alt='drv logo' className={clsx(classes.drvLogo, theme === 'client' ? '' : 'admin')} /> */}
        <Menu
          anchorEl={anchorEl}
          id="primary-search-account-menu"
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
        >
          {window.location.pathname !== "/terms" && (
            <div>
              <Box px={2} pb={1}>
                <Typography variant="caption" color="textSecondary">
                  {authUser !== null
                    ? authUser.name
                    : process.env.REACT_APP_NAME}
                </Typography>
              </Box>
              {authUser != null && ![6, 7].includes(authUser.role_id) && (
                <>
                  <Divider light />
                  {theme === "client" ? (
                    <MenuItem component="a" href="/escritorio">
                      {process.env.REACT_APP_NAME} | Admin
                    </MenuItem>
                  ) : (
                    <MenuItem component="a" href="/inicio">
                      {process.env.REACT_APP_NAME} | Cliente
                    </MenuItem>
                  )}
                </>
              )}
              <MenuItem onClick={handleProfileUser}>{t("profile")}</MenuItem>
              <MenuItem component="a" href="/favoritos">
                {t("my-favorites")}
              </MenuItem>
              <MenuItem onClick={() => setUserPassword(!userPassword)}>
                {t("login.change-password")}
              </MenuItem>
            </div>
          )}
          <MenuItem onClick={() => dispatch(logout())}>
            {t("login.logout")}
          </MenuItem>
          <Box display="flex" justifyContent="center" height={24}>
            <img
              src={`${drvLogo}`}
              alt="drv logo"
              className={clsx(
                classes.drvLogo,
                theme === "client" ? "" : "admin"
              )}
            />
          </Box>
        </Menu>
      </HiddenMobile>

      <HiddenDesktop display="flex">
        {theme === "client" && <NotificationPanel theme={theme} />}
        <GlobalSearch />
        <Link
          className={classes.buttonBrand}
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          underline="none"
          style={{ padding: "0px 16px" }}
          onClick={() => setMobileUserOpen(!mobileUserOpen)}
        >
          <Box gridGap={8} alignItems="center" display="flex">
            {/* <AccountCircleRounded color="primary" /> */}
            <MenuIcon fontSize="small" />
          </Box>
        </Link>
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileUserOpen}
          onClose={() => setMobileUserOpen(!mobileUserOpen)}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          <Box display="inline" onClick={handleCloseUserMenuMobile} overflow={'auto'} pb={8}>
            <Box display="flex" mx='12px' mt='68px' mb={3} justifyContent='space-between' onClick={handleProfileUser} >
              <Box display="flex" alignItems="start" >
                <Avatar className={classes.avatarBtnMobile}>{textAvatar}</Avatar>
                <Box display="inline">
                  <Typography variant="body2">{userName}</Typography>
                  <Typography variant="body2">ID: {authUser.id}</Typography>
                  <Typography style={{ color: "#3483FA" }} variant="body2">{t("common.manage-my-account")} {process.env.REACT_APP_NAME}</Typography>
                </Box>
              </Box>
              <Box p='13px'> <KeyboardArrowRightRounded /></Box>
            </Box>
            <IconButton
              onClick={() => setMobileUserOpen(!mobileUserOpen)}
              classes={{ root: classes.btnCloseDrawer }}
            >
              <CloseRounded />
            </IconButton>
            {authUser != null && ![6, 7].includes(authUser.role_id) && (
              <>
                {theme === "client" ? (
                  <MenuMobileItem
                    classes={{ root: theme }}
                    onClick={() => history.push("/escritorio")}
                  >
                    <SpeedOutlined />
                    {t("common.change-to")} {t("common.admin")}
                  </MenuMobileItem>
                ) : (
                  <MenuMobileItem
                    classes={{ root: theme }}
                    onClick={() => history.push("/inicio")}
                  >
                    <SpeedOutlined />
                    {t("common.change-to")} {t("common.client")}
                  </MenuMobileItem>
                )}
                <Divider style={{ marginTop: 12, marginBottom: 12 }} />
              </>
            )}

            <MenuMobileItem
              classes={{ root: "client" }}
              onClick={() => history.push("/favoritos")}
            >
              <FavoriteBorder />
              {t("my-favorites")}
            </MenuMobileItem>
            <ShareIcon
              component="menuItem"
              light={true}
              type="resource"
              visibility={0}
              title={"Bilbioteca"}
              description={"Biblioteca"}
              link={window.location.href}
              multimedia={``}
            />
            <MenuMobileItem
              classes={{ root: "client" }}
              onClick={() => setUserPassword(!userPassword)}
            >
              <VpnKey />
              {t("login.change-password")}
            </MenuMobileItem>
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
            <MenuMobileItem
              classes={{ root: "client" }}
              onClick={clearStorage}
            >
              <RefreshRounded />
              {t("restart")}
            </MenuMobileItem>
            <MenuMobileItem
              classes={{ root: "client" }}
              onClick={() => window.open("https://drv.es/reportar-error-en-plei/")}
            >
              <FeedbackRounded />
              {t("Reportar")}
            </MenuMobileItem>
            <MenuMobileItem
              classes={{ root: "client" }}
              onClick={() => history.push("/ayuda")}
            >
              <HelpOutlineRounded />
              {t("help")}
            </MenuMobileItem>
            <MenuMobileItem
              classes={{ root: "client" }}
              onClick={() => dispatch(logout())}
            >
              <ExitToAppRounded />
              {t("login.logout")}
            </MenuMobileItem>
          </Box>
          <Box className={classes.productBy}>
            <Typography variant="caption"> {t("common.a-product-of")}</Typography>
            <img
              src={String(drvLogo)}
              alt={`Logo de ${process.env.REACT_APP_NAME}`}
              height={18}
            ></img>
          </Box>
        </Drawer>
      </HiddenDesktop>

      <ChangeUserPassword
        showForm={userPassword}
        setShowForm={() => setUserPassword(!userPassword)}
      />
    </>
  );
}

export default AccountMenu;
