// Material UI
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const ScrollSubHeader = styled(Box)(({
        theme
    }) => ({
        maxWidth: 1280,
        margin: '0 auto',
        '& svg':{
            color: theme.palette.primary.main
        },
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            boxSizing: 'border-box',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
            paddingRight: '60px',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar':{
                display: 'none',
            },
        },
    }));

export default ScrollSubHeader;