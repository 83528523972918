import { put, takeLatest } from 'redux-saga/effects';
import { START_FORM_VALIDATOR, SUCCESS_FORM_VALIDATOR, START_RESET_FORM_VALIDATOR, RESET_FORM_VALIDATOR } from '../types';

/*
*  @var key = nombre del campo
*  @var value = valor del input
*  @var strValidator = reglas de validacion
*  @var indexTab = index del tab donde esta el campo
*
*  TODO Cambiar IF por CASE
*/
function* formValidator({ payload }) {
    let errorForm = {};
    let msgForm = {};
    let errorTab = {};
    let msgTab = [];
    let isValidForm = true;
    let objectValidator = payload;
    for (const key in objectValidator) {
        if (objectValidator.hasOwnProperty(key)) {
            const element = objectValidator[key];
            const value = element[0];
            let strValidator = element[1];
            let tabName = element[2];
            const rules = strValidator.split('|');

            errorForm[key] = false;
            msgForm[key] = '';
            for (let j = 0; j < rules.length; j++) {
                let rule = rules[j];

                if (errorForm[key].error)
                    continue;

                if (rule === 'required'){
                    if (value === '' || value === null){
                        errorForm[key] = true;
                        msgForm[key] = `Campo obligatorio.`;
                    }
                }else
                if (value === null)
                    continue;
                else
                if (rule.includes(':')){
                    rule = rule.split(':');
                    if (rule[0] === 'min'){
                        if (value.length < rule[1]){
                            errorForm[key] = true;
                            msgForm[key] = `Se requieren minimo ${rule[1]} caracteres.`;
                        }
                    }else
                    if (rule[0] === 'max'){
                        if (value.length > rule[1]){
                            errorForm[key] = true;
                            msgForm[key] = `Has superado los ${rule[1]} caracteres.`;
                        }
                    }else
                    if (rule[0] === 'equal'){
                        if (value !== rule[1]){
                            errorForm[key] = true;
                            msgForm[key] = `Los campos no coinciden`;
                        }
                    }
                }
                else
                if (rule === 'numeric'){
                    const pattern = /^([0-9])*$/;
                    if (!pattern.test(value)){
                        errorForm[key] = true;
                        msgForm[key] = `Solo números permitidos`;
                    }
                }else
                if (rule === 'email'){
                    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
                    if (!pattern.test(value)){
                        errorForm[key] = true;
                        msgForm[key] = `Formato de email invalido`;
                    }
                }else
                if (rule === 'boolean'){
                    if (value === false){
                        errorForm[key] = true;
                        msgForm[key] = `Campo obligatorio`;
                    }
                }else
                if (rule === 'notempty'){
                    if (value.length === 0){
                        errorForm[key] = true;
                        msgForm[key] = `Campo obligatorio`;
                    }
                }else
                if (rule === 'datemin'){
                    if (value.final < value.initial){
                        errorForm[key] = true;
                        msgForm[key] = `Fecha final debe ser menor a fecha inicial`;
                    }
                }
            }

            if (errorForm[key] === true){
                isValidForm = false;

                // Tab Validation
                if (tabName !== undefined && errorTab[tabName] !== true){
                    errorTab[tabName] = true;
                    msgTab.push('Debes completar adecuadamente '+ tabName);
                }
            }

        }
    }

    payload = {errorForm, errorTab, msgForm, msgTab, isValidForm};
    yield put ({ type: SUCCESS_FORM_VALIDATOR, payload})
}

function* resetFormValidator() {
    yield put ({ type: RESET_FORM_VALIDATOR})
}

export default function* validator() {
    yield takeLatest(START_FORM_VALIDATOR, formValidator);
    yield takeLatest(START_RESET_FORM_VALIDATOR, resetFormValidator);
}