import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_LOGIN, SUCCESS_LOGIN, ERROR_LOGIN,
    START_VERIFY_USER, SUCCESS_VERIFY_USER, ERROR_VERIFY_USER, ERROR_REQUEST_VERIFY_USER,
    START_LOGOUT_USER, SUCCESS_LOGOUT_USER, ERROR_LOGOUT_USER,
    START_REMEMBER_PASSWORD, SUCCESS_REMEMBER_PASSWORD, ERROR_REMEMBER_PASSWORD,
    SUCCESS_RESET_PASSWORD, ERROR_RESET_PASSWORD, START_RESET_PASSWORD,
    SUCCESS_CHANGE_PASSWORD, ERROR_CHANGE_PASSWORD, START_CHANGE_PASSWORD,
    START_SET_GLOBAL_FAMILY, START_SET_GLOBAL_BRAND, START_SET_GLOBAL_LANGUAGE, START_SET_GLOBAL_COUNTRY,
    SUCCESS_CLEAR_STORAGE, START_CLEAR_STORAGE, ERROR_CLEAR_STORAGE,
} from '../types';
import { apiCall, apiCallNoApi } from '../config/axios';


function* loginUser({ payload }) {
    try {
        const csrf = yield call(apiCallNoApi, 'get', '/sanctum/csrf-cookie')
        const response = yield call(apiCall, 'post', '/auth/login', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_LOGIN, payload: response.data })
        yield put({ type: START_SET_GLOBAL_FAMILY, payload: null })
        yield put({ type: START_SET_GLOBAL_BRAND, payload: null })
        yield put({ type: START_SET_GLOBAL_LANGUAGE, payload: response.data.global_language_id })
        yield put({ type: START_SET_GLOBAL_COUNTRY, payload: response.data.global_country_id })
    } catch (error) {
        yield put({ type: ERROR_LOGIN, payload: error.message })
    }
}

function* logoutUser() {
    try {
        const response = yield call(apiCall, 'post', '/auth/logout', null)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_LOGOUT_USER, payload: response.data })
        localStorage.removeItem('accept_terms')
        window.location.reload();
    } catch (error){
        yield put ({ type: ERROR_LOGOUT_USER, payload: error.message})
    }
}

function* verifyUser() {
    try {
        if (!localStorage.getItem('isAuth'))
            throw new Error("Usuario no autenticado");

        const response = yield call(apiCall, 'post', '/auth/me', null)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_VERIFY_USER, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_VERIFY_USER, payload: error.message })
    }
}

function* rememberPassword({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/auth/rememberpassword', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_REMEMBER_PASSWORD, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_REMEMBER_PASSWORD, payload: error.message })
    }
}

function* resetPassword({ payload }) {
    try {
        const csrf = yield call(apiCallNoApi, 'get', '/sanctum/csrf-cookie')
        const response = yield call(apiCall, 'post', '/auth/resetpassword', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_RESET_PASSWORD, payload: response.data })
        yield put({ type: START_SET_GLOBAL_FAMILY, payload: null })
        yield put({ type: START_SET_GLOBAL_BRAND, payload: null })
        yield put({ type: START_SET_GLOBAL_LANGUAGE, payload: response.data.global_language_id })
        yield put({ type: START_SET_GLOBAL_COUNTRY, payload: response.data.global_country_id })
    } catch (error) {
        yield put({ type: ERROR_RESET_PASSWORD, payload: error.message })
    }
}

function* changePassword({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/auth/changepassword', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CHANGE_PASSWORD, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CHANGE_PASSWORD, payload: error.message })
    }
}

function* clearStorage() {
    try {
        const fullPath = `${window.location.href}${window.location.search}`;
        const response = yield call(apiCall, 'get', `/auth/clearstorage?fromUrl=${fullPath}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CLEAR_STORAGE, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CLEAR_STORAGE, payload: error.message })
    }
}

export default function* auth() {
    yield takeLatest(START_LOGIN, loginUser);
    yield takeLatest(START_VERIFY_USER, verifyUser);
    yield takeLatest(START_LOGOUT_USER, logoutUser);
    yield takeLatest(START_REMEMBER_PASSWORD, rememberPassword);
    yield takeLatest(START_RESET_PASSWORD, resetPassword);
    yield takeLatest(START_CHANGE_PASSWORD, changePassword);
    yield takeLatest(START_CLEAR_STORAGE, clearStorage);
}