import { combineReducers } from 'redux';
import brandReducer from './brandReducer';
import courseReducer from './courseReducer';
import coursetypeReducer from './coursetypeReducer';
import roleReducer from './roleReducer';
import languageReducer from './languageReducer';
import lessonReducer from './lessonReducer';
import countryReducer from './countryReducer';
import validatorReducer from './validatorReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import resourceReducer from './resourceReducer';
import resourcetypeReducer from './resourcetypeReducer';
import resourcecategoryReducer from './resourcecategoryReducer';
import termReducer from './termReducer';
import sliderReducer from './sliderReducer';
import modalReducer from './modalReducer';
import eventReducer from './eventReducer';
import globalReducer from './globalReducer';
import offerReducer from './offerReducer';
import logReducer from './logReducer';
import tagReducer from './tagReducer';
import noveltyReducer from './noveltyReducer';
import usersettingReducer from './usersettingReducer';
import menuReducer from './menuReducer';
import helpReducer from './helpReducer';
import familyReducer from './familyReducer';
import testimonialReducer from './testimonialReducer';
import notificationReducer from './notificationReducer';
import seminarReducer from './seminarReducer';
import seminarlessonReducer from './seminarlessonReducer';
import seminartypeReducer from './seminartypeReducer';
import favoriteReducer from './favoriteReducer';
import integrationReducer from './integrationReducer';
import userdisplaytimeReducer from './userdisplaytimeReducer';
import generalsummaryreportReducer from './generalsummaryreportReducer';
import reportReducer from './reportReducer';

const rootReducer = combineReducers({
    brand: brandReducer,
    course: courseReducer,
    coursetype: coursetypeReducer,
    validator: validatorReducer,
    auth: authReducer,
    country: countryReducer,
    role: roleReducer,
    language: languageReducer,
    lesson: lessonReducer,
    user: userReducer,
    resource: resourceReducer,
    resourcetype: resourcetypeReducer,
    resourcecategory: resourcecategoryReducer,
    term: termReducer,
    slider: sliderReducer,
    modal: modalReducer,
    event: eventReducer,
    global: globalReducer,
    offer: offerReducer,
    log: logReducer,
    tag: tagReducer,
    novelty: noveltyReducer,
    usersetting: usersettingReducer,
    menu: menuReducer,
    help: helpReducer,
    family: familyReducer,
    testimonial: testimonialReducer,
    notification: notificationReducer,
    seminar: seminarReducer,
    seminarlesson: seminarlessonReducer,
    seminartype: seminartypeReducer,
    favorite: favoriteReducer,
    integration: integrationReducer,
    userdisplaytime: userdisplaytimeReducer,
    generalsummaryreport: generalsummaryreportReducer,
    report: reportReducer,
});

export default rootReducer;

