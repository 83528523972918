// Material UI
import { Box } from '@material-ui/core';
import { BackupRounded } from '@material-ui/icons';

// Libraries
import { useTranslation } from "react-i18next";
import Dropzone, { ILayoutProps } from 'react-dropzone-uploader'

// Styles
import 'react-dropzone-uploader/dist/styles.css'


const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            {files.length > 0 && submitButton}
        </div>
    )
}

function DropzoneMulti( {value, handleInputChange} : any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Handle functions
    const getUploadParams = ({ meta }: any) => {
        return { url: `${process.env.REACT_APP_API_URL}/resources/upload/${meta.name}` }
    }

    const handleChangeStatus = ({ meta, file }: any, status : string) => {
        let fileNames: string[] = [];
        if (status === 'done') {
            fileNames = [...value, meta.name];
            handleInputChange('fileNames', fileNames);
        }else
        if (status === 'removed'){
            fileNames = value.filter((fileName: string) => fileName !== meta.name);
            handleInputChange('fileNames', fileNames);
        }
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            LayoutComponent={Layout}
            onChangeStatus={({ meta, file }, status) => handleChangeStatus({ meta, file }, status)}
            classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
            inputContent={
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center">
                    <BackupRounded />
                        {t("dropzone-text")}
                </Box>
            }
        />
    )
}

export default DropzoneMulti;