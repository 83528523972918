// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';

// Material UI
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

// Custom components
import ListItemMenu from '../navigation/ListItemMenu';

// Redux Actions
import { IRootState } from '../../../redux/types'
import { setGlobalTag } from '../../../redux/actions/global';
import { getTags } from '../../../redux/actions/tags';

// Translate
import { useTranslation } from "react-i18next";

// Styles
import TagFilterStyles from '../../../styles/components/layouts/filters/TagFilterStyles';

// Types
interface IBrand {
    id: number,
    name: string,
    activeLines: any,
    tags: any
}

interface ITag {
    id: number,
    name: string
}

function TagFilter(props: any) {
    // Props
    const { layout } = props;

    // Translation
    const { t } = useTranslation(["global"]);
    // Styles
    const classes = TagFilterStyles();

    // Location & History
    const { pathname, search } = useLocation();
    const { replace } = useHistory();
    const searchParams = new URLSearchParams(search);

    // Redux Hooks
    const dispatch = useDispatch();
    const { tags } = useSelector((state: IRootState) => state.tag);
    const { globalFamilyId, globalTag, globalBrandId } = useSelector((state: IRootState) => state.global);
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    // Open State
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (!tags.length) {
            // dispatch(getTags());
        }
    }, []);

    useEffect(() => {
        dispatch(getTags());
    }, [globalFamilyId]);

    useEffect(() => {
        if (tags.length > 0) {
            if (search && searchParams.get('globalTag') !== null) {
                handleGlobalTag(Number(searchParams.get('globalTag')));
            }
        }
    }, [tags]);

    /* Functions */
    const handleClick = () => {
        setOpen(!open)
    };

    const handleGlobalTag = (tag: number) => {
        localStorage.setItem("globalTag", tag.toString());
        dispatch(setGlobalTag(tag));
        searchParams.set("globalTag", tag.toString());
        replace(`${pathname}?${searchParams}`);
    }

    const handleGlobalTagNull = () => {
        localStorage.setItem("globalTag", "null");
        dispatch(setGlobalTag(null));
        searchParams.delete("globalTag")
        replace(`${pathname}?${searchParams}`);
    }

    const ListItemTag = ({ id, name }: ITag) => {
        return (
            <ListItem key={id} onClick={() => handleGlobalTag(id)} className={id === globalTag ? 'active' : ''}>
                <ListItemText primary={name} />
            </ListItem>
        )
    }

    return (
        <>
            {(tags.length !== 0) &&
                <List id="nav-tag-filter" component="nav" className={clsx(classes.borderSidenav, layout)}>
                    <ListItemMenu button onClick={() => handleClick()} className={layout}>
                        <ListItemText primary={t("categories")} />
                        {open ? <ExpandLess color="primary" fontSize="small" /> : <ExpandMore color="primary" fontSize="small" />}
                    </ListItemMenu>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={clsx(classes.brandItemFilter, layout)}>
                            <ListItem key={'tag-0'} onClick={() => handleGlobalTagNull()} className={(null === globalTag) ? 'active' : ''}>
                                <ListItemText primary={t('all')} />
                            </ListItem>
                            {globalBrandId !== null ?
                                userBrands.find((brand: IBrand) => brand.id === globalBrandId)?.tags.map((tag: ITag) => <ListItemTag key={tag.id} id={tag.id} name={tag.name} />)
                                :
                                tags.map((tag: ITag) => <ListItemTag key={tag.id} id={tag.id} name={tag.name} />)
                            }
                        </List>
                    </Collapse>
                </List>
            }
        </>
    );
}

export default TagFilter;