// React
import { useState } from 'react';
import { useSelector } from 'react-redux'

// Material UI
import {
    Typography,  Box, Link, Divider, Drawer, Button, IconButton
} from '@material-ui/core';
import { ExpandMoreRounded, BusinessRounded, CloseRounded } from '@material-ui/icons';

import { useTranslation } from "react-i18next";

// Custom components
import BrandFilter from './BrandFilter';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Custom Style
import BrandMobileStyles from '../../../styles/components/layouts/filters/BrandMobileFilterStyles';


function BrandMobileFilter(props: any) {
    const {theme, type} = props;
    const classes = BrandMobileStyles();
    const [mobileOpen, setMobileOpen] = useState(false);
    
    // Translation
    const { t } = useTranslation(["global"]);

    // Redux Hooks
    const { userBrands } = useSelector((state: IRootState) => state.brand);
    const { globalBrandId } = useSelector((state: IRootState) => state.global);

    const getBrandName = (): string => {
        let brandName: string = ''
        const objBrand = userBrands.find((brand: any) => brand.id === globalBrandId)
        if (objBrand)
            brandName = objBrand.name.toUpperCase()

        return brandName
    }

    return (
        <>
        { type === 'nav' ?
            <Box 
                className={classes.mobile}>
                    <Box display={ window.location.pathname !== '/inicio' ? 'flex':'none'}>
                        <Divider orientation="vertical" className={classes.divider + ' ' + theme}/>
                            <Link className={classes.buttonBrand + ' ' + theme}
                                aria-haspopup="true"
                                underline="none"
                                onClick={() => setMobileOpen(!mobileOpen)}>
                                    <Typography 
                                    variant="body2" 
                                    className={classes.buttonBrandText + ' ' + theme}>
                                        {(globalBrandId !== 0 && globalBrandId !== null) ? getBrandName() : 'MARCAS'}
                                        </Typography>
                                <ExpandMoreRounded />
                            </Link>
                        <Divider orientation="vertical" className={classes.divider + ' ' + theme} />
                    </Box>
            </Box>
        :

            <Button className={classes.menuWidthLess} startIcon={<BusinessRounded />} onClick={() => setMobileOpen(!mobileOpen)}
                classes={{ label: classes.menuItem + ' ' + theme, startIcon: classes.menuIcon + ' ' + theme }}>
                <Typography variant="caption" color="textSecondary">{t("brands")}</Typography>
            </Button>
        
        }

            <Drawer
                variant="temporary"
                anchor='left'
                open={mobileOpen}
                onClose={() => setMobileOpen(!mobileOpen)}
                classes={{ paper: classes.drawerPaper + ' ' + theme }}
                ModalProps={{ keepMounted: true, }} >
                <IconButton onClick={() => setMobileOpen(!mobileOpen)} classes={{ root: classes.btnCloseDrawer }}><CloseRounded /></IconButton>
                <BrandFilter layout={theme} />
            </Drawer>
        </>
    );
}

export default BrandMobileFilter;