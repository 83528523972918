import { makeStyles, createStyles, fade } from '@material-ui/core/styles';



export default makeStyles((theme) => createStyles({
   
 

    // Common with brandfilter

    borderSidenav: {
        borderBottom: '1px solid ' + theme.palette.grey[100],
        paddingTop: '8px',
        paddingBottom: '16px',
        maxWidth: 240,
        display: 'flex',
        flexWrap: 'wrap',
        width:'100%',
        '&.admin': {
            borderTop: '1px solid ' + theme.palette.grey[100],
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            width: '100%',
        },
    },

     // Menu al brand filters


    brandItemFilter: {
        '& .MuiListItem-gutters': {
            padding: '0 24px 0px 30px',
        },
        '& .MuiListItem-root:hover': {
            cursor: 'pointer',
            '& .MuiTypography-root': {
                textDecoration: 'none'
            }
        },
        '& .MuiListItem-root.active': {
            borderLeft: '3px solid',
            borderColor: theme.palette.secondary.main,
            paddingLeft: '25px'
        },
        '& .MuiTypography-root': {
            fontSize: '15px',
            fontWeight: 400,
        },
        '&.admin ': {
            ' & .MuiListItem-root:hover': {
                background: theme.palette.grey[700],
                '& .MuiTypography-root': {
                    color: theme.palette.grey[50],
                }
            },
            '& .MuiListItem-root.active': {
                background: theme.palette.grey[700],
            },
            '& .MuiTypography-root': {
                color: theme.palette.grey[300],
            },
        },

        '&.client ': {
            ' & .MuiListItem-root:hover': {
                background: theme.palette.grey[100],
                '& .MuiTypography-root': {
                    color: theme.palette.grey[700],
                }
            },
            '& .MuiListItem-root.active': {
                background: theme.palette.grey[100],
            },
            '& .MuiTypography-root': {
                color: theme.palette.grey[700],
            },
        },

    },


}));
