import {
    INotificationState,
    START_GET_NOTIFICATIONS, SUCCESS_GET_NOTIFICATIONS, ERROR_GET_NOTIFICATIONS,
    START_GET_UNREAD_NOTIFICATIONS, SUCCESS_GET_UNREAD_NOTIFICATIONS, ERROR_GET_UNREAD_NOTIFICATIONS,
    START_SET_NOTIFICATION_AS_READ, SUCCESS_SET_NOTIFICATION_AS_READ, ERROR_SET_NOTIFICATION_AS_READ,
    START_SET_ALL_NOTIFICATIONS_AS_READ, SUCCESS_SET_ALL_NOTIFICATIONS_AS_READ, ERROR_SET_ALL_NOTIFICATIONS_AS_READ
} from '../types';
    
const initialState: INotificationState = {
    notifications: [],
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_NOTIFICATIONS:
        case START_GET_UNREAD_NOTIFICATIONS:
        case START_SET_NOTIFICATION_AS_READ:
        case START_SET_ALL_NOTIFICATIONS_AS_READ:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_NOTIFICATIONS:
        case SUCCESS_GET_UNREAD_NOTIFICATIONS:
        case SUCCESS_SET_ALL_NOTIFICATIONS_AS_READ:
        return{
            ...state,
            notifications: action.payload.notifications,
            msg: null,
            error:false,
            loading: false
        }
        case SUCCESS_SET_NOTIFICATION_AS_READ:
        return{
            ...state,
            notifications: state.notifications.map((notification: any) =>
                notification.id === action.payload.notification.id ? action.payload.notification : notification
            ),
            msg: null,
            error:false,
            loading: false
        }
        case ERROR_GET_NOTIFICATIONS:
        case ERROR_GET_UNREAD_NOTIFICATIONS:
        case ERROR_SET_NOTIFICATION_AS_READ:
        case ERROR_SET_ALL_NOTIFICATIONS_AS_READ:
        return{
            ...state,
            notifications: [],
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}