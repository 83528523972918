// React
import { useState, useEffect, ReactElement, ReactFragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Custom components
import SliderMoreInfo from './SliderMoreInfo';
import sliderStyles from '../../styles/components/HomeStyles';
import HomeSliderSkeleton from '../../components/layouts/navigation/skeletons/HomeSliderSkeleton';

// Redux Actions
import { getIndexSliders } from '../../redux/actions/sliders';
import { IRootState } from '../../redux/types';

// Libraries
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';


// Types
interface homeLinks {
    id: null | number,
    title: null | string,
    image: null | string
}

const LinkBlock = (({
    actionOnClikc, image, textTitle, validation
}: any) => {
    const classes = sliderStyles();
    //Custom Hook
    const isMobile = useScreenWidth(700);
    return (
        <>
            <Link className={classes.containerCard}>
                <Box
                    onClick={actionOnClikc}
                    className={classes.containercarousel}>
                    <Box position="relative" overflow="hidden" height={isMobile ? '120px' : validation ? "8.75rem" : "17.5rem"}>
                        <Box className={classes.bodyImage} style={isMobile ? { width: '97%', borderRadius: 8, height: 120, backgroundImage: `url(${image})` } : { backgroundImage: `url(${image})` }} />
                    </Box>
                    <Box className={classes.bodyText}>
                        <Box className={classes.contentText}>
                            <Typography className={classes.titleLink} >{textTitle}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </>
    )
}
);


function SliderClient() {
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = sliderStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { sliders, homeLinks, loading } = useSelector((state: IRootState) => state.slider);
    const { globalCountryId, globalLanguageId } = useSelector((state: IRootState) => state.global);
    // Open State
    const [open, setOpen] = useState(false);
    const [sliderSelected, setSliderSelected] = useState({ id: null });
    const [hasTopHomeLink, setHasTopHomeLink] = useState<homeLinks | false>(false);
    const [hasDownLeftHomeLink, setHasDownLeftHomeLink] = useState<homeLinks | false>(false);
    const [hasDownRightHomeLink, setHasDownRightHomeLink] = useState<homeLinks | false>(false);

    useEffect(() => {
        if (homeLinks.length > 0) {
            if (homeLinks.find((link: any) => link.order === 1) !== undefined) {
                setHasTopHomeLink(homeLinks.find((link: any) => link.order === 1));
                if (homeLinks.find((link: any) => link.order === 2) !== undefined) {
                    setHasDownLeftHomeLink(homeLinks.find((link: any) => link.order === 2));
                    setHasDownRightHomeLink(homeLinks.find((link: any) => link.order === 3));
                } else if (homeLinks.find((link: any) => link.order === 3) !== undefined) {
                    setHasDownLeftHomeLink(homeLinks.find((link: any) => link.order === 3));
                    setHasDownRightHomeLink(false);
                } else {
                    setHasDownLeftHomeLink(false);
                    setHasDownRightHomeLink(false);
                }
            } else if (homeLinks.find((link: any) => link.order === 2) !== undefined) {
                setHasTopHomeLink(homeLinks.find((link: any) => link.order === 2));
                if (homeLinks.find((link: any) => link.order === 3) !== undefined) {
                    setHasDownLeftHomeLink(homeLinks.find((link: any) => link.order === 3));
                } else {
                    setHasDownLeftHomeLink(false);
                    setHasDownRightHomeLink(false);
                }
            } else if (homeLinks.find((link: any) => link.order === 3) !== undefined) {
                setHasTopHomeLink(homeLinks.find((link: any) => link.order === 3));
                setHasDownLeftHomeLink(false);
                setHasDownRightHomeLink(false);
            }
        }
    }, [homeLinks]);

    useEffect(() => {
        if (globalCountryId !== null && globalLanguageId !== null) {
            dispatch(getIndexSliders({
                country_id: globalCountryId,
                language_id: globalLanguageId
            }));
        }
        setHasTopHomeLink(false);
        setHasDownLeftHomeLink(false);
        setHasDownRightHomeLink(false);
    }, [globalCountryId, globalLanguageId]);

    /* Functions */
    const handleClickItem = (slider: any) => {
        if (slider.type == 0) {
            window.open(slider.value)
        } else if (slider.type == 1) {
            setOpen(!open);
            setSliderSelected(slider);
        }
    }

    if (loading) {
        return <HomeSliderSkeleton />
    }

    return (

        <Container maxWidth="xl" classes={{ root: classes.containerLarge }}>
            <Grid container>
                <Grid item xs={12} md={hasTopHomeLink ? 6 : 12}>
                    <Carousel autoPlay showThumbs={false} interval={7000} infiniteLoop className={classes.containercarousel}>
                        {sliders.map((slider: any) => (
                            <Box className={classes.containerCard} key={slider.id}>
                                <Box position="relative" overflow="hidden" className={clsx(slider.value === null && classes.noPointerSlider, hasTopHomeLink ? classes.containerPrimary : classes.containerPrimaryHighest)}
                                    onClick={() => handleClickItem(slider)}
                                >
                                    <Box className={classes.bodyImage} style={{ backgroundImage: `url(${slider.image})` }} />
                                </Box>
                            </Box>
                        ))}
                    </Carousel>
                </Grid>
                {isMobile
                    ?
                    <Grid item xs={12}>
                        {hasTopHomeLink &&
                            <Carousel autoPlay showThumbs={false} showIndicators={false} showArrows={false} centerMode={hasDownLeftHomeLink ? true : false} interval={7000} dynamicHeight infiniteLoop className={clsx(classes.containercarousel, classes.containerMobile)}>
                                < LinkBlock
                                    actionOnClikc={() => handleClickItem(hasTopHomeLink)}
                                    image={hasTopHomeLink.image}
                                    textTitle={hasTopHomeLink.title} />
                                <>
                                    {hasDownLeftHomeLink &&
                                        <LinkBlock
                                            actionOnClikc={() => handleClickItem(hasDownLeftHomeLink)}
                                            image={hasDownLeftHomeLink.image}
                                            textTitle={hasDownLeftHomeLink.title} />
                                    }
                                </>
                                <>
                                    {hasDownRightHomeLink &&
                                        <LinkBlock
                                            actionOnClikc={() => handleClickItem(hasDownRightHomeLink)}
                                            image={hasDownRightHomeLink.image}
                                            textTitle={hasDownRightHomeLink.title} />
                                    }
                                </>
                            </Carousel>
                        }
                    </Grid>
                    :
                    <>
                        {hasTopHomeLink ?
                            <Grid item xs={12} md={6}>
                                <Grid container direction="column">
                                    <Grid item >
                                        <LinkBlock
                                            actionOnClikc={() => handleClickItem(hasTopHomeLink)}
                                            image={hasTopHomeLink.image}
                                            textTitle={hasTopHomeLink.title}
                                            validation={hasTopHomeLink}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Grid container>
                                            {hasDownLeftHomeLink
                                                ?
                                                <Grid item xs={12} sm={hasDownRightHomeLink ? 6 : 12}>
                                                    <LinkBlock
                                                        actionOnClikc={() => handleClickItem(hasDownLeftHomeLink)}
                                                        image={hasDownLeftHomeLink.image}
                                                        textTitle={hasDownLeftHomeLink.title}
                                                        validation={true} />
                                                </Grid>
                                                : <></>
                                            }
                                            {hasDownRightHomeLink
                                                ?
                                                <Grid item xs={12} sm={6} >
                                                    <LinkBlock
                                                        actionOnClikc={() => handleClickItem(hasDownRightHomeLink)}
                                                        image={hasDownRightHomeLink.image}
                                                        textTitle={hasDownRightHomeLink.title}
                                                        validation={true} />
                                                </Grid>
                                                : <></>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : <></>
                        }
                    </>
                }
            </Grid>

            <SliderMoreInfo slider={sliderSelected} open={open} setOpen={setOpen} />
        </Container>

    );
}

export default SliderClient;