import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    main: {
        '& svg': {
            color: `${theme.palette.grey[50]} !important`,
        }
    },
    dropdown: {
        zIndex: 20,
        '& > div': {
            background: theme.palette.primary.main
        },
        '& .MuiMenuItem-root': {
            color: theme.palette.grey[50],
            padding: '4px 24px',
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]:{
            '& .MuiMenuItem-root': {
                padding: '12px 24px',
            },
            overflow: 'auto',
            backdropFilter: 'blur(15px)',
            width: '98%',
            top: '50px!important',
            '& > div': {
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(52, 54, 56, 0.8)',
                borderRadius: '10px',
            },
            
        },
    },
    buttonCategory: {
        color: 'white',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        '& svg': {
            color: `${theme.palette.grey[50]} !important`,
        },
        [theme.breakpoints.down('sm')]:{
            borderLeft: 'none !important',
            justifyContent: 'flex-end',
            minWidth: '40px !important',
            maxWidth: 50,
        },
    },
    optionCategory: {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '&.admin': {
            '&:hover': {
                backgroundColor: theme.palette.grey[700],
            },
        },
        '&.active': {
            backgroundColor: 'rgb(255 255 255 / 30%)',
            '&.admin': {
                backgroundColor: theme.palette.grey[700],
            },
        },
        [theme.breakpoints.down('sm')]:{
            borderTop: `1px solid ${theme.palette.grey[200]}`, 
            justifyContent: 'space-between',
        },
    },
    svgSubMenu: {
        display: 'none',
        '&.active': {
            backgroundColor: 'transparent',
            display: 'block',
            color: theme.palette.grey[50],
            marginLeft: 8,
        },
        '&.admin': {
            marginRight: 8,
            marginLeft: 0,
        },
        [theme.breakpoints.down('sm')]:{
            '&.active': {
                color: theme.palette.success.main
            },
        },
    },
}));
