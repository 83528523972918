// React
import { useState } from 'react'

const useMassAssignmentData = () => {
    const [data, setData] = useState<any>({
        resourcetype: null,
        brand: [],
        line: [],
        countries: [],
        languages: [],
        role: [],
        visibility: [],
        category: [],
        country: [],
        province: [],
        town: [],
        postalcode: []
    })

    return [data, setData]
}

export default useMassAssignmentData