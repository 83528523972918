import {
    START_FILTER_SEMINARS,
    START_STORE_SEMINAR,
    START_UPDATE_SEMINAR,
    START_SHOW_SEMINAR,
    START_ASSIGN_HIGHLIGHT_SEMINAR,
    START_DELETE_SEMINAR,
    START_TOGGLE_FORM_SEMINAR
} from '../types';

export const getSeminars = payload => ({
    type: START_FILTER_SEMINARS,
    payload
})

export const storeSeminar = payload => ({
    type: START_STORE_SEMINAR,
    payload
})

export const updateSeminar = payload => ({
    type: START_UPDATE_SEMINAR,
    payload
})

export const showSeminar = payload => ({
    type: START_SHOW_SEMINAR,
    payload
})

export const deleteSeminar = payload => ({
    type: START_DELETE_SEMINAR,
    payload
})

export const assignHighlight = payload => ({
    type: START_ASSIGN_HIGHLIGHT_SEMINAR,
    payload
})

export const toggleFormSeminar = (payload = false) => ({
    type: START_TOGGLE_FORM_SEMINAR,
    payload
})