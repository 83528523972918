import {
    START_GET_OFFERS,
    START_GET_CLIENT_OFFERS,
    START_CREATE_OFFER,
    START_UPDATE_OFFER,
    START_SHOW_OFFER,
    START_DELETE_OFFER,
    START_TOGGLE_FORM_OFFER,
    START_ASSIGN_TAG_OFFER,
    START_DELETE_TAG_OFFER,
    START_ASSIGN_HIGHLIGHT_OFFER,
    START_COUNTER_VIEWS_OFFER
} from '../types';

export const getOffers = payload => ({
    type: START_GET_OFFERS,
    payload
})

export const getClientOffers = payload => ({
    type: START_GET_CLIENT_OFFERS,
    payload
})

export const createOffer = payload => ({
    type: START_CREATE_OFFER,
    payload
})

export const updateOffer = payload => ({
    type: START_UPDATE_OFFER,
    payload
})

export const showOffer = payload => ({
    type: START_SHOW_OFFER,
    payload
})

export const deleteOffer = payload => ({
    type: START_DELETE_OFFER,
    payload
})

export const assignHighlight = payload => ({
    type: START_ASSIGN_HIGHLIGHT_OFFER,
    payload
})

export const toggleFormOffer = (payload = false) => ({
    type: START_TOGGLE_FORM_OFFER,
    payload
})

export const assignTagOffer = payload => ({
    type: START_ASSIGN_TAG_OFFER,
    payload
})

export const deleteTagOffer = payload => ({
    type: START_DELETE_TAG_OFFER,
    payload
})

export const counterViewsOffer = payload => ({
    type: START_COUNTER_VIEWS_OFFER,
    payload
})