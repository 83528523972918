import { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from "react-router-dom"

// Material UI
import { Grid, Container, Divider, Typography, Box, Theme, makeStyles, createStyles } from '@material-ui/core';

// Custom components
import NoveltyModal from '../../components/novelties/NoveltyModal';
import DetailHeroPanel from '../../components/layouts/common/DetailHeroPanel';
import DetailInfoPanel from '../../components/layouts/common/DetailInfoPanel';
import CustomSnackBar from '../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../components/common/ScreenLoading';
import CardHero from '../../components/common/client/CardHero';
import ShareIcon from '../../components/common/ShareIcon';
import VerifyAccessRoute from '../../components/common/VerifyAccessRoute';
import ButtonBack from '../../components/common/ButtonBack';
import AdminDetailInfo from '../../components/common/admin/AdminDetailInfo';
import SeminarShortcut from '../../components/seminars/SeminarShortcut';

// Custom Hooks
import useUserDisplaytime from '../../hooks/useUserDisplaytime';
import useScreenWidth from '../../hooks/useScreenWidth';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';
import { showNoveltyBySlug, getClientNovelties, toggleFormNovelty, getNovelties } from '../../redux/actions/novelties'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';
import FavoriteButton from '../../components/common/FavoriteButton';

// Types
interface ParamTypes {
    slug: string
}
interface INovelty {
    id: null | number,
    visibility: number,
    creator: string,
    title: string,
    slug: string,
    content: string,
    image_url: string,
    created_at: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        customContainer: {
            '& img': {
                maxWidth: '100%',
            }
        },
    }),
);

function NoveltyDetail() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(960);
    // Styles
    const classes = useStyles();

    const location = useLocation();

    //Redux Hooks
    const dispatch = useDispatch();
    const { slug } = useParams<ParamTypes>();
    const { novelties, noveltySelected, error: errorNovelties, msg: msgNovelties, loading, showForm } = useSelector((state: IRootState) => state.novelty);
    const { globalFamilyId, globalBrandId, globalCountryId, globalLanguageId, globalCountriesId } = useSelector((state: IRootState) => state.global);
    const { user: authUser } = useSelector((state: IRootState) => state.auth);

    const [sortNovelties, setSortNovelties] = useState<INovelty[]>([]);
    const [content, setContent] = useState<string>('');
    const [favoriteState, setFavoriteState] = useState<boolean>(false);

    // Send track of user
    useUserDisplaytime({ foreign: noveltySelected });

    let isAdmin = location.pathname.includes('admin') === true
    let infoDetail = (noveltySelected != null) && {
        "item": [
            {
                "id": 0,
                "type": "tag",
                "title": "Tags",
                "color": "light",
                "info": `${noveltySelected.tags.map((tag: any) => { return tag.name.toUpperCase() }).join(', ')}`,
            },
            {
                "id": 1,
                "type": "date",
                "title": `${t("admin.people.created-date")}`,
                "color": "grey6",
                "info": `${moment(noveltySelected.created_at).format('LL')}`,
            },
        ]
    }

    // Ready Page
    useEffect(() => {
        dispatch(showNoveltyBySlug({ slug, type: 'noveltySelected' }));
        document.getElementById('btn-shortcutseminars')?.addEventListener('click', () => { console.log('Click addeventlistener') });
    }, [slug]);
    // TODO: Revisar con Sebastian para saber como evitar el error al visualizar un seminario con novedad
    useEffect(() => {
        if (noveltySelected !== null) {
            setFavoriteState(Boolean(noveltySelected.favoriteId));
        }
        if (noveltySelected !== null && noveltySelected.content.search('{mostrarSeminario}') >= 0) {
            let htmlShowSeminarShortcut: string = '';
            if (noveltySelected.seminarlesson !== null) {
                htmlShowSeminarShortcut = noveltySelected.content.replace('{mostrarSeminario}', renderToString(
                    <SeminarShortcut
                        seminar={noveltySelected.seminar}
                        seminarlesson={noveltySelected.seminarlesson}
                    />
                ));
            }
            if (noveltySelected.seminar !== null) {
                htmlShowSeminarShortcut = noveltySelected.content.replace('{mostrarSeminario}', renderToString(
                    <SeminarShortcut
                        seminar={noveltySelected.seminar}
                    />
                ));
            }
            setContent(htmlShowSeminarShortcut);
        }
    }, [noveltySelected]);

    useEffect(() => {
        if (novelties.length > 0) {
            let tmpSortNovelties = novelties;
            tmpSortNovelties.sort(function (a: any, b: any) {
                if (a.id > b.id) return -1;
                if (a.id < b.id) return 1;
                return 0;
            });
            setSortNovelties(tmpSortNovelties);
        }
    }, [novelties]);

    useEffect(() => {
        if (!novelties.length) {
            if (isAdmin) {
                dispatch(getNovelties({
                    family_id: globalFamilyId,
                    brand_id: globalBrandId,
                    language_id: globalLanguageId,
                    countries: globalCountriesId,
                    requesttype: 0,
                    type: 'novelties'
                }));
            } else {
                if (authUser !== null) {
                    dispatch(getClientNovelties({
                        family_id: globalFamilyId,
                        brand_id: globalBrandId,
                        country_id: globalCountryId,
                        language_id: globalLanguageId,
                        type: 'novelties',
                        requesttype: 0
                    }));
                }
            }
        }
    }, [globalFamilyId, globalBrandId, globalCountryId, globalLanguageId]);

    /* Functions */
    const handleShowNovelty = (slug: string) => {
        dispatch(showNoveltyBySlug({ slug, type: 'noveltySelected' }))
    }

    const cutContent = (content: string) => {
        let trimmedString: string = '';
        trimmedString = content.substring(0, Math.min(content.length, content.indexOf("</p>")))
        return trimmedString;
    }

    const handleFavoriteClick = (novelty_id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id: novelty_id,
                type: 'novelty'
            }));
        } else {
            dispatch(unsetFavorite({
                id: novelty_id,
                type: 'novelty',
            }));
        }
        setFavoriteState(state);
    };

    return (
        <>

            {(noveltySelected != null) &&

                <Container maxWidth="lg">

                    {showForm && (
                        <NoveltyModal />
                    )}

                    <ButtonBack />
                    <Grid container spacing={isMobile ? 1 : 5} direction={isMobile ? 'column-reverse' : 'row-reverse'} >

                        <Grid item xs={12} md={(isAdmin) ? 4 : 3}>
                            {isAdmin ?
                                <>
                                    <DetailHeroPanel
                                        visibility={noveltySelected.visibility !== null && noveltySelected.visibility}
                                        brand={noveltySelected.brand !== null && noveltySelected.brand.name}
                                        title={noveltySelected.title !== null && noveltySelected.title}
                                        author={noveltySelected.responsibles > 0 ? noveltySelected.responsibles[0].name : null}
                                        statusC={<>
                                            {(noveltySelected.status === 0) && `${t('active')}`}
                                            {(noveltySelected.status === 1) && `${t('inactive')}`}
                                            {(noveltySelected.status === 2) && `${t('draft')}`}
                                        </>}
                                        description={noveltySelected.description !== null && noveltySelected.description}
                                        // Actions
                                        edit={() => { dispatch(toggleFormNovelty({ type: "noveltySelected", change: false })) }}
                                        share={<ShareIcon
                                            meta={true}
                                            visibility={noveltySelected.visibility}
                                            title={noveltySelected.title}
                                            description={noveltySelected.description || ''}
                                            link={window.location.href}
                                            multimedia={noveltySelected.image_url}
                                            component="buttonTextGreen"
                                            type="course"
                                            buttonTextGreen={t("share")}
                                        />}
                                    />
                                    {isMobile ? <></> : <DetailInfoPanel attributes={infoDetail} />}
                                </>
                                :
                                <>

                                    <Box display="flex" alignItems="center" pb={3} mt={3}>
                                        <Typography variant="h6" style={{ minWidth: 'fit-content' }}>{t('more-novelties')}</Typography>
                                        <Divider style={{ marginLeft: 16, flexShrink: 1 }} />
                                    </Box>

                                    {sortNovelties.filter((novelty: any) => novelty.visibility === 2).slice(0, 5).map((novelty: any) => (
                                        <Box key={novelty.id} mb={2}>
                                            <CardHero
                                                type={1}
                                                onClickCard={() => handleShowNovelty(novelty.slug)}
                                                redirect={`/novedades/${novelty.slug}`}
                                                cardBrandName={novelty.brand?.name || ''}
                                                cardVisibility={novelty.visibility}
                                                cardImage={`${process.env.REACT_APP_AWS_URL}/novelties/${novelty.image_url}`}
                                                cardTitle={novelty.title}
                                                cardDescription={<div dangerouslySetInnerHTML={{ __html: cutContent(novelty.content) }}></div>}
                                                cardDate={`${moment(novelty.created_at).fromNow()}`}
                                                cardActions={
                                                    <ShareIcon
                                                        meta={true}
                                                        visibility={novelty.visibility}
                                                        title={novelty.title}
                                                        description={novelty.title}
                                                        link={`${window.location.origin}/novedades/${novelty.slug}${window.location.search}`}
                                                        type="novelty"
                                                        multimedia={`${process.env.REACT_APP_AWS_URL}/novelties/${novelty.image_url}`}
                                                    />
                                                }
                                                favoriteState={Boolean(novelty.favoriteId)}
                                                onClickFavorite={authUser === null ? undefined : handleFavoriteClick(novelty.id)}
                                            />
                                        </Box>
                                    ))}
                                </>
                            }
                        </Grid>

                        <Grid item xs={12} md={(isAdmin) ? 8 : 9}>
                            {isAdmin ?
                                <AdminDetailInfo resourceSelected={noveltySelected} />
                                :
                                <>
                                    <Box pb={4}>
                                        <Typography variant={isMobile ? "h5" : "h3"} style={{ fontWeight: 400 }}>{noveltySelected.title}</Typography>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" pt={2}>
                                            <Box>
                                                <Typography color="textSecondary">{`${moment(noveltySelected.created_at).fromNow()}`}</Typography>
                                                <Typography color="textSecondary" variant='body2'>{`${t('writter-by')} : ${noveltySelected.creator.name}`}</Typography>
                                            </Box>
                                            <Box display='flex'>
                                                <FavoriteButton handleClick={handleFavoriteClick(noveltySelected.id)} favoriteState={favoriteState} />
                                                <ShareIcon
                                                    meta={true}
                                                    visibility={noveltySelected.visibility}
                                                    description=''
                                                    title={noveltySelected.title}
                                                    link={window.location.href}
                                                    multimedia={`${process.env.REACT_APP_AWS_URL}/novelties/${noveltySelected.image_url}`}
                                                    component="button"
                                                    type="news"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            }

                            <VerifyAccessRoute visibility={noveltySelected.visibility} />

                            <img src={`${process.env.REACT_APP_AWS_URL}/novelties/${noveltySelected.image_url}`} alt={noveltySelected.title} width="100%" />
                            <Box>
                                <div className={classes.customContainer} dangerouslySetInnerHTML={{ __html: content }}></div>
                                {/* {seminarAssosiate &&
                                    <SeminarShortcut
                                        id={1}
                                        isRegistered={null} //para testear cambiar a true o null y ver comportamientos
                                        title={'Eveneto de prueba'}
                                        datetime={`${today}`}
                                        visibility={0}
                                    />
                                } */}
                            </Box>

                            {/* Metrics
                            <Box mt={2} justifyContent="space-between" display="flex" alignItems="center">
                                <Box display="flex">
                                    <Typography variant="body2" color="textSecondary"> 0 vistas </Typography>
                                    <Typography variant="body2" color="textSecondary"> &nbsp;· 0 veces compartidas </Typography>
                                </Box>
                                <Button startIcon={<ShareRounded />}> Compartir </Button>
                            </Box> */}
                            {isMobile ? <DetailInfoPanel attributes={infoDetail} /> : <></>}

                        </Grid>

                    </Grid>
                </Container>
            }

            <CustomSnackBar errors={[errorNovelties]} msgs={[msgNovelties]} />
            <ScreenLoading loadings={[loading]} />

        </>

    );
}

export default NoveltyDetail;