import { ReactElement } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

type ReactPdfViewerProps = {
    fileName: string,
    fileUrl: string
}

function ReactPdfViewer({ fileName, fileUrl }: ReactPdfViewerProps) {

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(slots: ToolbarSlot) => {
                const {
                    ShowSearchPopover,
                    ZoomOut,
                    Zoom,
                    ZoomIn,
                    GoToFirstPage,
                    GoToPreviousPage,
                    CurrentPageInput,
                    NumberOfPages,
                    GoToNextPage,
                    GoToLastPage,
                    EnterFullScreen,
                    SwitchTheme,
                    Download,
                    Print,
                    ShowProperties
                } = slots;
                return (
                    <div style={{ alignItems: 'center', display: 'flex', width: '100%', justifyContent: 'space-between' }} >
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} >
                            <ShowSearchPopover />
                            <ZoomOut />
                            <Zoom />
                            <ZoomIn />
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} >
                            <GoToFirstPage />
                            <GoToPreviousPage />
                            <CurrentPageInput />
                            <span>/ <NumberOfPages /></span>
                            <GoToNextPage />
                            <GoToLastPage />
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} >
                            <EnterFullScreen />
                            <SwitchTheme />
                            <Download />
                            <Print />
                            <ShowProperties />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const renderMobileToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {(slots: ToolbarSlot) => {
                const {
                    ShowSearchPopover,
                    ZoomOut,
                    Zoom,
                    ZoomIn,
                    GoToPreviousPage,
                    CurrentPageLabel,
                    NumberOfPages,
                    GoToNextPage,
                    EnterFullScreen,
                    SwitchTheme
                } = slots;
                return (
                    <div style={{ alignItems: 'center', display: 'flex', width: '100%', justifyContent: 'space-between' }} >
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} >
                            <ShowSearchPopover />
                            <ZoomOut />
                            <Zoom />
                            <ZoomIn />
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} >
                            <GoToPreviousPage />
                            <CurrentPageLabel />
                            <span>/ <NumberOfPages /></span>
                            <GoToNextPage />
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} >
                            <EnterFullScreen />
                            <SwitchTheme />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: useScreenWidth(600) ? renderMobileToolbar : renderToolbar,
        // renderToolbar,
        sidebarTabs: (defaultTabs) => [
            defaultTabs[1]
        ]
    });

    return (
        <div
            style={{
                height: '50rem'
            }}
        >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </Worker>
        </div>
    )
}

export default ReactPdfViewer;