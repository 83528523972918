// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// Material-UI
import { Paper, Grid, Link, Box } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    AllDayPanel, Scheduler, DayView, WeekView, MonthView, Appointments, DateNavigator, TodayButton,
    AppointmentTooltip, CurrentTimeIndicator, Toolbar, ViewSwitcher
} from '@devexpress/dx-react-scheduler-material-ui';
import classNames from 'clsx';

// Redux Actions
import { getCalendarEvents } from '../../redux/actions/events'
import { IRootState } from '../../redux/types'

// Custom Hooks
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";

// External Styles
import CalendarStyles from '../../styles/components/Events/EventsCalendarStyles';

// Libraries
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { AccessTime, BusinessRounded, Lens } from '@material-ui/icons';
import { Button, Typography } from '@material-ui/core';

// Custom components
import Visbility from '../common/client/Visibility';
import HomeCalendarSkeleton from '../layouts/navigation/skeletons/HomeCalendarSkeleton';

// Styles
const useStyles = (props: any) => makeStyles(theme => ({
    line: {
        height: '2px',
        borderTop: `2px ${theme.palette.primary.main} dotted`,
        width: '100%',
        transform: 'translate(0, -1px)',
    },
    circle: {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.primary.main,
    },
    nowIndicator: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        top: props.top,
    },
    shadedCell: {
        backgroundColor: fade(theme.palette.primary.main, 0.08),
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
        },
        '&:focus': {
            backgroundColor: fade(theme.palette.primary.main, 0.20),
            outline: 0,
        },
    },
    shadedPart: {
        backgroundColor: fade(theme.palette.primary.main, 0.08),
        position: 'absolute',
        height: props.shadedHeight,
        width: '100%',
        left: 0,
        top: 0,
        'td:focus &': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
        },
    },
    appointment: {
        backgroundColor: props.backgroundColor,
        '&:hover': {
            backgroundColor: grey[400],
        },
    },
    shadedAppointment: {
        backgroundColor: grey[400],
        '&:hover': {
            backgroundColor: grey[500],
        },
    },
    textCenter: {
        textAlign: 'center',
    },
    monthBody: {}
}));

// Types
type EventCalendarProps = {
    family_id?: null | number,
    brand_id?: null | number,
    fullHeight?: null | boolean,
}

const Appointment = ({
    onClick, isShaded, ...restProps
}: any) => {
    const isVisible = moment(restProps.data.endDate).isBefore(moment());
    const classes = useStyles({ backgroundColor: (restProps.data.brand != null) ? restProps.data.brand.main_color : restProps.data.background_color })();

    return (
        <Appointments.Appointment
            onClick={onClick}
            className={classNames({
                [classes.appointment]: true,
                [classes.shadedAppointment]: isShaded,
            })}
            {...restProps}
        />
    );
};

const TimeIndicator = ({
    top, ...restProps
}: any) => {
    const { nowIndicator, circle, line } = useStyles({ top })();
    return (
        <div {...restProps}>
            <div className={classNames(nowIndicator, circle)} />
            <div className={classNames(nowIndicator, line)} />
        </div>
    );
};

const TimeTableCell = ({
    currentTimeIndicatorPosition, isShaded, ...restProps
}: any) => {
    const classes = useStyles({ shadedHeight: currentTimeIndicatorPosition })();
    const isNow = !!currentTimeIndicatorPosition;

    return (
        <WeekView.TimeTableCell
            isShaded={isShaded && !isNow}
            currentTimeIndicatorPosition={currentTimeIndicatorPosition}
            className={classNames({
                [classes.shadedCell]: isShaded && !isNow,
            })}
            {...restProps}
        >
            {isNow && isShaded && (
                <div className={classes.shadedPart} />
            )}
        </WeekView.TimeTableCell>
    );
};

const Content = (({
    children, formatDate, appointmentData, ...restProps
}: any) => {
    // Translate
    const { t } = useTranslation(["global"]);
    const classes = CalendarStyles({ color: (appointmentData.brand != null) ? appointmentData.brand.main_color : appointmentData.background_color });
    const history = useHistory();
    const startValidation = formatDate(appointmentData.startDate, { month: 'long', day: 'numeric' });
    const endValidation = formatDate(appointmentData.endDate, { month: 'long', day: 'numeric' });
    //Location
    const location = useLocation();
    const homeLocation = location.pathname === '/inicio'

    return (
        <div className={classes.content}>
            <Grid container alignItems="flex-start" className={classes.titleContainer}>

                <Grid item xs={2} className={classNames(classes.textCenter)}>
                    <Lens className={classNames(classes.lens, classes.colorfulContent)} />
                </Grid>
                <Grid item xs={10}>
                    <div>
                        <div className={classNames(classes.title, classes.dateAndTitle)}>
                            {appointmentData.title}
                        </div>
                        {startValidation === endValidation
                            ?
                            <div className={classNames(classes.text, classes.dateAndTitle)}>
                                {formatDate(appointmentData.startDate, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                            :
                            <div className={classNames(classes.text, classes.dateAndTitle)}>
                                {`${formatDate(appointmentData.startDate, { weekday: 'long', month: 'long', day: 'numeric' })}
                                - ${formatDate(appointmentData.endDate, { weekday: 'long', month: 'long', day: 'numeric' })}`}
                            </div>
                        }
                        {appointmentData.speaker !== (null || undefined) &&
                            <div className={classNames(classes.text, classes.dateAndTitle, classes.spaker)}>
                                <Typography variant='body2' style={{ color: (appointmentData.brand != null) ? appointmentData.brand.main_color : appointmentData.background_color }}>{t('speaker')}:</Typography>
                                {appointmentData.speaker}
                            </div>
                        }
                        {appointmentData.description !== (null || undefined) &&
                            <div className={classNames(classes.text, classes.description)}>
                                <Typography variant='body2' style={{ color: (appointmentData.brand != null) ? appointmentData.brand.main_color : appointmentData.background_color }}>{t('description')}</Typography>
                                {appointmentData.description}
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.contentContainer}>
                <Grid
                    className={classNames(classes.contentItemIcon, classes.icon, classes.colorfulContent)}
                    item
                    xs={2}
                >
                    <BusinessRounded />
                </Grid>
                <Grid item xs={4}>
                    <Typography>{appointmentData.brand.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Visbility type={0} />
                </Grid>
            </Grid>
            <Grid container alignItems="center" className={classes.contentContainer}>
                <Grid item xs={2} className={classes.textCenter}>
                    <AccessTime className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                    <div className={classes.text}>
                        {`${formatDate(appointmentData.startDate, { hour: 'numeric', minute: 'numeric', hour12: true })}
                        - ${formatDate(appointmentData.endDate, { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                    </div>
                </Grid>
            </Grid>

            <Grid container alignItems="center" justify='center' key={`${appointmentData.text_button}_${appointmentData.id}`}>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        className={classNames(classes.callToActionBtn)}
                        onClick={() => { history.push(`/eventos/${appointmentData.id}`); }}>
                        {t('see-details')}
                    </Button>
                </Grid>
                {appointmentData.link &&
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Link className={classNames(classes.saveBtnForm)} component="a" variant="body2" rel="noopener noreferrer"
                            target="_blank" href={appointmentData.link}>
                            {(appointmentData.text_button != null) ? appointmentData.text_button : t("admin.events.join-here")}
                        </Link>
                    </Grid>
                }
            </Grid>

        </div>
    );
});

//Components for styles
//MONTH VIEW
const tableBodyMonth = (props: any) => {
    const classes = CalendarStyles();
    return (
        <MonthView.TimeTableLayout {...props} className={classes.monthBody} />
    )
}
const tableDayMonth = (props: any) => {
    const classes = CalendarStyles();
    return (
        <MonthView.DayScaleLayout {...props} className={classes.monthHeader} />
    )
}
const tableCellMonth = (props: any) => {
    const classes = CalendarStyles();
    return (
        <MonthView.TimeTableCell {...props} className={classes.monthCell} />
    )
}
//  WEEK VIEW
const tableBodyWeek = (props: any) => {
    const classes = CalendarStyles();
    return (
        <WeekView.TimeTableLayout {...props} className={classes.monthBody} />
    )
}
const tableDayWeek = (props: any) => {
    const classes = CalendarStyles();
    return (
        <WeekView.DayScaleLayout {...props} className={classes.monthHeader} />
    )
}

//Layout
const LayoutTooltip = (props: any) => {
    const classes = CalendarStyles();
    return (
        <AppointmentTooltip.Layout {...props} className={classes.layoutToltip} />
    )
}

const calendarLanguages = ['es', 'es', 'en', 'fr', 'de', 'ca'];

export default function EventCalendar({ family_id = null, brand_id = null, fullHeight = false }: EventCalendarProps) {

    // Translate
    const { t } = useTranslation(["global"]);

    // Style
    const classes = CalendarStyles();

    // Router
    let location = useLocation();
    const history = useHistory();

    //Redux Hooks
    const dispatch = useDispatch();
    const { calendarEvents, loading } = useSelector((state: IRootState) => state.event);
    const { globalCountryId, globalLanguageId, globalRoleId } = useSelector((state: IRootState) => state.global);

    //Calendar lenguage
    const [currentLanguage, setCurrentLanguage] = useState('es');

    // Dates
    const [calendarDate, setCalendarDate] = useState(moment());
    const currentDate = new Date();

    const { handleClickGeneralSummaryReport } = useGeneralSummaryReport(calendarEvents, 30, 4);

    useEffect(() => {
        dispatch(getCalendarEvents({
            currentDate: calendarDate,
            family_id,
            brand_id,
            country_id: globalCountryId,
            language_id: globalLanguageId,
            role_id: globalRoleId
        }));
    }, [globalCountryId, globalLanguageId, globalRoleId, family_id, brand_id]);

    useEffect(() => {
        if (globalLanguageId != null)
            setCurrentLanguage(calendarLanguages[globalLanguageId]);
    }, [globalLanguageId]);

    let hidenHeader = location.pathname === '/eventos'


    return (
        <>
            {!hidenHeader &&
                <Grid item xs={12} className={classes.header}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">{t("events")}</Typography>
                        <Typography onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); history.push(`/eventos`) }} color="textSecondary" className={classes.seeMore}>{t("see-more")}</Typography>
                    </Box>
                </Grid>
            }
            <div style={{ display: (loading) ? 'block' : 'none' }}>
                <HomeCalendarSkeleton />
            </div>
            <Paper style={{ display: (loading) ? 'none' : 'block' }}>
                <Scheduler
                    data={calendarEvents}
                    locale={currentLanguage}
                >
                    <ViewState
                        defaultCurrentDate={currentDate}
                        defaultCurrentViewName={"Month"}
                        onCurrentDateChange={(currentDate: any) => {
                            setCalendarDate(currentDate);
                            dispatch(getCalendarEvents({
                                currentDate,
                                family_id,
                                brand_id,
                                country_id: globalCountryId,
                                language_id: globalLanguageId,
                                role_id: globalRoleId
                            }))
                        }}
                    />

                    <DayView
                        displayName={t('days')}
                        startDayHour={8}
                        endDayHour={18} />
                    <WeekView
                        displayName={t('week')}
                        startDayHour={8}
                        endDayHour={18}
                        timeTableCellComponent={TimeTableCell}
                        timeTableLayoutComponent={tableBodyWeek}
                        dayScaleLayoutComponent={tableDayWeek}
                    />
                    {location.pathname === '/eventos'
                        ?
                        <MonthView
                            displayName={t('month')}
                            timeTableLayoutComponent={tableBodyMonth}
                            dayScaleLayoutComponent={tableDayMonth}
                        />
                        :
                        <MonthView
                            displayName={t('month')}
                            timeTableLayoutComponent={tableBodyMonth}
                            dayScaleLayoutComponent={tableDayMonth}
                            timeTableCellComponent={tableCellMonth}
                        />
                    }

                    <Toolbar />
                    <ViewSwitcher />
                    <DateNavigator />
                    <TodayButton />

                    <Appointments
                        appointmentComponent={Appointment}
                    />
                    <AppointmentTooltip
                        contentComponent={Content}
                        showCloseButton
                        layoutComponent={LayoutTooltip}
                    />

                    <CurrentTimeIndicator
                        indicatorComponent={TimeIndicator}
                        shadePreviousCells
                        shadePreviousAppointments
                    />

                    <AllDayPanel />
                </Scheduler>
            </Paper>
        </>
    );
}