import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_STORE_DISPLAYTIME, SUCCESS_STORE_DISPLAYTIME, ERROR_STORE_DISPLAYTIME,
    START_UPDATE_DISPLAYTIME, SUCCESS_UPDATE_DISPLAYTIME, ERROR_UPDATE_DISPLAYTIME,
} from '../types';
import { apiCall } from '../config/axios';

function* createDisplaytime({ payload }) {
    try{
        const response = yield call(apiCall, 'post', '/userdisplaytime', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_STORE_DISPLAYTIME, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_STORE_DISPLAYTIME, payload: error.message})
    }
}

function* updateDisplaytime({ payload }) {
    try{
        const response = yield call(apiCall, 'put', `/userdisplaytime/${payload.id}`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_UPDATE_DISPLAYTIME, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_UPDATE_DISPLAYTIME, payload: error.message})
    }
}

export default function* userdisplaytime() {
    yield takeLatest(START_STORE_DISPLAYTIME, createDisplaytime);
    yield takeLatest(START_UPDATE_DISPLAYTIME, updateDisplaytime);
}