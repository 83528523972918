// Material UI
import { Box, Typography } from "@material-ui/core";

// Libraries
// import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, ResponsiveContainer, CartesianGrid, Tooltip, YAxis } from "recharts";
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Styles
import GraphsStyles from "../../styles/components/graphs/GraphsStyles";

// Types
interface Item {
  foreign_id: number
  foreign_name: string
  image: string
  value: number
  menu: string
}

interface PopularContentChartProps {
  data: Item[],
  dataName: string
}

function PopularContentChart({ data, dataName }: PopularContentChartProps) {
  // Translate
  const { t } = useTranslation(["global"]);
  //Custom Hook
  const isMobile = useScreenWidth(960);
  const isLaptop = useScreenWidth(1243);

  //Styles
  const classes = GraphsStyles();

  /* Functions */
  const findContent = (content_id: number): Item | null => {
    const contentIndex = data.findIndex((content) => content.foreign_id === content_id);
    if (contentIndex >= 0) {
      return data[contentIndex];
    }
    return null;
  }

  let responsiveWidth = !isMobile && isLaptop


  const renderCustomAxisTick = ({ x, y, payload }: any) => {
    const content = findContent(payload.value);

    return (
      <svg x={isMobile ? x - 70 : x - 110} y={y - 24} width={isMobile ? 60 : 90} height={60} viewBox="0 0 1736 1024" fill="#666">
        <foreignObject y="-100" width="100%" height="100%">
          <Box style={{ backgroundImage: `url(${content?.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100%', width: '100%', borderRadius: '16%' }} />
        </foreignObject>
      </svg>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box className={classes.surfaceTooltip} p={1}>
          <Typography>{payload[0].payload.foreign_name}</Typography>
          <Typography variant="body2">{`${dataName}: ${payload[0].value}`}</Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer height={360} className={classes.container} width={responsiveWidth ? 'calc(100% - 24px)' : '100%'}>
      <BarChart
        data={data}
        layout="vertical"
        barCategoryGap={16}
        margin={isMobile ? {} : { top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis type="number" />
        <YAxis type="category" dataKey="foreign_id" width={isMobile ? 75 : 110} tick={renderCustomAxisTick} tickSize={0} axisLine={false} tickMargin={3} />
        <CartesianGrid horizontal={false} strokeDasharray="3 3" />
        <Tooltip cursor={{ fill: '#E0E0E0' }} content={<CustomTooltip />} />
        <Bar dataKey="value" radius={10} fill="#34B3F1" />
      </BarChart>
    </ResponsiveContainer>
  );
}


export default PopularContentChart;
