// React
import { useEffect, useState, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// Material UI
import {
    Grid,
    Container,
    Box,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Tooltip,
    Fade,
    Paper,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
} from '@material-ui/core'
import {
    MoreVert,
    Delete,
    InfoOutlined,
    CloseRounded,
    DoubleArrow,
} from '@material-ui/icons/'
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../../config/table'

// Custom components
import CoursesModal from '../../../components/courses/CoursesModal'
import Header from '../../../components/common/header/Header'
import SubHeader from '../../../components/common/header/Subheader'
import ScrollSubheader from '../../../components/common/header/ScrollSubHeader'
import TypeFilter from '../../../components/common/header/TypeFilter'
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar'
import CustomChip from '../../../components/common/admin/CustomChip'
import ScreenLoading from '../../../components/common/ScreenLoading'
import CountriesFilter from '../../../components/common/admin/CountryFilter'
import ShareIcon from '../../../components/common/ShareIcon'
import HighlightIcon from '../../../components/common/admin/HighlightIcon'
import VisibilityIcon from '../../../components/common/admin/VisibilityIcon'
import AdminContentFilter from '../../../components/layouts/filters/AdminContentFilter'
import TagInput from '../../../components/common/admin/TagInput'
import NotFilter from '../../NotFilter'
import { CancelButton, DeleteButton } from '../../../components/common/forms'
import MassAssignmentModal from '../../../components/common/admin/MassAssignmentModal'

// Redux Actions
import {
    getFilteredCourses,
    showCourse,
    deleteCourse,
    toggleFormCourse,
    assignHighlight,
    storeMassAssignment,
} from '../../../redux/actions/courses'
import { getBrands } from '../../../redux/actions/brands'
import { getCoursetypes } from '../../../redux/actions/coursetypes'
import { getTags } from '../../../redux/actions/tags'
import { IRootState } from '../../../redux/types'

// Libraries
import { useTranslation } from 'react-i18next'

//Styles
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles'
import FormsStyles from '../../../styles/components/common/forms/FormsStyles'
import HiddenDesktop from '../../../components/common/HiddenDesktop'

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth'

// Types
type IType =
    | 'string'
    | 'boolean'
    | 'numeric'
    | 'date'
    | 'datetime'
    | 'time'
    | 'currency'
const string: IType = 'string'

type OptionsType = { [key: string]: number }

interface IFilter {
    family_id: number | null
    brand_id: number | null
    line_id: number | null
    language_id: number | null
    coursetype_id: number | null
    countries: number[]
    filterBy?: OptionsType
}

function Courses({ history }: RouteComponentProps) {
    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Styles
    const tableclass = TableScreenStyles()
    const formClasses = FormsStyles()

    //Redux Hooks
    const dispatch = useDispatch()
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { courses, showForm, reload, error, msg, loading } = useSelector(
        (state: IRootState) => state.course
    )
    const { brands } = useSelector((state: IRootState) => state.brand)
    const { coursetypes } = useSelector((state: IRootState) => state.coursetype)
    const {
        globalFamilyId,
        globalBrandId,
        globalLineId,
        globalCountriesId,
        globalLanguageId,
    } = useSelector((state: IRootState) => state.global)
    const {
        tags,
        error: errorTag,
        msg: msgTag,
    } = useSelector((state: IRootState) => state.tag)

    // Component States
    const [openDeleteCourse, setOpenDeleteCourse] = useState(false)
    const [courseSelected, setCourseSelected] = useState(false)
    const [rowsSelected, setRowsSelected] = useState<any>([])

    // Table Menu State
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null)
    const [openOptions, setOpenOptions] = useState(-1)

    // Filter State
    const [filters, setFilters] = useState<IFilter>({
        family_id: null,
        brand_id: null,
        line_id: null,
        language_id: null,
        coursetype_id: null,
        countries: [],
    })

    // Variables
    const status = [
        { id: 0, name: 'Inactivo' },
        { id: 1, name: 'Activo' },
        { id: 2, name: 'Borrador' },
    ]
    const visibility = [
        { id: 0, name: t('close') },
        { id: 1, name: 'VIP' },
        { id: 2, name: 'Pago' },
    ]
    const optionsData = [
        { data: status, code: 'status', column: 'status' },
        { data: visibility, code: 'visibility', column: 'visibility' },
        { data: tags, code: 'categories', column: 'tag_id' },
    ]

    // Ready Page
    useEffect(() => {
        if (!brands.length) dispatch(getBrands())
        if (!coursetypes.length) dispatch(getCoursetypes())
        if (!tags.length) dispatch(getTags())
    }, [])

    useEffect(() => {
        setFilters({
            ...filters,
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            language_id: globalLanguageId,
            coursetype_id: null,
            countries: globalCountriesId,
        })
    }, [
        globalFamilyId,
        globalBrandId,
        globalLineId,
        globalLanguageId,
        globalCountriesId,
    ])

    useEffect(() => {
        if (filters.countries.length > 0)
            dispatch(getFilteredCourses(filters))
    }, [filters])

    useEffect(() => {
        if (reload) {
            dispatch(getFilteredCourses(filters))
        }
    }, [reload])

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null)
        setOpenOptions(-1)
    }

    const handleChangeFilter = (coursetype_id: number) => {
        setFilters({ ...filters, coursetype_id })
    }

    const handleClickHighlight = (id: number, isHighlight: boolean) => {
        dispatch(
            assignHighlight({
                id,
                isHighlight,
            })
        )
    }

    const handleClickSetOptions = (options: any) => {
        dispatch(
            getFilteredCourses({
                brand_id: globalBrandId,
                line_id: globalLineId,
                language_id: globalLanguageId,
                countries: globalCountriesId,
                filterBy: options,
            })
        )
    }

    const myElement: HTMLElement | null =
        document.getElementById('windowCourseAdmin')
    let midOfWidth = window.innerWidth / 2

    const columns: any = [
        {
            title: t('name'),
            field: 'title',
            render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        {(() => {
                            switch (rowData.status) {
                                case 0:
                                    return (
                                        <CustomChip
                                            size="small"
                                            color={'secondary'}
                                        />
                                    )
                                case 1:
                                    return (
                                        <CustomChip
                                            size="small"
                                            color={'primary'}
                                        />
                                    )
                                case 2:
                                    return (
                                        <CustomChip
                                            size="small"
                                            color={'grey'}
                                        />
                                    )

                                default:
                                    return (
                                        <CustomChip
                                            size="small"
                                            color={'grey'}
                                        />
                                    )
                            }
                        })()}
                        <Tooltip
                            placement="bottom-start"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 400 }}
                            title={rowData.title}
                        >
                            <Typography className={tableclass.nameResource}>
                                {rowData.title}
                            </Typography>
                        </Tooltip>

                        <HighlightIcon
                            id={rowData.id}
                            isHighlight={rowData.highlight ? true : false}
                            handleClick={handleClickHighlight}
                        />
                        <VisibilityIcon id={rowData.visibility} />
                    </Box>
                )
            },
        },
        {
            title: '',
            field: 'tags',
            hidden: isMobile,
            render: (rowData: any) => (
                <TagInput
                    rowId={rowData.id}
                    rowTags={rowData.tags}
                    rowCountries={rowData.countries}
                    rowBrandId={rowData.brand_id}
                    page={'courses'}
                />
            ),
        },
        {
            title: t('brand'),
            field: 'brand.name',
            hidden: globalBrandId !== null,
        },
        {
            title: t('duration'),
            field: 'duration',
            hidden: isMobile,
        },
        {
            title: t('language'),
            field: 'language',
            render: (rowData: any) =>
                rowData.languages
                    .map((language: any) => {
                        return language.code.toUpperCase()
                    })
                    .join(' - '),
            hidden: isMobile,
            type: string,
        },
        {
            title: t('roles'),
            field: 'role',
            hidden: isMobile,
            type: string,
        },
        {
            title: '',
            field: '',
            render: (rowData: any) => (
                <>
                    <IconButton
                        className="row-options"
                        id={`icon-button-${rowData.id}`}
                        aria-controls="icon-menu"
                        aria-haspopup="false"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget)
                            setOpenOptions(rowData.id)
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id={`simple-menu-${rowData.id}`}
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}
                    >
                        <MenuItem
                            onClick={() =>
                                history.push(
                                    `cursos/${rowData.brand_id}/${rowData.slug}`
                                )
                            }
                        >
                            {t('view')}
                        </MenuItem>
                        <MenuItem
                            className="edit-row-option"
                            onClick={() => {
                                dispatch(showCourse(rowData.id))
                                dispatch(toggleFormCourse())
                            }}
                        >
                            {t('edit')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setOpenDeleteCourse(!openDeleteCourse)
                                setCourseSelected(rowData.id)
                            }}
                        >
                            {t('delete')}
                        </MenuItem>
                        <ShareIcon
                            visibility={rowData.visibility}
                            component="menuItem"
                            title={rowData.title}
                            description={rowData.description}
                            link={`${window.location.origin}/cursos/${rowData.brand_id}/${rowData.slug}${window.location.search}`}
                            type="course"
                            multimedia={rowData.image_url}
                        />
                    </Menu>
                </>
            ),
        },
    ]

    const massEditableFields = [
        'countries',
        'brand',
        'line',
        'resourcetype',
        'role',
        'languages',
        'visibility',
    ]

    const handleSubmitMassAssignment = (data: any) => {
        dispatch(storeMassAssignment(data))
    }

    return (
        <>
            {showForm && <CoursesModal />}

            <Header title={t('academy')} />
            <SubHeader>
                <ScrollSubheader id="windowCourseAdmin">
                    <TypeFilter
                        id={null}
                        type={t('all')}
                        categories={[]}
                        active={filters.coursetype_id === null}
                        handleChangeFilter={handleChangeFilter}
                    />
                    {coursetypes.map((coursetype: any, index: number) => (
                        <TypeFilter
                            key={coursetype.id}
                            id={coursetype.id}
                            type={coursetype.name}
                            categories={[]}
                            active={coursetype.id === filters.coursetype_id}
                            handleChangeFilter={handleChangeFilter}
                        />
                    ))}
                </ScrollSubheader>
                <HiddenDesktop>
                    <IconButton
                        className="buttonScroll"
                        onClick={() => {
                            myElement?.scrollBy(midOfWidth, 0)
                        }}
                    >
                        <DoubleArrow />
                    </IconButton>
                </HiddenDesktop>
            </SubHeader>

            <Paper></Paper>

            <Container maxWidth="lg" data-testid="courses-admin-container">
                {authUser !== null && (
                    <Box style={{ display: showForm ? 'none' : 'block' }}>
                        <Box
                            mt={isMobile ? 2 : 6}
                            gridGap={16}
                            display="flex"
                            flexDirection={
                                isMobile
                                    ? 'column-reverse'
                                    : 'column'
                            }
                        >
                            <Box display="flex" justifyContent="center">
                                <Typography variant="h5" align="center">
                                    {t('all')}
                                </Typography>
                                <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                    title={
                                        <Box>
                                            <Typography variant="body2">
                                                {t('status')}:
                                            </Typography>
                                            <Box
                                                display="flex"
                                                gridGap={8}
                                                alignItems="center"
                                            >
                                                <CustomChip
                                                    size="small"
                                                    color={'primary'}
                                                />
                                                <Typography variant="caption">
                                                    {t('active')}
                                                </Typography>
                                            </Box>
                                            <Box
                                                display="flex"
                                                gridGap={8}
                                                alignItems="center"
                                            >
                                                <CustomChip
                                                    size="small"
                                                    color={'secondary'}
                                                />
                                                <Typography variant="caption">
                                                    {t('inactive')}
                                                </Typography>
                                            </Box>
                                            <Box
                                                display="flex"
                                                gridGap={8}
                                                alignItems="center"
                                            >
                                                <CustomChip
                                                    size="small"
                                                    color={'grey'}
                                                />
                                                <Typography variant="caption">
                                                    {t('draft')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                >
                                    <IconButton>
                                        <InfoOutlined
                                            htmlColor={'#8F99A3'}
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box>
                                <Grid
                                    container
                                    justify="space-between"
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={5}
                                        sm={6}
                                        className={tableclass.actionsResponsive}
                                    >
                                        {rowsSelected.length === 0 && (
                                            <Button
                                                id="btn-create-course"
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() =>
                                                    dispatch(
                                                        toggleFormCourse(true)
                                                    )
                                                }
                                            >
                                                {t(
                                                    'admin.courses.create-course'
                                                )}
                                            </Button>
                                        )}
                                        <MassAssignmentModal
                                            rows={rowsSelected}
                                            fields={massEditableFields}
                                            handleSubmit={
                                                handleSubmitMassAssignment
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={7}
                                        sm={6}
                                        className={tableclass.actionsResponsiveRight}
                                    >
                                        <AdminContentFilter
                                            data={optionsData}
                                            handleClick={handleClickSetOptions}
                                        />
                                        <CountriesFilter />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        {!courses.length && !loading ? (
                            <NotFilter subsection={null} handleClearFilters={handleClickSetOptions} />
                        ) : (
                            <Box mt={3}>
                                <div className="table_custom">
                                    <MaterialTable
                                        localization={localization}
                                        title="Usuarios Admin"
                                        columns={columns}
                                        data={courses}
                                        options={{
                                            ...options,
                                            selection: true,
                                        }}
                                        icons={tableIcons}
                                        onSelectionChange={(rows) =>
                                            setRowsSelected(
                                                rows.map((row: any) => row.id)
                                            )
                                        }
                                    />
                                </div>
                            </Box>
                        )}
                    </Box>
                )}
            </Container>

            <Dialog
                open={openDeleteCourse}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
                onClose={() => setOpenDeleteCourse(!openDeleteCourse)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t('delete')} {t('course')}
                        </Typography>
                        <Button
                            onClick={() =>
                                setOpenDeleteCourse(!openDeleteCourse)
                            }
                            color="primary"
                            variant="text"
                        >
                            <CloseRounded />
                        </Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Typography>{t('confirm-delete')}</Typography>
                </DialogContent>
                <DialogActions>
                    <CancelButton
                        onClick={() => setOpenDeleteCourse(!openDeleteCourse)}
                    >
                        {t('cancel')}
                    </CancelButton>
                    <DeleteButton
                        autoFocus
                        id="btn-handleldelete"
                        endIcon={<Delete />}
                        onClick={() => {
                            dispatch(deleteCourse(courseSelected))
                            setOpenDeleteCourse(!openDeleteCourse)
                        }}
                    >
                        {t('delete')}
                    </DeleteButton>
                </DialogActions>
            </Dialog>

            <CustomSnackBar errors={[error, errorTag]} msgs={[msg, msgTag]} />
            <ScreenLoading loadings={[loading]} />
        </>
    )
}

export default Courses
