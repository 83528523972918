// Material UI
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

//Custom Hook
import useScreenWidth from '../../../../hooks/useScreenWidth';

// Styles
import SkeletonStyle from '../../../../styles/components/layouts/navigation/SkeletonStyle';

function HomeCalendarSkeleton() {

    //Custom Hook
    const isMobile = useScreenWidth(960);
    // Styles
    const classes = SkeletonStyle();

    return (
        <Grid container spacing={2} style={isMobile ? { paddingTop: 0 } : { padding: 16 }}>
            <Grid item xs={12} style={isMobile ? { paddingTop: 0 } : { padding: 16 }}>
                <Box>
                    <Skeleton variant='rect' animation='wave' height={694} width='100%' className={classes.sklborderRadius} />
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomeCalendarSkeleton