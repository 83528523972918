// Material UI
import { Typography, Grid, } from '@material-ui/core';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';

// Styles
import cardStyles from '../../styles/components/courses/CoursesStyles';
import VipStyles from '../../styles/components/common/VipStyles';


// Custom Style for LinearProgress Component
const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
            boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)'
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[50],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: theme.palette.success.main,
        },
        "@global": {
            '.complete div': {
                backgroundColor: theme.palette.success.main
            }
        }

    }),
)(LinearProgress);

// Types
type LinearProgressBarProps = {
    progress: number,
    vip?: boolean
}


function LinearProgressBar({ progress, vip }: LinearProgressBarProps) {
    // Styles
    const classes = cardStyles();
    const vipclasses = VipStyles();

    return (
        <Grid container spacing={1} justify="space-between" alignItems="center" style={{ marginBottom: 2 }}>
            <Grid item xs={8} sm={9}>
                {vip ?
                    <BorderLinearProgress variant="determinate" value={progress} className={progress === 100 ? 'complete' : ''} classes={{ bar: vipclasses.barProgress }} />
                    :
                    <BorderLinearProgress variant="determinate" value={progress} className={progress === 100 ? 'complete' : ''} />
                }
            </Grid>
            <Grid item xs={4} sm={3}>
                <Typography align="center" variant="body2" className={vip ? vipclasses.text : clsx(classes.percentage, progress === 100 ? 'complete' : '')}>
                    {progress} %
                </Typography>
            </Grid>
        </Grid>
    );
}

export default LinearProgressBar;
