import { makeStyles, createStyles, fade } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    tapForm: {
        "& .MuiTab-root": {
            border: '0px solid',
            borderBottom: `2px solid ${theme.palette.grey[200]}`,
            color: theme.palette.grey[400],
            height: "40px",
            minHeight: "auto",
            marginTop: 8,
            fontWeight: 500,
        },
        "& .Mui-selected": {
            borderBottom: `2px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
            border: '0px solid',
        },
        "& .MuiTabs-indicator": {
            display: 'none',
        },
        "& .MuiTabs-root": {
            marginBottom: '16px',
        },
    },
    bodyForm: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(2),
    },
    bodyFormLink: {
        paddingBottom: theme.spacing(3),
        paddingTop: 0,
        '& h6': {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        overflow: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '8px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingBottom: theme.spacing(3),
        },
    },
    containerForm: {
        "& .MuiDialog-paperScrollPaper": {
            overflow: 'hidden',
            width: '100%',
        },
        "& .dzu-dropzone": {
            overflow: 'auto',
            width: 'auto !important',
            height: '100%'
        },
        "& .dzu-inputLabel": {
            color: theme.palette.primary.light,
            "&:active": {
                backgroundColor: theme.palette.grey[100],
            },
            "&:hover": {
                backgroundColor: theme.palette.grey[100],
            }
        },
        "& .MuiFormControl-root": {
            marginBottom: '0px',
        },
        '& .Mui-error': {
            borderColor: theme.palette.warning.main,
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.main,
        },
        "& p.Mui-error": {
            backgroundColor: theme.palette.common.white,
        },
    },
    errorTab: {
        borderBottom: `2px solid ${theme.palette.warning.main} !important`,
        borderRadius: '8px 8px 0px 0px',
        color: `${theme.palette.warning.main} !important`,
        backgroundColor: theme.palette.warning.light,
    },
    errorDialogActions: {
        backgroundColor: theme.palette.warning.light,
    },
    inscription: {
        color: theme.palette.common.white + '!important',
        backgroundColor: theme.palette.grey[300] + '!important',
    },
    singup: {
        color: theme.palette.common.white + '!important',
        backgroundColor: theme.palette.secondary.light + '!important',
        "&:hover": {
            color: theme.palette.common.white + '!important',
            backgroundColor: theme.palette.success.main + '!important',
        },
    },
    search: {
        position: 'relative',
        borderRadius: '4px',
        backgroundColor: fade(theme.palette.grey[100], 1),
        '&:hover': {
            backgroundColor: fade(theme.palette.grey[200], 1),
        },
        maxHeight: 32,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: "0px 12px",
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.secondary.main,
    },
    inputRoot: {
        color: 'inherit',
        maxHeight: 32,
        borderRadius: '4px',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    inputSelectAll: {
        color: theme.palette.secondary.main,
        textDecoration: "underline",
    },
    listCountries: {
        maxHeight: 300,
        overflow: "auto",
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '6px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        "& .MuiFormControlLabel-root": {
            maxWidth: 270,
            minWidth: 270,
            margin: 0,
        },
        "& .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
    },
    alertSnackbar: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.dark,
        borderRadius: 8,
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        textTransform: 'lowercase',
        minHeight: 65,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
            color: theme.palette.common.white,
        },
    },
}));