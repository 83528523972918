// React
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'

// MATERIAL UI
import { Drawer, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Assets
import logoDRV from '../../../assets/Images/drv/drvLogo.svg';
import logoDRVLight from '../../../assets/Images/drv/logo-drv-blanco.png';

// Redux Actions 
import { IRootState } from '../../../redux/types';

// Custom components
import AdminMenu from './admin/AdminMenu';
import BrandFilter from '../filters/BrandFilter';
import HiddenMobile from '../../common/HiddenMobile';

// Styles
import LayoutSyltes from '../../../styles/components/layouts/MainLayout';

interface SidebarProps {
    theme: string;
}

function Sidebar({ theme }: SidebarProps) {
    // Redux Hooks
    const { userBrands } = useSelector((state: IRootState) => state.brand);
    const { globalBrandId, globalLineId } = useSelector((state: IRootState) => state.global);

    // Styles
    const layoutClasses = LayoutSyltes();

    //Location
    const location = useLocation();
    let isAdmin = location.pathname.includes('admin') || (location.pathname === '/escritorio' || location.pathname === '/usuarios/perfil');

    return (
        <>
            {window.location.pathname !== "/terms" &&
                <HiddenMobile>
                    {window.location.pathname !== '/inicio' &&
                        <>
                            {globalBrandId != null && userBrands.find((brand: any) => brand.id === globalBrandId) !== undefined &&
                                <Box className={clsx(layoutClasses.containerLogo, (isAdmin) && 'dark')}>
                                <img src={process.env.REACT_APP_AWS_URL + '/brands/' + (isAdmin ? userBrands.find((brand: any) => brand.id === globalBrandId).light_logo : userBrands.find((brand: any) => brand.id === globalBrandId).dark_logo)}
                                        alt="Logo Marca"
                                        className={layoutClasses.logo + ' ' + theme}></img>
                                </Box>}
                            {globalBrandId == null &&
                                <Box className={clsx(layoutClasses.containerLogo, isAdmin && 'dark')}>
                                    <img src={isAdmin ? logoDRVLight : logoDRV}
                                        alt="drv logo"
                                        className={layoutClasses.logo + ' ' + theme}></img>
                                </Box>}

                            {/* BrandLine name */}
                            {globalBrandId != null && globalLineId != null && userBrands.find((brand: any) => brand.id === globalBrandId).active_lines.find((brandline: any) => brandline.id === globalLineId) !== undefined &&
                                <Box className={layoutClasses.lineBox + ' ' + theme}>
                                    <Typography variant='body2'>
                                        {userBrands.find((brand: any) => brand.id === globalBrandId).active_lines.find((brandline: any) => brandline.id === globalLineId).name}
                                    </Typography>
                                </Box>
                            }

                            <Drawer
                                classes={{ paper: clsx(layoutClasses.drawerPaper, theme, (globalLineId === null) && 'lessMargin') }}
                                variant="permanent"
                                open>
                                {theme === 'admin' && <AdminMenu />}

                                <BrandFilter layout={theme} />

                            </Drawer>
                        </>
                    }
                </HiddenMobile>
            }
        </>
    );
}

export default Sidebar;