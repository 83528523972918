// React
import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

// Redux Actions
import { getUserbrands } from '../../redux/actions/brands';
import { verifyUser } from '../../redux/actions/auth'
import { IRootState } from '../../redux/types';

const PublicRoute = ({component: Component, ...props}: any) => {
    // Redux Hooks
    const dispatch = useDispatch();
    const { userBrands } = useSelector((state: IRootState) => state.brand);

    // Ready Page
    useEffect(() => {
        if (localStorage.getItem('isAuth') !== null){
            dispatch(verifyUser());

            if (!userBrands.length)
                dispatch(getUserbrands());
        }
    }, []);

    return ( 
        <Route { ...props } render={ (props: any) => 
            <Component {...props} /> }
        />
    );
}
 
export default PublicRoute;