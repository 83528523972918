import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

// Material UI
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@material-ui/core";

//Custom components
import Hero from "../../components/helpcenter/Hero";

// Redux
import { IRootState } from "../../redux/types";

//Assets
import flagIcon from "../../assets/vector/flagHC.svg";
import videoIcon from "../../assets/vector/videoHC.svg";
import qaIcon from "../../assets/vector/qaHC.svg";

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import HelpCenterStyles from "../../styles/components/HelpCenterStyles";

const Home = ({ history }: RouteComponentProps) => {
  // Translation
  const { t } = useTranslation(["helpcenter"]);

  const { user: authUser } = useSelector((state: IRootState) => state.auth);

  // Styles
  const classes = HelpCenterStyles();
  const data = [{ title: "" }];

  const handleSection = (section: string) => {
    history.push("/ayuda/tutoriales/client/" + section + "/0");
  };

  const handleContact = () => {
    history.push("/ayuda/contacto");
  };

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Hero />
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        gridGap={64}
        className={classes.containerCards}
      >
        <Card className={classes.cardContainer}>
          <CardActionArea
            classes={{
              root: classes.focusCard,
              focusHighlight: classes.cardHover,
            }}
            onClick={() => {
              history.push("/ayuda/comoempezar");
            }}
          >
            <CardMedia
              className={classes.cardIcon}
              image={flagIcon}
              title={t("how-start")}
            />
            <CardContent className={classes.textCard}>
              <Typography variant="h5">{t("how-start")}</Typography>
              <Typography>{t("card1")}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card classes={{ root: classes.cardContainer }}>
          <CardActionArea
            classes={{
              root: classes.focusCard,
              focusHighlight: classes.cardHover,
            }}
            onClick={() => history.push("/ayuda/tutoriales/client")}
          >
            <CardMedia
              className={classes.cardIcon}
              image={videoIcon}
              title={t("card2t")}
            />
            <CardContent className={classes.textCard}>
              <Typography variant="h5">{t("card2t")}</Typography>
              <Typography>{t("card2")}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        {[1, 2, 3, 4, 5, 8, 9].includes(authUser.role_id) && (
          <Card className={classes.cardContainer}>
            <CardActionArea
              classes={{
                root: classes.focusCard,
                focusHighlight: classes.cardHover,
              }}
              onClick={() => history.push("/ayuda/tutoriales/admin")}
            >
              <CardMedia
                className={classes.cardIcon}
                image={qaIcon}
                title={t("card3t")}
              />
              <CardContent className={classes.textCard}>
                <Typography variant="h5">{t("card3t")}</Typography>
                <Typography>{t("card3")}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      </Box>
      <Container style={{ maxWidth: "1020px" }}>
        <Box
          pt={9}
          display="flex"
          justifyContent="center"
          gridGap={48}
          flexWrap="wrap"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSection("about-plei")}
            classes={{ root: classes.buttonOption }}
          >
            Acerca de {process.env.REACT_APP_NAME}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSection("profile")}
            classes={{ root: classes.buttonOption }}
          >
            Mi perfil
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSection("library")}
            classes={{ root: classes.buttonOption }}
          >
            Biblioteca
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSection("academy")}
            classes={{ root: classes.buttonOption }}
          >
            Formación
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSection("events")}
            classes={{ root: classes.buttonOption }}
          >
            Eventos
          </Button>

          {/*
                        <Button variant="outlined" color="primary" onClick={() => handleSection('slider')} classes={{root: classes.buttonOption}}>Personas</Button>
                        <Button variant="outlined" color="primary" onClick={() => handleSection('offers')} classes={{root: classes.buttonOption}}>Ofertas</Button>
                    */}
        </Box>
        <Box
          pt={15}
          textAlign="center"
          display="flex"
          flexDirection="column"
          gridGap={8}
          alignItems="center"
        >
          <Typography variant="h5">{t("morehelp")}</Typography>
          <Typography color="textSecondary">{t("team")}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleContact}
            classes={{ root: classes.buttonContact }}
          >
            {t("contactus")}
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default Home;
