// React
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

// Redux Actions
import { createDisplaytime } from "../redux/actions/userdisplaytime";
import { IRootState } from "../redux/types";

// Libraries
import moment, { Moment } from "moment";

// Types
interface IUserdisplaytime {
    id: number,
    displaytime: number
}
interface UserdisplaytimeProps {
    foreign: any
}

const useUserdisplaytime = ({ foreign }: UserdisplaytimeProps) => {

    const [lastActivity, setLastActivity] = useState<Moment | null>(null);

    const dispatch = useDispatch();
    const { isAuth } = useSelector((state: IRootState) => state.auth);
    const { displaytimeSelected } = useSelector((state: IRootState) => state.userdisplaytime);

    const location = useLocation();

    const saveCacheDisplaytime = useCallback(() => {
        let cacheDisplaytime = localStorage.getItem('userdisplaytime');
        if(cacheDisplaytime !== null){
            let newDisplaytime: IUserdisplaytime = JSON.parse(cacheDisplaytime);
            if (newDisplaytime !== null){
                newDisplaytime['displaytime'] = Number(newDisplaytime['displaytime'] ?? '0') + 2;
                localStorage.setItem('userdisplaytime', JSON.stringify(newDisplaytime));
            }
        }else{
            localStorage.setItem('userdisplaytime', JSON.stringify(displaytimeSelected));
        }
    }, [displaytimeSelected]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(lastActivity !== null){
                saveCacheDisplaytime();
            }
        }, 2000);

        return () => {
            clearInterval(intervalId);
        }
    }, [lastActivity, saveCacheDisplaytime]);

    useEffect(() => {
        if(foreign !== null && displaytimeSelected === null && isAuth){
            const section = location.pathname.split('/');
            dispatch(createDisplaytime({
                menu: section[1],
                foreign_id: foreign.id
            }));
        }
    }, [foreign]);

    useEffect(() => {
        // Start count second on page
        if(displaytimeSelected !== null){
            window.addEventListener('blur', onBlur);
            window.addEventListener('focus', onFocus);
            setLastActivity(moment());
        }
    }, [displaytimeSelected]);

    const onFocus = () => {
        setLastActivity(moment());
    }

    const onBlur = () => {
        setLastActivity(null);
        saveCacheDisplaytime();
    }
}

export default useUserdisplaytime;