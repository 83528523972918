
// Material UI
import { Box, makeStyles } from '@material-ui/core';
import { Description } from '@material-ui/icons/';

// Custom components
import AdobeEmbedApi from '../../common/client/AdobeEmbedApi';
import ReactPdfViewer from '../../common/client/ReactPdfViewer';

// Custom Styles
import ResourceStyle from '../../../styles/components/library/ResourceStyle';

// Types
type TypeFileViewProps = {
    file: any,
    type: string
}



function TypeFileView({ file, type }: TypeFileViewProps) {

    // Styles
    const resourceClasses = ResourceStyle();

    const TypeFile = () => {

        const fileNameExtension = file.ext === null ? '' : file.ext;

        const $imageExtensions = ['jpg', 'JPG', 'gif', 'png', 'jpeg', 'bmp', 'jfif', 'tif'];
        const $fileExtensions = ['pdf', ''];
        const $videoExtensions = ['mp4', 'mov', 'wmv', 'avi'];
        if ($imageExtensions.includes(fileNameExtension)) {
            return (
                <img
                    src={`${encodeURI(`${process.env.REACT_APP_AWS_URL}/${type}/${file.file_name}`)}`}
                    title={file.file_name}
                    alt={file.file_name}
                    className={resourceClasses.img} />
            )

        } else if ($videoExtensions.includes(fileNameExtension)) {
            return (
                <Box className={resourceClasses.media}>
                    <video src={`${process.env.REACT_APP_AWS_URL}/${type}/${file.file_name}`} width="100%" height="100%" controls>
                        Your browser does not support HTML <code>video</code>.
                    </video>
                </Box>
            )
        } else if ($fileExtensions.includes(fileNameExtension)) {
            return (
                // <AdobeEmbedApi fileName={file.file_name} fileUrl={`${process.env.REACT_APP_AWS_URL}/${type}/${file.file_name}`} />
                <ReactPdfViewer fileName={file.file_name} fileUrl={`${process.env.REACT_APP_AWS_URL}/${type}/${file.file_name}`} />
            )
        } else {
            return (
                <Box className={resourceClasses.media + ' detail'}>
                    <Description fontSize="large" color="inherit" />
                </Box>
            )
        }
    }

    return <TypeFile />

}

export default TypeFileView;