// React
import {
    useState,
    useEffect,
    forwardRef,
    Ref,
    ReactElement,
    ChangeEvent,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Slide,
    Tab,
    Tabs,
    Box,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    IconButton,
    Grid,
} from '@material-ui/core'
import { Delete, AddCircle, CloseRounded } from '@material-ui/icons'
import { TransitionProps } from '@material-ui/core/transitions'
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table'

// Commons components
import { SaveButton, CancelButton, DeleteButton } from '../common/forms'
import CustomSnackBar from '../common/admin/CustomSnackBar'
import ScreenLoading from '../common/ScreenLoading'
import InputCountry from '../common/admin/modal/InputCountry'
import CountriesFilter from '../common/admin/CountryFilter'
import TabPanel from '../common/admin/TabPanel'

// Redux Actions
import {
    getResourcecategories,
    showResourcecategory,
    storeResourcecategory,
    updateResourcecategory,
    deleteResourcecategory,
    toggleFormResourcecategories,
} from '../../redux/actions/resourcecategories'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'
import { getResourcetypes } from '../../redux/actions/resourcetypes'
import { IRootState } from '../../redux/types'
import Header from '../common/header/Header'
import { getCountries } from '../../redux/actions/countries'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'

// Types
type IType =
    | 'string'
    | 'boolean'
    | 'numeric'
    | 'date'
    | 'datetime'
    | 'time'
    | 'currency'
const string: IType = 'string'

interface IResourcecategory {
    id: number
    name: string
    resourcetype_id: number
    countries: any
    translations: any
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

function Resourcecategories() {
    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Styles
    const formClasses = FormsStyles()

    //Redux Hooks
    const dispatch = useDispatch()
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { globalLanguageId, globalCountriesId } = useSelector(
        (state: IRootState) => state.global
    )
    const {
        resourcecategories,
        resourcecategorySelected,
        showForm,
        error,
        msg,
        loading,
    } = useSelector((state: IRootState) => state.resourcecategory)
    const {
        resourcetypes,
        error: errorResourcetype,
        msg: msgResourcetype,
    } = useSelector((state: IRootState) => state.resourcetype)
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector(
        (state: IRootState) => state.validator
    )

    // Resource Category State
    const [resourcecategory, setResourcecategory] = useState<IResourcecategory>(
        {
            id: 0,
            name: '',
            resourcetype_id: 1,
            countries: [],
            translations: [],
        }
    )

    //Tab State
    const [currentTab, setCurrentTab] = useState(0)
    // Ready Page
    useEffect(() => {
        dispatch(getResourcetypes())
        dispatch(getCountries())
    }, [])

    useEffect(() => {
        if (globalLanguageId !== null) {
            dispatch(
                getResourcecategories({
                    languageId: globalLanguageId,
                    countriesId: globalCountriesId,
                })
            )
            dispatch(getResourcetypes())
        }
    }, [globalLanguageId])

    useEffect(() => {
        if (globalCountriesId !== null)
            dispatch(
                getResourcecategories({
                    languageId: globalLanguageId,
                    countriesId: globalCountriesId,
                })
            )
    }, [globalCountriesId])

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator())

            if (resourcecategory.id === 0)
                dispatch(storeResourcecategory(resourcecategory))
            else dispatch(updateResourcecategory(resourcecategory))
        }
    }, [isValidForm])

    useEffect(() => {
        if (
            resourcecategorySelected !== null &&
            resourcecategorySelected.id !== resourcecategory.id
        ) {
            setResourcecategory({
                id: resourcecategorySelected.id,
                name: resourcecategorySelected.name,
                resourcetype_id: resourcecategorySelected.resourcetype_id,
                countries: resourcecategorySelected.countries,
                translations: resourcecategorySelected.translations,
            })
        }
    }, [resourcecategorySelected])

    /* Functions */
    const handleInputChange = (nameInput: string) => (event: any) => {
        const value = event.target.value
        setResourcecategory({ ...resourcecategory, [nameInput]: value })
    }

    const handleChange = (inputName: string) => (event: any) => {
        setResourcecategory({
            ...resourcecategory,
            [inputName]: event.target.value,
        })
    }

    const handleSubmit = () => {
        dispatch(
            formValidator({
                name: [resourcecategory.name, 'required'],
                resourcetype_id: [resourcecategory.resourcetype_id, 'required'],
                countries: [resourcecategory.countries, 'notempty'],
            })
        )
    }

    const handleModalCreate = () => {
        setResourcecategory({
            id: 0,
            name: '',
            resourcetype_id: 1,
            countries: [],
            translations: [],
        })

        dispatch(toggleFormResourcecategories())
    }

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue)
    }

    const handleState = (inputName: string, value: any) => {
        setResourcecategory({ ...resourcecategory, [inputName]: value })
    }

    const addTranslation = () => {
        const newTranslation = { language_id: 1, text: '' }
        let stateValue = resourcecategory.translations
        stateValue.push(newTranslation)

        setResourcecategory({ ...resourcecategory, translations: stateValue })
    }

    const deleteTranslation = (key: number) => {
        let stateValue = [...resourcecategory.translations]
        stateValue.splice(key, 1)

        setResourcecategory({ ...resourcecategory, translations: stateValue })
    }

    const handleChangeTranslations =
        (nameInput: string, key: number) => (event: any) => {
            let stateValue = resourcecategory.translations
            stateValue[key][nameInput] = event.target.value

            setResourcecategory({
                ...resourcecategory,
                translations: stateValue,
            })
        }

    const columns: any = [
        { title: 'Id', field: 'id', type: string },
        { title: t('name'), field: 'name', type: string },
        { title: t('type'), field: 'resourcetype_name', type: string },
    ]

    return (
        <>
            <Header title={t('admin.library.library-categories')} />
            <ScreenLoading loadings={[loading]} />

            <Box pr={3} pl={3}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleModalCreate()}
                >
                    {t('admin.library.create-category')}
                </Button>

                <CountriesFilter />

                <div className="table_custom">
                    <MaterialTable
                        localization={localization}
                        title="Marcas"
                        columns={columns}
                        data={resourcecategories}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: t('edit'),
                                onClick: (event: any, rowData: any) => {
                                    dispatch(showResourcecategory(rowData.id))
                                },
                            },
                            {
                                icon: 'delete',
                                tooltip: t('delete'),
                                onClick: (event: any, rowData: any) => {
                                    dispatch(deleteResourcecategory(rowData.id))
                                },
                            },
                        ]}
                        options={options}
                        icons={tableIcons}
                    />
                </div>

                <Dialog
                    open={showForm}
                    fullWidth={true}
                    fullScreen={isMobile && true}
                    maxWidth={!isMobile && "md"}
                    className={formClasses.containerForm}
                    TransitionComponent={Transition}
                    onClose={() => dispatch(toggleFormResourcecategories())}
                    aria-describedby="alert-dialog-description"
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t('admin.library.create-category')}{' '}
                                {msgForm.id}
                            </Typography>
                            <Button
                                onClick={() =>
                                    dispatch(toggleFormResourcecategories())
                                }
                                color="primary"
                                variant="text"
                            >
                                <CloseRounded />
                            </Button>
                        </Grid>
                    </DialogTitle>

                    <DialogContent className={formClasses.bodyForm}>
                        <Grid
                            container
                            alignItems="flex-start"
                            justify="space-between"
                            spacing={2}
                        >
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel shrink={true} id="brands-label">
                                        {t('type') + '*'}
                                    </InputLabel>
                                    <Select
                                        value={resourcecategory.resourcetype_id}
                                        labelId="brands-label"
                                        id="brands-select-outlined"
                                        fullWidth
                                        label={t('type')}
                                        onChange={handleChange(
                                            'resourcetype_id'
                                        )}
                                    >
                                        {resourcetypes.map(
                                            (resourcetype: any) => (
                                                <MenuItem
                                                    key={resourcetype.id}
                                                    value={resourcetype.id}
                                                >
                                                    {resourcetype.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputCountry
                                        value={resourcecategory.countries}
                                        handleInputChange={handleState}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Tabs
                                value={currentTab}
                                indicatorColor="secondary"
                                textColor="primary"
                                onChange={handleChangeTab}
                                className={formClasses.tapForm}
                                aria-label="disabled tabs example"
                            >
                                <Tab
                                    label={t('general') + '-' + t('language')}
                                    {...a11yProps(0)}
                                    className={
                                        errorTab.general
                                            ? formClasses.errorTab
                                            : ''
                                    }
                                />
                            </Tabs>
                        </Grid>
                        <TabPanel value={currentTab} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        autoFocus
                                        id="name"
                                        label={t('name')}
                                        type="text"
                                        variant="filled"
                                        value={resourcecategory.name}
                                        error={errorForm.name}
                                        helperText={msgForm.name}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleInputChange('name')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            shrink={true}
                                            id="languages-label"
                                        >
                                            {t('languages') + '*'}
                                        </InputLabel>
                                        {authUser !== null && (
                                            <Select
                                                value={
                                                    resourcecategory
                                                        .translations
                                                        .language_id
                                                }
                                                labelId="languages-label"
                                                id={
                                                    'languages-select-outlined' +
                                                    resourcecategory
                                                        .translations.index
                                                }
                                                fullWidth
                                                label={t('languages')}
                                                onChange={handleChangeTranslations(
                                                    'language_id',
                                                    resourcecategory
                                                        .translations.index
                                                )}
                                            >
                                                {authUser.languages_name.map(
                                                    (
                                                        language: any,
                                                        index: number
                                                    ) => (
                                                        <MenuItem
                                                            key={language.id}
                                                            value={language.id}
                                                        >
                                                            {language.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <IconButton
                                        aria-label="add"
                                        color="primary"
                                        size="medium"
                                        onClick={() => addTranslation()}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        color="primary"
                                        size="medium"
                                        onClick={() =>
                                            deleteTranslation(
                                                resourcecategory.translations
                                                    .index
                                            )
                                        }
                                    >
                                        <Delete />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {resourcecategory.translations.map(
                            (translation: any, index: number) => (
                                <Grid container spacing={2} key={translation.text}>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            id={'txt-text' + index}
                                            label={t('name')}
                                            type="text"
                                            variant="filled"
                                            value={translation.text}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChangeTranslations(
                                                'text',
                                                index
                                            )}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <FormControl fullWidth variant="filled">
                                            <InputLabel
                                                shrink={true}
                                                id="languages-label"
                                            >
                                                {t('languages') + '*'}
                                            </InputLabel>
                                            <Select
                                                value={translation.language_id}
                                                labelId="languages-label"
                                                id={
                                                    'languages-select-outlined' +
                                                    index
                                                }
                                                fullWidth
                                                label={t('languages')}
                                                onChange={handleChangeTranslations(
                                                    'language_id',
                                                    index
                                                )}
                                            >
                                                {authUser.languages_name.map(
                                                    (
                                                        language: any,
                                                        index: number
                                                    ) => (
                                                        <MenuItem
                                                            key={language.id}
                                                            value={language.id}
                                                        >
                                                            {language.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <IconButton
                                            aria-label="add"
                                            color="primary"
                                            size="medium"
                                            onClick={() => addTranslation()}
                                        >
                                            <AddCircle />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            color="primary"
                                            size="medium"
                                            onClick={() =>
                                                deleteTranslation(
                                                    resourcecategory
                                                        .translations.index
                                                )
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </DialogContent>

                    <DialogActions
                        className={
                            !isValidForm && Object.keys(msgForm).length > 0
                                ? formClasses.errorDialogActions
                                : ''
                        }
                    >
                        {/* Delete no esta cerrando el modal, y el dispatch no se si esta apuntando bien el id */}
                        <DeleteButton
                            onClick={() =>
                                dispatch(
                                    deleteResourcecategory(
                                        resourcecategories.id
                                    )
                                )
                            }
                        >
                            {t('delete')}
                        </DeleteButton>
                        <CancelButton
                            onClick={() =>
                                dispatch(toggleFormResourcecategories())
                            }
                        >
                            {t('cancel')}
                        </CancelButton>
                        <SaveButton onClick={handleSubmit}>
                            {t('save')}
                        </SaveButton>
                    </DialogActions>
                </Dialog>
            </Box>

            <CustomSnackBar
                errors={[error, errorResourcetype]}
                msgs={[msg, msgResourcetype]}
            />
        </>
    )
}

export default Resourcecategories
