// React
import React, { useState, useEffect, ChangeEvent, MouseEvent, forwardRef, ReactElement, Ref, } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Container, DialogActions, Dialog, DialogTitle, DialogContent, Button, Slide,
    Box, Menu, Typography, MenuItem, IconButton, Grid, Tab, Tabs, TextField, FormHelperText
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { MoreVert, CloseRounded } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Custom components
import { SaveButton, CancelButton, DatePicker } from '../common/forms';
import CustomSwitchComponent from '../common/SwitchCustom';
import TabPanel from '../common/admin/TabPanel';
import CustomChip from '../common/admin/CustomChip'

// Redux Actions
import { getUserbrands } from '../../redux/actions/brands';
import { assingBrandOrLine, assingVipContent, showVipContent, disableVipContent, requestVipContent, denyVipContent } from '../../redux/actions/users';
import { formValidator, resetFormValidator } from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import moment, { Moment } from 'moment';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

// Types
interface IVipContent {
    id: null | number,
    mode: string,
    type: number,
    line_ids: number[],
    date_ini: null | string,
    date_end: null | string,
    user_id: null | number,
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UserBrands() {
    // Translate
    const { t } = useTranslation(["global"]);
    // Style
    const formClasses = FormsStyles();
    //Custom Hook
    const isMobile = useScreenWidth(700);

    /* Global Variables */
    const types = [{ id: 0, name: t('admin.people.temporary') }, { id: 1, name: t('admin.people.permanent') }];

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userProfileSelected, vipContentSelected } = useSelector((state: IRootState) => state.user);
    const { userBrands } = useSelector((state: IRootState) => state.brand);
    const { isValidForm, errorForm, errorTab, msgForm } = useSelector((state: IRootState) => state.validator);

    // vipContent State
    const [vipContentState, setVipContentState] = useState<IVipContent>({
        id: null,
        mode: '',
        type: 1,
        line_ids: [],
        date_ini: null,
        date_end: null,
        user_id: null
    });
    // Component State
    const [date_ini, setDateIni] = useState<Moment | null>(null);
    const [date_end, setDateEnd] = useState<Moment | null>(null);

    // tmpBrands State
    const [tmpBrands, setTmpBrands] = useState([]);
    // Open State
    const [open, setOpen] = useState(false);
    // VIP State
    const [tmpBrandLines, setTmpBrandLines] = useState([]);
    //Tabs State
    const [currentTab, setCurrentTab] = useState(0);
    // Table Menu States
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState<number | string>(-1);

    // Ready Page
    useEffect(() => {
        dispatch(getUserbrands())
    }, []);

    useEffect(() => {
        if (userProfileSelected !== null) {
            let testBrands: any = [];
            if (authUser.role_id === 6 || userProfileSelected.id === authUser.id) {
                testBrands = [...userProfileSelected.brands, ...userProfileSelected.brandlines]
            } else {
                userBrands.map((brand: any) => {
                    testBrands = [...testBrands, ...userBrands, ...brand.active_lines]
                })
            }
            setTmpBrands(testBrands)
        }
    }, [userBrands, userProfileSelected]);

    useEffect(() => {
        if (vipContentSelected !== null) {
            setVipContentState({
                id: vipContentSelected.id,
                mode: vipContentSelected.mode,
                type: (vipContentSelected.type === null) ? 1 : vipContentSelected.type,
                line_ids: tmpBrandLines.map((brandline: any) => brandline.pivot.id),
                date_ini: null,
                date_end: null,
                user_id: vipContentSelected.user_id
            });

            (vipContentSelected.date_ini != null) && setDateIni(moment(vipContentState.date_ini));
            (vipContentSelected.date_end != null) && setDateEnd(moment(vipContentState.date_end));
        }
    }, [vipContentSelected]);

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator());
            let tmpData = { ...vipContentState };
            if (tmpData.type === 0) {
                tmpData.date_ini = moment(date_ini).format('YYYY-MM-DD HH:mm:ss');
                tmpData.date_end = moment(date_end).format('YYYY-MM-DD HH:mm:ss');
            }
            dispatch(assingVipContent(tmpData));
            handleClose();
        }
    }, [isValidForm]);

    /* Functions */
    const handleAssignBrandOrLine = (id: number, type: string) => (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(assingBrandOrLine(
            {
                id,
                type,
                checked: event.target.checked,
                user_id: userProfileSelected.id
            }
        ))
    };

    const handleDisableVipContent = (id: number, type: string) => (event: MouseEvent<HTMLLIElement>) => {
        dispatch(disableVipContent(
            {
                id,
                type,
                user_id: userProfileSelected.id
            }
        ))
    };

    const handleMultiAutoCompleteChange = (inputName: string) => (event: any, value: any) => {
        let optionsMulti = [];
        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                optionsMulti.push(value[i].pivot.id);
            }
        }
        setVipContentState({ ...vipContentState, [inputName]: optionsMulti });
    }

    const handleNumberChange = (inputName: string) => (event: any, value: any) => {
        if (value != null)
            setVipContentState({ ...vipContentState, [inputName]: value.id });
        else
            setVipContentState({ ...vipContentState, [inputName]: value });
    }

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const handleOpen = (id: number, lines: any, mode: string) => () => {
        if (lines.length > 0) {
            let tmpBrandLines: any = [];
            lines.map((line: any) => {
                let tmpBrandLine = userProfileSelected.brandlines.find((ln: any) => line.id === ln.id);
                if (tmpBrandLine !== undefined)
                    tmpBrandLines.push(tmpBrandLine);
            })
            setTmpBrandLines(tmpBrandLines);
        } else
            setTmpBrandLines([]);
        dispatch(showVipContent({
            id,
            mode,
            user_id: userProfileSelected.id
        }))
        setVipContentState({ ...vipContentState, mode })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setVipContentState({
            id: null,
            mode: '',
            type: 1,
            line_ids: [],
            date_ini: null,
            date_end: null,
            user_id: userProfileSelected.id
        });
    };

    const handleSubmit = () => {
        dispatch(formValidator({
            type: [vipContentState.type, 'numeric', 'general'],
        }))
    }

    const handleRequestVip = (id: number, type: string) => {
        dispatch(requestVipContent({ id, type, user_id: userProfileSelected.id }));
    }

    const handleDenyVip = (id: number, type: string) => {
        dispatch(denyVipContent({ id, type, user_id: userProfileSelected.id }));
    }

    const columns: any = [
        { title: t("name"), field: 'name' },
        {
            title: "", field: "", render: (rowData: any) => {
                let tmpVipContent = undefined;
                // Search brand or brandline
                if (rowData.brand_id === undefined) {
                    tmpVipContent = userProfileSelected.brands.find((brand: any) => brand.id === rowData.id);
                } else {
                    tmpVipContent = userProfileSelected.brandlines.find((brandline: any) => brandline.id === rowData.id);
                }

                if (tmpVipContent !== undefined) {
                    if (tmpVipContent.pivot.status === 0 && [4, 6, 7, 8, 9].includes(userProfileSelected.role_id) && ![1, 2, 3].includes(authUser.role_id)) {
                        return (
                            <Button style={{ margin: '10px 0px' }} id="btn-request-vip" variant="outlined" color="secondary" onClick={() => (rowData.brand_id === undefined) ? handleRequestVip(rowData.id, 'brand') : handleRequestVip(rowData.id, 'line')}>
                                {t("admin.people.request-vip")}
                            </Button>
                        )
                    }
                    if (tmpVipContent.pivot.status === 2 && userProfileSelected.id !== authUser.id && ![4, 6, 7, 8, 9].includes(authUser.role_id)) {
                        return (
                            <Button style={{ margin: '10px 0px' }} id="btn-request-vip" variant="outlined" color="secondary" onClick={() => (rowData.brand_id === undefined) ? handleDenyVip(rowData.id, 'brand') : handleDenyVip(rowData.id, 'line')}>
                                {t("admin.people.deny-vip")}
                            </Button>
                        )
                    }
                }
            }
        },
        {
            title: "VIP", field: 'type', render: (rowData: any) => {
                let tmpVipContent = undefined;
                // Search brand or brandline
                if (rowData.brand_id === undefined) {
                    tmpVipContent = userProfileSelected.brands.find((brand: any) => brand.id === rowData.id);
                } else {
                    tmpVipContent = userProfileSelected.brandlines.find((brandline: any) => brandline.id === rowData.id);
                }

                if (tmpVipContent !== undefined) {
                    if (tmpVipContent.pivot.status === 2) {
                        return (
                            <Box display="flex" alignItems="center" gridGap={8}>
                                {(userProfileSelected.brandsIds.includes(rowData.id))}
                                <CustomChip type={2} color="secondary" label={t('admin.people.pending')} />
                            </Box>
                        )
                    }

                    if (tmpVipContent.pivot.status === 3) {
                        return (
                            <Box display="flex" alignItems="center" gridGap={8}>
                                {(userProfileSelected.brandsIds.includes(rowData.id))}
                                <CustomChip type={2} color="secondary" label={t('admin.people.denied')} />
                            </Box>
                        )
                    }

                    if (tmpVipContent.pivot.status === 0) {
                        return (
                            <Box display="flex" alignItems="center" gridGap={8}>
                                {(userProfileSelected.brandsIds.includes(rowData.id))}
                                <CustomChip type={2} color="default" label={t('admin.people.unassigned')} />
                            </Box>
                        )
                    }

                    if (tmpVipContent.pivot.type === 1 || (![4, 6, 7, 8, 9].includes(userProfileSelected.role_id))) {
                        return (
                            <Box display="flex" alignItems="center" gridGap={8}>
                                {(userProfileSelected.brandsIds.includes(rowData.id))}
                                <CustomChip type={2} color="primary" label={t('admin.people.permanent')} />
                            </Box>
                        )
                    } else if (tmpVipContent.pivot.type === 0) {
                        return (
                            <Box display="flex" alignItems="center" gridGap={8}>
                                {(userProfileSelected.brandsIds.includes(rowData.id))}
                                <CustomChip type={2} color="secondary" label={t('admin.people.temporary')} />
                            </Box>
                        )
                    } else {
                        return (
                            <Box display="flex" alignItems="center" gridGap={8}>
                                {(userProfileSelected.brandsIds.includes(rowData.id))}
                                <CustomChip type={2} color="default" label={t('admin.people.unassigned')} />
                            </Box>
                        )
                    }
                } else {
                    return (
                        <Box display="flex" alignItems="center" gridGap={8}>
                            {(userProfileSelected.brandsIds.includes(rowData.id))}
                            <CustomChip type={2} color="default" label={t('admin.people.unassigned')} />
                        </Box>
                    )
                }
            }
        },
        {
            title: t("admin.people.linked"), field: 'type', hidden: (authUser.role_id === 6 || userProfileSelected.id === authUser.id),
            render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8}>
                    <CustomSwitchComponent
                        disabled={(authUser.role_id === 5 && (userProfileSelected.role_id === 3 || userProfileSelected.role_id === 4)) || authUser.role_id > userProfileSelected.role_id}
                        checked={(rowData.brand_id === undefined) ? userProfileSelected.brandsIds.includes(rowData.id) : userProfileSelected.brandlinesIds.includes(rowData.id)}
                        onChange={(rowData.brand_id === undefined) ? handleAssignBrandOrLine(rowData.id, 'brand') : handleAssignBrandOrLine(rowData.id, 'line')}
                    />
                </Box>
            )
        },
        {
            title: '', field: '', hidden: (![6, 8, 9].includes(userProfileSelected.role_id) || [9, 8, 7, 6].includes(authUser.role_id) || userProfileSelected.id === authUser.id),
            render: (rowData: any) => {
                let tmpVipContent = undefined;
                // Search brand or brandline
                if (rowData.brand_id === undefined) {
                    tmpVipContent = userProfileSelected.brands.find((brand: any) => brand.id === rowData.id);
                } else {
                    tmpVipContent = userProfileSelected.brandlines.find((brandline: any) => brandline.id === rowData.id);
                }

                if (tmpVipContent !== undefined) {
                    let tmpId = '';
                    if (rowData.brand_id === undefined)
                        tmpId = 'brand-' + rowData.id;
                    else
                        tmpId = 'line-' + rowData.id;

                    return (
                        <>
                            {[1, 2, 3].includes(authUser.role_id) &&
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                        setAnchorOp(event.currentTarget);
                                        setOpenOptions(tmpId);
                                    }}>
                                    <MoreVert />
                                </IconButton>
                            }
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorOp}
                                getContentAnchorEl={null}
                                keepMounted
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                open={tmpId === openOptions}
                                onClick={handleCloseOptions}
                                onClose={handleCloseOptions}>
                                {(rowData.brand_id !== undefined) ?
                                    <MenuItem onClick={handleOpen(rowData.id, [], 'line')}>VIP</MenuItem>
                                    :
                                    <MenuItem onClick={handleOpen(rowData.id, rowData.active_lines, 'brand')}>VIP</MenuItem>
                                }
                                {(tmpVipContent.pivot.status === 1) &&
                                    <MenuItem onClick={(rowData.brand_id === undefined) ? handleDisableVipContent(rowData.id, 'brand') : handleDisableVipContent(rowData.id, 'line')}>{t('cancel')} VIP</MenuItem>
                                }
                            </Menu>
                        </>
                    )
                }

            }
        }
    ];

    return (

        <>
            <Container className='noPadding'>
                <Typography variant="subtitle1" color="textSecondary">Asignación de marcas y líneas</Typography>
                <Box mt={2} mb={5}>
                    <div className="table_custom white noMenu">
                        <MaterialTable
                            localization={localization}
                            title=""
                            columns={columns}
                            data={tmpBrands}
                            // dropdowns in table
                            parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.brand_id)}
                            options={options}
                            icons={tableIcons} />
                    </div>
                </Box>


                {(open) &&
                    <Dialog open={open}
                        fullWidth={true}
                        fullScreen={isMobile && true}
                        maxWidth={!isMobile && "md"}
                        className={formClasses.containerForm}
                        TransitionComponent={Transition}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">

                        <DialogTitle id="alert-dialog-title">
                            <Grid container justify="space-between">
                                <Typography variant="h6">
                                    {t('admin.people.assign')} VIP
                                </Typography>
                                <Button onClick={handleClose}
                                    color="primary" variant="text"><CloseRounded /></Button>
                            </Grid>
                        </DialogTitle>

                        <DialogContent className={formClasses.bodyForm}>

                            <Grid item xs={12}>
                                <Tabs
                                    value={currentTab}
                                    indicatorColor="secondary"
                                    textColor="primary"
                                    onChange={handleChangeTab}
                                    className={formClasses.tapForm}
                                    aria-label="disabled tabs example">

                                    <Tab label={t("general")}   {...a11yProps(0)} className={errorTab.general ? formClasses.errorTab : ''} />
                                </Tabs>
                            </Grid>

                            <TabPanel value={currentTab} index={0}>
                                <Grid container spacing={2}>

                                    {(vipContentState.mode === 'brand') &&
                                        <Grid item xs={12} sm={6} md={(vipContentState.mode === 'brand' && vipContentState.type === 1) ? 6 : 3} >
                                            <Autocomplete
                                                id="combo-box-brandlines"
                                                value={tmpBrandLines.filter((brandline: any) => vipContentState.line_ids.includes(brandline.pivot.id))}
                                                onChange={handleMultiAutoCompleteChange('line_ids')}
                                                options={tmpBrandLines}
                                                multiple
                                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                                renderInput={(params) => <TextField required {...params} label={t("lines")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                            />
                                            <FormHelperText error={errorForm.lines}>{msgForm.lines}</FormHelperText>
                                        </Grid>
                                    }

                                    <Grid item xs={12} sm={6} md={(vipContentState.mode === 'line' && vipContentState.type === 1) ? 12 : 3}>
                                        <Autocomplete
                                            id="combo-box-types"
                                            disableClearable
                                            value={types.find((type: any) => type.id === vipContentState.type)}
                                            onChange={handleNumberChange('type')}
                                            options={types}
                                            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                            renderInput={(params) => <TextField {...params} label={t("action")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                        />
                                        <FormHelperText error={errorForm.type}>{msgForm.type}</FormHelperText>
                                    </Grid>

                                    {(vipContentState.type === 0) &&
                                        <>
                                            <Grid item xs={12} sm={6} md={(vipContentState.mode === 'line') ? 4 : 3} >
                                                <DatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="dd/MM/yyyy hh:mm a"
                                                    label={t("start-date")}
                                                    InputLabelProps={{ shrink: true, }}
                                                    fullWidth
                                                    value={date_ini}
                                                    onChange={(newDate: any) => setDateIni(newDate)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={(vipContentState.mode === 'line') ? 4 : 3}>
                                                <DatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="dd/MM/yyyy hh:mm a"
                                                    label={t("end-date")}
                                                    InputLabelProps={{ shrink: true, }}
                                                    fullWidth
                                                    value={date_end}
                                                    onChange={(newDate: any) => setDateEnd(newDate)}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </TabPanel>

                        </DialogContent>


                        <DialogActions className={!isValidForm && Object.keys(msgForm).length > 0 ? formClasses.errorDialogActions : ''}>
                            <CancelButton onClick={handleClose}>
                                {t("cancel")}
                            </CancelButton>
                            <SaveButton autoFocus id="btn-handlelogin"
                                onClick={() => handleSubmit()}>
                                {t("save")}
                            </SaveButton>
                        </DialogActions>
                    </Dialog>
                }
            </Container>
        </>
    );
}

export default UserBrands;