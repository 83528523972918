// React
import { useState, ChangeEvent, useEffect} from 'react';

// Material UI
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackBar(props: any) {
    // Props
    const {errors, msgs} = props;
    // Open state
    const [open, setOpen] = useState(false);
    const stringErrors: string = errors.join('');
    const stringMsgs: string = msgs.join('');

    useEffect(() => {
        if (hasErrorsOrMessages())
            setOpen(true);
    }, [stringErrors, stringMsgs])

    const hasErrorsOrMessages = () => {
        const filterErrors = errors.filter((error: any, index: number) => error || (msgs[index] !== '' && msgs[index] !== null));
        return filterErrors.length > 0
    }

    /* Functions */
    const handleClose = (event: ChangeEvent<{}>, reason: string) => {
        if (reason === 'clickaway') return;

        setOpen(false);
    };

    return (
        <>
            {errors.map((error: any, index: number) => {
                if (error || (msgs[index] !== '' && msgs[index] !== null)){
                    return (
                        <Snackbar key={index} open={open} autoHideDuration={5000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={error ? 'error' : 'success'}>
                                {msgs[index]}
                            </Alert>
                        </Snackbar>
                    )
                }
            })}
        </>
    );
}