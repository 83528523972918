// React
import { useLocation } from 'react-router-dom';

// Material UI
import { makeStyles, createStyles, Theme, Container, Box, Typography, Grid, Button } from '@material-ui/core';
import { Add, ArrowForwardIosRounded, LanguageRounded, LocationOn, TranslateRounded } from '@material-ui/icons';

// Assets
import image from '../assets/Images/nofilter.png'

// Custom Hook
import useScreenWidth from '../hooks/useScreenWidth';
import useNoResultsFound from '../hooks/useNoResultsFound';

// Custom Styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            gap: 16,
            paddingRight: '14%',
            margin: '24px 0px',
            alignItems: 'center',
            '& p': {
                whiteSpace: 'nowrap',
            },
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0,
            }
        },
        divider: {
            height: 1,
            width: '100%',
            backgroundColor: theme.palette.grey[400],
        },
    }),
);

function NotFound({ subsection = null, handleClearFilters = () => { } }: any) {
    //Custom Hook
    const isMobile = useScreenWidth(700);

    const classes = useStyles();

    const { clearFilters, noResultsMsg, languageName, countryName } = useNoResultsFound(handleClearFilters);

    // Location & History
    const { pathname } = useLocation();
    let isAdmin = pathname.includes('admin');
    return (
        <Container maxWidth={"md"} style={isAdmin ? { marginTop: 20 } : {}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                    <Box mt={2} display="flex" gridGap={6}>
                        <Typography variant='h6'>No encontramos contenido relacionado </Typography>
                    </Box>

                    <Box display="flex" gridGap={8} alignItems="center" mb={2}>
                        <Typography>Te recomendamos hacer lo siguiente:</Typography>
                    </Box>
                    <Box display="flex" width={isMobile ? '100%' : '86%'} justifyContent='center' alignItems="center" mb={2}>
                        <Button color='primary' variant='contained' onClick={() => clearFilters()} size="medium">Limpíar filtros</Button>
                    </Box>
                    <Box className={classes.container}>
                        <Box className={classes.divider} />
                        <Typography >{isAdmin ? 'O puedes' : 'O puedes cambiar'}</Typography>
                        <Box className={classes.divider} />
                    </Box>

                    {isAdmin ?
                        <>
                            <Box display="flex" gridGap={8} alignItems="center" mb={2}>
                                <LanguageRounded color='secondary' />
                                <Typography >Cambiar los paises seleccionados</Typography>
                            </Box>
                            <Box display="flex" gridGap={8} alignItems="center" mb={2}>
                                <Add color='secondary' />
                                <Typography >Crear un nuevo elemento</Typography>
                            </Box>
                        </>
                        :
                        <>
                            <Box display="flex" gridGap={8} alignItems="center" mb={2}>
                                <TranslateRounded color='primary' />
                                <Typography >Lenguage actual:</Typography>
                                <Typography color='primary' style={{ fontWeight: 500 }}>{languageName}</Typography>
                            </Box>
                            <Box display="flex" gridGap={8} alignItems="center" mb={2}>
                                <LocationOn color='primary' />
                                <Typography >Pais actual:</Typography>
                                <Typography color='primary' style={{ fontWeight: 500 }}>{countryName}</Typography>
                            </Box>
                        </>
                    }
                    {noResultsMsg &&
                        <Box display="flex" gridGap={8} alignItems="center" mb={2} mt={2}>
                            <ArrowForwardIosRounded fontSize="small" color="secondary" />
                            <Typography variant="body2">{noResultsMsg}</Typography>
                        </Box>
                    }
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box my={4}>
                        <img src={image} width="100%" alt="logonotfound" />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default NotFound;
