// React
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

// Material UI
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';

// Custom Components
import NewsFeed from '../components/common/client/NewsFeed';
import CoursesFeed from '../components/common/client/CoursesFeed';
import ResourcesFeed from '../components/common/client/ResourcesFeed';
import SliderClient from '../components/sliders/SliderClient';
import CustomSnackBar from '../components/common/admin/CustomSnackBar';
import EventCalendar from '../components/events/EventCalendar';
import ContentHeroStyle from '../styles/components/HomeStyles';
import ShowFirstStartVideo from '../components/usersettings/ShowFirstStartVideo'
import MenuIcons, { IMenuIcons } from '../components/common/MenuIcons';

// Redux Actions
import { IRootState } from '../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

//Custom Hook
import useScreenWidth from '../hooks/useScreenWidth';

// Types
type IMenu = {
    id: number,
    name: string,
    code: keyof IMenuIcons,
    path: string
}

function Home() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const classes = ContentHeroStyle();

    // Router
    const history = useHistory();

    //Redux Hooks
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { error: errorNovelties, msg: msgNovelties } = useSelector((state: IRootState) => state.novelty);
    const { error: errorCourse, msg: msgCourse } = useSelector((state: IRootState) => state.course);
    const { error: errorResource, msg: msgResource } = useSelector((state: IRootState) => state.resource);

    const existMenu = (menuCode: keyof IMenuIcons) => {
        return authUser.menus.some((menu: IMenu) => menu.code === menuCode);
    };

    return (
        <Box>

            <SliderClient />

            <Container maxWidth="xl">
                <Box width='100%' className={classes.hiddenSm} pt={2}>
                    <Box display='flex' py={isMobile ? 3 : 4} px={isMobile ? 1 : 6} alignItems='center' gridGap={isMobile ? 16 : 48} className={classes.containerTextWorld}>
                        <Box className={classes.divider} />
                        <Typography variant={isMobile ? 'subtitle1' : 'h6'}>{t("meet-our-worlds")}</Typography>
                        <Box className={classes.divider} />
                    </Box>
                    <Box display='flex' pb={isMobile ? 1 : 4} px={isMobile ? 1 : 6} alignItems='center' justifyContent='center' gridGap={isMobile ? 16 : 48} className={classes.containerButtonsWorld}>
                        {authUser.menus.map((menu: IMenu, index: any) => (
                            <Button key={menu.id} color="primary" variant='contained' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); history.push(`${menu.path}`) }} className={clsx(classes.buttonWorld, authUser.menus.length <= 2 && 'fixed')}>
                                <MenuIcons menuCode={menu.code} />
                                <Typography variant={isMobile ? 'subtitle1' : 'h6'}>{menu.name}</Typography>
                            </Button>
                        ))}
                    </Box>
                </Box>
                <Grid container spacing={isMobile ? 5 : 4} className={classes.containerHero}>
                    <Grid item xs={12} md={existMenu('events') ? 7 : 12} style={isMobile ? { paddingTop: 2, paddingBottom: 2 } : { paddingTop: 16 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={existMenu('events') ? 12 : 6}>
                                <NewsFeed />
                            </Grid>
                            {/* Show courses if belongs to user */}
                            {existMenu('academy') &&
                                <Grid item xs={12} md={existMenu('events') ? 12 : 6}>
                                    <CoursesFeed />
                                </Grid>
                            }
                            {/* Show resources if belongs to user */}
                            {existMenu('library') &&
                                <Grid item xs={12} md={existMenu('events') ? 12 : 6}>
                                    <ResourcesFeed />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {existMenu('events') &&
                        <Grid item xs={12} md={5} style={isMobile ? { paddingTop: 0 } : { paddingTop: 16 }}>
                            <EventCalendar />
                        </Grid>
                    }

                </Grid>
            </Container>

            {process.env.REACT_APP_NAME === 'PLEI' &&
                <ShowFirstStartVideo />
            }

            <CustomSnackBar errors={[errorNovelties, errorCourse, errorResource]} msgs={[msgNovelties, msgCourse, msgResource]} />
            {/* <ScreenLoading loadings={[loadingNovelties, loadingCourse, loadingResource]} /> */}
        </Box>
    );
}

export default Home;