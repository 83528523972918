// Material UI
import { Typography } from '@material-ui/core';
import { Description, Image, Videocam, Dvr } from '@material-ui/icons';

// Types
type FiletypeIconProps = {
    id: number
}

function FiletypeIcon({ id }: FiletypeIconProps) {
    return (
        <>
            {id === 1 &&
                <Typography variant="caption"><Description color="secondary" fontSize="small"/></Typography>
            }
            {id === 2 &&
                <Typography variant="caption"><Image color="secondary" fontSize="small"/></Typography>
            }
            {id === 3 &&
                <Typography variant="caption"><Videocam  color="secondary" fontSize="small"/></Typography>
            }
            {id === 4 &&
                <Typography variant="caption"><Dvr  color="secondary" fontSize="small"/></Typography>
            }
        </>
    );
}

export default FiletypeIcon;