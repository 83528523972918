import { put, takeLatest, call } from 'redux-saga/effects';
import {
    START_SET_GLOBAL_FAMILY, SUCCESS_SET_GLOBAL_FAMILY,
    START_SET_GLOBAL_BRAND, SUCCESS_SET_GLOBAL_BRAND,
    START_SET_GLOBAL_LINE, SUCCESS_SET_GLOBAL_LINE,
    START_SET_GLOBAL_LANGUAGE, SUCCESS_SET_GLOBAL_LANGUAGE,
    START_SET_GLOBAL_COUNTRY, SUCCESS_SET_GLOBAL_COUNTRY,
    START_SET_GLOBAL_COUNTRIES, SUCCESS_SET_GLOBAL_COUNTRIES,
    START_SET_GLOBAL_ROLE, SUCCESS_SET_GLOBAL_ROLE,
    START_SET_GLOBAL_ORDERBY, SUCCESS_SET_GLOBAL_ORDERBY,
    START_SET_GLOBAL_TAG, SUCCESS_SET_GLOBAL_TAG,
    START_GLOBAL_SEARCH_QUERY, SUCCESS_GLOBAL_SEARCH_QUERY, ERROR_GLOBAL_SEARCH_QUERY
} from '../types';
import { apiCall } from '../config/axios';

function* setGlobalFamily({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_FAMILY, payload })
}

function* setGlobalBrand({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_BRAND, payload })
}

function* setGlobalLine({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_LINE, payload })
}

function* setGlobalLanguage({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_LANGUAGE, payload })
}

function* setGlobalCountry({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_COUNTRY, payload })
}

function* setGlobalCountries({ payload }) {
    localStorage.setItem('globalCountriesId', payload);
    yield put({ type: SUCCESS_SET_GLOBAL_COUNTRIES, payload })
}

function* setGlobalRole({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_ROLE, payload })
}

function* setGlobalOrderBy({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_ORDERBY, payload })
}

function* setGlobalTag({ payload }) {
    yield put({ type: SUCCESS_SET_GLOBAL_TAG, payload })
}

function* globalSearchQuery({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/globalsearch/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GLOBAL_SEARCH_QUERY, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GLOBAL_SEARCH_QUERY, payload: error.message })
    }
}

export default function* global() {
    yield takeLatest(START_SET_GLOBAL_FAMILY, setGlobalFamily);
    yield takeLatest(START_SET_GLOBAL_BRAND, setGlobalBrand);
    yield takeLatest(START_SET_GLOBAL_LINE, setGlobalLine);
    yield takeLatest(START_SET_GLOBAL_LANGUAGE, setGlobalLanguage);
    yield takeLatest(START_SET_GLOBAL_COUNTRY, setGlobalCountry);
    yield takeLatest(START_SET_GLOBAL_COUNTRIES, setGlobalCountries);
    yield takeLatest(START_SET_GLOBAL_ROLE, setGlobalRole);
    yield takeLatest(START_SET_GLOBAL_ORDERBY, setGlobalOrderBy);
    yield takeLatest(START_SET_GLOBAL_TAG, setGlobalTag);
    yield takeLatest(START_GLOBAL_SEARCH_QUERY, globalSearchQuery);
}