// React
import { useEffect, useState, useRef, MouseEvent, KeyboardEvent } from 'react';

// Material UI
import { Popper, Paper, MenuList, MenuItem, Grow, ClickAwayListener, makeStyles, Button } from '@material-ui/core';
import { AddRounded, CheckRounded, EditRounded, ExpandMore } from '@material-ui/icons/';

// Common components
import ButtonHeader from '../common/header/ButtonHeader';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import clsx from 'clsx';
const FilterStyles = makeStyles(theme => ({
    main: {
        '& svg': {
            color: `${theme.palette.grey[50]} !important`,
        }
    },
    dropdown: {
        zIndex: 20,
        '& > div': {
            background: theme.palette.primary.main
        },
        '& .MuiMenuItem-root': {
            color: theme.palette.grey[50],
            padding: '4px 24px',
            fontSize: 16
        },

    },
    buttonCategory: {
        color: 'white',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        '& svg': {
            color: `${theme.palette.grey[50]} !important`,
        }
    },
    optionCategory: {
        '&:hover': {
            backgroundColor: theme.palette.grey[700],
        },
    },
    svgSubMenu: {
        display: 'none',
        '&.active': {
            backgroundColor: 'transparent',
            display: 'block',
            color: theme.palette.grey[50],
            marginLeft: 8,
        },
        '&.admin': {
            marginRight: 8,
            marginLeft: 0,
        },
        [theme.breakpoints.down('sm')]: {
            '&.active': {
                color: theme.palette.success.main
            },
        },
    },
}));

function TypeFilter(props: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Props
    const { id, type, categories, active, handleChangeFilter, admin, filterCategoryId } = props;

    // Styles
    const classes = FilterStyles();

    // Filter States
    const [openMenu, setOpenMenu] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(openMenu);
    useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = openMenu;
    }, [openMenu]);

    /* Functions */
    const handleItemSelected = (id: null | number) => {
        switch (id) {
            case 1:
                handleChangeFilter(id, 1, null, t('slider'));
                break;
            case 2:
                handleChangeFilter(id, 2, null, t("link"));
                break;
            case 3:
                handleChangeFilter(id, 2, 1, `${t("link")} ${t("top")}`);
                break;
            case 4:
                handleChangeFilter(id, 2, 2, `${t("link")} ${t("leftBottom")}`);
                break;
            case 5:
                handleChangeFilter(id, 2, 3, `${t("link")} ${t("rightBottom")}`);
                break;
            case 6:
                handleChangeFilter(id, 3, 1, `${t("internal")}`);
                break;
            default:
                handleChangeFilter(1, 1, null, t('create-slider'));
                break;
        }
        setOpenMenu(false);
    }

    const handleToggle = () => {
        if (categories.length > 0)
            setOpenMenu((prevOpen) => !prevOpen);
        else
            if (admin === true)
                setOpenMenu((prevOpen) => !prevOpen);
            else
                handleItemSelected(id);
    };

    const handleClose = (event: MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenMenu(false);
    };

    function handleListKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMenu(false);
        }
    }

    return (
        <>
            <ButtonHeader
                ref={anchorRef}
                aria-controls={openMenu ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="primary"
                className={`${clsx({ 'active ': active })} ${classes.main}`}
                endIcon={admin === true ? <ExpandMore color="inherit" /> : categories.length > 0 && <ExpandMore color="inherit" />}
                onClick={handleToggle}>
                {type}
            </ButtonHeader>
            {admin === true
                ?
                <Popper
                    open={openMenu}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    className={classes.dropdown}>

                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
                            <Paper elevation={0} square>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {categories.map((category: any, index: number) => (
                                            <MenuItem className={classes.optionCategory} key={category.id} onClick={() => handleItemSelected(category.id)}>
                                                {category.name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                :
                categories.length > 0 &&
                <>
                    <Popper
                        open={openMenu}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.dropdown}>

                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}>
                                <Paper elevation={0} square>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            {categories.map((category: any, index: number) => {
                                                const activeCategory = filterCategoryId !== null && category.id === filterCategoryId;

                                                return (
                                                    <MenuItem key={category.id} onClick={() => handleItemSelected(category.id)}>
                                                        {category.name}
                                                        <CheckRounded className={clsx(classes.svgSubMenu, activeCategory && 'active')} />
                                                    </MenuItem>
                                                )
                                            }
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            }
        </>
    );
}

export default TypeFilter;
