import { useState, MouseEvent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { ChevronRightRounded } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            height: 25,
            color: theme.palette.grey[500],
            padding: 4,
            paddingLeft: 16,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
        modal: {
            maxHeight: 150,
            overflow: 'auto',
            padding: 4,
            paddingLeft: 16,
            paddingRight: 16,
            maxWidth: 280,
            '&::-webkit-scrollbar': {
                backgroundColor: theme.palette.grey[200],
                borderRadius: '4px',
                width: '10px',
                margin: "8px",
                paddingRight: 10,
                '&-thumb': {
                    backgroundColor: theme.palette.grey[500],
                    borderRadius: '4px'
                },
            },
        },
        title: {
            fontSize: '1rem',
            color: theme.palette.grey[500],
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }),
);

export default function ModalInfo({ title = [], options = [] }: any) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState<PopperPlacementType>();
    const classes = useStyles();

    const handleClick = (newPlacement: PopperPlacementType) => (
        event: MouseEvent<HTMLButtonElement>,
    ) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    return (
        <>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition style={{ maxHeight: 160 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.modal}>
                            {options.length > 0
                                ? options.map((option: any) => {
                                    return <Typography key={uuidv4()}>{option.name ? option.name : null}</Typography>
                                })
                                :
                                <Typography>{options.name}</Typography>
                            }
                        </Paper>
                    </Fade>
                )}
            </Popper>

            <Button
                className={classes.title}
                startIcon={<ChevronRightRounded />}
                style={{ paddingLeft: 0 }}
                onClick={handleClick('bottom-start')}>
                <Typography>{title}</Typography>
            </Button>
        </>
    );
}