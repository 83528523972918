import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    container: {
        fontFamily: theme.typography.fontFamily,
    },
    textHeroPie: {

    },
    textPie: {
        fontSize: theme.typography.body2.fontSize,
        fill: theme.palette.grey[400]
    },
    heroContent: {
        "& h6": {
            color: theme.palette.grey[700]
        },
        "& svg": {
            color: theme.palette.grey[400]
        },
    },
    surfaceTooltip: {
        backgroundColor: "rgba(30, 30, 30, 0.75)",
        border: "none",
        backdropFilter: "blur(48px)",
        color: theme.palette.grey[50],
        borderRadius: 8,
    },
    InfoContent: {
        '& p': {
            fontWeight: '500',
            color: theme.palette.grey[500],
            letterSpacing: '0.0015em',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 1,
            overflow: 'hidden',
            position: 'relative',
        },
        '& span': {
            lineHeight: '18px',
            letterSpacing: '0.0015em',
        }
    },
    toogleBtnContainer: {
        maxHeight: 32,
        padding: 4,
        gap: 4,
    },
    toogleBtn: {
        border: '0px !important',
        textTransform: 'capitalize',
        color: `${theme.palette.grey[700]} !important`,
        '& p': {
            fontWeight: '500',
        }
    },
    toggleBtnSelect: {
        borderRadius: '6px !important',
        backgroundColor: `${theme.palette.secondary.main} !important`,
        color: `${theme.palette.grey[50]} !important`,
        border: '0px !important',
    }
}));