import { useEffect, useState, ChangeEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';

// Material UI
import { Grid, Container, Box, Button, Tabs, Tab, withStyles } from '@material-ui/core';

// Custom components
import Header from '../../components/common/header/Header';
import ContentFilter from '../../components/layouts/filters/ContentFilter';
import NoveltyPanel from '../../components/novelties/NoveltyPanel';
import MediaFileBlock from '../../components/common/client/MediaFileBlock';
import CustomSnackBar from '../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../components/common/ScreenLoading';
import TabPanel from '../../components/common/admin/TabPanel';
import OffersPanel from '../../components/offers/OffersPanel';
import ShareIcon from '../../components/common/ShareIcon';
import NotFilter from '../NotFilter';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';
import { getResourcecategories } from '../../redux/actions/resourcecategories';
import { getResourcetypes } from '../../redux/actions/resourcetypes'
import { getResources } from '../../redux/actions/resources';
import { IRootState } from '../../redux/types';
import { getClientNovelties } from '../../redux/actions/novelties';
import { getClientOffers, counterViewsOffer } from '../../redux/actions/offers';

// Libraries
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import KnowBuyStyles from '../../styles/components/knowbuy/KnowBuyStyles';


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Types
interface IFilter {
    resourcetype_id: null | number,
    category_id: null | number,
    orderby_id: number,
    family_id: null | number,
    brand_id: null | number,
    line_id: null | number,
    country_id: null | number,
    role_id: null | number,
    language_id: null | number,
    tag_id: null | number,
    searchText: string
}

//Custom Tabs
interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
const ContainerTab = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        marginTop: 24,
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
            marginTop: 64,
            '& button': {
                padding: '8px 30px',
            }
        }
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 8,
        '& > span': {
            backgroundColor: theme.palette.primary.main,
            height: 8,
            maxWidth: 100,
            borderRadius: '8px 8px 0px 0px',
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: 4,
            '& > span': {
                height: 4,
                borderRadius: '4px 4px 0px 0px',
            },
        }
    },
}))((props: StyledTabsProps) => <Tabs variant={useScreenWidth(960) ? 'scrollable' : 'fullWidth'} scrollButtons={useScreenWidth(960) ? 'on' : 'auto'} {...props} TabIndicatorProps={{ children: <span /> }} />);

function KnowBuy() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const classes = KnowBuyStyles();

    //Tab State
    const [currentTab, setCurrentTab] = useState(0);

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
        searchParams.set("ktbtab", newValue.toString());
        replace(`${pathname}?${searchParams}`);
    };

    //>>>>>>>>>>>>>>>>>>>>>>>>>LOGIC LIBRARY
    // Location & History
    const { pathname, search } = useLocation();
    const { push, replace } = useHistory();
    const searchParams = new URLSearchParams(search);

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag } = useSelector((state: IRootState) => state.global);
    const { resources, error, msg, loading } = useSelector((state: IRootState) => state.resource);
    const { resourcecategories: categories, error: errorCategory, msg: msgCategory } = useSelector((state: IRootState) => state.resourcecategory);
    const { resourcetypes, error: errorResourcetype, msg: msgResourcetype } = useSelector((state: IRootState) => state.resourcetype);
    const { novelties, loading: noveltiesLoading } = useSelector((state: IRootState) => state.novelty);

    // Filter state
    const [filters, setFilters] = useState<IFilter>({
        resourcetype_id: 4,
        category_id: null,
        orderby_id: 0,
        family_id: null,
        brand_id: null,
        line_id: null,
        country_id: null,
        role_id: null,
        language_id: null,
        tag_id: null,
        searchText: ''
    });

    const rowsPerPage = useRef<number>(6)
    const pageNumber = useRef<number>(1)

    const nextPage = () => {
        pageNumber.current++; 
        getResourcesFromAPI();
    }

    const getResourcesFromAPI = () => {
        const pagination = {pageNumber: pageNumber.current, rowsPerPage: rowsPerPage.current}
        dispatch(getResources({filters, pagination }));
    }

    // Ready Page
    useEffect(() => {
        if (!resourcetypes.length)
            dispatch(getResourcetypes());

        if (search && (searchParams.get('ktbtab') !== null && searchParams.get('ktbtab') !== "null")) {
            setCurrentTab(Number(searchParams.get('ktbtab')));
        }
    }, []);

    useEffect(() => {
        if (globalCountryId !== null)
            dispatch(getResourcecategories({ languageId: globalLanguageId, countriesId: [globalCountryId], familyId: globalFamilyId, brandId: globalBrandId }));
    }, [globalCountryId, globalFamilyId, globalBrandId]);

    useEffect(() => {
        if (filters.country_id !== null) {
            pageNumber.current = 1;
            getResourcesFromAPI();
        }
    }, [filters]);

    useEffect(() => {
        if (globalCountryId !== null && resourcetypes.length > 0 && categories.length > 0) {
            setFilters({
                ...filters,
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                country_id: globalCountryId,
                line_id: globalLineId,
                role_id: globalRoleId,
                language_id: globalLanguageId,
                orderby_id: globalOrderById,
                tag_id: globalTag,
                resourcetype_id: 4,
                category_id: null
            });
        }
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag, resourcetypes, categories]);

    useEffect(() => {
        dispatch(getClientNovelties({
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            country_id: globalCountryId,
            language_id: globalLanguageId,
            tag_id: globalTag,
            role_id: globalRoleId,
            type: 'novelties',
            requesttype: 0
        }));
    }, [globalFamilyId, globalBrandId, globalCountryId, globalLanguageId, globalTag, globalRoleId]);

    useEffect(() => {
        dispatch(getClientOffers({
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            country_id: globalCountryId,
            role_id: globalRoleId,
            language_id: globalLanguageId,
            orderby_id: globalOrderById,
            tag_id: globalTag,
            type: 0
        }));
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag]);

    const handleClickMediaFileBlock = (id: number, hasaccess: boolean) => {
        if (hasaccess)
            push('/biblioteca/' + id);
    }

    /* Functions */
    const handleDownload = (resource_id: number) => {
        const URL = process.env.REACT_APP_API_URL + '/resources/download/';
        window.open(URL + resource_id);
    }

    const handleFavoriteClick = (resource_id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({id: resource_id, state, type: 'resource'}));
        } else {
            dispatch(unsetFavorite({id: resource_id, state, type: 'resource'}));
        }
    }

    const ShareTab = <ShareIcon
        meta={true}
        visibility={0}
        title={'Comercial'}
        description={'Comercial'}
        link={window.location.href}
        multimedia={``}
        component="button"
        type="resource"
    />

    const handleContentSearch = debounce((event: any) => {
        const value = event.target.value;
        if (value !== '' && value !== null) {
            const rawValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            setFilters({...filters, searchText: rawValue});
        } else
            setFilters({...filters, searchText: ''});
    }, 500)

    return (

        <>
            <Header type={1} />
            {/* <ContentFilter sectionName={t("library")} /> */}

            <Container style={{ maxWidth: 1111 }} >
                <ContainerTab
                    value={currentTab}
                    onChange={handleChangeTab}
                    aria-label="disabled tabs example">

                    <Tab label={<Box className={classes.share} display='flex'>{t("catalogs")}{ShareTab}</Box>}  {...a11yProps(0)} />
                    <Tab label={<Box className={classes.share} display='flex'>{t("updates")}{ShareTab}</Box>}  {...a11yProps(1)} />
                    <Tab label={<Box className={classes.share} display='flex'>{t("offers")}{ShareTab}</Box>}  {...a11yProps(2)} />
                    {/*<Tab label={<Box className={classes.share} display='flex'>{t('home')}{ShareTab}</Box>}  {...a11yProps(3)} />*/}
                </ContainerTab>

                <TabPanel value={currentTab} noPadding={true} index={0}>
                    <ContentFilter comercial={true} sectionName={t("catalogs")} handleContentSearch={handleContentSearch} />
                    {!resources.length && !loading ? <NotFilter subsection={t("catalogs")} /> :
                        <Box>
                            <Grid container spacing={isMobile ? 1 : 2} >
                                {resources.map((resource: any) => (
                                    <MediaFileBlock
                                        key={resource.id}
                                        data={resource}
                                        isCatalog={true}
                                        brand={filters.brand_id !== null ? '' : resource.brand_name}
                                        vipContent={resource.visibility === 1}
                                        hasaccess={resource.visibility === 1 && (authUser.vip_lines.includes(resource.line_id) || (resource.line_id === null && authUser.vip_brands.includes(resource.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                        handleClick={handleClickMediaFileBlock}
                                        handleDownload={handleDownload}
                                        handleFavorite={handleFavoriteClick(resource.id)}
                                    />
                                ))}
                            </Grid>
                            {resources.length % rowsPerPage.current === 0 &&
                                <Box mt={6} pb={4} style={{ textAlign: "center" }}>
                                    <Button variant="contained" size="large" color="primary" onClick={() => nextPage()}>{t("see-more")}</Button>
                                </Box>
                            }
                        </Box>
                    }
                </TabPanel>

                <TabPanel value={currentTab} noPadding={true} index={1}>
                    <ContentFilter comercial={true} />
                    {!novelties.length && !noveltiesLoading ? <NotFilter subsection={t("updates")} /> :
                        <NoveltyPanel novelties={novelties} />
                    }
                </TabPanel>

                <TabPanel value={currentTab} noPadding={true} index={2}>
                    <ContentFilter />
                    <OffersPanel userCounterViewsOffer={counterViewsOffer} subsection={t("offers")} />
                </TabPanel>

            </Container>
            <CustomSnackBar errors={[error, errorCategory, errorResourcetype]} msgs={[msg, msgCategory, msgResourcetype]} />
            <ScreenLoading loadings={[loading]} />

        </>
    );
}

export default KnowBuy;
