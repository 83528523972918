import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_TERMS, SUCCESS_GET_TERMS, ERROR_GET_TERMS,
    START_ACCEPT_TERMS, SUCCESS_ACCEPT_TERMS, ERROR_ACCEPT_TERMS
} from '../types';
import { apiCall } from '../config/axios';

function* getTerms() {
    try {
        const response = yield call(apiCall, 'get', '/terms/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_TERMS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_TERMS, payload: error.message })
    }
}

function* acceptTerms({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/terms/accept', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_ACCEPT_TERMS, payload: response.data })
        localStorage.removeItem('accept_terms');
    } catch (error) {
        yield put({ type: ERROR_ACCEPT_TERMS, payload: error.message })
    }
}

export default function* terms() {
    yield takeLatest(START_GET_TERMS, getTerms);
    yield takeLatest(START_ACCEPT_TERMS, acceptTerms);
}