// Material UI
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const SaveButton = styled(Button)(({
    theme
}) => ({
    borderRadius: theme.spacing(1),
    padding: `10px ${theme.spacing(6)}px`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[800], 
    border: `1px solid ${theme.palette.grey[800]}`,
    fontWeight: 500,
    position: 'relative',
    textTransform: 'capitalize',
    "& svg": {
        width: 0,
        height: 0,
        opacity: 0,
        transition: 'width 0.3s ease, height 0.3s ease, opacity 0.3s ease',
    },
    "&:hover": {
        border: `1px solid ${theme.palette.success.main}`,
        backgroundColor: theme.palette.success.main, 
        "& svg": {
            width: 24,
            height: 24,
            opacity: 1,
        },
    },
    [theme.breakpoints.up('md')]: {
        marginRight: 14,
    },
    // borderRadius: theme.spacing(1),
    // padding: `10px ${theme.spacing(6)}px`,
    // backgroundColor: theme.palette.grey[100],
    // filter: 'brightness(0.9)',
    // color: theme.palette.common.black,
    // border: `1px solid ${theme.palette.grey[600]}`,
    // fontWeight: 700,
    // "&:hover": {
    //     border: `1px solid ${theme.palette.grey[200]}`,
    //     color: theme.palette.common.black,
    //     backgroundColor: theme.palette.grey[200],
    // },
}));

export default SaveButton;