// Material UI
import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// Styles
import SkeletonStyle from '../../../../styles/components/layouts/navigation/SkeletonStyle';

//Custom Components
import CardsSkeleton from './CardsSkeleton';

//Custom Hook
import useScreenWidth from '../../../../hooks/useScreenWidth';

function HomeCoursesFeedSkeleton() {

    //Custom Hook
    const isMobile = useScreenWidth(960);
    // Styles
    const classes = SkeletonStyle();

    return (
        <Grid container spacing={isMobile ? 5 : 4} className={classes.containerHero}>
            <Grid item xs={12} className={classes.header}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Skeleton animation='wave' width={120} height={46} variant='text' />
                    <Skeleton animation='wave' width={65} height={30} variant='text' />
                </Box>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
                <Grid item xs={12} md={6} lg={4}><CardsSkeleton /></Grid>
            </Grid>
        </Grid>
    )
}

export default HomeCoursesFeedSkeleton