import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


interface IBrand {
    id: number,
    name: string
}

function BrandAutocomplete({ value, handleInputChange, props }: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    // Redux global state
    const { userBrands } = useSelector((state: IRootState) => state.brand);
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // Handle functions
    const handleAutoCompleteChange = (brand: IBrand | null) => {
        handleInputChange('brand', brand);
    }

    return (
        <Autocomplete
            id="autocomplete-brands"
            ChipProps={{ color: 'secondary' }}
            value={value}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={userBrands}
            getOptionLabel={(brand: IBrand) => brand.id !== undefined ? brand.name : ''}
            renderInput={(params) =>
                <TextField
                    {...props}
                    {...params}
                    label={t("brands")}
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    error={errorForm.brands}
                    helperText={msgForm.brands}
                />
            }
        />
    )
}

export default BrandAutocomplete;