import { 
  START_GET_REPORT_POPULAR_CONTENT,
  START_GET_REPORT_BEST_CTR,
  START_GET_REPORT_SESSION_LOGS,
  START_GET_REPORT_POPULAR_DETAIL_CONTENT
} from '../types';

export const getPopularContent = () => ({
  type: START_GET_REPORT_POPULAR_CONTENT
})

export const getBestCTR = () => ({
  type: START_GET_REPORT_BEST_CTR
})

export const getSessionlogs = payload => ({
  type: START_GET_REPORT_SESSION_LOGS,
  payload
})

export const getPopularDetailContent = () => ({
  type: START_GET_REPORT_POPULAR_DETAIL_CONTENT
})