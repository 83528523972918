import { START_SHOW_MODAL, START_HIDE_MODAL, START_ASSIGN_ACTION_MODAL } from '../types';

export const showModal = payload => ({
    type: START_SHOW_MODAL,
    payload
})

export const hideModal = () => ({
    type: START_HIDE_MODAL
})

export const assignActionModal = payload => ({
    type: START_ASSIGN_ACTION_MODAL,
    payload
})