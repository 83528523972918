import { useSelector } from 'react-redux'

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";


interface IVisibility {
    id: number,
    name: string
}

function VisibilityAutocomplete({ value, handleInputChange }: any) {
    // Translate
    const { t } = useTranslation(["global"]);

    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    const VISIBILITY: IVisibility[] = [
        { id: 0, name: t('private') ?? "Privado" },
        { id: 1, name: 'VIP' },
        { id: 2, name: t('public') ?? "Publico" },
        { id: 3, name: t('preregistration') ?? "Pre-Registro" }
    ];

    // Handle functions
    const handleAutoCompleteChange = (visibility: IVisibility | null) => {
        handleInputChange('visibility', visibility);
    }

    return (
        <Autocomplete
            id="autocomplete-visibility"
            disableClearable
            value={value}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={VISIBILITY}
            getOptionLabel={(option: IVisibility) => { console.log("OPTION: ", option); return option.name }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={t("visibility")}
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    error={errorForm.visibility}
                    helperText={msgForm.visibility}
                />
            }
        />
    )
}

export default VisibilityAutocomplete;