// React
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

// Redux Actions
import { IRootState } from '../../redux/types';

// Types
type VerifyAccessRouteProps = {
    visibility: number,
}

const VerifyAccessRoute = ({visibility} : VerifyAccessRouteProps) => {

    const { user: authUser } = useSelector((state: IRootState) => state.auth);

    return (
        <>
            {![2,3].includes(visibility) && authUser === null && !localStorage.getItem('isAuth') &&
                <>
                    {/* localStorage.setItem('login_redirect', window.location.pathname + window.location.search)*/ }
                    <Redirect to="/" />
                </>
            }
        </>
    )
}

export default VerifyAccessRoute;