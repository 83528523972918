import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Typography, Box, TextField, Container, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Room } from '@material-ui/icons';

//Custom Components
import ResetButton from '../../../common/header/ResetButton';

//Redux
import { setGlobalCountry } from '../../../../redux/actions/global';
import { IRootState } from '../../../../redux/types';

// Styles
import CountryFilterStyles from '../../../../styles/components/common/admin/CountryFilterStyles';

// Translation
import ReportButton from '../../../common/header/ReportButton';

// Types
interface ICountry {
    id: number,
    code: string,
    name: string
}

//Custom Style
const BreadcrumbStyles = makeStyles(theme => ({
    container: {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}))


function ClientBreadcumb() {

    const classes = CountryFilterStyles();
    const customClasses = BreadcrumbStyles();

    // Location & History
    const { pathname, search } = useLocation();
    const { replace } = useHistory();
    const searchParams = new URLSearchParams(search);

    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { globalCountryId } = useSelector((state: IRootState) => state.global);


    const dispatch = useDispatch();


    useEffect(() => {
        if (authUser !== null) {
            if (search && searchParams.get('globalCountryId') !== null && globalCountryId !== Number(searchParams.get('globalCountryId'))) {
                if (authUser.countries_name.find((country: any) => country.id === Number(searchParams.get('globalCountryId'))) != undefined)
                    handleGlobalCountry(Number(searchParams.get('globalCountryId')))
                else {
                    searchParams.set("globalCountryId", authUser.countries[0])
                    replace(`${pathname}?${searchParams}`);
                    handleGlobalCountry(authUser.countries[0])
                }
            }
        }
    }, []);

    const handleGlobalCountry = (countryId: number) => {
        dispatch(setGlobalCountry(countryId));
        localStorage.setItem("globalCountryId", countryId.toString());
        searchParams.set("globalCountryId", countryId.toString())
        replace(`${pathname}?${searchParams}`);
    }


    return (

        <>
            <Container maxWidth="xl" className={customClasses.container}>
                {authUser !== null &&
                    <Box className={classes.Containerbreadcumb} display="flex" justifyContent="space-between" alignContent="center">
                        <Box display="flex" alignItems="center">
                            <ResetButton />
                            <ReportButton />
                        </Box>

                        {/* <Box display="flex" alignItems="center">
                            <Breadcrumbs />
                        </Box> */}

                        <Box display="flex" alignItems="center">
                            <Room color="primary" fontSize="small" />
                            {authUser !== null && authUser.countries_name !== undefined && globalCountryId !== null &&
                                <>
                                    {authUser.countries.length === 1 ?

                                        <Typography color="primary" variant="body2" style={{ display: "flex" }}>
                                            {authUser.countries_name.find((country: any) => country.id === globalCountryId).name}
                                        </Typography>

                                        :
                                        <>
                                            <Autocomplete
                                                id="countries-menu"
                                                options={authUser.countries_name}
                                                value={authUser.countries_name.find((country: any) => country.id === globalCountryId)}
                                                autoComplete
                                                autoSelect
                                                getOptionLabel={(country: ICountry) => country.id !== undefined ? country.name : ''}
                                                renderTags={() => authUser.countries_name.find((country: any) => country.id === globalCountryId).name}
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={(event, newValue) => { if (!!newValue) handleGlobalCountry(newValue.id) }}
                                                className={classes.input + ' client'}
                                            />
                                        </>
                                    }
                                </>
                            }
                        </Box>
                    </Box>
                }
            </Container>

        </>

    );


}

export default ClientBreadcumb;