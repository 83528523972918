// React
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { Grid, Container, IconButton, Box, Button } from '@material-ui/core';
import { DoubleArrow } from '@material-ui/icons';

// Custom components
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import TypeFilter from '../../../components/common/header/TypeFilter';
import ContentFilter from '../../../components/layouts/filters/ContentFilter';
import ScrollSubHeader from '../../../components/common/header/ScrollSubHeader';
import NotFilter from '../../NotFilter';
import CourseBlock from '../../../components/courses/CourseBlock';
import HiddenDesktop from '../../../components/common/HiddenDesktop';

// Custom Hooks
import useGeneralSummaryReport from "../../../hooks/useGeneralSummaryReport";
import useSkeleton from '../../../components/layouts/navigation/SkeletonLayout';

// Redux Actions
import { getCoursesByBrand } from '../../../redux/actions/courses';
import { getCoursetypes } from '../../../redux/actions/coursetypes';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce'

//Custom Hook
import useScreenWidthView from '../../../hooks/useScreenWidth';

interface IFilter {
    family_id: number | null,
    brand_id: number | null,
    line_id: number | null,
    country_id: number | null,
    role_id: number | null,
    language_id: number | null,
    orderby_id: number,
    tag_id: number | null,
    coursetype_id: number | null,
    searchText: string
}

function CourseList() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidthView(750);
    const isTablet = useScreenWidthView(1000);

    // Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { coursetypes } = useSelector((state: IRootState) => state.coursetype);
    const { courses, coursesProgress, loading } = useSelector((state: IRootState) => state.course);
    const { globalFamilyId, globalBrandId, globalCountryId, globalLineId, globalRoleId, globalLanguageId, globalOrderById, globalTag } = useSelector((state: IRootState) => state.global);
    const { loading: loadingSkeleton, component: componentSkeleton } = useSkeleton();

    // Filter State
    const [filters, setFilters] = useState<IFilter>({
        family_id: null,
        brand_id: null,
        line_id: null,
        country_id: null,
        role_id: null,
        language_id: null,
        orderby_id: 0,
        tag_id: null,
        coursetype_id: null,
        searchText: ''
    });

    useGeneralSummaryReport(courses, 0, 3);
    const rowsPerPage = useRef<number>(8)
    const pageNumber = useRef<number>(1)

    const nextPage = () => {
        pageNumber.current++;
        getResourcesFromAPI();
    }

    const getResourcesFromAPI = () => {
        const pagination = { pageNumber: pageNumber.current, rowsPerPage: rowsPerPage.current }
        dispatch(getCoursesByBrand({ filters, pagination }));
    }

    useEffect(() => {
        if (!coursetypes.length)
            dispatch(getCoursetypes())
    }, []);

    useEffect(() => {
        setFilters({
            ...filters,
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            line_id: globalLineId,
            country_id: globalCountryId,
            role_id: globalRoleId,
            language_id: globalLanguageId,
            orderby_id: globalOrderById,
            tag_id: globalTag
        });
    }, [globalFamilyId, globalBrandId, globalLineId, globalCountryId, globalRoleId, globalLanguageId, globalOrderById, globalTag]);

    useEffect(() => {
        pageNumber.current = 1;
        getResourcesFromAPI();
    }, [filters]);

    /* Functions */
    const handleChangeFilter = (coursetype_id: number) => {
        setFilters({ ...filters, coursetype_id });
    }

    const handleContentSearch = debounce((event: any) => {
        const value = event.target.value;

        if (value !== '' && value !== null) {
            const rawValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            setFilters({ ...filters, searchText: rawValue });
        } else
            setFilters({ ...filters, searchText: '' });
    }, 500)

    const myElement: HTMLElement | null = document.getElementById('windowCourseClient');
    let midOfWidth = window.innerWidth / 2

    /*if (loadingSkeleton) {
        return (
            <>
                {componentSkeleton}
            </>
        )
    }*/
    return (
        <>
            {loadingSkeleton && componentSkeleton}

            <div style={{ display: loadingSkeleton ? 'none' : 'block' }}>
                <Header title={t("academy")} />
                <SubHeader>
                    <ScrollSubHeader id='windowCourseClient'>
                        {coursetypes.map((coursetype: any, index: number) => (
                            <TypeFilter
                                key={coursetype.id}
                                id={coursetype.id}
                                type={coursetype.name}
                                categories={[]}
                                active={coursetype.id === filters.coursetype_id}
                                handleChangeFilter={handleChangeFilter}
                            />
                        ))}
                        <TypeFilter
                            id={null}
                            type={t("all")}
                            categories={[]}
                            active={filters.coursetype_id === null}
                            handleChangeFilter={handleChangeFilter}
                        />
                    </ScrollSubHeader>
                    <HiddenDesktop>
                        <IconButton className='buttonScroll' onClick={() => { myElement?.scrollBy(midOfWidth, 0) }}>
                            <DoubleArrow />
                        </IconButton>
                    </HiddenDesktop>
                </SubHeader>
                <ContentFilter sectionName={t("courses")} handleContentSearch={handleContentSearch} />

                <Container data-testid="courses-client-container" style={{ maxWidth: 1111 }}>
                    {authUser !== null &&
                        <Grid container spacing={isMobile ? 1 : 2} direction="row" alignItems="stretch" >
                            {!courses.length && !loading ? <NotFilter /> :
                                courses.map((course: any, index: number) => (
                                    <Grid key={course.id} item xs={6} sm={4} md={isTablet ? 6 : 4} lg={3} className='websiteCourse'>
                                        <CourseBlock
                                            course={course}
                                            hasaccess={course.visibility === 1 && (authUser.vip_lines.includes(course.line_id) || (course.line_id === null && authUser.vip_brands.includes(course.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                            progress={coursesProgress.find((courseProgress) => courseProgress.course_id === course.id)}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    }

                    {courses.length % rowsPerPage.current === 0 &&
                        <Box mt={6} pb={4} style={{ textAlign: "center" }}>
                            <Button id="btn-see-more" variant="contained" size="large" color="primary" onClick={() => nextPage()}>{t("see-more")}</Button>
                        </Box>
                    }
                </Container>
            </div>
        </>
    );
}

export default CourseList;
