import {
  IReportState,
  START_GET_REPORT_POPULAR_CONTENT, SUCCESS_GET_REPORT_POPULAR_CONTENT, ERROR_GET_REPORT_POPULAR_CONTENT,
  START_GET_REPORT_BEST_CTR, SUCCESS_GET_REPORT_BEST_CTR, ERROR_GET_REPORT_BEST_CTR,
  START_GET_REPORT_SESSION_LOGS, SUCCESS_GET_REPORT_SESSION_LOGS, ERROR_GET_REPORT_SESSION_LOGS,
  START_GET_REPORT_POPULAR_DETAIL_CONTENT, SUCCESS_GET_REPORT_POPULAR_DETAIL_CONTENT, ERROR_GET_REPORT_POPULAR_DETAIL_CONTENT,
} from '../types';

const initialState: IReportState = {
  popularContent: {
    mostVisualized: [],
    mostViewed: []
  },
  bestCTR: {
    weekCTR: [],
    monthCTR: []
  },
  sessionlogs: [],
  popularDetailContent: [],
  menuCodes: [],
  msg: null,
  error: false,
  loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case START_GET_REPORT_POPULAR_CONTENT:
    case START_GET_REPORT_BEST_CTR:
    case START_GET_REPORT_SESSION_LOGS:
    case START_GET_REPORT_POPULAR_DETAIL_CONTENT:
      return {
        ...state,
        msg: null,
        error: false,
        loading: true
      }
    case SUCCESS_GET_REPORT_POPULAR_CONTENT:
      return {
        ...state,
        popularContent: action.payload.popularContent,
        msg: null,
        error: false,
        loading: false
      }
    case SUCCESS_GET_REPORT_BEST_CTR:
      return {
        ...state,
        bestCTR: action.payload.bestCTR,
        msg: null,
        error: false,
        loading: false
      }
    case SUCCESS_GET_REPORT_SESSION_LOGS:
      return {
        ...state,
        sessionlogs: action.payload.sessionlogs,
        msg: null,
        error: false,
        loading: false
      }
    case SUCCESS_GET_REPORT_POPULAR_DETAIL_CONTENT:
      return {
        ...state,
        popularDetailContent: action.payload.popularDetailContent,
        menuCodes: action.payload.menuCodes,
        msg: null,
        error: false,
        loading: false
      }
      case ERROR_GET_REPORT_POPULAR_CONTENT:
      case ERROR_GET_REPORT_BEST_CTR:
      case ERROR_GET_REPORT_SESSION_LOGS:
      case ERROR_GET_REPORT_POPULAR_DETAIL_CONTENT:
      return {
        ...state,
        popularContent: [],
        msg: action.payload,
        error: true,
        loading: false
      }
    default:
      return state;
  }
}