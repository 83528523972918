import { createMuiTheme } from "@material-ui/core/styles";

const mainTheme = createMuiTheme({
    typography: {
        fontFamily: "Poppins, sans-serif",
        h1: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "3.75rem",
        },
        h2: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "3rem",
        },
        h3: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "2.625rem",
        },
        h4: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "2rem",
        },
        h5: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.75rem",
        },
        h6: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.5rem",
        },
        subtitle1: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.125rem",
        },
        body1: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "1rem",
        },
        body2: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "0.875rem",
        },
        caption: {
            fontFamily: "Poppins, sans-serif",
            fontSize: "0.75rem",
        },
    },
    palette: {
        common: {
            black: "#171A1C",
            white: "#FAFAFA",
        },
        primary: {
            light: process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296",
            main: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
            dark: process.env.REACT_APP_COLOR_PRIMARY_DARK || "#FB0E4B",
        },
        secondary: {
            main: process.env.REACT_APP_COLOR_SECONDARY_MAIN || "#fd9eb7",
        },
        grey: {
            50: "#FAFAFA",
            100: "#E3E5E8",
            200: "#C7CCD1",
            300: "#ABB3BA",
            400: "#8F99A3",
            500: "#73808C",
            600: "#5C6670",
            700: "#454D54",
            800: "#2E3338",
            900: "#171A1C",
        },
        error: {
            light: "rgba(242, 61, 79, 0.2)",
            main: "#FE4634",
            dark: "#F23D4F",
        },
        warning: {
            light: "rgba(255, 119, 51, 0.2)",
            main: "#FF8833",
            dark: "#FF7733",
        },
        success: {
            light: "rgba(0, 166, 80, 0.2)",
            main: "#8DDD55",
            dark: "#00A650",
        },
        info: {
            light: "rgba(65, 137, 230, 0.2)",
            main: "#3483FA",
        },
        text: {
            primary: "#2E3338",
            secondary: "#8F99A3",
            disabled: "#FAFAFA",
        },
    },
    overrides: {
        // BASE
        MuiButton: {
            root: {
                textTransform: 'capitalize',
                fontWeight: 400,
                border: 'none',
                padding: '9px 14px',
                borderRadius: '8px',
                fontSize: "0.875rem",
                maxWidth: '100%',
                maxHeight: '42px',
            },
            contained: {
                boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
                color: "#FAFAFA",
                "&:hover": {
                    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_DARK || "#FB0E4B",
                    boxShadow: `0px 16px 40px 0px ${process.env.REACT_APP_COLOR_SURFACE_LIGHT || "rgba(252, 62, 111, 0.3)"}`,
                },
            },
            outlined: {
                boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                marginRight: "32px",
                color: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
                backgroundColor: "#FAFAFA",
                border: "none",
                width: '100%',
                "&:hover": {
                    color: "#FAFAFA",
                    backgroundColor: "#C7CCD1",
                },
                "& .Mui-disabled": {
                    border: "none",
                }
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                ZIndex: "99999",
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: "none",
            },
        },
        MuiCardActionArea: {
            focusHighlight: {
                backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
            },
        },
        MuiDialogActions: {
            root: {
                background: '#FAFAFA',
                padding: '16px 11px'
            }
        },
        // FORMS
        MuiGrid: {
            "spacing-xs-3": {
                margin: 0,
            }
        },
        MuiInputBase: {
            root: {
                border: 'none',
                boxShadow: 'inset 0px 0px 10px 0px rgb(0 0 0 / 16%)',
                padding: '9px 14px',
                background: '#FAFAFA',
                borderRadius: '8px',
                fontSize: '1rem',
                maxWidth: '100%',
                maxHeight: '45px',
                color: "#171A1C",
            }
        },
        MuiInput: {
            underline: {
                borderBottom: 'none !important',
                "&:before": {
                    borderBottom: 'none !important',
                },
                "&:after": {
                    borderBottom: 'none !important',
                },
            },
        },
        MuiStepIcon: {
            root: {
                color: "#ABB3BA",
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'transparent',
                border: '1px solid #C7CCD1',
                borderRadius: '8px !important',
            },
            underline: {
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    opacity: 0
                }
            }
        },
        MuiTableCell: {
            root: {
                padding: '0px',
                width: 'fit-content !important',
                "&:first-child": {
                    paddingLeft: '52px !important',
                },
            },
        },

        MuiPaper: {
            elevation8: {
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
            },
        },
        MuiToolbar: {
            root: {
                alignItems: "initial",
            }
        },
        MuiLink: {
            root: {
                '&:hover, &:hover h1, &:hover h2, &:hover h3, &:hover h4, &:hover h5, &:hover h6, &:hover p': {
                    color: process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296"
                }
            },
            underlineHover: {
                '&:hover': {
                    textDecoration: 'none',
                    cursor: 'pointer'
                }
            }
        },

    },
});

export default mainTheme;
