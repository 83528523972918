// React
import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation, useHistory } from "react-router-dom"

// Material UI
import { Box, Button, Container, Tab, Tabs, Typography, Tooltip, Fade } from "@material-ui/core";
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table';

// Commons components
import CustomSnackBar from '../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../components/common/ScreenLoading';
import TicketModal from "../../components/seminars/TicketModal";
import ButtonBack from '../../components/common/ButtonBack';
import TabPanel from '../../components/common/admin/TabPanel';
import DetailInfoPanel from '../../components/layouts/common/DetailInfoPanel';
import DetailHeroPanel from '../../components/layouts/common/DetailHeroPanel';
import SeminarModal from '../../components/seminars/SeminarModal';
import SeminarLessonDetail from '../../components/seminarlessons/SeminarLessonDetail';
import SeminarLessonModal from '../../components/seminarlessons/SeminarLessonModal';
import SeminarLessonBlock from '../../components/seminarlessons/SeminarLessonBlock';
import VerifyAccessRoute from '../../components/common/VerifyAccessRoute';

// Custom Hooks
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";
import useUserDisplaytime from '../../hooks/useUserDisplaytime';
import useBooleanState from '../../hooks/useBooleanState';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';
import { showSeminar, toggleFormSeminar } from '../../redux/actions/seminars'
import { setViewSeminarLesson, toggleFormSeminarLesson } from '../../redux/actions/seminarlessons'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';
import clsx from 'clsx';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import SeminarStyles from "../../styles/components/seminars/SeminarStyles";
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import TableScreenStyles from '../../styles/components/common/admin/TableScreenStyles';
import VipStyles from '../../styles/components/common/VipStyles';

// import ShareIcon from '../../components/common/ShareIcon';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const scrollToRef = (ref: any) => window.scrollTo({ top: ref.current.offsetTop, left: 0, behavior: 'smooth' })

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";
interface ParamTypes {
    id: string
}

function SeminarDetail() {

    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    const isLaptop = useScreenWidth(1024);

    //Styles
    const classes = SeminarStyles();
    const formClasses = FormsStyles();
    const tableclass = TableScreenStyles();
    const vipclasses = VipStyles();

    //Tab State
    const [currentTab, setCurrentTab] = useState(0);

    //Redux Hooks
    const dispatch = useDispatch();
    //Location
    const location = useLocation();
    const { pathname, search } = useLocation();
    const { replace } = useHistory();
    const searchParams = new URLSearchParams(search);
    const { id } = useParams<ParamTypes>();

    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { seminarSelected, showForm, error, msg, loading } = useSelector((state: IRootState) => state.seminar);
    const { seminarlesson_selected, showForm: showFormSeminarLesson, registeredUsers } = useSelector((state: IRootState) => state.seminarlesson);
    const [open, setOpen] = useBooleanState(false);
    const [favoriteState, setFavoriteState] = useState<boolean>(false);
    const myRef = useRef(null);
    const { handleViewGeneralSummaryReport } = useGeneralSummaryReport([], 0, 8);

    const executeScroll = () => scrollToRef(myRef)

    // Send track of user
    useUserDisplaytime({ foreign: seminarSelected });

    useEffect(() => {
        dispatch(showSeminar(id));
    }, [id]);

    useEffect(() => {
        if (seminarSelected !== null) {
            handleViewGeneralSummaryReport(seminarSelected.id)
            setFavoriteState(Boolean(seminarSelected.favoriteId));
        }
        if (seminarSelected !== null && search && (searchParams.get('lesson') !== null && searchParams.get('lesson') !== "null")) {
            let tmpLessonIndex = seminarSelected.lessons.findIndex((lesson: any) => lesson.id === Number(searchParams.get('lesson')));
            dispatch(setViewSeminarLesson(seminarSelected.lessons[tmpLessonIndex]));
            searchParams.delete("lesson");
            replace(`${pathname}?${searchParams}`);
        } else if (seminarSelected !== null && seminarSelected.lessons.length === 1 && seminarSelected.visibility !== 1) {
            dispatch(setViewSeminarLesson(seminarSelected.lessons[0]));
        }
    }, [seminarSelected])

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const columns: any = [
        {
            title: t("email"), field: 'email', render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        <Tooltip placement="bottom-start" TransitionComponent={Fade}
                            TransitionProps={{ timeout: 400 }} title={rowData.email}>
                            <Typography className={tableclass.emailText}>
                                {rowData.email}
                            </Typography>
                        </Tooltip>
                    </Box>)
            }
        },
        { title: t("phone"), field: 'phone', type: string },
        { title: t("postal-code"), field: 'postalcode', type: string },
        { title: t("town"), field: 'townName', type: string },
        { title: t("registered-time"), field: 'registeredtime', type: string }
    ];

    //Funcion para cuando esten los metodos
    let isWraped = false;

    /** Functions */
    const handleFavoriteClick = (seminar_id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id: seminar_id,
                type: 'seminar'
            }));
        } else {
            dispatch(unsetFavorite({
                id: seminar_id,
                type: 'seminar',
            }));
        }
        setFavoriteState(state);
    };

    return (
        <>
            {(seminarSelected !== null) &&
                <Container maxWidth={'xl'}>
                    <VerifyAccessRoute visibility={seminarSelected.visibility} />

                    {showForm && (
                        <SeminarModal />
                    )}

                    {seminarlesson_selected === null && showFormSeminarLesson === false ?
                        <ButtonBack data-seminardetail="goback" dataName="seminar" />
                        :
                        <ButtonBack data-seminarlessondetail="goback" goBack={false} customFunction={setViewSeminarLesson(null)} dataName="seminarlesson" />
                    }

                    {seminarlesson_selected !== null && showFormSeminarLesson === false &&
                        <SeminarLessonDetail handleOpen={setOpen} />
                    }
                    <Box display={isWraped ? 'flex' : 'block'}
                        marginTop={seminarlesson_selected != null && showFormSeminarLesson === false ? 4 : seminarSelected.lessons.length > 1 && 0}
                        gridGap={isWraped ? 24 : 0}>
                        <Box display='flex' mt={(isWraped || (authUser !== null && [1, 2].includes(authUser.role_id))) ? 0 : 5} flexWrap={isMobile ? 'wrap' : 'nowrap'} gridGap={24}>
                            <Box maxWidth={isLaptop ? 500 : 960} width='100%' marginX='auto' className={clsx((seminarSelected.visibility === 1) && vipclasses.containerHeroDetail, isWraped && 'wraped')}>
                                {seminarlesson_selected === null && (seminarSelected.lessons.length > 1 || seminarSelected.lessons.length === 0) &&
                                    <Box display='flex' gridGap={24} flexWrap={isLaptop || isMobile ? 'wrap' : 'nowrap'}>
                                        <Box aria-details={seminarSelected.title} height={isMobile ? 210 : 300} width={isLaptop || isMobile ? '100%' : '50%'}
                                            style={{
                                                backgroundImage: `url(${process.env.REACT_APP_AWS_URL + '/seminars/' + seminarSelected.cover})`,
                                                backgroundPosition: 'center center',
                                                backgroundSize: 'cover'
                                            }}>
                                            {/* <img
                                                style={{ borderRadius: 16 }}
                                                src={`${process.env.REACT_APP_AWS_URL + '/seminars/' + seminarSelected.cover}`}
                                                width='100%'
                                                alt={seminarSelected.title}
                                                title={seminarSelected.title}
                                            /> */}
                                        </Box>
                                        <Box width={isLaptop || isMobile ? '100%' : '50%'}>
                                            <Typography>{seminarSelected.description}</Typography>
                                        </Box>
                                    </Box>
                                }
                                {(authUser !== null && [1, 2].includes(authUser.role_id)) &&
                                    <Box display='flex'
                                        marginTop={seminarlesson_selected != null && showFormSeminarLesson === false ? '-16px' : seminarSelected.lessons.length > 1 && 0}
                                        marginBottom={seminarlesson_selected != null && showFormSeminarLesson === false ? '' : seminarSelected.lessons.length > 1 && '-32px'}>
                                        <Box maxWidth={isLaptop ? 500 : 960} width='100%' marginX='auto' p={isMobile ? 1 : 3} pb={0}>
                                            <Tabs
                                                value={currentTab}
                                                indicatorColor="secondary"
                                                textColor="primary"
                                                className={formClasses.tapForm}
                                                onChange={handleChangeTab}
                                                aria-label="disabled tabs example">

                                                <Tab label={t("content")}  {...a11yProps(0)} />
                                                <Tab label={t("registered")}  {...a11yProps(1)} />

                                            </Tabs>
                                        </Box>
                                        <Box width='361px' />
                                    </Box>
                                }
                                <div ref={myRef} >
                                    <TabPanel value={currentTab} index={0} >

                                        {seminarlesson_selected !== null && showFormSeminarLesson === false ?
                                            <Box className={classes.containerDetail}>
                                                <Typography align="center" variant="h4">{t('about-this-event')}</Typography>
                                                <Typography>{seminarSelected.description}</Typography>
                                                {seminarlesson_selected.datetime &&
                                                    <Box className={classes.talkerDateDetail}>
                                                        <Typography>{`${moment(seminarlesson_selected.datetime).format('LLLL')}`}</Typography>
                                                    </Box>
                                                }

                                                {seminarlesson_selected.isRegistered === null &&
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={() => { setOpen(true) }}
                                                        className={(seminarSelected.visibility === 1) ? vipclasses.btnLinkDetail : classes.btnLinkDetail}>
                                                        {t('book-my-ticket')}
                                                    </Button>
                                                }
                                            </Box>
                                            :
                                            <>

                                                {/* {location.pathname.includes('admin') &&
                                                    <AdminDetailInfo resourceSelected={course_selected} />
                                                } */}

                                                {showFormSeminarLesson && (
                                                    <SeminarLessonModal />
                                                )}

                                                {seminarSelected.lessons.map((seminarlesson: any) => (
                                                    <SeminarLessonBlock
                                                        key={seminarlesson.id}
                                                        seminarlesson={seminarlesson}
                                                        vipContent={(seminarSelected.visibility === 1)}
                                                        hasaccess={seminarSelected.visibility === 1 && authUser != null && (authUser.vip_lines.includes(seminarSelected.line_id) || (seminarSelected.line_id === null && authUser.vip_brands.includes(seminarSelected.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                                    />
                                                ))}
                                            </>
                                        }
                                    </TabPanel>

                                    <TabPanel value={currentTab} index={1}>
                                        <div className="table_custom">
                                            <MaterialTable
                                                localization={localization}
                                                title="Seminarios Admin"
                                                columns={columns}
                                                data={registeredUsers}
                                                options={{ ...options, exportAllData: true }}
                                                icons={tableIcons}
                                            />
                                        </div>
                                    </TabPanel>
                                </div>
                            </Box>

                            <Box width={isMobile ? '100%' : 'auto'}>
                                <DetailHeroPanel
                                    visibility={seminarSelected.visibility}
                                    status={seminarSelected.status}
                                    brand={'Anesi'}
                                    title={seminarSelected.title}
                                    description={isWraped ? seminarSelected.description : null}
                                    // Actions
                                    customButton={isWraped ? (() => setOpen(true)) : undefined}
                                    customButtonText={isWraped ? t('book-my-ticket') : null}
                                    edit={() => { dispatch(toggleFormSeminar()) }}
                                    add={() => { dispatch(toggleFormSeminarLesson()) }}
                                    onClickFavorite={handleFavoriteClick(seminarSelected.id)}
                                    favoriteState={favoriteState}
                                // share={<ShareIcon
                                //     visibility={seminarSelected.visibility}
                                //     title={seminarSelected.title}
                                //     description={seminarSelected.description}
                                //     link={`${window.location.href}`}
                                //     type="offer"
                                //     multimedia={`${process.env.REACT_APP_AWS_URL}/seminarios/${seminarSelected.cover}`}
                                //     component="buttonTextGreen"
                                //     buttonTextGreen={t("share")}
                                // />}
                                />
                                <DetailInfoPanel attributes={{
                                    "item": [
                                        {
                                            "id": 0,
                                            "type": "date",
                                            "title": `${t('date')} ${t('live-event')}`,
                                            "color": "grey6",
                                            "info": (seminarSelected.datetime) ? `${moment(seminarSelected.datetime).format('LLLL')}` : t('without-date'),
                                        }
                                    ]
                                }} />
                            </Box>
                        </Box>
                    </Box>

                    {open === true && !location.pathname.includes('admin') &&
                        <TicketModal stateModal={open} handleClose={setOpen} />
                    }

                    <CustomSnackBar errors={[error]} msgs={[msg]} />
                    <ScreenLoading loadings={[loading]} />
                </Container>
            }
        </>
    );
}

export default SeminarDetail;