// React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Material UI
import { Box, makeStyles, Grid, Typography } from '@material-ui/core';

// Custom Components
import CardHero from './CardHero';
import ShareIcon from '../ShareIcon';
import HomeCoursesFeedSkeleton from '../../layouts/navigation/skeletons/HomeCoursesFeedSkeleton';

// Redux Actions
import { setFavorite, unsetFavorite } from '../../../redux/actions/favorites';
import { getHomeCourses } from '../../../redux/actions/courses';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';

// Custom Style
const CoursesFeedStyle = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid  #C7CCD1',
        marginTop: 32,
        marginBottom: 24,
        "& h5": {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                fontWeight: 500,
            },
        },
        "& p": {
            whiteSpace: "nowrap",
        }
    },
    seeMore: {
        textDecoration: 'underline',
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
}));

function CoursesFeed() {

    // Translate
    const { t } = useTranslation(["global"]);

    // Styles
    const classes = CoursesFeedStyle();

    /* Functions */
    const history = useHistory();

    //Redux Hooks
    const dispatch = useDispatch();
    const { homeCourses, feedCourses, loading: coursesLoading } = useSelector((state: IRootState) => state.course);
    const { globalCountryId, globalLanguageId } = useSelector((state: IRootState) => state.global);

    useEffect(() => {
        dispatch(getHomeCourses({
            country_id: globalCountryId,
            language_id: globalLanguageId
        }));
    }, [globalCountryId, globalLanguageId]);

    const handleFavoriteClick = (id: number, type: string, inputField: string) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id,
                state,
                type,
                favoritefield: inputField
            }));
        } else {
            dispatch(unsetFavorite({
                id,
                state,
                type: type,
                favoritefield: inputField
            }));
        }
    };

    return (
        <>
            {(coursesLoading === true) ?
                <HomeCoursesFeedSkeleton />
                :
                <>
                    {(feedCourses.length > 0)
                        ?
                        <>
                            {(feedCourses.length > 0) &&
                                <Grid item xs={12} className={classes.header}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h5">{t("Continue-learning")}</Typography>
                                        <Typography onClick={() => history.push(`/cursos`)} color="textSecondary" className={classes.seeMore}>{t("see-more")}</Typography>
                                    </Box>
                                </Grid>
                            }
                            <Grid container spacing={2}>
                                {feedCourses.map((feedCourse: any) => (
                                    <Grid key={feedCourse.id} item xs={12} md={6} lg={4}>
                                        <CardHero
                                            type={1}
                                            redirect={`/cursos/${feedCourse.brand_id}/${feedCourse.slug}`}
                                            cardBrandName={feedCourse.brandName}
                                            cardVisibility={feedCourse.visibility}
                                            cardImage={feedCourse.image_url}
                                            cardTitle={feedCourse.title}
                                            cardDescription={feedCourse.description}
                                            cardDate={`${moment(feedCourse.created_at).fromNow()}`}
                                            cardActions={
                                                <ShareIcon
                                                    visibility={feedCourse.visibility}
                                                    title={feedCourse.title}
                                                    description={feedCourse.description}
                                                    link={`${window.location.origin}/cursos/${feedCourse.brand_id}/${feedCourse.slug}${window.location.search}`}
                                                    type="news"
                                                    multimedia={feedCourse.image_url}
                                                    buttonText={t("share")}
                                                />
                                            }
                                            favoriteState={Boolean(feedCourse.favoriteId)}
                                            onClickFavorite={handleFavoriteClick(feedCourse.id, 'course', 'feedCourses')}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                        :
                        <>
                            {(homeCourses.length > 0) &&
                                <Grid item xs={12} className={classes.header}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h5">{t("new-formations")}</Typography>
                                        <Typography onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); history.push(`/cursos`) }} color="textSecondary" style={{ textDecoration: 'underline', cursor: "pointer" }}>{t("see-more")}</Typography>
                                    </Box>
                                </Grid>
                            }
                            <Grid container spacing={2}>
                                {homeCourses.slice(0, 3).map((homeCourse: any) => (
                                    <Grid key={homeCourse.id} item xs={12} lg={4}>
                                        <CardHero
                                            type={1}
                                            redirect={`/cursos/${homeCourse.brand_id}/${homeCourse.slug}`}
                                            cardBrandName={homeCourse.brandName}
                                            cardVisibility={homeCourse.visibility}
                                            cardImage={homeCourse.image_url}
                                            cardTitle={homeCourse.title}
                                            cardDescription={homeCourse.description}
                                            cardDate={`${moment(homeCourse.created_at).fromNow()}`}
                                            cardActions={
                                                <ShareIcon
                                                    visibility={homeCourse.visibility}
                                                    title={homeCourse.title}
                                                    description={homeCourse.description}
                                                    link={`${window.location.origin}/cursos/${homeCourse.brand_id}/${homeCourse.slug}${window.location.search}`}
                                                    type="news"
                                                    multimedia={homeCourse.image_url}
                                                    buttonText={t("share")}
                                                />
                                            }
                                            favoriteState={Boolean(homeCourse.favoriteId)}
                                            onClickFavorite={handleFavoriteClick(homeCourse.id, 'course', 'homeCourses')}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    }
                </>
            }
        </>
    );
}

export default CoursesFeed;
