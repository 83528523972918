// Material UI
import { Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Variant as ThemeVariant } from '@material-ui/core/styles/createTypography';

//Styles
import TooltipCardsStyles from '../../../styles/components/common/TooltipCardsStyles.js';
// Types
type Variant = ThemeVariant | 'srOnly';
type TooltipCardsProps = {
    titleCard: string,
    classNameCustom: string,
    variantCustom?: Variant | 'inherit',
}

const TooltipCards = ({ titleCard, classNameCustom, variantCustom = 'inherit' }: TooltipCardsProps) => {
    const classes = TooltipCardsStyles();

    return (
        <Tooltip title={titleCard} placement='bottom-start' arrow>
            <Typography variant={variantCustom} classes={{ root: clsx(classes.root, `${classNameCustom}`) }}>
                {titleCard}
            </Typography>
        </Tooltip>
    )
}

export default TooltipCards;
