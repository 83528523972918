import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { showModal, hideModal, assignActionModal } from '../../../../redux/actions/modal';
import { IRootState } from '../../../../redux/types';

export const useModal = () => {
    // Get modal params from store using reselect
    return {
        modal: useSelector((state: IRootState) => state.modal, shallowEqual)
    };
};

export const useShowModal = (props: any) => {
    const dispatch = useDispatch();
    return useCallback(
        (params = {}) => {
            dispatch(showModal({ ...props }));
        },
        [dispatch]
    );
};

export const useErrorModal = ({ props }: any) => {
    const dispatch = useDispatch();
    return {
        handleShowErrorModal: useCallback(
            error => {
                // dispatch(showErrorModalAction({ ...props }));
            },
            [dispatch]
        )
    };
};

export const useConfirmationModal = (paramFunction:any) => {
    const dispatch = useDispatch();
    return useCallback(() => {
            dispatch(assignActionModal(paramFunction));
        }, [dispatch]
    );
};

export const useInfoModal = () => {
    return {
        // Create your reusable info modal logic
    };
};

export const useHideModal = () => {
    const dispatch = useDispatch();
    const handleOnClose = useCallback(() => {
        dispatch(hideModal());
    }, [dispatch]);
    return {
        handleOnClose
    }
};