import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_GET_LANGUAGES, SUCCESS_GET_LANGUAGES, ERROR_GET_LANGUAGES,
    START_GET_FILTERED_LANGUAGES, SUCCESS_GET_FILTERED_LANGUAGES, ERROR_GET_FILTERED_LANGUAGES
} from '../types';
import { apiCall } from '../config/axios';

function* getLanguages() {
    try{
        const response = yield call(apiCall, 'get', '/languages')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_LANGUAGES, payload: response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_LANGUAGES, payload: error.message})
    }
}

function* getFilteredLanguages() {
    try{
        const response = yield call(apiCall, 'get', '/languages/filteredlanguages')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_FILTERED_LANGUAGES, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_GET_FILTERED_LANGUAGES, payload: error.message})
    }
}

export default function* languages() {
    yield takeLatest(START_GET_LANGUAGES, getLanguages);
    yield takeLatest(START_GET_FILTERED_LANGUAGES, getFilteredLanguages);
}