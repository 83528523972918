// React
import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, Dialog, TextField, Chip, Typography, Grid, Switch, Select, MenuItem, FormHelperText, DialogContent,
    Checkbox, DialogTitle, DialogContentText, DialogActions, Tabs, Tab, Box, InputLabel, List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../config/table';

// Custom components
import { InputCountry, SaveButton, CancelButton } from '../common/forms';
import SubtitleGrey from '../../components/common/typography/SubtitleGrey';
import TabPanel from '../common/admin/TabPanel';
import useBooleanState from '../../hooks/useBooleanState';


// Redux Actions
import { setMassivelyAssign, getMassivelyAssignData } from '../../redux/actions/users';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

// Interfaces
import FamilyInterface from '../../interfaces/brand/FamilyInterface';
import BrandInterface from '../../interfaces/brand/BrandInterface';
import LineInterface from '../../interfaces/brand/LineInterface';
import CustomSwitchComponent from '../common/SwitchCustom';
import useMassAssignmentData from "../../hooks/useMassAssignmentData";
import useMassAssignmentAddedInputs from "../../hooks/useMassAssignmentAddedInputs";
import MassAssigmentBody from "../common/admin/MassAssigmentBody";
import { storeMassAssignment } from "../../redux/actions/resources";
import { IAddedInputs } from "../../interfaces/MassAssignmentAddedInputs";


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type BrandVip = {
    brand_id: number,
    type: number,
    status: number
}

type LineVip = {
    line_id: number,
    type: number,
    status: number
}

type MassivelyAssign = {
    brands: BrandVip[],
    lines: LineVip[],
    menus: number[]
}

type UserMassivelyAssignProps = {
    userIds: number[]
}

function UserMassivelyAssign({ userIds }: UserMassivelyAssignProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const formClasses = FormsStyles();

    const massEditableFields = [
        'languages',
        'country',
        'province',
        'town',
        'postalcode',
    ]

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userFamilies, userBrands } = useSelector((state: IRootState) => state.brand);
    const { massivelyAssignData } = useSelector((state: IRootState) => state.user);

    //Massively Assign State
    const [massivelyAssign, setMassivelyAssignState] = useState<MassivelyAssign>({
        brands: [],
        lines: [],
        menus: []
    });
    const [family, setFamily] = useState<FamilyInterface | null>(null);
    const [brands, setBrands] = useState<number[]>([]);
    const [data, setData] = useMassAssignmentData()
    const { addedInputs, buttons, addInput, removeInput, handleCheckChange } =
        useMassAssignmentAddedInputs(massEditableFields)
    // tmpBrands State
    const [tmpBrands, setTmpBrands] = useState([]);

    //Tab State
    const [currentTab, setCurrentTab] = useState(0);

    // Open State
    const [open, toggleOpen] = useBooleanState();

    useEffect(() => {
        clearModal();
    }, []);

    useEffect(() => {
        if (open === true) {
            dispatch(getMassivelyAssignData(userIds));
        }
    }, [open]);

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleMultiAutoCompleteChange = (event: any, value: any) => {
        let optionsMulti = [];
        let brands: any = [];
        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                optionsMulti.push(value[i].id);
                let tmpBrand = userBrands.find((brand: BrandInterface) => brand.id === value[i].id);
                if (tmpBrand !== undefined) {
                    brands = [...brands, tmpBrand, ...tmpBrand.active_lines];
                }
            }
        }
        setBrands(optionsMulti);
        setTmpBrands(brands);
    }

    const handleAssignBrand = (brandValue: BrandInterface) => (event: ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        let tmpOptions: MassivelyAssign = { ...massivelyAssign };

        // Set values
        let tmpBrands: BrandVip[] = massivelyAssign.brands;
        let tmpLines: LineVip[] = massivelyAssign.lines;

        // Clear lines of brand
        brandValue.active_lines?.forEach((line: LineInterface) => {
            let lineIndex = tmpLines.findIndex((tmpLine: LineVip) => tmpLine.line_id === line.id);
            if (lineIndex >= 0) {
                tmpLines.splice(lineIndex, 1);
            }
        });

        // If true add brand and lines
        if (checked) {
            tmpBrands.push({
                brand_id: brandValue.id,
                type: 1,
                status: 0
            });
            brandValue.active_lines?.forEach((line: LineInterface) => {
                tmpLines.push({
                    line_id: line.id,
                    type: 1,
                    status: 0
                });
            });
            tmpOptions.lines = tmpLines;
        } else {
            let brandIndex = tmpBrands.findIndex((tmpBrand: BrandVip) => tmpBrand.brand_id === brandValue.id);
            if (brandIndex >= 0) {
                tmpBrands.splice(brandIndex, 1);
            }
        }
        tmpOptions.brands = tmpBrands;

        setMassivelyAssignState(tmpOptions);
    };

    const handleAssignBrandVip = (brandValue: BrandInterface) => (event: ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        let tmpOptions: MassivelyAssign = { ...massivelyAssign };

        // Set values
        let tmpBrands: BrandVip[] = massivelyAssign.brands;
        let tmpLines: LineVip[] = massivelyAssign.lines;

        let brand = tmpBrands.find((tmpBrand: BrandVip) => tmpBrand.brand_id === brandValue.id);

        if (brand !== undefined) {
            brand.status = Number(checked);
        }

        brandValue.active_lines?.forEach((lineValue: LineInterface) => {
            let line = tmpLines.find((tmpLine: LineVip) => tmpLine.line_id === lineValue.id);
            if (line !== undefined) {
                line.status = Number(checked);
            }
        });

        tmpOptions.brands = tmpBrands;
        tmpOptions.lines = tmpLines;

        setMassivelyAssignState(tmpOptions);
    };

    const handleAssignLine = (lineValue: LineInterface) => (event: ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        let tmpOptions: MassivelyAssign = { ...massivelyAssign };

        // Set values
        let tmpBrands: BrandVip[] = massivelyAssign.brands;
        let tmpLines: LineVip[] = massivelyAssign.lines;


        // If true add line and brand
        if (checked) {
            // Clear brand if exist
            let brandIndex = tmpBrands.findIndex((tmpBrand: BrandVip) => tmpBrand.brand_id === lineValue.brand_id);
            if (brandIndex < 0) {
                tmpBrands.push({ brand_id: lineValue.brand_id, type: 1, status: 0 });
            }
            tmpLines.push({ line_id: lineValue.id, type: 1, status: 0 });
        } else {
            let lineIndex = tmpLines.findIndex((tmpLine: LineVip) => tmpLine.line_id === lineValue.id);
            if (lineIndex >= 0) {
                tmpLines.splice(lineIndex, 1);
            }
        }
        tmpOptions.brands = tmpBrands;

        setMassivelyAssignState(tmpOptions);
    };

    const handleAssignLineVip = (lineValue: LineInterface) => (event: ChangeEvent<HTMLInputElement>) => {
        let checked = event.target.checked;
        let tmpOptions: MassivelyAssign = { ...massivelyAssign };

        // Set values
        let tmpBrands: BrandVip[] = massivelyAssign.brands;
        let tmpLines: LineVip[] = massivelyAssign.lines;

        let line = tmpLines.find((tmpLine: LineVip) => tmpLine.line_id === lineValue.id);

        if (line !== undefined) {
            line.status = Number(checked);
        }

        if (checked) {
            let brand = tmpBrands.find((tmpBrand: BrandVip) => tmpBrand.brand_id === lineValue.brand_id);

            if (brand !== undefined) {
                brand.status = Number(checked);
            }

            tmpOptions.brands = tmpBrands;
        }

        tmpOptions.lines = tmpLines;

        setMassivelyAssignState(tmpOptions);
    };

    const handleMenuChange = (value: any) => (event: ChangeEvent<HTMLInputElement>) => {

        let checked = event.target.checked;
        let tmpOptions: MassivelyAssign = { ...massivelyAssign };

        let tmpOption = tmpOptions.menus;
        if (checked) {
            tmpOption.push(value.id);
        } else {
            let indexOption = tmpOption.indexOf(value.id);
            tmpOption.splice(indexOption, 1);
        }
        tmpOptions.menus = tmpOption;

        setMassivelyAssignState(tmpOptions);
    };

    const handleSubmit = () => {
        const general: any = addedInputs.map((input: IAddedInputs) => ({
            name: input.name,
            value: data[input.name] !== null ? (data[input.name].id ?? data[input.name]) : null,
            overwrite: input.checked
        }))

        dispatch(setMassivelyAssign({ users: userIds, data: massivelyAssign, brands, general }));
        clearModal();
    };

    const clearModal = () => {
        setMassivelyAssign({
            brands: [],
            lines: [],
            menus: []
        });
        setFamily(null);
        setBrands([]);
        setTmpBrands([]);
    };

    const columns: any = [
        { title: t("name"), field: 'name' },
        {
            title: t("admin.people.linked"), field: 'type',
            render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8}>
                    <CustomSwitchComponent
                        color={(massivelyAssignData['brands'] !== undefined && massivelyAssignData['brands'][rowData.id] < userIds.length) ? "secondary" : "primary"}
                        checked={(rowData.brand_id === undefined) ? massivelyAssign.brands.some((brand: BrandVip) => brand.brand_id === rowData.id) : massivelyAssign.lines.some((line: LineVip) => line.line_id === rowData.id)}
                        onChange={(rowData.brand_id === undefined) ? handleAssignBrand(rowData) : handleAssignLine(rowData)}
                    />
                </Box>
            )
        },
        {
            title: t("admin.people.vip"), field: 'type', hidden: !(massivelyAssign.brands.length > 0 || massivelyAssign.lines.length),
            render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8}>
                    {((rowData.brand_id === undefined) ? massivelyAssign.brands.some((brand: BrandVip) => brand.brand_id === rowData.id) : massivelyAssign.lines.some((line: LineVip) => line.line_id === rowData.id)) &&
                        <CustomSwitchComponent
                            // color={(massivelyAssignData['brands'] !== undefined && massivelyAssignData['brands'][rowData.id] < userIds.length) ? "secondary" : "primary"}
                            checked={(rowData.brand_id === undefined) ? massivelyAssign.brands.some((brand: BrandVip) => brand.brand_id === rowData.id && brand.status === 1) : massivelyAssign.lines.some((line: LineVip) => line.line_id === rowData.id && line.status === 1)}
                            onChange={(rowData.brand_id === undefined) ? handleAssignBrandVip(rowData) : handleAssignLineVip(rowData)}
                        />
                    }
                </Box>
            )
        },
    ];

    const menuColumns: any = [
        { title: t("name"), field: 'name' },
        {
            title: t("admin.people.linked"), field: 'type',
            render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8}>
                    <CustomSwitchComponent
                        // color={(massivelyAssignData['menus'][rowData.id] !== undefined && massivelyAssignData['menus'][rowData.id] < userIds.length) ? "secondary" : "primary" }
                        checked={massivelyAssign.menus.includes(rowData.id)}
                        onChange={handleMenuChange(rowData)}
                    />
                </Box>
            )
        },
    ];

    const handleSubmitMassAssignment = (data: any) => {
        dispatch(storeMassAssignment(data))
    }

    return (
        <>
            {![4, 6, 8, 9].includes(authUser.role_id) &&
                <Button id="btn-massively-assign" variant="outlined" color="secondary" onClick={() => toggleOpen(!open)}>
                    {t("admin.people.massively-assign")}
                </Button>
            }

            {massivelyAssignData !== null &&
                <Dialog
                    open={open}
                    fullWidth={true}
                    fullScreen={isMobile && true}
                    maxWidth={!isMobile && "md"}
                    className={formClasses.containerForm}
                    onClose={() => toggleOpen(!open)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t("admin.people.massively-assign")}
                            </Typography>
                            <Button onClick={() => toggleOpen(!open)}
                                color="primary" variant="text"><CloseRounded /></Button>
                        </Grid>
                    </DialogTitle>

                    <DialogContent className={formClasses.bodyForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Tabs
                                    value={currentTab}
                                    indicatorColor="secondary"
                                    textColor="primary"
                                    onChange={handleChangeTab}
                                    className={formClasses.tapForm}
                                    aria-label="disabled tabs example">
                                    <Tab label={t("general")} {...a11yProps(0)} />
                                    <Tab label={t("brand")} {...a11yProps(1)} />
                                    <Tab label={t("section")} {...a11yProps(2)} />
                                </Tabs>
                            </Grid>
                        </Grid>

                        <TabPanel value={currentTab} index={0}>
                            <MassAssigmentBody
                                data={data}
                                setData={setData}
                                addedInputs={addedInputs}
                                buttons={buttons}
                                addInput={addInput}
                                removeInput={removeInput}
                                handleCheckChange={handleCheckChange}
                            />
                        </TabPanel>

                        <TabPanel value={currentTab} index={1}>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="combo-box-families"
                                        value={family}
                                        onChange={(event: any, value: any) => setFamily(value)}
                                        options={userFamilies}
                                        getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={t("family")}
                                                variant="filled"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="combo-box-brands"
                                        value={userBrands.filter((brand: any) => brands.includes(brand.id))}
                                        onChange={handleMultiAutoCompleteChange}
                                        options={(family !== null) ? userBrands.filter((brand: any) => brand.family_id === family.id) : userBrands}
                                        getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                        multiple
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={t("brands")}
                                                variant="filled"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>

                            {(brands.length > 0) &&
                                <Box mt={2}>
                                    <Typography variant="body2" color="textSecondary">Asignación de marcas y líneas</Typography>
                                    <div className="table_custom white">
                                        <MaterialTable
                                            localization={localization}
                                            title={t("brands")}
                                            columns={columns}
                                            data={tmpBrands}
                                            // dropdowns in table
                                            parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.brand_id)}
                                            options={{ ...options, search: false, exportButton: false }}
                                            icons={tableIcons} />
                                    </div>
                                </Box>
                            }

                        </TabPanel>

                        <TabPanel value={currentTab} index={2}>

                            <Box mt={2}>
                                <div className="table_custom white">
                                    <MaterialTable
                                        localization={localization}
                                        title="Menu"
                                        columns={menuColumns}
                                        data={authUser.menus}
                                        options={{ ...options, search: false, exportButton: false }}
                                        icons={tableIcons} />
                                </div>
                            </Box>

                        </TabPanel>

                    </DialogContent>
                    <DialogActions>

                        <CancelButton onClick={() => toggleOpen(!open)} aria-label="close">
                            {t("cancel")}
                        </CancelButton>

                        <SaveButton autoFocus id="btn-save-user"
                            onClick={() => handleSubmit()}>
                            {t("save")}
                        </SaveButton>

                    </DialogActions>

                </Dialog>
            }
        </>
    );
}

export default UserMassivelyAssign;
