// React
import { useSelector } from 'react-redux'

// Material UI
import { Container, Button, Box, Typography } from '@material-ui/core';

// Custom components
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import ContentFilter from '../../../components/layouts/filters/ContentFilter';
import EventCalendar from '../../../components/events/EventCalendar';
import ButtonBack from '../../../components/common/ButtonBack';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import { NavigateBeforeRounded } from '@material-ui/icons';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

function EventList() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Filter sidebar function
    const { globalFamilyId, globalBrandId } = useSelector((state: IRootState) => state.global);
    return (

        <>
            <Header title={t("events")} />
            <SubHeader />
            <ContentFilter showOrderBy={false} goBackAction={true} />

            <Container maxWidth="lg">
                <Box mb={3} />
                {isMobile &&
                    <Box pb={3}>
                        <Typography variant='h6' align='center'>{t("events")}</Typography>
                    </Box>
                }
                <EventCalendar fullHeight={true} family_id={globalFamilyId} brand_id={globalBrandId} />
            </Container>
        </>
    );
}

export default EventList;