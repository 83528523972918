// React
import { useDispatch } from 'react-redux'

// Material UI
import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, Typography
} from '@material-ui/core';
import { CloseRounded, Delete } from '@material-ui/icons';

// Redux Actions
import { deleteSeminar } from '../../redux/actions/seminars'

//Custom Componenta
import { CancelButton, DeleteButton } from '../common/forms';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import FormStyles from '../../styles/components/common/forms/FormsStyles';

// Types
type SeminarDeleteModalProps = {
    open: boolean,
    setOpen: (state: boolean) => void,
    seminar_id: number | null
}

function SeminarDeleteModal({ open, setOpen, seminar_id }: SeminarDeleteModalProps) {

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Redux Hooks
    const dispatch = useDispatch();

    // styles
    const formClasses = FormStyles();

    return (
        <Dialog
            open={open}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "sm"}
            className={formClasses.containerForm}
            onClose={() => setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {t("delete")} {t("seminar")}
                    </Typography>
                    <Button onClick={() => setOpen(!open)}
                        color="primary" variant="text"><CloseRounded /></Button>
                </Grid>
            </DialogTitle>
            <DialogContent className={formClasses.bodyForm}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {t("admin.seminars.delete-seminar-text")}
                    </Grid>
                </Grid>
                <Typography >
                </Typography>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => setOpen(!open)} >
                    {t("cancel")}
                </CancelButton>
                <DeleteButton autoFocus id="btn-handleldelete"
                    endIcon={<Delete />}
                    onClick={() => { dispatch(deleteSeminar(seminar_id)); setOpen(!open) }}>
                    {t("delete")}
                </DeleteButton>
            </DialogActions>
        </Dialog>
    );
}

export default SeminarDeleteModal;