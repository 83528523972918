// React
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import { Box, Typography } from '@material-ui/core';

// Redux Actions
import { getInternalBanner } from '../../../redux/actions/sliders';
import { IRootState } from '../../../redux/types';

// Custom components
import SliderMoreInfo from '../../sliders/SliderMoreInfo';
import ResetButton from './ResetButton';

// Libraries
import { Carousel } from 'react-responsive-carousel';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Custom Style
import HeaderStyles from '../../../styles/components/common/header/HeaderStyles';
import ReportButton from './ReportButton';

// Types
interface InternalSliderProps {
    detail?: boolean,
    isHidden?: boolean
}

export default function InternalSlider({ detail, isHidden }: InternalSliderProps) {
    // Custom Hook
    const isTable = useScreenWidth(960);
    // Styles
    const classes = HeaderStyles();

    //Location
    const location = useLocation();

    // Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { internalbanner } = useSelector((state: IRootState) => state.slider);
    const { globalCountryId, globalLanguageId } = useSelector((state: IRootState) => state.global);
    const { commitFront } = useSelector((state: IRootState) => state.log);

    // States
    const [open, setOpen] = useState(false);
    const [sliderSelected, setSliderSelected] = useState({ id: null });

    useEffect(() => {
        if (authUser !== null) {
            dispatch(getInternalBanner({
                country_id: globalCountryId,
                language_id: globalLanguageId,
                internaltype: 3
            }));
        }
    }, [globalCountryId, globalLanguageId]);

    /* Functions */
    const handleClickItem = (slider: any) => {
        if (slider.type === 0) {
            window.open(slider.value)
        } else if (slider.type == 1) {
            setOpen(!open);
            setSliderSelected(slider);
        }
    }

    let isAdmin = location.pathname.includes('admin') || (location.pathname === '/escritorio' || location.pathname === '/usuarios/perfil');

    return (
        <Box>
            {(isAdmin)
                ?
                <Box className={classes.internalBannerAdmin}>
                    <Box p={2} display="flex" alignItems="center">
                        <Typography variant='caption' color='textSecondary'>{commitFront}</Typography>
                        <ResetButton admin />
                        <ReportButton admin />
                    </Box>
                </Box>
                :
                <>
                    {!internalbanner.length ?
                        <Box className={classes.internalBannerNull} display={(detail === true) ? 'none' : (isHidden === true && window.innerWidth < 959) ? 'none' : 'block'} />
                        :
                        <Carousel autoPlay showThumbs={false} interval={7000} infiniteLoop>
                            {internalbanner.map((banner: any) => (
                                <Box key={banner.id} onClick={() => handleClickItem(banner)}>
                                    <img className={classes.internalBanner} src={(banner !== null) ? `${banner.image}?${new Date().getTime()}` : ''} alt={banner.title} />
                                </Box>
                            ))}
                        </Carousel>
                    }
                </>
            }


            {sliderSelected.id !== null &&
                <SliderMoreInfo slider={sliderSelected} open={open} setOpen={setOpen} />
            }


        </Box>
    );
}
