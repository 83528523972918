// MATERIAL UI
import {
	Button,
	Box,
	Typography,
	Paper
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
//Assets
import logo from '../../assets/Images/logos/logodark.svg';
import backgroundImage from '../../assets/Images/errorBoundaryImage.jpg'
// Styles
import ErrorBoundaryStyles from '../../styles/components/ErrorBoundaryStyles';


export default function ErrorBoundary() {
	const classes = ErrorBoundaryStyles();

	// Translate
	const { t } = useTranslation(["global"]);

	return (
		<Box className={classes.container}>
			<Box className={classes.logoContainer}>
				<img
					src={logo}
					height={'35px'}
					alt="logo"
				/>
			</Box>
			<Box className={classes.surfaceImage} style={{ backgroundImage: `url(${backgroundImage})` }} />
			<Box className={classes.surfaceGradient} />
			<Paper elevation={0} className={classes.surfacePaper}>
				<Box className={classes.messageContainer}>
					<Box className={classes.dividerGradient} />
					<Typography className={classes.messagePaper} align='center'>
						{t("common.error-message-boundary")}
					</Typography>
					<Box className={classes.dividerGradient} />
				</Box>
				<Button href="/inicio"
					id="btn-back-to-home"
					variant="contained"
					fullWidth
					className={classes.buttonBack}
				>
					{t("go-to-home")}
				</Button>
			</Paper>
		</Box>
	);
}