import { useState, useEffect, FC } from 'react'
import { ColumnDef, Row, flexRender, getCoreRowModel, useReactTable, TableState } from '@tanstack/react-table'
import { IconButton } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons/";
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

type CommonReactTableProps<TData> = {
    columns: ColumnDef<TData>[],
    data: TData[],
    state?: Partial<TableState>,
    rowId?: keyof TData,
}

export type OrderById = {
    id: string,
    order: number
}

type DraggableReactTableProps<TData> = CommonReactTableProps<TData> & {
    draggableRow?: false,
    onDraggableRowChange?: never
    getDataOrdered?: never,
    orderField?: never
} | {
    draggableRow: true,
    onDraggableRowChange?: (draggedRowIndex: number, targetRowIndex: number) => void,
    getDataOrdered?: (data: TData[]) => void,
    orderField?: keyof TData | 'order'
}


type ReactTableProps<TData> = CommonReactTableProps<TData> & DraggableReactTableProps<TData>

const BasicRow: FC<{
    row: Row<any>
}> = ({ row }) => {
    return (
        <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    )
}

const DraggableRow: FC<{
    row: Row<any>
    reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void
}> = ({ row, reorderRow }) => {
    const [, dropRef] = useDrop({
        accept: 'row',
        drop: (draggedRow: Row<any>) => reorderRow(draggedRow.index, row.index),
    })

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: 'row',
    })

    return (
        <tr
            key={row.id}
            ref={previewRef} //previewRef could go here
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <td ref={dropRef}>
                <IconButton ref={dragRef}><DragIndicator fontSize='small' /></IconButton>
            </td>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    )
}

const ReactTable = <T extends object>({ columns, data, rowId, state, draggableRow = false, onDraggableRowChange, orderField = 'order', getDataOrdered }: ReactTableProps<T>): JSX.Element => {

    const [dataTable, setDataTable] = useState(data);

    const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
        dataTable.splice(targetRowIndex, 0, dataTable.splice(draggedRowIndex, 1)[0] as T);
        if (draggableRow) {
            if (onDraggableRowChange) { onDraggableRowChange(draggedRowIndex, targetRowIndex) };
            if (getDataOrdered) { getDataOrdered(dataTable) };
        }
        setDataTable([...dataTable]);
    }

    const table = useReactTable({
        data: dataTable,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row: any) => row[rowId], //good to have guaranteed unique row ids/keys for rendering
        state
    });

    useEffect(() => {
        setDataTable(data);
    }, [data]);

    return (
        <DndProvider backend={HTML5Backend}>
            <table className='tableBrands_custom'>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {draggableRow &&
                                <th />
                            }
                            {headerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        if (draggableRow)
                            return <DraggableRow key={row.id} row={row} reorderRow={reorderRow} />

                        return <BasicRow key={row.id} row={row} />
                    })}
                </tbody>
                <tfoot>
                    {table.getFooterGroups().map(footerGroup => (
                        <tr key={footerGroup.id}>
                            {footerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.footer,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </DndProvider>
    )
}

export default ReactTable