import { IFormValidatorState, SUCCESS_FORM_VALIDATOR, RESET_FORM_VALIDATOR } from '../types';
    
const initialState: IFormValidatorState = {
    errorForm: [],
    errorTab: [],
    msgForm: [],
    msgTab: [],
    isValidForm: false
}
    
// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case RESET_FORM_VALIDATOR:
            return{
                ...state,
                errorForm: [],
                errorTab: [],
                msgForm: [],
                msgTab: [],
                isValidForm: false
            }
        case SUCCESS_FORM_VALIDATOR:
            return{
                ...state,
                errorTab: action.payload.errorTab,
                errorForm: action.payload.errorForm,
                msgForm: action.payload.msgForm,
                msgTab: action.payload.msgTab,
                isValidForm: action.payload.isValidForm
            }
        default:
            return state;
    }
}