import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_EVENTS, SUCCESS_GET_EVENTS, ERROR_GET_EVENTS,
    START_GET_FILTERED_EVENTS, SUCCESS_GET_FILTERED_EVENTS, ERROR_GET_FILTERED_EVENTS,
    START_GET_EVENTS_BY_BRAND, SUCCESS_GET_EVENTS_BY_BRAND, ERROR_GET_EVENTS_BY_BRAND,
    START_GET_CALENDAR_EVENTS, SUCCESS_GET_CALENDAR_EVENTS, ERROR_GET_CALENDAR_EVENTS,
    START_CREATE_EVENT, SUCCESS_CREATE_EVENT, ERROR_CREATE_EVENT,
    START_SHOW_EVENT, SUCCESS_SHOW_EVENT, ERROR_SHOW_EVENT,
    START_DELETE_EVENT, SUCCESS_DELETE_EVENT, ERROR_DELETE_EVENT,
    START_TOGGLE_FORM_EVENT, SUCCESS_TOGGLE_FORM_EVENT
} from '../types';
import { apiCall } from '../config/axios';

function* getEvent() {
    try {
        const response = yield call(apiCall, 'get', '/events/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_EVENTS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_EVENTS, payload: error.message })
    }
}

function* getFilteredEvents({payload}) {
    try {
        const response = yield call(apiCall, 'post', '/events/filteredevents', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_FILTERED_EVENTS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_FILTERED_EVENTS, payload: error.message })
    }
}

function* getEventsByBrand({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/events/indexbybrand`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_EVENTS_BY_BRAND, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_EVENTS_BY_BRAND, payload: error.message })
    }
}

function* getCalendarEvents({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/events/calendar`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_CALENDAR_EVENTS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_CALENDAR_EVENTS, payload: error.message })
    }
}

function* createEvent({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/events/store', payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CREATE_EVENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CREATE_EVENT, payload: error.message })
    }
}

function* showEvent({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/events/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_EVENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_EVENT, payload: error.message })
    }
}

function* deleteEvent({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/events/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_EVENT, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_EVENT, payload: error.message })
    }
}

function* toggleFormEvent({ payload }) {
    yield put({ type: SUCCESS_TOGGLE_FORM_EVENT, payload })
}

export default function* events() {
    yield takeLatest(START_GET_EVENTS, getEvent);
    yield takeLatest(START_GET_FILTERED_EVENTS, getFilteredEvents);
    yield takeLatest(START_GET_EVENTS_BY_BRAND, getEventsByBrand);
    yield takeLatest(START_GET_CALENDAR_EVENTS, getCalendarEvents);
    yield takeLatest(START_CREATE_EVENT, createEvent);
    yield takeLatest(START_SHOW_EVENT, showEvent);
    yield takeLatest(START_DELETE_EVENT, deleteEvent);
    yield takeLatest(START_TOGGLE_FORM_EVENT, toggleFormEvent);
}