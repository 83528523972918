import {
    START_GET_COURSES,
    START_GET_FILTERED_COURSES,
    START_GET_COURSES_BY_BRAND,
    START_GET_HOME_COURSES,
    START_STORE_COURSE,
    START_SHOW_COURSE,
    START_SHOW_COURSE_BY_SLUG,
    START_DELETE_COURSE,
    START_COURSE_LESSON_STEP,
    START_FINISH_COURSE,
    START_TOGGLE_FORM_COURSE,
    START_ASSIGN_TAG_COURSE,
    START_DELETE_TAG_COURSE,
    START_ASSIGN_HIGHLIGHT_COURSE, 
    START_MASS_ASSIGNMENT_COURSES
} from '../types';

export const getCourses = () => ({
    type: START_GET_COURSES
})

export const getFilteredCourses = payload => ({
    type: START_GET_FILTERED_COURSES,
    payload
})

export const getCoursesByBrand = payload => ({
    type: START_GET_COURSES_BY_BRAND,
    payload
})

export const getHomeCourses = payload => ({
    type: START_GET_HOME_COURSES,
    payload
})

export const createCourse = payload => ({
    type: START_STORE_COURSE,
    payload
})

export const showCourse = payload => ({
    type: START_SHOW_COURSE,
    payload
})

export const showCourseBySlug = payload => ({
    type: START_SHOW_COURSE_BY_SLUG,
    payload
})

export const deleteCourse = payload => ({
    type: START_DELETE_COURSE,
    payload
})

export const courseLessonStep = payload => ({
    type: START_COURSE_LESSON_STEP,
    payload
})

export const finishCourse = payload => ({
    type: START_FINISH_COURSE,
    payload
})

export const toggleFormCourse = (payload = false) => ({
    type: START_TOGGLE_FORM_COURSE,
    payload
})

export const assignTagCourse = payload => ({
    type: START_ASSIGN_TAG_COURSE,
    payload
})

export const deleteTagCourse = payload => ({
    type: START_DELETE_TAG_COURSE,
    payload
})

export const assignHighlight = payload => ({
    type: START_ASSIGN_HIGHLIGHT_COURSE,
    payload
})

export const storeMassAssignment = payload => ({
    type: START_MASS_ASSIGNMENT_COURSES,
    payload
})