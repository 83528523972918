import { makeStyles, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => createStyles({
    // DRAWER
    settingsBtn:{
        color: theme.palette.grey[200],
    },
    cleanBtn:{
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
        textTransform: 'initial',
        textDecoration: 'underline',
        '&:hover':{
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            backgroundColor: theme.palette.grey[50],
        },
        [theme.breakpoints.down('md')]:{
    margin: '8px 0px',
}
    },
    tapBtn:{
        minWidth: '110px',
        minHeight: 'fit-content',
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 500,
        padding: '8px 15px 9px',
        lineHeight: '21px',
        textTransform: 'capitalize',
    },
    contentItem:{
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    list:{
        padding: 0,
        maxHeight: 376,
        overflow: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '8px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        [theme.breakpoints.down('md')]:{
            overflow: 'hidden',
            maxHeight: '100%',
        }
    },
    badgeItem:{
        width: 'fit-content',
    },
    dotItem:{
        right: '-8px',
        top: '6px',
    },
    iconItem:{
        color: theme.palette.grey[200],
    },
    drawerPaper: {
        width: 340,
        paddingTop: '5px',
        border: 'none',
        maxHeight: '-webkit-fill-available',
        height: '-webkit-fill-available',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '6px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        '&.admin': {
            background: theme.palette.grey[900],
            borderRadius: '0px 4px 0px 0px',
            [theme.breakpoints.up('md')]: {
                marginTop: '170px'
            },
        },
        '&.client': {
            background: theme.palette.grey[50],
            [theme.breakpoints.up('md')]: {
                marginTop: '189px'
            },
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16,
            width: 'calc(100% - 64px)',
        },
    },
}));