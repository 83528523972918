import React, { useEffect, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

// Material UI
import {
    Grid, Typography, Tab, Tabs,
    Button, Card, Container, Box, withStyles, Menu, MenuItem, List, ListItem,
    ListItemIcon, ListItemText, Fade, Tooltip, Dialog, DialogTitle,
    DialogContent, TextField, FormControl, InputLabel, Select, Divider, Avatar, IconButton
} from '@material-ui/core';
import { AddBox, AddRounded, CloseRounded, CreateRounded } from '@material-ui/icons/';
import VerifiedIcon from '../../assets/vector/VerifiedCustomIcon.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

// Custom components
import Header from '../common/header/Header';
import SubHeader from '../common/header/Subheader';
import ScreenLoading from '../common/ScreenLoading';
import CustomSnackBar from '../common/admin/CustomSnackBar';
import TabPanel from '../common/admin/TabPanel';
import CustomChip from '../common/admin/CustomChip';
import UserInfo from './UserInfo';
import UserLinked from './UserLinked';
import UserNotifications from './UserNotifications';
import UserBrands from './UserBrands';
import UserHistory from './UserHistory';
import UserRegistrations from './UserRegistrations';
import DisableUser from './DisableUser';
import EnableUser from './EnableUser';
import ButtonBack from '../common/ButtonBack';
import HiddenMobile from '../common/HiddenMobile';
import HiddenDesktop from '../common/HiddenDesktop';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Redux Actions
import { showProfileUser, getNotDependents, showDependents, settinguserHierarchy, showUser } from '../../redux/actions/users';
import { getRoles } from '../../redux/actions/roles';
import { getCountries, getLocations } from '../../redux/actions/countries';
import { getLanguages } from '../../redux/actions/languages';
import { IRootState } from '../../redux/types';

// Translate
import { useTranslation } from "react-i18next";

//Styles
import UsersStyle from '../../styles/components/common/UsersStyle';
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Types
interface ParamTypes {
    id: string
}

//Custom Tabs
interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
const ContainerTab = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        marginTop: 24,
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
            marginTop: 0,
        }
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 8,
        '& > span': {
            backgroundColor: theme.palette.primary.main,
            height: 8,
            maxWidth: 100,
            borderRadius: '8px 8px 0px 0px',
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: 4,
            '& > span': {
                height: 4,
                borderRadius: '4px 4px 0px 0px',
            },
        }
    },
}))((props: StyledTabsProps) => <Tabs variant={useScreenWidth(700) ? 'scrollable' : 'fullWidth'} scrollButtons={useScreenWidth(700) ? 'on' : 'auto'} {...props} TabIndicatorProps={{ children: <span /> }} />);

function UserProfile() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const classes = UsersStyle();
    const formClasses = FormsStyles();
    // table menu function
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(-1)
    //Tabs
    const [currentTab, setCurrentTab] = React.useState(0);
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };
    //Redux Hooks
    const dispatch = useDispatch();
    const { id } = useParams<ParamTypes>();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userProfileSelected, error, msg, loading, reload } = useSelector((state: IRootState) => state.user);
    const { countries } = useSelector((state: IRootState) => state.country);
    const { languages } = useSelector((state: IRootState) => state.language);
    const { roles, rolehierarchy } = useSelector((state: IRootState) => state.role);
    const [openLinked, setOpenLinked] = useState(false);
    const [roleLinked, setRoleLinked] = useState<number | null>(null);
    const [userHierarchies, setUserHierarchies] = useState([]);

    const client_types = [t("admin.people.esthetic-professional"), t("admin.people.final-audience"), t("admin.people.esthetic-academy-student"), t("admin.people.esthetic-academy-professor")];

    const handleClose = () => {
        setOpenLinked(false);
    };

    useEffect(() => {
        if (!roles.length)
            dispatch(getRoles());

        if (!countries.length)
            dispatch(getCountries());

        if (!languages.length)
            dispatch(getLanguages());

        dispatch(getLocations());
    }, []);

    useEffect(() => {
        if (authUser !== null) {
            let userId: number = 0;
            if (id != null)
                userId = parseInt(id);
            else if (id == null)
                userId = authUser.id;

            if (userProfileSelected?.id !== userId)
                dispatch(showProfileUser(userId));
        }
    }, [authUser]);

    useEffect(() => {
        if (reload && !error) {
            let userId = null;
            if (authUser != null && id != null)
                userId = id;
            else if (authUser != null && id == null)
                userId = authUser.id;

            dispatch(showProfileUser(userId));
            dispatch(showDependents(userId));
            dispatch(getNotDependents(userId));
        }
    }, [reload]);

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const menuLinked = (id: number) => (event: MouseEvent<HTMLDivElement>) => {
        setAnchorOp(event.currentTarget);
        setOpenOptions(id);
    };

    const settingUserHierarchy = (action: number) => (event: any, value: any) => {
        dispatch(settinguserHierarchy({
            user_id: userProfileSelected.id,
            parent_id: value.id,
            action
        }));
        setRoleLinked(null);
        handleClose();
        handleCloseOptions();
    };


    const handleClickEdit = () => {
        if (authUser != null && id != null)
            dispatch(showUser(id));
        else if (authUser != null && id == null)
            dispatch(showUser(authUser.id));
    };

    let parentsId: number[] = [];
    let flag: boolean = true;
    let userName =
        authUser !== null ? authUser.name.split(" ") : process.env.REACT_APP_NAME;
    let countWords = userName.length;
    let textAvatar =
        countWords <= 1
            ? `${userName[0].charAt(0).toLocaleUpperCase()}`
            : userName[0] === "(C)" || "(Cc)"
                ? `${userName[0].charAt(0).toLocaleUpperCase()}${userName[1]
                    .charAt(0)
                    .toLocaleUpperCase()}`
                : `${userName[1].charAt(0).toLocaleUpperCase()}${userName[2]
                    .charAt(0)
                    .toLocaleUpperCase()}`;

    return (
        <>
            <Header title="Perfil" />
            <SubHeader className='profile' />

            {authUser !== null && userProfileSelected !== null &&
                <Container maxWidth='lg'>
                    <HiddenDesktop>
                        <Box className={classes.heroContainer}>
                            <ButtonBack />
                        </Box>
                        <Box>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'} pb={1}>
                                {/* Avatar user */}
                                <Box style={{ position: 'relative' }}>
                                    <Avatar className={classes.avatarMobile}>{textAvatar}</Avatar>
                                    <Box className={classes.statusAvatar}>
                                        {(userProfileSelected.status === 1) ?
                                            <CustomChip size='medium' type={1} color='primary' />
                                            :
                                            <CustomChip size='medium' type={1} color='secondary' />
                                        }
                                    </Box>
                                </Box>
                                <Button onClick={handleClickEdit} variant="contained" className={classes.buttonEdit} startIcon={<CreateRounded />}>
                                    {t("edit")}
                                </Button>
                            </Box>
                            {/* User name & verify icon */}
                            <Box display='flex' gridGap={4} alignItems={'center'}>
                                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>{userProfileSelected.name}</Typography>
                                {userProfileSelected.verification === 1 ?
                                    <object data={VerifiedIcon} aria-label={t("admin.people.verified")} className={classes.iconVerified} />
                                    :
                                    <object data={VerifiedIcon} aria-label={t("admin.people.unverified")} className={classes.iconUnverified} />}
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                {/* Inner information, ID, Rol, Status */}
                                <Box display='inline' alignContent={'center'} height={'fit-content'}>
                                    <Typography variant="body2" color="textSecondary"><b>ID:</b> {userProfileSelected.id}</Typography>
                                    <Typography variant="body2" color="textSecondary"><b>{t("rol")}:</b> {userProfileSelected.roleName}</Typography>
                                </Box>
                                <Box display={'inline'} textAlign={'end'}>
                                    <Typography variant="body2" color="textSecondary">{t('admin.people.last-login')}</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {userProfileSelected.last_login_at === null ? "Sin datos" : (moment(userProfileSelected.last_login_at).format('l'))}
                                    </Typography>
                                </Box>
                            </Box>

                            {[4, 6, 8, 9].includes(userProfileSelected.role_id) &&
                                <Box py={2}>
                                    {[4, 8, 9].includes(userProfileSelected.role_id) &&
                                        <Typography variant="body2" style={{ fontWeight: 400 }}>
                                            Código comercial: <b>{userProfileSelected.commercial_code}</b>
                                        </Typography>
                                    }
                                    {userProfileSelected.role_id === 6 && userProfileSelected.center != null &&
                                        <Typography variant='body2' style={{ fontWeight: 400 }}>
                                            Centro: {userProfileSelected.center.name}
                                        </Typography>
                                    }
                                </Box>}
                        </Box>
                    </HiddenDesktop>
                    <Card className={classes.cardContainer}>
                        <Grid container justify="space-between" alignItems="flex-start" spacing={isMobile ? 2 : 8}>
                            <Grid item xs={12} md={9}>
                                <HiddenMobile>
                                    <Box display="flex" alignItems="center" gridGap={16} justifyContent='space-between'>
                                        <Box display="flex" alignItems="center" gridGap={16} >
                                            <Typography variant="h5" className={classes.title}>{userProfileSelected.name}</Typography>

                                            {userProfileSelected.verification === 1 ?
                                                <object data={VerifiedIcon} aria-label={t("admin.people.verified")} className={classes.iconVerified} />
                                                :
                                                <object data={VerifiedIcon} aria-label={t("admin.people.unverified")} className={classes.iconUnverified} />}

                                            {(userProfileSelected.status === 1) ?
                                                <CustomChip type={1} status={0} />
                                                :
                                                <CustomChip type={1} status={1} />
                                            }
                                        </Box>
                                        <Button fullWidth={window.innerWidth < 600 && true} onClick={handleClickEdit} variant="contained" color="primary" startIcon={<CreateRounded />}>
                                            {t("edit")}
                                        </Button>
                                    </Box>

                                    <Box display="flex" gridGap={10} alignItems={'center'}>
                                        <Typography variant="subtitle1" color="textSecondary">{userProfileSelected.roleName}</Typography>
                                        <Typography variant="subtitle1" color="textSecondary" style={{ fontWeight: 400 }}>|</Typography>
                                        <Typography variant="subtitle1" color="textSecondary" style={{ fontWeight: 400 }}>ID:{userProfileSelected.id}</Typography>
                                        <Box display="flex" width='100%' justifyContent='space-between' alignItems='center' gridGap={16} flexWrap={window.innerWidth < 600 ? 'wrap' : 'no-wrap'}>
                                            <Box display="flex" gridGap={10}>
                                                {client_types[userProfileSelected.client_type] &&
                                                    <Typography variant="body2" color="textSecondary">| {t('admin.people.client-type')}: {client_types[userProfileSelected.client_type]}</Typography>
                                                }
                                                <Typography variant="body2" color="textSecondary">
                                                    | {t('admin.people.last-login') + ': '}
                                                    {userProfileSelected.last_login_at === null ? "Sin datos" : (moment(userProfileSelected.last_login_at).format('lll'))}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                    {[4, 8, 9].includes(userProfileSelected.role_id) &&
                                        <Box pb={1}>
                                            <Typography variant="subtitle1" style={{ fontWeight: "lighter", marginBottom: '16px' }}>
                                                Código comercial: <b>{userProfileSelected.commercial_code}</b>
                                            </Typography>
                                        </Box>
                                    }


                                    {userProfileSelected.role_id === 6 && userProfileSelected.center != null &&
                                        <Typography variant="subtitle1" style={{ fontWeight: "lighter", marginBottom: '16px' }}>Centro: {userProfileSelected.center.name}</Typography>
                                    }
                                </HiddenMobile>


                            </Grid>

                            <Grid item xs={12} md={3}>
                                {(userProfileSelected.parents.length > 0) &&
                                    <Box>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={isMobile ? '' : 'space-between'}>
                                            <Typography style={{ fontWeight: 500, textTransform: 'capitalize' }}>{`${t("admin.people.associate-superior")}`}</Typography>
                                            {[1, 2, 3].includes(authUser.role_id) &&
                                                isMobile ?
                                                <Button
                                                    onClick={() => { setOpenLinked(!openLinked) }}
                                                    variant='outlined'
                                                    size='small'
                                                    color='inherit'
                                                    startIcon={<AddBox htmlColor={'#8DDD55'} />}
                                                    style={{ marginLeft: 8 }}>
                                                    {`${t("add")}`}
                                                </Button>
                                                :
                                                <Tooltip title={`${t("admin.people.assign-superior")}`}>
                                                    <IconButton onClick={() => { setOpenLinked(!openLinked) }}>
                                                        <AddBox htmlColor={'#8DDD55'} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </Box>
                                        <List dense component="nav" aria-label="linked users" className={classes.LinkedList}>
                                            {userProfileSelected.parents.map((userhierarchy: any) => (
                                                <>
                                                    {(userhierarchy.role_id !== 1 && userhierarchy.role_id !== 2) &&
                                                        <>
                                                            <ListItem button aria-controls="simple-menu" divider key={userhierarchy.id}
                                                                aria-haspopup="false" onClick={menuLinked(userhierarchy.id)}>
                                                                <ListItemText primary={
                                                                    <Tooltip title={userhierarchy.role.name} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                                                                        <Typography variant="body2" align="center">
                                                                            {userhierarchy.name}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                } />
                                                                {[1, 2, 3].includes(authUser.role_id) &&
                                                                    <Menu
                                                                        id="simple-linked-menu"
                                                                        anchorEl={anchorOp}
                                                                        getContentAnchorEl={null}
                                                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                                        open={userhierarchy.id === openOptions}
                                                                        onClick={handleCloseOptions}
                                                                        onClose={handleCloseOptions}>
                                                                        <MenuItem onClick={() => settingUserHierarchy(0)(null, userhierarchy)}>{t("admin.people.unlink")}</MenuItem>
                                                                    </Menu>
                                                                }
                                                            </ListItem>
                                                            {userhierarchy.parents.map((parent: any) => {
                                                                if (!parentsId.includes(parent.id)) {
                                                                    parentsId.push(parent.id);
                                                                    flag = true;
                                                                } else {
                                                                    flag = false;
                                                                }

                                                                return (
                                                                    <>
                                                                        {(parent.role_id !== 1 && parent.role_id !== 2 && flag) &&
                                                                            <ListItem button aria-controls="simple-menu" divider key={parent.id}
                                                                                aria-haspopup="true" 
                                                                                onClick={menuLinked(parent.id)}>
                                                                                <ListItemText primary={
                                                                                    <Tooltip title={parent.role.name} TransitionComponent={Fade} 
                                                                                    TransitionProps={{ timeout: 600 }}>
                                                                                        <Typography variant="body2" align="center">
                                                                                            {`> ${parent.name}`}
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                } />
                                                                                {[1, 2, 3].includes(authUser.role_id) &&
                                                                                    <Menu
                                                                                        id="simple-linked-menu"
                                                                                        anchorEl={anchorOp}
                                                                                        getContentAnchorEl={null}
                                                                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                                                        open={parent.id === openOptions}
                                                                                        onClick={handleCloseOptions}
                                                                                        onClose={handleCloseOptions}>
                                                                                        <MenuItem onClick={() => settingUserHierarchy(0)(null, parent)}>{t("admin.people.unlink")}</MenuItem>
                                                                                    </Menu>
                                                                                }
                                                                            </ListItem>
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                </>
                                            ))}
                                        </List>
                                    </Box>

                                }
                            </Grid>

                        </Grid>

                        <ContainerTab
                            value={currentTab}
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example">

                            <Tab label={t("general")} {...a11yProps(0)} />
                            <Tab label={t("admin.people.dependents")} {...a11yProps(1)} style={{ display: (userProfileSelected.role_id !== 6 ? '' : 'none') }} />
                            <Tab label={t("admin.people.notifications")} {...a11yProps(2)} style={{ display: 'none' }} />
                            <Tab label={t("brands")} {...a11yProps(3)} />
                            <Tab label={t("rows")} {...a11yProps(4)} style={{ display: ([1, 2, 3].includes(authUser.role_id) ? '' : 'none') }} />
                            <Tab label={t("inscriptions")} {...a11yProps(5)} />
                        </ContainerTab>


                        <TabPanel value={currentTab} index={0}>
                            <UserInfo user_id={id} />
                            <EnableUser authUser={authUser} userProfileSelected={userProfileSelected} />
                            <DisableUser authUser={authUser} userProfileSelected={userProfileSelected} />
                        </TabPanel>

                        <TabPanel value={currentTab} index={1}>
                            <UserLinked user_id={id} />
                        </TabPanel>

                        <TabPanel value={currentTab} index={2}>
                            <UserNotifications />
                        </TabPanel>

                        <TabPanel value={currentTab} index={3}>
                            <UserBrands />
                        </TabPanel>

                        <TabPanel value={currentTab} index={4}>
                            <UserHistory user_id={id} />
                        </TabPanel>

                        <TabPanel value={currentTab} index={5}>
                            <UserRegistrations user_id={id} />
                        </TabPanel>

                    </Card>



                    <Dialog open={openLinked}
                        fullWidth={true}
                        fullScreen={isMobile && true}
                        maxWidth={!isMobile && "sm"}
                        className={formClasses.containerForm}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">

                        <DialogTitle id="alert-dialog-title">
                            <Grid container justify="space-between">
                                <Typography variant="h6">
                                    {`${t('admin.people.assign-superior')}`}
                                </Typography>
                                <Button onClick={handleClose}
                                    color="primary" variant="text"><CloseRounded />
                                </Button>
                            </Grid>
                        </DialogTitle>

                        <DialogContent className={formClasses.bodyForm}>
                            <form noValidate autoComplete="nope">
                                <Grid container alignItems="flex-start" justify="space-between" spacing={2} >

                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="filled">
                                            <InputLabel shrink={true} id="lbl-roles">{t("rol")}</InputLabel>
                                            <Select
                                                required
                                                value={roleLinked}
                                                labelId="lbl-roles"
                                                id="combo-box-roles"
                                                fullWidth
                                                label="role"
                                                onChange={(event: any) => {
                                                    setRoleLinked(event.target.value);
                                                    setUserHierarchies(authUser.sons.filter((userhierarchy: any) => {
                                                        if (event.target.value === userhierarchy.role_id)
                                                            return userhierarchy;
                                                    }));
                                                }}
                                            >
                                                {roles.filter((role: any) => rolehierarchy.includes(role.id) && role.id !== 4).map((role: any) => {
                                                    return (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Autocomplete
                                            id="combo-box-usershierarchies"
                                            value={null}
                                            onChange={settingUserHierarchy(1)}
                                            options={userHierarchies}
                                            fullWidth
                                            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                            renderInput={(params) => <TextField {...params} label={t("users")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                        />
                                    </Grid>

                                </Grid>
                            </form>
                        </DialogContent>
                    </Dialog>
                </Container>
            }
            <ScreenLoading loadings={[loading]} />
            <CustomSnackBar errors={[error]} msgs={[msg]} />
        </>
    );
}

export default UserProfile;