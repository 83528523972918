// React
import { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

// Material UI
import { Container, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Menu, MenuItem, IconButton, Grid, Typography, Tooltip, Fade } from '@material-ui/core';
import { Delete, MoreVert, InfoOutlined, CloseRounded } from '@material-ui/icons';
import MaterialTable from 'material-table'
import { localization, options, tableIcons } from '../../../config/table';

// Redux Actions
import { getNovelties, showNovelty, assignHighlight, deleteNovelty, toggleFormNovelty } from '../../../redux/actions/novelties';
import { getTags } from '../../../redux/actions/tags';
import { IRootState } from '../../../redux/types';

// Commons components
import UpdatesModal from "../../../components/novelties/NoveltyModal";
import CustomSnackBar from '../../../components/common/admin/CustomSnackBar';
import CustomChip from '../../../components/common/admin/CustomChip';
import ScreenLoading from '../../../components/common/ScreenLoading';
import Header from '../../../components/common/header/Header';
import SubHeader from '../../../components/common/header/Subheader';
import CountriesFilter from '../../../components/common/admin/CountryFilter';
import HighlightIcon from '../../../components/common/admin/HighlightIcon';
import VisibilityIcon from '../../../components/common/admin/VisibilityIcon';
import AdminContentFilter from '../../../components/layouts/filters/AdminContentFilter';
import TagInput from '../../../components/common/admin/TagInput';
import NotFilter from '../../NotFilter';
import { CancelButton, DeleteButton } from '../../../components/common/forms';

// Libraries
import moment from 'moment';
import { useTranslation } from "react-i18next";
import 'react-dropzone-uploader/dist/styles.css'

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Styles
import TableScreenStyles from '../../../styles/components/common/admin/TableScreenStyles';
import FormsStyles from '../../../styles/components/common/forms/FormsStyles';

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

function NoveltyAdmin({ history }: RouteComponentProps) {
    // Translation
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // styles
    const tableclass = TableScreenStyles();
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { novelties, reload, showForm, error, msg, loading } = useSelector((state: IRootState) => state.novelty);
    const { tags, error: errorTag, msg: msgTag } = useSelector((state: IRootState) => state.tag);
    const { globalFamilyId, globalBrandId, globalCountriesId, globalLanguageId } = useSelector((state: IRootState) => state.global);

    // Component States
    const [openDeleteNovelty, setOpenDeleteNovelty] = useState(false);
    const [noveltySelected, setNoveltySelected] = useState(false);

    // Variables Filter by
    const status = [{ id: 0, name: 'Activo' }, { id: 1, name: 'Inactivo' }, { id: 2, name: 'Borrador' }];
    const visibility = [{ id: 0, name: t('close') }, { id: 1, name: 'VIP' }];
    const optionsData = [{ data: status, code: 'status', column: 'status' }, { data: visibility, code: 'visibility', column: 'visibility' }, { data: tags, code: 'categories', column: 'tag_id' }];

    //Tabs
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(-1);

    // Ready Page
    useEffect(() => {
        if (!tags.length)
            dispatch(getTags());
    }, []);

    useEffect(() => {
        if (globalLanguageId !== null && globalCountriesId.length > 0) {
            dispatch(getNovelties({
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                language_id: globalLanguageId,
                countries: globalCountriesId,
                requesttype: 0,
                type: 'novelties'
            }));
        }
    }, [globalFamilyId, globalBrandId, globalLanguageId, globalCountriesId]);

    useEffect(() => {
        if (reload) {
            dispatch(getNovelties({
                family_id: globalFamilyId,
                brand_id: globalBrandId,
                language_id: globalLanguageId,
                countries: globalCountriesId,
                requesttype: 0,
                type: 'novelties'
            }));
        }
    }, [reload]);

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(-1);
    };

    const handleClickHighlight = (id: number, isHighlight: boolean) => {
        dispatch(assignHighlight({
            id,
            isHighlight
        }));
    };

    const handleClickSetOptions = (options: any) => {
        dispatch(getNovelties({
            brand_id: globalBrandId,
            language_id: globalLanguageId,
            countries: globalCountriesId,
            requesttype: 0,
            type: 'novelties',
            filterBy: options
        }));
    };

    const columns: any = [
        {
            title: t("title"), field: "title", render: (rowData: any) => {
                return (
                    <Box display="flex" alignItems="center" gridGap={8}>
                        {(() => {
                            switch (rowData.status) {
                                case 0:
                                    return (<CustomChip size="small" color={'primary'} />)
                                case 1:
                                    return (<CustomChip size="small" color={'secondary'} />)
                                case 2:
                                    return (<CustomChip size="small" color={'grey'} />)

                                default:
                                    return (<CustomChip size="small" color={'grey'} />)
                            }
                        })()}
                        <Tooltip placement="bottom-start" TransitionComponent={Fade}
                            TransitionProps={{ timeout: 400 }} title={rowData.title}>
                            <Typography className={tableclass.nameResource}>
                                {rowData.title}
                            </Typography>
                        </Tooltip>

                        <HighlightIcon id={rowData.id} isHighlight={(rowData.highlight) ? true : false} handleClick={handleClickHighlight} />
                        <VisibilityIcon id={rowData.visibility} />
                    </Box>)
            }
        },
        {
            title: '', field: 'tags', hidden: isMobile, render: (rowData: any) =>
                <TagInput
                    rowId={rowData.id}
                    rowTags={rowData.tags}
                    rowCountries={rowData.countries}
                    rowBrandId={rowData.brand_id}
                    page={'novelties'}
                />
        },
        { title: 'Id', field: 'id', hidden: isMobile },
        { title: t("brand"), field: 'brand.name', hidden: (globalBrandId !== null) },
        {
            title: t("language"), field: "language", render: (rowData: any) => (
                rowData.languages.map((language: any) => { return language.code.toUpperCase() }).join(' - ')
            ), hidden: isMobile, type: string
        },
        { title: t("roles"), field: 'role.name', type: string, hidden: isMobile },
        { title: t("date"), field: '', hidden: isMobile, render: (rowData: any) => (`${moment(rowData.created_at).format('YYYY - MMM - DD')}`) },
        {
            title: '', field: '', render: (rowData: any) =>
                <>

                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setAnchorOp(event.currentTarget);
                            setOpenOptions(rowData.id);
                        }}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorOp}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        open={rowData.id === openOptions}
                        onClick={handleCloseOptions}
                        onClose={handleCloseOptions}>
                        <MenuItem onClick={() => history.push(`novedades/${rowData.slug}`)}>{t("view")}</MenuItem>
                        <MenuItem onClick={() => { dispatch(showNovelty({ id: rowData.id, type: 'noveltySelected' })); dispatch(toggleFormNovelty({ type: "noveltySelected", change: false })) }}>{t("edit")}</MenuItem>
                        <MenuItem onClick={() => { setOpenDeleteNovelty(!openDeleteNovelty); setNoveltySelected(rowData.id) }}>{t("delete")}</MenuItem>
                    </Menu>
                </>
        }
    ];

    return (
        <>
            {showForm && (
                <UpdatesModal />
            )}
            <Header title={t('updates')} />
            <SubHeader />

            <Container maxWidth="lg">
                <Box style={{ display: showForm ? "none" : "block" }}>
                    <Box mt={isMobile ? 2 : 6} gridGap={16} display="flex" flexDirection={isMobile ? 'column-reverse' : 'column'}>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="h5" align="center">{t("all")}</Typography>
                            <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} arrow
                                title={
                                    <Box>
                                        <Typography variant="body2">{t('status')}:</Typography>
                                        <Box display="flex" gridGap={8} alignItems="center">
                                            <CustomChip size="small" color={'primary'} />
                                            <Typography variant="caption">{t('active')}</Typography>
                                        </Box>
                                        <Box display="flex" gridGap={8} alignItems="center">
                                            <CustomChip size="small" color={'secondary'} />
                                            <Typography variant="caption">{t('inactive')}</Typography>
                                        </Box>
                                        <Box display="flex" gridGap={8} alignItems="center">
                                            <CustomChip size="small" color={'grey'} />
                                            <Typography variant="caption">{t('draft')}</Typography>
                                        </Box>
                                    </Box>
                                }>
                                <IconButton>
                                    <InfoOutlined htmlColor={'#8F99A3'} fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box >
                            <Grid container justify="space-between" spacing={1}>
                                <Grid item xs={5} sm={6} className={tableclass.actionsResponsive}>
                                    <Button variant="outlined" color="secondary"
                                        onClick={() => dispatch(toggleFormNovelty({ type: "noveltySelected", change: true }))}>
                                        {t('create') + ' ' + t('update')}
                                    </Button>
                                </Grid>
                                <Grid item xs={7} sm={6} className={tableclass.actionsResponsiveRight}>
                                    <AdminContentFilter data={optionsData} handleClick={handleClickSetOptions} />
                                    <CountriesFilter />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    {!novelties.length && !loading ? <NotFilter subsection={null} handleClearFilters={handleClickSetOptions} /> :
                        <Box mt={3}>
                            <div className="table_custom">
                                <MaterialTable
                                    localization={localization}
                                    title={t("novelties")}
                                    columns={columns}
                                    data={novelties}
                                    options={options}
                                    icons={tableIcons} />
                            </div>
                        </Box>}
                </Box>

                <Dialog
                    open={openDeleteNovelty}
                    fullWidth={true}
                    fullScreen={isMobile && true}
                    maxWidth={!isMobile && "sm"}
                    className={formClasses.containerForm}
                    onClose={() => setOpenDeleteNovelty(!openDeleteNovelty)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t("delete")} {t("document")}
                            </Typography>
                            <Button onClick={() => setOpenDeleteNovelty(!openDeleteNovelty)}
                                color="primary" variant="text"><CloseRounded /></Button>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={formClasses.bodyForm}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} >
                                <Typography >
                                    {t("admin.novelties.delete-novelty-text")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton onClick={() => setOpenDeleteNovelty(!openDeleteNovelty)} >
                            {t("cancel")}
                        </CancelButton>
                        <DeleteButton autoFocus id="btn-handleldelete"
                            endIcon={<Delete />}
                            onClick={() => { dispatch(deleteNovelty(noveltySelected)); setOpenDeleteNovelty(!openDeleteNovelty) }}>
                            {t("delete")}
                        </DeleteButton>
                    </DialogActions>
                </Dialog>

                <CustomSnackBar errors={[error, errorTag]} msgs={[msg, msgTag]} />
                <ScreenLoading loadings={[loading]} />
            </Container>
        </>
    );
}

export default NoveltyAdmin;