export function clearStorage(){
    localStorage.removeItem('isAuth');
    localStorage.removeItem('globalBrandId');
    localStorage.removeItem('globalLineId');
    localStorage.removeItem('globalCountryId');
    localStorage.removeItem('globalCountriesId');
    localStorage.removeItem('globalRoleId');
    localStorage.removeItem('globalOrderById');
    localStorage.removeItem('statusInstallApp');
    localStorage.removeItem('globalTag');
    localStorage.removeItem('globalLanguageCode');
    localStorage.removeItem('globalLanguageId');
    localStorage.removeItem('userId');
    localStorage.removeItem('login_redirect');

    window.location.reload();
}