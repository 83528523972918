import { put, call, takeLatest } from 'redux-saga/effects';
import { START_GET_FAMILIES, SUCCESS_GET_FAMILIES, ERROR_GET_FAMILIES } from '../types';
import { apiCall } from '../config/axios';

function* getFamilies() {
    try{
        const response = yield call(apiCall, 'get', '/families')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_FAMILIES, payload: response.data})
    } catch (error){
        yield put({ type: ERROR_GET_FAMILIES, payload: error.message})
    }
}

export default function* families() {
    yield takeLatest(START_GET_FAMILIES, getFamilies);
}