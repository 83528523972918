import { forwardRef } from 'react';

import i18n from "i18next";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Save from '@material-ui/icons/Save';
import GetApp from '@material-ui/icons/GetApp';
import Visibility from '@material-ui/icons/Visibility';
import Cached from '@material-ui/icons/Cached';
import Schedule from '@material-ui/icons/Schedule';
import MoreVert from '@material-ui/icons/MoreVert';
import mainTheme from "../styles/components/themes/ClientTheme";
import Assignment from '@material-ui/icons/Assignment';


export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox color="primary" fontSize="small" {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check color="primary" fontSize="small"  {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear color="primary" fontSize="small"  {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline color="primary" fontSize="small" {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit color="primary" fontSize="small"  {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt color="primary" fontSize="small"  {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList color="primary" fontSize="small" {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage color="primary" fontSize="small" {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage color="primary" fontSize="small"  {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight color="primary" fontSize="small" {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft color="primary" fontSize="small" {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear color="primary" fontSize="small" {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search color="primary" fontSize="small" {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward color="primary" fontSize="small"  {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove color="primary" fontSize="small" {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn color="primary" fontSize="small"  {...props} ref={ref} />),
    Save: forwardRef((props, ref) => <Save color="primary" fontSize="small"  {...props} ref={ref} />),
    GetApp: forwardRef((props, ref) => <GetApp color="primary" fontSize="small"  {...props} ref={ref} />),
    Visibility: forwardRef((props, ref) => <Visibility color="primary" fontSize="small"  {...props} ref={ref} />),
    Cached: forwardRef((props, ref) => <Cached color="primary" fontSize="small"  {...props} ref={ref} />),
    Schedule: forwardRef((props, ref) => <Schedule color="primary" fontSize="small"  {...props} ref={ref} />),
    MoreVert: forwardRef((props, ref) => <MoreVert color="primary" fontSize="small"  {...props} ref={ref} />),
    Assignment: forwardRef((props, ref) => <Assignment color="primary" fontSize="small"  {...props} ref={ref} />),
};

export const localization = {
    pagination: {
        labelDisplayedRows: '{from}-{to} '+i18n.t('global:of')+' {count}',
        labelRowsSelect: i18n.t('global:rows'),
        labelRowsPerPage: i18n.t('global:rows-per-page'),
        firstAriaLabel: i18n.t('global:first-page'),
        firstTooltip: i18n.t('global:first-page'),
        previousAriaLabel: i18n.t('global:previous-page'),
        previousTooltip: i18n.t('global:previous-page'),
        nextAriaLabel: i18n.t('global:next-page'),
        nextTooltip: i18n.t('global:next-page'),
        lastAriaLabel: i18n.t('global:last-page'),
        lastTooltip: i18n.t('global:last-page'),
    },
    toolbar: {
        nRowsSelected: '{0} '+ i18n.t('global:rows-selected'),
        exportTitle: i18n.t('global:export'),
        exportAriaLabel: i18n.t('global:export'),
        exportName: i18n.t('global:export-csv'),
        searchTooltip: i18n.t('global:search'),
        searchPlaceholder: i18n.t('global:search'),
    },
    header: {
        actions: i18n.t('global:actions'),
    },
    body: {
        emptyDataSourceMessage: i18n.t('global:no-records-found'),
        filterRow: {
            filterTooltip: i18n.t('global:filter')
        }
    }
}

export const options = {
    exportButton: true,
    sorting: true,
    showTitle: false,
    actionsColumnIndex: -1,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
    headerStyle: {
        backgroundColor: `${mainTheme.palette.grey[50]}`,
        color: `${mainTheme.palette.grey[400]}`,
    },
    rowStyle: {
        fontFamily: 'Popins, sans-serif',
        backgroundColor: `${mainTheme.palette.grey[50]}`,
        color: `${mainTheme.palette.grey[700]}`,
        fontWeight: 300,
    },
    searchFieldStyle: {
        background: `${mainTheme.palette.grey[100]}`,
        padding: '0px 8px',
        borderRadius: '4px',
        margin:' 16px 8px',
        height: '32px',
        width: '240px',
        '&:before': {
            border: 'none'
        }
    },
}

export const optionsSelect = {
    exportButton: false,
    sorting: false,
    showTitle: false,
    selection: true,
    actionsColumnIndex: -1,
    pageSize: 5,
    pageSizeOptions: [5, 10],
    headerStyle: {
        
        color: `${mainTheme.palette.grey[400]}`,
    },
    rowStyle: {
        fontFamily: 'Popins, sans-serif',
        
        color: `${mainTheme.palette.grey[700]}`,
        fontWeight: 300,
    },
    searchFieldStyle: {
        background: `${mainTheme.palette.grey[100]}`,
        padding: '0px 8px',
        borderRadius: '4px',
        margin:' 16px 8px',
        height: '32px',
        width: '240px',
        '&:before': {
            border: 'none'
        }
    },
}
