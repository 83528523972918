// React
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, Redirect } from "react-router-dom";

// Material UI
import {
    Button, FormControl, Grid, ThemeProvider, IconButton, FormHelperText, InputAdornment, Box, Typography, Input
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// Commons components
import CustomSnackBar from '../../components/common/admin/CustomSnackBar';
import ScreenLoading from '../../components/common/ScreenLoading';
// Assets
import logo from '../../assets/Images/logos/logodark.svg';

// Redux Actions
import { formValidator, resetFormValidator } from '../../redux/actions/validator'
import { resetPassword } from '../../redux/actions/auth';
import { IRootState } from "../../redux/types"

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import NavLayoutTheme from '../../styles/components/themes/NavLayoutTheme';
import ResourceStyle from '../../styles/pages/LoginRegisterStyle';

// Types
interface ParamTypes {
    token: string
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AssignPassword() {
    // Translate
    const { t } = useTranslation(["global"]);

    // Styles
    const classes = ResourceStyle();

    // URL Params
    let query = useQuery();
    let { token } = useParams<ParamTypes>();
    let emailQuery = query.get("email");

    // Redux Hooks
    const dispatch = useDispatch();
    const { isValidForm, errorForm, msgForm } = useSelector((state: IRootState) => state.validator);
    const { isAuth, loading, msg, error } = useSelector((state: IRootState) => state.auth);

    // User State
    const [user, setUser] = useState({
        email: '',
        verificationCode: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        showForm: false,
        setPassword: true
    });

    // Ready page
    React.useEffect(() => {
        if (token !== undefined && emailQuery != null) {
            setUser({ ...user, showForm: true, email: emailQuery, verificationCode: token });
        }
    }, []);

    // Send Form
    React.useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator());
            dispatch(resetPassword({ user }));
        }
    }, [isValidForm]);

    // Toggle Show Password
    const handleClickShowPassword = () => {
        setUser({ ...user, showPassword: !user.showPassword });
    };

    // Input State Form
    const handleChange = (inputName: string) => (event: any) => {
        setUser({ ...user, [inputName]: event.target.value });
    };

    // Validate password Form
    const handleSubmitPassword = () => {
        dispatch(formValidator({
            email: [user.email, 'required|max:45|min:3'],
            verificationCode: [user.verificationCode, 'required|max:10|min:3'],
            password: [user.password, 'required|max:45|min:3'],
            confirmPassword: [user.confirmPassword, 'required|max:45|min:3|equal:' + user.password]
        }));
    }

    if (isAuth) {
        return <Redirect to="/terms" />
    }

    return (
        <>
            <Grid container className={classes.container}>
                <Grid className={classes.form}>
                    <Box mb={5} display="flex" flexDirection="column" alignItems="flex-start" gridGap={32}>
                        <img src={logo} width="86.37" alt="logo" />
                    </Box>
                    <Box mb={5}>
                        <Typography color="textSecondary">
                            {t("login.assing-password-text")}
                        </Typography>
                    </Box>

                    <form noValidate autoComplete="off">
                        <FormControl variant="filled" fullWidth>
                            <Box mb={0.5}>
                                <Typography>{t("login.new-password")}</Typography>
                            </Box>
                            <Input
                                id="password1"
                                required
                                placeholder={t("login.new-password")}
                                type={user.showPassword ? 'text' : 'password'}
                                value={user.password}
                                error={errorForm.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {user.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error id="outlined-helper-text">{msgForm.password}</FormHelperText>
                        </FormControl>

                        <FormControl variant="filled" fullWidth>
                            <Box mb={0.5}>
                                <Typography>{t("login.confirm-password")}</Typography>
                            </Box>
                            <Input
                                id="password2"
                                required
                                placeholder={t("login.confirm-password")}
                                type={user.showPassword ? 'text' : 'password'}
                                value={user.confirmPassword}
                                error={errorForm.confirmPassword}
                                onChange={handleChange('confirmPassword')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {user.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error id="outlined-helper-text">{msgForm.confirmPassword}</FormHelperText>
                        </FormControl>

                        <Box mt={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => handleSubmitPassword()}>
                                {t("login.login")}
                            </Button>
                        </Box>
                    </form>
                </Grid>



            </Grid>
            <CustomSnackBar errors={[error]} msgs={[msg]} />
            <ScreenLoading loadings={[loading]} />
        </>
    )
}

export default AssignPassword;