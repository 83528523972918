// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from "react-router-dom";


// Material UI
import { Grid, Box, Container } from '@material-ui/core';

// Custom components
import ResourceModal from '../../components/library/ResourceModal';
import ButtonBack from '../../components/common/ButtonBack';
import ShareIcon from '../../components/common/ShareIcon';
import TypeFileView from '../../components/common/client/TypeFileView';
import VerifyAccessRoute from '../../components/common/VerifyAccessRoute';
import FeaturedDetail from '../../components/common/client/FeaturedDetail';
import DetailHeroPanel from '../../components/layouts/common/DetailHeroPanel';
import DetailInfoPanel from '../../components/layouts/common/DetailInfoPanel';
import CustomSnackBar from '../../components/common/admin/CustomSnackBar';
import useScreenWidth from '../../hooks/useScreenWidth';

// Custom Hooks
import useUserDisplaytime from '../../hooks/useUserDisplaytime';
import useGeneralSummaryReport from "../../hooks/useGeneralSummaryReport";

// Redux Actions
import { IRootState } from '../../redux/types';
import { setFavorite, unsetFavorite } from '../../redux/actions/favorites';
import { viewResource, toggleFormEditResources } from '../../redux/actions/resources';
import { getResourcetypes } from '../../redux/actions/resourcetypes'
import { getResourcecategories } from '../../redux/actions/resourcecategories';

// Libraries
import { useTranslation } from "react-i18next";
import AdminDetailInfo from '../../components/common/admin/AdminDetailInfo';

// Types
interface ParamTypes {
    id: string
}

function ResourceDetail() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Redux Hooks
    const dispatch = useDispatch();
    const { id } = useParams<ParamTypes>();
    const { resourceSelected, showEditForm } = useSelector((state: IRootState) => state.resource);
    const { userBrands } = useSelector((state: IRootState) => state.brand);
    const { globalFamilyId, globalBrandId, globalLanguageId, globalCountriesId } = useSelector((state: IRootState) => state.global);
    const { resourcetypes } = useSelector((state: IRootState) => state.resourcetype);
    const [favoriteState, setFavoriteState] = useState<boolean>(false);

    const location = useLocation();

    const [msgCopy, setMsgCopy] = useState('');

    // Send track of user
    useUserDisplaytime({ foreign: resourceSelected });
    const { handleViewGeneralSummaryReport } = useGeneralSummaryReport([], 0, 2);

    // Ready Page
    useEffect(() => {
        dispatch(viewResource(id));
    }, [id]);

    useEffect(() => {
        if (resourceSelected !== null && resourceSelected.view && resourceSelected.id == id) {
            handleViewGeneralSummaryReport(resourceSelected.id)
            setFavoriteState(Boolean(resourceSelected.favoriteId));
        }
    }, [resourceSelected]);

    useEffect(() => {
        if (globalLanguageId !== null) {
            if (!resourcetypes.length)
                dispatch(getResourcetypes());

            if (!resourcetypes.length)
                dispatch(getResourcecategories({ languageId: globalLanguageId, countriesId: globalCountriesId, familyId: globalFamilyId, brandId: globalBrandId }));
        }
    }, [globalLanguageId]);

    /* Functions */
    const handleDownload = (resource_id: number) => {
        const URL = process.env.REACT_APP_API_URL + '/resources/download/';
        window.open(URL + resource_id);
    }

    const handleCopyText = () => {
        if (resourceSelected.description === null || resourceSelected.description === "")
            return null;

        navigator.clipboard.writeText(resourceSelected.description || '');
        setMsgCopy(t('text-copied-success'));
        setTimeout(() => { setMsgCopy('') }, 5000);
    }

    const handleFavoriteClick = (resource_id: number) => (state: boolean) => {
        if (state) {
            dispatch(setFavorite({
                id: resource_id,
                type: 'resource'
            }));
        } else {
            dispatch(unsetFavorite({
                id: resource_id,
                type: 'resource',
            }));
        }
        setFavoriteState(state);
    };

    let infoDetail = (resourceSelected !== null && resourceSelected.view) && {
        "item": [
            {
                "id": 0,
                "type": "tag",
                "title": "Tags",
                "color": "light",
                "info": `${resourceSelected.tags.map((tag: any) => { return tag.name.toUpperCase() }).join(', ')}`,
            },
            {
                "id": 1,
                "type": "file",
                "title": `${t("admin.library.file-type")}`,
                "color": "blue",
                "info": `${resourceSelected.ext.toUpperCase()}`,
            },
            {
                "id": 2,
                "type": "size",
                "title": `${t("admin.library.file-size")}`,
                "color": "orange",
                "info": `${(resourceSelected.filesize / 1000 / 1000).toFixed(2) + "MB"}`,
            },
            {
                "id": 3,
                "type": "date",
                "title": `${t("admin.people.created-date")}`,
                "color": "grey6",
                "info": resourceSelected.upload_date,
            },
        ]
    }

    return (
        <div>
            {(resourceSelected !== null && resourceSelected.view) &&
                <Container maxWidth="xl">

                    {showEditForm && <ResourceModal />}

                    <ButtonBack />

                    <Grid container spacing={5} >

                        <Grid item xs={12} md={8}>
                            <VerifyAccessRoute visibility={resourceSelected.visibility} />

                            {location.pathname.includes('admin') &&
                                <AdminDetailInfo resourceSelected={resourceSelected} />
                            }

                            <Box mt={isMobile ? 1 : 3}>
                                <TypeFileView
                                    file={resourceSelected}
                                    type={'resources'}
                                />
                            </Box>
                            {resourceSelected.resourcetype_id === 4 && userBrands !== null && userBrands.find((brand: any) => brand.id === resourceSelected.brand_id)?.highlight === 1 &&
                                <FeaturedDetail />
                            }

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DetailHeroPanel
                                visibility={resourceSelected.visibility}
                                brand={resourceSelected.brand !== null && resourceSelected.brand.name}
                                title={resourceSelected.alias}
                                author={null}
                                description={resourceSelected.description}
                                // Actions
                                edit={() => { dispatch(toggleFormEditResources()) }}
                                download={() => handleDownload(resourceSelected.id)}
                                hasDescription={!(resourceSelected.description === null || resourceSelected.description === "")}
                                copyDescription={() => { handleCopyText() }}
                                onClickFavorite={handleFavoriteClick(resourceSelected.id)}
                                favoriteState={favoriteState}
                                share={<ShareIcon
                                    meta={true}
                                    visibility={resourceSelected.visibility}
                                    title={resourceSelected.alias || resourceSelected.file_name}
                                    description={resourceSelected.description || ''}
                                    link={window.location.href}
                                    multimedia={`${process.env.REACT_APP_AWS_URL}/${'resources'}/${resourceSelected.cover === null ? resourceSelected.file_name : resourceSelected.cover}`}
                                    multimediaType={resourceSelected.ext === 'mp4' ? 'video' : 'image'}
                                    type="resource"
                                    component="buttonTextGreen"
                                    buttonTextGreen={t("share")}
                                />}
                            />
                            <DetailInfoPanel attributes={infoDetail} />
                        </Grid>

                    </Grid>

                    <CustomSnackBar errors={[false]} msgs={[msgCopy]} />

                </Container>
            }
        </div>
    );
}

export default ResourceDetail;
