// React
import { useState } from 'react'

// Export function that return 2 values -> boolean and function to change the boolean value
export default function useBooleanState(initState: boolean = false): [boolean, (state: boolean) => void] {

    const [state, setState] = useState<boolean>(initState);

    const changeState = (state: boolean) => {
        setState(state);
    }

    return [state, changeState];
}