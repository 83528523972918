import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    mobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    buttonBrand: {
        cursor: "pointer",
        display: "flex",
        alignItems: 'center',
        color: theme.palette.grey[50],
        padding: '0px 3px 0px 10px',
        height: 50,
        '&.admin':{
            color: theme.palette.grey[50],
            "&:hover p": {
                color: theme.palette.grey[50],
            },
        },
        '&.client':{
            color: theme.palette.grey[400],
			"&:hover p": {
				color: theme.palette.grey[400],
			},
        },
        "&:hover": {
            color: theme.palette.grey[200],
        },
  
    },

    buttonBrandText: {
        whiteSpace: 'nowrap',
        maxWidth: 96,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    drawerPaper: {
        width: 240,
        paddingTop: '5px',
        border: 'none',
        maxHeight: '-webkit-fill-available',
        height: '-webkit-fill-available',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '6px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
        '&.admin': {
            background: theme.palette.grey[900],
            borderRadius: '0px 4px 0px 0px',
            [theme.breakpoints.up('md')]: {
                marginTop: '170px'
            },
        },
        '&.client': {
            background: theme.palette.grey[50],
            [theme.breakpoints.up('md')]: {
                marginTop: '189px'
            },
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 16,
            width: 'calc(100% - 64px)',
        },
    },

    divider:{
        '&.admin':{
            backgroundColor: '#FAFAFA'
        },
        '&.client':{
            color: theme.palette.grey[400]
        }
    },
    menuIcon:{
        margin: 0,
        "&.client svg":{
            color: theme.palette.grey[400],
            fontSize: '24px !important',
        },
        "&.admin svg":{
            color: theme.palette.grey[300],
            fontSize: '24px !important',
        }
    },

    menuItem:{
        '&.client':{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 8,
            paddingBottom: 10, 
        },
        '&.admin':{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
            minWidth: 80,
            minHeight: 80,
            padding: '0px !important',
            backgroundColor: theme.palette.grey[800],
            color: theme.palette.grey[300],
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
        }
    },
    menuWidthLess:{
        minWidth: '25%',
        maxHeight: 71,
        padding: 0,
        border: '0px !important',
        
    },

    optionItem: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        minWidth: 80,
        minHeight: 80,
        padding: '0px !important',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.grey[300],
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
    },
    
    btnCloseDrawer:{
        position: 'fixed',
        right: 8,
        top: 8,
        backgroundColor: theme.palette.common.white,
    },
}));