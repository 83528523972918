import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import { Grid, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

// Custom components
import OfferBlock from '../offers/OfferBlock';
import NotFilter from '../../pages/NotFilter';

// Redux Actions
import { IRootState } from '../../redux/types';

// Libraries
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Custom Style
const offerStyles = makeStyles((theme) => createStyles({
    containercarousel: {
        position: "relative",
        [theme.breakpoints.up('md')]: {
            '& .carousel .slider': {
                width: '67%',
            },
        }
    },
    containerCard: {
        lineHeight: 0,
        margin: 0,
        position: "relative",
        "& h5, h6, p": {
            color: "#FAFAFA",
            textShadow: "0 1px 1px #222",
        },
    },
}));

interface OffersPanelProps {
    userCounterViewsOffer: any,
    subsection: string
}

function OffersPanel({ userCounterViewsOffer, subsection }: OffersPanelProps) {
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = offerStyles();

    // Redux Hooks
    const dispatch = useDispatch();
    const { offers, loading } = useSelector((state: IRootState) => state.offer);
    const [counted, setCounted] = useState<number[]>([]);

    /* Functions */
    const counterViews = (offerId: number) => {
        if (!counted.includes(offerId)) {
            dispatch(userCounterViewsOffer(offerId));
            let tmpCounted = [...counted];
            tmpCounted.push(offerId);
            setCounted(tmpCounted);
        }
    }

    return (
        <>
            <Grid item xs={12} >
                {offers.filter((offer: any) => offer.highlight === 1).length >= 2 && !loading &&
                    <Carousel centerMode={isMobile ? false : true} autoPlay showThumbs={false} showArrows={isMobile ? false : true} interval={5000} infiniteLoop className={classes.containercarousel}>
                        {offers.filter((offer: any) => offer.highlight === 1).map((offer: any) => (
                            <Box className={classes.containerCard} onMouseOver={() => counterViews(offer.id)} key={offer.id}>
                                <OfferBlock type={0} offer={offer} />
                            </Box>
                        ))}
                    </Carousel>
                }
            </Grid>

            <Grid container spacing={isMobile ? 1 : 3} style={isMobile ? { marginTop: 16 } : { marginTop: 24 }}>
                {!offers.filter((offer: any) => (offer.highlight === 0 || offer.highlight === null)).length && !loading ?
                    <NotFilter subsection={subsection} />
                    :
                    offers.filter((offer: any) => (offer.highlight === 0 || offer.highlight === null)).map((offer: any) => (
                        <Grid item xs={6} md={4} onMouseOver={() => counterViews(offer.id)} key={offer.id}>
                            <OfferBlock type={1} offer={offer} />
                        </Grid>
                    ))
                }
            </Grid>
        </>
    );
}

export default OffersPanel;