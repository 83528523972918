import { 
    ITestimonialState,
    START_GET_TESTIMONIALS, SUCCESS_GET_TESTIMONIALS, ERROR_GET_TESTIMONIALS,
    START_SHOW_TESTIMONIAL, SUCCESS_SHOW_TESTIMONIAL, ERROR_SHOW_TESTIMONIAL,
    START_STORE_TESTIMONIAL, SUCCESS_STORE_TESTIMONIAL, ERROR_STORE_TESTIMONIAL,
    START_UPDATE_TESTIMONIAL, SUCCESS_UPDATE_TESTIMONIAL, ERROR_UPDATE_TESTIMONIAL,
    START_DELETE_TESTIMONIAL, SUCCESS_DELETE_TESTIMONIAL, ERROR_DELETE_TESTIMONIAL,
    SUCCESS_TOGGLE_FORM_TESTIMONIALS
 } from '../types';
    
const initialState: ITestimonialState = {
    testimonials: [],
    testimonialSelected: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_TESTIMONIALS:
        case START_SHOW_TESTIMONIAL:
        case START_STORE_TESTIMONIAL:
        case START_UPDATE_TESTIMONIAL:
        case START_DELETE_TESTIMONIAL:
            return{
                ...state,
                msg: null,
                error: false,
                loading: true
            }
        case SUCCESS_GET_TESTIMONIALS:
            return{
                ...state,
                testimonials: action.payload.testimonials,
                msg: action.payload.msg,
                error: false,
                loading: false
            }
        case SUCCESS_STORE_TESTIMONIAL:
            return{
                ...state,
                testimonials: [...state.testimonials, action.payload.testimonial],
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false,
            }
        case SUCCESS_UPDATE_TESTIMONIAL:
            return{
                ...state,
                testimonials: state.testimonials.map((testimonial: any) => 
                    testimonial.id === action.payload.testimonial.id ? action.payload.testimonial : testimonial 
                ),
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false,
            }
        case SUCCESS_SHOW_TESTIMONIAL:
            return{
                ...state,
                testimonialSelected: action.payload.testimonial,
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: true
            }
        case SUCCESS_DELETE_TESTIMONIAL:
            return{
                ...state,
                testimonials: state.testimonials.filter((testimonial: any) => testimonial.id != action.payload.testimonial_id),
                msg: action.payload.msg,
                error: false,
                loading: false,
                showForm: false,
            }
        case SUCCESS_TOGGLE_FORM_TESTIMONIALS:
            return{
                ...state,
                showForm: !state.showForm,
            }
        case ERROR_GET_TESTIMONIALS:
        case ERROR_SHOW_TESTIMONIAL:
        case ERROR_STORE_TESTIMONIAL:
        case ERROR_UPDATE_TESTIMONIAL:
        case ERROR_DELETE_TESTIMONIAL:
            return{
                ...state,
                reload: false,
                msg: action.payload,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}