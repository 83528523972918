// React
import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, DialogActions, Dialog, DialogTitle, DialogContent,
    Grid, TextField, Typography, FormHelperText, Tabs, Tab,
    FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox,
    Radio, RadioGroup
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CloseRounded } from '@material-ui/icons/';
import { DateTimePicker } from '@material-ui/pickers';

// Custom components
import { SaveButton, CancelButton, DatePicker } from '../common/forms';
import TabPanel from '../common/admin/TabPanel';
import TinyRichEditor from '../common/admin/TinyRichEditor';

// Redux Actions
import { createSeminarLesson, toggleFormSeminarLesson } from '../../redux/actions/seminarlessons';
import { getNovelties } from '../../redux/actions/novelties';
import { formValidator, resetFormValidator } from '../../redux/actions/validator';
import { IRootState } from '../../redux/types';


// Libraries
import Dropzone, { ILayoutProps } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { useTranslation } from "react-i18next";
import moment, { Moment } from 'moment';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Types
interface ISeminarLesson {
    id: null | number,
    novelties: number[],
    fileNames: null | any,
    seminar_id: number,
    title: null | string,
    content: string,
    extracontent: null | string,
    type: number,
    order: null | number,
    duration: null | number,
    datetime: string | null,
    notifications: any
}

type FormElement = ChangeEvent<HTMLInputElement>;

//Dropzone
const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            {files.length > 0 && submitButton}
        </div>
    )
}

function SeminarLessonModal() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { seminarSelected } = useSelector((state: IRootState) => state.seminar);
    const { seminarlesson_selected, showForm } = useSelector((state: IRootState) => state.seminarlesson);
    const { novelties } = useSelector((state: IRootState) => state.novelty);
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector((state: IRootState) => state.validator);
    const { globalFamilyId, globalBrandId, globalCountriesId, globalLanguageId } = useSelector((state: IRootState) => state.global);

    // Lesson State
    const [seminarLessonState, setSeminarLessonState] = useState<ISeminarLesson>({
        id: null,
        novelties: [],
        fileNames: [],
        seminar_id: seminarSelected.id,
        title: '',
        content: '',
        extracontent: null,
        type: 0,
        order: null,
        duration: 1,
        datetime: null,
        notifications: { email: 0, notification: 0, integration: 0, hierarchy: 0 }
    });
    const [minOrder, setMinOrder] = useState(1);

    const types = [
        { id: 0, name: 'Embed' },
        { id: 1, name: 'Vimeo' }
    ]

    // Ready Page
    useEffect(() => {
        if (seminarlesson_selected != null) {
            setSeminarLessonState({
                id: seminarlesson_selected.id,
                novelties: seminarlesson_selected.noveltiesIds,
                fileNames: [],
                seminar_id: seminarlesson_selected.seminar_id,
                title: seminarlesson_selected.title,
                content: seminarlesson_selected.content,
                extracontent: seminarlesson_selected.extracontent,
                type: seminarlesson_selected.type,
                order: seminarlesson_selected.order,
                duration: seminarlesson_selected.duration,
                datetime: (seminarlesson_selected.datetime != null) ? moment(seminarlesson_selected.datetime).format('YYYY-MM-DD HH:mm:ss') : null,
                notifications: (seminarlesson_selected.notifications !== null) ? JSON.parse(seminarlesson_selected.notifications) : { email: 0, notification: 0, integration: 0, hierarchy: 0 }
            });
        }
    }, []);

    useEffect(() => {
        dispatch(getNovelties({
            family_id: globalFamilyId,
            brand_id: globalBrandId,
            language_id: globalLanguageId,
            requesttype: 0,
            type: 'novelties'
        }));
    }, [globalFamilyId, globalBrandId, globalLanguageId]);

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator());
            dispatch(createSeminarLesson(seminarLessonState));
        }
    }, [isValidForm]);

    /* Functions */
    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value;
        setSeminarLessonState({ ...seminarLessonState, [inputName]: value });
    }

    const handleNotificationsChange = (inputName: string) => (event: FormElement) => {
        let value;
        if (inputName === 'hierarchy')
            value = event.target.value;
        else
            value = event.target.checked;

        let tmpNotificationJson = { ...seminarLessonState.notifications };
        tmpNotificationJson[inputName] = value;
        setSeminarLessonState({ ...seminarLessonState, notifications: tmpNotificationJson });
    }

    const handleMultiAutoCompleteChange = (inputName: string, value: any) => {
        let optionsMulti: number[] = [];

        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                optionsMulti.push(value[i].id);
            }
        }

        setSeminarLessonState({ ...seminarLessonState, [inputName]: optionsMulti });
    }

    const handleEditorChange = (e: any) => {
        setSeminarLessonState({ ...seminarLessonState, content: e.target.getContent() });
    }

    const handleSubmit = () => {
        dispatch(formValidator({
            title: [seminarLessonState.title, 'required|max:50|min:2', 'general'],
            seminar: [seminarLessonState.seminar_id, 'required|numeric', 'general'],
            content: [seminarLessonState.content, 'notempty', 'general'],
            order: [seminarLessonState.order, 'required|numeric|min:1', 'general'],
            duration: [seminarLessonState.duration, 'required|numeric|min:1', 'general']
        }));
    }

    // Tabs
    const [currentTab, setCurrentTab] = useState(0);
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    //Dropzone
    const getUploadParams = ({ meta }: any) => {
        let tmpFileNames = [...seminarLessonState.fileNames];
        tmpFileNames.push(meta.name);
        setSeminarLessonState({ ...seminarLessonState, fileNames: tmpFileNames });
        return { url: `${process.env.REACT_APP_API_URL}/seminarlessons/uploaddropzone/${meta.name}` }
    }

    return (
        <>
            <Dialog
                open={showForm}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                className={formClasses.containerForm}
                onClose={() => dispatch(toggleFormSeminarLesson())}
                aria-labelledby="form-dialog-title"
                disableEnforceFocus>

                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {seminarLessonState.id !== null ? `${t('edit')} ${t('lesson')}` : `${t('create')} ${t('lesson')}`}
                        </Typography>
                        <Button onClick={() => dispatch(toggleFormSeminarLesson())} color="primary" variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container
                        alignItems="flex-start" justify="space-between" spacing={2}>

                        <Grid item xs={6} md={5}>
                            <TextField
                                label={t("title")}
                                fullWidth
                                required
                                value={seminarLessonState.title}
                                error={errorForm.title}
                                helperText={msgForm.title}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ 'data-createlesson': 'name' }}
                                onChange={handleInputChange('title')}
                                variant="filled" />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label={t("seminar")}
                                fullWidth
                                disabled
                                margin="normal"
                                value={seminarSelected.title}
                                error={errorForm.course}
                                helperText={msgForm.course}
                                onChange={handleInputChange('seminar')}
                                variant="filled" />
                            <FormHelperText error={errorForm.course}>{msgForm.course}</FormHelperText>

                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                label={t("duration") + '(Min)'}
                                type="number"
                                fullWidth
                                required
                                margin="normal"
                                value={seminarLessonState.duration}
                                error={errorForm.duration}
                                helperText={msgForm.duration}
                                onChange={handleInputChange('duration')}
                                inputProps={{ 'data-createlesson': 'duration' }}
                                variant="filled" />
                            <FormHelperText error={errorForm.duration}>{msgForm.duration}</FormHelperText>
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                label={t("order")}
                                type="number"
                                fullWidth
                                required
                                margin="normal"
                                InputProps={{ inputProps: { min: minOrder, 'data-createlesson': 'order' } }}
                                value={seminarLessonState.order}
                                error={errorForm.order}
                                helperText={msgForm.order}
                                onChange={handleInputChange('order')}
                                variant="filled" />
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Tabs
                            value={currentTab}
                            indicatorColor="secondary"
                            textColor="primary"
                            className={formClasses.tapForm}
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example">

                            <Tab label={t("general")}  {...a11yProps(0)} className={errorTab.general ? formClasses.errorTab : ''} />
                            <Tab label="Material de apoyo"  {...a11yProps(1)} />

                        </Tabs>
                    </Grid>

                    <TabPanel value={currentTab} index={0}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6} md={(seminarLessonState.type === 1) ? 3 : 6}>
                                <DatePicker
                                    ampm={false}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy hh:mm a"
                                    label={`${t('date')} ${t('live-event')}`}
                                    InputLabelProps={{ shrink: true, }}
                                    fullWidth
                                    value={seminarLessonState.datetime}
                                    onChange={(newDate: any) => setSeminarLessonState({ ...seminarLessonState, datetime: moment(newDate).format('YYYY-MM-DD HH:mm:ss') })}
                                />
                            </Grid>

                            <Grid item xs={12} md={(seminarLessonState.type === 1) ? 3 : 6}>
                                <Autocomplete
                                    id="combo-box-types"
                                    disableClearable
                                    value={types.find((type: any) => type.id === seminarLessonState.type)}
                                    options={types}
                                    onChange={(event, value) => { setSeminarLessonState({ ...seminarLessonState, type: value.id }) }}
                                    getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                    renderInput={(params) => <TextField {...params} label={t("type")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                />
                            </Grid>

                            {(seminarLessonState.type === 0) ?
                                <Grid item xs={12}>
                                    <TinyRichEditor
                                        data-tinyrich="textarea"
                                        handleEditorChange={handleEditorChange}
                                        initialContent={seminarLessonState.content}
                                        uploadURL='/seminarlessons/upload'
                                    />
                                    <FormHelperText error={errorForm.content}>{msgForm.content}</FormHelperText>
                                </Grid>
                                :
                                <>

                                    <Grid item xs={12} sm={3} md={3}>
                                        <TextField
                                            data-createseminarlesson="content"
                                            label={`${t('link')} ${t('video')}`}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{ shrink: true }}
                                            variant="filled"
                                            value={seminarLessonState.content}
                                            onChange={(event: FormElement) => { setSeminarLessonState({ ...seminarLessonState, content: event.target.value }) }} />
                                        <FormHelperText error={errorForm.videolink}>{msgForm.videolink}</FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3}>
                                        <TextField
                                            data-createseminarlesson="extracontent"
                                            label={`${t('link')} ${t('chat')}`}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{ shrink: true }}
                                            variant="filled"
                                            value={seminarLessonState.extracontent}
                                            onChange={(event: FormElement) => { setSeminarLessonState({ ...seminarLessonState, extracontent: event.target.value }) }} />
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Notificaciones</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={seminarLessonState.notifications.email} onChange={handleNotificationsChange("email")} name="email" />}
                                            label="Email"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={seminarLessonState.notifications.notification} onChange={handleNotificationsChange("notification")} name="notification" />}
                                            label="Notificacion"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={seminarLessonState.notifications.integration} onChange={handleNotificationsChange("integration")} name="integration" />}
                                            label="Integracion"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Notificar a:</FormLabel>
                                    <RadioGroup aria-label="notifyTo" name="notifyTo" value={seminarLessonState.notifications.hierarchy} onChange={handleNotificationsChange("hierarchy")}>
                                        <FormControlLabel value="0" control={<Radio />} label="Todos" />
                                        <FormControlLabel value="1" control={<Radio />} label="Comerciales" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>


                        </Grid>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-novelties"
                                    value={novelties.filter((novelty: any) => seminarLessonState.novelties.includes(novelty.id))}
                                    onChange={(event, value) => handleMultiAutoCompleteChange('novelties', value)}
                                    options={novelties}
                                    multiple
                                    getOptionLabel={(option: any) => option.id !== undefined ? option.title : ''}
                                    renderInput={(params) => <TextField {...params} label={t("novelties")} variant="filled" InputLabelProps={{ shrink: true }} />}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    LayoutComponent={Layout}
                                    classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                    inputContent="Arrastra los archivos para subir"
                                />
                            </Grid>

                        </Grid>
                    </TabPanel>
                </DialogContent>

                <DialogActions className={!isValidForm && Object.keys(msgForm).length > 0 ? formClasses.errorDialogActions : ''}>
                    <CancelButton onClick={() => dispatch(toggleFormSeminarLesson())}>
                        {t("cancel")}
                    </CancelButton>
                    <SaveButton data-createlesson="saveButton" onClick={() => handleSubmit()}>
                        {seminarLessonState.id !== null ? t("save") : t("create")}
                    </SaveButton>
                </DialogActions>

            </Dialog>

        </>
    );
}

export default SeminarLessonModal;