// React
import { useState, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import {
  Button,
  Dialog,
  TextField,
  Chip,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  DialogActions,
  Tabs,
  Tab,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import Autocomplete, {
} from "@material-ui/lab/Autocomplete";

// Custom components
import SubtitleGrey from "../../components/common/typography/SubtitleGrey";
import TabPanel from "../common/admin/TabPanel";

import { CancelButton, InputCountry, SaveButton, CountryAutocomplete, ProvinceAutocomplete, TownAutocomplete, PostalcodeAutocomplete } from "../common/forms";

// Redux Actions
import {
  storeUser,
  updateUser,
  toggleFormUsers,
} from "../../redux/actions/users";
import {
  formValidator,
  resetFormValidator,
} from "../../redux/actions/validator";
import { getMenus } from "../../redux/actions/menus";
import { IRootState } from "../../redux/types";

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

//Styles
import FormsStyles from "../../styles/components/common/forms/FormsStyles";
import OptionFilterUser from "../layouts/filters/OptionFilterUser";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Types
interface IUser {
  parent: any;
  id: number;
  name: string;
  phone: string;
  email: string;
  role_id: number;
  filterRoleId: number;
  countries: number[];
  languages: number[];
  brands: number[];
  menus: number[];
  address: string;
  country: any;
  province: any;
  town: any;
  postalcode: any;
  code: string;
  client_type: null | number;
  can_verify: number;
  centerName: null | string;
  centerAddress: null | string;
  centerCountry: null | number;
  centerProvince: null | number;
  centerTown: null | number;
  centerPostalcode: any;
}

type UserModalProps = {
  role_id: number;
  showBotton?: boolean;
};

function UserModal({ role_id, showBotton = true }: UserModalProps) {
  // Translate
  const { t } = useTranslation(["global"]);
  //Custom Hook
  const isMobile = useScreenWidth(700);
  //Styles
  const formClasses = FormsStyles();

  //Redux Hooks
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const { userSelected, showForm } = useSelector((state: IRootState) => state.user);
  const { menus } = useSelector((state: IRootState) => state.menu);
  const { roles, rolehierarchy } = useSelector((state: IRootState) => state.role);
  const { isValidForm, errorForm, errorTab, msgForm } = useSelector((state: IRootState) => state.validator);
  const { userBrands } = useSelector((state: IRootState) => state.brand);
  const { languages } = useSelector((state: IRootState) => state.language);
  const { countries, provinces, towns, postalcodes } = useSelector((state: IRootState) => state.country);

  //Tab State
  const [currentTab, setCurrentTab] = useState(0);

  // User State
  const [user, setUser] = useState<IUser>({
    parent: authUser,
    id: 0,
    name: "",
    phone: "",
    email: "",
    role_id: role_id,
    filterRoleId: role_id,
    countries: [],
    languages: [],
    brands: [],
    menus: [],
    address: "",
    country: [],
    province: [],
    town: [],
    postalcode: [],
    code: "",
    client_type: null,
    can_verify: 0,
    centerName: null,
    centerAddress: null,
    centerCountry: null,
    centerProvince: null,
    centerTown: null,
    centerPostalcode: null,
  });

  const client_types = [
    { id: 0, name: t("admin.people.esthetic-professional") },
    { id: 1, name: t("admin.people.final-audience") },
    { id: 2, name: t("admin.people.esthetic-academy-student") },
    { id: 3, name: t("admin.people.esthetic-academy-professor") },
  ];

  useEffect(() => {
    if (!menus.length) dispatch(getMenus());
  }, []);

  useEffect(() => {
    if (user.role_id === 6) setCurrentTab(2);
    else setCurrentTab(0);
  }, [user.role_id]);

  // Send Form
  useEffect(() => {
    if (isValidForm) {
      dispatch(resetFormValidator());

      if (user.id === 0) dispatch(storeUser(user));
      else dispatch(updateUser(user));
    }
  }, [isValidForm]);

  useEffect(() => {
    if (userSelected !== null && userSelected.id !== user.id) {
      const objTown = towns.find((town: any) => town.id === userSelected.person.town_id);
      const objProvince = provinces.find((province: any) => province.id === objTown.province_id);
      setUser({
        parent: authUser,
        id: userSelected.id,
        role_id: userSelected.role_id,
        filterRoleId: role_id,
        name: userSelected.name,
        phone: userSelected.phone,
        email: userSelected.email,
        countries: userSelected.countries,
        languages: userSelected.languages,
        brands: userSelected.brands,
        menus: userSelected.menus,
        address: userSelected.person.address,
        country: countries.find((country: any) => country.id === objProvince.country_id),
        province: objProvince,
        town: objTown,
        postalcode: postalcodes.find((postalcode: any) => postalcode.id === userSelected.person.postalcode_id),
        code: userSelected.commercial_code,
        client_type: userSelected.person.client_type,
        can_verify: userSelected.can_verify,
        centerName:
          userSelected.center != null ? userSelected.center.name : null,
        centerAddress:
          userSelected.center != null ? userSelected.center.address : null,
        centerCountry:
          userSelected.center != null ? userSelected.center.country_id : null,
        centerProvince:
          userSelected.center != null ? userSelected.center.province_id : null,
        centerTown:
          userSelected.center != null ? userSelected.center.town_id : null,
        centerPostalcode:
          userSelected.center != null
            ? userSelected.center.postalcode_id
            : null,
      });
    }
  }, [userSelected]);

  /* Functions */
  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleAutoCompleteChangeCenter =
    (inputName: string) => (event: any, value: any) => {
      if (value !== null) setUser({ ...user, [inputName]: value.id });
    };

  const handleMultiAutoCompleteChange =
    (inputName: string, validLength: boolean = false) =>
      (event: any, value: any) => {
        let optionsMulti = [];
        if (value !== null) {
          for (let i = 0; i < value.length; i++) {
            if (validLength && i <= 0) optionsMulti.push(value[i].id);
            else if (!validLength) optionsMulti.push(value[i].id);
          }
        }

        setUser({ ...user, [inputName]: optionsMulti });
      };

  const handleInputChange = (inputName: string) => (event: any) => {
    const value = event.target.value;
    setUser({ ...user, [inputName]: value });

    // Assign Tab for role
    if (inputName === "role_id") {
      if (role_id === 6) setCurrentTab(2);
      else setCurrentTab(0);
    }
  };

  const handleSubmit = () => {
    if (user.role_id === 3) {
      dispatch(
        formValidator({
          name: [user.name, "required|max:45|min:2"],
          email: [user.email, "required|max:45|min:5", "general"],
          role_id: [user.role_id, "required|max:45|min:2", "general"],
          countries: [user.countries, "notempty", "work"],
          country: [user.country, "notempty", "general"],
          province: [user.province, "notempty", "general"],
          town: [user.town, "notempty", "general"],
          languages: [user.languages, "notempty", "general"],
        })
      );
    } else if (user.role_id === 6) {
      dispatch(
        formValidator({
          name: [user.name, "required|max:45|min:2"],
          email: [user.email, "required|max:45|min:5", "general"],
          phone: [user.phone, "required|max:45|min:6|numeric"],
          role_id: [user.role_id, "required|max:45|min:2", "general"],
          countries: [user.countries, "notempty", "work"],
          province: [user.province, "notempty", "general"],
          town: [user.town, "notempty", "general"],
          languages: [user.languages, "notempty", "general"],
          centerName: [user.centerName, "required|max:50|min:2", "center"],
        })
      );
    } else {
      dispatch(
        formValidator({
          name: [user.name, "required|max:45|min:2"],
          email: [user.email, "required|max:45|min:5", "general"],
          role_id: [user.role_id, "required|max:45|min:2", "general"],
          countries: [user.countries, "notempty", "work"],
          country: [user.country, "notempty", "general"],
          province: [user.province, "notempty", "general"],
          town: [user.town, "notempty", "general"],
          languages: [user.languages, "notempty", "general"],
        })
      );
    }
  };

  const handleModalCreate = () => {
    setUser({
      parent: authUser,
      id: 0,
      name: "",
      phone: "",
      email: "",
      role_id: role_id,
      filterRoleId: role_id,
      countries: [],
      languages: [],
      brands:
        [4, 8, 9].includes(authUser.role_id) && role_id === 6
          ? userBrands.map((brand: any) => brand["id"])
          : [],
      menus: authUser.menus.map((menu: any) => menu.id),
      address: "",
      country: [],
      province: [],
      town: [],
      postalcode: [],
      code: "",
      client_type: null,
      can_verify: 0,
      centerName: null,
      centerAddress: null,
      centerCountry: null,
      centerProvince: null,
      centerTown: null,
      centerPostalcode: null,
    });

    dispatch(toggleFormUsers());
  };

  const handleCloseModalUser = () => {
    dispatch(resetFormValidator());
    dispatch(toggleFormUsers());
  };

  const handleToggleMenusClick = (value: number) => () => {
    const currentIndex = user.menus.indexOf(value);
    const newChecked = [...user.menus];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setUser({ ...user, menus: newChecked });
  };

  const handleState = (inputName: string, value: any) => {
    setUser({ ...user, [inputName]: value });
  };

  return (
    <>
      {showBotton && (
        <Button
          id="btn-create-user"
          variant="outlined"
          color="secondary"
          onClick={() => handleModalCreate()}
        >
          {t("admin.people.create-user")}
        </Button>
      )}

      <Dialog
        open={showForm}
        fullWidth={true}
        fullScreen={isMobile && true}
        maxWidth={!isMobile && "md"}
        className={formClasses.containerForm}
        onClose={handleCloseModalUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container justify="space-between">
            <Typography variant="h6">
              {user.id !== 0 ? t("edit") : t("create")} {t("user")}
            </Typography>
            <Button
              onClick={handleCloseModalUser}
              color="primary"
              variant="text"
            >
              <CloseRounded />
            </Button>
          </Grid>
        </DialogTitle>

        <DialogContent className={formClasses.bodyForm}>
          <form noValidate autoComplete="nope">
            <Grid
              container
              alignItems="flex-start"
              justify="space-between"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={
                  [6].includes(user.role_id) && authUser.role_id !== 6 ? 8 : 12
                }
              >
                <TextField
                  id="txt-input-name"
                  label={t("name")}
                  fullWidth
                  value={user.name}
                  error={errorForm.name}
                  helperText={msgForm.name}
                  onChange={handleInputChange("name")}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                />
              </Grid>

              {[6].includes(user.role_id) && authUser.role_id !== 6 && (
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="combo-box-brands"
                    ChipProps={{ color: "secondary" }}
                    value={userBrands.filter((brand: any) =>
                      user.brands.includes(brand.id)
                    )}
                    onChange={handleMultiAutoCompleteChange("brands")}
                    options={userBrands}
                    multiple
                    disabled={
                      ([3, 4, 6, 8, 9].includes(authUser.role_id) &&
                        authUser.id === user.id) ||
                      ([4, 8, 9].includes(authUser.role_id) &&
                        user.role_id === 6)
                    }
                    getOptionLabel={(option: any) =>
                      option.id !== undefined ? option.name : ""
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={option.name}
                          {...getTagProps({ index })}
                          disabled={
                            (userSelected !== null && user.id !== 0 &&
                              userSelected.permissions.notEditBrands.includes(
                                option.id
                              )) ||
                            ([3, 4, 6, 8, 9].includes(authUser.role_id) &&
                              authUser.id === user.id) ||
                            ([4, 8, 9].includes(authUser.role_id) &&
                              user.role_id === 6)
                          }
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label={t("brands")}
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        error={errorForm.brands}
                        helperText={msgForm.brands}
                      />
                    )}
                  />
                  {/* <InputMultiBrands countriesSelected={brandsSelected.brands} handleState={handleState} /> */}
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Tabs
                value={currentTab}
                indicatorColor="secondary"
                textColor="primary"
                onChange={handleChangeTab}
                className={formClasses.tapForm}
                aria-label="disabled tabs example"
              >
                <Tab
                  label={t("general")}
                  {...a11yProps(0)}
                  className={errorTab.general ? formClasses.errorTab : ""}
                  style={{ display: user.role_id !== 6 ? "" : "none" }}
                />
                <Tab
                  label={t("admin.people.work")}
                  {...a11yProps(1)}
                  className={errorTab.work ? formClasses.errorTab : ""}
                  style={{ display: user.role_id !== 6 ? "" : "none" }}
                />
                <Tab
                  label={t("general")}
                  {...a11yProps(2)}
                  className={errorTab.general ? formClasses.errorTab : ""}
                  style={{ display: user.role_id === 6 ? "" : "none" }}
                />
                <Tab
                  label={t("admin.people.center")}
                  {...a11yProps(3)}
                  className={errorTab.center ? formClasses.errorTab : ""}
                  style={{
                    display:
                      user.role_id === 6 || user.role_id === 7 ? "" : "none",
                  }}
                />
                <Tab
                  label={t("admin.people.configuration")}
                  {...a11yProps(4)}
                  className={errorTab.config ? formClasses.errorTab : ""}
                  style={{ display: authUser.role_id === 6 ? "none" : "" }}
                />
              </Tabs>
            </Grid>

            <TabPanel value={currentTab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel shrink={true} id="lbl-roles">
                      {t("rol") + " *"}
                    </InputLabel>
                    <Select
                      value={user.role_id}
                      labelId="lbl-roles"
                      id="combo-box-roles"
                      fullWidth
                      label="rol"
                      onChange={handleInputChange("role_id")}
                    >
                      {roles.map((role: any) => {
                        if (rolehierarchy.includes(role.id) && role.id !== 4)
                          return (
                            <MenuItem key={role.id} value={role.id}>
                              {role.name}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <CountryAutocomplete value={user.country} handleInputChange={handleState} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    id="combo-box-languages"
                    value={languages.filter((language: any) =>
                      user.languages.includes(language.id)
                    )}
                    onChange={handleMultiAutoCompleteChange("languages")}
                    options={languages}
                    multiple
                    fullWidth
                    getOptionLabel={(option: any) =>
                      option.id !== undefined ? option.name : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={t("language")}
                        error={errorForm.languages}
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <FormHelperText error={errorForm.languages}>
                    {msgForm.languages}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ProvinceAutocomplete value={user.province} country={user.country} handleInputChange={handleState} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TownAutocomplete value={user.town} province={user.province} handleInputChange={handleState} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <PostalcodeAutocomplete value={user.postalcode} province={user.province} handleInputChange={handleState} />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="txt-input-address"
                    label={t("address")}
                    fullWidth
                    margin="normal"
                    value={user.address}
                    error={errorForm.address}
                    helperText={msgForm.address}
                    onChange={handleInputChange("address")}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="txt-input-email"
                    label={t("email")}
                    fullWidth
                    required
                    value={user.email}
                    error={errorForm.email}
                    helperText={msgForm.email}
                    onChange={handleInputChange("email")}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="txt-input-phone"
                    label={t("phone")}
                    fullWidth
                    value={user.phone}
                    error={errorForm.phone}
                    helperText={msgForm.phone}
                    onChange={handleInputChange("phone")}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <Grid container spacing={2}>
                {user.role_id !== 6 && (
                  <Grid item xs={12} sm={6} md={user.role_id === 4 ? 4 : 6}>
                    <InputCountry
                      value={user.countries}
                      handleInputChange={handleState}
                      userSelected={(userSelected !== null && user.id !== 0) ? userSelected : []}
                      label={t("admin.people.manage-countries")}
                    />
                  </Grid>
                )}

                {[8, 9].includes(user.role_id) && (
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="txt-commercial-code"
                      label={t("commercial-code")}
                      fullWidth
                      margin="normal"
                      value={user.code}
                      error={errorForm.code}
                      helperText={msgForm.code}
                      onChange={handleInputChange("code")}
                      InputLabelProps={{ shrink: true }}
                      variant="filled"
                    />
                  </Grid>
                )}

                {![2, 5].includes(user.role_id) && (
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="combo-box-brands"
                      ChipProps={{ color: "secondary" }}
                      value={userBrands.filter((brand: any) =>
                        user.brands.includes(brand.id)
                      )}
                      onChange={handleMultiAutoCompleteChange("brands")}
                      options={userBrands}
                      multiple
                      disabled={
                        [3, 4, 6, 8, 9].includes(authUser.role_id) &&
                        authUser.id === user.id
                      }
                      getOptionLabel={(option: any) =>
                        option.id !== undefined ? option.name : ""
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option.key}
                            label={option.name}
                            {...getTagProps({ index })}
                            disabled={
                              userSelected !== null && user.id !== 0
                                ? userSelected.permissions.notEditBrands.includes(
                                  option.id
                                ) ||
                                ([3, 4, 6].includes(authUser.role_id) &&
                                  authUser.id === user.id)
                                : false
                            }
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("brands")}
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                          error={errorForm.brands}
                          helperText={msgForm.brands}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>

            <TabPanel value={currentTab} index={2}>
              <Grid container spacing={2}>
                {authUser.role_id !== 6 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel shrink={true} id="lbl-roles">
                        {t("rol") + " *"}
                      </InputLabel>
                      <Select
                        value={user.role_id}
                        labelId="lbl-roles"
                        id="combo-box-roles"
                        fullWidth
                        label="rol"
                        onChange={handleInputChange("role_id")}
                      >
                        {roles.map((role: any) => {
                          if (rolehierarchy.includes(role.id) && role.id !== 4)
                            return (
                              <MenuItem key={role.id} value={role.id}>
                                {role.name}
                              </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {authUser.role_id !== 6 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="combo-box-languages"
                      value={languages.filter((language: any) =>
                        user.languages.includes(language.id)
                      )}
                      onChange={handleMultiAutoCompleteChange("languages")}
                      options={languages}
                      multiple
                      fullWidth
                      getOptionLabel={(option: any) =>
                        option.id !== undefined ? option.name : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={t("language")}
                          error={errorForm.languages}
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <FormHelperText error={errorForm.languages}>
                      {msgForm.languages}
                    </FormHelperText>
                  </Grid>
                )}

                {authUser.role_id !== 6 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="combo-box-client-types"
                      value={client_types.find(
                        (client_type: any) =>
                          client_type.id === user.client_type
                      )}
                      onChange={handleAutoCompleteChangeCenter("client_type")}
                      options={client_types}
                      getOptionLabel={(option: any) =>
                        option.id !== undefined ? option.name : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          rowsMax={1}
                          label="Tipo de cliente"
                          variant="filled"
                          error={errorForm.languages}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <FormHelperText error={errorForm.languages}>
                      {msgForm.languages}
                    </FormHelperText>
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="txt-input-email"
                    label={t("email")}
                    fullWidth
                    required
                    margin="normal"
                    value={user.email}
                    error={errorForm.email}
                    helperText={msgForm.email}
                    onChange={handleInputChange("email")}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="txt-input-phone"
                    label={t("phone")}
                    fullWidth
                    margin="normal"
                    value={user.phone}
                    error={errorForm.phone}
                    helperText={msgForm.phone}
                    onChange={handleInputChange("phone")}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={currentTab} index={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="txt-center-name"
                    label={t("admin.people.center-name")}
                    required
                    fullWidth
                    margin="normal"
                    value={user.centerName}
                    error={errorForm.centerName}
                    helperText={msgForm.centerName}
                    onChange={handleInputChange("centerName")}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    id="txt-center-address"
                    label={t("address")}
                    fullWidth
                    margin="normal"
                    value={user.address}
                    error={errorForm.address}
                    helperText={msgForm.address}
                    onChange={handleInputChange("address")}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                  />
                </Grid>

                {authUser.role_id !== 6 && (
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="combo-box-countries"
                      value={countries.filter((country: any) =>
                        user.countries.includes(country.id)
                      )}
                      onChange={handleMultiAutoCompleteChange(
                        "countries",
                        true
                      )}
                      options={countries}
                      multiple
                      fullWidth
                      getOptionLabel={(option: any) =>
                        option.id !== undefined ? option.name : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={t("country")}
                          error={errorForm.countries}
                          variant="filled"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <FormHelperText error={errorForm.countries}>
                      {msgForm.countries}
                    </FormHelperText>
                  </Grid>
                )}

                {authUser.role_id !== 6 && (
                  <Grid item xs={12} sm={6}>
                    <ProvinceAutocomplete value={user.province} country={user.countries} handleInputChange={handleState} />
                  </Grid>
                )}

                {authUser.role_id !== 6 && (
                  <Grid item xs={12} sm={6}>
                    <TownAutocomplete value={user.town} province={user.province} handleInputChange={handleState} />
                  </Grid>
                )}

                {authUser.role_id !== 6 && (
                  <Grid item xs={12} sm={6}>
                    <PostalcodeAutocomplete value={user.postalcode} province={user.province} handleInputChange={handleState} />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SubtitleGrey variant="body1">
                    {t("restrict-access")}
                  </SubtitleGrey>
                  <List>
                    {authUser.menus.map((value: any) => {
                      const labelId = `checkbox-list-label-${value.id}`;

                      return (
                        // <ListItem
                        //   key={`menu-${value.id}`}
                        //   style={{ height: "28px" }}
                        //   dense
                        //   button
                        //   onClick={handleToggleMenusClick(value.id)}
                        // >
                        //   <ListItemIcon>
                        //     <Checkbox
                        //       edge="start"
                        //       checked={user.menus.indexOf(value.id) !== -1}
                        //       tabIndex={-1}
                        //       disableRipple
                        //       inputProps={{ "aria-labelledby": labelId }}
                        //     />
                        //   </ListItemIcon>
                        //   <ListItemText id={labelId} primary={value.name} />
                        // </ListItem>
                        <OptionFilterUser key={value.id} onChange={handleToggleMenusClick(value.id)} checked={user.menus.indexOf(value.id) !== -1} text={value.name} />
                      );
                    })}
                  </List>
                </Grid>

                {[1, 2].includes(authUser.role_id) && user.role_id !== 6 && (
                  <Grid item xs={12} sm={6}>
                    <SubtitleGrey variant="body1">
                      {t("admin.people.can-verify")}
                    </SubtitleGrey>
                    <FormControlLabel
                      control={
                        <OptionFilterUser onChange={(event: any) =>
                          setUser({
                            ...user,
                            can_verify: Number(event.target.checked),
                          })
                        }
                          checked={Boolean(user.can_verify)} text={t("active")} />
                        // <Checkbox
                        //   checked={Boolean(user.can_verify)}
                        //   onChange={(event: any) =>
                        //     setUser({
                        //       ...user,
                        //       can_verify: Number(event.target.checked),
                        //     })
                        //   }
                        //   name="checkbox_can_verify"
                        // />
                      }
                      label={''}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>

            {/*<TabPanel value={currentTab} index={2}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                        id="combo-box-roles"
                                        defaultValue={roles[role_id - 1]}
                                        options={roles}
                                        disabled
                                        getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                        renderInput={(params) => <TextField {...params} label="Rol" variant="filled" />}/>
                                     </Grid>
                                </Grid>
                            </TabPanel>*/}
          </form>

          {/*!isValidForm && Object.keys(msgForm).length > 0 &&
                        <Alert severity="error">
                            {msgTab.map((msg: string) => (
                                <Typography variant="caption" display="block" gutterBottom>{msg}</Typography>
                            ))}
                        </Alert>
                    */}
        </DialogContent>
        <DialogActions
          className={
            !isValidForm && Object.keys(msgForm).length > 0
              ? formClasses.errorDialogActions
              : ""
          }
        >
          <CancelButton onClick={handleCloseModalUser} aria-label="close">
            {t("cancel")}
          </CancelButton>

          <SaveButton
            autoFocus
            id="btn-save-user"
            onClick={() => handleSubmit()}
          >
            {t("save")}
          </SaveButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserModal;
