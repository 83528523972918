import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    //----------CONTAINER-------
    //------PANEL------
    //Sliders [  ][      ][  ]
    containercarousel: {
        marginTop: 24,
        '& .carousel': {
            borderRadius: 8,
        },
    },
    //------CARDS-------
    //Sliders [  ][      ][  ]
    card: {
        border: 'none',
        position: 'relative',
        boxShadow: '1px 1px 6px 0px rgb(0 0 0 / 14%) !important',
        borderRadius: 8,
        '& .MuiCardActionArea-focusHighlight': {
            backgroundColor: theme.palette.grey[800],
        },
        [theme.breakpoints.up('md')]: {
            boxSizing: 'border-box',
        },
    },
    imgPrimary: {
        borderRadius: 8,
        margin: '0px 12px',
        height: 400,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        maxHeight: 400,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        },
    },
    contentPrimary: {
        boxSizing: 'border-box',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        color: theme.palette.common.white,
        padding: 56,
        gap: 8,
        background: 'radial-gradient(96.02% 552.55% at 3.98% 59.64%, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%)',
        '& h4': {
            maxWidth: 500,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 3,
            overflow: 'hidden',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 24,
        },
    },
    linkPrimary: {
        color: theme.palette.common.white,
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
        fontWeight: 300,
        display: 'flex',
        gap: 8,
        padding: 0,
        '&.dark': {
            color: theme.palette.grey[800],
            '&:Hover, &:active': {
                textDecoration: 'underline',
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
            },
        },
        '&:Hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
        },
    },
    labelLive: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        '& svg': {
            color: theme.palette.common.white,
        },
    },
    //GridBlock {}{}{}
    containerBlock: {
        position: 'relative',
    },
    detailBlock: {
        marginTop: 8,
        minHeight: 61,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    titleBlock: {
        maxWidth: 300,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 40,
            lineHeight: '1.2',
        },
    },
    menuBlock: {
        borderRadius: '50%',
        padding: 8,
    },
    imgBlock: {
        width: '100%',
        borderRadius: '8px 8px 8px 0px',
        cursor: 'pointer',
    },
    //-------DetailLayout--------
    //Video&Chat Embed [        ][===]
    limitEmbed:{
        padding: '0px !important',
        [theme.breakpoints.up('md')]: {
            "& iframe":{
                height: 500,
            },
        },
        "& iframe":{
            width: '100%',
            border: 'none',
        },
        "& p":{
            margin: 0,
        },
    },
    //Video&Chat Vimeo [        ][===]
    containerHeroDetail: {
        borderRadius: 10,
        display: 'flex',
        boxSizing: 'content-box',
        border: `2px solid ${theme.palette.primary.light}`,
        boxShadow: `0px 8px 30px 0px ${process.env.REACT_APP_COLOR_SURFACE_LIGHT || "rgba(252, 62, 111, 0.3)"}`,
        position: 'relative',
        '&.wraped': {
            minHeight: 500
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            boxShadow: 'none',
            border: 'none',
            '&.wraped': {
                minHeight: 'inherit'
            },
        },
    },
    bannerHeroDetail: {
        position: 'absolute',
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.common.white,
        padding: 56,
        gap: 8,
        background: 'radial-gradient(124.34% 346.02% at -3.79% -4.78%, rgba(0, 0, 0, 0.53) 0%, rgba(46, 46, 46, 0.477) 100%)',
        backdropFilter: 'blur(6px)',
        borderRadius: 8,
        '& h4': {
            maxWidth: 500,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 3,
            overflow: 'hidden',
        },
        '&.hide': {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 24,
        },
    },
    videoDetail: {
        paddingBottom: '40%',
        position: 'relative',
        width: '75%',
        height: '100%',
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '56.25%',
            width: '100%',
            height: '70%',
        },
        [theme.breakpoints.down('xs')]: {
            '& iframe': {
                borderRadius: '8px !important',
            },
        },
        '& iframe': {
            borderRadius: '8px 0px 0px 8px',
        },
    },
    chatDetail: {
        paddingBottom: '40%',
        position: 'relative',
        width: '25%',
        height: '100%',
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '66.25%',
            width: '100%',
            height: '50%',
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '96.25%',
            '& iframe': {
                borderRadius: '8px !important',
            },
        },
        '& iframe': {
            borderRadius: '0px 8px 8px 0px',
        },
    },
    iframeDetail: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    btnLinkDetail: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    //Details&Description  =================
    containerDetail: {
        marginTop: 32,
        borderTop: `3px solid ${theme.palette.grey[800]}`,
        padding: 32,
        justifyItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        height: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            padding: 8,
        },
        '&.noBorder': {
            border: 'none',
        },
        '& h4': {
            fontWeight: 700,
        },
        '&.white': {
            marginTop: 8,
            gap: 24,
            color: theme.palette.common.white,
            borderTop: `3px solid ${theme.palette.common.white}`,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            paddingTop: 24,
        },
    },
    talkerDateDetail: {
        // marginTop: '-24px',
        display: 'flex',
        gap: 16,
        flexDirection: 'column',
        '& p': {
            fontWeight: 500,
        },
    },
    textReadyDetail: {
        marginTop: '-8px',
        textAlign: 'center',
        gap: 8,
        display: 'flex',
        flexDirection: 'column',
    },
}));