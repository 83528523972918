import { useSelector } from 'react-redux'

// Material UI
import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
const DocumentStyles = makeStyles(theme => ({
    inputRegister:{
        paddingTop: '3px !important',
    }
}));

interface ILanguage {
    id: number,
    name: string
}

function LanguageMultiAutocomplete({ value, handleInputChange, type }: any) {
    // Translate
    const { t } = useTranslation(["global"]);
    const classes = DocumentStyles();

    // Redux global state
    const { languages } = useSelector((state: IRootState) => state.language);
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // Handle fnuctions
    const handleMultiAutoCompleteChange = (languages: ILanguage[]) => {
        let optionsMulti: number[] = [];
        if (languages !== null) {
            for (let i = 0; i < languages.length; i++) {
                optionsMulti.push(languages[i].id);
            }
        }

        handleInputChange('languages', optionsMulti);
    }

    return (
        <Autocomplete
            id="multi-autocomplete-languages"
            value={languages.filter((language: any) => value.includes(language.id))}
            onChange={(event, value) => handleMultiAutoCompleteChange(value)}
            options={languages}
            multiple
            limitTags={1}
            classes={type === 1 ?{ inputRoot: classes.inputRegister }:{}}
            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={type === 1 ? '' : t("languages") + '*'}
                    variant="filled"
                    placeholder={type === 1 ? t("languages") : ''}
                    InputLabelProps={{ shrink: type === 1 ? false : true }}
                    error={errorForm.languages}
                    helperText={msgForm.languages}
                />
            }
        />
    )
}

export default LanguageMultiAutocomplete;