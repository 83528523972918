// Material UI
import { Typography } from '@material-ui/core';
import { Lock, PeopleRounded, PublicRounded } from '@material-ui/icons';

// Styles
import VipStyles from '../../../styles/components/common/VipStyles';

// Types
type VisibilityIconProps = {
    id: number
}

function VisibilityIcon({ id }: VisibilityIconProps) {
    // Styles
    const vipclasses = VipStyles();

    return (
        <>
            {id === 0 &&
                <Typography variant="caption" className={vipclasses.text}><PeopleRounded fontSize="small"/></Typography>
            }
            {id === 1 &&
                <Typography variant="caption" className={vipclasses.text}>VIP</Typography>
            }
            {id === 2 &&
                <Typography variant="caption" className={vipclasses.text}><PublicRounded fontSize="small" /></Typography>
            }
        </>
    );
}

export default VisibilityIcon;