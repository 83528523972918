// Material UI
import { Grid, Divider, Typography, Box, makeStyles, Container } from '@material-ui/core';

// Custom components
import CardHero from '../common/client/CardHero';
import ShareIcon from '../common/ShareIcon';
import SeminarCard from "./SeminarCard";
import NotFilter from '../../pages/NotFilter';

// Interfaces
import SeminarInterface from '../../interfaces/SeminarInterface';

// Libraries
import { useTranslation } from "react-i18next";
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import SeminarStyles from "../../styles/components/seminars/SeminarStyles";

type SeminarPanelProps = {
    highlightSeminars: SeminarInterface[],
    seminars: SeminarInterface[],
    loading: boolean,
    subsection: null | string
}

function SeminarPanel({ highlightSeminars, seminars, loading, subsection }: SeminarPanelProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const classes = SeminarStyles();

    /* Functions */
    const cutContent = (content: string) => {
        let trimmedString: string = '';
        trimmedString = content.substring(0, Math.min(content.length, content.indexOf("</p>")));
        return trimmedString;
    }


    return (

        <Container style={{ maxWidth: 1111 }}>
            {(highlightSeminars.length === 1) &&
                <Box mt={isMobile ? 0 : 3} mb={isMobile ? 2 : 0}>
                    <SeminarCard type={0} seminar={highlightSeminars[0]} />
                </Box>
            }

            {(highlightSeminars.length >= 2) &&
                <>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" style={{ minWidth: 'fit-content' }}>{t('featured')}</Typography>
                        <Divider style={{ marginLeft: 16, flexShrink: 1 }} />
                    </Box>
                    <Grid container item alignContent="flex-start" style={{ padding: '1rem' }}>

                        <Grid item xs={(isMobile) && (highlightSeminars.length >= 7) ? 8 : 12}>
                            <Carousel centerMode={isMobile ? false : true} autoPlay={true} showArrows={isMobile ? false : true} interval={5000} infiniteLoop className={classes.containercarousel}>
                                {
                                    highlightSeminars.map((seminar: SeminarInterface) => (
                                        <SeminarCard type={0} seminar={seminar} key={seminar.id} />
                                    ))
                                }

                            </Carousel>
                        </Grid>
                    </Grid>
                </>
            }

            {highlightSeminars.length > 0 &&
                <Box display="flex" alignItems="center" py={1} pb={3}>
                    <Typography variant="h6" style={{ minWidth: 'fit-content' }}>{t('last-seminars')}</Typography>
                    <Divider style={{ marginLeft: 16, flexShrink: 1 }} />
                </Box>
            }

            {!seminars.length && !loading ?
                <NotFilter subsection={subsection} />
                :
                <Grid container spacing={isMobile ? 1 : 3} direction="row">
                    {seminars.map((seminar: SeminarInterface, index: number) => (
                        <Grid item xs={6} sm={4} lg={3} key={seminar.id}>
                            <SeminarCard type={1} seminar={seminar} />
                        </Grid>
                    ))}
                </Grid>
            }
        </Container>

    );
}

export default SeminarPanel;