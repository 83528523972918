import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
    title: {
        color: theme.palette.common.white,
        fontWeight: 300,
        letterSpacing: '-0.005em'
    },
    border: {
        marginLeft: '24px',
        display: 'flex',
        alignItems: 'flex-end',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(7),
        },
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(16),
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '20px',
        },
    },
    body: {
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            marginLeft: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            display: 'none',
        },
    },
    internalBanner: {
        maxWidth: '100%',
        height: '64px',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
        },
    },
    internalBannerNull: {
        width: "100%",
        height: 64,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            height: 8,
        },
    },
    internalBannerAdmin: {
        width: "100%",
        height: 64,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            height: 50,
        },
    },
    contentTitle: {
        padding: 0,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    buttonURL: {
        padding: '2px 8px',
        marginLeft: 16,
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.grey[50],
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.26)',
        minWidth: 'auto',
        borderRadius: 8,
        fontSize: theme.typography.caption.fontSize,
        '& svg': {
            color: theme.palette.grey[50],
        },
        '& .MuiButton-label': {
            justifyContent: 'center',
        },
        '&:hover, &:active': {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.common.white,
            '& svg': {
                color: theme.palette.common.white,
            }
        },
        '& .admin': {
            backgroundColor: theme.palette.grey[800],
            color: theme.palette.warning.main,
            '& svg': {
                color: theme.palette.warning.main,
            },
            '&:hover, &:active': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.warning.main,
            }
        },
    },
    lightIcon: {
        color: theme.palette.grey[400]
    },
}));
