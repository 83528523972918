// React
import { useSelector } from 'react-redux'

// Material UI
import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Redux Actions
import { getLanguages } from '../../../redux/actions/languages';
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
const DocumentStyles = makeStyles(theme => ({
    inputRegister:{
        paddingTop: '3px !important',
    }
}));

interface ILanguage {
    id: number,
    name: string
}

function LanguageAutocomplete({ value, handleInputChange, type }: any) {
    // Translate
    const { t } = useTranslation(["global"]);
    const classes = DocumentStyles();

    // Redux global state
    const { languages } = useSelector((state: IRootState) => state.language);
    const { errorForm, msgForm } = useSelector((state: IRootState) => state.validator);

    // Handle functions
    const handleAutoCompleteChange = (language: ILanguage | null) => {
        handleInputChange('language', language);
    }

    return (
        <Autocomplete
            id="autocomplete-language"
            ChipProps={{ color: 'secondary' }}
            value={value}
            onChange={(event, value) => handleAutoCompleteChange(value)}
            options={languages}
            getOptionLabel={(language: ILanguage) => language.id !== undefined ? language.name : ''}
            renderInput={(params) => 
                <TextField
                    {...params}
                    required
                    label={t("language")}
                    variant="filled"
                    InputLabelProps={{ shrink: true }}
                    error={errorForm.language}
                    helperText={msgForm.language}
                />
            }
        />
    )
}

export default LanguageAutocomplete;