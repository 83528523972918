import { 
    START_GET_TESTIMONIALS, 
    START_SHOW_TESTIMONIAL,
    START_STORE_TESTIMONIAL, 
    START_UPDATE_TESTIMONIAL, 
    START_DELETE_TESTIMONIAL, 
    START_TOGGLE_FORM_TESTIMONIALS
} from '../types';

export const getTestimonials = payload => ({
    type: START_GET_TESTIMONIALS,
    payload
})

export const storeTestimonial = payload => ({
    type: START_STORE_TESTIMONIAL,
    payload
})

export const updateTestimonial = payload => ({
    type: START_UPDATE_TESTIMONIAL,
    payload
})

export const showTestimonial = payload => ({
    type: START_SHOW_TESTIMONIAL,
    payload
})

export const deleteTestimonial = payload => ({
    type: START_DELETE_TESTIMONIAL,
    payload
})

export const toggleFormTestimonials = () => ({
    type: START_TOGGLE_FORM_TESTIMONIALS
})