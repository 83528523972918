// React
import { useState } from 'react';
import { useSelector } from 'react-redux'

// Material UI
import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';

// Custom components
import TabPanel from '../admin/TabPanel';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

//Styles
import FormsStyles from '../../../styles/components/common/forms/FormsStyles';
import DocumentStyles from '../../../styles/components/common/client/FeaturedDetail';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function FeaturedDetail() {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    const { resourceSelected } = useSelector((state: IRootState) => state.resource);

    //Styles
    const formClasses = FormsStyles();
    const classes = DocumentStyles();

    //Tab State
    const [currentTab, setCurrentTab] = useState(0);

    const ReviewBlock = (props: any) => {
        const { testimonial } = props;
        return (
            <Grid item sm={6} xs={12} classes={{ root: classes.reviewItem }}>
                {/*<Box display="flex" flexDirection={isMobile ? 'row' : 'column'} gridGap={16}>
                    <Box>
                        <Typography variant='body2'>Nickname</Typography>
                        <Typography variant='caption'>16/12/2021</Typography>
                    </Box>
                    <Box width="max-content">
                        <Typography variant='body2'>Rango edad</Typography>
                        <Typography variant='caption'>35-45</Typography>
                    </Box>
                </Box>*/}
                <Box>
                    {testimonial.filetype_id === 2 ?
                        <img
                            src={`${process.env.REACT_APP_AWS_URL}/testimonials/${testimonial.file_name}`}
                            title={testimonial.title}
                            alt={testimonial.title}
                            width='100%'
                        />
                        :
                        <video src={`${process.env.REACT_APP_AWS_URL}/testimonials/${testimonial.file_name}`} width="100%" height="100%" controls>
                            Your browser does not support HTML <code>video</code>.
                        </video>
                    }
                </Box>
            </Grid>
        );
    }

    const BeforeAfterBlock = (props: any) => {
        const { testimonial } = props;
        return (
            <Grid item sm={6} xs={12} classes={{ root: classes.reviewItem }}>
                {/*<Box display="flex" flexDirection={isMobile ? 'row' : 'column'} gridGap={16}>
                    <Box>
                        <Typography variant='body2'>Nickname</Typography>
                        <Typography variant='caption'>16/12/2021</Typography>
                    </Box>
                    <Box width="max-content">
                        <Typography variant='body2'>Rango edad</Typography>
                        <Typography variant='caption'>35-45</Typography>
                    </Box>
                </Box>*/}
                <Box>
                    {testimonial.filetype_id === 2 ?
                        <img
                            src={`${process.env.REACT_APP_AWS_URL}/testimonials/${testimonial.file_name}`}
                            title={testimonial.title}
                            alt={testimonial.title}
                            width='100%'
                        />
                        :
                        <video src={`${process.env.REACT_APP_AWS_URL}/testimonials/${testimonial.file_name}`} width="100%" height="100%" controls>
                            Your browser does not support HTML <code>video</code>.
                        </video>
                    }
                </Box>
            </Grid>
        );
    }

    return (
        <>
            <Tabs
                value={currentTab}
                indicatorColor="secondary"
                textColor="primary"
                scrollButtons='on'
                variant={isMobile ? 'scrollable' : 'standard'}
                centered
                onChange={(event, newValue) => setCurrentTab(newValue)}
                className={formClasses.tapForm}
                aria-label="disabled tabs example">

                <Tab label={t("reviews")}  {...a11yProps(0)} />
                <Tab label={t("before-and-after")}  {...a11yProps(1)} />
                {/*<Tab label={t("related-courses")}   {...a11yProps(2)} />
                <Tab label={t("related-docs")}   {...a11yProps(3)} />*/}
            </Tabs>

            <TabPanel value={currentTab} index={0}>
                <Grid container spacing={2}>
                    {/* Mapeo de los testimonios relacionados */}
                    {resourceSelected.testimonials.filter((testimonial: any) => testimonial.type === 0).map((testimonial: any) => (
                        <ReviewBlock key={testimonial.id} testimonial={testimonial} />
                    ))}
                </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <Grid container spacing={4}>
                    {/* Mapeo de los fotos de antes y despúes relacionados */}
                    {resourceSelected.testimonials.filter((testimonial: any) => testimonial.type === 1).map((testimonial: any) => (
                        <BeforeAfterBlock key={testimonial.id} testimonial={testimonial} />
                    ))}
                </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <Grid container spacing={2} direction="row" alignItems="stretch" >
                    {/* Mapeo de cursos relacionados
                    <Grid item xs={6} sm={4}>
                        <CourseBlock />
                    </Grid> */}
                </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
                <Grid container spacing={3}>
                    {/* Mapeo de documentos relacionados
                    <MediaFileBlock/>  */}
                </Grid>
            </TabPanel>
        </>
    );
}

export default FeaturedDetail;