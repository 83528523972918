import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

// Libraries
import { useTranslation } from "react-i18next";

// Types
type IType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";
const string: IType = "string";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: 4,
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.common.white,
            maxHeight: 20,
        },
        label: {
            paddingLeft: 8,
            paddingRight: 8,
        },
        small: {
            height: 8,
            width: 8,
            borderRadius: '50%',
        },
        medium: {
            height: 12,
            width: 12,
            borderRadius: '50%',
            outline: `3px solid ${theme.palette.grey[50]}`
        },
        primary: {
            backgroundColor: theme.palette.success.main,
        },
        secondary: {
            backgroundColor: theme.palette.warning.main,
        },
        client: {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.light}`,
        },
        new: {
            borderRadius: 4,
            height: 20,
            backgroundColor: theme.palette.primary.main,
            float: 'right',
            position: 'relative',
            right: '36px',
            bottom: '10px',
            '& span': {
                fontSize: '0.875rem',
                color: theme.palette.common.white,
            },
        },
    }),
);
// TODO: Color no puede ser gray solo primary, secondary o default
export default function CustomChip({ type, status, custom, label, color, size = [] }: any) {
    const classes = useStyles();
    // Translate
    const { t } = useTranslation(["global"]);
    return (
        <>
            {size != '' ?
                size === 'medium' ?
                    <Chip
                        color={color}
                        classes={{
                            root: classes.medium,
                            colorPrimary: classes.primary,
                            colorSecondary: classes.secondary
                        }}
                    />
                    :
                    <Chip
                        size={size}
                        color={color}
                        classes={{
                            root: classes.root,
                            sizeSmall: classes.small,
                            colorPrimary: classes.primary,
                            colorSecondary: classes.secondary
                        }}
                    />
                :
                <Chip
                    label={
                        type === 0
                            ? status == 0 ? 'Borrador' : 'Publicado'
                            : type === 1
                                ? status == 0 ? t("active") : t("inactive")
                                : label}
                    color={
                        type === 0
                            ? status == 0 ? '' : 'primary'
                            : type === 1
                                ? status == 0 ? 'primary' : 'secondary'
                                : color}
                    classes={{
                        root: classes.root,
                        colorPrimary: classes.primary,
                        colorSecondary: classes.secondary,
                        label: classes.label,
                    }}
                    className={custom === "new" ? classes.new :
                        custom === "client" ? classes.client : ''}
                />
            }
        </>
    );
}