import { useState, ChangeEvent} from 'react';

// Material UI
import { Box, Grid, Tab, Tabs, Typography, Tooltip, IconButton } from "@material-ui/core";
import { BarChartRounded, Info, ListRounded } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

//Custom Components
import TabPanel from '../common/admin/TabPanel';
import ContentFilter from '../layouts/filters/ContentFilter';
import CustomVerticalBarChart from './CustomVerticalBarChart';

// Libraries
import MaterialTable from 'material-table';
import { useTranslation } from "react-i18next";

// Custom Hooks
import useSessionlogs from '../../hooks/graphs/useSessionlogs';

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import GraphsStyles from '../../styles/components/graphs/GraphsStyles';
import CustomShapePieChart from './CustomShapePieChart';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function RankingUsers() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Styles
    const classes = GraphsStyles();
    const formClasses = FormsStyles();

    // Condicional para el switch de grid a lista
    const [display, setDisplay] = useState<number>(1);

    //Tab state
    const [currentTab, setCurrentTab] = useState(0);
    //Toggle Button State
    const [timegap, setTimegap] = useState<string>('week');

    const { sessionTotal, sessionTime } = useSessionlogs(timegap);

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleTimegap = (event: React.MouseEvent<HTMLElement>, newTimegap: string) => {
        setTimegap(newTimegap);
    };

    const changeDisplayMode = (mode: number) => {
        setDisplay(mode)
    }

    const columnsSessionTotal: any = [
        { title: '#', field: 'position' },
        { title: t("date"), field: 'day' },
        { title: t("sessions"), field: "value" }
    ];

    const columnsSessionTime: any = [
        { title: '#', field: 'position' },
        { title: t("email"), field: 'email' },
        { title: t("sessiontime"), field: "value" }
    ];

    return (
        <Box width='100%'>
            <Box width={'100%'} display={'flex'} justifyContent='space-between' alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} gridGap={8} className={classes.heroContent}>
                    <Typography variant='subtitle1'>Usuarios</Typography>
                    <Tooltip placement='bottom' title={
                        <Box maxWidth={'240px'}>
                            <Typography variant='caption'>Los usuarios más relevantes.
                                también se mostrarán algunos datos generales de todos los usuarios</Typography>
                        </Box>
                    }>
                        <Info fontSize='small' />
                    </Tooltip>
                </Box>
                <ToggleButtonGroup
                    value={timegap}
                    exclusive
                    aria-label='time gap'
                    size='small'
                    onChange={handleTimegap}
                    classes={{
                        root: classes.toogleBtnContainer
                    }}
                >
                    <ToggleButton value="month" aria-label='month' classes={{ root: classes.toogleBtn, selected: classes.toggleBtnSelect }}>
                        <Typography variant='body2'>Mensual</Typography>
                    </ToggleButton>
                    <ToggleButton value="week" aria-label='week' classes={{ root: classes.toogleBtn, selected: classes.toggleBtnSelect }}>
                        <Typography variant='body2'>Semanal</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box mb={-2}>
                    
                    <ContentFilter showFilterBy={true} showOrderBy={false} />
                </Box>
                {/* Botón para cambiar de visualización(display) entre gráficas o tabla */}
                {display ?
                    <Tooltip title='Cambiar visualización a lista'>
                        <IconButton onClick={() => changeDisplayMode(0)}>
                            <ListRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title='Cambiar visualización a gráficas'>
                        <IconButton onClick={() => changeDisplayMode(1)}>
                            <BarChartRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
            {/* Validación de visualización(display) entre gráficas o tabla */}
            <Grid item xs={12}>
                <Tabs
                    value={currentTab}
                    indicatorColor="secondary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    className={formClasses.tapForm}
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="tabs usuario">

                    <Tab label={"Total Sesiones"} {...a11yProps(0)} />
                    <Tab label={"Tiempo por sesión"} {...a11yProps(1)} />
                </Tabs>
            </Grid>
            {/* Gráfica con top 5 contenidos mas populares entre ofertas y biblioteca*/}
            <TabPanel value={currentTab} index={0}>
                {display ?
                    <CustomVerticalBarChart data={sessionTotal} dataKey={"value"} typeDataName={"sessiones"} dataKeyX={'day'} />
                    :
                    <div className="table_analitics">
                        {/* Visualizacion de tabla */}
                        <MaterialTable
                            title=""
                            columns={columnsSessionTotal}
                            data={sessionTotal}
                            options={{
                                exportButton: false,
                                sorting: true,
                                search: false,
                                toolbar: false,
                            }}
                        />
                    </div>
                }
            </TabPanel>
            {/* Gráfica con top 5 contenidos mas populares de ofertas*/}
            <TabPanel value={currentTab} index={1}>
                {display ?
                    <CustomShapePieChart data={sessionTime} dataKey={"value"} typeDataName={'email'} />
                    :
                    <div className="table_analitics">
                        {/* Visualizacion de tabla */}
                        <MaterialTable
                            title=""
                            columns={columnsSessionTime}
                            data={sessionTime}
                            options={{
                                exportButton: false,
                                sorting: true,
                                search: false,
                                toolbar: false,
                            }}
                        />
                    </div>
                }
            </TabPanel>
        </Box>
    );
}


export default RankingUsers;
