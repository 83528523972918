import InputCountryForm from '../../common/admin/modal/InputCountry';
import BrandAutocompleteForm from './BrandAutocomplete';
import BrandMultiAutocompleteForm from './BrandMultiAutocomplete';
import LineAutocompleteForm from './LineAutocomplete';
import RoleAutocompleteForm from './RoleAutocomplete';
import VisibilityAutocompleteForm from './VisibilityAutocomplete';
import ResourcetypeSelectForm from './ResourcetypeSelect';
import LanguageAutocompleteForm from './LanguageAutocomplete';
import LanguageMultiAutocompleteForm from './LanguageMultiAutocomplete';
import ResourcecategoryAutocompleteForm from './ResourcecategoryAutocomplete';
import DropzoneMultiForm from './DropzoneMulti';
import DropzoneForm from './Dropzone';
import StrongPasswordForm, { StrongPasswordProps } from './StrongPassword';
import StatusAutocompleteForm from './StatusAutocomplete';
import DatePickerForm from './DatePicker';
import SaveButtonForm from './buttons/SaveButton';
import CancelButtonForm from './buttons/CancelButton';
import DeleteButtonForm from './buttons/DeleteButton';
import CountryAutocompleteForm from './CountryAutocomplete';
import ProvinceAutocompleteForm from './ProvinceAutocomplete';
import TownAutocompleteForm from './TownAutocomplete';
import PostalcodeAutocompleteForm from './PostalcodeAutocomplete';
import { CancelRounded, DeleteRounded, CheckCircleRounded } from '@material-ui/icons';

export const BrandAutocomplete = (props: any) => <BrandAutocompleteForm {...props} />;
export const BrandMultiAutocomplete = (props: any) => <BrandMultiAutocompleteForm {...props} />;
export const InputCountry = (props: any) => <InputCountryForm {...props} />;
export const LineAutocomplete = (props: any) => <LineAutocompleteForm {...props} />;
export const RoleAutocomplete = (props: any) => <RoleAutocompleteForm {...props} />;
export const VisibilityAutocomplete = (props: any) => <VisibilityAutocompleteForm {...props} />;
export const ResourcetypeSelect = (props: any) => <ResourcetypeSelectForm {...props} />;
export const LanguageAutocomplete = (props: any) => <LanguageAutocompleteForm {...props} />;
export const LanguageMultiAutocomplete = (props: any) => <LanguageMultiAutocompleteForm {...props} />;
export const ResourcecategoryAutocomplete = (props: any) => <ResourcecategoryAutocompleteForm {...props} />;
export const DropzoneMulti = (props: any) => <DropzoneMultiForm {...props} />;
export const Dropzone = (props: any) => <DropzoneForm {...props} />;
export const StrongPassword = ({ password, property, handleInputChange }: StrongPasswordProps) => <StrongPasswordForm password={password} property={property} handleInputChange={handleInputChange} />;
export const StatusAutocomplete = (props: any) => <StatusAutocompleteForm {...props} />;
export const DatePicker = (props: any) => <DatePickerForm {...props} />;
export const SaveButton = (props: any) => <SaveButtonForm startIcon={<CheckCircleRounded/>} {...props} />;
export const CancelButton = (props: any) => <CancelButtonForm startIcon={<CancelRounded />} {...props} />;
export const DeleteButton = (props: any) => <DeleteButtonForm startIcon={<DeleteRounded/>} {...props} />;
export const CountryAutocomplete = (props: any) => <CountryAutocompleteForm {...props} />;
export const ProvinceAutocomplete = (props: any) => <ProvinceAutocompleteForm {...props} />;
export const TownAutocomplete = (props: any) => <TownAutocompleteForm {...props} />;
export const PostalcodeAutocomplete = (props: any) => <PostalcodeAutocompleteForm {...props} />;