// React
import { useState } from 'react';
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';

// MATERIAL UI
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Link, makeStyles } from '@material-ui/core';
import {
    ExpandMore, ExpandLess, HomeRounded, BookmarkRounded, SchoolRounded, LocalActivityRounded,
    BusinessRounded, PeopleAltRounded, ViewCarouselRounded, ForumRounded, FlashOnRounded, EventRounded, WifiTetheringRounded
} from '@material-ui/icons';

// Custom Components
import ListItemMenu from '../ListItemMenu';
import SubListItemMenu from '../SubListItemMenu';


// Redux Actions
import { IRootState } from '../../../../redux/types';

// Translate
import { useTranslation } from "react-i18next";

// Styles
const Styles = makeStyles(theme => ({
    notification: {
        position: 'relative',
        '& div': {
            background: theme.palette.error.main,
            width: '8px',
            height: '8px',
            borderRadius: '100%',
            position: 'absolute',
            top: '-2px',
            right: '-12px',
        }
    },
}));


function AdminMenu() {
    // Translate
    const { t } = useTranslation(["global"]);
    // Styles
    const classes = Styles();
    // Redux Hooks
    const { user } = useSelector((state: IRootState) => state.auth);

    // Open State
    const [open, setOpen] = useState('');


    /* Functions */
    const handleClick = (id: any) => {
        if (open === id)
            setOpen('');
        else
            setOpen(id);
    };

    /* Global Variables */
    let menuItems: any = [];
    if (user !== null) {
        if (user.role_id === 1) {
            // SUPERADMIN
            menuItems = [
                {
                    id: 1,
                    name: t("home"),
                    icon: <HomeRounded fontSize="small" />,
                    path: "/escritorio",
                    list: []
                },
                {
                    id: 2,
                    name: t("admin.people.people"),
                    icon: <PeopleAltRounded fontSize="small" />,
                    path: "/admin/usuarios",
                    list: []
                },
                {
                    id: 3,
                    name: t("slider"),
                    icon: <ViewCarouselRounded fontSize="small" />,
                    path: "/admin/sliders",
                    list: []
                },
                {
                    id: 4,
                    name: t("offers"),
                    icon: <LocalActivityRounded fontSize="small" />,
                    path: "/admin/ofertas",
                    list: []
                },
                {
                    id: 5,
                    name: t("updates"),
                    icon: <FlashOnRounded fontSize="small" />,
                    path: "/admin/novedades",
                    list: []
                },
                {
                    id: 6,
                    name: t("news"),
                    icon: <ForumRounded fontSize="small" />,
                    path: "/admin/noticias",
                    list: []
                },
                {
                    id: 7,
                    name: t("admin.library.library"),
                    icon: <BookmarkRounded fontSize="small" />,
                    path: "/admin/biblioteca",
                    list: []
                },
                {
                    id: 8,
                    name: t("academy"),
                    icon: <SchoolRounded fontSize="small" />,
                    path: "/admin/cursos",
                    list: []
                },
                {
                    id: 9,
                    name: t("events"),
                    icon: <EventRounded fontSize="small" />,
                    path: "/admin/events",
                    list: []
                },
                {
                    id: 10,
                    name: t("seminars"),
                    icon: <WifiTetheringRounded fontSize="small" />,
                    path: "/admin/seminarios",
                    list: []
                },
                {
                    id: 11,
                    name: t("brands"),
                    icon: <BusinessRounded fontSize="small" />,
                    path: "/admin/marcas",
                    list: []
                },
            ];

        } else if (user.role_id === 2) {
            // ADMIN
            menuItems = [
                {
                    id: 1,
                    name: t("home"),
                    icon: <HomeRounded fontSize="small" />,
                    path: "/escritorio",
                    list: []
                },
                {
                    id: 2,
                    name: t("admin.people.people"),
                    icon: <PeopleAltRounded fontSize="small" />,
                    path: "/admin/usuarios",
                    list: []
                },
                {
                    id: 3,
                    name: t("slider"),
                    icon: <ViewCarouselRounded fontSize="small" />,
                    path: "/admin/sliders",
                    list: []
                },
                {
                    id: 4,
                    name: t("offers"),
                    icon: <LocalActivityRounded fontSize="small" />,
                    path: "/admin/ofertas",
                    list: []
                },
                {
                    id: 5,
                    name: t("updates"),
                    icon: <FlashOnRounded fontSize="small" />,
                    path: "/admin/novedades",
                    list: []
                },
                {
                    id: 6,
                    name: t("news"),
                    icon: <ForumRounded fontSize="small" />,
                    path: "/admin/noticias",
                    list: []
                },
                {
                    id: 7,
                    name: t("admin.library.library"),
                    icon: <BookmarkRounded fontSize="small" />,
                    path: "/admin/biblioteca",
                    list: []
                },
                {
                    id: 8,
                    name: t("academy"),
                    icon: <SchoolRounded fontSize="small" />,
                    path: "/admin/cursos",
                    list: []
                },
                {
                    id: 9,
                    name: t("events"),
                    icon: <EventRounded fontSize="small" />,
                    path: "/admin/events",
                    list: []
                },
                {
                    id: 10,
                    name: t("seminars"),
                    icon: <WifiTetheringRounded fontSize="small" />,
                    path: "/admin/seminarios",
                    list: []
                },
            ];
        } else if (user.role_id === 3) {
            // responsables
            menuItems = [
                {
                    id: 1,
                    name: t("home"),
                    icon: <HomeRounded fontSize="small" />,
                    path: "/escritorio",
                    list: []
                },
                {
                    id: 2,
                    name: t("admin.people.people"),
                    icon: <PeopleAltRounded fontSize="small" />,
                    path: "/admin/usuarios",
                    list: []
                },
            ];

        } else if ([8, 9].includes(user.role_id)) {
            // commercial
            menuItems = [
                {
                    id: 1,
                    name: t("home"),
                    icon: <HomeRounded fontSize="small" />,
                    path: "/escritorio",
                    list: []
                },
                {
                    id: 2,
                    name: t("admin.people.people"),
                    icon: <PeopleAltRounded fontSize="small" />,
                    path: "/admin/usuarios",
                    list: []
                },
            ];
        } else if (user.role_id === 5) {
            menuItems = [
                {
                    id: 1,
                    name: t("home"),
                    icon: <HomeRounded fontSize="small" />,
                    path: "/escritorio",
                    list: []
                },
                {
                    id: 2,
                    name: t("slider"),
                    icon: <ViewCarouselRounded fontSize="small" />,
                    path: "/admin/sliders",
                    list: []
                },
                {
                    id: 3,
                    name: t("offers"),
                    icon: <LocalActivityRounded fontSize="small" />,
                    path: "/admin/ofertas",
                    list: []
                },
                {
                    id: 4,
                    name: t("updates"),
                    icon: <FlashOnRounded fontSize="small" />,
                    path: "/admin/novedades",
                    list: []
                },
                {
                    id: 5,
                    name: t("news"),
                    icon: <ForumRounded fontSize="small" />,
                    path: "/admin/noticias",
                    list: []
                },
                {
                    id: 6,
                    name: t("admin.library.library"),
                    icon: <BookmarkRounded fontSize="small" />,
                    path: "/admin/biblioteca",
                    list: []
                },
                {
                    id: 7,
                    name: t("academy"),
                    icon: <SchoolRounded fontSize="small" />,
                    path: "/admin/cursos",
                    list: []
                },
                {
                    id: 8,
                    name: t("events"),
                    icon: <EventRounded fontSize="small" />,
                    path: "/admin/events",
                    list: []
                },
                {
                    id: 9,
                    name: t("seminars"),
                    icon: <WifiTetheringRounded fontSize="small" />,
                    path: "/admin/seminarios",
                    list: []
                },
            ];
        }
    }

    return (
        <List component="nav">

            {menuItems.map((item: any, index: any) => (
                <div key={item.name}>
                    {item.list.length > 0 ?
                        <div key={index}>
                            <ListItemMenu button onClick={() => handleClick(index)} className="admin">
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                                {open === index ? <ExpandLess color="primary" fontSize="small" /> : <ExpandMore color="primary" fontSize="small" />}
                            </ListItemMenu>

                            <Collapse in={open === index} timeout="auto" unmountOnExit>
                                <SubListItemMenu disablePadding >
                                    {item.list.map((list: any, indexList: number) => (
                                        <Link data-testid={"menu-" + item.path.replace('/', '')} key={indexList} component={RouterLink} to={list.path} >
                                            <ListItem>
                                                <ListItemText primary={list.name} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                </SubListItemMenu>
                            </Collapse>
                        </div>
                        :
                        <Link data-testid={"menu" + item.path.replaceAll('/', '-')} component={RouterLink} to={item.path} key={index}>
                            <ListItemMenu button selected={window.location.pathname === item.path} className="admin">
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText>
                                    <span className={classes.notification}>
                                        {item.name}
                                    </span>
                                </ListItemText>
                            </ListItemMenu>
                        </Link>
                    }
                </div>
            ))}
        </List>

    );


}

export default AdminMenu;