// React
import { useState } from 'react'
import { useDispatch } from 'react-redux'

// Material UI
import { Grid, Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons/';

// Custom components
import { CancelButton, SaveButton, StrongPassword } from "../common/forms";

// Redux Actions
import { changeUserPassword } from '../../redux/actions/users';

// Translate
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

type UpdatePassword = {
    password: string,
    confirmPassword: string,
    isCorrect: boolean
}

type UserChangePasswordProps = {
    user_id: number,
    open: boolean,
    handleChangeOpen: (state: boolean) => void
}

// Export function that return 2 values
export function StateChangePasswordModal(): [boolean, (state: boolean) => void] {

    const [state, setState] = useState<boolean>(false);

    const changeState = (state: boolean) => {
        setState(state);
    }

    return [state, changeState];
}

function UserChangePassword({ user_id, open, handleChangeOpen }: UserChangePasswordProps) {
    //Styles
    const formClasses = FormsStyles();
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);

    //Redux Hooks
    const dispatch = useDispatch();

    const [updatePassword, setUpdatePassword] = useState<UpdatePassword>({
        password: '',
        confirmPassword: '',
        isCorrect: false
    });

    /** Functions */
    const handleInputChange = (inputName: string, value: string, isCorrect: boolean) => {
        setUpdatePassword({ ...updatePassword, [inputName]: value, isCorrect });
    }

    const handleSubmit = () => {
        if (updatePassword.isCorrect && updatePassword.password === updatePassword.confirmPassword) {
            dispatch(changeUserPassword({ user_id, password: updatePassword.password }));
            handleChangeOpen(!open);
        }
    }

    return (
        <Dialog open={open}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            onClose={() => handleChangeOpen(!open)}
            aria-labelledby="form-dialog-title"
        >

            <DialogTitle id="form-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">{t("login.change-password")}</Typography>
                    <Button onClick={() => handleChangeOpen(!open)} color="primary" variant="text"><CloseRounded /></Button>
                </Grid>
            </DialogTitle>
            <DialogContent className={formClasses.bodyForm}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Box mb={0.5}>
                            <Typography>{t("login.new-password")}</Typography>
                        </Box>
                        <StrongPassword password={updatePassword.password} property={'password'} handleInputChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} >
                        <Box mb={0.5}>
                            <Typography>{t("login.confirm-password")}</Typography>
                        </Box>
                        <StrongPassword password={updatePassword.confirmPassword} property={'confirmPassword'} handleInputChange={handleInputChange} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => handleChangeOpen(!open)} >
                    {t("close")}
                </CancelButton>
                <SaveButton onClick={handleSubmit}>
                    {t("save")}
                </SaveButton>
            </DialogActions>
        </Dialog>
    );
}

export default UserChangePassword;