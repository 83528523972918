import { put, call, takeLatest } from 'redux-saga/effects';
import {
    START_GET_LESSONS, SUCCESS_GET_LESSONS, ERROR_GET_LESSONS,
    START_CREATE_LESSON, SUCCESS_CREATE_LESSON, ERROR_CREATE_LESSON,
    START_STORE_LESSONMULTIMEDIA, SUCCESS_STORE_LESSONMULTIMEDIA, ERROR_STORE_LESSONMULTIMEDIA,
    START_SHOW_LESSON, SUCCESS_SHOW_LESSON, ERROR_SHOW_LESSON,
    START_DELETE_LESSON, SUCCESS_DELETE_LESSON, ERROR_DELETE_LESSON,
    START_DELETE_LESSONMULTIMEDIA, SUCCESS_DELETE_LESSONMULTIMEDIA, ERROR_DELETE_LESSONMULTIMEDIA,
    START_TOGGLE_FORM_LESSON, SUCCESS_TOGGLE_FORM_LESSON,
    START_SET_VIEW_LESSON, SUCCESS_SET_VIEW_LESSON,
    START_COURSE_LESSON_STEP
} from '../types';
import { apiCall } from '../config/axios';

function* getLessons() {
    try {
        const response = yield call(apiCall, 'get', '/lessons/index')
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_GET_LESSONS, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_GET_LESSONS, payload: error.message })
    }
}

function* createLesson({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/lessons/store`, { lesson: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_CREATE_LESSON, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_CREATE_LESSON, payload: error.message })
    }
}

function* storeLessonMultimedia({ payload }) {
    try {
        const response = yield call(apiCall, 'post', '/lessons/storemultimedia', { lessonmultimedia: payload })
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_STORE_LESSONMULTIMEDIA, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_STORE_LESSONMULTIMEDIA, payload: error.message })
    }
}

function* showLesson({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/lessons/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_LESSON, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_LESSON, payload: error.message })
    }
}

function* deleteLesson({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/lessons/delete/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_LESSON, payload: response.data })
        yield put({ type: START_COURSE_LESSON_STEP, payload: { course_id: response.data.lesson.course_id, lesson_order: response.data.lesson.order } })
    } catch (error) {
        yield put({ type: ERROR_DELETE_LESSON, payload: error.message })
    }
}

function* deleteLessonMultimedia({ payload }) {
    try {
        const response = yield call(apiCall, 'post', `/lessons/deletemultimedia/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_DELETE_LESSONMULTIMEDIA, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_DELETE_LESSONMULTIMEDIA, payload: error.message })
    }
}

function* toggleFormLesson() {
    yield put({ type: SUCCESS_TOGGLE_FORM_LESSON })
}

function* setViewLesson({ payload }) {
    yield put({ type: SUCCESS_SET_VIEW_LESSON, payload })
}

export default function* lessons() {
    yield takeLatest(START_GET_LESSONS, getLessons);
    yield takeLatest(START_CREATE_LESSON, createLesson);
    yield takeLatest(START_STORE_LESSONMULTIMEDIA, storeLessonMultimedia);
    yield takeLatest(START_SHOW_LESSON, showLesson);
    yield takeLatest(START_DELETE_LESSON, deleteLesson);
    yield takeLatest(START_DELETE_LESSONMULTIMEDIA, deleteLessonMultimedia);
    yield takeLatest(START_TOGGLE_FORM_LESSON, toggleFormLesson);
    yield takeLatest(START_SET_VIEW_LESSON, setViewLesson);
}