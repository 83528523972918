import { makeStyles, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => createStyles({
   
    itemBtn: {
        gap: 4,
        '& svg': {
            color: theme.palette.success.main,
        }
    },
    dropdownActions: {
        backgroundColor: 'transparent',
        marginBottom: 92,
        width: 180,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
            width: '100vw',
        },
    },

    containerActions: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(52, 54, 56, 0.73)',
        backdropFilter: 'blur(15px)',
        borderRadius: '10px',
        '& .MuiListItemIcon-root': {
            minWidth: 'auto',
            marginRight: ' 6px',
            color: theme.palette.grey[100],
        },
        '& .MuiMenuItem-root': {
            height: 56,
            alignItems: 'center',
            color: theme.palette.grey[100],
            fontSize: 16,
            justifyContent: 'center',
            borderBottom: '1px solid ' + theme.palette.grey[100],
        },
        [theme.breakpoints.down('md')]: {
            '& li:last-child': {
                borderBottom: 'none',
                color: theme.palette.error.main,
            },
            '&.filter': {
                '& li:last-child': {
                    color: theme.palette.grey[100],
                }
            },
        },
    },

}));
