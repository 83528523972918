import { START_STORE_DISPLAYTIME, START_UPDATE_DISPLAYTIME, START_UPDATE_CURRENT_DISPLAYTIME } from '../types';

export const createDisplaytime = payload => ({
    type: START_STORE_DISPLAYTIME,
    payload
})

export const updateDisplaytime = payload => ({
    type: START_UPDATE_DISPLAYTIME,
    payload
})