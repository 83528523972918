// React
import { useState } from 'react';

// Material UI & Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, Grid, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons/';

//Custom Components
import { SaveButton } from '../common/forms';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

function TermsModal({ displayTerms, handleDisplayTerms }: any) {
    // Styles
    const formClasses = FormsStyles();
    //Custom Hook
    const isMobile = useScreenWidth(700);

    return (
        <>
            <Dialog
                open={displayTerms}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                onClose={() => handleDisplayTerms(false)}
                aria-labelledby="form-dialog-title"
                className={formClasses.containerForm}>

                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">Términos y condiciones</Typography>
                        <Button onClick={() => handleDisplayTerms(false)} color="primary" variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container>
                        <Grid item xs={12} >
                            <Typography color="textSecondary" variant="body2">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio lacus libero, potenti arcu, habitant aliquam. Dictum proin lacinia eget maecenas faucibus non lorem accumsan nulla. Leo sem morbi diam nunc habitasse velit id cursus id. Tortor tincidunt ultrices tellus aliquet sit suscipit dolor neque cursus. Enim, augue sit elementum imperdiet purus eget lectus. Auctor odio massa massa dui maecenas posuere tristique.
                                Id arcu, sit cursus blandit nulla eget. Sit blandit aliquam fringilla imperdiet volutpat aliquet imperdiet mauris sagittis. Ipsum tristique scelerisque in imperdiet ut in posuere amet. Massa et neque enim quisque in massa mi nunc. Id blandit tristique maecenas adipiscing eu faucibus.
                                Egestas ornare hendrerit ultrices malesuada scelerisque non etiam. A a, adipiscing quis lectus etiam consectetur auctor id. Odio consectetur pellentesque mauris, duis nec senectus. Diam amet, ut consequat quis. Bibendum sit suspendisse pretium parturient sed lorem cum gravida imperdiet. At id faucibus sodales euismod mi morbi cursus in augue. Tincidunt ullamcorper ut et feugiat lectus et rhoncus convallis purus. Magnis odio id velit aliquam. Lacus a fermentum nunc tincidunt vehicula viverra. Bibendum faucibus mauris at tincidunt nulla faucibus tincidunt diam. Facilisi elementum vivamus id pretium. Aliquet dignissim sed aliquam dignissim nec. Turpis sit volutpat massa semper nulla vitae, et. Aliquet aliquam blandit viverra interdum felis tellus. Sit ipsum suspendisse suspendisse ornare nunc, senectus fermentum dui.
                                Nulla phasellus faucibus faucibus vivamus ornare nunc proin sollicitudin volutpat. Quam turpis facilisis ac dolor. Tincidunt bibendum turpis sit ac et ornare urna, lorem a. Elementum lobortis volutpat interdum id. Eu aliquam donec consectetur elit elit, egestas diam.
                                Vestibulum in ut feugiat interdum in neque at. Aliquam viverra vulputate dui eu. Magna aliquet tortor facilisis maecenas. Elit nunc, sed egestas enim adipiscing lorem. Malesuada sed feugiat dignissim lacus, amet aliquet curabitur. Ut commodo feugiat ipsum fringilla pulvinar arcu consequat duis libero. Gravida vel in enim at euismod quis et ultrices.
                                Semper rhoncus ultricies eget quis elit. Porttitor sapien amet egestas in fringilla eu, vitae eu. Faucibus amet nullam in mi viverra rhoncus, consequat viverra id. Auctor amet, sed posuere faucibus risus. Malesuada viverra mus curabitur nisl gravida at tincidunt mi. Velit urna gravida libero adipiscing velit. Pretium, nisi, enim consequat diam. At ipsum, lectus bibendum euismod. Nullam massa fringilla enim non pretium ligula. Vitae risus tincidunt nulla quam congue. Nullam viverra iaculis vitae, mauris sagittis. Mi purus quisque elit sit nunc elementum. Posuere tristique a tellus commodo gravida. Ipsum suspendisse leo tortor proin a.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={() => handleDisplayTerms(false)}>
                        Continuar
                    </SaveButton>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default TermsModal;