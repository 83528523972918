// React - Redux
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

// Redux Actions
import { IRootState } from '../../redux/types'
import { getPopularDetailContent } from '../../redux/actions/reports';

export default function usePopularDetailContent() {
  // Redux Hooks
  const dispatch = useDispatch();
  const { popularDetailContent, menuCodes } = useSelector((state: IRootState) => state.report);

  useEffect(() => {
    dispatch(getPopularDetailContent());
  }, [dispatch]);

  return { menuCodes: menuCodes, reports: popularDetailContent }
}