import {
    ITermState,
    START_GET_TERMS, SUCCESS_GET_TERMS, ERROR_GET_TERMS,
    START_ACCEPT_TERMS, SUCCESS_ACCEPT_TERMS, ERROR_ACCEPT_TERMS
} from '../types';

const initialState: ITermState = {
    terms: [],
    accepted: false,
    msg: null,
    error: false,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action: any) {
    switch(action.type) {
        case START_GET_TERMS:
        case START_ACCEPT_TERMS:
        return{
            ...state,
            msg: null,
            error:false,
            loading: true
        }
        case SUCCESS_GET_TERMS:
        return{
            ...state,
            terms: action.payload.terms,
            accepted: action.payload.accepted,
            msg: null,
            error:false,
            loading: false
        }
        case SUCCESS_ACCEPT_TERMS:
        return{
            ...state,
            accepted: true,
            msg: null,
            error:false,
            loading: false
        }
        case ERROR_GET_TERMS:
        case ERROR_ACCEPT_TERMS:
        return{
            ...state,
            terms: [],
            accepted: false,
            msg: action.payload,
            error: true,
            loading: false
        }
        default:
            return state;
    }
}