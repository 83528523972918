import {
	makeStyles,
	createStyles,
} from "@material-ui/core/styles";
import mainTheme from "../themes/ClientTheme";

export default makeStyles((theme) =>
	createStyles({
		title: {
			color: mainTheme.palette.grey[800],
			fontWeight: "500"
		},
		cardContainer: {
			marginBottom: theme.spacing(3),
			padding: theme.spacing(3),
			boxShadow: '4px 6px 20px rgb(0 0 0 / 10%)',
			borderRadius: 24,
			[theme.breakpoints.down('sm')]: {
				borderRadius: 0,
				padding: theme.spacing(0),
				backgroundColor: "transparent",
				boxShadow: 'none !important',
			}
		},
		avatar: {
			textAlign: "center",
			'& svg': {
				color: mainTheme.palette.grey[200],
				height: "120px",
				width: "120px",
				margin: "20px",
			},
		},
		LinkedList: {
			maxHeight: 77,
			overflowY: 'auto',
			'&::-webkit-scrollbar': {
				backgroundColor: theme.palette.grey[200],
				borderRadius: '4px',
				width: '6px',
				margin: "8px",
				'&-thumb': {
					backgroundColor: theme.palette.grey[500],
					borderRadius: '4px'
				},
			},
		},
		verified: {
			paddingLeft: "21px",
			color: mainTheme.palette.grey[500],
			"&active": {
				paddingLeft: "21px",
				color: mainTheme.palette.primary.main,
			}
		},
		iconVerified: {
			filter: 'invert(99%) sepia(47%) saturate(2196%) hue-rotate(32deg) brightness(91%) contrast(88%)',
		},
		iconUnverified: {
			filter: 'invert(69%) sepia(11%) saturate(204%) hue-rotate(172deg) brightness(93%) contrast(81%)',
		},
		iconDenied: {
			filter: 'invert(73%) sepia(19%) saturate(7466%) hue-rotate(334deg) brightness(100%) contrast(104%)',
		},
		expand: {
			color: theme.palette.grey[400],
			transform: 'rotate(0deg)',
			transition: theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandOpen: {
			transform: 'rotate(90deg)',
			color: theme.palette.info.main,
		},
		expandActive: {
			borderBottom: '0px !important',
			color: theme.palette.info.main,
		},
		itemList: {
			cursor: 'pointer',
			borderTop: `1px solid ${theme.palette.grey[400]}`,
			'&.last': {
				borderBottom: `1px solid ${theme.palette.grey[400]}`,
			},
			'&:hover': {
				border: `1px solid `,
				backgroundColor: theme.palette.info.light,
				borderColor: theme.palette.info.main,
				borderRadius: 8,
				'& > h6, > svg': {
					color: theme.palette.info.main,
				}
			},
		},
		//----------------====MOBILE STYLES []===------------
		heroContainer: {
			backgroundColor: theme.palette.error.light,
			height: 100,
			paddingTop: 70,
			paddingLeft: 16,
			width: '-webkit-fill-available',
			margin: '-12px',
		},
		avatarMobile: {
			height: 68,
			width: 68,
			fontSize: 24,
			fontWeight: 400,
			backgroundColor: theme.palette.primary.light,
			border: `4px solid ${theme.palette.grey[50]}`,
			marginTop: '-18px',
		},
		statusAvatar: {
			position: 'absolute',
			bottom: 8,
			right: 8,
			zIndex: 99,
		},
		buttonEdit: {
			backgroundColor: theme.palette.info.light,
			color: theme.palette.info.main,
			borderRadius: 16,
			padding: '5px 16px',
			height: 'fit-content'
		},
		iconItemMobile: {
			'& svg': {
				fill: theme.palette.grey[300],
			}
		},
		//----------------====FAVORITES===------------
		containerListFav: {
			height: 40,
			padding: 8,
			backgroundColor: theme.palette.grey[100],
			borderRadius: 8,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		containerInfoFav: {
			cursor: 'pointer',
			maxWidth: 'calc(100% - 96px)',
		},
		titleFav: {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			maxWidth: '30vw',
			fontWeight: 300,
			[theme.breakpoints.down('sm')]: {
				maxWidth: 'calc(100% - 56px)',
			},
		},
		imgFav: {
			backgroundColor: theme.palette.grey[400],
			height: 40,
			width: 40,
			borderRadius: 8,
		},
	})
);
