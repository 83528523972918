import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import mainTheme from "../themes/ClientTheme";

export default makeStyles((theme) => createStyles({
    embed: {
        overflow: 'hidden',
    },
    card: {
        borderRadius: 8,
        border: 'none',
        cursor: "pointer",
        [theme.breakpoints.up('md')]: {
            // padding: '16px',
            boxShadow: '0px 0px 6px 1px rgb(0 0 0 / 8%) !important',
        },
    },

    catalogCard: {
        borderRadius: 8,
        border: 'none',
        cursor: "pointer",
        boxShadow: '6px 6px 20px rgb(0 0 0 / 10%)',
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    detailTitle: {
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem !important',
            lineHeight: '1.235',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '16px',
        },
    },

    media: {
        background: mainTheme.palette.grey[50],
        borderRadius: '8px 8px 8px 0px',
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important',
        backgroundColor: mainTheme.palette.grey[100],
        position: 'relative',
        width: '100%',
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            color: mainTheme.palette.grey[400],
            fontSize: '65px'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "240px",
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: "158px",
        }
    },

    contentButton: {
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: theme.palette.grey[700],
        opacity: '0.4',
        '&:hover': {
            backgroundColor: theme.palette.grey[800],
            opacity: '1',
        }
    },
    optionsMobile: {
        // padding: '0px 10px'
    },
    tooltipContainer: {
        maxWidth: 'fit-content',
    },
    content: {
        padding: '10px 0px'
    },
    title: {
        overflow: 'hidden',
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
            gridGap: 4,
        },
    },
    resourceText1: {
        padding: 12,
        paddingBottom: 0,
        [theme.breakpoints.up('sm')]: {
            minHeight: 74,
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
            padding: 8,
        },
    },
    date: {
        fontSize: '12px',
    },
    downloadButton: {
        maxWidth: 168,
        backgroundColor: theme.palette.error.light,
        color: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 24,
            width: '100%',
            maxWidth: '100%',
        }
    },
    img: {
        [theme.breakpoints.up('md')]: {
            borderRadius: 8,
            maxWidth: '80%',
            display: 'block',
            margin: '0 auto'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            borderRadius: 8,
        }
    }
}));