import { makeStyles, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => createStyles({
    nameResource: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 240,
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 150,
        },
    },
    emailText: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 200,
        fontWeight: 300,
    },

    actionsResponsive: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    actionsResponsiveRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },

    buttonTag: {
        color: theme.palette.grey[200],
        backgroundColor: theme.palette.grey[100],
        height: 20,
        padding: 0,
        paddingLeft: 8,
        minWidth: 24
    },
}))