import { makeStyles, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => createStyles({
    appBarMobile: {
        top: 'auto',
        bottom: 0,
        padding: 8,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 10,
        alignItems: 'center',
    },
    menuContent: {
        borderRadius: 16,
        backgroundColor: theme.palette.grey[800],
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
        width: 'inherit',
        [theme.breakpoints.between('sm', 'md')]: {
            width: 500,
        },
    },
    menuWidthLess: {
        minWidth: '25%',
        maxHeight: 71,
        padding: 0,
        border: '0px !important',
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 8,
        paddingBottom: 10,
        color: theme.palette.grey[300],
    },
    menuIcon: {
        margin: 0,
        "& svg": {
            fontSize: '24px !important',
        }
    },
    fabIcon: {
        height: 56,
        width: 56,
        minWidth: 56,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '0px 6px 24px rgba(252, 62, 111, 0.2)',
    },
    optionsMenuMobile: {
        paddingBottom: 96,
        top: 'auto',
        bottom: 0,
        padding: 8,
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },

 
    optionsMenuContent: {
        width: 'inherit',
        paddingRight: 56,
        [theme.breakpoints.between('sm', 'md')]: {
            width: 500,
            margin: '0 auto',
        },
    },
    optionItem: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        minWidth: 80,
        minHeight: 80,
        padding: '0px !important',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.grey[300],
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
    },


}));
