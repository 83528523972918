// React
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, RouteChildrenProps } from "react-router-dom";

// Material UI
import { Box, Container, Divider, List, ListItem, ListItemText, Typography, Grid } from '@material-ui/core';

// Redux
import { IRootState } from '../../redux/types';

//Custom components
import CustomBreadcrumb from '../../components/helpcenter/CustomBreadcrumb';
import useSectionData, { SectionData, SectionName } from '../../hooks/helpcenter/useSectionData';

// Libraries
import { useTranslation } from "react-i18next";

// Styles
import HelpCenterStyles from '../../styles/components/HelpCenterStyles';

// Types
interface ParamTypes {
    type: string
}


const Section = ({ history }: RouteChildrenProps) => {
    // Translation
    const { t } = useTranslation(["helpcenter"]);

    const { type } = useParams<ParamTypes>();
    const [faq, setFaq] = useState<SectionName[]>([]);
    const [objSections, setObjSections] = useState<string[]>([]);
    const { user: authUser } = useSelector((state: IRootState) => state.auth);

    // Styles
    const classes = HelpCenterStyles();

    const handleInfo = (section: string, iQuestion: number) => {
        history.push('/ayuda/tutoriales/' + type + '/' + section + '/' + iQuestion);
    }

    const data: SectionData = useSectionData();


    useEffect(() => {

        if (type === 'client') {
            setObjSections(['about-plei', 'profile', 'library', 'academy', 'events', 'basic']);
            setFaq(data.client);
        } else {
            setObjSections(['panel', 'clients', 'people', 'content', 'data', 'slider', 'brands']);
            if ([1, 2, 5].includes(authUser.role_id)) {
                setFaq(data.admin);
            } else {
                if ([3].includes(authUser.role_id)) {
                    setFaq(data.responsible);
                } else {
                    setFaq(data.comercial);
                }
            }
        }




    }, []);

    return (
        <Container style={{ padding: 0, marginTop: 50 }} maxWidth={"xl"}>
            <CustomBreadcrumb />
            <Container maxWidth={"md"}>
                <Box mt={6} mb={1}>
                    <Typography variant="h4" className={classes.sectionTitle}>{t("frequent-questions")}</Typography>
                    <Typography>{t("p1")}</Typography>
                </Box>

                <Box display="grid" gridGap={64}>
                    <Grid container spacing={8}>
                        {objSections.length > 0 &&
                            <>
                                {faq.filter((sections: any, index) => sections[objSections[index]] !== undefined).map((sections: any, index: number) => (
                                    <Grid key={sections[objSections[index]].title} item xs={12} md={6}>
                                        <Box pb={4} pt={4}>
                                            <Typography variant="subtitle1">{sections[objSections[index]].title}</Typography>
                                            <List component="nav">
                                                {sections[objSections[index]].content.map((section: any, index2: number) => {
                                                    return (
                                                        <>
                                                            <ListItem button onClick={() => handleInfo(objSections[index], index2)}>
                                                                <ListItemText primary={section.question} />
                                                            </ListItem>
                                                            <Divider />
                                                        </>
                                                    )
                                                })}
                                            </List>
                                        </Box>
                                    </Grid>
                                ))}
                            </>
                        }
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
}

export default Section;