// React
import { useState, useEffect, ChangeEvent, forwardRef, ReactElement, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, TextField,
    Typography, Grid, FormHelperText, Slide, Tab, Tabs
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import InputColor from 'react-input-color';

// Custom components
import TabPanel from '../common/admin/TabPanel';
import { CancelButton, DatePicker, SaveButton } from '../common/forms';

// Redux Actions
import { createEvent, toggleFormEvent } from '../../redux/actions/events'
import { getCountries, getFilteredCountries } from '../../redux/actions/countries'
import { getLanguages } from '../../redux/actions/languages'
import { filterUsers } from '../../redux/actions/users'
import { getRoles } from '../../redux/actions/roles'
import { formValidator, resetFormValidator } from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Types
interface IEvent {
    id: null | number,
    brand: null | any,
    line: null | any,
    country: null | any,
    languages: number[],
    title: string,
    description: string,
    speaker: null | string,
    link: null | string,
    file: null | any,
    text_button: null | string,
    background_color: any,
    text_color: any,
    method: number,
    type: number,
    status: number,
    visibility: number,
    role: any
}

type FormElement = ChangeEvent<HTMLInputElement>;

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EventsModal() {
    //Styles
    const formClasses = FormsStyles();

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    /* Global Constants */
    const types = [
        { id: 0, name: t("admin.events.webinar") },
        { id: 1, name: t("admin.events.instagram-live") },
        { id: 2, name: t("admin.events.live") },
        { id: 3, name: t("admin.events.training") },
        { id: 4, name: t("admin.events.fair") },
        { id: 5, name: t("admin.events.question-session") },
        { id: 6, name: t("admin.events.launch") },
        { id: 7, name: t("admin.events.ftf-event") }
    ];
    const status = [{ id: 0, name: t('active') }, { id: 1, name: t('inactive') }];
    const visibility = [{ id: 0, name: t('private') }, { id: 1, name: 'VIP' }, { id: 2, name: t('public') }];
    const methods = [{ id: 0, name: t("admin.events.custom") }, { id: 1, name: t("Normal") }];

    //Redux Hooks
    const dispatch = useDispatch();
    const { event_selected, showForm } = useSelector((state: IRootState) => state.event);
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { brands } = useSelector((state: IRootState) => state.brand);
    const { countries } = useSelector((state: IRootState) => state.country);
    const { languages } = useSelector((state: IRootState) => state.language);
    const { users } = useSelector((state: IRootState) => state.user);
    const { roles } = useSelector((state: IRootState) => state.role);
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector((state: IRootState) => state.validator);

    // Event State
    const [eventState, setEventState] = useState<IEvent>({
        id: null,
        brand: null,
        line: null,
        country: null,
        languages: [],
        title: '',
        description: '',
        speaker: '',
        link: '',
        file: null,
        text_button: null,
        background_color: { hex: '#5e72e4' },
        text_color: { hex: '#5e72e4' },
        method: 0,
        type: 0,
        status: 0,
        visibility: 0,
        role: []
    });
    // Component States
    const [date_ini, setDateIni] = useState<Moment | null>(null);
    const [date_end, setDateEnd] = useState<Moment | null>(null);
    const [minOrder, setMinOrder] = useState(1);
    const [tmpRoles, setTmpRoles] = useState([]);
    //Tab State
    const [currentTab, setCurrentTab] = useState(0);

    // Ready Page
    useEffect(() => {
        if (authUser.role_id == 1)
            dispatch(getCountries())
        else
            dispatch(getFilteredCountries())
        if (languages.length == 0)
            dispatch(getLanguages())
        if (roles.length == 0)
            dispatch(getRoles())
    }, []);

    useEffect(() => {
        if (event_selected != null) {
            setEventState({
                id: event_selected.id,
                brand: event_selected.brand,
                line: event_selected.line,
                country: event_selected.country,
                languages: event_selected.languages.map((language: any) => language.id),
                title: event_selected.title,
                description: event_selected.description,
                speaker: event_selected.speaker,
                link: event_selected.link,
                file: null,
                text_button: event_selected.text_button,
                background_color: { hex: event_selected.background_color },
                text_color: { hex: event_selected.text_color },
                method: event_selected.method,
                type: event_selected.type,
                status: event_selected.status,
                visibility: event_selected.visibility,
                role: event_selected.role
            });
            (event_selected.date_ini != null) && setDateIni(moment(event_selected.date_ini));
            (event_selected.date_end != null) && setDateEnd(moment(event_selected.date_end));
        }
    }, [event_selected]);

    useEffect(() => {
        if (roles.length > 0) {
            let tmpRoles: any = [];
            roles.forEach((role: any) => {
                if (role.id != 1 && role.id != 2 && role.id != 5)
                    tmpRoles.push(role);
            });
            setTmpRoles(tmpRoles);
        }
    }, [roles]);

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator());
            let formData = new FormData();
            if (eventState.id == null)
                formData.append("id", "");
            else
                formData.append("id", eventState.id as any);
            formData.append("title", eventState.title);
            formData.append("description", eventState.description);
            formData.append("role", JSON.stringify(eventState.role));
            if (eventState.brand != null)
                formData.append("brand", JSON.stringify(eventState.brand));
            if (eventState.line != null)
                formData.append("line", JSON.stringify(eventState.line));
            formData.append("country", JSON.stringify(eventState.country));
            formData.append("languages", JSON.stringify(eventState.languages));
            if (eventState.file == null)
                formData.append("file", "");
            else
                formData.append("file", eventState.file);
            if (eventState.speaker != null)
                formData.append("speaker", eventState.speaker);
            if (eventState.link != null)
                formData.append("link", eventState.link);
            if (eventState.text_button != null)
                formData.append("text_button", eventState.text_button);
            if (eventState.background_color != null)
                formData.append("background_color", eventState.background_color.hex);
            if (eventState.text_color != null)
                formData.append("text_color", eventState.text_color.hex);
            formData.append("date_ini", moment(date_ini).format('YYYY-MM-DD HH:mm:ss'));
            if (date_end != null)
                formData.append("date_end", moment(date_end).format('YYYY-MM-DD HH:mm:ss'));
            formData.append("method", eventState.method as any);
            formData.append("type", eventState.type as any);
            formData.append("status", eventState.status as any);
            formData.append("visibility", eventState.visibility as any);
            dispatch(createEvent(formData));
            handleCloseModalEvent();
        }
    }, [isValidForm]);

    /* Functions */
    const handleAutoCompleteChange = (inputName: string) => (event: any, value: any) => {
        setEventState({ ...eventState, [inputName]: value });
    }

    const handleInputChangeFile = (event: any) => {
        const value = event.target.files[0];
        setEventState({ ...eventState, file: value });
    }
    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value;
        setEventState({ ...eventState, [inputName]: value });
    }

    const handleMultiAutoCompleteChange = (inputName: string) => (event: any, value: any) => {
        let optionsMulti = [];
        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                optionsMulti.push(value[i].id);
            }
        }
        setEventState({ ...eventState, [inputName]: optionsMulti });
    }

    const handleNumberChange = (inputName: string) => (event: any, value: any) => {
        if (value != null)
            setEventState({ ...eventState, [inputName]: value.id });
        else
            setEventState({ ...eventState, [inputName]: value });
    }

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleSubmit = () => {
        dispatch(formValidator({
            country: [eventState.country, 'required'],
            languages: [eventState.languages, 'notempty', 'addsOns'],
            title: [eventState.title, 'required|max:50|min:2'],
            date_ini: [date_ini, 'required', 'general'],
            date_end: [{ initial: date_ini, final: date_end }, `required|datemin`, 'general'],
            role: [eventState.role, 'notempty', 'addsOns'],
            method: [eventState.method, 'required|numeric'],
            type: [eventState.type, 'required|numeric'],
            status: [eventState.status, 'required|numeric'],
            visibility: [eventState.visibility, 'required|numeric']
        }));
    }

    const handleCloseModalEvent = () => {
        dispatch(resetFormValidator());
        dispatch(toggleFormEvent());
    }

    return (
        <Dialog
            open={showForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            TransitionComponent={Transition}
            onClose={handleCloseModalEvent}
            aria-labelledby="alert-dialog-title"
            className={formClasses.containerForm}
            aria-describedby="alert-dialog-description">

            <DialogTitle id="alert-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {eventState.id !== null ? t("edit") : t("create")} {t("event")}
                    </Typography>
                    <Button onClick={handleCloseModalEvent}
                        color="primary" variant="text"><CloseRounded /></Button>
                </Grid>
            </DialogTitle>
            <DialogContent className={formClasses.bodyForm}>
                <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                    <Grid item xs={12} sm={6} md={(eventState.brand != null) ? 6 : 8}>
                        <TextField
                            label={t("title")}
                            fullWidth
                            required
                            margin="normal"
                            value={eventState.title}
                            error={errorForm.title}
                            helperText={msgForm.title}
                            onChange={handleInputChange('title')}
                            InputLabelProps={{ shrink: true }}
                            variant="filled" />
                    </Grid>

                    <Grid item xs={12} sm={6} md={(eventState.brand != null) ? 3 : 4}>
                        <Autocomplete
                            id="combo-box-brands"
                            value={eventState.brand}
                            onChange={handleAutoCompleteChange('brand')}
                            options={brands}
                            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                            renderInput={(params) => <TextField {...params} error={errorForm.brand}
                                helperText={msgForm.brand} label={t("brands")} variant="filled" InputLabelProps={{ shrink: true }} />}
                        />
                    </Grid>

                    {(eventState.brand != null) &&
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="combo-box-lines"
                                value={eventState.line}
                                onChange={handleAutoCompleteChange('line')}
                                options={eventState.brand.active_lines}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField {...params} label={t("line")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                        </Grid>
                    }

                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={formClasses.tapForm}
                        onChange={handleChangeTab}
                        aria-label="disabled tabs example">

                        <Tab label={t("general")}  {...a11yProps(0)} className={errorTab.general ? formClasses.errorTab : ''} />
                        <Tab label={t("add-ons")}  {...a11yProps(1)} className={errorTab.addsOns ? formClasses.errorTab : ''} />

                    </Tabs>
                </Grid>

                <TabPanel value={currentTab} index={0}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-roles"
                                ChipProps={{ color: 'secondary', size: 'small' }}
                                value={eventState.role}
                                onChange={handleAutoCompleteChange('role')}
                                options={roles.filter((role: any) => role.code > 3)}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField required  {...params} label={t("roles")} error={errorForm.role} variant="filled" fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.role}>{msgForm.role}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-country"
                                value={eventState.country}
                                onChange={handleAutoCompleteChange('country')}
                                options={countries}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField required {...params} error={errorForm.country} helperText={msgForm.country} label={t("country")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-languages"
                                value={languages.filter((language: any) => eventState.languages.includes(language.id))}
                                onChange={handleMultiAutoCompleteChange('languages')}
                                options={languages}
                                multiple
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField required {...params} label={t("languages")} error={errorForm.languages} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.languages}>{msgForm.languages}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-status"
                                disableClearable
                                value={status.find((stt: any) => stt.id == eventState.status)}
                                onChange={handleNumberChange('status')}
                                options={status}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => <TextField {...params} label={t("status")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.status}>{msgForm.status}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-visibility"
                                disableClearable
                                value={visibility.find((vsb: any) => vsb.id == eventState.visibility)}
                                onChange={handleNumberChange('visibility')}
                                options={visibility}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => <TextField {...params} label={t("visibility")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.visibility}>{msgForm.visibility}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t("image")}
                                fullWidth
                                margin="normal"
                                type="file"
                                error={errorForm.file_image}
                                helperText={msgForm.file_image}
                                onChange={handleInputChangeFile}
                                InputLabelProps={{ shrink: true }}
                                variant="filled" />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-types"
                                disableClearable
                                value={types.find((type: any) => type.id == eventState.type)}
                                onChange={handleNumberChange('type')}
                                options={types}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField {...params} label={t("type")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.type}>{msgForm.type}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <DatePicker
                                ampm={false}
                                disableToolbar
                                required
                                variant="inline"
                                format="dd/MM/yyyy hh:mm a"
                                label={t("start-date")}
                                InputLabelProps={{ shrink: true, }}
                                fullWidth
                                value={date_ini}
                                onChange={(newDate: any) => setDateIni(newDate)}
                            />
                            <FormHelperText error={errorForm.date_ini}>{msgForm.date_ini}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <DatePicker
                                ampm={false}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy hh:mm a"
                                label={t("end-date")}
                                minDate={date_ini}
                                InputLabelProps={{ shrink: true, }}
                                fullWidth
                                value={date_end}
                                onChange={(newDate: any) => setDateEnd(newDate)}
                            />
                            <FormHelperText error={errorForm.date_end}>{msgForm.date_end}</FormHelperText>
                        </Grid>
                    </Grid>
                </TabPanel>


                <TabPanel value={currentTab} index={1}>
                    <Grid container spacing={2} >

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t("link-url")}
                                fullWidth
                                margin="normal"
                                value={eventState.link}
                                error={errorForm.link}
                                helperText={msgForm.link}
                                onChange={handleInputChange('link')}
                                InputLabelProps={{ shrink: true }}
                                variant="filled" />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t("admin.events.text-button")}
                                fullWidth
                                margin="normal"
                                value={eventState.text_button}
                                onChange={handleInputChange('text_button')}
                                InputLabelProps={{ shrink: true }}
                                variant="filled" />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-method"
                                value={methods.find((method: any) => method.id == eventState.method)}
                                onChange={handleNumberChange('method')}
                                options={methods}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        error={errorForm.country}
                                        helperText={msgForm.country}
                                        label={t("admin.events.design-mode")}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>

                        {eventState.method === 0 &&
                            <>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label={t("description")}
                                        fullWidth
                                        margin="normal"
                                        value={eventState.description}
                                        error={errorForm.description}
                                        helperText={msgForm.description}
                                        onChange={handleInputChange('description')}
                                        InputLabelProps={{ shrink: true }}
                                        variant="filled" />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        label={t('speaker')}
                                        fullWidth
                                        margin="normal"
                                        value={eventState.speaker}
                                        error={errorForm.speaker}
                                        helperText={msgForm.speaker}
                                        onChange={handleInputChange('speaker')}
                                        InputLabelProps={{ shrink: true }}
                                        variant="filled" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="body2" color="textSecondary" >{t("background-color")}</Typography>
                                    <div className="input-color">
                                        <InputColor
                                            initialValue={eventState.background_color.hex}
                                            onChange={(event: any) => { setEventState({ ...eventState, background_color: event }) }}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="body2" color="textSecondary">{t("text-color")}</Typography>
                                    <div className="input-color">
                                        <InputColor
                                            initialValue={eventState.text_color.hex}
                                            onChange={(event: any) => { setEventState({ ...eventState, text_color: event }) }}
                                        />
                                    </div>
                                </Grid>
                            </>
                        }

                    </Grid>
                </TabPanel>
            </DialogContent>


            <DialogActions className={!isValidForm && Object.keys(msgForm).length > 0 ? formClasses.errorDialogActions : ''}>
                <CancelButton onClick={handleCloseModalEvent}>
                    {t("cancel")}
                </CancelButton>
                <SaveButton autoFocus id="btn-handlelogin"
                    onClick={() => handleSubmit()}>
                    {eventState.id !== null ? t("save") : t("admin.events.create-event")}
                </SaveButton>
            </DialogActions>
        </Dialog>
    );
}

export default EventsModal;