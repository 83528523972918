// Material UI
import { Box, Typography, makeStyles, Button } from '@material-ui/core';

//Custom Components
import TooltipCards from '../typography/TooltipCards';

// Libraries
import moment from 'moment';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Styles
import { BookmarkRounded } from '@material-ui/icons';
import VipStyles from '../../../styles/components/common/VipStyles';

// Custom Style
const CardsFeedStyle = makeStyles(theme => ({
    //Styles cards feed
    headerFeed: {
        backgroundColor: theme.palette.primary.main,
        height: 22,
        padding: 0,
        paddingLeft: 16,
    },
    titleFeed: {
        color: theme.palette.common.white,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 500,
        maxHeight: 16,
    },
    contentFeed: {
        padding: '0px 0px 8px 0px',
        '&:last-child': {
            paddingBottom: 8,
        },
    },
    containerFeed: {
        padding: ' 8px 16px',
        borderColor: theme.palette.primary.main,
        width: '100%',
    },
    feedResponsive: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 24,
        },
    },
    iconFeed: {
        fill: theme.palette.primary.main
    },
}));

// Types
type CardsFeedProps = {
    resources: any,
    history: any
}

const CardsFeed = ({ resources, history }: CardsFeedProps) => {
    //Custom Hook
    const isMobile = useScreenWidth(960);
    // Styles
    const classes = CardsFeedStyle();
    const vipclasses = VipStyles();

    return (
        <Box display="flex" justifyContent="space-between" gridGap={22} flexDirection={isMobile ? 'column' : 'row'}>
            {resources.map((resource: any) => (
                <Button key={resource.id} variant='outlined' onClick={() => history.push(`biblioteca/${resource.id}`)} classes={{ root: classes.containerFeed }}>
                    <BookmarkRounded fontSize='large' classes={{ root: (resource.visibility === 1) && true ? vipclasses.icon : classes.iconFeed }} />
                    <Box pl={2} textAlign="left" minHeight={74} display='flex' flexDirection='column' justifyContent='center' width='100%'>
                        <TooltipCards titleCard={resource.title} classNameCustom='containerFeed' />
                        <Typography variant="caption" color="textSecondary">{`${moment(resource.created_at).fromNow()}`}</Typography>
                    </Box>
                </Button>
            ))}
        </Box>
    )
}

export default CardsFeed
