
import { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

// Material UI
import { Drawer, AppBar, Hidden, Box, ButtonGroup, Button, Typography, Fab, Grid } from '@material-ui/core';

// Custom components
import HiddenDesktop from '../../../common/HiddenDesktop';

// Redux
import { IRootState } from '../../../../redux/types';

// Styles
import AdminMobileStyles from '../../../../styles/components/layouts/navigation/admin/AdminMobileStyles';

import { useTranslation } from "react-i18next";
import { AddRounded, BookmarkOutlined, BusinessRounded, CloseRounded, EventRounded, FlashOnRounded, ForumRounded, HomeRounded, LocalActivityRounded, MoreHorizRounded, PeopleAltRounded, SchoolRounded, ViewCarouselRounded, WifiTetheringRounded } from '@material-ui/icons';

function AdminMobileMenu({ action }: any) {
    let history = useHistory();
    const classes = AdminMobileStyles();

    // Translation
    const { t } = useTranslation(["global"]);

    // Redux Hooks
    const { user } = useSelector((state: IRootState) => state.auth);

    // Handle grid menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = 'primary-search-account-menu';

    return (
        <HiddenDesktop>
            <AppBar position="fixed" className={classes.appBarMobile}>
                <ButtonGroup classes={{ root: classes.menuContent, grouped: classes.menuWidthLess }}>
                    <Button fullWidth startIcon={<HomeRounded color={window.location.pathname === '/escritorio' ? 'secondary' : 'primary'} />} onClick={() => history.push('/escritorio')}
                        classes={{ label: classes.menuItem, startIcon: classes.menuIcon }}>
                        <Typography variant="caption" color={window.location.pathname === '/escritorio' ? 'secondary' : 'initial'}>Inicio</Typography>
                    </Button>
                    {![3, 8, 9].includes(user.role_id) &&
                        <Button fullWidth startIcon={<SchoolRounded color={window.location.pathname === '/admin/cursos' ? 'secondary' : 'primary'} />} onClick={() => history.push('/admin/cursos')}
                            classes={{ label: classes.menuItem, startIcon: classes.menuIcon }}>
                            <Typography variant="caption" color={window.location.pathname === '/admin/cursos' ? 'secondary' : 'initial'}>{t("academy")}</Typography>
                        </Button>
                    }
                    {![3, 8, 9].includes(user.role_id) &&
                        <Button fullWidth startIcon={<BookmarkOutlined color={window.location.pathname === '/admin/biblioteca' ? 'secondary' : 'primary'} />} onClick={() => history.push('/admin/biblioteca')}
                            classes={{ label: classes.menuItem, startIcon: classes.menuIcon }}>
                            <Typography variant="caption" color={window.location.pathname === '/admin/biblioteca' ? 'secondary' : 'initial'}>{t("admin.library.library")}</Typography>
                        </Button>
                    }
                    <Button fullWidth startIcon={<MoreHorizRounded color={['/escritorio', '/admin/cursos', '/admin/biblioteca'].includes(window.location.pathname) ? 'primary' : 'secondary'} />}
                        onClick={handleProfileMenuOpen}
                        classes={{ label: classes.menuItem, startIcon: classes.menuIcon }}>
                        <Typography
                            variant="caption"
                            color={['/escritorio', '/admin/cursos', '/admin/biblioteca'].includes(window.location.pathname) ? 'initial' : 'secondary'}>
                            {t("see-more")}
                        </Typography>
                    </Button>
                </ButtonGroup>


                <Fab className={classes.fabIcon} onClick={action}>
                    <AddRounded htmlColor="white" aria-label="add" />
                </Fab>

            </AppBar>

            <Box display="flex">
                {isMenuOpen &&
                    <>
                        <Hidden mdUp implementation="css">

                            <Drawer
                                variant="temporary"
                                anchor='bottom'
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                onClick={handleMenuClose}
                                ModalProps={{ keepMounted: true, }}
                                classes={{ paper: classes.optionsMenuMobile }}>

                                <Grid container justify="flex-end" spacing={2} className={classes.optionsMenuContent}>
                                    <Grid item xs={4}>
                                        <Button fullWidth startIcon={<PeopleAltRounded color={window.location.pathname === '/admin/usuarios' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/usuarios'); handleMenuClose() }}
                                            classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                            <Typography variant="caption" color={window.location.pathname === '/admin/usuarios' ? 'secondary' : 'initial'}>{t("admin.people.people")}</Typography>
                                        </Button>
                                    </Grid>
                                    {![3, 8, 9].includes(user.role_id) &&
                                        <>
                                            <Grid item xs={4}>
                                                <Button fullWidth startIcon={<LocalActivityRounded color={window.location.pathname === '/admin/ofertas' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/ofertas'); handleMenuClose() }}
                                                    classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                                    <Typography variant="caption" color={window.location.pathname === '/admin/ofertas' ? 'secondary' : 'initial'}>{t("offers")}</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button fullWidth startIcon={<EventRounded color={window.location.pathname === '/admin/events' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/events'); handleMenuClose() }}
                                                    classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                                    <Typography variant="caption" color={window.location.pathname === '/admin/events' ? 'secondary' : 'initial'}>{t("events")}</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button fullWidth startIcon={<ForumRounded color={window.location.pathname === '/admin/noticias' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/noticias'); handleMenuClose() }}
                                                    classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                                    <Typography variant="caption" color={window.location.pathname === '/admin/noticias' ? 'secondary' : 'initial'}>{t("news")}</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button fullWidth startIcon={<FlashOnRounded color={window.location.pathname === '/admin/novedades' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/novedades'); handleMenuClose() }}
                                                    classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                                    <Typography variant="caption" color={window.location.pathname === '/admin/novedades' ? 'secondary' : 'initial'}>{t("updates")}</Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button fullWidth startIcon={<ViewCarouselRounded color={window.location.pathname === '/admin/sliders' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/sliders'); handleMenuClose() }}
                                                    classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                                    <Typography variant="caption" color={window.location.pathname === '/admin/sliders' ? 'secondary' : 'initial'}>{t("slider")}</Typography>
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                    {[5, 2, 1].includes(user.role_id) &&
                                        <Grid item xs={4}>
                                        <Button fullWidth startIcon={<WifiTetheringRounded color={window.location.pathname === '/admin/seminarios' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/seminarios'); handleMenuClose() }}
                                                classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                                <Typography variant="caption" color={window.location.pathname === '/admin/seminarios' ? 'secondary' : 'initial'}>{t("seminars")}</Typography>
                                            </Button>
                                        </Grid>
                                    }
                                    {user.role_id === 1 &&
                                        <Grid item xs={4}>
                                            <Button fullWidth startIcon={<BusinessRounded color={window.location.pathname === '/admin/marcas' ? 'secondary' : 'primary'} />} onClick={() => { history.push('/admin/marcas'); handleMenuClose() }}
                                                classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                                <Typography variant="caption" color={window.location.pathname === '/admin/marcas' ? 'secondary' : 'initial'}>{t("brands")}</Typography>
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs={4}>
                                        <Button fullWidth startIcon={<CloseRounded />}
                                            aria-controls={menuId}
                                            aria-haspopup="true"
                                            onClick={handleMenuClose}
                                            classes={{ label: classes.optionItem, startIcon: classes.menuIcon }}>
                                            <Typography variant="caption" color="textSecondary">{t("back")}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Drawer>
                        </Hidden>
                    </>
                }
            </Box>
        </HiddenDesktop>
    );
}

export default AdminMobileMenu;
