import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CloseRounded } from "@material-ui/icons";
import { CancelButton, SaveButton } from "./index";
import { storeLocation } from "../../../redux/actions/countries";
import FormsStyles from "../../../styles/components/common/forms/FormsStyles";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';


interface ILocation {
    input: string;
    name: string;
    province: any;
}

interface IPostalcode {
    id: number,
    name: string,
    province_id: number
}

const filter = createFilterOptions();

function PostalcodeAutocomplete({ value, province, handleInputChange }: any) {
    const formClasses = FormsStyles();

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    const dispatch = useDispatch();
    const [open, toggleOpen] = useState(false);
    const { postalcodes } = useSelector((state: IRootState) => state.country);
    const [createLocation, setCreateLocation] = useState<ILocation>({
        input: "",
        name: "",
        province: null,
    });

    // Handle functions
    const handleCloseModalLocations = () => {
        setCreateLocation({
            input: "",
            name: "",
            province: null,
        });

        toggleOpen(false);
    };

    const handleSubmitCreteLocation = (event: any) => {
        event.preventDefault();
        dispatch(storeLocation(createLocation));

        handleCloseModalLocations();
    };

    const handleAutoCompleteChange = (postalcode: IPostalcode | null) => {
        handleInputChange('postalcode', postalcode);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseModalLocations}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "sm"}
                className={formClasses.containerForm}
            >
                <form onSubmit={handleSubmitCreteLocation}>
                    <DialogTitle id="form-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t("add")}{" "}
                                {createLocation.input === "town" ? t("town") : t("postal-code")}
                            </Typography>
                            <Button
                                onClick={handleCloseModalLocations}
                                color="primary"
                                variant="text"
                            >
                                <CloseRounded />
                            </Button>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={formClasses.bodyForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DialogContentText>
                                    No encontraste lo que buscabas, Agregalo!
                                </DialogContentText>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    variant="filled"
                                    id="name"
                                    value={createLocation.name}
                                    onChange={(event) =>
                                        setCreateLocation({
                                            ...createLocation,
                                            name: event.target.value,
                                        })
                                    }
                                    label={
                                        createLocation.input === "town"
                                            ? t("town")
                                            : t("postal-code")
                                    }
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <CancelButton onClick={handleCloseModalLocations} color="primary">
                            {t("cancel")}
                        </CancelButton>

                        <SaveButton type="submit" color="primary">
                            {t("add")}
                        </SaveButton>
                    </DialogActions>
                </form>
            </Dialog>

            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setCreateLocation({
                                input: "postalcode",
                                name: newValue,
                                province: province,
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setCreateLocation({
                            input: "postalcode",
                            name: newValue.inputValue,
                            province: province,
                        });
                    } else {
                        handleAutoCompleteChange(newValue)
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== "") {
                        filtered.push({
                            inputValue: params.inputValue,
                            code: `Agregar "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                id="combo-box-postalcodes"
                options={province !== null ? postalcodes.filter((postalcode: any) => postalcode.province_id === province.id) : []}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.id !== undefined ? option.code : "";
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.code}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("postal-code")}
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </>
    )
}

export default PostalcodeAutocomplete;


