import { makeStyles, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => createStyles({
    container:{
        maxWidth: 'fit-content',
        minWidth: "max-content",
    },
    input:{
        '& .MuiInputBase-root':{
            border:'none',
            padding: '0px',
            color: theme.palette.primary.main,
        },
        '&.admin .MuiInputBase-root':{
            width:'179px',
            paddingRight:'0px !important',
        },
        '&.client .MuiInputBase-root':{
            width:'auto',
            minWidth: 170,
            paddingRight:'28px !important',
        },
        '& .MuiAutocomplete-clearIndicator':{
            marginRight:'-10px'
        },
        '& .MuiAutocomplete-endAdornment svg':{
            color: theme.palette.primary.main
        },
        '& .MuiFormControl-root':{
            marginBottom: '0px'
        }
    },
    [theme.breakpoints.down("xs")]:{
        input: {
            '&.admin .MuiInputBase-root': {
                width: '70px',
            },
        },
        popperResp:{
            width: '180px !important',
            left: '-16px !important'
        }
    },
    popperResp:{
        width: 'min-content !important',
    },
    icon:{
        color: theme.palette.primary.main
    },
    // Breadcumb
    Containerbreadcumb: {
        padding: 5,
        borderBottom: '1px solid ' + theme.palette.grey[100],
        '&  > svg':{
            position: 'relative',
            top: '5px',
            right: '4px',
        },
        '& > p':{
            padding: '5px'
        }
    },
    buttonURL: {
        padding: '2px 8px',
        marginLeft: 16,
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main,
        minWidth: 'auto',
        borderRadius: 8,
        fontSize: theme.typography.caption.fontSize,
        '& svg': {
            color: theme.palette.info.main,
        },
        '& .MuiButton-label': {
            justifyContent: 'center',
        },
        '&:hover, &:active': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.info.main,
            '& svg': {
                color: theme.palette.common.white,
            }
        }
    },
    
}))