// React
import {
    useState,
    useEffect,
    ChangeEvent,
    forwardRef,
    ReactElement,
    Ref,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    DialogTitle,
    DialogActions,
    Grid,
    FormHelperText,
    Slide,
    Tab,
    Tabs,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
} from '@material-ui/core'
import {
    AddCircle,
    BackupRounded,
    CloseRounded,
    Delete,
} from '@material-ui/icons/'
import { TransitionProps } from '@material-ui/core/transitions'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DateTimePicker } from '@material-ui/pickers'
import moment, { Moment } from 'moment'

// Custom components
import { CancelButton, DatePicker, SaveButton } from '../common/forms'
import TabPanel from '../common/admin/TabPanel'
import InputCountry from '../common/admin/modal/InputCountry'

// Redux Actions
import { createSlider, toggleFormSlider } from '../../redux/actions/sliders'
import {
    getCountries,
    getFilteredCountries,
} from '../../redux/actions/countries'
import { getLanguages } from '../../redux/actions/languages'
import { getUserbrands } from '../../redux/actions/brands'
import { getRoles } from '../../redux/actions/roles'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import Dropzone from 'react-dropzone-uploader'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

// Types
interface ISlider {
    id: null | number
    brand: null | any
    line: null | any
    countries: number[]
    languages: any
    title: string
    internaltype: number
    type: null | number
    value: null | string
    file_image: null | any
    order: number
    role: null | any
    status: number
    visibility: number
}

type SliderModalProps = {
    type: number
    position?: number
    title: string
}

type FormElement = ChangeEvent<HTMLInputElement>

type TitleLanguages = {
    id: string,
    title: string
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

function SliderModal({ type, position, title }: SliderModalProps) {
    // Translate
    const { t } = useTranslation(['global'])
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const formClasses = FormsStyles()

    /* Global Variables */
    const types = [
        { id: 0, name: t('link') },
        { id: 1, name: t('more-info') },
    ]
    const status = [
        { id: 0, name: t('active') },
        { id: 1, name: t('inactive') },
    ]
    const visibility = [
        { id: 0, name: t('close') },
        { id: 1, name: 'VIP' },
    ]

    //Redux Hooks
    const dispatch = useDispatch()
    const { sliders, slider_selected, showForm } = useSelector(
        (state: IRootState) => state.slider
    )
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { userBrands } = useSelector((state: IRootState) => state.brand)
    const { roles } = useSelector((state: IRootState) => state.role)
    const { countries } = useSelector((state: IRootState) => state.country)
    const { languages } = useSelector((state: IRootState) => state.language)
    const { showModal } = useSelector((state: IRootState) => state.modal)
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector(
        (state: IRootState) => state.validator
    )

    // Slider State
    const [sliderState, setSliderState] = useState<ISlider>({
        id: null,
        brand: null,
        line: null,
        countries: [],
        languages: [],
        title: '',
        internaltype: type,
        type: 0,
        value: '',
        file_image: null,
        order: position !== undefined ? position : 1,
        role: null,
        status: 0,
        visibility: 0,
    })
    // Component State
    const [date_ini, setDateIni] = useState<Moment | null>(null)
    const [date_end, setDateEnd] = useState<Moment | null>(null)
    const [titleLanguages, setTitleLanguages] = useState<TitleLanguages[]>([])
    //Tab State
    const [currentTab, setCurrentTab] = useState(0)

    useEffect(() => {
        if (!roles.length) {
            dispatch(getRoles())
        }
        if (!languages.length) dispatch(getLanguages())

        if (slider_selected != null) {
            setSliderState({
                id: slider_selected.id,
                brand: slider_selected.brand,
                line: slider_selected.line,
                countries: slider_selected.countries.map(
                    (country: any) => country.id
                ),
                languages: slider_selected.languages.map(
                    (language: any) => language.id
                ),
                title: slider_selected.title,
                internaltype: slider_selected.internaltype,
                type: slider_selected.type,
                value: slider_selected.value,
                file_image: null,
                order: slider_selected.order,
                role: slider_selected.role,
                status: slider_selected.status,
                visibility: slider_selected.visibility,
            })

            slider_selected.date_ini != null &&
                setDateIni(moment(slider_selected.date_ini))
            slider_selected.date_end != null &&
                setDateEnd(moment(slider_selected.date_end))

            if (slider_selected.internaltype === 2) {
                let tmpTitleLanguages: TitleLanguages[] = slider_selected.languages.map((language: any) => {
                    return {
                        id: language.id,
                        title: language.pivot.title,
                    }
                })
                setTitleLanguages(tmpTitleLanguages);
            }
        }
    }, [])

    useEffect(() => {
        if (authUser != null) {
            if (authUser.role_id == 1) dispatch(getCountries())
            else dispatch(getFilteredCountries())
        }
    }, [authUser])

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator())
            let formData = new FormData()
            if (sliderState.id == null) formData.append('id', '')
            else formData.append('id', sliderState.id as any)
            formData.append('title', sliderState.title)
            if (sliderState.brand != null)
                formData.append('brand', JSON.stringify(sliderState.brand))
            if (sliderState.line != null)
                formData.append('line', JSON.stringify(sliderState.line))

            if (sliderState.role != null)
                formData.append('role', JSON.stringify(sliderState.role))
            formData.append('countries', JSON.stringify(sliderState.countries))
            formData.append('languages', JSON.stringify(sliderState.languages))
            if (type === 2)
                formData.append(
                    'titleLanguages',
                    JSON.stringify(titleLanguages)
                )
            formData.append('languages', JSON.stringify(sliderState.languages))
            if (sliderState.internaltype !== null)
                formData.append('internaltype', sliderState.internaltype as any)
            if (sliderState.type == null) formData.append('type', '')
            else formData.append('type', sliderState.type as any)
            if (sliderState.value == null) formData.append('value', '')
            else formData.append('value', sliderState.value as any)
            if (sliderState.file_image == null) formData.append('file', '')
            else formData.append('file', sliderState.file_image)
            if (date_ini == null) formData.append('date_ini', '')
            else
                formData.append(
                    'date_ini',
                    moment(date_ini).format('YYYY-MM-DD HH:mm:ss')
                )
            if (date_end == null) formData.append('date_end', '')
            else
                formData.append(
                    'date_end',
                    moment(date_end).format('YYYY-MM-DD HH:mm:ss')
                )
            formData.append('order', sliderState.order as any)
            formData.append('status', sliderState.status as any)
            formData.append('visibility', sliderState.visibility as any)
            dispatch(createSlider(formData))
        }
    }, [isValidForm])

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue)
    }

    const handleAutoCompleteChange =
        (inputName: string) => (event: any, value: any) => {
            setSliderState({ ...sliderState, [inputName]: value })
        }

    const handleMultiAutoCompleteChange =
        (inputName: string) => async (event: any, value: any) => {
            let optionsMulti: number[] = []
            if (inputName === 'languages') {
                let tmpTitleLanguages = []
                if (value !== null) {
                    for (let i = 0; i < value.length; i++) {
                        tmpTitleLanguages.push({
                            id: value[i].id,
                            title: '',
                        })
                        optionsMulti.push(value[i].id)
                    }
                }
                setSliderState({ ...sliderState, [inputName]: optionsMulti })
                setTitleLanguages(tmpTitleLanguages)
            } else {
                if (value !== null) {
                    for (let i = 0; i < value.length; i++) {
                        optionsMulti.push(value[i].id)
                    }
                }
                setSliderState({ ...sliderState, [inputName]: optionsMulti })
            }
        }

    const handleNumberChange =
        (inputName: string) => (event: any, value: any) => {
            if (value != null)
                setSliderState({ ...sliderState, [inputName]: value.id })
            else setSliderState({ ...sliderState, [inputName]: value })
        }

    const handleInputChangeFile = (event: any) => {
        const value = event.target.files[0]
        setSliderState({ ...sliderState, file_image: value })
    }
    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value
        setSliderState({ ...sliderState, [inputName]: value })
    }

    const handleState = async (inputName: string, value: any) => {
        if (type === 1 && value !== null && value.length > 0) {
            await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/sliders/countryorder/${value[0]}`
                )
                .then((response: any) => {
                    setSliderState({
                        ...sliderState,
                        [inputName]: value,
                        order: response.data.order,
                    })
                })
        } else setSliderState({ ...sliderState, [inputName]: value })
    }

    const handleTitleLanguageChange =
        (index: number) => (event: FormElement) => {
            let tmpTitleLanguages = [...titleLanguages]
            tmpTitleLanguages[index].title = event.target.value
            setTitleLanguages(tmpTitleLanguages)
        }

    const handleSubmit = () => {
        if (type === 1) {
            dispatch(
                formValidator({
                    title: [sliderState.title, 'required|max:50|min:2'],
                    brand: [sliderState.brand, 'notempty'],
                    countries: [sliderState.countries, 'notempty', 'general'],
                    languages: [sliderState.languages, 'notempty', 'general'],
                    role: [sliderState.role, 'required', 'addOn'],
                    order: [sliderState.order, 'numeric', 'general'],
                    file_image: [sliderState.file_image, 'required', 'general'],
                })
            )
        } else if (type === 2) {
            dispatch(
                formValidator({
                    title: [sliderState.title, 'required|max:50|min:2'],
                    countries: [sliderState.countries, 'notempty', 'general'],
                    languages: [sliderState.languages, 'notempty', 'general'],
                    order: [sliderState.order, 'numeric', 'general'],
                    titleLanguages: [titleLanguages, 'notempty', 'addOn'],
                    file_image: [sliderState.file_image, 'required', 'general'],
                })
            )
        } else if (type === 3) {
            dispatch(
                formValidator({
                    title: [sliderState.title, 'required|max:50|min:2'],
                    countries: [sliderState.countries, 'notempty', 'general'],
                    languages: [sliderState.languages, 'notempty', 'general'],
                    role: [sliderState.role, 'required', 'addOn'],
                    order: [sliderState.order, 'numeric', 'general'],
                    file_image: [sliderState.file_image, 'required', 'general'],
                })
            )
        }
    }

    const handleCloseModalSlider = () => {
        dispatch(resetFormValidator())
        dispatch(toggleFormSlider())
    }

    return (
        <Dialog
            open={showForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            TransitionComponent={Transition}
            onClose={handleCloseModalSlider}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        {sliderState.id !== null ? t('edit') : t('create')}{' '}
                        {title}
                    </Typography>
                    <Button
                        onClick={handleCloseModalSlider}
                        color="primary"
                        variant="text"
                    >
                        <CloseRounded />
                    </Button>
                </Grid>
            </DialogTitle>

            <DialogContent className={formClasses.bodyForm}>
                <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} md={sliderState.brand != null ? 6 : 8}>
                        <TextField
                            label={t('title')}
                            fullWidth
                            required
                            margin="normal"
                            value={sliderState.title}
                            error={errorForm.title}
                            helperText={msgForm.title}
                            onChange={handleInputChange('title')}
                            InputLabelProps={{ shrink: true }}
                            variant="filled"
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={sliderState.brand != null ? 6 : 12}
                        md={sliderState.brand != null ? 3 : 4}
                    >
                        <Autocomplete
                            id="combo-box-brands"
                            value={sliderState.brand}
                            onChange={handleAutoCompleteChange('brand')}
                            options={userBrands}
                            getOptionLabel={(option: any) =>
                                option.id !== undefined ? option.name : ''
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('brand')}
                                    error={errorForm.brand}
                                    variant="filled"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                        <FormHelperText error={errorForm.brand}>
                            {msgForm.brand}
                        </FormHelperText>
                    </Grid>

                    {sliderState.brand != null && (
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="combo-box-lines"
                                value={sliderState.line}
                                onChange={handleAutoCompleteChange('line')}
                                options={sliderState.brand.active_lines}
                                getOptionLabel={(option: any) =>
                                    option.id !== undefined ? option.name : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('lines')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        indicatorColor="secondary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        className={formClasses.tapForm}
                        aria-label="disabled tabs example"
                    >
                        <Tab
                            label={t('general')}
                            {...a11yProps(0)}
                            className={
                                errorTab.general ? formClasses.errorTab : ''
                            }
                        />
                        {type !== 3 ? (
                            <Tab
                                label={
                                    type === 2
                                        ? t('add-ons') + '-' + t('language')
                                        : t('add-ons')
                                }
                                {...a11yProps(1)}
                                className={
                                    errorTab.addOn ? formClasses.errorTab : ''
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </Tabs>
                </Grid>

                <TabPanel value={currentTab} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={type === 2 ? 3 : 4}>
                            <Autocomplete
                                id="combo-box-roles"
                                ChipProps={{ color: 'secondary' }}
                                value={sliderState.role}
                                onChange={handleAutoCompleteChange('role')}
                                options={roles.filter(
                                    (role: any) => role.code > 3
                                )}
                                getOptionLabel={(option: any) =>
                                    option.id !== undefined ? option.name : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        required={type !== 2}
                                        {...params}
                                        error={errorForm.role}
                                        label={t('rol')}
                                        variant="filled"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.role}>
                                {msgForm.role}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={type === 2 ? 3 : 4}>
                            <InputCountry
                                value={sliderState.countries}
                                handleInputChange={handleState}
                            />
                        </Grid>

                        {type !== 2 && (
                            <Grid item xs={12} sm={6} md={type === 2 ? 3 : 4}>
                                <Autocomplete
                                    id="combo-box-languages"
                                    value={languages.filter((language: any) =>
                                        sliderState.languages.includes(
                                            language.id
                                        )
                                    )}
                                    onChange={handleMultiAutoCompleteChange(
                                        'languages'
                                    )}
                                    options={languages}
                                    multiple
                                    getOptionLabel={(option: any) =>
                                        option.id !== undefined
                                            ? option.name
                                            : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('language')}
                                            error={errorForm.languages}
                                            variant="filled"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                                <FormHelperText error={errorForm.languages}>
                                    {msgForm.languages}
                                </FormHelperText>
                            </Grid>
                        )}

                        <Grid item xs={12} sm={6} md={type === 2 ? 3 : 4}>
                            <Autocomplete
                                id="combo-box-status"
                                disableClearable
                                value={status.find(
                                    (stt: any) => stt.id == sliderState.status
                                )}
                                onChange={handleNumberChange('status')}
                                options={status}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('status')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.status}>
                                {msgForm.status}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={type === 2 ? 3 : 4}>
                            <Autocomplete
                                id="combo-box-visibility"
                                disableClearable
                                value={visibility.find(
                                    (vsb: any) =>
                                        vsb.id == sliderState.visibility
                                )}
                                onChange={handleNumberChange('visibility')}
                                options={visibility}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('visibility')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.visibility}>
                                {msgForm.visibility}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={t('position')}
                                type="number"
                                fullWidth
                                required
                                disabled={type !== 1}
                                InputProps={{ inputProps: {} }}
                                InputLabelProps={{ shrink: true }}
                                value={sliderState.order}
                                error={errorForm.order}
                                helperText={msgForm.order}
                                onChange={handleInputChange('order')}
                                variant="filled"
                            />
                            <FormHelperText error={errorForm.order}>
                                {msgForm.order}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-types"
                                value={types.find(
                                    (type: any) => type.id == sliderState.type
                                )}
                                onChange={handleNumberChange('type')}
                                options={types}
                                getOptionLabel={(option: any) =>
                                    option.id !== undefined ? option.name : ''
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('action')}
                                        variant="filled"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                            <FormHelperText error={errorForm.type}>
                                {msgForm.type}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={sliderState.type == 0 ? 'URL' : 'Email'}
                                fullWidth
                                margin="normal"
                                value={sliderState.value}
                                error={errorForm.value}
                                helperText={msgForm.value}
                                onChange={handleInputChange('value')}
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                            />
                        </Grid>

                        <Grid item xs={12} md={type === 2 ? 12 : 4}>
                            <TextField
                                label={t('image-url')}
                                fullWidth
                                margin="normal"
                                type="file"
                                error={errorForm.file_image}
                                helperText={msgForm.file_image}
                                onChange={handleInputChangeFile}
                                InputLabelProps={{ shrink: true }}
                                variant="filled"
                            />
                            {/* ############################ Ejemplar para implementar el dorpzone */}
                            {slider_selected !== null &&
                                slider_selected.image !== null &&
                                sliderState.file_image === null && (
                                    <Box
                                        p={1}
                                        display="grid"
                                        justifyContent="center"
                                        my={2}
                                        borderRadius={8}
                                        gridGap={8}
                                        style={{ backgroundColor: '#FAFAFA' }}
                                    >
                                        <Typography variant="body2">
                                            {t('admin.library.preview') + ':'}
                                        </Typography>
                                        <img
                                            src={slider_selected.image}
                                            width="100%"
                                            height="auto"
                                            alt={slider_selected.title}
                                        />
                                    </Box>
                                )}
                            {/* <Typography variant="body2" color="textSecondary" >{t("image-url") + '*'}</Typography> */}
                            {/* <Dropzone
                                getUploadParams={getUploadParams}
                                maxFiles={1}
                                multiple={false}
                                canCancel={false}
                                classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                inputContent={
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center">
                                        <BackupRounded />
                                        {t("dropzone-text")}
                                    </Box>
                                }
                            /> */}
                            <FormHelperText error={errorForm.fileName}>
                                {msgForm.fileName}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={1}>
                    <Grid container spacing={2}>
                        {type !== 3 && (
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    label={t('start-date')}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={date_ini}
                                    onChange={(newDate: any) =>
                                        setDateIni(newDate)
                                    }
                                />
                            </Grid>
                        )}

                        {type !== 3 && (
                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    label={t('end-date')}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={date_end}
                                    onChange={(newDate: any) =>
                                        setDateEnd(newDate)
                                    }
                                />
                            </Grid>
                        )}

                        {type === 2 && (
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="combo-box-languages"
                                    value={languages.filter((language: any) =>
                                        sliderState.languages.includes(
                                            language.id
                                        )
                                    )}
                                    onChange={handleMultiAutoCompleteChange(
                                        'languages'
                                    )}
                                    options={languages}
                                    multiple
                                    getOptionLabel={(option: any) =>
                                        option.id !== undefined
                                            ? option.name
                                            : ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('language')}
                                            error={errorForm.languages}
                                            variant="filled"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                                <FormHelperText error={errorForm.languages}>
                                    {msgForm.languages}
                                </FormHelperText>
                            </Grid>
                        )}

                        {type === 2 && languages.length && (
                            <Grid item xs={12}>
                                {titleLanguages.map(
                                    (titleLanguage: any, index: number) => (
                                        <Grid container spacing={2} key={titleLanguage.id}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label={t('language')}
                                                    disabled
                                                    fullWidth
                                                    margin="normal"
                                                    value={
                                                        languages.find(
                                                            (language: any) =>
                                                                language.id ===
                                                                titleLanguage.id
                                                        ).name
                                                    }
                                                    variant="filled"
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    label={t('title')}
                                                    fullWidth
                                                    required
                                                    margin="normal"
                                                    value={titleLanguage.title}
                                                    onChange={handleTitleLanguageChange(
                                                        index
                                                    )}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="filled"
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        )}
                    </Grid>
                </TabPanel>
            </DialogContent>

            <DialogActions
                className={
                    !isValidForm && Object.keys(msgForm).length > 0
                        ? formClasses.errorDialogActions
                        : ''
                }
            >
                <CancelButton onClick={handleCloseModalSlider}>
                    {t('cancel')}
                </CancelButton>
                <SaveButton
                    autoFocus
                    id="btn-handlelogin"
                    onClick={() => handleSubmit()}
                >
                    {sliderState.id !== null ? t('save') : t('create')}
                </SaveButton>
            </DialogActions>
        </Dialog>
    )
}

export default SliderModal
