import { put, call, takeLatest } from 'redux-saga/effects';
import { 
    START_GET_INTEGRATIONS, SUCCESS_GET_INTEGRATIONS, ERROR_GET_INTEGRATIONS, 
    START_UPDATE_INTEGRATION, SUCCESS_UPDATE_INTEGRATION, ERROR_UPDATE_INTEGRATION,
    START_SHOW_INTEGRATION, SUCCESS_SHOW_INTEGRATION, ERROR_SHOW_INTEGRATION,
    START_TOGGLE_FORM_INTEGRATIONS, SUCCESS_TOGGLE_FORM_INTEGRATIONS,
} from '../types';
import { apiCall } from '../config/axios';

function* getIntegrations({ payload }) {
    try{
        const response = yield call(apiCall, 'get', `/integrations/index`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_GET_INTEGRATIONS, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_GET_INTEGRATIONS, payload: error.message})
    }
}

function* updateIntegration({ payload }) {
    try{
        const response = yield call(apiCall, 'put',  `/integrations/update/${payload.id}`, payload)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put ({ type: SUCCESS_UPDATE_INTEGRATION, payload:response.data})
    } catch (error){
        yield put ({ type: ERROR_UPDATE_INTEGRATION, payload: error.message})
    }
}

function* showIntegration({ payload }) {
    try {
        const response = yield call(apiCall, 'get', `/integrations/show/${payload}`)
        if (response.data.error)
            throw new Error(response.data.msg);

        yield put({ type: SUCCESS_SHOW_INTEGRATION, payload: response.data })
    } catch (error) {
        yield put({ type: ERROR_SHOW_INTEGRATION, payload: error.message })
    }
}

function* toggleFormIntegrations() {
    yield put ({ type: SUCCESS_TOGGLE_FORM_INTEGRATIONS})
}

export default function* integrations() {
    yield takeLatest(START_GET_INTEGRATIONS, getIntegrations);
    yield takeLatest(START_UPDATE_INTEGRATION, updateIntegration);
    yield takeLatest(START_SHOW_INTEGRATION, showIntegration);
    yield takeLatest(START_TOGGLE_FORM_INTEGRATIONS, toggleFormIntegrations);
}