// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';

// Material UI
import { List, ListItem, ListItemText, Collapse, Typography, Box, Button, Chip } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { ExpandMore, ExpandLess, ArrowDropDownRounded, ArrowRightRounded, FilterListRounded, ArrowBack } from '@material-ui/icons';

// Custom components
import TagFilter from './TagFilter';
import ListItemMenu from '../navigation/ListItemMenu';

// Redux Actions
import { IRootState } from '../../../redux/types';
import { setGlobalFamily, setGlobalBrand, setGlobalLine, setGlobalTag } from '../../../redux/actions/global';

// Translate
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Style
import BrandStyles from '../../../styles/components/layouts/filters/BrandFilterStyles';

function BrandFilter(props: any) {
    // Props
    const { layout } = props;

    // Translation
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const classes = BrandStyles();

    // Location & History
    const { pathname, search } = useLocation();
    const { replace } = useHistory();
    const searchParams = new URLSearchParams(search);

    // Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userFamilies, userBrands } = useSelector((state: IRootState) => state.brand);
    const { globalFamilyId, globalBrandId, globalLineId } = useSelector((state: IRootState) => state.global);

    // Open State
    const [open, setOpen] = useState(true);
    const [expanded, setExpanded] = useState<string[]>(["B" + (localStorage.getItem('globalBrandId') || "null")]);
    const [selected, setSelected] = useState<string[]>(["L" + (localStorage.getItem('globalLineId') || "null")]);

    useEffect(() => {
        if ((pathname === "/novedades" || pathname === "/noticias")) {
            handleGlobalBrand(null);
        } else if (search && (searchParams.get('globalFamilyId') !== null && searchParams.get('globalFamilyId') !== "null")) {
            if (search && (searchParams.get('globalBrandId') !== null && searchParams.get('globalBrandId') !== "null")) {
                let userBrand = userBrands.find((brand: any) => brand.id === Number(searchParams.get('globalBrandId')));
                if (userBrand !== undefined) {
                    if (searchParams.get('globalLineId') !== null && userBrand.active_lines.find((brandline: any) => brandline.id === Number(searchParams.get('globalLineId')))) {
                        handleGlobalFamilyBrandLine(Number(searchParams.get('globalFamilyId')), Number(searchParams.get('globalBrandId')), Number(searchParams.get('globalLineId')));
                    } else {
                        handleGlobalFamilyBrandLine(Number(searchParams.get('globalFamilyId')), Number(searchParams.get('globalBrandId')));
                    }
                } else {
                    handleGlobalFamilyBrandLine(userBrands[0].family_id, userBrands[0].id, null);
                }
            } else {
                handleGlobalFamilyBrandLine(Number(searchParams.get('globalFamilyId')), null, null);
            }
        } else {
            handleGlobalFamilyBrandLine(null, null, null);
        }
    }, []);

    useEffect(() => {
        setExpanded(["B" + globalBrandId]);
        setSelected(["L" + globalLineId]);
    }, [globalBrandId, globalLineId]);

    /* Functions */
    const handleClick = () => {
        setOpen(!open)
    };

    const clearFilters = () => {
        setOpen(false);
        handleGlobalFamily(null);
    };

    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        setExpanded([nodeIds[0]]);
    };

    const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        setSelected(nodeIds);
    };

    const handleGlobalFamily = (familyId: number | null) => {
        localStorage.setItem("globalFamilyId", (familyId === null) ? "null" : familyId.toString());
        dispatch(setGlobalFamily(familyId));
        searchParams.set("globalFamilyId", (familyId === null) ? "null" : familyId.toString())
        handleGlobalBrand(null);
        setExpanded(['Bnull']);
        setSelected(['Lnull']);
        setOpen(true);
    }

    const handleGlobalBrand = (brandId: number | null) => {
        localStorage.setItem("globalLineId", "null");
        dispatch(setGlobalLine(null));
        searchParams.delete("globalLineId")

        localStorage.setItem("globalTag", "null");
        dispatch(setGlobalTag(null));
        searchParams.delete("globalTag")

        localStorage.setItem("globalBrandId", (brandId === null) ? "null" : brandId.toString());
        dispatch(setGlobalBrand(brandId));
        searchParams.set("globalBrandId", (brandId === null) ? "null" : brandId.toString())
        replace(`${pathname}?${searchParams}`);
    }

    const handleGlobalLine = (lineId: number) => {
        localStorage.setItem("globalLineId", lineId.toString());
        dispatch(setGlobalLine(lineId));
        searchParams.set("globalLineId", lineId.toString())
        replace(`${pathname}?${searchParams}`);
    }

    const handleGlobalFamilyBrandLine = (familyId: number | null, brandId: number | null, lineId: number | null = null) => {
        localStorage.setItem("globalFamilyId", (familyId === null) ? "null" : familyId.toString());
        dispatch(setGlobalFamily(familyId));
        searchParams.set("globalFamilyId", (familyId === null) ? "null" : familyId.toString());

        localStorage.setItem("globalBrandId", (brandId === null) ? "null" : brandId.toString());
        dispatch(setGlobalBrand(brandId));
        searchParams.set("globalBrandId", (brandId === null) ? "null" : brandId.toString());

        localStorage.setItem("globalLineId", (lineId === null) ? "null" : lineId.toString());
        dispatch(setGlobalLine(lineId));
        searchParams.set("globalLineId", (lineId === null) ? "null" : lineId.toString());

        replace(`${pathname}?${searchParams}`);
    }

    return (
        <List id="sidebar" component="nav" className={clsx(classes.borderSidenav, layout)}>


            {(globalFamilyId != null) &&
                <ListItem className={clsx(classes.filterClear, layout)}>
                    <FilterListRounded />
                    <Button id="btn-clear-filters" color="inherit" variant="outlined" size="small" className={clsx(classes.buttonClearFilter, layout)} onClick={clearFilters}>
                        Limpiar filtros
                    </Button>
                </ListItem>
            }

            {userFamilies.map((family: any) => (
                <ListItem key={family.id} button onClick={() => handleGlobalFamily(family.id)}
                    className={clsx(classes.mainFamily, (globalFamilyId != null) ? 'small' : '', (globalFamilyId === family.id || globalFamilyId === null) ? family.code : '', layout)}>
                    {isMobile || t(`${family.code}`).length > 16 ?
                        <Typography >{`${t(`${family.code}`).substring(0, 16).toUpperCase()}.`}</Typography>
                        :
                        <Typography >{t(`${family.code}`).toUpperCase()}</Typography>
                    }
                </ListItem>
            ))}

            {layout === 'client' && <TagFilter layout={layout} />}

            {(globalFamilyId != null) &&
                <ListItemMenu button onClick={() => handleClick()} className={layout}>
                    <ListItemText primary={t("brand")} />
                    {open ? <ExpandLess color="primary" fontSize="small" /> : <ExpandMore color="primary" fontSize="small" />}
                </ListItemMenu>
            }


            {(globalFamilyId != null) &&
                <Collapse in={open} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                    <TreeView
                        id='tree-brand-filter'
                        expanded={expanded}
                        selected={selected}
                        defaultCollapseIcon={<ArrowDropDownRounded fontSize='large' />}
                        defaultExpandIcon={<ArrowRightRounded fontSize='large' />}
                        defaultEndIcon={<></>}
                        defaultParentIcon={<ArrowBack />}
                        className={clsx(classes.brandItemFilter, layout)}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}>

                        <TreeItem
                            classes={{
                                root: clsx(classes.rootBrand, layout),
                                expanded: clsx(classes.selectedItem, layout),
                                selected: clsx(classes.selectedItem, layout),
                                content: classes.contentBrand,
                                group: classes.groupItem,
                                label: classes.treeLabel
                            }}
                            nodeId={"Bnull"}
                            key={'without-brand'}
                            onClick={() => handleGlobalBrand(null)}
                            label={<Typography variant="body2" style={{ fontWeight: 500 }}>{t("all")}</Typography>}
                        />

                        {userBrands.filter((brand: any) => brand.familiesIds.includes(globalFamilyId)).map((brand: any, index: number) => (
                            <>
                                {/* Return TreeItem without lines ELSE return with lines */}
                                {brand.active_lines.length === 0 ?
                                    <TreeItem
                                        key={brand.id}
                                        classes={{
                                            root: clsx(classes.rootBrand, layout),
                                            expanded: clsx(classes.selectedItem, layout),
                                            selected: clsx(classes.selectedItem, layout),
                                            content: classes.contentBrand,
                                            group: classes.groupItem,
                                            label: classes.treeLabel
                                        }}
                                        nodeId={"B" + brand.id}
                                        onClick={() => handleGlobalBrand(brand.id)}
                                        label={<Typography variant="body2" style={{ fontWeight: 500 }}>{brand.name}</Typography>} />
                                    :
                                    <TreeItem
                                        key={brand.id}
                                        classes={{
                                            root: clsx(classes.rootBrand, layout),
                                            expanded: clsx(classes.selectedItem, layout),
                                            selected: clsx(classes.selectedItem, layout),
                                            content: classes.contentBrand,
                                            group: classes.groupItem,
                                            label: classes.treeLabel
                                        }}
                                        nodeId={"B" + brand.id}
                                        onClick={() => handleGlobalBrand(brand.id)}
                                        label={<Typography variant="body2" style={{fontWeight: 500}}>{brand.name}</Typography>}>

                                        {/* Buscador de cada marca */}
                                        {/*<Box display="flex" alignItems="center" gridGap={8} maxHeight='28px' justifyContent="center" width="100%">
                                    <ContentSearch small={true} layout={layout} sectionName={t('lines')}/>
                                </Box> */}

                                        {/* Botón para desplegar todas las líneas(orden => buscador - linea 1 - línea 2 - línea 3 - "see-all-lines") */}
                                        {/* <Button variant="text" fullWidth disableRipple
                                    classes={{
                                        root: clsx(classes.buttonSeeMore, layout),
                                        label: clsx(classes.labelSeeMore, layout),
                                    }}
                                    onClick={() => handleClick()}>
                                    <Typography>{t('see-all-lines')}</Typography>
                                </Button>  */}

                                        {/* With Brandlines */}
                                        {brand.active_lines.filter((brandline: any) => authUser.brandlines.includes(brandline.id) || authUser.role_id === 1).map((line: any, index: number) => (
                                            <TreeItem
                                                key={line.id}
                                                onClick={() => handleGlobalLine(line.id)}
                                                classes={{
                                                    root: clsx(classes.rootLine, layout),
                                                    selected: line.id === globalLineId ? clsx(classes.selectedLine, layout) : '',
                                                    label: classes.treeLabel
                                                }}
                                                nodeId={"L" + line.id}
                                                label={
                                                    <Box display="flex" alignItems="center" gridGap={8} pl={1}>
                                                        <Chip classes={{ root: clsx(classes.chipLine, layout),}} />
                                                        <Typography variant="body2">{line.name}</Typography>
                                                    </Box>
                                                }
                                            />
                                        ))}

                                        {/* Without Brandlines */}
                                        {(brand.active_lines.filter((brandline: any) => authUser.brandlines.includes(brandline.id) || authUser.role_id === 1).length > 0) &&
                                            <TreeItem
                                                onClick={() => handleGlobalLine(0)}
                                                classes={{
                                                    root: clsx(classes.rootLine, layout),
                                                    selected: clsx(classes.selectedLine, layout),
                                                    label: classes.treeLabel
                                                }}
                                                nodeId={"L0"}
                                                label={
                                                    <Box display="flex" alignItems="center" gridGap={8} pl={3}>
                                                        <Typography variant="body2">{t("without-line")}</Typography>
                                                    </Box>
                                                }
                                            />
                                        }
                                    </TreeItem>
                                }
                            </>
                        ))}
                    </TreeView>
                </Collapse>
            }

        </List>
    );
}

export default BrandFilter;