// React
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    Typography,
    Box,
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons/'

// Custom components
import InputCountry from '../common/admin/modal/InputCountry'
import {
    LineAutocomplete,
    Dropzone,
    CancelButton,
    SaveButton,
} from '../common/forms'

//Redux Actions
import { IRootState } from '../../redux/types'
import { getFilteredCountries } from '../../redux/actions/countries'
import {
    storeTestimonial,
    updateTestimonial,
    toggleFormTestimonials,
} from '../../redux/actions/testimonials'
import {
    formValidator,
    resetFormValidator,
} from '../../redux/actions/validator'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth'

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles'

// Types
interface ITestimonial {
    id: number
    type: number
    fileName: null | string
    title: null | string
    brand_id: number
    line: any
    countries: any
}

function TestimonialModal({ type }: any) {
    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const formClasses = FormsStyles()

    //Redux Hooks
    const dispatch = useDispatch()
    const { brandSelected } = useSelector((state: IRootState) => state.brand)
    const { testimonialSelected, showForm } = useSelector(
        (state: IRootState) => state.testimonial
    )
    const { countries } = useSelector((state: IRootState) => state.country)
    const { isValidForm, errorForm, msgForm } = useSelector(
        (state: IRootState) => state.validator
    )

    // Testimonial state
    const [testimonial, setTestimonial] = useState<ITestimonial>({
        id: 0,
        type: type,
        title: null,
        fileName: null,
        brand_id: brandSelected.id,
        line: [],
        countries: [],
    })

    useEffect(() => {
        if (!countries.length) dispatch(getFilteredCountries())
    }, [])

    useEffect(() => {
        if (
            testimonialSelected !== null &&
            testimonialSelected.id !== testimonial.id
        ) {
            let line: any = []
            if (testimonialSelected.line_id !== null)
                line = brandSelected.active_lines.find(
                    (line: any) => line.id === testimonialSelected.line_id
                )

            setTestimonial({
                id: testimonialSelected.id,
                type: testimonialSelected.type,
                title: testimonialSelected.title,
                fileName: testimonialSelected.file_name,
                brand_id: testimonialSelected.brand_id,
                line: line,
                countries: testimonialSelected.countries,
            })
        }
    }, [testimonialSelected])

    // Functions
    const handleCloseModal = () => {
        dispatch(toggleFormTestimonials())
    }

    const handleInputChange = (inputName: string, value: any) => {
        setTestimonial({ ...testimonial, [inputName]: value })
    }

    const handleInputTextChange = (inputName: string) => (event: any) => {
        const value = event.target.value
        setTestimonial({ ...testimonial, [inputName]: value })
    }

    const handleSubmit = () => {
        dispatch(
            formValidator({
                fileName: [testimonial.fileName, 'required'],
                title: [testimonial.title, 'required'],
                countries: [testimonial.countries, 'notempty'],
            })
        )
    }

    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator())

            if (testimonial.id === 0) dispatch(storeTestimonial(testimonial))
            else dispatch(updateTestimonial(testimonial))
        }
    }, [isValidForm])

    const handleModalCreate = () => {
        setTestimonial({
            id: 0,
            type: type,
            title: null,
            fileName: null,
            brand_id: brandSelected.id,
            line: [],
            countries: [],
        })

        dispatch(toggleFormTestimonials())
    }

    return (
        <>
            <Button
                id="btn-create-user"
                variant="outlined"
                color="secondary"
                onClick={() => handleModalCreate()}
            >
                {t('create')} {type ? t('photos') : t('testimonial')}
            </Button>

            <Dialog
                open={showForm}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                className={formClasses.containerForm}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {testimonial.id === 0 ? t('create') : t('edit')}{' '}
                            {type ? t('photos') : t('testimonial')}
                        </Typography>
                        <Button
                            onClick={handleCloseModal}
                            color="primary"
                            variant="text"
                        >
                            <CloseRounded />
                        </Button>
                    </Grid>
                </DialogTitle>

                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" justify="space-between" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                id="Titulo"
                                label={t('title') + '*'}
                                type="text"
                                variant="filled"
                                value={testimonial.title}
                                error={errorForm.title}
                                helperText={msgForm.title}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleInputTextChange('title')}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <LineAutocomplete
                                value={testimonial.line}
                                brand={brandSelected}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <InputCountry
                                value={testimonial.countries}
                                handleInputChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Dropzone
                            name={'fileName'}
                            handleInputChange={handleInputChange}
                            dir={'testimonials'}
                        />
                        {testimonialSelected !== null &&
                            testimonialSelected.file_name !== null &&
                            testimonialSelected.file_name ===
                            testimonial.fileName && (
                                <Box
                                    p={1}
                                    display="grid"
                                    justifyContent="center"
                                    my={2}
                                    borderRadius={8}
                                    gridGap={8}
                                    style={{ backgroundColor: '#FAFAFA' }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {t('admin.library.preview') + ':'}
                                    </Typography>
                                    <img
                                        src={
                                            process.env.REACT_APP_AWS_URL +
                                            '/testimonials/' +
                                            testimonialSelected.file_name
                                        }
                                        width="75%"
                                        height="auto"
                                        alt={testimonialSelected.file_name}
                                    />
                                </Box>
                            )}
                    </Grid>
                </DialogContent>

                <DialogActions
                    className={
                        !isValidForm && Object.keys(msgForm).length > 0
                            ? formClasses.errorDialogActions
                            : ''
                    }
                >
                    <CancelButton onClick={handleCloseModal}>
                        {t('cancel')}
                    </CancelButton>
                    <SaveButton onClick={() => handleSubmit()}>
                        {t('save')}
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TestimonialModal
