import {
    INoveltyState,
    START_GET_NOVELTIES, SUCCESS_GET_NOVELTIES, ERROR_GET_NOVELTIES,
    START_GET_CLIENT_NOVELTIES, SUCCESS_GET_CLIENT_NOVELTIES, ERROR_GET_CLIENT_NOVELTIES,
    START_CREATE_NOVELTY, SUCCESS_CREATE_NOVELTY, ERROR_CREATE_NOVELTY,
    START_UPDATE_NOVELTY, SUCCESS_UPDATE_NOVELTY, ERROR_UPDATE_NOVELTY,
    START_SHOW_NOVELTY, SUCCESS_SHOW_NOVELTY, ERROR_SHOW_NOVELTY,
    START_SHOW_NOVELTY_BY_SLUG, SUCCESS_SHOW_NOVELTY_BY_SLUG, ERROR_SHOW_NOVELTY_BY_SLUG,
    START_DELETE_NOVELTY, SUCCESS_DELETE_NOVELTY, ERROR_DELETE_NOVELTY,
    SUCCESS_TOGGLE_FORM_NOVELTY,
    START_ASSIGN_TAG_NOVELTY, SUCCESS_ASSIGN_TAG_NOVELTY, ERROR_ASSIGN_TAG_NOVELTY,
    START_DELETE_TAG_NOVELTY, SUCCESS_DELETE_TAG_NOVELTY, ERROR_DELETE_TAG_NOVELTY,
    START_ASSIGN_HIGHLIGHT_NOVELTY, SUCCESS_ASSIGN_HIGHLIGHT_NOVELTY, ERROR_ASSIGN_HIGHLIGHT_NOVELTY
} from '../types';

const initialState: INoveltyState = {
    reload: false,
    novelties: [],
    news: [],
    noveltySelected: null,
    newsSelected: null,
    msg: null,
    error: false,
    loading: false,
    showForm: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
    switch (action.type) {
        case START_GET_NOVELTIES:
        case START_GET_CLIENT_NOVELTIES:
        case START_CREATE_NOVELTY:
        case START_UPDATE_NOVELTY:
        case START_SHOW_NOVELTY:
        case START_SHOW_NOVELTY_BY_SLUG:
        case START_DELETE_NOVELTY:
        case START_ASSIGN_TAG_NOVELTY:
        case START_DELETE_TAG_NOVELTY:
        case START_ASSIGN_HIGHLIGHT_NOVELTY:
            return {
                ...state,
                error: false,
                reload: false,
                loading: true
            }
        case SUCCESS_GET_NOVELTIES:
            return {
                ...state,
                [action.payload.type]: action.payload.novelties,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_GET_CLIENT_NOVELTIES:
            return {
                ...state,
                [action.payload.type]: action.payload.novelties,
                reload: false,
                msg: null,
                error: false,
                loading: false
            }
        case SUCCESS_CREATE_NOVELTY:
        case SUCCESS_UPDATE_NOVELTY:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: true,
                loading: false
            }
        case SUCCESS_SHOW_NOVELTY:
        case SUCCESS_SHOW_NOVELTY_BY_SLUG:
            return {
                ...state,
                [action.payload.type]: action.payload.novelty,
                msg: null,
                error: false,
                reload: false,
                loading: false
            }
        case SUCCESS_DELETE_NOVELTY:
        case SUCCESS_ASSIGN_HIGHLIGHT_NOVELTY:
            return {
                ...state,
                msg: action.payload.msg,
                error: false,
                reload: true,
                loading: false
            }
        case SUCCESS_TOGGLE_FORM_NOVELTY:
            return {
                ...state,
                [action.payload.type]: (action.payload.change) ? null : state[action.payload.type as keyof INoveltyState],
                showForm: !state.showForm,
                reload: false
            }
        case SUCCESS_ASSIGN_TAG_NOVELTY:
            let tmpNovelty = (action.payload.type === 'news') ? state.news.find((novelty: any) => novelty.id === action.payload.id) : state.novelties.find((novelty: any) => novelty.id === action.payload.id);
            tmpNovelty.tags = action.payload.tags.toString();
            return {
                ...state,
                [action.payload.type]: (action.payload.type === 'news') ? state.news.map((novelty: any) => novelty.id === action.payload.id ? tmpNovelty : novelty) : state.novelties.map((novelty: any) => novelty.id === action.payload.id ? tmpNovelty : novelty),
                error: false,
                loading: false,
                reload: false
            }
        case SUCCESS_DELETE_TAG_NOVELTY:
            let tmpNovelty1 = (action.payload.type === 'news') ? state.news.find((novelty: any) => novelty.id === action.payload.id) : state.novelties.find((novelty: any) => novelty.id === action.payload.id);
            const tags = tmpNovelty1.tags.split(',');
            const newTags = tags.filter((tagId: number) => tagId != action.payload.tagId).toString();
            tmpNovelty1.tags = newTags === '' ? null : newTags;
            return {
                ...state,
                [action.payload.type]: (action.payload.type === 'news') ? state.news.map((novelty: any) => novelty.id === action.payload.id ? tmpNovelty1 : novelty) : state.novelties.map((novelty: any) => novelty.id === action.payload.id ? tmpNovelty1 : novelty),
                error: false,
                loading: false,
                reload: false
            }
        case ERROR_GET_NOVELTIES:
        case ERROR_GET_CLIENT_NOVELTIES:
        case ERROR_CREATE_NOVELTY:
        case ERROR_UPDATE_NOVELTY:
        case ERROR_SHOW_NOVELTY:
        case ERROR_SHOW_NOVELTY_BY_SLUG:
        case ERROR_DELETE_NOVELTY:
        case ERROR_ASSIGN_TAG_NOVELTY:
        case ERROR_DELETE_TAG_NOVELTY:
        case ERROR_ASSIGN_HIGHLIGHT_NOVELTY:
            return {
                ...state,
                msg: action.payload,
                error: true,
                reload: false,
                loading: false,
                showForm: false
            }
        default:
            return state;
    }
}