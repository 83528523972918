import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeContext } from "@react-pdf-viewer/core";

const mainTheme = createMuiTheme({
	typography: {
		fontFamily: "Poppins, sans-serif",
		h1: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "3.75rem",
		},
		h2: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "3rem",
		},
		h3: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "2.625rem",
		},
		h4: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "2rem",
		},
		h5: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "1.75rem",
		},
		h6: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "1.5rem",
		},
		subtitle1: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "1.125rem",
		},
		body1: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "1rem",
			fontWeight: 200
		},
		body2: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "0.875rem",
			fontWeight: 200
		},
		caption: {
			fontFamily: "Poppins, sans-serif",
			fontSize: "0.75rem",
		},
	},
	palette: {
		common: {
			black: "#171A1C",
			white: "#FAFAFA",
		},
		primary: {
			light: process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296",
			main: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
			dark: process.env.REACT_APP_COLOR_PRIMARY_DARK || "#FB0E4B",
		},
		secondary: {
			main: process.env.REACT_APP_COLOR_SECONDARY_MAIN || "#fd9eb7",
		},
		grey: {
			50: "#FAFAFA",
			100: "#E3E5E8",
			200: "#C7CCD1",
			300: "#ABB3BA",
			400: "#8F99A3",
			500: "#73808C",
			600: "#5C6670",
			700: "#454D54",
			800: "#2E3338",
			900: "#171A1C",
		},
		error: {
			light: "rgba(242, 61, 79, 0.2)",
			main: "#FE4634",
			dark: "#F23D4F",
		},
		warning: {
			light: "rgba(255, 119, 51, 0.2)",
			main: "#FF8833",
			dark: "#FF7733",
		},
		success: {
			light: "rgba(0, 166, 80, 0.2)",
			main: "#8DDD55",
			dark: "#00A650",
		},
		info: {
			light: "rgba(65, 137, 230, 0.2)",
			main: "#3483FA",
		},
		text: {
			primary: "#2E3338",
			secondary: "#8F99A3",
			disabled: "#FAFAFA",
		},
	},
	overrides: {
		// BASE
		MuiButton: {
			root: {
				textTransform: 'capitalize',
				fontWeight: 400,
			},
			contained: {
				boxShadow: 'none',
				borderRadius: '8px',
			},
			containedPrimary: {
				color: "#FAFAFA",
			}
		},
		MuiBackdrop: {
			root: {
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				ZIndex: "99999",
			},
		},
		MuiCardActionArea: {
			focusHighlight: {
				backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
			},
		},
		MuiDialogActions: {
			root: {
				background: '#E3E5E8',
				padding: '16px 11px'
			}
		},
		// FORMS
		MuiFormControl: {
			root: {
				marginBottom: "13px",
			},
		},
		MuiInputLabel: {
			shrink: {
				top: "1px",
				left: "-1px",
				fontSize: "14px",
				color: "#73808C",
				fontWeight: 400,
				letterSpacing: "0.5px",
			},
		},
		MuiInput: {
			underline: {
				borderBottom: 'none !important',
				"&:before": {
					borderBottom: 'none !important',
				},
				"&:after": {
					borderBottom: 'none !important',
				},
			},
		},
		MuiFilledInput: {
			root: {
				backgroundColor: 'transparent',
				border: '1px solid #C7CCD1',
				borderRadius: '8px !important',
				boxShadow: 'none',
			},
			underline: {
				'&:before': {
					display: 'none'
				},
				'&:after': {
					opacity: 0,
				}
			},
		},
		MuiTableCell: {
			root: {
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: "Poppins, sans-serif !important",
				borderBottom: '1px solid #454D54',
				color: '#454D54!important',
				padding: '0px 8px',
				"&:first-child": {
					paddingLeft: '8px !important',
				},
			},
		},
		MuiChip: {
			root: {
				borderRadius: '8px',
				backgroundColor: "#ABB3BA",
				color: "#FAFAFA",
				maxHeight: '26px'
			},
			colorPrimary: {
				backgroundColor: "#8DDD55",
			},
			colorSecondary: {
				backgroundColor: "#FF8833",
			},
			deletable: {
				backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#FC3E6F",
				height: "26px",
			},
			deleteIcon: {
				color: "#FAFAFA",
				width: "auto",
				height: "17px",
			},
			sizeSmall: {
				height: '18px',
				fontSize: '12px'
			},
		},
		MuiLink: {
			root: {
				'&:hover, &:hover h1, &:hover h2, &:hover h3, &:hover h4, &:hover h5, &:hover h6, &:hover p': {
					color: process.env.REACT_APP_COLOR_PRIMARY_LIGHT || "#FD7296"
				}
			},
			underlineHover: {
				'&:hover': {
					textDecoration: 'none',
					cursor: 'pointer'
				}
			}
		},

		MuiDialogTitle: {
			root: {
				"& h6": {
					fontWeight: 700,
				},
				fontWeight: 700,
			}
		},

		MuiDrawer: {
			root: {
				top: "50px",
			},
		},
		MuiDivider: {
			root: {
				width: '100%',
				height: '1px',
				margin: '0px',
				backgroundColor: "#8F99A3",
			}
		},
	},
});

export default mainTheme;
