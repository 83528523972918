// React
import { useState, useEffect, forwardRef, ReactElement, Ref } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Grid,
  FormHelperText,
  Slide,
  Tab,
  Box,
  ThemeProvider,
} from "@material-ui/core";
import {
  CloseRounded,
  FavoriteRounded,
  PersonRounded,
  RotateLeftRounded,
  SpaRounded,
  TextsmsRounded,
  ThumbUpAltRounded,
} from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";

// Libraries
import { useTranslation } from "react-i18next";
import clsx from "clsx";

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

//Custom components
import SuscriptionLive from "../common/forms/SuscriptionLive";
import ButtonBack from "../common/ButtonBack";
import Login from "../../pages/auth/Login";
import Register from "../../pages/auth/Register";

// Assets
import logo from "../../assets/Images/logos/logodark.svg";

//Styles
import FormStyles from "../../styles/components/common/forms/FormsStyles";
import mainTheme from "../../styles/components/themes/NavLayoutTheme";

// Redux Actions
import { IRootState } from "../../redux/types";
import { subscribeToSeminarlesson } from "../../redux/actions/seminarlessons";
import { CancelButton, SaveButton } from "../common/forms";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TicketModal({ stateModal, handleClose }: any) {
  //Styles
  const classes = FormStyles();

  //Custom Hook
  const isMobile = useScreenWidth(700);

  // Redux Hooks
  const dispatch = useDispatch();
  const {
    isAuth,
    authFrom,
    user: authUser,
    loading,
    msg,
    error,
  } = useSelector((state: IRootState) => state.auth);
  const { seminarlesson_selected } = useSelector(
    (state: IRootState) => state.seminarlesson
  );

  // Translate
  const { t } = useTranslation(["global"]);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);

  useEffect(() => {
    if (
      seminarlesson_selected !== null &&
      seminarlesson_selected.isRegistered !== null
    )
      handleClose(false);
  }, [seminarlesson_selected]);

  useEffect(() => {
    if (isAuth && authFrom === "seminar")
      dispatch(
        subscribeToSeminarlesson({
          seminarlesson_id: seminarlesson_selected.id,
        })
      );
  }, [isAuth]);

  const handleGoBack = () => {
    setShowRegistration(false);
    setShowLogin(false);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog
        open={stateModal}
        fullWidth={true}
        fullScreen={isMobile && true}
        maxWidth={(!showLogin && !showRegistration) && isMobile  ? false : "sm"}
        className={classes.containerForm}
        TransitionComponent={Transition}
        onClose={() => {
          handleClose(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            alignItems="center"
            justify={
              !showLogin && !showRegistration ? "flex-end" : "space-between"
            }
          >
            {!showLogin && !showRegistration ? (
              <Button
                onClick={() => {
                  handleClose(false);
                }}
                color="primary"
                variant="text"
              >
                <CloseRounded />
              </Button>
            ) : (
              <>
                <img src={logo} height="24" alt="logo" />
                <ButtonBack
                  customFunction={handleGoBack}
                  goBack={false}
                  async={false}
                />
              </>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.bodyFormLink}>
          <Grid
            container
            alignItems="center"
            justify={
              !showLogin && !showRegistration ? "flex-end" : "space-between"
            }
          >
            {isAuth ? (
              <SuscriptionLive />
            ) : (
              !showLogin &&
              !showRegistration && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  height={isMobile && "90%"}
                  maxWidth={350}
                  gridGap={16}
                  pb={2}
                  mx="auto"
                >
                  <Typography variant="h6">
                    {t("client.seminars.register-to-watch-this-seminar-text")}
                  </Typography>
                  <SaveButton
                    autoFocus
                    id="btn-handlelogin"
                    startIcon={<FavoriteRounded />}
                    onClick={() => setShowLogin(true)}
                  >
                    {t("client.seminars.do-you-have-user-login-text", {
                      APP_NAME: process.env.REACT_APP_NAME,
                    })}
                  </SaveButton>
                  <SaveButton
                    id="btn-handlelogin"
                    className={classes.singup}
                    startIcon={<SpaRounded />}
                    onClick={() => setShowRegistration(true)}
                  >
                    {t("client.seminars.dont-you-have-user-register-text", {
                      APP_NAME: process.env.REACT_APP_NAME,
                    })}
                  </SaveButton>
                  <SaveButton
                    id="btn-handlelogin"
                    startIcon={<TextsmsRounded />}
                    style={{ padding: "10px 16px" }}
                    onClick={() => setShowLogin(true)}
                  >
                    {t(
                      "client.seminars.do-you-have-user-dont-remember-password-text"
                    )}
                  </SaveButton>
                  <CancelButton
                    className={classes.inscription}
                    onClick={() => {
                      handleClose(false);
                    }}
                  >
                    {t("cancel")}
                  </CancelButton>
                </Box>
              )
            )}
            {showLogin && <Login authFrom={"seminar"} />}
            {showRegistration && <Register authFrom={"seminar"} />}
          </Grid>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default TicketModal;
